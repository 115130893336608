import React from 'react';
import '@/components/popup/popup.css';
import popupNestingHook from '@/hooks/components/popup/nesting/popupNesting';


/* 중첩 팝업 */
const PopupNesting = () => {

  const { popupNesting, popupNestingClose, popupNestingConfirm, isVisible } = popupNestingHook();


  return (
    /* 레이아웃 팝업 */
    <>
      {
        (popupNesting.TYPE === 'decision' || popupNesting.TYPE === 'alert') &&
        <div className={`layout-popup nesting ${isVisible && 'visible'}`}>

          {/* 알림 팝업 */}
          {popupNesting.TYPE === 'alert' &&
            <div className={'layout-popup-box alert'}>


              <article className={'layout-popup-content'}>
                <span>{popupNesting.CONTENT}</span>
              </article>


              <button className={'layout-popup-button'} onClick={() => popupNestingClose()}>
                OK
              </button>
            </div>
          }

          {/* 결정 팝업 */}
          {popupNesting.TYPE === 'decision' &&
            <div className={'layout-popup-box decision'}>


              <article className={'layout-popup-content'}>
                <span>{popupNesting.CONTENT}</span>
              </article>


              <div className='layout-popup-button-box'>
                <button className={'layout-popup-button double gray'}
                  onClick={() => popupNestingClose()}>
                  {popupNesting.RESERVE === 'close' && 'close'}
                </button>
                <button className={'layout-popup-button double'} onClick={() => popupNestingConfirm()}>
                  {popupNesting.BUTTON}
                </button>
              </div>

            </div>
          }
        </div>
      }
    </>


  );
};

export default PopupNesting;

import React from 'react';
import { Outlet } from 'react-router-dom';
import FloatingContainer from '@/components/container/service/full/FloatingContainer';

/**  플로팅 레이아웃  **/
const FloatingLayout = () => {

    return (
        <div className='AppRoot'>
            <FloatingContainer>
                <Outlet />
            </FloatingContainer>
        </div>
    );

};

export default FloatingLayout;

import { streamApi } from '@/services/network';
import { StreamMappingCode, StreamResponseCode } from '@/utils/code-enums';

/* #######################################
 * ############# 스트림 옵션변경 #############
 * ####################################### */

/* function interface */
export interface FunctionReq {
    mediaBoxIp: string;
    lectureId: string;
    conference: boolean;
    record: boolean;
    roomId?: string;
}

export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
    target?: string
}



/* Api Interface */
interface ApiReq {
    lecture_id: string;
    video: boolean;
    audio: boolean;
    conference: boolean;
    record: boolean;
    username: string;
    room_id: string;
}
interface ApiRes {
    status: number;
    code: number;
    data: any
}




export const changeStream = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `http://${request.mediaBoxIp}:9090/v2/change_stream`;
    const method = 'POST';

    const req: ApiReq = {
        lecture_id: request.lectureId,
        video: true,
        audio: true,
        conference: request.conference,
        record: request.record,
        username: request.roomId ? 'CAMERA' : '',
        room_id: request.roomId ? request.roomId : ''
    };

    const res:ApiRes = await streamApi<ApiReq, ApiRes>(endPoint, req, method);

    const message = StreamMappingCode[res.code.toString() as StreamResponseCode] || 'FAIL';

    if (res.code.toString() === StreamResponseCode.SUCCESS && res.data) {
        return {
            message,
            target: res.data
        };
    }
    return { message };

};

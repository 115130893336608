import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { popupErrorStore, popupOtherStore } from '@/recoil/store/popup';
import { useEffect, useRef } from 'react';
import { getSession } from '@/hooks/common';
import { classTalkPopupStore } from '@/recoil/store/class';
import { thisSchedulerStore, timeTableStore, checkInTimeInfoStore } from '@/recoil/store/schedule';
import checkIn, { ClassInfoInterface } from '@/hooks/services/checkIn';
import { checkInManagementStore } from '@/recoil/store/checkIn';
import scheduler from '@/hooks/services/scheduler';
import { mirrorAction } from '@/services/native';
import { classTimetable } from '@/services/core/timetable/classTimeTable';
import { checkIn as statusCheckIn } from '@/services/status/checkIn';
import { useNavigate } from 'react-router-dom';
import publicMethod from '@/utils/publicMethod';


/* 전자칠판 레이아웃 */
export default () => {

  const navigate = useNavigate();

  const { usersInfo, classInfo, classCheckIn, classCheckOut, returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk } = checkIn();

  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  const [checkInManagement, setCheckInManagement] = useRecoilState(checkInManagementStore);   // 체크인 관리 ( 각 서버 정상접근중인지 여부 확인 )

  const setPopupOther = useSetRecoilState(popupOtherStore);
  // 수업 톡 리스트
  const classTalkPopup = useRecoilValue(classTalkPopupStore);

  const checkInManagementClear = useResetRecoilState(checkInManagementStore);                 // 체크인 관리 초기화

  const setTimeTable = useSetRecoilState(timeTableStore);       // 당일 시간표
  const thisScheduler = useRecoilValue(thisSchedulerStore);
  const [checkInTimeInfo, setCheckInTimeInfo] = useRecoilState(checkInTimeInfoStore);   // 체크인 시간정보
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);

  const checkInRunning = useRef(false);

  scheduler();    // 스케줄러 실행

  /* 스케줄이 있는 경우 */
  useEffect(() => {
    // console.log('thisScheduler ==', !thisScheduler);



    // 로그 메시지 출력 및 체크인 처리 함수
    const logAndCheckIn = (message: string) => {
      console.log(message);
      deviceCheckIn(sessionBaseInfo?.mappingClass![0].classId!);
    };



    // checkInManagementClear();
    // classCheckOut();
    if (!checkInTimeInfo) {
      logAndCheckIn(`
            스케줄러에서 클래스가 종료된 경우 classCheckOut 하면서 바로 다음수업 classCheckIn 진행
            1. Core 해당교실 체크인 전송 및 데이터 반환
            2. Class Check in
            3. Check In Target classId : ${sessionBaseInfo?.mappingClass![0].classId}
        `);
    } else { }
    // if (!thisScheduler) return;

    // const nowTime = Number(publicMethod.getThisTime());

    // // 체크인해야 하는 경우
    // const shouldCheckIn = () =>
    //   thisScheduler.inOut === 'IN' && Number(thisScheduler.startTime) < nowTime && !classInfo && !checkInTimeInfo;
    // console.log('shouldCheckIn ==', shouldCheckIn);
    // // 체크아웃해야 하는 경우
    // const shouldCheckOut = () =>
    //   thisScheduler.sec < 1 && classInfo && checkInTimeInfo;

    // // 다시 체크인해야 하는 경우
    // const shouldReCheckIn = () =>
    //   !classInfo && !checkInTimeInfo;

    // // 로그 메시지 출력 및 체크인 처리 함수
    // const logAndCheckIn = (message: string) => {
    //   console.log(message);
    //   deviceCheckIn(sessionBaseInfo?.mappingClass![0].classId!);
    // };

    // if (shouldCheckIn()) {
    //   logAndCheckIn(`
    //         스케줄러에서 클래스가 종료된 경우 classCheckOut 하면서 바로 다음수업 classCheckIn 진행
    //         1. Core 해당교실 체크인 전송 및 데이터 반환
    //         2. Class Check in
    //         3. Check In Target classId : ${sessionBaseInfo?.mappingClass![0].classId}
    //     `);
    // } else if (shouldCheckOut()) {
    //   console.log('1. Class Check out');
    // } else if (shouldReCheckIn()) {
    //   logAndCheckIn(`
    //         다시 체크인 해야하는 경우
    //         1. Core 해당교실 체크인 전송 및 데이터 반환
    //         2. Class Check in
    //         3. Check In Target classId : ${sessionBaseInfo?.mappingClass![0].classId}
    //     `);
    // }

  }, [thisScheduler, classInfo, checkInTimeInfo]);

  /* 스케줄러와 함께 자동 체크인/체크아웃 */
  useEffect(() => {

    /* 전자칠판/원퀵 시간표 조회 */
    getScheduleTable();

  }, []);

  /* 체크아웃 실패시 처리 */
  useEffect(() => {
    console.log(`LINK SCHOOL ${JSON.stringify(checkInManagement)}`);
    if (checkInManagement.checkInState === true) {
      console.log('LINK SCHOOL CHECK IN SUCCESS');
    } else if (checkInManagement.checkInState === false) {
      console.log('LINK SCHOOL CHECK IN FAIL');
      checkInManagementClear();
      classCheckOut();
    }
  }, [checkInManagement]);

  const getScheduleTable = async (): Promise<void> => {

    if (sessionBaseInfo?.currentWeek && sessionBaseInfo?.currentSemester && sessionTokenInfo?.coreAccessToken && sessionBaseInfo?.schoolInfo) {
      const newTimeTableRes = await classTimetable({ weekNumber: sessionBaseInfo.currentWeek, currentSemester: sessionBaseInfo.currentSemester.semester, token: sessionTokenInfo.coreAccessToken, classId: sessionBaseInfo.schoolInfo.classId });

      if (newTimeTableRes.message === 'SUCCESS' && newTimeTableRes.data) {
        setTimeTable(newTimeTableRes.data.scheduleTimeTable);
      }
      else if (newTimeTableRes.message === 'LOGOUT') {
        navigate('/', { replace: true });
      }
      else if (newTimeTableRes.message === 'TOKEN_EXPIRED') {
        navigate('/', { replace: true });
      }
      else if (newTimeTableRes.message === 'TIMEOUT') {
        navigate('/', { replace: true });
      }
      else {
        // 서버 에러 팝업
        setPopupError(prevData => [...prevData, { CODE: newTimeTableRes.errorCode, MESSAGE: newTimeTableRes.errorMessage }]);

      }
    }


  };

  const getSecureRandomNumber = (): number => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return (array[0] % 25000) + 5000; // 5000ms (5초) ~ 30000ms (30초) 범위의 난수 생성
  };

  const deviceCheckIn = async (classId: string) => {

    if (checkInRunning.current) {
      console.log('Already run check in');
      return;
    }
    checkInRunning.current = true;

    let randomNumber = 0;

    if (sessionBaseInfo?.baseInfo.role !== 'board' && sessionBaseInfo?.baseInfo.role !== 'onequick') {
      randomNumber = getSecureRandomNumber();
    }

    console.log(`${randomNumber} ms 후 체크인 시도`);
    setTimeout(async () => {
      try {
        console.log('체크인 Api 호출');
        const statusCheckInRes = await statusCheckIn({
          classroomId: classId,
          token: sessionTokenInfo?.coreAccessToken!
        });
        if (statusCheckInRes.code === 'OK' && statusCheckInRes.data) {
          const hookReq: ClassInfoInterface = {
            ...statusCheckInRes.data,
            classGrade: sessionBaseInfo?.mappingClass?.find((f) => f.classId === classId)?.grade ?? '',
            classNumber: sessionBaseInfo?.mappingClass?.find((f) => f.classId === classId)?.className ?? '',
            groupInfo: statusCheckInRes.data.groupInfo || null
          };
          setCheckInManagement((prev) => ({ ...prev, coreCheckIn: true }));
          await classCheckIn(hookReq);
        } else {
          setCheckInTimeInfo(null);
        }
      } catch (error) {
        console.error('Check-in failed:', error);
      } finally {
        // 작업 완료 후 플래그 해제
        console.log('30초 뒤 체크인 재시도 가능으로 변경');
        setTimeout(() => {
          checkInRunning.current = false;
        }, 30000);
      }
    }, randomNumber);



  };


  /* 팝업 등 처리 */
  useEffect(() => {

    const latestTalk = classTalkPopup[classTalkPopup.length - 1];
    // alert(JSON.stringify(latestTalk));

    if (classTalkPopup.length > 0) {
      let popupType = '';
      let request = false;


      switch (latestTalk.talkTitle) {
        case 'mirror':
          popupType = 'mirror';
          request = true;
          break;
        case 'file':
          popupType = 'file';
          request = latestTalk.talkType === 'event';
          break;
        case 'link':
          popupType = 'link';
          request = latestTalk.talkType === 'event';
          break;
        case 'msg':
          popupType = 'msg';
          break;
        default:
          break;
      }


      if (popupType !== '') {
        try {
          const windowState = mirrorAction('screen', { command: 'get' });
          const windowStateJson = JSON.parse(windowState!);
          if (windowStateJson.get === 'min' || windowStateJson.get === 'normal') {
            mirrorAction('popup', { command: 'popup', width: '400', height: '500' });
          }
        } catch (e: any) {
          console.log(e);
        }
        setPopupOther({ TYPE: popupType, REQUEST: request, DATA: latestTalk });
      }
    }
  }, [classTalkPopup]);









  return { usersInfo, returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk };

};

import { statusApi } from '@/services/network';
import {
  ContentMappingCode,
  ContentResponseCode,
  StatusMappingLogCode,
  StatusResponseErrorCode
} from '@/utils/code-enums';

/* ##############################################
 * ############# Bypass 메세지 전송처리 #############
 * ############################################## */





/* function interface */
export interface FunctionReq {
  token: string;
  topic: string;
  message: string;
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  code: string
}


/* Api Interface */
interface ApiReq {
  topic: string;
  message: string;
}
interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const userBypassMessage = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/push-notification`;
  const method = 'POST';

  const req: ApiReq = {
    topic: request.topic,
    message: request.message
  };

  const res: ApiRes = await statusApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to send the message.';

  return {
    message,
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label
  };
};

import { contentApi } from '@/services/network';
import {
    GroupListManagementInterface,
    GroupManagementInterface,
    SubGroupInterface
} from '@/recoil/store/teacherManagement';

/* #########################################
 * ############# 모둠 저장/업데이트 #############
 * ######################################### */


/* function interface */
export interface functionReq {
    token: string;
    parentId: number;
    groupData: GroupManagementInterface
}

export interface functionRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}




/* Api Interface */
interface apiReq {
    parentId: number;
    id?: number;
    groupName: string;
    subGroupList: SubGroupInterface[];
}

interface apiRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}


export const updateGroup = async(request: functionReq): Promise<functionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/setting/group`;
    const method = 'PUT';

    const req: apiReq = {
        parentId: request.parentId,
        groupName: request.groupData.groupName,
        subGroupList: request.groupData.subGroupList
    };
    if (request.groupData.id) {
        req.id = request.groupData.id;
    }

    console.log('그룹 업데이트 Api Request', req);

    const res = await contentApi<apiReq, apiRes>(endPoint, req, method, request.token, null);

    console.log('그룹 업데이트 Api Response', res);

    if (res.code === 'OK') {
        return {
            code: 'OK',
            status: res.status,
            message: '그룹 업데이트 성공',
            data: res.data
        };
    }
    else {
        return {
            code: 'FAIL',
            status: res.status,
            message: '그룹 업데이트 실패'
        };
    }

};

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useRef, useState } from 'react';
import { ClassTalkListInterface, classTalkListStore } from '@/recoil/store/class';
import { getSession } from '@/hooks/common';
import { toastStore } from '@/recoil/store/common';
import { getChatToken } from '@/services/core/me/getChatToken';
import publicMethod from '@/utils/publicMethod';
import JSZip from 'jszip';
import { chatServerFileDownload, chatServerFileDownloadS3 } from '@/services/chat/chatServerFileDownload';
import { saveAs } from 'file-saver';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import { forceLogoutStore, userTokenInfoStore } from '@/recoil/store/user';
import { classGroupModeStore } from '@/recoil/store/checkIn';
import { mirrorManagementStore } from '@/recoil/store/teacherManagement';
import { useNavigate } from 'react-router-dom';
import { popupErrorStore } from '@/recoil/store/popup';


// 수업톡 훅
export default (
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>,
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>,
  getDetailMonitorRequest: (id: string) => void,
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>
) => {

  // 페이지 이동
  const navigate = useNavigate();
  // 세션값 가져오기
  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  // 수업 톡 리스트
  const [classTalkList, setClassTalkList] = useRecoilState(classTalkListStore);
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 파일 이름 div (요청, 전송)
  const fileContentBoxRef = useRef<HTMLDivElement>(null);
  const fileContentBoxRequestRef = useRef<HTMLDivElement>(null);
  // 파일 길이 설정 (요청, 전송)
  const [fileWidth, setFileWidth] = useState<number>(200);
  const [fileWidthRequest, setFileWidthRequest] = useState<number>(200);

  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
  const setUserTokenInfo = useSetRecoilState(userTokenInfoStore);
  const setForceLogout = useSetRecoilState(forceLogoutStore);
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);
  // 모둠모드 상태
  const classGroupMode = useRecoilValue(classGroupModeStore);
  // 수업 상태 관리 - 미러링
  const mirrorManagement = useRecoilValue(mirrorManagementStore);



  useEffect(() => {
    if (fileContentBoxRef.current) {
      const getFileDivWidth = fileContentBoxRef.current.getBoundingClientRect().width;
      setFileWidth(Math.round(getFileDivWidth));
    }
  }, [fileContentBoxRef, classTalkList]);

  useEffect(() => {
    if (fileContentBoxRequestRef.current) {
      const getFileDivWidthRequest = fileContentBoxRequestRef.current.getBoundingClientRect().width;
      setFileWidthRequest(Math.round(getFileDivWidthRequest));
    }
  }, [fileContentBoxRequestRef, classTalkList]);

  const handleTeacherScreenShare = (item: ClassTalkListInterface, approve: 'Y' | 'N') => {

    /* ******************************************************************************************
    *
    이 부분 런타임 데이터 적용하고 어떻게 바뀔지 모르니 우선 현재 공유중인지 아닌지 여부도 체크
    *
    * ******************************************************************************************/

    const isGroupMode = classGroupMode.groupMode;
    const isAlreadySharing = isGroupMode
      ? mirrorManagement.mirrorInfo.filter(u => !u.groupMirror).length > 0
      : mirrorManagement.mirrorInfo.length > 0;

    if (item.talkTitle === 'mirror') {
      if (isAlreadySharing) {
        setToast({ TYPE: 'alert', CONTENT: 'Please stop the current screen sharing and try again.', TIME: 3 });
        return;
      }
    } else {
      handleNonMirrorRequest(item, approve);
    }

    responseEvent(item.eventInfo!.type, approve, item.talkId, item.eventInfo?.targetInfo![0].name || '');
  };

  const handleNonMirrorRequest = (item: ClassTalkListInterface, approve: 'Y' | 'N') => {
    if (item.talkTitle === 'file') {
      setToast({ TYPE: 'alert', CONTENT: 'File transfer has been completed.', TIME: 3 });
    }
    if (item.talkTitle === 'link') {
      setToast({ TYPE: 'alert', CONTENT: 'Link transfer has been completed.', TIME: 3 });
    }
  };


  // 요청에 대한 공유 거절, 수락을 했을 경우
  const confirmScreenShare = (approve: 'Y' | 'N', item: ClassTalkListInterface) => {
    if (approve === 'Y') {
      // 선생일 경우에만 예외처리 필요 -> 이미 선생님이 다른 학생들(A,B)을 공유중인데, 다른 학생(c)이 화면공유 요청을 했을 경우 대비
      if (sessionBaseInfo?.baseInfo.role === 'teacher') {
        handleTeacherScreenShare(item, approve);
        return;
      }
    }
    responseEvent(item.eventInfo!.type, approve, item.talkId, item.eventInfo?.targetInfo![0].name || '');
  };


  const responseEvent = async (type: string, approve: 'Y' | 'N', talkId: string, targetId: string) => {


    if (type.includes('file') || type.includes('link')) {
      const permissionMessageIssue = await returnPermissionMessage({ permission: approve === 'Y', talkId: talkId });
      if (permissionMessageIssue) {
        const filteredTalkData = classTalkList.filter((item) => item.talkId !== talkId);
        setClassTalkList(filteredTalkData);
        await removeClassTalk(talkId);
      }
    } else if (type.includes('mirror')) {
      const mirrorPermissionMessageIssue = await returnMirrorPermissionMessage({ permission: approve === 'Y', talkId: talkId, senderId: '', targetId: targetId });
      if (mirrorPermissionMessageIssue) {
        const filteredTalkData = classTalkList.filter((item) => item.talkId !== talkId);
        setClassTalkList(filteredTalkData);
        await removeClassTalk(talkId);
      }
    } else if (type.includes('monitor')) {
      getDetailMonitorRequest(talkId);
    } else {
      // 스트림
    }
  };


  // 수업톡 삭제
  const removeClassTalk = async (talkId: string) => {
    const deleteClassTalkApi = await deleteClassTalk([talkId]);
    if (deleteClassTalkApi) {
      const filteredTalkData = classTalkList.filter((item) => item.talkId !== talkId);
      setClassTalkList(filteredTalkData);
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'Failed to delete the class talk.', TIME: 3 });
    }
  };

  // 링크 복사 상세 함수
  const handleCopyClipBoard = async (text: string) => {
    try {
      // 클립보드 사용 가능 -> PC
      if (navigator.clipboard?.writeText) {
        await navigator.clipboard.writeText(text);
      }
      // 클립보드 사용 불가능 -> TABLET, MOBILE
      else {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
      }
      // 링크 복사 완료 토스트
      setToast({ TYPE: 'alert', CONTENT: 'The link has been copied.', TIME: 3 });
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // 다운로드 버튼 클릭 시
  const clickDownload = (talkId: string, fileList: { fileUrl: string; fileRealName: string; }[]) => {

    // 파일이 하나일 경우, 설정된 디렉토리에 저장 후 알림 삭제
    // 파일이 2개일 경우, zip 파일로 압축하여 알림 삭제
    // 받은 fileList를 모두 다운로드하기 (fileInfo: { fileUrl: string, fileName: string }[])

    const downloadList = fileList?.map((item: any) => ({
      fileUrl: item.fileUrl,
      fileName: item.fileRealName
    }));
    if (downloadList.length > 0) {
      downloadFiles(downloadList);
      removeClassTalk(talkId);
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'There are no files to download.', TIME: 3 });
    }
  };


  // 토큰 처리 로직을 별도의 함수로 분리
  const handleChatToken = async (): Promise<string | null> => {
    const ctApiRes = await getChatToken({ id: sessionBaseInfo?.baseInfo.userId!, accessToken: sessionTokenInfo.coreAccessToken! });

    if (ctApiRes.message === 'SUCCESS' && ctApiRes.data) {
      setLoginSessionCount(publicMethod.sessionUpdate);
      setUserTokenInfo({
        coreAccessToken: sessionTokenInfo.coreAccessToken,
        chatAccessToken: ctApiRes.data.accessToken,
        chatRefreshToken: ctApiRes.data.refreshToken
      });
      return ctApiRes.data.accessToken;
    } else if (ctApiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
      return null;
    } else if (ctApiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
      return null;
    } else if (ctApiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      return null;
    } else {
      setPopupError(prevData => [...prevData, { CODE: ctApiRes.errorCode, MESSAGE: ctApiRes.errorMessage }]);
      return null;
    }
  };

  // 여러 파일을 다운로드하고 ZIP 파일로 만드는 함수
  const downloadAndZipFiles = async (fileInfo: { fileUrl: string, fileName: string }[], accessToken: string) => {
    const zip = new JSZip();
    for (const f of fileInfo) {
      let blob;
      if (sessionBaseInfo?.baseInfo.role === 'board') {
        blob = await chatServerFileDownloadS3({ token: accessToken, fileUrl: f.fileUrl });
      }
      else {
        blob = await chatServerFileDownload({ token: accessToken, fileUrl: f.fileUrl });
      }

      if (blob.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
        break;
      }
      zip.file(f.fileName, blob, { binary: true });
    }
    const content = await zip.generateAsync({ type: 'blob' });
    window.MirrorBLOBDownloadFileName(`${fileInfo[0].fileName} and ${fileInfo.length - 1} more files.zip`);
    saveAs(content, `${fileInfo[0].fileName} and ${fileInfo.length - 1} more files.zip`);
  };

  // 단일 파일을 다운로드하는 함수
  const downloadSingleFile = async (fileInfo: { fileUrl: string, fileName: string }[], accessToken: string) => {
    let blob;
    if (sessionBaseInfo?.baseInfo.role === 'board') {
      blob = await chatServerFileDownloadS3({ token: accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    else {
      blob = await chatServerFileDownload({ token: accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    if (blob.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      const file = new File([blob], fileInfo[0].fileName, { type: blob.type });
      window.MirrorBLOBDownloadFileName(fileInfo[0].fileName);
      saveAs(file, fileInfo[0].fileName);
    }
  };

  // 파일 다운로드
  const downloadFiles = async (fileInfo: { fileUrl: string, fileName: string }[]) => {

    const chatToken = await handleChatToken();

    if (chatToken) {
      if (fileInfo.length > 1) {
        await downloadAndZipFiles(fileInfo, chatToken);
      } else {
        await downloadSingleFile(fileInfo, chatToken);
      }
    }


  };





  return { classTalkList, fileContentBoxRef, fileContentBoxRequestRef, fileWidth, fileWidthRequest, confirmScreenShare, removeClassTalk, handleCopyClipBoard, clickDownload };
};

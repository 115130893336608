import { atom } from 'recoil';
import { MemberInfoInterface } from '@/services/core/schools/getClassMember';
import { SubGroupInterface } from '@/recoil/store/teacherManagement';

/*  @@@@@@@@@@ 선생 - 교수학습 을 위한 recoil 값 @@@@@@@@@@ */

/* 화면공유, 파일전송, 링크전송, 메시지전송 등등  */
export interface ClassCommandInterface {
    TYPE: string | null; // 화면공유, 화면공유 대상, 파일, 링크, 메시지
    SCREEN : {
        id: string
        division: 'teacher' | 'student' | 'onequick' | 'board';
        name: string;
        number?: number;
    }[]; // 화면공유 리스트 (화면공유)
    TARGET : {
        id: string
        division: 'teacher' | 'student' | 'onequick' | 'board';
        name: string;
        number?: number;
    }[]; // 공유, 전송 받는 사람 리스트 (화면, 파일, 링크, 메시지, 잠금)
    DATA?: string | File[] | null; // 문자열 또는 파일 리스트 또는 null ( 파일, 링크, 메시지)
}

export const classCommandStore = atom<ClassCommandInterface>({
    key: 'classCommand',
    default: {
        TYPE: null,
        SCREEN: [],
        TARGET: [],
        DATA: null
    }
});



/*  @@@@@@@@@@ 학생 - 학습모드 를 위한 recoil 값 @@@@@@@@@@ */

/* 화면공유, 파일전송, 링크전송, 메시지전송 등등  */
export interface StudentTargetInterface {
    TYPE: string | null; // 화면공유, 화면공유 대상, 파일, 링크, 메시지
    TARGET : {
        id: string
        division: 'teacher' | 'student' | 'onequick' | 'board';
        name: string;
        number?: number;
    }[]; // 공유, 전송 받는 사람 리스트 (화면, 파일, 링크, 메시지, 잠금)
    DATA?: string | File[] | null; // 문자열 또는 파일 리스트 또는 null ( 파일, 링크, 메시지)
}

export const studentTargetStore = atom<StudentTargetInterface>({
    key: 'studentTarget',
    default: {
        TYPE: null,
        TARGET: [],
        DATA: null
    }
});



export interface ClassTalkListInterface {
    talkId: string,
    talkType: 'chat' | 'event';
    talkTitle: 'file' | 'msg' | 'link' | 'mirror'
    sendTime: string,
    isRead: boolean,
    chatInfo?: {
        messageId: string
        id: string
        name: string
        type: string
        str?: string
        fileList?: {
            fileRealName: string;
            fileUrl: string;
            fileSize: number;
        }[]
    }
    eventInfo?: {
        mirrorId?: string;  // 미러링에서 사용 예정 ( 오픈요청 승인/거절, 참여요청 승인/거절 등 )
        senderId: string    // 내가
        senderName: string
        pubInfo?: {
            id: string
            name: string
            division: string
        }
        targetInfo?: {
            id: string
            name: string
            division: string
        }[]  // 누구에게
        eventId: string
        type: 'fileRequest' | 'mirrorRequest' | 'linkRequest' | 'fileResponse' | 'mirrorResponse' | 'linkResponse'
        str: string
        fileList?: {
            fileUrl: string; // 모두 저장 (zip파일 동작 후 삭제 필요)
            fileRealName: string
            fileSize: number
        }[]
    }
}

/* 교수학습/학습도구 - 수업톡 (적재)  */
export const classTalkListStore = atom<ClassTalkListInterface[]>({
    key: 'classTalkList',
    default: []
});


/* 교수학습/학습도구 - 수업톡 팝업 (팝업만 띄우는 용도 - 주의)  */
export const classTalkPopupStore = atom<ClassTalkListInterface[]>({
    key: 'classTalkPopup',
    default: []
});






/* 선생 - 수업톡 onOff  */
export interface ClassTalkToggleInterface {
    OPEN: boolean;
}

export const classTalkToggleStore = atom<ClassTalkToggleInterface>({
    key: 'classTalkToggle',
    default: {
        OPEN: false
    }
});



/* 선생 - 모니터링 화면  */
export interface MonitorScreenInterface {
    OPEN: boolean;
    ZOOM: boolean;
    DATA ?: any;
    RESERVE ?: string;
}

export const monitorScreenStore = atom<MonitorScreenInterface>({
    key: 'monitorScreen',
    default: {
        OPEN: false,
        ZOOM: false,
        DATA: {},
        RESERVE: ''
    }
});




/*  @@@@@@@@@@ 교수학습 모드 recoil 값 @@@@@@@@@@ */

export interface LearningModeInterface {
    TYPE: number; // 전체모드 (0), 전체모드 + 모둠 세팅 전 (1), 전체모드 + 모둠 세팅 후 (2), 모둠모드 + 모둠수업 시작(3)
    GROUP_ID ? : string;
}

export const learningModeStore = atom<LearningModeInterface>({
    key: 'learningMode',
    default: {
        TYPE: 0,
        GROUP_ID: ''
    }
});



/* 교실환경 - 수업모드 선택 시  */
interface ClassSettings {
    lightness: number;
    color: number;
}

export interface ClassModeInterface {
    openPopup: boolean;
    classId: string;
    lecture: ClassSettings;
    talk: ClassSettings;
    multi: ClassSettings;
    rest: ClassSettings;
}

export const classModeStore = atom<ClassModeInterface>({
    key: 'classMode',
    default: {
        classId: '',
        openPopup: false,
        lecture: { lightness: 5, color: 3 },
        talk: { lightness: 5, color: 3 },
        multi: { lightness: 5, color: 3 },
        rest: { lightness: 5, color: 3 }
    }
});



/*  @@@@@@@@@@ 모듬 생성 recoil 값 @@@@@@@@@@ */

/*  모둠 생성 데이터값 (클라이언트)  */


export interface ClassGroupCustomInterface {
    id ?: number
    groupName: string
    subGroupList: SubGroupInterface[]
}


export const classGroupCustomStore = atom<ClassGroupCustomInterface>({
    key: 'classGroupCustomState',
    default: {
        groupName: '',
        subGroupList: []
    }
});

export interface RegisteredMemberInterface {
    totalMember: string[],
    selectedGroupMember: string[]
}

export const registeredMemberStore = atom<RegisteredMemberInterface>({
    key: 'registeredMemberState',
    default: {
        totalMember: [],
        selectedGroupMember: []
    }
});

/*  모둠 생성 모드 상태 값 (커스텀바) */

export interface GroupCustomModeInterface {
    OPEN: boolean;
    STEP: number; // 1: 모둠원 선택, 2: 모둠장 선택, 3: 칠판 선택
    subGroupId: string
    subGroupName: string
    subGroupMember: {
        id?: number
        memberId: string
        division: string
        name: string
        number: string | null
        position: 'leader' | 'member'
        deviceActive: boolean
    }[]
    classRealMember: MemberInfoInterface[]
}

export const GroupCustomModeStore = atom<GroupCustomModeInterface>({
    key: 'GroupCustomMode',
    default: {
        OPEN: false,
        STEP: 1,
        subGroupId: '',
        subGroupName: '',
        subGroupMember: [],
        classRealMember: []
    }
});

/*  @@@@@@@@@@ 반 선택 (공지대상, 공유대상) 를 위한 recoil 값 @@@@@@@@@@ */

/* 반 아이디, 반 이름(00학과 0반) */
export interface ClassTargetInterface {
    id?: number,
    classId: string,
    className: string
}

export const classTargetStore = atom<ClassTargetInterface[]>({
    key: 'classTarget',
    default: []
});

/*  @@@@@@@@@@ 교수학습 / 학습모드 레드닷 @@@@@@@@@@ */
export interface redDotStateInterface {
    isRedDot: boolean;
}

export const redDotStateStore = atom<redDotStateInterface>({
    key: 'redDotState',
    default: {
        isRedDot: false
    }
});


/*  @@@@@@@@@@ 반 선택 (공지대상, 공유대상) 를 위한 recoil 값 @@@@@@@@@@ */


/* 선생 - 블렌디드 타겟 지정  */
export interface ClassBlendedToggleInterface {
    OPEN: boolean;
    DATA: any;
    IS_CHANGE: boolean;
}

export const classBlendedToggleStore = atom<ClassBlendedToggleInterface>({
    key: 'classBlendedToggle',
    default: {
        OPEN: false,
        DATA: {},
        IS_CHANGE: false
    }
});

import React, { useEffect, useState } from 'react';
import { MirrorOpenRequestInterface, UsersInfoBoxInterface, UsersInfoInterface } from '@/hooks/services/checkIn';
import popupTargetSelectHook from '@/hooks/components/popup/command/popupTargetSelect';
import { useRecoilValue } from 'recoil';
import { blendedStateStore } from '@/recoil/store/blended';
import { classGroupModeStore } from '@/recoil/store/checkIn';
import { getSession } from '@/hooks/common';
import '@/components/popup/popup.css';
import CloseIcon from '@/assets/icon/popup/close_button.svg';               // DomPurify 적용완료
import ScreenShareIcon from '@/assets/icon/common/screenshare.svg';         // DomPurify 적용완료
import FileIcon from '@/assets/icon/common/file.svg';                        // DomPurify 적용완료
import LinkIcon from '@/assets/icon/common/link.svg';                       // DomPurify 적용완료
import CheckedIcon from '@/assets/icon/common/checked-box.svg';             // DomPurify 적용완료
import UncheckedIcon from '@/assets/icon/common/unchecked-box.svg';        // DomPurify 적용완료
import RadioCheckedIcon from '@/assets/icon/common/RadioChecked.svg';       // DomPurify 적용완료
import RadioUncheckedIcon from '@/assets/icon/common/RadioUnchecked.svg';  // DomPurify 적용완료
import OnlineClassIcon from '@/assets/icon/common/online_class.svg';        // DomPurify 적용완료
import CopyIcon from '@/assets/icon/common/copy.svg';                       // DomPurify 적용완료
import publicMethod from '@/utils/publicMethod';


interface PopupTargetSelectProps {
  usersInfo: UsersInfoInterface[]
  usersInfoBox: UsersInfoBoxInterface[],
  setUsersInfoBox: React.Dispatch<React.SetStateAction<UsersInfoBoxInterface[]>>,
  requestJoinBlendedStreamLearning?: (userList: string[], roomId: string) => void,
  sendPermissionRequestMessage: (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }) => Promise<boolean>,
  mirrorOpenRequest: (request: MirrorOpenRequestInterface) => Promise<boolean>
}

interface SubGroupInterface {
  subGroupId: string;
  subGroupName: string;
  subMirror: boolean;
  subGroupMember: {
    division: string;
    id: string;
    name: string;
    number: string | null;
    position: 'leader' | 'member';
  }[];
}

/* 학생의 전송 대상 팝업 */
const PopupTargetSelect = ({ usersInfo, usersInfoBox, setUsersInfoBox, requestJoinBlendedStreamLearning, sendPermissionRequestMessage, mirrorOpenRequest }: PopupTargetSelectProps) => {


  const blendedState = useRecoilValue(blendedStateStore);
  const { popup, isVisible, popupClose, targetMemberData, studentTarget, targetSelectComplete, clickTarget, clickBlendedTarget, copyToast, copyLink, moveLink } = popupTargetSelectHook(usersInfo, usersInfoBox, setUsersInfoBox, sendPermissionRequestMessage, mirrorOpenRequest, requestJoinBlendedStreamLearning);

  const { sessionBaseInfo } = getSession();
  // 모둠모드 상태
  const classGroupMode = useRecoilValue(classGroupModeStore);
  // 나의 모둠
  const [mySubGroup, setMySubGroup] = useState<SubGroupInterface | null>(null);


  useEffect(() => {
    // 모둠모드일 경우, 자신이 속한 모둠을 찾아서 상태에 저장
    if (classGroupMode.groupMode) {
      const foundSubGroup = classGroupMode.groupInfo?.subGroupList.find(subGroup =>
        subGroup.subGroupMember.some(member => member.id === sessionBaseInfo?.baseInfo.userId)
      );
      if (foundSubGroup) {
        setMySubGroup(foundSubGroup);
      }
    }
  }, [classGroupMode]);

  return (
    <>
      {(popup.TYPE.includes('studentTarget') || popup.TYPE.includes('blendedTarget')) &&
        <div className={`layout-popup  ${isVisible && 'visible'}`}>
          {popup.TYPE.includes('studentTarget') &&
            <div className={'layout-popup-box command'}>
              <div className={'cmd-lp-top'}>
                <div className='cmd-lp-close-box'>
                  <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose(true)} />
                </div>
                <div className='cmd-lp-cover'>
                  {studentTarget.TYPE === 'screen' &&
                    <>
                      <img src={publicMethod.svgClear(ScreenShareIcon)} alt={'ScreenShareIcon'} draggable={false} />
                      <span>Screen Sharing</span> Please select the target to share my screen.
                    </>
                  }

                  {studentTarget.TYPE === 'file' &&
                    <>
                      <img src={publicMethod.svgClear(FileIcon)} alt={'FileIcon'} draggable={false} />
                      <span>File Transfer</span> Please select the target to receive the file.
                    </>
                  }

                  {studentTarget.TYPE === 'link' &&
                    <>
                      <img src={publicMethod.svgClear(LinkIcon)} alt={'LinkIcon'} draggable={false} />
                      <span>Link Transfer</span> Please select the target to receive the link.
                    </>
                  }
                </div>

              </div>

              <div className='cmd-lp-target-box'>
                <div className='cmd-lp-tb-inner'>

                  {
                    classGroupMode.groupMode && studentTarget.TYPE === 'screen'
                      ?
                      <>
                        {/* In group mode and screen sharing - cannot select students, can select teacher + board + only the board of my group */}
                        {
                          targetMemberData.filter(item => ((item.division === 'teacher' && item.checkIn) || item.division === 'board' || item.id === mySubGroup?.subGroupMember.filter(item => item.division === 'onequick')[0]?.id))
                            .sort((a, b) => {
                              if (a.division === 'teacher' && b.division !== 'teacher') return -1;
                              if (a.division !== 'teacher' && b.division === 'teacher') return 1;
                              if (a.division === 'board' && b.division !== 'board') return -1;
                              if (a.division !== 'board' && b.division === 'board') return 1;
                              return 0;
                            })
                            .map((target) => (
                              <div key={target.id} className='cmd-lp-tb-item' onClick={() => clickTarget(target)}>
                                <img src={usersInfoBox.find(u => u.id === target.id)?.isChecked ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={usersInfoBox.find(u => u.id === target.id)?.isChecked ? 'checkbox' : 'uncheckbox'} draggable={false} />
                                {
                                  target.division === 'teacher'
                                    ? <span>{target.name} Teacher</span> // Teacher's name
                                    : <span>{target.name}</span>
                                }
                              </div>
                            ))
                        }
                      </>
                      :
                      <>
                        {/* If not in group mode or not screen sharing - can select targets excluding students */}
                        {
                          targetMemberData.filter(item => ((item.division === 'teacher' && item.checkIn) || item.division === 'board' || item.division === 'onequick'))
                            .sort((a, b) => {
                              if (a.division === 'teacher' && b.division !== 'teacher') return -1;
                              if (a.division !== 'teacher' && b.division === 'teacher') return 1;
                              if (a.division === 'board' && b.division !== 'board') return -1;
                              if (a.division !== 'board' && b.division === 'board') return 1;
                              return 0;
                            })
                            .map((target) => (
                              <div key={target.id} className='cmd-lp-tb-item' onClick={() => clickTarget(target)}>
                                <img src={usersInfoBox.find(u => u.id === target.id)?.isChecked ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={usersInfoBox.find(u => u.id === target.id)?.isChecked ? 'checkbox' : 'uncheckbox'} draggable={false} />
                                {
                                  target.division === 'teacher'
                                    ? <span>{target.name} Teacher</span> // Teacher's name
                                    : <span>{target.name}</span>
                                }
                              </div>
                            ))
                        }
                      </>
                  }
                </div>
              </div>

              <div className='layout-popup-button-box'>
                <button className={'layout-popup-button double gray'} onClick={() => popupClose(true)}>
                  Cancel
                </button>
                <button className={`layout-popup-button double ${studentTarget.TARGET.length === 0 && 'disable'}`} onClick={() => targetSelectComplete()}>
                  {studentTarget.TYPE === 'screen' && <>Screen Sharing</>}
                  {studentTarget.TYPE === 'file' && <>File Transfer</>}
                  {studentTarget.TYPE === 'link' && <>Link Transfer</>}
                </button>
              </div>

            </div>
          }

          {popup.TYPE.includes('blendedTarget') &&
            <>
              <div className={'layout-popup-box command'}>
                <div className={'cmd-lp-top'}>
                  <div className='cmd-lp-close-box'>
                    <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose(true)} />
                  </div>
                  <div className='cmd-lp-cover'>
                    <img src={publicMethod.svgClear(OnlineClassIcon)} alt={'OnlineClassIcon'} draggable={false} />
                    <span>Video Class Invitation</span> Please select the target to invite.
                  </div>
                </div>

                <div className='cmd-lp-target-box'>
                  <div className='cmd-lp-tb-inner'>
                    {
                      targetMemberData.filter(item => item.division === 'student').map((target) => (
                        <div key={target.id} className='cmd-lp-tb-item' onClick={() => clickBlendedTarget(target)}>
                          <img src={usersInfoBox.find(u => u.id === target.id)?.isChecked ? publicMethod.svgClear(CheckedIcon) : publicMethod.svgClear(UncheckedIcon)} alt={usersInfoBox.find(u => u.id === target.id)?.isChecked ? 'checkbox' : 'uncheckbox'} draggable={false} />
                          <span>{target.number} {target.name}</span>
                        </div>
                      ))
                    }
                  </div>
                </div>

                <div className="cmd-lp-content-title">
                  Invitation Link Address
                </div>

                <div className="cmd-lp-content link" style={{ marginTop: '6px' }}>
                  <span className={'link-https other'} onClick={() => blendedState.publicUrl ? moveLink(blendedState?.publicUrl) : console.log('Error')}> {blendedState?.publicUrl}</span>
                  <img src={publicMethod.svgClear(CopyIcon)} alt={'CopyIcon'} draggable={false} onClick={() => blendedState.publicUrl ? copyLink(blendedState?.publicUrl) : console.log('Error')} />
                </div>

                <div className='layout-popup-button-box'>
                  <button className={'layout-popup-button double gray'} onClick={() => popupClose(true)}>
                    Cancel
                  </button>
                  <button className={`layout-popup-button double ${targetMemberData.filter(item => usersInfoBox.find(u => u.id === item.id)?.isChecked).length === 0 && 'disable'}`} onClick={targetMemberData.filter(item => usersInfoBox.find(u => u.id === item.id)?.isChecked).length > 0 ? () => targetSelectComplete() : undefined}>
                    Invite
                  </button>
                </div>

              </div>
              <div className={`layout-popup-toast ${copyToast && 'visible'}`}>
                The link has been copied.
              </div>
            </>
          }

        </div>
      }
    </>
  );

};

export default PopupTargetSelect;

import mqtt, { MqttClient } from 'mqtt';
import React from 'react';
import { ClassInfoInterface } from '@/hooks/services/checkIn';
import publicMethod from '@/utils/publicMethod';

export interface MqttConnectRequest {
    lectureId: string;
    clientId: string;

    setReceiveMessages: React.Dispatch<React.SetStateAction<MqttPrivateReceiveMessage[]>>;
    setMirrorOpenPermissionRequestMessages: React.Dispatch<React.SetStateAction<MqttMirrorOpenPermissionRequestMessage[]>>;
    setMirrorJoinPermissionRequestMessages: React.Dispatch<React.SetStateAction<MqttMirrorJoinPermissionRequestMessage[]>>;

    setPermissionRequestMessages: React.Dispatch<React.SetStateAction<MqttPermissionRequestMessage[]>>;
    setPermissionResponseMessages: React.Dispatch<React.SetStateAction<MqttPermissionResponseMessage[]>>;

    setBypassMessage: React.Dispatch<React.SetStateAction<MqttBypassMessageInterface[]>>;

    setClassMessages: React.Dispatch<React.SetStateAction<MqttClassMessageInterface[]>>;

    setMirrorManageMessage?: React.Dispatch<React.SetStateAction<MqttMirrorManageMessage[]>>;

    stateChangeFunction: (type: 'mqtt' | 'chat', state: boolean) => Promise<void>;
    mqttAccountInfo: { id: string; pwd: string; };
}

let processEnd = false;
let mqttClient: MqttClient | null = null;
let isReconnecting = false; // 재접속 상태를 관리하는 플래그
let mqttRetryAttempt = 0; // MQTT 재접속 시도 횟수를 추적
let reconnectTimer: NodeJS.Timeout | null = null; // 재연결 타이머

export const mqttConnect = (functionReq: MqttConnectRequest): MqttClient => {

    // alert('Mqtt Mirror 관련 인터페이스 재검토 필요');

    const brokerURL = `${process.env.REACT_APP_MQTT_SERVER}`;
    const userName = functionReq.mqttAccountInfo.id;
    const password = functionReq.mqttAccountInfo.pwd;

    const connectMqttClient = (): MqttClient => {
        processEnd = false;
        mqttClient = mqtt.connect(brokerURL, {
            username: userName,
            password: password,
            clientId: `${functionReq.clientId + publicMethod.generateRandomString()}`,
            keepalive: 10,
            connectTimeout: 30000,
            clean: true,
            protocolId: 'MQIsdp',
            protocolVersion: 3,
            reconnectPeriod: 0 // 자동 재접속 비활성화
        });

        mqttClient.on('connect', () => {
            isReconnecting = false; // 연결이 성공하면 재접속 상태를 초기화
            mqttRetryAttempt = 0; // 연결 성공 시 재접속 시도 횟수를 초기화
            if (reconnectTimer) {
                clearTimeout(reconnectTimer);
                reconnectTimer = null;
            }
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('LINK SCHOOL MQTT - Connected broker');
            }

            mqttClient!.subscribe(`topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`, (err) => {
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    if (err) {
                        console.log(`LINK SCHOOL MQTT - Subscription error : Private : ${JSON.stringify(err)}`);
                    } else {
                        console.log(`LINK SCHOOL MQTT - Subscription success : Private : topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`);
                    }
                }
            });
            mqttClient!.subscribe(`bypass-topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`, (err) => {
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    if (err) {
                        console.log(`LINK SCHOOL MQTT - Subscription error : Bypass Private : ${JSON.stringify(err)}`);
                    } else {
                        console.log('LINK SCHOOL MQTT - Subscription success : Bypass Private');
                    }
                }
            });
            mqttClient!.subscribe(`topic/lecture/${functionReq.lectureId}`, (err) => {
                if (err) {
                    functionReq.stateChangeFunction('mqtt', false);
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log(`LINK SCHOOL MQTT - Subscription error : Class : ${JSON.stringify(err)}`);
                    }
                } else {
                    functionReq.stateChangeFunction('mqtt', true);
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log('LINK SCHOOL MQTT - Subscription success : Class');
                    }
                }
            });
            if (functionReq.setMirrorManageMessage) {
                mqttClient!.subscribe(`topic/mirror/${functionReq.lectureId}`, (err) => {
                    if (err) {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log(`LINK SCHOOL MQTT - Subscription error : Mirror : ${JSON.stringify(err)}`);
                        }
                    } else {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL MQTT - Subscription success : Mirror');
                        }
                    }
                });
            }
        });

        mqttClient.on('message', (topic, message) => {
            try {
                if (topic === `topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`) {
                    const messageObj: MqttPermissionRequestMessage | MqttPermissionResponseMessage | MqttPrivateReceiveMessage | MqttMirrorOpenPermissionRequestMessage = JSON.parse(message.toString());
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log(`mqtt 받은 private 메시지 : ${message.toString()}`);
                    }
                    if (messageObj.eventType === 'TALK_LINK_PERMISSION' || messageObj.eventType === 'TALK_FILE_PERMISSION') {
                        functionReq.setPermissionRequestMessages((prevMessages: MqttPermissionRequestMessage[]) => [...prevMessages, messageObj]);
                    }
                    else if (messageObj.eventType === 'PERMISSION_RESPONSE') {
                        functionReq.setPermissionResponseMessages((prevMessages: MqttPermissionResponseMessage[]) => [...prevMessages, messageObj]);
                    }
                    else if (messageObj.eventType === 'TALK_LINK_SEND' || messageObj.eventType === 'TALK_MESSAGE_SEND' || messageObj.eventType === 'TALK_FILE_SEND') {
                        functionReq.setReceiveMessages((prevMessages: MqttPrivateReceiveMessage[]) => [...prevMessages, messageObj]);
                    }
                    else if (messageObj.eventType === 'MIRROR_OPEN_PERMISSION') {
                        functionReq.setMirrorOpenPermissionRequestMessages((prevMessages: MqttMirrorOpenPermissionRequestMessage[]) => [...prevMessages, messageObj]);
                    }
                    else if (messageObj.eventType === 'MIRROR_JOIN') {
                        functionReq.setMirrorJoinPermissionRequestMessages((prevMessages: MqttMirrorJoinPermissionRequestMessage[]) => [...prevMessages, messageObj]);
                    }
                }
                else if (topic === `bypass-topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`) {
                    const meesageObj: MqttBypassMessageInterface = JSON.parse(message.toString());
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log(`mqtt 받은 bypass 메시지 : ${message.toString()}`);
                    }
                    functionReq.setBypassMessage((prevMessages: MqttBypassMessageInterface[]) => [...prevMessages, meesageObj]);
                }
                else if (topic === `topic/lecture/${functionReq.lectureId}`) {
                    const messageObj: MqttClassMessageInterface = JSON.parse(message.toString());
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log(`mqtt 받은 class 메시지 : ${message.toString()}`);
                    }
                    functionReq.setClassMessages((prevMessages: MqttClassMessageInterface[]) => [...prevMessages, messageObj]);
                }
                else if (topic === `topic/mirror/${functionReq.lectureId}` && functionReq.setMirrorManageMessage) {
                    const messageObj: MqttMirrorManageMessage[] = JSON.parse(message.toString());
                    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                        console.log(`mqtt 받은 mirror 메시지 : ${message.toString()}`);
                    }
                    functionReq.setMirrorManageMessage(messageObj);
                }
            } catch (e: any) {
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    console.error(`LINK SCHOOL MQTT - Class Message : ${JSON.stringify(e.toString())}`);
                }
            }
        });

        // Handle disconnection events
        mqttClient.on('close', () => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.error('LINK SCHOOL MQTT - Disconnected from broker');
            }
            // 구독한 토픽에 대해 unsubscribe
            try {
                mqttClient!.unsubscribe(`topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`);
                mqttClient!.unsubscribe(`bypass-topic/lecture/${functionReq.lectureId}/${functionReq.clientId}`);
                mqttClient!.unsubscribe(`topic/lecture/${functionReq.lectureId}`);

                if (functionReq.setMirrorManageMessage) {
                    mqttClient!.unsubscribe(`topic/mirror/${functionReq.lectureId}`);
                }
            } catch(e: any) {
                console.log(e);
            }

            if (!isReconnecting && !processEnd) {
                isReconnecting = true;
                mqttRetryAttempt++;
                scheduleReconnect();
            } else {
                console.log('LINK SCHOOL MQTT REAL DISCONNECT');
            }
        });

        mqttClient.on('offline', () => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('LINK SCHOOL MQTT - Went offline');
            }
        });

        mqttClient.on('reconnect', () => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.error('LINK SCHOOL MQTT - Re Connect');
            }
        });

        return mqttClient;
    };

    const scheduleReconnect = () => {
        if (reconnectTimer) {
            clearTimeout(reconnectTimer);
        }
        const delay = Math.min(1000 * Math.pow(2, mqttRetryAttempt), 60000); // 최대 1분 지연
        reconnectTimer = setTimeout(() => {
            if (isReconnecting && mqttClient && !processEnd) {
                mqttClient.reconnect();
            }
        }, delay);
    };

    // 초기 연결 시도
    return connectMqttClient();
};

export const mqttDisconnect = (myMqttClient: MqttClient) => {
    if (myMqttClient) {
        processEnd = true;
        isReconnecting = false;
        mqttRetryAttempt = 0;
        if (reconnectTimer) {
            clearTimeout(reconnectTimer);
        }
        myMqttClient.end(true, () => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.error('LINK SCHOOL MQTT - Disconnected broker');
            }
            mqttClient = null;
        });
    }
};


/* 선생님 관리 미러링 대기포함 상태 */
export interface MqttMirrorManageMessage {
    id: string;
    roomNumber: number;
    createRoom: boolean;
    groupMirror: boolean;
    subGroupId: string;
    requestUserId: string;
    permissionUserId?: string;
    webRtcRoomId: number;
    lectureId: string;
    lectureTalk: string;
    createdAt: string;
    updatedAt: string;
    mirrorUsers: {
        id: number;
        userId: string;
        division: string;
        number?: number;
        name: string;
        mirrorType: 'NONE' | 'BOTH' | 'PUB' | 'SUB';
        state: 'WAIT' | 'JOIN' | 'REQ';
        sendJoinMessage: boolean;
        lectureTalk: string;
    }[]
}



/* 수업 공용메세지 인터페이스 */
type MqttClassMessageType = 'CHECK_IN' | 'CHECK_OUT_USER' | 'CHECK_IN_EXTENSION' | 'LOCKED' | 'GROUP_MODE' | 'USER_INFO_MIRROR' | 'CHECK_OUT_LECTURE';
export interface MqttClassMessageInterface {
    eventType: MqttClassMessageType
    checkInLecture: ClassInfoInterface
}



/* 요청메세지 인터페이스 */
type MqttPrivateMirrorJoinPermissionRequestMessageType = 'MIRROR_JOIN';
type MqttPrivateMirrorOpenPermissionRequestMessageType = 'MIRROR_OPEN_PERMISSION';
type MqttPrivatePermissionRequestMessageType = 'TALK_LINK_PERMISSION' | 'TALK_FILE_PERMISSION';
type MqttPrivatePermissionResponseMessageType = 'PERMISSION_RESPONSE';
type MqttPrivateMessageType = 'TALK_LINK_SEND' | 'TALK_MESSAGE_SEND' | 'TALK_FILE_SEND';

export interface MqttMirrorOpenPermissionRequestMessage {
    talkId: string; // 수업톡 Id
    eventType: MqttPrivateMirrorOpenPermissionRequestMessageType; // 이벤트 타입
    lectureId: string; // 수업 Id
    senderId: string; // 요청자/발신자 Id
    message: MqttMirrorOpenRequestData; // 요청메세지
}

export interface MqttMirrorJoinPermissionRequestMessage {
    talkId: string; // 수업톡 Id
    eventType: MqttPrivateMirrorJoinPermissionRequestMessageType; // 이벤트 타입
    lectureId: string; // 수업 Id
    senderId: string; // 요청자/발신자 Id
    message: MqttMirrorJoinRequestData; // 요청메세지
}



export interface MqttPermissionRequestMessage {
    talkId: string; // 수업톡 Id
    eventType: MqttPrivatePermissionRequestMessageType; // 이벤트 타입
    lectureId: string; // 수업 Id
    senderId: string; // 요청자/발신자 Id
    message: MqttMessageRequestData; // 요청메세지
}
export interface MqttPermissionResponseMessage {
    talkId: string; // 수업톡 Id
    eventType: MqttPrivatePermissionResponseMessageType; // 이벤트 타입
    lectureId: string; // 수업 Id
    senderId: string; // 요청자/발신자 Id
    message: MqttMessageResponseData; // 요청메세지
}
export interface MqttPrivateReceiveMessage {
    talkId: string; // 수업톡 Id
    eventType: MqttPrivateMessageType;
    lectureId: string; // 수업 Id
    senderId: string; // 요청자/발신자 Id
    message: MqttMessageFileData[] | string; // 파일정보 OR 링크 OR 메세지
}

interface MqttMessageFileData {
    fileName: string;
    fileSize: number;
    filePath: string;
    fileType: string;
}

interface MqttMirrorJoinRequestData {
    talkId: string;
    mirrorId: string;
    roomNumber: number;
    mirrorType: 'PUB' | 'SUB';
    mirrorUsers: {
        id: string;
        userId: string;
        division: string;
        number: number;
        name: string;
        mirrorType: 'PUB' | 'SUB';
        state: string;
        sendJoinMessage: boolean;
        lectureTalkId: string;
    }[]
}

interface MqttMirrorOpenRequestData {
    id: string;
    createRoom: boolean;
    groupMirror: boolean;
    subGroupId?: string;
    requestUserId: string;
    permissionUserId?: string;
    roomNumber?: number;
    createdAt: string;
    updatedAt: string;
    lectureId: string;
    lectureTalkId: string;
    mirrorUsers: {
        id: string;
        userId: string;
        division: 'student' | 'teacher' | 'board' | 'onequick';
        number?: number;
        name: string;
        mirrorType: 'PUB' | 'SUB';
        state: 'WAIT' | 'JOIN' | 'REQ';
        sendJoinMessage: boolean;
        lectureTalkId?: string;
    }[]
}

interface MqttMessageRequestData {
    requestId: string;
    requestName: string;
    requestMessage: string; // JSON 타입의 파일정보가 있을수도 있음
    rcvrInfoList: {
        userId: string;
        userName: string;
        division: 'student' | 'teacher' | 'board' | 'onequick';
        userNumber: number;
    }[];
}

interface MqttMessageResponseData {
    talkId: string;
    permission: 'APPROVED' | 'REJECTED' | 'PENDING';
    responseType?: 'MIRROR_JOIN';
    mirrorId?: string;
}

/* 수업 Bypass 수신 인터페이스 */
type MqttBypassMessageType = 'MONITOR_REQUEST' | 'MONITOR_RESPONSE' | 'STREAM_REQUEST';
export interface MqttBypassMessageInterface {
    type: MqttBypassMessageType;
    senderId: string;
    bypassData: string;
}

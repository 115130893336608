import { useEffect, useRef, useState } from 'react';

import videojs from 'video.js';

import { useRecoilState } from 'recoil';
import { videoScreenStore } from '@/recoil/store/myBox';




// 모니터링 상세화면 훅
export default () => {

  // 비디오 객체
  const videoRef = useRef<any>(null);
  // 플레이어 객체
  const playerRef = useRef<any>(null);

  // 모니터링 state
  const [videoScreen, setVideoScreen] = useRecoilState(videoScreenStore);
  // 모니터링 상세화면 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 마우스 Hover 여부
  const [isHovered, setIsHovered] = useState(false);



  // const VIDEO_URL = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
  const VIDEO_URL = `${process.env.REACT_APP_CDN_DIST}/${videoScreen.DATA.uid}/${videoScreen.DATA.uid}/playlist.m3u8`;
  // ${process.env.REACT_APP_ENV}


  // useEffect(() => {
  //     alert(VIDEO_URL);
  // }, []);

  // 비디오 옵션
  const videoOptions = {
    autoplay: true, // 자동 재생 여부
    controls: false, // 컨트롤러 여부
    responsive: true, // 반응형 여부
    playsinline: true, // 인라인 재생 여부
    loadingSpinner: false, // 로딩 스피너 여부
    fluid: true, // 비디오 크기에 맞게 조절 여부,
    muted: false, // 음소거 여부
    sources: [{ src: VIDEO_URL, type: 'application/x-mpegURL' }]
  };

  // 상세화면 끄기
  const videoClose = () => {
    setIsVisible(false);

    setTimeout(() => {
      handleVideoStateReset();
      setVideoScreen((prevData) => ({ ...prevData, OPEN: false }));
    }, 100);
    setTimeout(() => {
      setVideoScreen((prevData) => ({ ...prevData, ZOOM: false, DATA: {}, RESERVE: '' }));
    }, 200);
  };

  const handlePlayerReady = (player: any) => {
    let timer: NodeJS.Timeout | undefined;

    player.on('waiting', () => {
      videojs.log('player is waiting');
    });

    player.on('volumechange', () => {
      setVideoScreen(prev => ({ ...prev, VOLUME: player.volume() }));

      if (player.volume() === 0) {
        setVideoScreen(prev => ({ ...prev, MUTE: true }));
        player.muted(true);
      } else {
        setVideoScreen(prev => ({ ...prev, MUTE: false }));
        player.muted(false);
      }
    });

    player.on('playing', () => {
      clearTimeout(timer);

      videojs.log('player is going now');
      setVideoScreen(prev => ({ ...prev, PLAY: true, DURATION: player.duration() }));
    });

    player.on('pause', () => {
      clearTimeout(timer);

      videojs.log('player is paused');
      setVideoScreen(prev => ({ ...prev, PLAY: false }));
      setVideoScreen(prev => ({ ...prev, HIDE: false }));
    });

    player.on('ended', () => {
      videojs.log('player is ended');
      setVideoScreen(prev => ({ ...prev, PLAY: false, HIDE: false, OPEN: false }));

      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    });

    player.on('dispose', () => {
      videojs.log('player will dispose');
    });
  };

  const handleVideoStateReset = () => {
    setVideoScreen(prev => ({
      ...prev,
      PLAY: false,
      MUTE: false,
      ZOOM: false,
      VOLUME: 0.5,
      DURATION: 0,
      CURRENT_TIME: 0
    }));

    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }
  };

  useEffect(() => {
    if (videoScreen.OPEN) {
      if (!isVisible) {
        setTimeout(() => {
          setIsVisible(true);
        }, 100);
      }

      // 비디오 플레이어가 없는 경우에만 새로 생성
      if (!playerRef.current) {
        const videoElement = document.createElement('video-js');
        videoElement.classList.add('vjs-big-play-centered');
        videoRef.current?.appendChild(videoElement);

        // 새로운 비디오 플레이어 인스턴스 생성
        const player = videojs(videoElement, videoOptions, () => {
          console.log('동영상 로드 완료');
          handlePlayerReady(player);
        });

        playerRef.current = player;
      } else {
        console.log('동영상 시작');
        const player = playerRef.current;
        player.autoplay(true);
        player.src(VIDEO_URL);
        console.log('동영상 시작2');
      }
    }

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  // 마우스 및 터치 이벤트 처리
  useEffect(() => {
    let hideTimer: NodeJS.Timeout | null = null;

    if (isHovered) {
      // 마우스나 터치 이벤트 발생 시 호출되는 함수
      const handleUserActivity = () => {
        // 사용자 활동이 감지되면 HIDE를 false로 설정하여 보이게 함
        setVideoScreen((prev) => ({ ...prev, HIDE: false }));

        // 기존 타이머가 있으면 초기화
        if (hideTimer) {
          clearTimeout(hideTimer);
        }

        // 6초 후에 HIDE를 true로 설정하는 새로운 타이머 설정
        hideTimer = setTimeout(() => {
          setVideoScreen((prev) => ({ ...prev, HIDE: true }));
        }, 6000);
      };

      window.addEventListener('mousemove', handleUserActivity);
      window.addEventListener('touchstart', handleUserActivity);
      window.addEventListener('touchmove', handleUserActivity);

      return () => {
        if (hideTimer) {
          clearTimeout(hideTimer);
        }
        window.removeEventListener('mousemove', handleUserActivity);
        window.removeEventListener('touchstart', handleUserActivity);
        window.removeEventListener('touchmove', handleUserActivity);
      };
    } else {
      // 마우스가 호버되지 않은 경우 즉시 HIDE를 true로 설정
      setVideoScreen((prev) => ({ ...prev, HIDE: true }));
    }
  }, [isHovered]);

  // 키보드 이벤트
  useEffect(() => {
    // 비디오 컨트롤러를 사용할 때 playerRef.current가 유효한지 확인
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!playerRef.current) return; // playerRef.current가 유효한지 확인

      if (e.key === 'Escape') {
        videoClose();
        handleVideoStateReset();
      } else if (e.code === 'Space') {
        if (videoScreen.PLAY) {
          playerRef.current.pause();
        } else {
          playerRef.current.play();
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [videoScreen, playerRef]);


  return { videoRef, playerRef, videoScreen, setVideoScreen, isVisible, setIsHovered, videoClose, handleVideoStateReset };
};

import './PageContainer.css';
import React, { ReactNode, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { deviceListStore } from '@/recoil/store/checkIn';
import { getSession } from '@/hooks/common';
import publicMethod from '@/utils/publicMethod';
import SignageLNB from '@/components/page/signage/common/SignageLNB';
import PopupAlertSignage from '@/components/popup/signage/popupAlertSignage';
import PopupErrorSignage from '@/components/popup/signage/popupErrorSignage';
import PopupEnvironmentSignage from '@/components/popup/signage/popupEnvironmentSignage';


/* ———————— 사이니지 Props 설정  ———————— */
interface PageContainerProps {
  children: ReactNode;
}

/* ———————— 사이니지 페이짘 컨테이너  ———————— */
const PageContainer = ({ children }: PageContainerProps) => {


  /* —————————————————————————————————————————— */
  /* ———————————————— 변수 설정  ———————————————— */
  /* —————————————————————————————————————————— */

  // 경로 파악
  const location = useLocation();
  const locationParts = location.pathname.split('/');
  const locationPage = locationParts[1];
  // 페이지 이동
  const navigate = useNavigate();
  // 세션 정보
  const { sessionBaseInfo } = getSession();
  // 디바이스 목록
  const deviceList = useRecoilValue(deviceListStore);
  // 현재 시간
  const [currentDate, setCurrentDate] = useState(new Date());


  /* —————————————————————————————————————————— */
  /* ——————————————— useEffect  ——————————————— */
  /* —————————————————————————————————————————— */

  /* 최초 접근 시*/
  useEffect(() => {
    // 매 초마다 현재 시간을 업데이트
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 1000);
    // 컴포넌트가 언마운트될 때 clearInterval로 인터벌 해제
    return () => clearInterval(intervalId);
  }, []);



  return (

    <div className='container-app-class'>



      {/* 에러 팝업 */}
      <PopupErrorSignage />

      {/* 교실환경 알림 팝업 */}
      <PopupEnvironmentSignage />

      {/* 알림 팝업 */}
      <PopupAlertSignage />



      <div className='container-app-time' >
        <span>{publicMethod.formatDateSignage(currentDate)}</span>
        <span>{publicMethod.formatTimeSignage(currentDate)}</span>
      </div>
      <div className='container-app-page'>
        {children}
      </div>
      <SignageLNB deviceList={deviceList} sessionBaseInfo={sessionBaseInfo} locationPage={locationPage} navigate={navigate} />


    </div>
  );


};

export default PageContainer;

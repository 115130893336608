import { contentApi } from '@/services/network';
import { getCodeList } from '@/services/content/code/getCodeList';
import publicMethod from '@/utils/publicMethod';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';
import { MusicCategoryListInterface } from '@/recoil/store/signage';

/* ############################################
 * ############# 음원 카테고리 목록조회 #############
 * ############################################ */


/* function interface */
export interface FunctionReq {
    token: string
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: MusicCategoryListInterface[]
}


export const getCategoryList = async(request: FunctionReq): Promise<FunctionRes> => {

    const soundTypeList = await getCodeList({ token: request.token, type: 'SOUND' });

    const code = ContentMappingCode[soundTypeList.code as ContentResponseCode] || 'FAIL';

    if (soundTypeList.code === 'OK' && soundTypeList.data) {

        const musicCategory: MusicCategoryListInterface[] = soundTypeList.data.map((item) => {
            return {
                id: item.id,
                code: item.code1,
                value: item.value1
            };
        });

        return {
            code,
            status: 200,
            message: '음원 카테고리 조회 성공',
            data: musicCategory
        };
    }
    else if (soundTypeList.code === ContentResponseCode.UNAUTHORIZED) {
        return {
            code,
            status: 401,
            message: '토큰 만료',
            data: []
        };
    }
    return {
        code,
        status: 410,
        message: '음원 카테고리 조회 실패',
        data: []
    };





};

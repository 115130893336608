import '@/components/container/service/full/Container.css';
import React, { ReactNode } from 'react';
import { useRecoilValue } from 'recoil';
import { NetworkStatusStore } from '@/recoil/store/common';
import PageNetwork from '@/components/page/service/common/error/PageNetwork';
import PopupError from '@/components/popup/common/popupError';


interface AdminContainerProps{
    children: ReactNode;
}


/* ———————— 관리자 컨테이너  ———————— */
const AdminContainer = ({ children }: AdminContainerProps) => {

    // 네트워크 상태
    const networkStatus = useRecoilValue(NetworkStatusStore);

    return (
        <div className={'container-admin'}>

            {/* z index 600 */}
            {/* 서버에러 팝업 */}
            <PopupError/>

            {
                !networkStatus &&
                <div className='container-network-error auth'>
                    <PageNetwork/>
                </div>
            }
            {children}
        </div>
    );

};

export default AdminContainer;

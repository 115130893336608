import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* ##################################
 * ############# 약관조회 #############
 * ################################## */


/* function interface */
export interface FunctionReq {
    /*
     * PRIVACY_POLICY : 개인정보 수집 및 이용 동의
     * OPENSOURCE_LICENSE : 오픈소스 라이선스
     * SERVICE_TERM : 서비스 이용약관
     * PRIVACY_POLICY_BLENDED : 개인정보 수집 및 이용 동의(블렌디드)
     * PRIVACY_NOTICE : 개인정보처리방침
     * PRIVACY_POLICY_2 : 개인정보 수집 및 이용 동의(법정대리인)
     */
    classificationCode: 'PRIVACY_POLICY' | 'OPENSOURCE_LICENSE' | 'SERVICE_TERM' | 'PRIVACY_POLICY_BLENDED' | 'PRIVACY_NOTICE' | 'PRIVACY_POLICY_2'
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: {
        createdBy: string
        createdById: string
        createdDate: string
        lastModifiedBy: string
        lastModifiedById: string
        lastModifiedDate: string
        id: number
        classificationCode: string
        version: string
        releaseStatus: string
        releaseType: string
        link: string
        content: string
    }
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
    data: {
        createdBy: string
        createdById: string
        createdDate: string
        lastModifiedBy: string
        lastModifiedById: string
        lastModifiedDate: string
        id: number
        classificationCode: string
        version: string
        releaseStatus: string
        releaseType: string
        link: string
        content: string
    }
}



export const getList = async(request: FunctionReq): Promise<FunctionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/external/policy/terms?classificationCode=${ request.classificationCode }`;
    const method = 'GET';

    const res = await contentApi<any, ApiRes>(endPoint, {}, method, null, null);

    const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

    if (res.code === ContentResponseCode.OK) {
        return {
            code,
            status: res.status,
            message: res.message,
            data: res.data ? res.data : []
        };
    }
    return {
        code,
        status: res.status,
        message: res.message,
        data: {
            createdBy: '',
            createdById: '',
            createdDate: '',
            lastModifiedBy: '',
            lastModifiedById: '',
            lastModifiedDate: '',
            id: 0,
            classificationCode: '',
            version: '',
            releaseStatus: '',
            releaseType: '',
            link: '',
            content: ''
        }
    };
};

import { Outlet } from 'react-router-dom';

/**  외부인증 레이아웃  **/
const ExternalLayout = () => {
    return (
        <div className="AuthRoot">
            <Outlet/>
        </div>
    );
};

export default ExternalLayout;

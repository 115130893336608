import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupErrorStore, popupStore } from '@/recoil/store/popup';
import {
    changeSchoolInfoStore,
    departmentListStore,
    RegisterDetailInfo,
    registerDetailStore
} from '@/recoil/store/auth';
import { ChangeEvent, useEffect, useState } from 'react';
import { internalsSchool, SchoolListInterface } from '@/services/core/schools/internalsSchool';

/* 학교검색 팝업 HOOK */
export default() => {

    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 입력값 설정
    const [inputs, setInputs] = useState({ searchName: '' });
    // 선택한 학교 정보
    const [schoolInfo, setSchoolInfo] = useState({ school_id: '', school_name: '', school_type: '' });
    // 입력값 설정
    const [schoolList, setSchoolList] = useState<SchoolListInterface[]>([]);
    // 검색 실패 시, 상태값
    const [searchTextError, setSearchTextError] = useState(false);
    // 회원가입 state
    const setRegisterDetail = useSetRecoilState(registerDetailStore);
    const setDepartmentList = useSetRecoilState(departmentListStore);
    // 1번이라도 검색한 이력이 있는가
    const [searchHistory, setSearchHistory] = useState(false);
    // 변경할 학교 정보
    const [changeSchoolInfo, setChangeSchoolInfo] = useRecoilState(changeSchoolInfoStore);
    // 팝업 에러 state
    const setPopupError = useSetRecoilState(popupErrorStore);

    /** 팝업 노출 시키기**/



    useEffect(() => {
        if(popup.TYPE === 'schoolSearch'){
            setTimeout(() => {
                setIsVisible(true);

                if(changeSchoolInfo){
                    setSchoolInfo({ school_id: changeSchoolInfo.schoolId, school_name: changeSchoolInfo.schoolName, school_type: '' });
                }
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popup.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
        setTimeout(() => {
            setInputs({ searchName: '' });
            setSchoolInfo({ school_id: '', school_name: '', school_type: '' });
            setSchoolList([]);
            setSearchTextError(false);
            setSearchHistory(false);
        }, 200);
    };

    // 검색 실행
    const runSearch = async () => {

        if(inputs.searchName === ''){
            setSearchTextError(true);
        }else{

            /* @@@@@@@@@@ 학교 검색 API @@@@@@@@@ */
            /* 어차피 백엔드에서 조회하여 나올 것이기 때문에 따로 검색필터는 걸지 않음 */
            const apiRes = await internalsSchool({ schoolName: inputs.searchName });

            if (apiRes.data) {
                setSchoolList(apiRes.data);
                setSearchHistory(true);
            } else {
                // 서버 에러 팝업
                setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
            }

        }
    };

    // 입력값 설정
    const handleInputs = (event : ChangeEvent<HTMLInputElement>) => {

        const { name, value } = event.target;
        // 영문, 한글, 숫자, 특수문자 입력 가능 (공백 입력 불가)
        const isValidInput = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]*$/.test(value);

        if (value.length <= 20 && isValidInput) { // 입력값이 20자 이하일 경우에만 상태 업데이트
            setInputs((prevData: any) => ({ ...prevData, [name]: value }));
        }
    };

    // 엔터 입력 시
    const handleKeyPress = (event : any) => {
        if (event.key === 'Enter') {
            runSearch();
        }
    };

    // 학교 아이템 클릭 시
    const clickSchoolItem = (schoolInfo : any) => {
        setDepartmentList(schoolInfo.departments ? schoolInfo.departments : []);
        setSchoolInfo({ school_id: schoolInfo.id, school_name: schoolInfo.name, school_type: schoolInfo.schoolType });
    };

    // 학교 선택완료 시
    const selectSchoolInfo = () => {

        if(popup.RESERVE === 'change'){ // 재직/재학 정보 변경으로부터 넘어왔을 경우
            setChangeSchoolInfo({ schoolId: schoolInfo.school_id, schoolName: schoolInfo.school_name });
        }else{ // 회원가입으로부터 넘어왔을 경우
            // 학교 아이디, 이름 저장
            setRegisterSingleData(5, 'schoolId', schoolInfo.school_id);
            setRegisterSingleData(5, 'schoolName', schoolInfo.school_name);
            setRegisterSingleData(5, 'schoolType', schoolInfo.school_type);
        }

        // 팝업 종료
        popupClose();
    };

    // 회원가입 상세 데이터 설정
    const setRegisterSingleData = (stepNumber: number, dataType: string, dataValue: boolean | string | number) => {

        setRegisterDetail((prevData: RegisterDetailInfo) => {

            if(dataType === 'step' ||  dataType === 'type'){
                return { ...prevData,  [dataType]: dataValue };
            }
            let updatedData: RegisterDetailInfo = { ...prevData };
            // @ts-ignore
            updatedData[`step${stepNumber}`] = { ...prevData[`step${stepNumber}`], [dataType]: dataValue };
            return updatedData;

        });
    };


    return { popup, popupClose, inputs, schoolInfo,  schoolList,  searchTextError, searchHistory, runSearch, handleInputs, handleKeyPress, clickSchoolItem, selectSchoolInfo, isVisible };
};

import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ###################################
 * ############# 세션 연장 #############
 * ################################### */





/* function interface */
export interface FunctionReq {
  token: string
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  code: string            // core 반환 구분값
  message: string         // core 반환 구분값 desc
}



export const sessionExtend = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/users/me/extend-session`;
  const method = 'POST';

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to extend login.';

  return {
    message,
    errorCode,
    errorMessage
  };
};

import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ####################################
 * ############# 2차인증요청 #############
 * #################################### */


/* function interface */
export interface FunctionReq {
  type: 'EMAIL' | 'PHONE'
  checkType: 'make' | 'update'
  username?: string
  email?: string
  phone?: string
}

export interface FunctionRes {
  message: string         // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
  data?: {
    requestId: string
    requestedTo: string
    method: string
    retryIn: number
    retryAvailableAt: string
    expiredAt: string
    expiredIn: string
  }
}


/* Api Interface */
interface ApiRes {
  status: number,
  data?: {
    requestId: string
    requestedTo: string
    method: string
    retryIn: number
    retryAvailableAt: string
    expiredAt: string
    expiredIn: string
  }
  code: string
  message: string
}

interface ApiReq {
  type: string
  checkType: 'make' | 'update'
  username?: string,
  email?: string
  phone?: string
}



export const requestCode = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/auth/request-code`;
  const method = 'POST';

  const requestBody: ApiReq = {
    type: request.type,
    checkType: request.checkType
  };
  if (request.username) requestBody.username = request.username;
  if (request.email) requestBody.email = request.email;
  if (request.phone) requestBody.phone = request.phone;


  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, requestBody, method, null, null);

  let message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to send the verification code.';

  if (res.data && res.code === CoreResponseCode.CODE_SEND_SUCCESS) {
    return {
      message,
      errorCode,
      errorMessage,
      data: {
        requestId: res.data.requestId,
        requestedTo: res.data.requestedTo,
        method: res.data.method,
        retryIn: res.data.retryIn,
        retryAvailableAt: res.data.retryAvailableAt,
        expiredAt: res.data.expiredAt,
        expiredIn: res.data.expiredIn
      }
    };
  }
  return {
    message,
    errorCode,
    errorMessage
  };

};

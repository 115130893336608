import { coreApi } from '@/services/network';
import { downloadLogoFile } from '@/services/content/core/downloadLogoFile';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##################################
 * ############# 회원정보 #############
 * ################################## */

/* function interface */
export interface FrontSessionBaseInfo {
  ipAddress: string                       // IP 주소
  currentSemester?: {                     // 현재 학기
    endDate: string
    semester: number
    startDate: string
    numberOfWeek: { week: number, year: number }[]
  } | null
  currentWeek?: number | null             // 현재 주차
  checkInSession?: {                      // 체크인 관련
    classId?: string                    // 교실 id
    checkIn: boolean                    // 체크인 여부
    checkOutAt: string | null           // 체크아웃 시간
    checkInAt: string | null            // 체크인 시간
  }
  baseInfo: {
    role: string                        // 역할
    email: string                       // 이메일
    phone: string                       // 핸드폰번호
    name: string                        // 이름
    username: string                    // 아이디
    chatroomIds: string[]               // 채팅방 id
    authRejectReason: string | null     // 반려 사유
    neisId: string                      // neis id
    schoolId: string                    // 학교 id
    status: string                      // 사용자 상태 (pending: 대기 approved: 승인 rejected: 거절 moving_out: 전출 graduated: 졸업 deleted: 삭제 withdrawn: 탈퇴)
    userId: string                      // 사용자 UUID
    auth: boolean                       // 승인여부
    signupMethod?: string                // internal: 일반 | google: 구글 | whalespace: 웨일
  }
  mappingClass?: {
    classId: string                     // 교실 id
    userClassId?: string                // 수업 식별 id ( 디바이스는 없음 )
    className: string                   // 교실명
    grade: string                       // 학년
    department?: string                // 학과
    classificationType?: string         // homeroom(담임) | assistant(부담임) | subject(교과교사) | student(학생)
    classType: string
  }[]
  schoolInfo?: {
    classId: string                     // 메인 수업 id
    userClassId: string                 // 메인 교실 id
    schoolClassification: 'General' | 'School'  // 학교/비학교 구분
    classification: string               // 사용자 분류
    classificationType: string[]         // 가입시 입력한 사용자 세부분류
    className: string                   // 교실명
    departmentName: string              // 학과정보
    grade: string                       // 학년
    schoolName: string                  // 학교명
    schoolId: string                    // 학교 id
    number: string | null               // 학생번호
    logoList?: {
      logoType: 'wlogo' | 'hlogo'     // 가로형 | 세로형
      base64Image: string
    }[]
  }
  mqtt: {
    id: string;
    pwd: string;
  }
  welcomeSession: string                  // 환영메시지
  accessTime: {                           // 로그인 시간
    app?: string
    google?: string
    whalespace?: string
  }
  teacherClassSetting?: string            // {\"groupSetting\":{},\"AIoTSetting\":{}, \"checkInInfo\":{}} 모둠, AIoT 셋팅정보, 교실 상태정보 JSON
  callbackId?: string
  username?: string
}

export interface functionReq {
  accessToken: string
}

export interface functionRes {
  message: string        // 성공(SUCCESS)/2차인증(VERIFY)/중복로그인(ALREADY)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  code: string
  data?: FrontSessionBaseInfo
}



/* Api Interface */
interface apiRes {
  status: number,
  data: {
    ipAddress: string                       // IP 주소
    currentSemester?: {                     // 현재 학기
      endDate: string
      semester: number
      startDate: string
      numberOfWeek: { week: number, year: number }[]
    } | null
    currentWeek?: number | null             // 현재 주차
    checkInSession?: {                      // 체크인 관련
      classId?: string                    // 교실 id
      checkIn: boolean                    // 체크인 여부
      checkOutAt: string | null           // 체크아웃 시간
      checkInAt: string | null            // 체크인 시간
    }
    baseInfo: {
      role: string                        // 역할
      email: string                       // 이메일
      phone: string                       // 핸드폰번호
      name: string                        // 이름
      username: string                    // 아이디
      chatroomIds: string[]               // 채팅방 id
      authRejectReason: string | null     // 반려 사유
      neisId: string                      // neis id
      schoolId: string                    // 학교 id
      status: string                      // 사용자 상태
      userId: string                      // 사용자 UUID
      auth: boolean                       // 승인여부
      signupMethod: string                // internal: 일반 | google: 구글 | whalespace: 웨일
    }
    mappingClass?: {
      classId: string                     // 수업 id
      userClassId: string                 // 교실 id
      className: string                   // 교실명
      grade: string                       // 학년
      department: string
      classificationType: string
      classType: string
    }[]
    schoolInfo?: {
      classId: string                     // 메인 수업 id
      userClassId: string                 // 메인 교실 id
      schoolClassification: 'General' | 'School'  // 학교/비학교 구분
      classification: string               // 사용자 분류
      classificationType: string[]         // 가입시 입력한 사용자 세부분류
      className: string                   // 교실명
      departmentName: string              // 학과정보
      grade: string                       // 학년
      schoolName: string                  // 학교명
      schoolId: string                    // 학교 id
      number: string | null               // 학생번호
      logoList?: {
        logoType: 'wlogo' | 'hlogo'     // 가로형 | 세로형
        base64Image: string
      }[]
    }
    mqtt: {
      id: string;
      pwd: string;
    }
    welcomeSession: string                  // 환영메시지
    accessTime: {                           // 로그인 시간
      app?: string
      google?: string
      whalespace?: string
    }
    teacherClassSetting?: string            // {\"groupSetting\":{},\"AIoTSetting\":{}, \"checkInInfo\":{}} 모둠, AIoT 셋팅정보, 교실 상태정보 JSON
    callbackId?: string
    username?: string
  }
  code: string
  message: string
}




export const getProfile = async (request: functionReq): Promise<functionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/users/me`;
  const method = 'GET';

  const res: apiRes = await coreApi<{}, apiRes>(endPoint, {}, method, request.accessToken, null);

  let message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve profile.';

  if (res.code === CoreResponseCode.GET_PROFILE_SUCCESS) {
    try {
      if (res.data.schoolInfo?.classification === 'faculty' && res.data.schoolInfo.classificationType.includes('external')) {
        res.data.schoolInfo.classification = 'teacher';
      }
      if (res.data.schoolInfo?.schoolId) {
        const getLogoFile = await downloadLogoFile({ token: request.accessToken, schoolId: res.data.schoolInfo?.schoolId });
        if (getLogoFile.code === 'OK' && getLogoFile.data) {
          if (getLogoFile.data.length > 0) {
            res.data.schoolInfo.logoList = getLogoFile.data.map(item => {
              return {
                logoType: item.logoType,
                base64Image: item.base64Image
              };
            });
          }
        }
      }
    } catch (e: any) {
      console.log(e);
    }
    return {
      message,
      errorCode,
      errorMessage,
      code: message,
      data: {
        ...res.data,
        mappingClass: res.data.mappingClass?.map(item => {
          return {
            classId: item.classId,
            userClassId: item.userClassId,
            className: item.className,
            grade: item.grade,
            department: item.department,
            classificationType: item.classificationType,
            classType: item.classType
          };
        })
          .sort((a, b) => {
            // classificationType이 "classroom"인 항목을 먼저
            if (a.classificationType === 'classroom' && b.classificationType !== 'classroom') {
              return -1;
            }
            if (a.classificationType !== 'classroom' && b.classificationType === 'classroom') {
              return 1;
            }

            // grade를 문자열 숫자로 변환하여 오름차순 정렬
            if (parseInt(a.grade) !== parseInt(b.grade)) {
              return parseInt(a.grade) - parseInt(b.grade);
            }

            // className에서 숫자 부분을 추출
            const extractNumber = (className: any) => {
              const match = className.match(/\d+/); // 숫자를 추출
              return match ? parseInt(match[0]) : null; // 숫자가 없으면 null 처리
            };

            const classNameA = extractNumber(a.className);
            const classNameB = extractNumber(b.className);

            // 숫자가 둘 다 없을 때, 문자열 비교
            if (classNameA === null && classNameB === null) {
              return a.className.localeCompare(b.className);
            }

            // 하나만 숫자가 없는 경우, 숫자가 있는 것이 더 앞에 오도록 정렬
            if (classNameA === null) return 1;
            if (classNameB === null) return -1;

            // 둘 다 숫자가 있는 경우, 숫자 기준으로 정렬
            return classNameA - classNameB;
          })
      }
    };
  }
  else if (res.code === CoreResponseCode.PASSWORD_CHANGE_REQUIRE || res.code === CoreResponseCode.RESET_PASSWORD_LOGIN) {
    return {
      message,
      errorCode,
      errorMessage,
      code: message,
      data: res.data
    };
  }
  return {
    message,
    errorCode,
    errorMessage,
    code: message
  };

};

import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* #######################################
 * ############# 요청메세지 전송 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
  token: string;
  lectureId: string;
  permission: boolean;
  permissionUserId?: string;
  groupMirror: boolean;
  subGroupId?: string;
  pubUsers: {
    userId: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    number?: number;
    name: string;
  }[];
  subUsers: {
    userId: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    number?: number;
    name: string;
  }[];
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
  data?: {
    mirrorId: string;
    lectureTalkId?: string;
  };
}


/* Api Interface */
interface ApiReq {
  permission: boolean;
  permissionUserId?: string;
  groupMirror: boolean;
  subGroupId?: string;
  pubUsers: {
    userId: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    number?: number;
    name: string;
  }[];
  subUsers: {
    userId: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    number?: number;
    name: string;
  }[];
}

interface ApiRes {
  status: number,         // status
  data?: {
    mirrorId: string;
    lectureTalkId?: string;
  };
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const sendOpenPermission = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/mirror/open/${request.lectureId}`;
  const method = 'POST';

  const req: ApiReq = {
    permission: request.permission,
    groupMirror: request.groupMirror,
    pubUsers: request.pubUsers,
    subUsers: request.subUsers
  };
  if (request.permissionUserId) {
    req.permissionUserId = request.permissionUserId;
  }
  if (request.subGroupId) {
    req.subGroupId = request.subGroupId;
  }

  const res: ApiRes = await statusApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to process the request.';


  if (res.code === 'OK' && res.data) {

    return {
      message,
      errorCode,
      errorMessage: 'N/A',
      code: res.code,
      data: res.data
    };
  }
  return {
    errorCode,
    errorMessage,
    code: res.label,
    message
  };
};

import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupErrorStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { boardDetailScreenStore } from '@/recoil/store/myBox';
import { getChatToken } from '@/services/core/me/getChatToken';
import publicMethod from '@/utils/publicMethod';
import { chatServerFileDownload, chatServerFileDownloadS3 } from '@/services/chat/chatServerFileDownload';
import { saveAs } from 'file-saver';
import { getSession } from '@/hooks/common';
import { forceLogoutStore, userTokenInfoStore } from '@/recoil/store/user';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import { useNavigate } from 'react-router-dom';




// 전자칠판 캡처 상세 훅
export default () => {



  // 페이지 이동
  const navigate = useNavigate();
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);
  // 전자칠판 이미지 state
  const [boardDetailScreen, setBoardDetailScreen] = useRecoilState(boardDetailScreenStore);
  // 전자칠판 이미지 상세화면 노출 여부
  const [isVisible, setIsVisible] = useState(false);

  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  const setUserTokenInfo = useSetRecoilState(userTokenInfoStore);                                                       // Core, Chat Token 관리
  const setForceLogout = useSetRecoilState(forceLogoutStore);                                                           // 강제로그아웃
  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
  const [imageSrc, setImageSrc] = useState('');

  /** 상세화면 노출 시키기**/
  useEffect(() => {
    if (boardDetailScreen.OPEN) {
      // blob 데이터 이미지화
      if (boardDetailScreen.DATA) {
        makeImageBase64();
      }
      // 팝업 노출
      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  }, [boardDetailScreen]);



  // 상세화면 끄기
  const boardDetailClose = () => {
    setIsVisible(false);
    setImageSrc('');
    setTimeout(() => {
      setBoardDetailScreen((prevData) => ({ ...prevData, OPEN: false }));
    }, 100);
    setTimeout(() => {
      setBoardDetailScreen((prevData) => ({ ...prevData, DATA: null, RESERVE: '' }));
    }, 200);
  };

  // 파일 이미지화
  const makeImageBase64 = async () => {

    const ctApiRes = await getChatToken({ id: sessionBaseInfo?.baseInfo.userId!, accessToken: sessionTokenInfo.coreAccessToken! });

    if (ctApiRes.message === 'SUCCESS' && ctApiRes.data) {
      setLoginSessionCount(publicMethod.sessionUpdate);
      setUserTokenInfo({
        coreAccessToken: sessionTokenInfo.coreAccessToken,
        chatAccessToken: ctApiRes.data.accessToken,
        chatRefreshToken: ctApiRes.data.refreshToken
      });
    }
    else if (ctApiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
      return;
    }
    else if (ctApiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
      return;
    }
    else if (ctApiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      return;
    } else {
      // 서버 에러 팝업
      setPopupError(prevData => [...prevData, { CODE: ctApiRes.errorCode, MESSAGE: ctApiRes.errorMessage }]);
      return;
    }

    if (boardDetailScreen.DATA?.fileUrl) {

      let blob;
      if (sessionBaseInfo?.baseInfo.role === 'board') {
        blob = await chatServerFileDownloadS3({ token: ctApiRes.data?.accessToken, fileUrl: boardDetailScreen.DATA?.fileUrl });
      }
      else {
        blob = await chatServerFileDownload({ token: ctApiRes.data?.accessToken, fileUrl: boardDetailScreen.DATA?.fileUrl });
      }

      if (blob.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      } else {
        // Blob URL 생성
        setImageSrc(URL.createObjectURL(blob));
      }
    }



  };


  // 파일 다운로드
  const downloadFiles = async (fileInfo: { fileUrl: string, fileName: string, fileFullName: string }[]) => {

    const ctApiRes = await getChatToken({ id: sessionBaseInfo?.baseInfo.userId!, accessToken: sessionTokenInfo.coreAccessToken! });

    if (ctApiRes.message === 'SUCCESS' && ctApiRes.data) {
      setLoginSessionCount(publicMethod.sessionUpdate);
      setUserTokenInfo({
        coreAccessToken: sessionTokenInfo.coreAccessToken,
        chatAccessToken: ctApiRes.data.accessToken,
        chatRefreshToken: ctApiRes.data.refreshToken
      });
    }
    else if (ctApiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
      return;
    }
    else if (ctApiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
      return;
    }
    else if (ctApiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      return;
    } else {
      // 서버 에러 팝업
      setPopupError(prevData => [...prevData, { CODE: ctApiRes.errorCode, MESSAGE: ctApiRes.errorMessage }]);
      return;
    }


    let blob;
    if (sessionBaseInfo?.baseInfo.role === 'board') {
      blob = await chatServerFileDownloadS3({ token: ctApiRes.data?.accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    else {
      blob = await chatServerFileDownload({ token: ctApiRes.data?.accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    if (blob.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      // Create a new File object from the Blob
      const file = new File([blob], fileInfo[0].fileFullName, { type: blob.type });
      window.MirrorBLOBDownloadFileName(fileInfo[0].fileFullName);
      saveAs(file, fileInfo[0].fileFullName);
    }

  };




  return { boardDetailScreen, isVisible, boardDetailClose, downloadFiles, imageSrc };
};

import { chatApi } from '@/services/network';
import { ChatMappingCode, ChatResponseCode, StreamMappingCode, StreamResponseCode } from '@/utils/code-enums';

/* ###################################
 * ############# 토큰재발급 #############
 * ################################### */

/* function interface */
export interface FunctionReq {
    accessToken: string
    refreshToken: string
}
export interface FunctionRes {
    message: string
    accessToken?: string
    refreshToken?: string
}




/* Api Interface */
interface ApiReq {
    access_token: string
    refresh_token: string
}

interface ApiRes {
    status: number,
    code: string
    message: string
    grant_type?: string
    access_token?: string
    refresh_token?: string
    refresh_token_expiration_time?: string
}




export const reissue = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/user/reissue`;
    const method = 'POST';

    const requestBody: ApiReq = {
        access_token: request.accessToken,
        refresh_token: request.refreshToken
    };

    const res:ApiRes = await chatApi<ApiReq, ApiRes>(endPoint, requestBody, method, request.accessToken, null);

    const message = ChatMappingCode[res.code as ChatResponseCode] || 'FAIL';

    if (res.status === 200) {
        if (res.code === ChatResponseCode.SUCCESS) {
            return {
                message,
                accessToken: res.access_token,
                refreshToken: res.refresh_token
            };
        }
    }
    return { message };

};

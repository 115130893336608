import React from 'react';
import '@/components/popup/popup.css';
import popupErrorSignageHook from '@/hooks/components/popup/signage/popupErrorSignage';




/* 사이니지 에러 팝업 */
const PopupErrorSignage = () => {

  const { popupError, popupClose, isVisible } = popupErrorSignageHook();

  return (

    <>
      {popupError.length > 0 &&
        <div className={`layout-popup signage error ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box signage alert'}>
            <article className={'layout-popup-content'}>
              <span style={{ fontSize: '32px', marginBottom: '10px' }}>{popupError[0].MESSAGE}</span>
              <span style={{ fontSize: '32px', marginBottom: '10px' }}>[{popupError[0].CODE}]</span>
            </article>

            <button className={'layout-popup-button signage'} onClick={() => popupClose(popupError.length - 1)}>
              OK
            </button>
          </div>
        </div>
      }
    </>




  );
};

export default PopupErrorSignage;

/* eslint-disable react-hooks/rules-of-hooks */
import { userTokenInfoStore, userBaseInfoStore } from '@/recoil/store/user';
import { useRecoilValue } from 'recoil';
import { deviceInfoStore } from '@/recoil/store/common';

/* 세션체크 */
export const getSession = () => {
  const sessionTokenInfo = useRecoilValue(userTokenInfoStore);
  const sessionBaseInfo = useRecoilValue(userBaseInfoStore);
  const deviceInfo = useRecoilValue(deviceInfoStore);

  return {
    sessionTokenInfo,
    sessionBaseInfo,
    deviceInfo
  };
};
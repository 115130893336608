import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* #########################################
 * ############# 교실 학생목록 조회 #############
 * ######################################### */





/* function interface */
export interface FunctionReq {
  token: string
  classId?: string
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  data?: MemberInfoInterface[]
}

export interface MemberInfoInterface {
  class: string
  division: string
  email: string
  grade: string
  id: string
  lastVisited: string
  name: string
  number: string
  phone: string
  status: string
}

/* Api Interface */
interface ApiRes {
  status: number          // status
  message: string
  code: string
  data?: { subjectInList: MemberInfoInterface[] }
}



export const getClassMember = async (request: FunctionReq): Promise<FunctionRes> => {
  let endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/schools/class/member`;
  const method = 'GET';

  if (request.classId) {
    endPoint += `?classId=${request.classId}`;
  }

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint, {}, method, request.token);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve the information.';

  if (res.code === CoreResponseCode.GET_SUBJECT_MEMEBER_SUCCESS && res.data) {
    const userList = res.data.subjectInList.filter((item) => item.division === 'student');
    const boardList = res.data.subjectInList
      .filter((item) => item.division === 'OPS')
      .map((item) => ({ ...item, division: 'board' }));
    const oneQuickList1 = res.data.subjectInList
      .filter((item) => item.division === 'OneQuick')
      .map((item) => ({ ...item, division: 'onequick' }));
    const oneQuickList2 = res.data.subjectInList
      .filter((item) => item.division === 'Onequick')
      .map((item) => ({ ...item, division: 'onequick' }));

    return {
      message,
      errorCode,
      errorMessage,
      data: [...userList, ...boardList, ...oneQuickList1, ...oneQuickList2]
    };
  }
  return {
    message,
    errorCode,
    errorMessage
  };
};

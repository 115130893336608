import { Outlet } from 'react-router-dom';
import './index.css';
import React, { useEffect } from 'react';
import AuthContainer from '@/components/container/service/full/AuthContainer';
import { useSetRecoilState } from 'recoil';
import { ExternalPopupInfoStore } from '@/recoil/store/common';

/**  인증 레이아웃  **/
const AuthLayout = () => {

    const setExternalPopupInfo = useSetRecoilState(ExternalPopupInfoStore);

    useEffect(() => {
        // native callback 시작 --> 화면 최대화 기본
        const handleNativeCallback = (action: string, paramString: string) => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`LINK SCHOOL NATIVE CALLBACK START - ${action} : ${JSON.stringify(paramString)}`);
            }
            try {
                const param = JSON.parse(paramString);
                if (param.command === 'popupresult') {
                    const result = JSON.parse(param.result);
                    if (result.tokenVersionId && result.encData && result.integrityValue) {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL NATIVE CALLBACK - NICE Redirect');
                        }
                        setExternalPopupInfo(prev => ({ ...prev, callbackData: { passInfo: { tokenVersionId: result.tokenVersionId, encData: result.encData, integrityValue: result.integrityValue } } }));
                    }
                    else if (result.oAuthCode) {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL NATIVE CALLBACK - SSO Redirect');
                        }
                        setExternalPopupInfo(prev => ({ ...prev, callbackData: { oAuthInfo: { code: result.oAuthCode } } }));
                    } else {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL NATIVE CALLBACK - 팝업 닫힘');
                        }
                        setExternalPopupInfo(prev => ({ ...prev, externalPopup: false }));
                    }
                }
            } catch (e: any) {
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    console.log(`LINK SCHOOL NATIVE CALLBACK FAIL - ${JSON.stringify(e.message)}`);
                }
            }
        };

        window.MirrorCallbackAction(handleNativeCallback);

    }, []);

    return (
        <div className="AuthRoot">
            <AuthContainer>
                <Outlet/>
            </AuthContainer>
        </div>
    );
};

export default AuthLayout;

import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode,
  StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';
import { MqttMirrorManageMessage } from '@/services/classMqtt';

/* ########################################
 * ############# 미러링 목록 조회 #############
 * ######################################## */





/* function interface */
export interface FunctionReq {
  token: string;
  lectureId: string;
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
  data: MqttMirrorManageMessage[];
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  data?: MqttMirrorManageMessage[]
  label: string
}



export const findLectureMirrorList = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/mirror/${request.lectureId}`;
  const method = 'GET';

  const res: ApiRes = await statusApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to retrieve data.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message,
    data: res.data ? res.data : []
  };
};

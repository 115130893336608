import { getSession } from '@/hooks/common';
import {
  GroupListManagementInterface,
  GroupManagementInterface,
  groupManagementStore
} from '@/recoil/store/teacherManagement';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { forceLogoutStore } from '@/recoil/store/user';
import { getClassMember, MemberInfoInterface } from '@/services/core/schools/getClassMember';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import publicMethod from '@/utils/publicMethod';
import { saveGroups } from '@/services/content/settings/saveGroups';
import { useEffect, useState } from 'react';
import { getGroupList } from '@/services/content/settings/getGroupList';
import { updateGroup } from '@/services/content/settings/updateGroup';
import { useNavigate } from 'react-router-dom';
import { removeGroup } from '@/services/content/settings/removeGroup';
import { popupErrorStore } from '@/recoil/store/popup';

/* 모둠정보 관리 & Core 업데이트 */
export default () => {

  const navigate = useNavigate();
  const { sessionBaseInfo, sessionTokenInfo } = getSession();

  const [groupManagement, setGroupManagement] = useRecoilState(groupManagementStore);
  const [groupSetting, setGroupSetting] = useState<boolean>(false);

  const setForceLogout = useSetRecoilState(forceLogoutStore);
  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);

  useEffect(() => {
    if (sessionBaseInfo?.baseInfo.role === 'teacher') {
      getTeacherGroups();
    }
  }, []);

  /* 그룹정보 최초셋팅 */
  const getTeacherGroups = async (): Promise<boolean> => {
    const res = await getGroupList({ token: sessionTokenInfo.coreAccessToken! });
    console.log('aschool groupData init: ' + res.status);
    if (res.code === 'OK') {

      if (res.data) {
        console.log('aschool groupData1: ' + JSON.stringify(res.data));
        setGroupManagement(res.data);
      }
      setGroupSetting(true);
      return true;
    } else {
      setGroupSetting(false);
      return false;
    }
  };

  /*
   * 그룹조회(classId 를 통해 그룹 목록 조회 또는 classId 와 groupId 를 통해 하나의 그룹 조회)
   *  - null 반환할 경우 최초 그룹정보 조회부터 실패한 케이스 ( 그룹정보 조회 실패시 모든 기능 사용 불가 )
   *  - [] 반환할 경우 셋팅된 그룹 정보가 없는 케이스
   *  - GroupManagementInterface[] 반환할 경우 그룹정보가 존재하는 케이스
   */
  const getGroupInfo = async (classId: string, groupId?: number): Promise<GroupManagementInterface[] | null> => {
    if (groupSetting) {
      const groups = groupManagement.find(gm => gm.classId === classId);
      if (groups) {
        if (groupId) {
          const groupList = groups.groupList.find(gl => Number(gl.id) === Number(groupId));
          return groupList ? [groupList] : [];
        } else {
          return groups.groupList;
        }
      }
      return [];
    } else {
      // 그룹 조회 재실행 및 groupInfo 가져오기 시작 && 한번 더 조회 실패시 모든기능 사용 못함
      const res = await getTeacherGroups();
      if (res) {
        return getGroupInfo(classId, groupId);
      } else {
        return null;
      }
    }
  };

  /*
   * 실제 교실 내 학생&디바이스 목록 조회
   *  - null 반환할 경우 현재 교실내 학생&디바이스 목록 조회 실패
   */
  const getClassRealMember = async (classId: string): Promise<MemberInfoInterface[] | null> => {
    const apiRes = await getClassMember({ token: sessionTokenInfo.coreAccessToken!, classId });
    if (apiRes.message === 'SUCCESS' && apiRes.data) {
      setLoginSessionCount(publicMethod.sessionUpdate);
      return apiRes.data.filter((item) => !(item.division === 'student' && item.status !== 'approved'));
    }
    else if (apiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
    }
    else if (apiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
    }
    else if (apiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      // 서버 에러 팝업
      setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
    }
    return null;
  };

  /*
   * groupId 를 통한 그룹 수정
   * - true 반환할 경우 그룹 수정 성공
   * - false 반환할 경우 그룹 수정 실패
   */
  const modifyGroup = async (classId: string, groupInfo: GroupManagementInterface): Promise<boolean> => {
    let parentId: number;

    groupManagement.forEach((gm: GroupListManagementInterface) => {
      if (gm.classId === classId) {
        parentId = gm.id!;
      }
    });

    const res = await updateGroup({ token: sessionTokenInfo.coreAccessToken!, parentId: parentId!, groupData: groupInfo });
    if (res.code === 'OK' && res.data) {
      console.log('aschool groupData2: ' + JSON.stringify(res.data));
      setGroupManagement(res.data);
      return true;
    } else {
      return false;
    }
  };

  /*
   * 그룹 삭제
   * - true 반환할 경우 그룹 삭제 성공
   * - false 반환할 경우 그룹 삭제 실패
   */
  const deleteGroup = async (groupId: number): Promise<boolean> => {
    let parentId: number | null = null;

    groupManagement.forEach((gm: GroupListManagementInterface) => {
      gm.groupList.forEach((gl: GroupManagementInterface) => {
        if (gl.id === groupId) {
          parentId = gm.id!;
        }
      });
    });
    if (parentId) {
      const res = await removeGroup({ token: sessionTokenInfo.coreAccessToken!, parentId: parentId, removeGroupId: groupId });
      if (res.code === 'OK' && res.data) {
        console.log('aschool groupData3: ' + JSON.stringify(res.data));
        setGroupManagement(res.data);
        return true;
      } else {
        return false;
      }
    }
    else {
      return false;
    }
  };

  /*
   * 그룹 추가
   * - true 반환할 경우 그룹 추가 성공
   * - false 반환할 경우 그룹 추가 실패
   */
  const makeNewGroup = async (classId: string, groupInfo: GroupManagementInterface): Promise<boolean> => {
    let update: boolean = false;
    let parentId: number | null = null;

    groupManagement.forEach((gm: GroupListManagementInterface) => {
      if (gm.classId === classId) {
        update = true;
        parentId = gm.id!;
      }
    });

    let res;

    if (!update) {
      // classId가 없는 경우 ( save )
      const request = { classId: classId, teacherId: sessionBaseInfo?.baseInfo.userId!, groupList: [groupInfo] };
      res = await saveGroups({ token: sessionTokenInfo.coreAccessToken!, groupData: [request] });
    } else if (parentId) {
      // classId가 있는 경우 ( update )
      res = await updateGroup({ token: sessionTokenInfo.coreAccessToken!, parentId: parentId, groupData: groupInfo });
    } else {
      return false;
    }

    if (res.code === 'OK' && res.data) {
      console.log('aschool groupData4: ' + JSON.stringify(res.data));
      setGroupManagement(res.data);
      return true;
    } else {
      return false;
    }
  };


  return { getGroupInfo, modifyGroup, makeNewGroup, deleteGroup, getClassRealMember };


};

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';


/* 마이페이지 팝업 HOOK */
export default() => {

    // 페이지 이동
    const navigate = useNavigate();
    // 팝업  state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE.includes('mypage')){
            setIsVisible(true);
            document.body.style.overflow = 'hidden';
        } else {
            setIsVisible(false);
        }
    }, [popup.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };



    return { popup, isVisible, popupClose, navigate };
};

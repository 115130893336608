import { streamApi } from '@/services/network';

/* #########################################
 * ############# 미디어박스 핑전송 #############
 * ######################################### */

/* function interface */
export interface FunctionReq {
    mediaBoxIp: string;
}



/* Api Interface */
interface ApiRes {
    status: number;
    code: number;
    data: any
}




export const pingServer = async(request: FunctionReq): Promise<void> => {
    const endPoint = `http://${request.mediaBoxIp}:9090/v2/ping`;
    const method = 'GET';

    await streamApi<{}, ApiRes>(endPoint, {}, method);

};

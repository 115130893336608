import '@/components/container/service/full/Container.css';
import { ReactNode } from 'react';
import { UsersInfoInterface } from '@/hooks/services/checkIn';
import PopupOther from '@/components/popup/other/popupOther';
import MirrorContainer from '@/components/container/service/full/mirrorContainer';



interface BoardContainerProps {
    children: ReactNode;
    usersInfo: UsersInfoInterface[]
    returnPermissionMessage : (request: { permission: boolean, talkId: string }) => Promise<boolean>
    returnMirrorPermissionMessage : (request: { permission: boolean, talkId: string }) => Promise<boolean>
    getDetailMonitorRequest : (id: string) => void
    deleteClassTalk : (talkIds: string[]) => Promise<boolean>
}


// 서비스 앱 페이지 컨테니어
const BoardContainer = ({ children, usersInfo, returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk }: BoardContainerProps) => {


    return (

        <div className={'container-class'}>


            {/* z index 100 */}
            {/* 타유저로부터 요청이 올경우 */}
            <PopupOther usersInfo={usersInfo} returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage} getDetailMonitorRequest={getDetailMonitorRequest} deleteClassTalk={deleteClassTalk}/>

            {/* z index 80 */}
            {/* 미러링 화면 */}
            <MirrorContainer usersInfo={usersInfo} />



            <>{children}</>
        </div>
    );


};

export default BoardContainer;

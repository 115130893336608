import { atom } from 'recoil';

/* 블랜디드 관련 종합 상태 */
export interface BlendedStateInterface {
    deviceId: string | null;            // 디바이스 id
    recordLectureId: string | null;     // 블랜디드 lecture id
    recordLectureTitle: string | null;  // 블랜디드 lecture 제목
    record: boolean;                    // 녹화 On/Off
    stream: boolean;                    // 구루미 On/Off
    recordMode: 'NONE' | 'COUNTDOWN' | 'PLAY' | 'PAUSE';  // 녹화 상태 ( '녹화안함' | '카운트다운' | '녹화중' | '일시정지' | '강제종료' )
    mediaBoxRequest: boolean;           // 미디어박스 Api 요청 여부
    recordCount: number;                // 녹화 카운트
    recordStartDate: string | null;     // 녹화 시작 시간
    recordEndDate: string | null;       // 녹화 종료 시간
    forceRecordEnd: boolean;            // 녹화 강제 종료 해야하는지 여부
    roomId: string | null;              // 구루미 방 id
    publicUrl: string | null;           // 구루미 방 public url
}
export const blendedStateStore = atom<BlendedStateInterface>({
    key: 'blendedState',
    default: {
        deviceId: `${process.env.REACT_APP_ENV}` === 'DEBUG' ? 'CAMERA_040312469AB6': null,
        // deviceId: process.env.REACT_APP_ENV === 'DEBUG' ? 'CAMERA_1C6EE60138DD' : null,
        mediaBoxRequest: false,
        recordLectureId: null,
        recordLectureTitle: null,
        record: false,
        recordMode: 'NONE',
        recordCount: 0,
        recordStartDate: null,
        recordEndDate: null,
        forceRecordEnd: false,
        stream: false,
        roomId: null,
        publicUrl: null
    }
});

export const TABLE_HEADER_FILE = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Student Number', className: 'col-studentNumber' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'File Name', className: 'col-fileName' },
  { title: '', className: 'col-download' },
  { title: 'File Size', className: 'col-fileSize' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_LINK = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Student Number', className: 'col-studentNumber' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'URL', className: 'col-url' },
  { title: 'Copy URL', className: 'col-urlCopy' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_MESSAGE = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Student Number', className: 'col-studentNumber' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'Message', className: 'col-message' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_VIDEO = [
  { title: 'Video', className: 'col-thumbnailPath' },
  { title: 'Subject', className: 'col-subject' },
  { title: 'Title', className: 'col-title' },
  { title: 'Download', className: 'col-download' },
  { title: 'Duration', className: 'col-duration' },
  { title: 'File Size', className: 'col-fileSize' },
  { title: 'Disclosure Status', className: 'col-disclosureStatus' },
  { title: 'Record Date', className: 'col-recordDate' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_BOARD = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'File Name', className: 'col-fileName' },
  { title: 'Download', className: 'col-download' },
  { title: 'File Size', className: 'col-fileSize' },
  { title: 'Date Created', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_ALERT = [
  { title: 'No', className: 'col-no' },
  { title: 'Target', className: 'col-type' },
  { title: 'Title', className: 'col-title' },
  { title: 'Attachment', className: 'col-attachment' },
  { title: 'Author', className: 'col-writer' },
  { title: 'Registration Date', className: 'col-date' }
];

export const TABLE_HEADER_ALERT_STUDENT = [
  { title: 'No', className: 'col-no' },
  { title: 'Type', className: 'col-type' },
  { title: 'Title', className: 'col-title' },
  { title: 'Attachment', className: 'col-attachment' },
  { title: 'Author', className: 'col-writer' },
  { title: 'Registration Date', className: 'col-date' }
];

export const TABLE_HEADER_NOTICE_A = [
  { title: 'No', className: 'col-no' },
  { title: 'Type', className: 'col-type' },
  { title: 'Title', className: 'col-title' },
  { title: 'Attachment', className: 'col-attachment' },
  { title: 'Author', className: 'col-writer' },
  { title: 'Registration Date', className: 'col-date' }
];

export const TABLE_HEADER_NOTICE_B = [
  { title: 'No', className: 'col-no' },
  { title: 'Title', className: 'col-title' },
  { title: 'Attachment', className: 'col-attachment' },
  { title: 'Author', className: 'col-writer' },
  { title: 'Registration Date', className: 'col-date' }
];

export const TABLE_HEADER_STUDENT_MANAGE = [
  { title: 'Name', className: 'col-name' },
  { title: 'Student Number', className: 'col-studentNumber' },
  { title: 'Phone Number', className: 'col-phone' },
  { title: 'Email', className: 'col-email' },
  { title: 'Authority Status', className: 'col-authority' },
  { title: 'Last Visit Date', className: 'col-lastVisit' },
  { title: 'Reset Password', className: 'col-resetPassword' }
];

export const TABLE_HEADER_DEVICE = [
  { title: 'Active', className: 'col-active' },
  { title: 'Device Status', className: 'col-deviceStatus' },
  { title: 'Device Name', className: 'col-deviceName' },
  { title: 'Device ID', className: 'col-deviceID' },
  { title: 'Model Name', className: 'col-modelName' },
  { title: 'S/N', className: 'col-serialNumber' },
  { title: 'Registration Date', className: 'col-date' },
  { title: 'Inspection Request', className: 'col-inspectionRequest' }
];

// ==================== Student ==================== //

export const TABLE_HEADER_FILE_STUDENT = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'File Name', className: 'col-fileName' },
  { title: '', className: 'col-download' },
  { title: 'File Size', className: 'col-fileSize' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_LINK_STUDENT = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'URL', className: 'col-url' },
  { title: 'Copy URL', className: 'col-urlCopy' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_MESSAGE_STUDENT = [
  { title: 'Subject', className: 'col-subject' },
  { title: 'Class', className: 'col-class' },
  { title: 'Target', className: 'col-teacherName' },
  { title: 'Message', className: 'col-message' },
  { title: 'Date', className: 'col-date' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

export const TABLE_HEADER_VIDEO_STUDENT = [
  { title: 'Video', className: 'col-thumbnailPath' },
  { title: 'Subject', className: 'col-subject' },
  { title: 'Title', className: 'col-title' },
  { title: 'Duration', className: 'col-duration' },
  { title: 'File Size', className: 'col-fileSize' },
  { title: 'Teacher in Charge', className: 'col-teacherName' },
  { title: 'Record Date', className: 'col-recordDate' },
  { title: 'Expiration Date', className: 'col-expirationDate' }
];

import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { popupAutoStore, popupErrorStore, popupOtherStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSession } from '@/hooks/common';
import { classTalkListStore, classTalkPopupStore, redDotStateStore } from '@/recoil/store/class';
import { forceLogoutStore, userBaseInfoStore, userTokenInfoStore } from '@/recoil/store/user';
import { toastClassStore } from '@/recoil/store/common';
import { checkInTimeInfoStore, loginSessionCountStore, thisSchedulerStore } from '@/recoil/store/schedule';
import { FunctionRes, logout as coreLogout } from '@/services/core/auth/logout';



/* 선생, 학생 레이아웃 HOOK */
export default (classCheckOut: () => Promise<boolean>) => {

  // 페이지 이동
  const navigate = useNavigate();
  // 팝업 state
  const [popupOther, setPopupOther] = useRecoilState(popupOtherStore);
  // 팝업 자동 로그아웃 state
  const [popupAuto, setPopupAuto] = useRecoilState(popupAutoStore);
  // 토스트 체크인 state
  const [toastClass, setToastClass] = useRecoilState(toastClassStore);
  // 로그인 정보, 접근 디바이스 (PC인지 태블릿인지)
  const { sessionBaseInfo } = getSession();
  // 수업톡 팝업 리스트
  const [classTalkPopup] = useRecoilState(classTalkPopupStore);
  // 수업톡 리스트
  const classTalkList = useRecoilValue(classTalkListStore);
  // 강제로그아웃 감지
  const forceLogout = useRecoilValue(forceLogoutStore);

  const [loginSessionCount, setLoginSessionCount] = useRecoilState(loginSessionCountStore);  // 로그인 세션 카운트

  /* 현재시간 스케줄러 셋팅 함수 */
  const checkInTimeInfo = useRecoilValue(checkInTimeInfoStore);

  const { sessionTokenInfo } = getSession();


  // 로그인 데이터 초기화
  const resetSessionTokenInfo = useResetRecoilState(userTokenInfoStore);
  const resetSessionBaseInfo = useResetRecoilState(userBaseInfoStore);

  // 교수학습 레드닷 상태
  const setRedDotState = useSetRecoilState(redDotStateStore);
  // 스케쥴러 체크인 입장 시간 체크
  const thisScheduler = useRecoilValue(thisSchedulerStore);

  // 수업시작 알림 스낵바 제어
  const [snackBar, setSnackBar] = useState<{ startTime: string; } | null>(null);
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);


  // useEffect(()=>{
  //     console.log('classTalkList 변화감지 111', classTalkList);
  //     console.log(classTalkList);
  // }, [classTalkList]);
  //
  // useEffect(()=>{
  //     console.log('classTalkList 변화감지 popupOther', popupOther);
  //     console.log(popupOther);
  // }, [popupOther]);

  useEffect(() => {

    /* @@@@@@@@@@@@@@@@@@ 공통 팝업 @@@@@@@@@@@@@@@@*/

    // 시스템 점검 안내
    // setPopupSystem((prevData) => ({ ...prevData,
    //     TYPE: 'systemCheck',
    //     TITLE: '시스템 점검 안내',
    //     CONTENT: '보다 안정화된 서비스 제공을 위해 \n 서비스 점검 중입니다. \n 점검 시간에는 모든 서비스 이용이 \n 일시적으로 중단됩니다.',
    //     BUTTON: '확인',
    //     RESERVE: '01/10(수) 15:00 ~ 01/10(수) 18:00'
    // }));
    // 시스템 업데이트
    // setPopupSystem((prevData) => ({ ...prevData, TYPE: 'systemUpdateAlarm', TITLE: '업데이트 알림', CONTENT: '', BUTTON: '업데이트', RESERVE: '최신 버전으로 업데이트가 필요합니다.' }));
    // 업데이트 내역
    // setPopupSystem((prevData) => ({ ...prevData, TYPE: 'systemUpdateHistory', TITLE: '업데이트 내역', CONTENT: '소소한 버그와 안정성 개선', BUTTON: '확인', RESERVE: '1.2.4' }));


    if (loginSessionCount && (loginSessionCount.sec > 0 && loginSessionCount.sec <= 300 && !loginSessionCount.popupOn && popupAuto.TYPE !== 'autoLogout')) {
      setLoginSessionCount(({ ...loginSessionCount, popupOn: true }));
      setPopupAuto((prevData) => ({ ...prevData, TYPE: 'autoLogout' }));
    }

  }, [loginSessionCount, popupAuto]);

  useEffect(() => {

    if (!sessionBaseInfo) { // 로그인 상태가 아닐 경우
      resetSessionTokenInfo();
      resetSessionBaseInfo();
      navigate('/auth/login');
    }

  }, [sessionBaseInfo]);

  useEffect(() => {
    const latestTalk = classTalkPopup[classTalkPopup.length - 1];

    // 팝업이 하나도 쌓이지 않았을 경우 + 전달할 톡이 있을 경우 -> 팝업 띄우기
    // 팝업이 한개 이상 쌓여있을 경우 -> 팝업을 띄우지 않음
    if (popupOther.TYPE === '' && classTalkPopup.length > 0) {
      let popupType = '';
      let request = false;

      switch (latestTalk.talkTitle) {
        case 'mirror':
          popupType = 'mirror';
          request = true;
          break;
        case 'file':
          popupType = 'file';
          request = latestTalk.talkType === 'event';
          break;
        case 'link':
          popupType = 'link';
          request = latestTalk.talkType === 'event';
          break;
        case 'msg':
          popupType = 'msg';
          break;
        default:
          break;
      }

      if (popupType !== '') {
        // 팝업 띄우기 (화면공유, 파일, 링크, 메시지에 관해서만)
        setPopupOther({ TYPE: popupType, REQUEST: request, DATA: latestTalk });
      }
    }

  }, [classTalkPopup]);

  useEffect(() => {

    // 읽지 않은 수업톡이 하나라도 있을 경우
    if (classTalkList.filter(u => !u.isRead).length > 0) {
      // LNB 레드닷 활성화
      setRedDotState({ isRedDot: true });
      // FLOATING 레드닷 활성화
      window.MirrorMainMessage('onRedDot');
    } else {
      // LNB 레드닷 비활성화
      setRedDotState({ isRedDot: false });
      // FLOATING 레드닷 비활성화
      window.MirrorMainMessage('offRedDot');
    }


  }, [classTalkList]);


  /* 스케줄이 있는 경우 */
  useEffect(() => {
    if (thisScheduler && sessionBaseInfo?.mappingClass && sessionBaseInfo.mappingClass.length > 0) {

      const isWithinCheckInPeriod = thisScheduler.inOut === 'IN'
        && !checkInTimeInfo
        && thisScheduler.sec <= 300
        && thisScheduler.sec > 1
        && (!snackBar || snackBar?.startTime !== thisScheduler.startTime)
        && toastClass.TYPE !== 'checkIn';

      if (isWithinCheckInPeriod) {
        setSnackBar({ startTime: thisScheduler.startTime });

        const getCheckInClassId = sessionBaseInfo?.mappingClass.find(item => item.classId === thisScheduler.classId);
        const departmentName = (getCheckInClassId?.department && getCheckInClassId?.department !== 'General' && getCheckInClassId?.department !== '-')
          ? `${getCheckInClassId.department}학과 `
          : '';
        const gradeName = `${getCheckInClassId?.grade}학년`;
        const className = `${getCheckInClassId?.className}반`;

        const getClassName = `${gradeName} ${departmentName}${className}`;

        setToastClass({ TYPE: 'checkIn', RESERVE: getClassName, TIME: thisScheduler.sec });
      }
    }
  }, [thisScheduler]);



  /* 강제 로그아웃 */
  useEffect(() => {
    if (forceLogout.force) {
      logout();
    }
  }, [forceLogout]);

  /* 로그아웃 */
  const logout = async () => {

    if (sessionTokenInfo.coreAccessToken) {

      await classCheckOut();

      const resApi: FunctionRes = await coreLogout({ accessToken: sessionTokenInfo.coreAccessToken });

      if (resApi.message === 'SUCCESS') {
        resetSessionTokenInfo();
        resetSessionBaseInfo();
        setLoginSessionCount(null);
      } else {
        // 서버 에러 팝업
        setPopupError(prevData => [...prevData, { CODE: resApi.errorCode, MESSAGE: resApi.errorMessage }]);
      }
    }

  };


  return { logout };

};

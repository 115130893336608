import React from 'react';
import '@/components/popup/popup.css';
import popupNoticeHook from '@/hooks/components/popup/common/popupNotice';
import NoticeIcon from '@/assets/icon/popup/system-icon.svg';   // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';





/* 공지사항 팝업 */
const PopupNotice = () => {

  const { popupNotice, isVisible, popupClose, popupAgainNone } = popupNoticeHook();

  return (
    <>
      {popupNotice.TYPE === 'notice' &&
        <div className={`layout-popup notice ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>

              {/* Popup Icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(NoticeIcon)} alt={'NoticeIcon'} draggable={false} />
              </figure>

              {/* Popup Title */}
              <div className='layout-popup-title'>{popupNotice.TITLE}</div>

              <div className='layout-popup-common-content agreement'>
                <span className='layout-popup-notice-sub' dangerouslySetInnerHTML={{ __html: popupNotice.CONTENT }}></span>
              </div>
            </article>

            <button className={'layout-popup-button'} onClick={popupClose}>
              Confirm
            </button>

            <div className={'layout-popup-again-none'} onClick={popupAgainNone}>
              <span>Do not show again</span>
            </div>

          </div>

        </div>
      }
    </>
  );

};

export default PopupNotice
  ;

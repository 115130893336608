import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* #############################################
 * ############# 모둠/AIoT/수업상태 셋팅 ############
 * ############################################# */





/* function interface */
export interface FunctionReq {
  token: string,
  type: 'aiot' | 'study-group',
  settingKey: string          // SELECT KEY
  data: string                // JSON string
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
}


/* Api Interface */
interface ApiReq {
  data: string,
}
interface ApiRes {
  status: number,         // status
  code: string            // core 반환 구분값
  message: string         // core 반환 구분값 desc
}



export const classSetting = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/settings/me/class/${request.type}/${request.settingKey}`;
  const method = 'PUT';

  const req: ApiReq = { data: request.data };

  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to update information.';

  return {
    message,
    errorCode,
    errorMessage
  };
};

import React, { Fragment } from 'react';
import '@/components/popup/popup.css';
import ScrollBar from '@/components/common/scroll/ScrollBar';
import popupCommandHook from '@/hooks/components/popup/command/popupCommand';
import Iconsvg from '@/components/common/Iconsvg';                      // Dompurify 적용완료
import LinkIcon from '@/assets/icon/common/link.svg';                   // Dompurify 적용완료
import MessageIcon from '@/assets/icon/common/message.svg';             // Dompurify 적용완료
import OnlineClassIcon from '@/assets/icon/common/online_class.svg';    // Dompurify 적용완료
import CheckedIcon from '@/assets/icon/popup/popup-checked.svg';        // Dompurify 적용완료
import UncheckedIcon from '@/assets/icon/popup/popup-unchecked.svg';    // Dompurify 적용완료
import CloseIcon from '@/assets/icon/popup/close_button.svg';           // Dompurify 적용완료
import FileDeleteIcon from '@/assets/icon/popup/file_delete_close.svg';  // Dompurify 적용완료
import FileIcon from '@/assets/icon/common/file.svg';                    // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';

interface PopupCommandProps {
  sendPermissionRequestMessage: (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }) => Promise<boolean>
  startBlendedStreamLearning?: (roomTitle: string) => Promise<boolean>
  startBlendedRecord?: (recordName: string) => Promise<boolean>
  endBlendedRecord?: () => Promise<boolean>
}

/* 파일, 링크, 메시지전송 팝업 */
const PopupCommand = ({ sendPermissionRequestMessage, startBlendedStreamLearning, startBlendedRecord, endBlendedRecord }: PopupCommandProps) => {

  // 파일 전송 팝업
  const renderFilePopup = () => (
    <div className={'layout-popup-box command'}>
      <div className={'cmd-lp-top'}>
        <div className='cmd-lp-close-box'>
          <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
        </div>
        <div className='cmd-lp-cover'>
          <img src={publicMethod.svgClear(FileIcon)} alt={'FileIcon'} draggable={false} />
          <span>File Transfer</span> Please add a file.
        </div>
      </div>
      <div className='cmd-lp-content file'>
        {files.length === 0 && (
          <div className='cmd-file-container-none'>
            You can send up to 5 files (within 100MB) <span>Supported formats: hwp, doc, pdf, png, jpeg, gif, txt, xlsx</span>
          </div>
        )}
        {files.length > 0 && (
          <ScrollBar scrollbarHeight={'174px'}>
            {files.map((file, index) => (
              <div key={`${file.name}${index.toString()}`} className='cmd-file-container'>
                <img src={publicMethod.getFileIcon(file.name)} alt={'FileIcon'} draggable={false} />
                <div className="cmd-file-container-input">
                  <span>{displayText[index] ? displayText[index].name : file.name}</span>
                </div>
                <div className="cmd-file-container-size">
                  <span style={{ color: '#ADB0B8' }}>{publicMethod.formatFileSize(file.size)}</span>
                </div>
                <img src={publicMethod.svgClear(FileDeleteIcon)} alt={'FileDeleteIcon'} draggable={false} onClick={() => removeFile(index)} />
              </div>
            ))}
          </ScrollBar>
        )}
        <input
          id='shareFile'
          style={{ display: 'none' }}
          type="file"
          ref={fileInputRef}
          accept=".hwp, .doc, .docx, .pdf, .png, .jpg, .jpeg, .gif, .txt, .xls, .xlsx, .pdf, .ppt, .pptx"
          onChange={handleFileChange}
          multiple
        />
      </div>
      {files.length !== 0 && (
        <div className='cmd-file-size'>
          <span>Total Capacity</span>
          <span> <span style={{ color: '#3F0AB1', marginRight: '4px' }}>{publicMethod.formatFileSize(totalFileSizeCount)}</span>/100MB</span>
        </div>
      )}
      <div className='layout-popup-button-box'>
        <button className={`layout-popup-button double ${files.length === 5 || publicMethod.isHundredMegaByte(totalFileSizeCount) ? 'disable' : 'white'}`} onClick={files.length === 5 || publicMethod.isHundredMegaByte(totalFileSizeCount) ? undefined : clickFileInput}>
          <Iconsvg name='fileUpload' />
          <span style={{ marginLeft: '8px' }}>Add File</span>
        </button>
        <button className={`layout-popup-button double ${files.length === 0 && 'disable'}`} onClick={files.length !== 0 ? sendFile : undefined}>
          Next
        </button>
      </div>
    </div>
  );

  // 링크 전송 팝업
  const renderLinkPopup = () => (
    <div className={'layout-popup-box'}>
      <div className={'cmd-lp-top'}>
        <div className='cmd-lp-close-box'>
          <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
        </div>
        <div className='cmd-lp-cover'>
          <img src={publicMethod.svgClear(LinkIcon)} alt={'LinkIcon'} draggable={false} />
          <span>Link Transfer</span> Please enter the link to share.
        </div>
      </div>
      <div className='cmd-lp-content link'>
        <input className={'ellipsis'} name='link' type="text" value={linkInput.link} autoComplete={'off'} placeholder={'URL 입력'} onChange={handleLinkInputs} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} />
      </div>
      <div className={`cmd-lp-button ${linkInput.link === '' ? 'gray' : ''}`} onClick={linkInput.link !== '' ? sendLink : undefined}>
        Next
      </div>
    </div>
  );

  // 메시지 전송 팝업
  const renderMessagePopup = () => (
    <main className={'layout-popup-box'}>

      <div className="cmd-lp-top">
        <div className="cmd-lp-close-box">
          <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
        </div>

        <div className="cmd-lp-cover">
          <img src={publicMethod.svgClear(MessageIcon)} alt={'MessageIcon'} draggable={false} />
          <span>Message Transfer</span>
          {
            popup.RESERVE !== 'studentTarget'
              ?
              <>Please select the message to send.</>
              :
              <>Please enter the message to send to the teacher.</>
          }
        </div>
      </div>


      {
        popup.RESERVE !== 'studentTarget'
          ?
          <>
            <div className="cmd-lp-content message">
              <ScrollBar scrollbarHeight="100%">

                {checkboxItems.map(item => (

                  <Fragment key={item.key}>
                    {
                      item.key === 'textarea_cb'
                        ?
                        <div
                          className={`cmd-message-item ${item.checked ? 'selected' : ''} long`}
                          onClick={() => handleCheckboxChange(item.key, item.label)}>
                          <div className="cmd-message-item-checkbox">
                            <img
                              src={item.checked ? publicMethod.svgClear(CheckedIcon) : publicMethod.svgClear(UncheckedIcon)}
                              alt={item.checked ? 'checkbox' : 'uncheckbox'}
                              draggable={false} />
                          </div>
                          <div className="cmd-message-item-content">
                            <textarea name={'message'} value={messageInput.message}
                              placeholder={'메시지 입력 (최대 100자)'}
                              onChange={handleMessageInputs} />
                          </div>
                        </div>
                        :
                        <div className={`cmd-message-item ${item.checked ? 'selected' : ''}`}
                          onClick={() => handleCheckboxChange(item.key, item.label)}>
                          <img
                            src={item.checked ? publicMethod.svgClear(CheckedIcon) : publicMethod.svgClear(UncheckedIcon)}
                            alt={item.checked ? 'checkbox' : 'uncheckbox'}
                            draggable={false} />
                          <span>{item.label}</span>
                        </div>

                    }
                  </Fragment>

                ))}
              </ScrollBar>
            </div>
            <div
              className={`cmd-lp-button ${(messageInput.message === '' && messageInput.checkLabel === '') && 'gray'}`}
              onClick={() => sendMessage()}>
              Next
            </div>
          </>

          :
          <>
            <div className="cmd-lp-content message">
              <div
                className={`cmd-message-item full student ${messageInput.message !== '' && 'selected'}`}
                onClick={() => handleCheckboxChange('textarea_cb', '')}>
                <div className="cmd-message-item-content full">
                  <textarea name={'message'} value={messageInput.message}
                    placeholder={'메시지 입력 (최대 100자)'} onChange={handleMessageInputs} />
                </div>
              </div>
            </div>
            <div className={`cmd-lp-button ${messageInput.message === '' && 'gray'}`}
              onClick={() => sendMessage()}>
              Next
            </div>
          </>
      }
    </main>
  );

  // 블렌디드 전송 팝업
  const renderBlendedRecordPopup = () => (
    <div className={'layout-popup-box'}>
      <div className={'cmd-lp-top'}>
        <div className="cmd-lp-close-box">
          <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false}
            onClick={() => popupBlendedClose(true)} />
        </div>
        <div className="cmd-lp-cover">
          <img src={publicMethod.svgClear(OnlineClassIcon)} alt={'OnlineClassIcon'} draggable={false} />
          <span>Class Title</span> Please enter the title of the class to be recorded.
        </div>
      </div>

      <div className="cmd-lp-content blended">
        <input name="record" type="text" placeholder={'수업 제목'} value={recordInput.record} autoComplete={'off'}
          onChange={handleRecordInputs} />
      </div>

      <div
        className={`cmd-lp-button ${recordInput.record === '' ? 'gray' : ''}`}
        onClick={recordInput.record !== '' ? sendRecord : undefined}>
        OK
      </div>
    </div>
  );

  // 블렌디드 온라인 수업 전송 팝업
  const renderBlendedOnlinePopup = () => (
    <div className={'layout-popup-box'}>
      <div className={'cmd-lp-top'}>
        <div className="cmd-lp-close-box">
          <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false}
            onClick={() => popupBlendedClose(true)} />
        </div>
        <div className="cmd-lp-cover">
          <img src={publicMethod.svgClear(OnlineClassIcon)} alt={'OnlineClassIcon'} draggable={false} />
          <span>Video Class Title</span> Please enter the title of the video class to be created.
        </div>
      </div>

      <div className="cmd-lp-content blended">
        <input name="online" type="text" placeholder={'수업 제목'} value={onlineInput.online} autoComplete={'off'}
          onChange={handleOnlineInputs} />
      </div>

      {
        onlineInput.online !== ''
          ?
          <div className={'cmd-lp-button'} onClick={() => sendOnline()}>
            Create Video Class
          </div>
          :
          <div className={'cmd-lp-button gray'}>
            Create Video Class
          </div>
      }
    </div>
  );

  // 팝업 타입에 따른 렌더링
  const renderPopupContent = () => {
    switch (popup.TYPE) {
      case 'file':
        return renderFilePopup();
      case 'link':
        return renderLinkPopup();
      case 'msg':
        return renderMessagePopup();
      case 'blendedRecord':
        return renderBlendedRecordPopup();
      case 'blendedOnline':
        return renderBlendedOnlinePopup();
      default:
        return null;
    }
  };


  const {
    popup,
    popupClose,
    isVisible,
    files,
    fileInputRef,
    displayText,
    handleFileChange,
    removeFile,
    sendFile,
    clickFileInput,
    handleLinkInputs,
    sendLink,
    handleMessageInputs,
    handleCheckboxChange,
    sendMessage,
    messageInput,
    linkInput,
    checkboxItems,
    handleRecordInputs,
    sendRecord,
    recordInput,
    totalFileSizeCount,
    handleOnlineInputs,
    sendOnline,
    onlineInput,
    popupBlendedClose,
    setIsFocused
  } = popupCommandHook(sendPermissionRequestMessage, startBlendedStreamLearning, startBlendedRecord);


  return (
    <>
      {(popup.TYPE === 'file' || popup.TYPE === 'link' || popup.TYPE === 'msg' || popup.TYPE === 'blendedRecord' || popup.TYPE === 'blendedOnline') &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          {renderPopupContent()}
        </div>
      }
    </>


  );
};

export default PopupCommand;


import { atom } from 'recoil';
import { MyBoxTableInterface } from '@/hooks/pages/service/teacher/class/myBox';

/* 선생 - 전자칠판 캡처 상세 화면  */
export interface BoardDetailScreenInterface {
    OPEN: boolean;
    DATA ?: MyBoxTableInterface | null;
    RESERVE ?: string;
}

export const boardDetailScreenStore = atom<BoardDetailScreenInterface>({
    key: 'boardDetailScreen',
    default: {
        OPEN: false,
        DATA: null,
        RESERVE: ''
    }
});




/* 비디오 상세 화면  */
export interface VideoScreenInterface {
    OPEN: boolean;
    ZOOM: boolean;
    PLAY: boolean;
    MUTE: boolean;
    HIDE: boolean;
    VOLUME: number;
    DURATION: number;
    CURRENT_TIME: number;
    PREV_TIME: number;
    DATA ?: any;
    RESERVE ?: string;
}

export const videoScreenStore = atom<VideoScreenInterface>({
    key: 'videoScreen',
    default: {
        OPEN: false,
        PLAY: false,
        MUTE: false,
        ZOOM: false,
        HIDE: false,
        VOLUME: 0.5,
        DURATION: 0,
        CURRENT_TIME: 0,
        PREV_TIME: 0
    }
});

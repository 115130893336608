import React from 'react';
import '@/components/popup/popup.css';
import popupAlertHook from '@/hooks/components/popup/common/popupAlert';


interface PopupAlertProps {
    logout ?: ()=> void;
}

/* 알림 팝업 */
const PopupAlert= ({ logout }: PopupAlertProps) => {

    const { popup, popupClose, isVisible } = popupAlertHook(logout);

    return (

        <>
            {popup.TYPE.includes('alert') &&
                <div className={`layout-popup ${popup.RESERVE === 'autoLogout' && 'autoLogoutAlert'} ${isVisible && 'visible'}`}>
                    <div className={'layout-popup-box alert'}>
                       
                        <article className={'layout-popup-content'}>
                            <span>{popup.CONTENT}</span>
                        </article>

                        <button className={'layout-popup-button'} onClick={() => popupClose()}>
                            {popup.BUTTON}
                        </button>
                    </div>
                </div>
            }
        </>
        



    );
};

export default PopupAlert;

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';
import { toastClassStore, toastStore } from '@/recoil/store/common';
import { useMediaQuery } from '@mui/material';
import { checkInTimeInfoStore, thisSchedulerStore } from '@/recoil/store/schedule';
import { ClassInfoInterface } from '@/hooks/services/checkIn';
import { checkInManagementStore } from '@/recoil/store/checkIn';
import { popupErrorStore, popupStore } from '@/recoil/store/popup';
import { forceLogoutStore } from '@/recoil/store/user';
import { useNavigate } from 'react-router-dom';
import { checkIn } from '@/services/status/checkIn';


/* 체크인 토스트 HOOK */
export default (classCheckIn: (classInfo: ClassInfoInterface) => Promise<boolean>, checkInExtendRequest: () => Promise<boolean>) => {


  // 페이지 이동 함수
  const navigate = useNavigate();
  // 팝업 state
  const setPopup = useSetRecoilState(popupStore);
  // 토스트 state
  const [toast, setToast] = useRecoilState(toastStore);
  // 토스트 체크인 state
  const [toastClass, setToastClass] = useRecoilState(toastClassStore);
  // 토스트 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 접근 디바이스 (PC인지 태블릿인지)
  const { deviceInfo } = getSession();
  // 화면이 가로 1500px 이하일 경우
  const isSmallScreen = useMediaQuery('(max-width: 1500px)');
  // mini 클래스 명 선정
  const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';
  /* 현재시간 스케줄러 셋팅 함수 */
  const checkInTimeInfo = useRecoilValue(checkInTimeInfoStore);
  // 스케쥴러 체크인 입장 시간 체크
  const thisScheduler = useRecoilValue(thisSchedulerStore);
  // 세션 토큰 정보
  const { sessionTokenInfo, sessionBaseInfo } = getSession();
  // 체크인 관리 ( 각 서버 정상접근중인지 여부 확인 )
  const setCheckInManagement = useSetRecoilState(checkInManagementStore);
  // 강제 로그아웃
  const setForceLogout = useSetRecoilState(forceLogoutStore);
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);



  useEffect(() => {

    const handleCheckIn = () => {
      if (!thisScheduler) return;

      if (thisScheduler.sec === 0 || checkInTimeInfo) {
        toastClose();
      } else if (thisScheduler.sec === 1 && !checkInTimeInfo) {
        runCheckIn();
      } else if (thisScheduler.inOut === 'IN' && thisScheduler.sec <= 300 && !isVisible && !checkInTimeInfo) {
        setIsVisible(true);
      }
    };

    const handleCheckOut = () => {
      if (!checkInTimeInfo) {
        toastClose();
        return;
      }

      if (checkInTimeInfo.sec === 0) {
        toastClose();
      } else if (checkInTimeInfo.sec <= 300 && !isVisible) {
        setIsVisible(true);
      }
    };

    if (toastClass.TYPE === 'checkIn') {
      handleCheckIn();
    } else if (toastClass.TYPE === 'checkOut') {
      handleCheckOut();
    }

  }, [toastClass, thisScheduler, checkInTimeInfo]);




  // 체크인 실행
  const runCheckIn = async () => {

    let getCheckInClassId: { classId: string; userClassId?: string; className: string; grade: string; } | undefined | null;

    if (thisScheduler && sessionBaseInfo?.mappingClass) {
      getCheckInClassId = sessionBaseInfo?.mappingClass.find(item => item.classId === thisScheduler.classId);
    }

    if (getCheckInClassId) {

      const statusCheckIn = await checkIn({
        classroomId: getCheckInClassId.classId,
        token: sessionTokenInfo?.coreAccessToken!
      });
      if (statusCheckIn.code === 'OK' && statusCheckIn.data) {
        const hookReq: ClassInfoInterface = {
          ...statusCheckIn.data,
          classGrade: sessionBaseInfo?.mappingClass?.find(f => f.classId === getCheckInClassId?.classId)?.grade ?? '',
          classNumber: sessionBaseInfo?.mappingClass?.find(f => f.classId === getCheckInClassId?.classId)?.className ?? '',
          groupInfo: statusCheckIn.data.groupInfo || null
        };
        setCheckInManagement((prev) => ({ ...prev, coreCheckIn: true }));
        await classCheckIn(hookReq);
      }
      else if (statusCheckIn.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      }
      else if (statusCheckIn.code === 'INVALID_TOKEN' || statusCheckIn.code === 'WRONG_TOKEN' || statusCheckIn.code === 'NOT_EXISTS_TOKEN') {
        setCheckInManagement((prev) => ({ ...prev, checkInState: false }));
        setForceLogout({ force: true, reason: 'token' });
      }
      else if (statusCheckIn.code === 'CHECK_IN_NOT_ALLOWED_CLASSROOM') {
        setPopup({
          TYPE: 'alert',
          TITLE: 'Notification',
          CONTENT: 'You can only check in during the assigned class time.\n',
          BUTTON: 'OK',
          RESERVE: ''
        });
      } else if (statusCheckIn.code === 'CHECK_IN_ALREADY_ANOTHER_TEACHER') {
        setPopup({
          TYPE: 'alert',
          TITLE: 'Notification',
          CONTENT: 'Another teacher is already checked in.\n',
          BUTTON: 'OK',
          RESERVE: ''
        });
      }

      else {
        setPopupError(prevData => [...prevData, { CODE: statusCheckIn.errorCode, MESSAGE: statusCheckIn.errorMessage }]);
      }

    }
  };


  // 토스트 끄기
  const toastClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setToastClass((prevData) => ({ ...prevData, TYPE: '', CONTENT: '', RESERVE: '', TIME: 300 }));
    }, 100);
  };


  // 체크인 연장
  const checkInExReq = async () => {
    const functionRes = await checkInExtendRequest();
    if (functionRes) {

      setIsVisible(false);
      setTimeout(() => {
        setToastClass((prevData) => ({ ...prevData, TYPE: '', CONTENT: '', RESERVE: '', TIME: 300 }));
      }, 100);

      setTimeout(() => {
        setToast({ TYPE: 'alert', CONTENT: '\'5 minutes\' of class time has been extended.', TIME: 3 });
      }, 200);

      if (`${process.env.REACT_APP_ENV !== 'PRODUCTION'}`) {
        console.log('Check-in has been extended by 5 minutes.');
      }

    } else {
      if (`${process.env.REACT_APP_ENV !== 'PRODUCTION'}`) {
        console.log('Failed to extend check-in.');
      }
      setIsVisible(false);
      setTimeout(() => {
        setToastClass((prevData) => ({ ...prevData, TYPE: '', CONTENT: '', RESERVE: '', TIME: 300 }));
      }, 100);

      setTimeout(() => {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to extend class time.', TIME: 3 });
      }, 200);
    }
  };



  return { isVisible, miniClass, toast, toastClass, toastClose, checkInTimeInfo, thisScheduler, runCheckIn, checkInExReq };
};

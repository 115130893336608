import { useEffect, useState } from 'react';
import '@/components/container/service/class/ClassTalkBar.css';
import { useRecoilState } from 'recoil';
import { classTalkListStore, classTalkToggleStore } from '@/recoil/store/class';
import { getSession } from '@/hooks/common';
import { useMediaQuery } from '@mui/material';
import ClassTalkItem from '@/components/container/service/class/ClassTalkItem';
import CloseButton from '@/assets/icon/popup/close_button.svg'; // Dompurify 적용완료
import NoneData from '@/assets/icon/learning/none-data.svg';    // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



/* 수업톡 설정 */
interface ClassTalkBarProps {
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>
  getDetailMonitorRequest: (id: string) => void
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>
}

/* 수업톡 설정 */
const ClassTalkBar = ({ returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk }: ClassTalkBarProps) => {


  // 접근 디바이스 (PC인지 태블릿인지)
  const { deviceInfo } = getSession();
  // 화면이 1500px 이하일 경우
  const isSmallScreen = useMediaQuery('(max-width: 1500px)');
  // mini 클래스 명 선정
  const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';
  // 수업 우측 탭 노출 여부
  const [classTalkToggle, setClassTalkToggle] = useRecoilState(classTalkToggleStore);
  // 수업 톡 리스트
  const [classTalkList, setClassTalkList] = useRecoilState(classTalkListStore);
  // 수업톡 ON/OFF
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {

    if (classTalkToggle.OPEN) {
      setTimeout(() => {
        setIsVisible(true);
      }, 100);

      // 모두 읽음 처리
      isReadComplete();
    }
  }, [classTalkToggle.OPEN]);


  // 알림톡 OFF
  const navClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setClassTalkToggle((prevData) => ({ ...prevData, OPEN: false }));
    }, 310);
    isReadComplete();
  };

  // 모두 읽음 처리
  const isReadComplete = () => {
    setTimeout(() => {
      const filteredTalkData = classTalkList.map(item => {
        if (!item.isRead) {
          return { ...item, isRead: true };
        }
        return item;
      });
      setClassTalkList(filteredTalkData);
    }, 300); // 0.3 seconds timeout
  };



  return (
    <>
      {
        classTalkToggle.OPEN &&
        <>
          <div className={`layout-class-talk ${isVisible && 'visible'}`}>
          </div>
          <section className={`class-talk-section ${miniClass} ${isVisible && 'open'}`} onClick={isReadComplete} onTouchStart={isReadComplete}>
            <div className='class-talk-close'>
              <figure className='class-talk-img' style={{ cursor: 'pointer' }} onClick={() => navClose()}>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} />
              </figure>
            </div>

            <div className='class-talk-title'>
              Class Talk
            </div>

            <div className={`class-talk-content ${(!classTalkList || classTalkList.length === 0) && 'none'}`}>
              {
                (!classTalkList || classTalkList.length === 0)
                  ?
                  <div className='class-talk-none-data'>
                    <img src={publicMethod.svgClear(NoneData)} alt={'NoneData'} draggable={false} />
                    <span>No class talk notifications.</span>
                  </div>
                  :
                  <ClassTalkItem returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage} getDetailMonitorRequest={getDetailMonitorRequest} role={'teacher'} deleteClassTalk={deleteClassTalk} />
              }
            </div>
          </section>
        </>
      }
    </>
  );
};

export default ClassTalkBar;

import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupNestingStore, popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';


/* 중첩 팝업 HOOK */
export default() => {

    // 팝업 state
    const setPopup = useSetRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 중첩 팝업 state
    const [popupNesting, setPopupNesting] = useRecoilState(popupNestingStore);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupNesting.TYPE !== ''){
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }
    }, [popupNesting]);

    // 팝업 끄기
    const popupNestingClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopupNesting((prevData) => ({ ...prevData, TYPE: '' }));
        }, 100);
    };

    // 팝업 확인
    const popupNestingConfirm =  () => {
        // 중첩 팝업 끄기
        popupNestingClose();
        // 0.4 초 뒤에 해제
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            // setPopup((prevData) => ({ ...prevData, TITLE: '', CONTENT: '', BUTTON: '', RESERVE: '' }));
        }, 100); 
    };


    return { popupNesting, popupNestingClose, popupNestingConfirm, isVisible };
};

import { ncpApi } from '@/services/network';

/* ###############################################################################
 * ############# 앱로그 업로드 ( NCP Effective Log Search & Analytics ) #############
 * ############################################################################### */

/* function interface */
export interface FunctionReq {
	body: LogBody | LogicDelayLog,
	logLevel: string
}

/* log body interface */
export interface LogBody {
	type: 'frontLog'
	device: string,
	callLocation: string,
	logicDescription: string,
	userUuid?: string,
	userName?: string,
	userType: string,
	external?: {
		externalTarget: string,
		externalParam?: any,
		externalResult?: any
	},
	internal?: {
		internalTarget: string,
		internalParam?: any,
		internalResult?: any
	},
}

/* 로직 지연시간 로그 */
export interface LogicDelayLog {
	type: 'check';
	device: string;			// os 정보 ex) Android 12
	code: string;			// 로그인(CK_0001), 디바이스로그인(CK_0002), 체크인(CK_0003), 체크아웃(CK_0004)
	description: string;
	startAt: string;		// 이벤트 시작시간
	endAt: string;			// 이벤트 종료시간
	millisecond: string;	// 로직 지연시간 Millisecond
	message: string;		// 로직 내용
}


/* Api Interface */
interface ApiReq {
	projectName: string,
	projectVersion: string,
	logLevel: string,
	logType: 'WEB',
	logSource: 'json'
	body: any
}



export const ncpLogUpdate = async(request: FunctionReq): Promise<void> => {
    if (request.body.type !== 'check' || (request.body.type === 'check' && `${process.env.REACT_APP_ENV}` === 'PRODUCTION')) {
        const endPoint = 'https://elsa-col.ncloud.com/_store';
        const method = 'POST';

        const requestBody: ApiReq = {
            projectName: `${process.env.REACT_APP_NCP_LOG_KEY}`,
            projectVersion: `${process.env.REACT_APP_VERSION}`,
            logLevel: request.logLevel,
            logType: 'WEB',
            logSource: 'json',
            body: request.body
        };
        await ncpApi<ApiReq, any>(endPoint, requestBody, method);
    }
};

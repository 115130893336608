import React from 'react';
import '@/components/popup/popup.css';
import popupEnvironmentHook from '@/hooks/components/popup/common/popupEnvironment';
import EnvironmentHigh from '@/assets/icon/popup/environment_high.svg';     // Dompurify 적용완료
import EnvironmentLow from '@/assets/icon/popup/environment_low.svg';     // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';





/* 교실환경 안내 팝업 */
const PopupEnvironment = () => {

  const { popupEnvironment, isVisible, popupClose, alarmTime } = popupEnvironmentHook();

  return (
    <>
      {popupEnvironment.TYPE === 'environment' &&
        <div className={`layout-popup environment ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>

              {/* 팝업 아이콘 */}
              <figure className='layout-popup-icon-box'>
                {
                  // 타이틀 문구가 어떻게 전달되는지 확인 필요 (높음, 낮음)
                  popupEnvironment.TITLE?.includes('High') ?
                    <img src={publicMethod.svgClear(EnvironmentHigh)} alt={'EnvironmentIcon'} draggable={false} />
                    :
                    <img src={publicMethod.svgClear(EnvironmentLow)} alt={'EnvironmentIcon'} draggable={false} />
                }
              </figure>

              {/* 팝업 타이틀 */}
              <div className='layout-popup-title'>{popupEnvironment.TITLE}</div>

              <div className='layout-popup-sub-title environment'>
                <span>Location: {popupEnvironment.RESERVE}</span>
                <span>Time: {alarmTime}</span>

              </div>

              <div className='layout-popup-update-content environment'>
                {popupEnvironment.CONTENT}
              </div>
            </article>

            <button className={'layout-popup-button'} onClick={() => popupClose()}>
              {popupEnvironment.BUTTON}
            </button>


          </div>

        </div>
      }
    </>
  );
};

export default PopupEnvironment
  ;

import './toast.css';
import ToastClose from '@/assets/icon/common/toast-close.svg';  // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import checkInToastHook from '@/hooks/components/common/toast/checkInToast';
import { ClassInfoInterface } from '@/hooks/services/checkIn';



interface CheckInToastProps {
  menuOpen: boolean;
  checkInExtendRequest: () => Promise<boolean>
  classCheckIn: (classInfo: ClassInfoInterface) => Promise<boolean>
}

/* 체크인 토스트 */
const CheckInToast = ({ menuOpen, checkInExtendRequest, classCheckIn }: CheckInToastProps) => {

  const { isVisible, miniClass, toastClass, toastClose, checkInTimeInfo, thisScheduler, runCheckIn, checkInExReq } = checkInToastHook(classCheckIn, checkInExtendRequest);

  return (
    <>
      {toastClass.TYPE.includes('check') &&
        <div className={`layout-toast ${menuOpen ? '' : 'tab-closed'} ${miniClass} ${isVisible && 'visible'}`}>
          <article className={`layout-toast-box ${miniClass}`}>

            <div className={`layout-toast-text ${toastClass.TYPE === 'checkIn' && 'blue'} ${toastClass.TYPE === 'checkOut' && 'red'}`}>

              {
                toastClass.TYPE === 'checkIn' && thisScheduler &&
                <>
                  Would you like to enter the <span>{toastClass.RESERVE} classroom?</span> The class will start in <span> {publicMethod.formatTimer(thisScheduler.sec)} </span>.
                </>
              }

              {
                toastClass.TYPE === 'checkOut' && checkInTimeInfo &&
                <>
                  The class will end in <span> {publicMethod.formatTimer(checkInTimeInfo.sec)} </span>. If you click [Extend Time], the check-in will be extended for 5 minutes.
                </>
              }

            </div>

            <div className={`layout-toast-button ${toastClass.TYPE === 'checkIn' && 'blue'} ${toastClass.TYPE === 'checkOut' && 'red'}`}>
              {
                toastClass.TYPE === 'checkIn'
                  ?
                  <button onClick={runCheckIn}>
                    Enter Classroom
                  </button>
                  :
                  <button onClick={checkInExReq}>
                    Extend Time
                  </button>
              }
              <img src={publicMethod.svgClear(ToastClose)} alt={'ToastClose'} draggable={false} onClick={() => toastClose()} />
            </div>

          </article>
        </div>
      }
    </>
  );
};

export default CheckInToast;

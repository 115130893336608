import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { popupErrorStore, popupOtherStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { adjustText } from '@/utils/table/tableMethod';
import { linkOpen, mirrorAction } from '@/services/native';
import {
  ClassTalkListInterface,
  classTalkListStore,
  classTalkPopupStore
} from '@/recoil/store/class';
import { getSession } from '@/hooks/common';
import { classGroupModeStore, myMirrorManagementStore } from '@/recoil/store/checkIn';
import { toastStore } from '@/recoil/store/common';
import { getChatToken } from '@/services/core/me/getChatToken';
import publicMethod from '@/utils/publicMethod';
import JSZip from 'jszip';
import { chatServerFileDownload, chatServerFileDownloadS3 } from '@/services/chat/chatServerFileDownload';
import { saveAs } from 'file-saver';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import { forceLogoutStore, userTokenInfoStore } from '@/recoil/store/user';
import { mirrorManagementStore } from '@/recoil/store/teacherManagement';
import { useNavigate } from 'react-router-dom';


// 타인으로부터 온 팝업 훅
// 화면공유, 파일전송, 파일 공유 요청, 링크전송, 링크 공유 요청, 메시지전송
export default (
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>,
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>,
  getDetailMonitorRequest: (id: string) => void,
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>,
  joinBlendedStreamLearning?: (roomId: string) => Promise<'SUCCESS' | 'FAIL' | 'NONE'>
) => {


  // 페이지 이동
  const navigate = useNavigate();
  // 세션값 가져오기
  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);
  // 타인 팝업 state
  const [popupOther, setPopupOther] = useRecoilState(popupOtherStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 파일 관련 텍스트
  const [displayText, setDisplayText] = useState<{ fileRealName: string }[]>([]);
  // 파일 리스트 (전송 or 공유)
  const fileList = popupOther.REQUEST ? popupOther.DATA?.eventInfo?.fileList : popupOther.DATA?.chatInfo?.fileList;
  // 링크 복사 완료 토스트
  const [copyToast, setCopyToast] = useState<boolean>(false);
  // 수업 톡 리스트
  const [classTalkList, setClassTalkList] = useRecoilState(classTalkListStore);
  // 수업 톡 리스트
  const [classTalkPopup, setClassTalkPopup] = useRecoilState(classTalkPopupStore);

  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
  const setUserTokenInfo = useSetRecoilState(userTokenInfoStore);
  const setForceLogout = useSetRecoilState(forceLogoutStore);

  // 모둠모드 상태
  const classGroupMode = useRecoilValue(classGroupModeStore);
  // 수업 상태 관리 - 미러링
  const mirrorManagement = useRecoilValue(mirrorManagementStore);

  const myMirrorManagement = useRecoilValue(myMirrorManagementStore);

  let getTargetDivision;
  let targetDivision;





  /** 팝업 노출 시키기**/
  useEffect(() => {
    if (popupOther.TYPE !== '') {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
    }
    if ((popupOther.TYPE === 'mirror' || popupOther.TYPE === 'file' || popupOther.TYPE === 'link' || popupOther.TYPE === 'msg')) {
      // 역할 변수 지정 (선생, 학생)
      getTargetDivision = popupOther.DATA?.eventInfo?.targetInfo![0].division;
      if (getTargetDivision === 'student') {
        targetDivision = ' Student';
      } else if (getTargetDivision === 'teacher') {
        targetDivision = ' Teacher';
      } else {
        targetDivision = '';
      }

    }

  }, [popupOther]);

  /** ******* 파일명 세팅 (길 경우, 확장자가 보이도록 축약 처리) ******* **/
  useEffect(() => {

    const handleResize = () => {
      const fileNameWidth = 150;
      const fileList = popupOther.DATA?.eventInfo?.fileList || popupOther.DATA?.chatInfo?.fileList;
      const newDisplayText = fileList?.map((item: any) => ({
        ...item,
        fileRealName: adjustText(item.fileRealName, fileNameWidth)
      }));
      setDisplayText(newDisplayText || []);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };


  }, [popupOther.DATA]);





  const handleTeacherScreenShare = (item: ClassTalkListInterface, approve: 'Y' | 'N') => {

    /* ******************************************************************************************
    *
    이 부분 런타임 데이터 적용하고 어떻게 바뀔지 모르니 우선 현재 공유중인지 아닌지 여부도 체크
    *
    * ******************************************************************************************/

    const isGroupMode = classGroupMode.groupMode;
    const isAlreadySharing = isGroupMode
      ? mirrorManagement.mirrorInfo.filter(u => !u.groupMirror).length > 0
      : mirrorManagement.mirrorInfo.length > 0;

    if (item.talkTitle === 'mirror') {
      if (isAlreadySharing) {
        setToast({ TYPE: 'alert', CONTENT: 'Please stop the current screen sharing and try again.', TIME: 3 });
        return;
      }
    } else {
      handleNonMirrorRequest(item, approve);
    }

    console.log('approve teacher1', JSON.stringify(item));
    responseEvent(item.eventInfo!.senderId!, item.eventInfo!.type, approve, item.talkId, item.eventInfo!.targetInfo![0].name);
  };

  const handleNonMirrorRequest = (item: ClassTalkListInterface, approve: 'Y' | 'N') => {
    if (item.talkTitle === 'file') {
      setToast({ TYPE: 'alert', CONTENT: 'File transfer has been completed.', TIME: 3 });
    }
    if (item.talkTitle === 'link') {
      setToast({ TYPE: 'alert', CONTENT: 'Link transfer has been completed.', TIME: 3 });
    }
  };


  // 요청에 대한 공유 거절, 수락을 했을 경우
  const confirmScreenShare = (approve: 'Y' | 'N', item: ClassTalkListInterface) => {
    if (approve === 'Y') {
      // 선생일 경우에만 예외처리 필요 -> 이미 선생님이 다른 학생들(A,B)을 공유중인데, 다른 학생(c)이 화면공유 요청을 했을 경우 대비
      if (sessionBaseInfo?.baseInfo.role === 'teacher') {
        console.log('approve teacher item', JSON.stringify(item));
        handleTeacherScreenShare(item, approve);
        return;
      }
    }
    responseEvent(item.eventInfo!.senderId!, item.eventInfo!.type, approve, item.talkId, item.eventInfo!.targetInfo![0].name); //임시 mark
  };



  // 화면공유 상세 함수, targetId를 넘어오는 targetInfo(배열)로 받으면 여러명으로 가능
  const responseEvent = async (senderId: string, type: string, approve: 'Y' | 'N', talkId: string, targetId: string) => {

    if (type.includes('file') || type.includes('link')) {
      const permissionMessageIssue = await returnPermissionMessage({ permission: approve === 'Y', talkId: talkId });
      if (permissionMessageIssue) {
        popupClose(talkId, true);
      }
    } else if (type.includes('mirror')) {
      const mirrorPermissionMessageIssue = await returnMirrorPermissionMessage({ permission: approve === 'Y', talkId: talkId, senderId: senderId, targetId: targetId });
      if (mirrorPermissionMessageIssue) {
        popupClose(talkId, true);
      }
    } else if (type.includes('monitor')) {
      getDetailMonitorRequest(talkId);
    } else {
      // 스트림
    }

  };

  // 팝업 끄기 + 수업톡 읽음 처리
  const popupClose = (classTalkId?: string, isDelete?: boolean) => {

    if (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') { // 전자칠판 혹은 소형칠판의 경우
      const filteredData = classTalkPopup.filter((item, index) => index !== classTalkPopup.length - 1);
      if (filteredData.length > 0) {
        setClassTalkPopup(filteredData); // 상태 업데이트
      } else {
        setClassTalkPopup([]); // 상태 업데이트
        setIsVisible(false);
        setTimeout(() => {
          setPopupOther((prevData) => ({ ...prevData, TYPE: '' }));
          if (!myMirrorManagement || (myMirrorManagement && myMirrorManagement.position !== 'sub')) {
            mirrorAction('screen', { command: 'state', state: 'min' });
          }
        }, 100);
      }
    } else { // 선생 또는 학생인 경우
      setIsVisible(false);
      // 수업톡 읽음 처리
      if (classTalkId) {
        if (isDelete) {   // 수업톡 삭제 처리
          readTalkData(classTalkId, true);
        } else {
          readTalkData(classTalkId, false);
        }
      }
      setTimeout(() => {
        setPopupOther((prevData) => ({ ...prevData, TYPE: '' }));
      }, 100);
    }
    document.body.style.overflow = '';
  };


  // 수업톡 읽음 처리 (필요에 따라 삭제)
  const readTalkData = async (classTalkId: string, isDelete: boolean) => {

    if (isDelete) { // 삭제 처리할 경우 -> 읽음처리 필요도 없이 바로 삭제
      await removeClassTalk(classTalkId);
    } else { // 수업톡 읽음 처리
      const filteredTalkData = classTalkList.map(item => {
        if (item.talkId === classTalkId) {
          return { ...item, isRead: true };
        }
        return item;
      });
      console.log('classTalkList => readTalkData', filteredTalkData);
      setClassTalkList(filteredTalkData);
    }
  };

  // 수업톡 삭제
  const removeClassTalk = async (talkId: string) => {
    const deleteClassTalkApi = await deleteClassTalk([talkId]);
    if (deleteClassTalkApi) {
      const filteredTalkData = classTalkList.filter(item => item.talkId !== talkId);
      setClassTalkList(filteredTalkData);
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'Failed to delete the class talk.', TIME: 3 });
    }
  };


  // 화상수업 거절, 수락을 했을 경우
  const confirmOnlineClass = async (approve: 'Y' | 'N', roomId: string) => {

    if (approve === 'Y' && joinBlendedStreamLearning) {
      const joinState = await joinBlendedStreamLearning(roomId);
      if (joinState === 'SUCCESS') {
        setToast({ TYPE: 'alert', CONTENT: 'You have joined the video class.', TIME: 3 });
      } else {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to join the video class.', TIME: 3 });
      }
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'You have declined to join the video class.', TIME: 3 });
    }
    popupClose();
  };



  // 링크 복사하기
  const copyLink = (talkId: string) => {
    if (popupOther.REQUEST) {
      handleCopyClipBoard(`${popupOther.DATA?.eventInfo?.str}`);
    } else {
      handleCopyClipBoard(`${popupOther.DATA?.chatInfo?.str}`);
    }
    // 팝업 닫지 않고 수업톡 읽음 처리
    readTalkData(talkId, false);
  };

  // 링크 복사 상세 함수
  const handleCopyClipBoard = async (text: string) => {
    try {
      // 클립보드 사용 가능 -> PC
      if (navigator.clipboard?.writeText) {
        await navigator.clipboard.writeText(text);
      }
      // 클립보드 사용 불가능 -> TABLET, MOBILE
      else {
        const input = document.createElement('textarea');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
      }

      // 링크 복사 완료 토스트
      setCopyToast(true);
      setTimeout(() => {
        setCopyToast(false);
      }, 3000);

    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  // 주소로 이동
  const moveLink = (talkId: string) => {
    if (popupOther.REQUEST) {
      linkOpen('out', `${popupOther.DATA?.eventInfo?.str}`);
    } else {
      linkOpen('out', `${popupOther.DATA?.chatInfo?.str}`);
    }
    // 팝업 닫기 후, 수업톡 읽음 처리, 삭제 처리
    popupClose(talkId, false);
  };




  // 파일 다운로드
  // 토큰 처리 로직을 별도의 함수로 분리
  const handleChatToken = async (): Promise<string | null> => {
    const ctApiRes = await getChatToken({ id: sessionBaseInfo?.baseInfo.userId!, accessToken: sessionTokenInfo.coreAccessToken! });

    if (ctApiRes.message === 'SUCCESS' && ctApiRes.data) {
      setLoginSessionCount(publicMethod.sessionUpdate);
      setUserTokenInfo({
        coreAccessToken: sessionTokenInfo.coreAccessToken,
        chatAccessToken: ctApiRes.data.accessToken,
        chatRefreshToken: ctApiRes.data.refreshToken
      });
      return ctApiRes.data.accessToken;
    } else if (ctApiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
      return null;
    } else if (ctApiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
      return null;
    } else if (ctApiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      return null;
    } else {
      setPopupError(prevData => [...prevData, { CODE: ctApiRes.errorCode, MESSAGE: ctApiRes.errorMessage }]);
      return null;
    }
  };

  // 여러 파일을 다운로드하고 ZIP 파일로 만드는 함수
  const downloadAndZipFiles = async (fileInfo: { fileUrl: string, fileName: string }[], accessToken: string) => {
    const zip = new JSZip();
    for (const f of fileInfo) {

      let blob;
      if (sessionBaseInfo?.baseInfo.role === 'board') {
        blob = await chatServerFileDownloadS3({ token: accessToken, fileUrl: f.fileUrl });
      }
      else {
        blob = await chatServerFileDownload({ token: accessToken, fileUrl: f.fileUrl });
      }
      if (blob.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
        break;
      }
      zip.file(f.fileName, blob, { binary: true });
    }
    const content = await zip.generateAsync({ type: 'blob' });
    window.MirrorBLOBDownloadFileName(`${fileInfo[0].fileName} 외 ${fileInfo.length - 1}건.zip`);
    saveAs(content, `${fileInfo[0].fileName} 외 ${fileInfo.length - 1}건.zip`);
  };

  // 단일 파일을 다운로드하는 함수
  const downloadSingleFile = async (fileInfo: { fileUrl: string, fileName: string }[], accessToken: string) => {

    let blob;
    if (sessionBaseInfo?.baseInfo.role === 'board') {
      blob = await chatServerFileDownloadS3({ token: accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    else {
      blob = await chatServerFileDownload({ token: accessToken, fileUrl: fileInfo[0].fileUrl });
    }
    if (blob.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      const file = new File([blob], fileInfo[0].fileName, { type: blob.type });
      window.MirrorBLOBDownloadFileName(fileInfo[0].fileName);
      saveAs(file, fileInfo[0].fileName);
    }
  };

  // 파일 다운로드
  const downloadFiles = async (fileInfo: { fileUrl: string, fileName: string }[]) => {

    const chatToken = await handleChatToken();

    if (chatToken) {
      if (fileInfo.length > 1) {
        await downloadAndZipFiles(fileInfo, chatToken);
      } else {
        await downloadSingleFile(fileInfo, chatToken);
      }
    }


  };

  // 저장 버튼 클릭 시 (저장, 전체 저장)
  const downloadAll = (talkId: string) => {
    // 받은 fileList를 모두 다운로드하기 (fileInfo: { fileUrl: string, fileName: string }[])
    const downloadList = fileList?.map((item: any) => ({
      fileUrl: item.fileUrl,
      fileName: item.fileRealName
    }));
    console.log('LINK SCHOOL 전체 다운로드', downloadList);
    downloadFiles(downloadList!);
    // 팝업 닫기 후, 수업톡 읽음 처리, 삭제 처리
    popupClose(talkId, false);
  };






  return { popupOther, isVisible, copyLink, moveLink, removeClassTalk, popupClose, displayText, fileList, copyToast, confirmScreenShare, confirmOnlineClass, sessionBaseInfo, targetDivision, downloadFiles, downloadAll };
};

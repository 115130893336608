import { coreApi } from '@/services/network';
import { FrontSessionBaseInfo } from '@/services/core/me/getProfile';
import { downloadLogoFile } from '@/services/content/core/downloadLogoFile';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##################################
 * ############# 회원정보 #############
 * ################################## */

/* function interface */
interface DeviceProfile {
  ipAddress: string                       // IP 주소
  currentSemester?: {                     // 현재 학기
    endDate: string
    semester: number
    startDate: string
    numberOfWeek: { week: number, year: number }[]
  } | null
  currentWeek?: number | null             // 현재 주차
  checkInSession: {                      // 체크인 관련
    classId?: string                    // 교실 id
    checkIn: boolean                    // 체크인 여부
    checkOutAt: string | null           // 체크아웃 시간
    checkInAt: string | null            // 체크인 시간
  }
  baseInfo: {
    name: string
    chatroomIds: string[]
    schoolId: string
    deviceId: string
    modelName: string
    categoryId: string
    type: string | null
    linkSchoolDeviceId: string
  }
  mappingClass?: {
    classId: string
    className: string                   // 교실명
    grade: string                       // 학년
  }[]
  mqtt: {
    id: string;
    pwd: string;
  }
  schoolInfo: {
    classId: string                     // 메인 교실 id
    className: string                   // 교실명
    schoolClassification: 'General' | 'School'  // 학교/비학교 구분
    departmentName: string              // 학과정보
    grade: string                       // 학년
    schoolName: string                  // 학교명
    schoolId: string                    // 학교 id
    logoList?: {
      logoType: 'wlogo' | 'hlogo'     // 가로형 | 세로형
      base64Image: string
    }[]
  }
}

export interface FunctionReq {
  accessToken: string
  categoryId: string
}

export interface FunctionRes {
  message: string        // 성공(SUCCESS)/2차인증(VERIFY)/중복로그인(ALREADY)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  code: string
  data?: FrontSessionBaseInfo
}



/* Api Interface */
interface ApiRes {
  status: number,
  data: DeviceProfile
  code: string
  message: string
}




export const getDeviceProfile = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/device/me`;
  const method = 'GET';

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint, {}, method, request.accessToken, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve device profile.';

  if (res.code === CoreResponseCode.GET_PROFILE_SUCCESS && res.data) {
    const data = res.data;
    const returnObj: any = {
      message,
      errorCode,
      errorMessage,
      code: res.code,
      data: {
        ...res.data,
        ipAddress: data.ipAddress,
        currentSemester: data.currentSemester,
        currentWeek: data.currentWeek,
        checkInSession: data.checkInSession,
        baseInfo: {
          role: request.categoryId === 'OPS' ? 'board' : request.categoryId.toLowerCase(),
          email: '',
          phone: '',
          name: data.baseInfo.name,
          username: data.baseInfo.deviceId,
          chatroomIds: data.baseInfo.chatroomIds,
          authRejectReason: null,
          neisId: '',
          schoolId: data.baseInfo.schoolId,
          status: 'approved',
          userId: data.baseInfo.deviceId,
          auth: true
        },
        mappingClass: data.mappingClass,
        schoolInfo: {
          classId: data.schoolInfo.classId,
          userClassId: '',
          classification: '',
          classificationType: [],
          className: data.schoolInfo.className,
          schoolClassification: data.schoolInfo.schoolClassification,
          departmentName: data.schoolInfo.departmentName,
          grade: data.schoolInfo.grade,
          schoolName: data.schoolInfo.schoolName,
          schoolId: data.schoolInfo.schoolId,
          number: null
        },
        welcomeSession: '',
        accessTime: {
          app: ''
        }
      }
    };
    if (!['OPS', 'Onequick'].includes(request.categoryId)) {
      try {
        if (data.schoolInfo.schoolId) {
          const getLogoFile = await downloadLogoFile({ token: request.accessToken, schoolId: data.schoolInfo.schoolId });
          if (getLogoFile.code === 'OK' && getLogoFile.data) {
            if (getLogoFile.data.length > 0) {
              returnObj.data.schoolInfo.logoList = getLogoFile.data.map(item => {
                return {
                  logoType: item.logoType,
                  base64Image: item.base64Image
                };
              });
            }
          }
        }
      } catch (e: any) {
        console.log(e);
      }
    }
    return returnObj;
  }

  return {
    message,
    errorCode,
    errorMessage,
    code: res.code
  };

};

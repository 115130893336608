import { getSession } from '@/hooks/common';
import { mqttGlobalAlimConnect, MqttGlobalAlimResponse } from '@/services/globalMqtt';
import { useEffect, useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { popupEnvironmentStore } from '@/recoil/store/popup';
import { v4 as uuidv4 } from 'uuid';



export default () => {

  // 세션, 토큰 정보
  const { sessionBaseInfo } = getSession();
  const [globalAlimMessages, setGlobalAlimMessages] = useState<MqttGlobalAlimResponse[]>([]);
  // 교실환경 팝업 state
  const setPopupEnvironment = useSetRecoilState(popupEnvironmentStore);






  /* Global mqtt connect */
  useEffect(() => {
    if (sessionBaseInfo?.baseInfo.role === 'signage') {
      const uniqueId = uuidv4();
      const globalAlimMessageRequest = {
        userId: sessionBaseInfo?.baseInfo.userId,
        uniqueId: uniqueId,
        setGlobalAlimMessages: setGlobalAlimMessages,
        mqttAccountInfo: sessionBaseInfo?.mqtt
      };
      mqttGlobalAlimConnect(globalAlimMessageRequest);
    }
  }, [sessionBaseInfo]);

  useEffect(() => {

    if (globalAlimMessages.length > 0) {

      const targetMessage = globalAlimMessages[0];
      const message = targetMessage.message;

      if (targetMessage.notificationType === 'CLASSROOM_ENVIRONMENT') {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('교실환경 알림', message);
        }

        // 교실환경 알림 메시지 분리
        // message split to ^ ->  {학년}$^${반}$^${title}$^${message}
        const splitMessage = message.split('$^$');
        const environment_class = `${splitMessage[0]} - ${splitMessage[1]} Class`;
        const environment_title = splitMessage[2];
        const environment_content = splitMessage[3];



        setGlobalAlimMessages(prev => prev.slice(1));
        // 교실환경 팝업 띄우기
        setPopupEnvironment({ TYPE: 'environmentSignage', TITLE: environment_title, CONTENT: environment_content, BUTTON: 'OK', RESERVE: environment_class });
      }
    }
  }, [globalAlimMessages]);

};

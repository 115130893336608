import React from 'react';
import '@/pages/service/teacher/class/home.css';

/* 네트워크에 연결할 수 없습니다. */
const PageNetwork = () => {

  return (
    <article className='hna-inner-box'>
      <span className='hna-inner-title'>
        Unable to connect to the network.
      </span>
      <span className='hna-inner-sub-title'>
        Please check your network connection and try again.
      </span>
    </article>
  );
};

export default PageNetwork;

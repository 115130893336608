import { contentApi } from '@/services/network';
import { GroupListManagementInterface } from '@/recoil/store/teacherManagement';

/* #########################################
 * ############# 보유 모둠 목록조회 #############
 * ######################################### */


/* function interface */
export interface functionReq {
  token: string
}

export interface functionRes {
  code: string
  status: number
  message: string
  data?: GroupListManagementInterface[]
}




/* Api Interface */
interface apiRes {
  code: string
  status: number
  message: string
  data?: GroupListManagementInterface[]
}



export const getGroupList = async (request: functionReq): Promise<functionRes> => {

  const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/setting/groups`;
  const method = 'GET';

  const res = await contentApi<{}, apiRes>(endPoint, {}, method, request.token, null);

  if (res.code === 'OK') {

    return {
      code: 'OK',
      status: res.status,
      message: '보유그룹 조회 성공',
      data: res.data
    };
  } else {
    return {
      code: 'FAIL',
      status: res.status,
      message: '보유그룹 조회 실패'
    };
  }

};

/* Schedule 데이터 관리 */
import { atom } from 'recoil';

export interface TimeTableInterface {
    today: string           // YYYYMMDD
    timeTable: {
        classId: string         // 체크인 교실 Key
        className: string       // 대상교실 별칭
        startTime: string       // 시작시간
        endTime: string         // 종료시간
    }[]
}

export interface ThisSchedulerInterface {
    classId: string
    className: string
    startTime: string
    endTime: string
    inOut: 'IN' | 'OUT'
    sec: number        // -300 : 체크인 시간까지 300초남음, 300 : 체크아웃 시간까지 300초 남음
}

/* 체크인 시간관리 ( 체크아웃 시간체크 등 ) */
export interface CheckInTimeInfoInterface {
    classId: string;
    checkOutTime: string;
    sec: number        // 체크아웃까지 남은시간 체크
}

export const timeTableStore = atom<TimeTableInterface | null>({
    key: 'timeTable',
    default: null
});

export const thisSchedulerStore = atom<ThisSchedulerInterface | null>({
    key: 'thisScheduler',
    default: null
});

export const checkInTimeInfoStore = atom<CheckInTimeInfoInterface | null>({
    key: 'checkInTimeInfo',
    default: null
});





/* 로그인 세션관리용 Recoil */
export interface sessionTimeInfoInterface {
    sessionOutTime: string;
    popupOn: boolean;
    sec: number        // 체크아웃까지 남은시간 체크
}
export const loginSessionCountStore = atom<sessionTimeInfoInterface | null>({
    key: 'loginSession',
    default: null
});

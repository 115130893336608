// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inner-section{display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 24px 24px 24px 36px; width: 100%; height: calc(100% - 68px); overflow-y: auto; }
.inner-section.none-padding{padding-bottom: 0px;}
.inner-section.none-overflow{overflow-y: unset}
.inner-section-admin{display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 24px ; width: 100%; height: 100%; overflow-y: auto; }
.inner-page{ display: flex; box-sizing: border-box; width: 100%; height: 100%; max-width: 1500px; max-height: 890px;  }
.inner-page.center{ align-items: center; }
.inner-page-admin{ display: flex; box-sizing: border-box; width: 100%; height: 100%; max-width: 1500px; max-height: 100%;  }




`, "",{"version":3,"sources":["webpack://./src/pages/service/common.css"],"names":[],"mappings":"AAAA,eAAe,aAAa,EAAE,uBAAuB,EAAE,mBAAmB,EAAE,sBAAsB,EAAE,4BAA4B,EAAE,WAAW,EAAE,yBAAyB,EAAE,gBAAgB,EAAE;AAC5L,4BAA4B,mBAAmB,CAAC;AAChD,6BAA6B,iBAAiB;AAC9C,qBAAqB,aAAa,EAAE,uBAAuB,EAAE,mBAAmB,EAAE,sBAAsB,EAAE,cAAc,EAAE,WAAW,EAAE,YAAY,EAAE,gBAAgB,EAAE;AACvK,aAAa,aAAa,EAAE,sBAAsB,EAAE,WAAW,EAAE,YAAY,EAAE,iBAAiB,EAAE,iBAAiB,GAAG;AACtH,oBAAoB,mBAAmB,EAAE;AACzC,mBAAmB,aAAa,EAAE,sBAAsB,EAAE,WAAW,EAAE,YAAY,EAAE,iBAAiB,EAAE,gBAAgB,GAAG","sourcesContent":[".inner-section{display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 24px 24px 24px 36px; width: 100%; height: calc(100% - 68px); overflow-y: auto; }\n.inner-section.none-padding{padding-bottom: 0px;}\n.inner-section.none-overflow{overflow-y: unset}\n.inner-section-admin{display: flex; justify-content: center; align-items: center; box-sizing: border-box; padding: 24px ; width: 100%; height: 100%; overflow-y: auto; }\n.inner-page{ display: flex; box-sizing: border-box; width: 100%; height: 100%; max-width: 1500px; max-height: 890px;  }\n.inner-page.center{ align-items: center; }\n.inner-page-admin{ display: flex; box-sizing: border-box; width: 100%; height: 100%; max-width: 1500px; max-height: 100%;  }\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

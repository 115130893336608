declare global {
    interface AppSettingsInterface {
        feedMax: number
    }
    interface FeedsInterface {
        rfid: string
        rfdisplay: string
    }
    interface Window {
        MirrorCheckIn: (mirrorSettings: any, callback: (msg: any) => void) => void;
        MirrorCommand: (command: any) => void;
        MirrorGetImageBase: () => string;
        MirrorGetFileBase: (pullPath: string) => string;
        MirrorBrowserInternal: (link: string) => void;
        MirrorBrowserExternal: (link: string) => void;
        MirrorSendAction: (action: string, param: string) => void | string;
        MirrorCallbackAction: (callback: (action: string, param: string) => void) => void;
        MirrorFloatingCallbackMessage(callbackfunction: (msg: string) => void): void;
        MirrorMainCallbackMessage(callbackfunction: (msg: string) => void): void;
        MirrorMainMessage(msg: string): void;
        MirrorFloatingMessage(msg: string): void;
        MirrorSetLock(isLock: boolean, timeoutsec: number): void;
        MirrorBrowserPopup(url: string): void;
        MirrorPopupAuth(url: string): void;
        MirrorPopupResult(result: string): void;
        MirrorGetImageBase100: () => string
        getUserVideoIndex: (uuid: string) => number;
        MirrorGetDownloadPath: () => string;
        MirrorSelectDownloadPath: () => string;
        MirrorFloatingShow: (isShow: boolean, url: string) => void;
        MirrorBLOBDownloadFileName: (fileName: string) => void;
        appSettings: AppSettingsInterface;
        feeds: FeedsInterface[];
    }
}

/* ###############################################
 * ############# native 요청 Function #############
 * ############################################### */
export const mirrorAction = (action: string, param: any): void | string => {
    return window.MirrorSendAction(action, JSON.stringify(param));
};


/* ######################################################
 * ############# In App / Out App Link open #############
 * ###################################################### */
export const linkOpen = (inOut: string, link: string): boolean => {
    try {
        if (inOut === 'in') {
            window.MirrorBrowserInternal(link);
        } else if (inOut === 'out') {
            window.MirrorBrowserExternal(link);
        }
        return true;
    } catch (e: any) {
        console.log(`
            ========================================== LINK SCHOOL - LINK OPEN FAIL ==========================================
            ${e.message}
            ======================================================================================================================`);
        return false;
    }
};





/* ##################################################
 * ############# Media box send command #############
 * ################################################## */

/* Interface 공통 */
type CommonProperties = {
    commandDivision?: string;
    classroom: string;
    command: string;
    type: string;
    sender: string;
};

/* WebRtc room 생성 요청 */
export interface MediaBoxRoomOpen extends CommonProperties {
    data: {
        pubs: string[]
    }
}

/* WebRtc room 전체 파괴 */
export interface MediaBoxRoomDeleteAll extends CommonProperties {}

/* WebRtc room 참여 요청 */
export interface MediaBoxJoinRoomRequest extends CommonProperties {
    data: {
        pubs: string[]
        subs: string[]
        roomnum: number
    }
}

/* Mirror Stop Request Interface */
export interface MediaBoxStopMirror extends CommonProperties {
    data: {
        mirror: {
            roomnum: number
        }
    }
}

/* Mirror Start Request Interface */
export interface MediaBoxStartMirror extends CommonProperties {
    data: {
        pubs: string[],
        subs: string[]
    }
}

/* register, unRegister Interface */
export interface MediaBoxRegisterRequest extends CommonProperties {
    role: string
}

/* monitorstart, monitorstop Interface */
export interface MediaBoxStartMonitor extends CommonProperties {
    data?: {
        monitorSettings: {
            intervalSec: number
            maxCount: number
            sizePercent: number
        }
    }
}

export type MediaBoxCommand = MediaBoxStopMirror | MediaBoxStartMirror |
    MediaBoxRegisterRequest | MediaBoxStartMonitor | MediaBoxJoinRoomRequest |
    MediaBoxRoomOpen | MediaBoxRoomDeleteAll;

export const mirrorCommand = (commandData: MediaBoxCommand): boolean => {
    if (commandData.commandDivision) {
        console.log(commandData.commandDivision);
    }
    delete commandData.commandDivision;
    console.log(`LINK SCHOOL - MIRROR COMMAND : ${JSON.stringify(commandData)}`);
    try {
        window.MirrorCommand(commandData);
        return true;
    } catch (e: any) {
        console.log(`
            ========================================== LINK SCHOOL - WEB MIRROR COMMAND FAIL ==========================================
            ${e.message}
            ======================================================================================================================`);
        return false;
    }
};

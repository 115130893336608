import React from 'react';
import '@/components/popup/popup.css';
import popupSystemHook from '@/hooks/components/popup/common/popupSystem';
import publicMethod from '@/utils/publicMethod';
import SystemIcon from '@/assets/icon/popup/system-icon.svg';   // Dompurify 적용완료





/* 시스템 안내 팝업 */
const PopupSystem = () => {

  const { popupSystem, isVisible, popupClose, confirmSystemCheck, confirmSystemUpdate } = popupSystemHook();

  return (
    <>
      {popupSystem.TYPE.includes('system') &&
        <div className={`layout-popup system ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>

              {/* 팝업 아이콘 */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(SystemIcon)} alt={'SystemIcon'} draggable={false} />
              </figure>

              {/* 팝업 타이틀 */}
              <div className='layout-popup-title'>{popupSystem.TITLE}</div>


              {
                popupSystem.TYPE === 'systemCheck' &&
                <>
                  <div className='layout-popup-common-sub'>
                    {popupSystem.CONTENT}
                  </div>
                  <div className='layout-popup-common-content center'>
                    <span>{popupSystem.RESERVE}</span>
                  </div>
                </>
              }

              {
                popupSystem.TYPE === 'systemUpdateAlarm' &&
                <div className='layout-popup-common-content center'>
                  <span>{popupSystem.RESERVE}</span>
                </div>
              }


              {
                popupSystem.TYPE === 'systemUpdateHistory' &&
                <div className='layout-popup-update-content'>

                  <p> {popupSystem.RESERVE} Version</p>

                  <span className='layout-popup-update-content-detail'>
                    {popupSystem.CONTENT}
                  </span>
                </div>
              }
            </article>


            {
              popupSystem.TYPE === 'systemCheck' &&
              <button className={'layout-popup-button'} onClick={() => confirmSystemCheck()}>
                {popupSystem.BUTTON}
              </button>
            }

            {
              popupSystem.TYPE === 'systemUpdateAlarm' &&
              <button className={'layout-popup-button'} onClick={() => confirmSystemUpdate()}>
                {popupSystem.BUTTON}
              </button>
            }

            {
              popupSystem.TYPE === 'systemUpdateHistory' &&
              <button className={'layout-popup-button'} onClick={() => popupClose()}>
                {popupSystem.BUTTON}
              </button>
            }



          </div>

        </div>
      }
    </>
  );
};

export default PopupSystem
  ;

import { grmApi } from '@/services/network';
import { GooroomeeMappingCode, GooroomeeResponseCode } from '@/utils/code-enums';

/* ########################################
 * ############# 구루미 OTP 생성 #############
 * ######################################## */





/* function interface */
export interface FunctionReq {
    roomId: string
    username: string
    roleId: string   // participant : 학생 / speaker : 교사
    apiUserId: string,
}
export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
    otp?: string
}


/* Api Interface */
interface ApiReq {
    roomId: string
    username: string
    roleId: string
    apiUserId: string
}
interface ApiRes {
    status: number
    code: string
    message: string
    data?: {
        otp: string
        expiresIn: number
    }
}



export const makeRoomOtp = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_GOOROOMEE_SERVER}/openapi/v3/rooms/users/otp`;
    const method = 'POST';

    const requestBody: ApiReq = {
        roomId: request.roomId,
        username: request.username,
        roleId: request.roleId,
        apiUserId: request.apiUserId
    };

    const res: ApiRes = await grmApi<ApiReq, ApiRes>(endPoint, requestBody, method);

    const message = GooroomeeMappingCode[res.code as GooroomeeResponseCode] || 'FAIL';

    if (res.code === GooroomeeResponseCode.SUCCESS && res.data) {
        return {
            message,
            otp: res.data?.otp
        };
    }
    return { message };

};

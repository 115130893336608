import { atom } from 'recoil';


/* 음원카테고리 목록 */
export interface MusicCategoryListInterface {
    id: string;
    code: string;
    value: string;
}
export const musicCategoryInfoStore = atom<MusicCategoryListInterface[]>({
    key: 'musicCategoryList',
    default: []
});


/* 현재 카테고리의 음원정보 */
export interface CategoryMusicListInterface {
    id: number;
    name: string;
    duration: string;
    format: string;
    fileName: string;
    fileLength: string;
    contentInfoId: number;
    thumbnailId: number;
    thumbnailUrl: string;
}
export const categoryMusicListStore = atom<CategoryMusicListInterface[]>({
    key: 'categoryMusicList',
    default: []
});

export const categoryChangeLoadingStore = atom<boolean>({
    key: 'categoryChangeLoading',
    default: false
});






/* 전역 플레이어 정보 (다운로드받은 카테고리 리스트와 완전 별개로 운영) */
export interface PlayerModuleMusic {
    id: number;
    name: string;
    duration: string;
    format: string;
    fileName: string;
    fileLength: string;
    contentInfoId: number;
    thumbnailId: number;
    thumbnailUrl: string; // 다운로드된 썸네일 데이터 URL을 저장할 필드
    audioUrl?: string;  // 다운로드된 오디오 데이터 URL을 저장할 필드
}
export interface MusicPlayerInterface {
    musicDownloading: boolean;
    playOrder: number; // 반복없음 - 0, 반복 - 1, 한곡반복 - 2
    musicList: PlayerModuleMusic[];
    categoryIndex: number;
    currentTrackIndex: number;
    isPlaying: boolean;
    elapsedTime: number;
    totalTime: number;
    replay: boolean;
}
export const musicPlayerStore = atom<MusicPlayerInterface>({
    key: 'musicPlayer',
    default: {
        musicDownloading: false,
        playOrder: 0, // 반복없음 - 0, 반복 - 1, 한곡반복 - 2
        musicList: [],
        categoryIndex: 0,
        currentTrackIndex: 0,
        isPlaying: false,
        elapsedTime: 0,
        totalTime: 0,
        replay: false
    }
});

// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import TestLayout from '@/layout/service/testLayout';
import Loadable from '@/components/Loadable';

/* 테스트 페이지 */
const TestMain1 = Loadable(lazy(() => import('@/pages/service/test/main1')));
const TestSub1 = Loadable(lazy(() => import('@/pages/service/test/sub1')));
const TestSub2 = Loadable(lazy(() => import('@/pages/service/test/sub2')));
const TestSub3 = Loadable(lazy(() => import('@/pages/service/test/sub3')));


const TestRoutes = {
    path: '/test',
    element: <TestLayout />,
    children: [
        /* url 바로 접근시  */
        { path: '', element: <TestMain1/> },
        { path: 'main1', element: <TestMain1/> },
        { path: 'sub1', element: <TestSub1/> },
        { path: 'sub2', element: <TestSub2/> },
        { path: 'sub3', element: <TestSub3/> },
        /* 404 오류 페이지 접근 시*/
        { path: '*', element: <TestMain1 /> }
    ]
};

export default TestRoutes;

import { statusApi } from '@/services/network';
import { MirrorInfoInterface } from '@/recoil/store/teacherManagement';
import { CheckInUserInterface, ClassGroupInfoInterface } from '@/hooks/services/checkIn';
import {
  ContentMappingCode,
  ContentResponseCode,
  StatusMappingLogCode,
  StatusResponseErrorCode
} from '@/utils/code-enums';

/* #######################################
 * ############# 수업 상태 조회 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
  lectureId: string
  token: string
}
export interface FunctionRes {
  message: string;
  code: string;
  errorCode: string;
  errorMessage: string;
  data?: {
    lectureId: string;                                          // 수업 id
    classId: string;                                            // 교실 id
    chatRoomIds: string[];                                      // 공용 채팅방 id
    myRoomId: string[];                                         // 개인 채팅방 id list
    lectureSubjectClassification: lectureSubjectClassification;   // 수업 분류 코드
    subject?: string;                                           // 과목명
    subjectId?: string;                                         // 과목 id
    classSubject?: string;                                      // 교실과목명
    classSubjectId?: string;                                    // 교실과목 id
    currentTimeSlotIndex: string;                               // 현재 시간표 인덱스
    mediaBoxIp?: string;                                        // 미디어박스 ip
    department?: string;                                        // 학과
    checkOutTime: string;                                       // 체크아웃 시간
    checkedInAt: string;                                        // 체크인 시간
    breakTime: boolean;                                         // 쉬는시간 여부
    usersInfo: CheckInUserInterface[];                          // 해당 교실의 시간표에 해당하는 선생님/학생/전자칠판/원퀵 리스트
    monitoring: MonitoringInterface;                            // 모니터링 정보
    mirrorInfo: MirrorInfoInterface[];                          // 미러링 정보
    groupMode: boolean;                                         // 그룹모드 여부
    groupInfo: ClassGroupInfoInterface | null;                  // 그룹 정보
    chatroomToken: ChatroomTokenInterface;                      // Utility 토큰 정보
  }
}

export interface ChatroomTokenInterface {
  grantType: string;
  accessToken: string;
  refreshToken: string;
  refreshTokenExpirationTime: string;
  userId: string;
  userNickName: string;
  userGroup: string;
  userRole: string;
}

export interface MonitoringInterface {
  resolution: number;
  monitoringEnds: number;
  monitoringCycle: number;
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  data?: {
    lectureId: string;                                          // 수업 id
    classRoomId: string;                                        // 교실 id
    chatRoomIds: string[];                                      // 공용 채팅방 id
    myRoomId: string[];                                         // 개인 채팅방 id list
    lectureSubjectClassification: lectureSubjectClassification;   // 수업 분류 코드
    subject?: string;                                           // 과목명
    subjectId?: string;                                         // 과목 id
    classSubject?: string;                                      // 교실과목명
    classSubjectId?: string;                                    // 교실과목 id
    currentTimeSlotIndex: string;                               // 현재 시간표 인덱스
    mediaBoxIp?: string;                                        // 미디어박스 ip
    department?: string;                                        // 학과
    checkOutTime: string;                                       // 체크아웃 시간
    checkedInAt: string;                                        // 체크인 시간
    breakTime: boolean;                                         // 쉬는시간 여부
    usersInfo: CheckInUserInterface[];                          // 해당 교실의 시간표에 해당하는 선생님/학생/전자칠판/원퀵 리스트
    monitoring: MonitoringInterface;                            // 모니터링 정보
    mirrorInfo: MirrorInfoInterface[];                          // 미러링 정보
    groupMode: boolean;                                         // 그룹모드 여부
    groupInfo?: ClassGroupInfoInterface;                        // 그룹 정보
    chatroomToken: ChatroomTokenInterface;                      // Utility 토큰 정보
  }
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}

export type lectureSubjectClassification = 'CLASS' | 'BREAK' | 'FREE';



export const getLectureState = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/state/${request.lectureId}`;
  const method = 'POST';

  const res: ApiRes = await statusApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to retrieve class information.';

  if (res.code === 'OK' && res.data) {

    const transformedClassInList = res.data.usersInfo.map(item => ({
      ...item,
      division: item.division.toLowerCase() === 'ops' ? 'board' : item.division.toLowerCase() === 'OneQuick/Signage' ? 'onequick' : item.division.toLowerCase()
    }));

    res.data.usersInfo = transformedClassInList.map(u => {
      if (u.division === 'student' || u.division === 'teacher' || u.division === 'board' || u.division === 'onequick') {
        return u;
      }
      return undefined;
    }).filter((u): u is CheckInUserInterface => u !== undefined);

    res.data.usersInfo = res.data.usersInfo.filter(u => u.roomId && u.roomId.length > 0);

    const changeData: any = res.data;
    changeData.classId = changeData.classRoomId;
    delete changeData.classRoomId;

    return {
      code: res.code,
      data: changeData,
      errorCode,
      errorMessage,
      message: '조회 성공'
    };
  }
  return {
    code: res.label,
    errorCode,
    errorMessage,
    message: res.message
  };
};

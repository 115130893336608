import { statusApi } from '@/services/network';
import {
  ContentMappingCode,
  ContentResponseCode,
  StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* ################################################
 * ############# 개인 화면잠금 상태 업데이트 #############
 * ################################################ */





/* function interface */
export interface FunctionReq {
  lectureId: string;
  userId?: string;
  locked: boolean;
  token: string;
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  code: string
}


/* Api Interface */
interface ApiReq {
  locked: boolean;
}
interface ApiRes {
  status: number,         // status
  data?: any
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const userLockUpdate = async (request: FunctionReq): Promise<FunctionRes> => {
  let endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/state/user-info/locked/${request.lectureId}`;
  if (request.userId) {
    endPoint += `/${request.userId}`;
  }
  const method = 'PUT';

  const req: ApiReq = {
    locked: request.locked
  };

  const res: ApiRes = await statusApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to request screen lock.';

  return {
    message,
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label
  };
};

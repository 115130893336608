import { useRecoilValue, useSetRecoilState } from 'recoil';
import {
  userBaseInfoStore,
  userRedirectDataStore, userTempInfoStore,
  userTokenInfoStore
} from '@/recoil/store/user';
import { useEffect, useState } from 'react';
import { deviceLogin } from '@/services/core/device/deviceLogin';
import { deviceInfoStore, NetworkStatusStore, WinPcDownloadPathStore } from '@/recoil/store/common';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { LogicDelayLog, ncpLogUpdate } from '@/services/ncloud/saveAppLog';
import { mirrorAction } from '@/services/native';
import { popupErrorStore } from '@/recoil/store/popup';
import { sessionExtend } from '@/services/core/me/sessionExtend';


export default () => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setUserBaseInfo = useSetRecoilState(userBaseInfoStore);
  const setUserTokenInfo = useSetRecoilState(userTokenInfoStore);
  const userTempInfo = useRecoilValue(userTempInfoStore);
  const setDeviceInfo = useSetRecoilState(deviceInfoStore);
  const setUserRedirectData = useSetRecoilState(userRedirectDataStore);
  const [targetRoute, setTargetRoute] = useState<string | null>(null);
  const setWinPcDownloadPath = useSetRecoilState(WinPcDownloadPathStore);
  const setNetworkStatus = useSetRecoilState(NetworkStatusStore);

  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);

  const delayLogBody: LogicDelayLog = {
    type: 'check',
    device: '',
    code: 'CK_0002',
    description: 'Device Login',
    startAt: new Date().toISOString(),
    endAt: '',
    millisecond: '',
    message: ''
  };

  // 디바이스 정보 조회 from native
  const getDeviceInformation = async () => {
    let deviceId, deviceModel, osVersion, os, osInfo, appVersion;
    try {
      const deviceIdJson = window.MirrorSendAction('screen', JSON.stringify({ 'command': 'getdeviceid' }));
      const deviceModelJson = window.MirrorSendAction('screen', JSON.stringify({ 'command': 'getmodel' }));

      if (deviceIdJson && deviceModelJson) {
        const parseDeviceId = JSON.parse(deviceIdJson);
        const parseDeviceModel = JSON.parse(deviceModelJson);
        deviceId = parseDeviceId.deviceid;
        deviceModel = parseDeviceModel.model;
        osVersion = parseDeviceModel.osversion;
        os = parseDeviceModel.os;
        appVersion = parseDeviceModel.appversion;
        // deviceId = 'OPS_E08F4CC7C4B9';
        // deviceModel = 'OPS';
        // deviceId = 'BOARD_E08F4CC7C4B9';
        // deviceModel = 'Signage';
      } else {
        deviceId = null;
        deviceModel = null;
        osVersion = null;
        os = null;
        // deviceId = 'OPS_E08F4CC7C4B9';
        // deviceModel = 'OPS';
        // deviceId = 'BOARD_E08F4CC7C4B9';
        // deviceModel = 'Signage';
      }
    } catch (e) {
      console.error('Error parsing device information', e);
      deviceId = null;
      deviceModel = null;
      osVersion = null;
      os = null;
    }

    if (os && osVersion) {
      osInfo = `${os} ${osVersion}`;
    } else if (os) {
      osInfo = os;
    } else {
      osInfo = 'Web';
    }

    return { deviceId, deviceModel, osInfo, appVersion };
  };

  // 리디렉션 로직을 처리 ( PASS / IPIN )
  const handleRedirect = async (deviceModel: string) => {

    if (deviceModel === 'WinPC') {

      // 전달정보 ( pass, ipin )
      const tokenVersionId = searchParams.get('token_version_id');
      const encData = searchParams.get('enc_data');
      const integrityValue = searchParams.get('integrity_value');
      const code = searchParams.get('code');

      if (code) {
        window.MirrorPopupResult(JSON.stringify({ oAuthCode: code }));
      }
      else if (tokenVersionId && encData && integrityValue) {
        window.MirrorPopupResult(JSON.stringify({ tokenVersionId, encData, integrityValue }));
      }
    } else {
      const tokenVersionId = searchParams.get('token_version_id');
      const encData = searchParams.get('enc_data');
      const integrityValue = searchParams.get('integrity_value');

      if (!userTempInfo) {
        setUserBaseInfo(null);
        setUserTokenInfo({
          coreAccessToken: ''
        });
      } else {
        try {
          const localUserBaseInfo = window.localStorage.getItem('session-base-info');
          const localUserTokenInfo = window.localStorage.getItem('session-token-info');
          if (localUserBaseInfo && localUserTokenInfo) {

            const parseUserBaseInfo: any = JSON.parse(localUserBaseInfo);
            const parseUserTokenInfo = JSON.parse(localUserTokenInfo);

            setUserBaseInfo(parseUserBaseInfo);
            setUserTokenInfo(parseUserTokenInfo);

            if (tokenVersionId && encData && integrityValue) {
              setUserRedirectData({
                tokenVersionId: tokenVersionId,
                encData: encData,
                integrityValue: integrityValue
              });
            }

            navigate(`/service/${parseUserBaseInfo?.baseInfo.role}/mypage/basicInfoChange`);
          }


        } catch (e: any) {
          console.log(e);
        }
      }
      window.localStorage.removeItem('session-base-info');
      window.localStorage.removeItem('session-token-info');
    }
  };

  // 장치 로그인을 처리하는 도우미 함수
  const handleDeviceLogin = async (deviceModel: string, deviceId: string | null, osInfo: string, appVersion: string) => {
    if (deviceId && deviceModel !== 'WinPC') {

      if (deviceModel === 'OPS' || deviceModel === 'Onequick') {
        mirrorAction('screen', { command: 'state', state: 'min' });
      }

      const apiRes = await deviceLogin({ deviceId, categoryId: deviceModel as 'OPS' | 'Onequick' | 'Signage' });

      if (apiRes.message === 'SUCCESS' && apiRes.profile && apiRes.accessToken) {
        setUserTokenInfo({ coreAccessToken: apiRes.accessToken });
        setUserBaseInfo(apiRes.profile);



        setDeviceInfo({ model: deviceModel as 'OPS' | 'Onequick' | 'Signage', deviceId, osInfo, appVersion });
        setTargetRoute(deviceModel);
        delayLogBody.message = `디바이스 로그인 성공 : ${deviceId} / ${deviceModel}`;

        // 20분마다 sessionExtendApiCall 호출
        setInterval(() => {
          sessionExtendApiCall(apiRes.accessToken);
        }, 20 * 60 * 1000); // 20분 = 20 * 60 * 1000ms

      } else {
        // 서버 에러 팝업
        setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
        delayLogBody.message = `디바이스 로그인 실패 : ${deviceId} / ${deviceModel}`;
      }

      delayLogBody.endAt = new Date().toISOString();
      delayLogBody.millisecond = `${new Date(delayLogBody.endAt).getTime() - new Date(delayLogBody.startAt).getTime()}`;
      ncpLogUpdate({ logLevel: 'DEBUG', body: delayLogBody });

    } else {
      setupWinPC(osInfo, appVersion);
    }
  };

  /* Device 만료시간을 늘리기 위한 Api call */
  const sessionExtendApiCall = async (token: string | undefined) => {
    if (token) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('디바이스 세션연장');
      }
      sessionExtend({ token: token });
    }
  };

  // WinPC 설정을 처리
  const setupWinPC = (osInfo: string, appVersion: string) => {
    setDeviceInfo({ model: 'WinPC', deviceId: null, osInfo, appVersion });
    setTargetRoute('WinPC');
    setWinPcDownloadPath(window.MirrorGetDownloadPath());
  };

  // Android 설정을 처리
  const handleAndroidSetup = (osInfo: string, appVersion: string) => {
    setDeviceInfo({ model: 'Android', deviceId: null, osInfo, appVersion });
    setTargetRoute('Android');
  };

  // 초기화 세부사항을 log
  const logInitialization = (deviceId: string | null, deviceModel: string | null, osInfo: string, appVersion: string) => {
    console.log({
      'Web Version': process.env.REACT_APP_VERSION,
      'App Version': appVersion,
      'Device ID': deviceId,
      'Device Model': deviceModel,
      'OS Information': osInfo
    });
  };

  const updateOnlineStatus = () => {
    setNetworkStatus(navigator.onLine);
  };

  const appInit = async () => {

    const { deviceId, deviceModel, osInfo, appVersion } = await getDeviceInformation();
    delayLogBody.device = osInfo;

    const currentPath = window.location.pathname;
    const currentDomain = window.location.hostname;

    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log(`currentPath : ${currentPath}`);
      console.log(`currentDomain : ${currentDomain}`);
      console.log(`osInfo : ${osInfo}`);
      console.log(`Target env : ${process.env.REACT_APP_ENV}`);
    }

    if (osInfo === 'Web') {
      if (`${process.env.REACT_APP_ENV}` === 'PRODUCTION') {
        if (['/admin/environment', '/admin/error', '/admin/invalid'].includes(currentPath)) {
          // Allow access for the specific paths in admin domains
        } else {
          window.location.href = 'https://admin.a-school.org';
        }
      }
    }

    /* admin.link-school.co.kr/*, dev-admin.link-school.co.kr/* 도메인에서 /admin/environment, /admin/error, /admin/invalid path를 들어온 경우는 허용처리해줘야한다. */

    // /* *** 사이니지 (테스트 코드) *** */
    // const { osInfo } = await getDeviceInformation();
    // delayLogBody.device = osInfo;
    // const deviceId = 'eaaeb04123123c7824f42';
    // // const deviceId = 'QCX21398VV';
    // const deviceModel = 'Signage';
    // const appVersion = '0.0.1';

    /* *** 사이니지 (테스트 코드) *** */


    // const deviceId = 'BOARD_8C1D96E38168';

    // Onequick
    // const { osInfo } = await getDeviceInformation();
    // delayLogBody.device = osInfo;
    // const deviceId = 'BOARD_4C77CBFC5562';
    // const deviceModel = 'Onequick';
    // const appVersion = '0.0.1';

    // Prod OPS
    // const { osInfo } = await getDeviceInformation();
    // delayLogBody.device = osInfo;
    // const deviceId = 'OPS_7413EA31A0DD';
    // const deviceModel = 'OPS';
    // const appVersion = '0.0.1';




    handleRedirect(deviceModel!);
    // handleRedirect(deviceModel);
    console.log('deviceModel ===== ', deviceModel);

    if (deviceModel && ['WinPC', 'OPS', 'Onequick', 'Signage'].includes(deviceModel)) {
      await handleDeviceLogin(deviceModel, deviceId, osInfo, appVersion);
    } else {
      handleAndroidSetup(osInfo, appVersion);
    }


    logInitialization(deviceId, deviceModel, osInfo, appVersion);
  };

  // 최초 접근 시
  useEffect(() => {
    // 온라인 및 오프라인 이벤트 리스너 추가
    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    // setTimeout(() => {
    //
    // }, 300);
    appInit();

    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };

  }, []);

  return targetRoute;

};

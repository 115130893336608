import FilePNG from '@/assets/icon/file/document_png.svg'; // Dompurify 적용완료
import FileJPEG from '@/assets/icon/file/document_jpeg.svg'; // Dompurify 적용완료
import FileXLS from '@/assets/icon/file/document_xls.svg'; // Dompurify 적용완료
import FileDOC from '@/assets/icon/file/document_doc.svg'; // Dompurify 적용완료
import FileETC from '@/assets/icon/file/document_etc.svg'; // Dompurify 적용완료
import FileGIF from '@/assets/icon/file/document_gif.svg'; // Dompurify 적용완료
import FileHWP from '@/assets/icon/file/document_hwp.svg'; // Dompurify 적용완료
import FilePDF from '@/assets/icon/file/document_pdf.svg'; // Dompurify 적용완료
import FileTXT from '@/assets/icon/file/document_txt.svg'; // Dompurify 적용완료
import FileCommon from '@/assets/icon/file/document_upload.svg'; // Dompurify 적용완료
import DOMPurify from 'dompurify';

const getRandomInt = (min: number, max: number): number => {
  const range = max - min + 1;
  const array = new Uint32Array(1);
  window.crypto.getRandomValues(array);
  return min + (array[0] % range);
};

const wonUnit = (wonNumber: number) => {
  return typeof wonNumber !== 'undefined' ? wonNumber.toLocaleString() : '';
};


// 핸드폰 번호 양식 (01012345678 -> 010-1234-5678)
const phoneFormat = (phoneString: string) => {
  return `${phoneString.slice(0, 3)}-${phoneString.slice(3, 7)}-${phoneString.slice(7)}`;
};


// 핸드폰 번호 마스킹 (010-1234-5678 -> 010-****-5678)
const phoneMasking = (phoneString: string) => {
  const parts = phoneString.split('-');
  parts[1] = '****';
  return parts.join('-');
};

// 시간 초 -> 분 (300 -> 05:00)
const secondToMMSS = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = time % 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

// 앞의 3자리 제외 *** 처리 (linkschool -> lin********)
const maskIdString = (inputString: string) => {
  const prefix = inputString.substring(0, 3);
  return prefix + '*'.repeat(inputString.length - 3);
};

// 날짜 형식이 맞는지 확인 (19901010 은 가능, 19902010는 불가능)
const isValidDate = (dateString: string) => {
  const regex = /^\d{8}$/;
  if (!regex.test(dateString)) return false; // YYYYMMDD 형식이 아니라면 유효하지 않음

  const year = parseInt(dateString.substr(0, 4), 10);
  const month = parseInt(dateString.substr(4, 2), 10);
  const day = parseInt(dateString.substr(6, 2), 10);

  // 유효한 날짜인지 확인
  const date = new Date(year, month - 1, day); // month는 0부터 시작하므로 -1
  return !isNaN(date.getTime()) && date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

// 오늘 날짜 이전인지 파악 (19970101 -> true, 20250228 -> false)
const isDateBeforeToday = (dateString: string) => {
  const today = new Date();
  const inputDate = new Date(
    parseInt(dateString.substring(0, 4)),
    parseInt(dateString.substring(4, 6)) - 1,
    parseInt(dateString.substring(6, 8))
  );

  return inputDate < today;
};

// 만 14세 이상인지 파악하기 (19970101 -> true, 20100203 -> false)
const isOver14YearsOld = (birthdate: string) => {
  // 생년월일을 날짜 객체로 변환
  const birthdateDate = new Date(
    parseInt(birthdate.substring(0, 4)),
    parseInt(birthdate.substring(4, 6)) - 1,
    parseInt(birthdate.substring(6, 8))
  );

  // 현재 날짜
  const today = new Date();

  // 생일로부터 14년 후의 날짜
  const fourteenthBirthday = new Date(birthdateDate);
  fourteenthBirthday.setFullYear(birthdateDate.getFullYear() + 14);
  // 만 14세가 넘었는지 확인
  return fourteenthBirthday <= today;
};



// 핸드폰 번호 (앞의 0 제거)
const removeZeroPhoneString = (phoneString: string) => {
  // 맨 앞의 0 제거
  return phoneString.replace(/^0+/, '');
};



// 1의 자리 숫자 앞에 0 붙이기 (1 -> 01)
const addZeroIfNeeded = (num: string) => {
  if (num.length === 1) {
    return `0${num}`;
  }
  return num;
};


// 날짜변환 (20240101 -> 2024.01.01)
const formatDate = (dateString: string, type: string) => {

  if (dateString === 'today') {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}.${month}.${day}`;
  } else {
    const year = dateString.slice(0, 4);
    const month = dateString.slice(4, 6);
    const day = dateString.slice(6, 8);

    if (type === 'dot') {
      return `${year}.${month}.${day}`;
    }
  }
};

// 오늘의 요일 구하기
const getTodayDay = () => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const today = new Date();
  return days[today.getDay()];
};

// 오늘의 요일 구하기 (0-일, 1-월 .... 5-금, 6-토 )
const getTodayDayIndex = () => {
  const today = new Date();
  return today.getDay();
};


// 2024-07-09T02:51:42.000Z -> 2024.07.09 11:51
const convertDate = (isoString: string, second?: boolean) => {
  // ISO 문자열을 Date 객체로 변환
  const date = new Date(isoString);
  // 년, 월, 일, 시간, 분을 가져와서 형식에 맞게 변환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  if (second) {
    // 최종 문자열 형식
    return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
  } else {
    // 최종 문자열 형식
    return `${year}.${month}.${day} ${hours}:${minutes}`;
  }



};




// 2024-07-09T02:51:42.000Z -> 2024.07.09
const convertDateDot = (isoString: string) => {
  // ISO 문자열을 Date 객체로 변환
  const date = new Date(isoString);
  // 년, 월, 일, 시간, 분을 가져와서 형식에 맞게 변환
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
  const day = String(date.getDate()).padStart(2, '0');
  // 최종 문자열 형식
  return `${year}.${month}.${day}`;
};


const convertDateMore = (dateString: string) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${year}.${month}.${day} ${hours}:${minutes}:${seconds}`;
};


// 이번 주차의 월요일을 가져오기 (일요일의 경우, 내일이 아닌 전주의 월요일 일자를 가져오기)
// 0 -> 이번주, -1 -> 전주, 1 -> 다음주
const getMondayOfCurrentWeek = (dateIndex: number) => {
  const today = new Date();
  const getDate = new Date(today);
  getDate.setDate(today.getDate() + dateIndex * 7);
  const currentDay = getDate.getDay(); // 오늘의 요일
  const offset = currentDay === 0 ? 6 : currentDay - 1; // 일요일이면 6, 그 외에는 요일 - 1

  const firstDayOfWeek = new Date(getDate.setDate(getDate.getDate() - offset));

  // 현재가 일요일인 경우
  if (firstDayOfWeek.getDay() === 0) {
    firstDayOfWeek.setDate(firstDayOfWeek.getDate() - 6); // 전 주의 월요일로 조정
  }

  const year = firstDayOfWeek.getFullYear();
  let month: string | number = firstDayOfWeek.getMonth() + 1; // getMonth()는 0에서 시작하므로 +1
  let date: string | number = firstDayOfWeek.getDate();

  // 월과 일이 한 자리수일 경우 앞에 0을 붙임
  month = month < 10 ? `0${month}` : month;
  date = date < 10 ? `0${date}` : date;

  return `${year}${month}${date}`;
};

// 이번 주차와 각각 요일별 날짜를 가져오기
const getScheduleMarkUp = (mondayYYYYMMDD: string, count: number) => {
  const mondayDate = new Date(
    Number(mondayYYYYMMDD.substring(0, 4)),


    parseInt(mondayYYYYMMDD.substring(4, 6)) - 1,
    parseInt(mondayYYYYMMDD.substring(6, 8))
  );


  const thursdayDate = new Date(mondayDate);
  thursdayDate.setDate(mondayDate.getDate() + 3);

  const thursdayMonth = thursdayDate.getMonth() + 1;
  const thursdayWeek = Math.ceil(thursdayDate.getDate() / 7);

  const dateArray = [];
  const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  for (let i = 0; i < 7; i++) {
    const currentDate = new Date(mondayDate);
    currentDate.setDate(currentDate.getDate() + i);
    const formattedDate = `${currentDate.getMonth() + 1}.${currentDate.getDate()}`;
    const day = daysOfWeek[currentDate.getDay()];
    dateArray.push({ date: formattedDate, day });
  }


  if (count === 5) {
    dateArray.pop();
    dateArray.pop();
  }

  if (count === 6) {
    dateArray.pop();
  }

  // if (dateArray[dateArray.length - 1].day !== '토') {
  //     dateArray.pop();
  // }

  // const title = `${thursdayMonth}월 ${thursdayWeek}째주`;



  const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const title = `${thursdayWeek === 1 ? `${thursdayWeek}st` : thursdayWeek === 2 ? `${thursdayWeek}nd` : `${thursdayWeek}th`} week of ${monthNames[thursdayMonth - 1]}`;

  return { title, date: dateArray };
};



// 숫자 삽입 시, 배열 데이터 [1,2,3] 생
const createNumberArray = (length: number) => {
  return Array.from({ length }, (_, index) => index + 1);
};

// datetime 을 YYYY.MM.DD 로 변경
const formatDateToYYYYMMDD = (date: Date | string): string => {
  if (typeof date === 'string') {
    // If the input is a string, parse it into a Date object
    date = new Date(date);
  }

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Assemble the formatted string
  return `${year}.${month}.${day}`;
};

// datetime 을 YYYY.MM.DD HH:MM 로 변경
const formatDateToYYYYMMDDHHMM = (date: Date | string): string => {
  if (typeof date === 'string') {
    // If the input is a string, parse it into a Date object
    date = new Date(date);
  }

  // Extract year, month, day, hour, and minute components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  // Assemble the formatted string
  return `${year}.${month}.${day} ${hours}:${minutes}`;
};


// datetime 을 YYYY-MM-DD 로 변경
const formatDateToYYYYMMDDSlash = (date: Date | string): string => {
  if (typeof date === 'string') {
    // If the input is a string, parse it into a Date object
    date = new Date(date);
  }

  // Extract year, month, and day components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  // Assemble the formatted string
  return `${year}-${month}-${day}`;
};




// 분 초 형식
const formatTimer = (timeInSeconds: number) => {
  const minutes = Math.floor(timeInSeconds / 60);
  const seconds = timeInSeconds % 60;

  // '분'과 '초'를 한 자리 수일 경우에도 두 자리로 표현하기 위해 padStart 메서드 사용
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  if (minutes === 0) {
    return `${formattedSeconds}초`;
  } else {
    return `${formattedMinutes}분 ${formattedSeconds}초`;
    // if(seconds === 0){
    //     return `${formattedMinutes}분`;
    // }else{
    //     return `${formattedMinutes}분 ${formattedSeconds}초`;
    // }
  }


};

/* 현재 시간(초단위까지) */
const getCurrentDateTime = (): string => {
  const now = new Date();
  const year = now.getFullYear();
  const month = String(now.getMonth() + 1).padStart(2, '0');
  const day = String(now.getDate()).padStart(2, '0');
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  return `${year}${month}${day}${hours}${minutes}${seconds}`;
};


/* 시간:분 */
const getCurrentTime = (timestamp?: string): string => {
  let now;
  if (timestamp) {
    now = new Date(timestamp);
  } else {
    now = new Date();
  }
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
};


/* 랜덤 클래스톡 번호 생성*/
const randomClassTalkNumber = (): number => {
  const now = new Date();
  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');
  const seconds = String(now.getSeconds()).padStart(2, '0');

  const nowTime = `${hours}${minutes}${seconds}`;

  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  const randomNum = String(10000 + (array[0] % 90000));

  return Number(nowTime + randomNum);
};



// 파일 사이즈 MB, KB로 변환
const formatFileSize = (size: number) => {
  // MB 단위로 변환 (1MB = 1024KB)
  const sizeInMB = size / (1024 * 1024);
  // 파일 사이즈가 0.1MB 이상일 경우 MB 단위로 표기
  if (sizeInMB >= 0.1) {
    return `${sizeInMB.toFixed(1)}MB`;
  }
  // 파일 사이즈가 0.1MB 이하일 경우 KB 단위로 변환 및 표기
  const sizeInKB = size / 1024;
  return `${sizeInKB.toFixed(0)}KB`;
};

// 파일 사이즈 MB, KB로 변환
const isHundredMegaByte = (size: number) => {
  // MB 단위로 변환 (1MB = 1024KB)
  const sizeInMB = size / (1024 * 1024);
  // 소수점 첫째 자리까지 표기
  const fixedSize = sizeInMB.toFixed(1);
  // 파일 사이즈가 100MB 이상일 경우 FALSE 반환, 이하일 경우 TRUE 반환
  return Number(fixedSize) >= 100;

};



// 파일 확장자에 따른 이미지 변환
const getFileIcon = (fileName: string) => {
  if (!fileName.includes('.')) {
    return svgClear(FileCommon); // 확장자가 없을 경우 기본 아이콘 반환
  }

  const extension = fileName.split('.').pop()?.toLowerCase() ?? '';
  switch (extension) {
    case 'png':
      return svgClear(FilePNG);
    case 'jpg':
    case 'jpeg':
      return svgClear(FileJPEG);
    case 'xls':
    case 'xlsx':
      return svgClear(FileXLS);
    case 'doc':
      return svgClear(FileDOC);
    case 'etc':
      return svgClear(FileETC);
    case 'gif':
      return svgClear(FileGIF);
    case 'hwp':
      return svgClear(FileHWP);
    case 'pdf':
      return svgClear(FilePDF);
    case 'txt':
      return svgClear(FileTXT);
    default:
      return svgClear(FileCommon);
  }
};

// 파일 사이즈 총합 구하기
function calculateTotalFileSize(fileInfos: { fileRealName: string; fileUrl: string; fileSize: number; }[] | { fileRealName: string, fileSize: number }[]): number {
  let totalSize = 0;
  for (const fileInfo of fileInfos) {
    totalSize += fileInfo.fileSize;
  }
  return totalSize;
}

const getThisTime = () => {
  const date = new Date();
  const hours = (`0${date.getHours()}`).slice(-2); // Ensure two digits, add leading zero if necessary
  const minutes = (`0${date.getMinutes()}`).slice(-2); // Ensure two digits, add leading zero if necessary
  const seconds = (`0${date.getSeconds()}`).slice(-2); // Ensure two digits, add leading zero if necessary
  return `${hours}${minutes}${seconds}`;
};


const getThisDay = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
};

const getSecondsDifferenceFromNow = (time: string): number => {
  const now = new Date();
  now.setMilliseconds(0); // Set milliseconds to 0

  const hours = parseInt(time.slice(0, 2));
  const minutes = parseInt(time.slice(2, 4));
  const seconds = parseInt(time.slice(4, 6));

  const specifiedTime = new Date(now.getFullYear(), now.getMonth(), now.getDate(), hours, minutes, seconds);
  specifiedTime.setMilliseconds(0); // Set milliseconds to 0

  return Math.abs(Math.floor((specifiedTime.getTime() - now.getTime()) / 1000));
};

const getFileExtensionFromBase64 = (base64String: string): string => {
  // Check if the base64 string is a valid data URI
  const matches = /^data:([a-z]+\/([a-z]+));base64,/.exec(base64String);
  if (matches && matches.length > 2) {
    return matches[2];
  }
  return 'jpeg';
};

const getMimeTypeFromBase64 = (base64String: string): string => {
  // Check if the base64 string is a valid data URI
  const matches = /^data:([a-z]+\/[a-z]+);base64,/.exec(base64String);
  if (matches && matches.length > 1) {
    return matches[1];
  }
  return '';
};

const getCurrentDateTimeSecond = (): string => {
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return year + month + day + hours + minutes + seconds;
};

const streamEndTime = (sec: number): string => {
  const now = new Date();
  now.setSeconds(now.getSeconds() + sec);

  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const secondsStr = now.getSeconds().toString().padStart(2, '0');

  // Format the date and time
  return `${year}-${month}-${day}T${hours}:${minutes}:${secondsStr}`;
};

const streamStartTime = (): string => {
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
  const day = now.getDate().toString().padStart(2, '0');
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');

  // Format the date and time
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

const formatDuration = (seconds: number): string => {
  // 올림된 초 값을 분과 초로 변환
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // 포맷팅된 초를 항상 두 자릿수로 표시
  const formattedSeconds = remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

  return `${minutes}:${formattedSeconds}`;
};

const getRemainingSeconds = (targetTimeStr: string): number => {
  // 현재 시간을 가져옵니다.
  const now: Date = new Date();

  // 주어진 목표 시간을 `Date` 객체로 변환합니다.
  const targetTime: Date = new Date(targetTimeStr);

  // 두 시간의 차이를 초 단위로 계산합니다.
  const remainingTimeInMillis: number = targetTime.getTime() - now.getTime();
  return Math.floor(remainingTimeInMillis / 1000);
};

const getTimeDifferenceInSeconds = (yyyymmddhhmmss: string): number => {

  const year = parseInt(yyyymmddhhmmss.substring(0, 4));
  const month = parseInt(yyyymmddhhmmss.substring(4, 6)) - 1;
  const day = parseInt(yyyymmddhhmmss.substring(6, 8));
  const hours = parseInt(yyyymmddhhmmss.substring(8, 10));
  const minutes = parseInt(yyyymmddhhmmss.substring(10, 12));
  const seconds = parseInt(yyyymmddhhmmss.substring(12, 14));

  const targetDate = new Date(year, month, day, hours, minutes, seconds);

  // 현재 시간 구하기
  const currentDate = new Date();

  // 현재 시간과 목표 시간의 차이 계산
  return Math.floor((targetDate.getTime() - currentDate.getTime()) / 1000);

};


const convertToSeconds = (timeString: string) => {
  const [minutes, seconds] = timeString.split(':').map(Number);
  return minutes * 60 + seconds;
};

const sessionUpdate = () => {
  if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
    console.log('LINK SCHOOL - SESSION 갱신');
  }
  const now = new Date();
  const sessionCount = 3600;
  // const sessionCount = 305;
  const hourLater = new Date(now.getTime() + sessionCount * 1000); // 현재 시간에 1시간(3600초)을 더한 시간

  const year = hourLater.getFullYear();
  const month = String(hourLater.getMonth() + 1).padStart(2, '0');
  const day = String(hourLater.getDate()).padStart(2, '0');
  const hours = String(hourLater.getHours()).padStart(2, '0');
  const minutes = String(hourLater.getMinutes()).padStart(2, '0');
  const seconds = String(hourLater.getSeconds()).padStart(2, '0');
  const logOutTime = `${year}${month}${day}${hours}${minutes}${seconds}`;

  return { sessionOutTime: logOutTime, sec: sessionCount, popupOn: false };
};

const generateRandomString = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  return Array.from({ length: 10 }, () => {
    const randomIndex = getRandomInt(0, characters.length);
    return characters[randomIndex];
  }).join('');
};

const generateRandomNumber = (): number => {
  const array = new Uint8Array(20);
  crypto.getRandomValues(array); // 보안성을 강화한 랜덤 값 생성

  let numberString = Array.from(array, (num) => {
    return num % 10; // 0~9 사이의 값으로 변환
  }).join('');

  return Number(numberString);
};



const truncatedText = (text: string, maxLength: number, minus: number) => {
  if (text !== null && text !== undefined && text !== '') {
    return text.length > maxLength ? `${text.slice(0, maxLength - minus)}...` : text;
  } else {
    return '';
  }
};

const maskingId = (text: string) => {
  if (text.length <= 3) return text; // 길이가 3 이하인 경우 마스킹하지 않음

  const visiblePart = text.substring(0, 3); // 첫 3글자
  const maskedPart = '*'.repeat(text.length - 3); // 나머지 부분을 '*'로 대체
  return visiblePart + maskedPart;
};

const maskingName = (name: string) => {
  if (name.length <= 2) return name; // 길이가 2 이하인 경우 마스킹하지 않음

  const firstChar = name.charAt(0); // 첫 글자
  const lastChar = name.charAt(name.length - 1); // 마지막 글자
  const maskedPart = '*'.repeat(name.length - 2); // 중간 부분을 '*'로 대체
  return firstChar + maskedPart + lastChar;
};

const maskingEmail = (email: string) => {
  const [user, domain] = email.split('@');
  const maskedUser = `${user.slice(0, 3)}****`;
  return `${maskedUser}@${domain}`;
};

const maskPhoneNumber = (phoneNumber: string): string => {
  // 전화번호 길이에 상관없이 동일한 패턴으로 변환
  const sanitizedNumber = phoneNumber.replace(/\D/g, ''); // 숫자만 남김 (\D는 숫자가 아닌 모든 문자를 대체)
  const front = sanitizedNumber.slice(0, 3);
  const back = sanitizedNumber.slice(-4);
  return `${front}-****-${back}`;
};

const svgClear = (dom: string) => {
  return DOMPurify.sanitize(dom, { USE_PROFILES: { svg: true } });
};


// 20240829 -> 8월 29일 목요일
const formatDateMeal = (dateString: string): string => {
  // 문자열이 8자리인지 확인 (YYYYMMDD)
  if (dateString.length !== 8) {
    return '0 month 0 day Mon';
  }

  // 문자열에서 연, 월, 일 추출
  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10);
  const day = parseInt(dateString.slice(6, 8), 10);

  // Date 객체 생성
  const date = new Date(year, month - 1, day); // JS의 월은 0부터 시작하므로 -1
  // 요일 배열 정의
  const dayNames = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  // 요일 계산
  const dayOfWeek = dayNames[date.getDay()];
  // 결과 문자열 포맷
  return `${month} month ${day} day ${dayOfWeek}`;
};

// 날짜 문자열이 오늘 날짜와 같은지 확인하는 함수
const isTodayMeal = (dateString: string): boolean => {
  // 입력된 날짜가 8자리인지 확인
  if (dateString.length !== 8) {
    return false;
  }

  // 입력된 문자열에서 연, 월, 일 추출
  const year = parseInt(dateString.slice(0, 4), 10);
  const month = parseInt(dateString.slice(4, 6), 10);
  const day = parseInt(dateString.slice(6, 8), 10);

  // 오늘 날짜 가져오기
  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1; // JS의 월은 0부터 시작
  const todayDay = today.getDate();

  // 오늘 날짜와 입력된 날짜 비교
  return year === todayYear && month === todayMonth && day === todayDay;
};


export const formatTodayYYYYMMDD = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');

  console.log(`${year}${month}${day}`);
  return `${year}${month}${day}`;
};


// 날짜 포맷팅 함수
const formatDateSignage = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  };
  return date.toLocaleDateString('ko-KR', options);
};

// 시간 포맷팅 함수
const formatTimeSignage = (date: Date) => {
  /* 12시간 기준 */
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  };
  return date.toLocaleTimeString('ko-KR', options);


  /* 24시간 기준 */
  // const options: Intl.DateTimeFormatOptions = {
  //     hour: '2-digit',
  //     minute: '2-digit',
  //     hour12: false
  // };
  // let kor = '';
  // const hours = date.getHours();
  // if (hours >= 12) {
  //     kor = '오후';
  // } else {
  //     kor = '오전';
  // }
  // const now = date.toLocaleTimeString('ko-KR', options);
  // return `${kor} ${now}`;
};

// 밀리세컨 -> 00:00 형식
const formatMilliseconds = (milliseconds: number): string => {
  const totalSeconds = Math.floor(milliseconds / 1000); // 밀리세컨드를 초로 변환
  const minutes = Math.floor(totalSeconds / 60); // 분을 계산
  const seconds = totalSeconds % 60; // 남은 초 계산

  // 두 자리 숫자로 포맷하기
  const formattedMinutes = String(minutes).padStart(1, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};


// '090000' -> '085000'으로 10분전 시간
const getTimeBeforeTenMinutes = (time: string) => {
  // 시간을 파싱
  let hours = parseInt(time.slice(0, 2), 10);
  let minutes = parseInt(time.slice(2, 4), 10);
  let seconds = parseInt(time.slice(4, 6), 10);

  // Date 객체 생성 (기본적으로 1970-01-01의 날짜로 설정)
  let date = new Date();
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(seconds);

  // 10분을 빼기
  date.setMinutes(date.getMinutes() - 10);

  // 새로운 시간 포맷 생성 ('HHMMSS' 형식)
  return `${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}`;
};

/* 블랜디드러닝 녹화 카운트, 잔여 카운트를 HH:MM:SS 형태로 포맷팅 */
const secondsToTime = (count: number): string => {
  const hours = Math.floor(count / 3600);
  const minutes = Math.floor((count % 3600) / 60);
  const seconds = count % 60;

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

const formatSeconds = (seconds: number): string => {
  const padZero = (num: number): string => String(num).padStart(2, '0');

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = seconds % 60;

  if (hours > 0) {
    return `${padZero(hours)}:${padZero(minutes)}:${padZero(secs)}`;
  } else {
    return `${padZero(minutes)}:${padZero(secs)}`;
  }
};

const shortenClassName = (className: string) => {
  return className
    .split('-')  // 하이픈으로 문자열 분할
    .map((part) => (part.length > 4 ? `${part.slice(0, 4)}...` : part))  // 각 부분이 4자를 넘으면 줄임
    .join('-');  // 다시 하이픈으로 합침
};


export default {
  wonUnit, phoneFormat, phoneMasking, secondToMMSS, maskIdString, isValidDate, isDateBeforeToday, isOver14YearsOld,
  removeZeroPhoneString, addZeroIfNeeded, formatDate, getMondayOfCurrentWeek, getScheduleMarkUp, getTodayDay, getTodayDayIndex,
  createNumberArray, formatDateToYYYYMMDD, formatDateToYYYYMMDDHHMM, formatDateToYYYYMMDDSlash, formatTimer, getCurrentDateTime,
  getCurrentTime, randomClassTalkNumber, formatFileSize, isTodayMeal, formatTodayYYYYMMDD,
  getFileIcon, calculateTotalFileSize, getThisDay, getThisTime, getSecondsDifferenceFromNow, getFileExtensionFromBase64,
  getMimeTypeFromBase64, getCurrentDateTimeSecond, streamStartTime, streamEndTime, formatDuration, getRemainingSeconds,
  getTimeDifferenceInSeconds, convertToSeconds, sessionUpdate, generateRandomString, generateRandomNumber, truncatedText,
  maskingId, maskingName, maskingEmail, maskPhoneNumber, convertDate, convertDateDot, getRandomInt, convertDateMore, svgClear, formatDateMeal,
  formatDateSignage, formatTimeSignage, formatMilliseconds, getTimeBeforeTenMinutes, secondsToTime, isHundredMegaByte, formatSeconds, shortenClassName
};



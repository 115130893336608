// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import BoardLayout from '@/layout/board/boardLayout';
import Loadable from '@/components/Loadable';

/* 전자칠판/원퀵 화면 */
const BoardHome = Loadable(lazy(() => import('@/pages/board/home')));

const BoardRoutes = {
    path: '/',
    element: <BoardLayout />,
    children: [
        /* url 바로 접근시  */
        { path: '/', element: <BoardHome/> },
        { path: '/board', element: <BoardHome/> },
        { path: '/board/home', element: <BoardHome/> },
        /* 404 오류 페이지 접근 시*/
        { path: '*', element: <BoardHome /> }
    ]
};

export default BoardRoutes;

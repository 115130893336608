import React from 'react';
import '@/components/popup/popup.css';
import publicMethod from '@/utils/publicMethod';
import popupAutoLogoutHook from '@/hooks/components/popup/common/popupAutoLogout';
import { useRecoilValue } from 'recoil';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import CloseButton from '@/assets/icon/popup/close_button.svg'; // Dompurify 적용완료
import LimitTime from '@/assets/icon/popup/limit_time.svg';     // Dompurify 적용완료



interface PopupAutoLogoutProps {
  logout: () => void;
}

/* 자동 로그아웃 안내 팝업 */
const PopupAutoLogout = ({ logout }: PopupAutoLogoutProps) => {

  const { popupAuto, isVisible, popupClose, userLogout, keepLogin } = popupAutoLogoutHook(logout);

  const loginSessionCount = useRecoilValue(loginSessionCountStore);  // 로그인 세션 카운트

  return (
    <>
      {popupAuto.TYPE === 'autoLogout' &&
        <div className={`layout-popup auto ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>

              {/* Popup Close Button */}
              <figure className='layout-popup-close-button-box'>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} onClick={() => popupClose()} />
              </figure>

              {/* Popup Icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(LimitTime)} alt={'LimitTime'} draggable={false} />
              </figure>

              {/* Popup Title */}
              <div className='layout-popup-title'>Auto Logout Notification</div>

              {/* Popup Detailed Content */}
              <div className='layout-popup-common-sub'>
                Remaining Time: <span>{publicMethod.formatTimer(loginSessionCount?.sec!)}</span>
              </div>

              {/* Popup Detailed Content */}
              <div className='layout-popup-common-content'>
                <span>For the protection of personal information, you will be automatically logged out if there is no service usage for <span style={{ color: '#3F0AB1' }}>60 minutes after login</span>. If you do not wish to log out, please click [Extend Login].</span>
              </div>

            </article>

            <div className='layout-popup-button-box'>
              <button className={'layout-popup-button double white'} onClick={() => userLogout()}>
                Logout
              </button>
              <button className={'layout-popup-button double'} onClick={() => keepLogin()}>
                Extend Login
              </button>
            </div>

          </div>

        </div>
      }
    </>
  );

};

export default PopupAutoLogout
  ;

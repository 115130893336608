import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { learningModeStore } from '@/recoil/store/class';
import { groupManagementStore } from '@/recoil/store/teacherManagement';
import { ClassInfoInterface } from '@/hooks/services/checkIn';

/* 지난 모둠설정 불러오기 HOOK */
export default(classInfo: ClassInfoInterface | null ) => {

    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 모둠 state
    const groupManagement = useRecoilValue(groupManagementStore);
    // 그룹 관련 변수
    const [groupSelect, setGroupSelect] = useState({ groupId: '', groupName: '' });
    const [checkboxItems, setCheckboxItems] = useState<{groupId: string; groupName: string; checked: boolean;}[]>([]);

    // 팝업 state
    const setLearningMode = useSetRecoilState(learningModeStore);

    /** 팝업 노출 시키기**/
    useEffect(() => {

        if(popup.TYPE === 'group'){
            const filteredGroup = groupManagement.filter(u => u.classId === classInfo?.classId)[0].groupList.map(item => ({
                groupId: String(item.id),
                groupName: item.groupName,
                checked: false
            }));

            setCheckboxItems(filteredGroup);

            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }

    }, [popup]);


    // 팝업 끌 경우
    const popupClose = () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            setGroupSelect({ groupId: '', groupName: '' });
            document.body.style.overflow = '';
        }, 100);
    };


    // 그룹 체크박스 선택
    const handleCheckboxChange = (groupId: string, groupName : string) => {
        const selectedIndex = checkboxItems.findIndex(item => item.groupId === groupId);
        const updatedCheckboxItems = checkboxItems.map((item, index) => ({
            ...item,
            checked: index === selectedIndex
        }));
        setCheckboxItems(updatedCheckboxItems);
        setGroupSelect((prevData) => ({ ...prevData, groupId: groupId, groupName: groupName }));
    };

    // 그룹 선택
    const selectGroup =  () => {
        setLearningMode( (prevData) => ({ ...prevData,  TYPE: 2, GROUP_ID: groupSelect.groupId }));
        popupClose();
    };



    return { popup, popupClose, isVisible, handleCheckboxChange, selectGroup, groupSelect, checkboxItems };
};

import './index.css';
import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';

/**  테스트 레이아웃  **/
const TestLayout = () => {

    useEffect(() => {

    }, []);


    return (
        <div className="TestRoot">
            <Outlet/>
        </div>
    );
};

export default TestLayout;

import { getSession } from '@/hooks/common';
import { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { popupNoticeAgainListStore, popupNoticeStore } from '@/recoil/store/popup';
import { forceLogoutStore } from '@/recoil/store/user';
import { ApiRes as linkSchoolAlarmRes, getNoticeList as getNoticeListLinkSchool } from '@/services/content/notice/linkschool/getNoticeList';
import { useNavigate } from 'react-router-dom';



export default () => {

    // 페이지 이동
    const navigate = useNavigate();
    // 세션, 토큰 정보
    const { sessionTokenInfo, sessionBaseInfo } = getSession();
    // 공지사항 팝업 state
    const setPopupNotice = useSetRecoilState(popupNoticeStore);
    // 다시보지 않기 리스트
    const popupNoticeAgainList = useRecoilValue(popupNoticeAgainListStore);

    // 강제 로그아웃
    const setForceLogout = useSetRecoilState(forceLogoutStore);

    /* 최초 접근 시 */
    useEffect(() => {
        // 공지사항 팝업 세팅
        noticePopupSetting();
    }, []);




    /* 공지사항 팝업 세팅 */
    const noticePopupSetting = async () => {

        // 링크쿨 알림 API 조회
        const linkSchoolAlarmApi : linkSchoolAlarmRes = await getNoticeListLinkSchool({ token: sessionTokenInfo.coreAccessToken!, page: 0, limit: 1, popup: true });

        if (linkSchoolAlarmApi.code === 'OK' && linkSchoolAlarmApi.data && linkSchoolAlarmApi.data.length > 0) {

            // 1. 가장 최근의 공지사항 ID를 가져온다. (API 조회해 최근 링스쿨 알림과 popupNoticeStore에의 ID 비교)
            const latestNoticeId = linkSchoolAlarmApi.data[0].id;
            // 2. 최근 공지사항과 popupNoticeStore에의 ID 비교
            // 3-1. ID가 일치할 경우 -> 다시보지 않기한 경우이므로 팝업 띄우지 않기
            // 3-2. ID가 일치하지 않을 경우에만 팝업 띄우기
            if(latestNoticeId !== popupNoticeAgainList){
                setPopupNotice({ ID: String(linkSchoolAlarmApi.data[0].id), TYPE: 'notice', TITLE: linkSchoolAlarmApi.data[0].title, CONTENT: linkSchoolAlarmApi.data[0].content });
            }
        }
        else if (linkSchoolAlarmApi.code === 'TIMEOUT') {
            if(sessionBaseInfo){
                navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
            }else{
                navigate('/auth/error');
            }
        }
        else if (linkSchoolAlarmApi.code === 'TOKEN_EXPIRED') {
            setForceLogout({ force: true, reason: 'token' });
        }else{
            /* @@@@@@@@@@@@ 기획 정의 필요 @@@@@@@@@@@@ */
            // alert('조회 실패');
        }
    };





    return { popupNoticeAgainList };
};

import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import AdminContainer from '@/components/container/service/full/AdminContainer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deviceManagementStore } from '@/recoil/store/teacherManagement';
import { DeviceListInterface, deviceListStore } from '@/recoil/store/checkIn';
import { classModeStore } from '@/recoil/store/class';
import { userBaseInfoStore, userTokenInfoStore } from '@/recoil/store/user';
import { getClassRoomDevice } from '@/services/core/schools/getClassRoomDevice';
import { popupErrorStore } from '@/recoil/store/popup';

/**  관리자 레이아웃  **/
const AdminLayout = () => {

  // 페이지 이동
  const navigate = useNavigate();

  // 경로 파악
  const location = useLocation();
  const locationParts = location.pathname.split('/');
  const locationPage = locationParts[2];
  // ########################################################################################## 디바이스 목록조회 ##########################################################################################
  const deviceManagement = useRecoilValue(deviceManagementStore);
  const [searchParams] = useSearchParams();
  const [deviceList, setDeviceList] = useRecoilState(deviceListStore);
  const setDeviceClassMode = useSetRecoilState(classModeStore);
  const setTokenInfo = useSetRecoilState(userTokenInfoStore);
  const setBaseInfo = useSetRecoilState(userBaseInfoStore);
  // const [isSuccess, setIsSuccess] = useState<boolean | null>(null);
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);



  /* 최초 진입 시  */
  useEffect(() => {
    /* 디바이스 목록 조회 */
    getClassDeviceList();
  }, []);


  // useEffect(() => {
  //     if (isSuccess) {
  //         // 진행
  //         // alert('진행');
  //     } else if (isSuccess === false) {
  //         // 실패
  //         // alert(2);
  //     } else {
  //         // 대기
  //         // alert(3);
  //     }
  // }, [isSuccess]);


  /*  디바이스 목록 조회 */
  const getClassDeviceList = async () => {

    const token = searchParams.get('token');        // core token
    const classId = searchParams.get('classId');    // classId
    const schoolId = searchParams.get('schoolId');  // schoolId

    if (token && classId && schoolId) {
      setTokenInfo({ coreAccessToken: token });
      setBaseInfo({
        ipAddress: '',                       // IP 주소
        baseInfo: {
          role: '',
          email: '',
          phone: '',
          name: '',
          username: '',
          chatroomIds: [],
          authRejectReason: null,
          neisId: '',
          schoolId: schoolId,
          status: '',
          userId: '',
          auth: true
        },
        schoolInfo: {
          classId: '',
          userClassId: '',
          schoolClassification: 'General',
          classification: '',
          classificationType: [],
          className: '',
          departmentName: '',
          grade: '',
          schoolName: '',
          schoolId: schoolId,
          number: ''
        },
        mqtt: {
          id: '',
          pwd: ''
        },
        welcomeSession: '',
        accessTime: {}
      });
      const deviceListRes = await getClassRoomDevice({ token: token, classId: classId });

      if (deviceListRes.message === 'SUCCESS' && deviceListRes.data) {
        // setIsSuccess(true);

        const deviceList_: DeviceListInterface[] = deviceListRes.data;
        setDeviceList((prev) => ({ ...prev, deviceList: deviceList_ }));
        const targetDeviceClassMode = deviceManagement.find(device => device.classId === deviceList.classId);
        if (targetDeviceClassMode) {
          setDeviceClassMode((prev) => ({
            ...prev,
            classId: deviceList.classId,
            lecture: targetDeviceClassMode.modeInfo.lecture,
            talk: targetDeviceClassMode.modeInfo.talk,
            multi: targetDeviceClassMode.modeInfo.multi,
            rest: targetDeviceClassMode.modeInfo.rest
          }));
        } else {
          setDeviceClassMode((prev) => ({
            ...prev,
            classId: deviceList.classId
          }));
        }
      }
      else if (deviceListRes.message === 'TIMEOUT') {
        navigate('/admin/error');
      }
      else {
        // 서버 에러 팝업
        setPopupError(prevData => [...prevData, { CODE: deviceListRes.errorCode, MESSAGE: deviceListRes.errorMessage }]);
      }
    }
    else if (locationPage !== 'error' && locationPage !== 'invalid') { // 에러페이지 혹은 잘못된 접근 페이지가 아닐 경우에만 페이지 이동
      navigate('/admin/invalid');
    }
  };
  // ########################################################################################## 디바이스 목록조회 ##########################################################################################


  return (
    <div className='AppRoot'>
      <AdminContainer >
        {/* { isSuccess &&  <Outlet context={isSuccess}/> } */}
        <Outlet />
      </AdminContainer>
    </div>
  );

};

export default AdminLayout;

import '@/components/page/service/teacher/class/myBox/boardCapture.css';
import boardCaptureHook from '@/hooks/components/page/service/teacher/class/myBox/boardCapture';
import ImgDownloadIcon from '@/assets/icon/myBox/img_download.png';
import VideoReadyImg from '@/assets/image/learning/video_ready.png';
import MonitorCloseIcon from '@/assets/icon/learning/close-monitor.svg';    // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



/* 전자칠판 캡처 상세 */
const BoardCapture = () => {

  const { boardDetailScreen, isVisible, boardDetailClose, downloadFiles, imageSrc } = boardCaptureHook();


  return (

    <>
      {
        boardDetailScreen.OPEN && boardDetailScreen.DATA && imageSrc !== '' &&
        /* 전자칠판 캡처 상세 배경 - dimgray */
        <div className={`layout-board ${isVisible && 'visible'}`}>

          {/* 전자칠판 캡처 상세 박스 */}
          <div className={'layout-board-box'}>

            <div className={'layout-board-title-box'}>
              <span>{boardDetailScreen.DATA.fileFullName}</span>
              <img src={publicMethod.svgClear(MonitorCloseIcon)} alt={'MonitorCloseIcon'} draggable={false} onClick={boardDetailClose} />
            </div>

            <div className={'layout-board-download'}>
              <img src={ImgDownloadIcon} alt={'ImgDownloadIcon'} draggable={false} onClick={() => downloadFiles([{ fileUrl: boardDetailScreen.DATA?.fileUrl!, fileName: boardDetailScreen.DATA?.fileName!, fileFullName: boardDetailScreen.DATA?.fileFullName! }])} />
            </div>

            <div className={'layout-board-screen-box'}>
              <img src={imageSrc} alt={'Screen Box'} draggable={false} placeholder={VideoReadyImg} onError={(e) => { e.currentTarget.src = VideoReadyImg; }} />
            </div>

            <div className='layout-board-bottom'>
              <div className='layout-board-bottom-content'>
                <span>[{boardDetailScreen.DATA.subject}] {boardDetailScreen.DATA.grade} - {boardDetailScreen.DATA.className} Class</span>
                <p>{boardDetailScreen.DATA.target} Teacher &nbsp;&nbsp; {publicMethod.formatDateToYYYYMMDD(boardDetailScreen.DATA.date)}</p>
              </div>

            </div>
          </div>
        </div>
      }
    </>




  );
};

export default BoardCapture;

import mqtt, { MqttClient } from 'mqtt';
import React from 'react';

export interface GlobalMqttConnectRequest {
  userId: string;
  uniqueId: string;
  setGlobalAlimMessages: React.Dispatch<React.SetStateAction<MqttGlobalAlimResponse[]>>;
  setDuplicateSession?: React.Dispatch<React.SetStateAction<boolean>>;
  mqttAccountInfo: { id: string; pwd: string; };
}

let processEnd = false;
let isReconnecting = false;
let mqttClient: MqttClient | null = null;
let mqttRetryAttempt = 0; // MQTT 재접속 시도 횟수를 추적
let reconnectTimer: NodeJS.Timeout | null = null; // 재연결 타이머

export const mqttGlobalAlimConnect = (functionReq: GlobalMqttConnectRequest): MqttClient => {

  const brokerURL = `${process.env.REACT_APP_MQTT_SERVER}`;
  const userName = functionReq.mqttAccountInfo.id;
  const password = functionReq.mqttAccountInfo.pwd;

  const connectMqttClient = (): MqttClient => {
    processEnd = false;
    mqttClient = mqtt.connect(brokerURL, {
      username: userName,
      password: password,
      clientId: `${functionReq.uniqueId}-global-alim`,
      keepalive: 10,
      connectTimeout: 30000,
      clean: true,
      protocolId: 'MQIsdp',
      protocolVersion: 3,
      reconnectPeriod: 0, // 자동 재접속 비활성화
      // port: 1885
    });

    mqttClient.on('connect', () => {
      isReconnecting = false;
      mqttRetryAttempt = 0; // 연결 성공 시 재접속 시도 횟수를 초기화
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL GLOBAL MQTT - Connected broker');
      }
      if (functionReq.setDuplicateSession) {
        mqttClient!.subscribe('topic/notification/all', (err) => {
          if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
            if (err) {
              console.log(`LINK SCHOOL GLOBAL MQTT - Subscription error : all : ${JSON.stringify(err)}`);
            } else {
              console.log('LINK SCHOOL GLOBAL MQTT - Subscription success : all');
            }
          }
        });
      }
      mqttClient!.subscribe(`topic/notification/${functionReq.userId}`, (err) => {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          if (err) {
            console.log(`LINK SCHOOL GLOBAL MQTT - Subscription error : my : ${JSON.stringify(err)}`);
          } else {
            console.log('LINK SCHOOL GLOBAL MQTT - Subscription success : my');
          }
        }
      });
      if (functionReq.setDuplicateSession) {
        mqttClient!.subscribe(`session/${functionReq.userId}`, (err) => {
          if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
            if (err) {
              console.log(`LINK SCHOOL GLOBAL MQTT - Subscription error : session : ${JSON.stringify(err)}`);
            } else {
              console.log('LINK SCHOOL GLOBAL MQTT - Subscription success : session');
            }
          }
        });
      }
    });

    mqttClient.on('message', (topic, message) => {
      try {
        const messageStr = message.toString();
        const messageObj: MqttGlobalAlimResponse | MqttSessionCheckResponse = JSON.parse(messageStr);
        if ('uniqueId' in messageObj) {
          if (messageObj.uniqueId.toString() !== functionReq.uniqueId.toString()) {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
              console.log(`RECEIVE GLOBAL MQTT SESSION CHECK (OTHER DEVICE) : ${functionReq.uniqueId} / ${messageObj.uniqueId}`);
            }
            if (functionReq.setDuplicateSession) {
              functionReq.setDuplicateSession(true);
            }
          } else {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
              console.log(`RECEIVE GLOBAL MQTT SESSION CHECK (SAME DEVICE) : ${functionReq.uniqueId} / ${messageObj.uniqueId}`);
            }
          }
        } else {
          functionReq.setGlobalAlimMessages((prevMessages: MqttGlobalAlimResponse[]) => [...prevMessages, messageObj]);
        }
      } catch (e: any) {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.error('LINK SCHOOL MQTT - Class Message', e);
        }
      }
    });

    mqttClient.on('close', () => {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL GLOBAL MQTT - Disconnected from broker');
      }
      try {
        mqttClient!.unsubscribe('topic/notification/all');
        mqttClient!.unsubscribe(`topic/notification/${functionReq.userId}`);

        if (functionReq.setDuplicateSession) {
          mqttClient!.unsubscribe(`session/${functionReq.userId}`);
        }
      } catch (e: any) {
        console.log(e);
      }

      if (!isReconnecting && !processEnd) {
        isReconnecting = true;
        mqttRetryAttempt++;
        scheduleReconnect();
      } else {
        console.log('LINK SCHOOL GLOBAL MQTT REAL DISCONNECT');
      }
    });

    mqttClient.on('offline', () => {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL GLOBAL MQTT - Went offline');
      }
    });

    mqttClient.on('reconnect', () => {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL GLOBAL MQTT - Re Connect');
      }
    });

    return mqttClient;
  };

  const scheduleReconnect = () => {
    if (reconnectTimer) {
      clearTimeout(reconnectTimer);
    }
    const delay = Math.min(1000 * Math.pow(2, mqttRetryAttempt), 60000); // 최대 1분 지연
    reconnectTimer = setTimeout(() => {
      if (isReconnecting && mqttClient && !processEnd) {
        mqttClient.reconnect();
      }
    }, delay);
  };

  return connectMqttClient();
};

export interface MqttGlobalAlimResponse {
  memberId: string;
  senderId: string;
  notificationType: string;
  message: string;
  detailId: string;
  createDate: string;
}

export interface MqttSessionCheckResponse {
  uniqueId: string;
}

export const mqttGlobalAlimDisconnect = (myMqttClient: MqttClient) => {
  processEnd = true;
  isReconnecting = false; // 재연결 시도를 중단
  mqttRetryAttempt = 0; // 재접속 시도 횟수 초기화
  if (reconnectTimer) {
    clearTimeout(reconnectTimer); // 재연결 타이머 중지
  }
  myMqttClient.end(true, () => {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('LINK SCHOOL GLOBAL MQTT - Disconnected broker');
    }
    mqttClient = null;
  });
};

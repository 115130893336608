import '@/components/container/service/class/ClassTalkItem.css';
import ClassTalkItemHook from '@/hooks/components/container/service/class/ClassTalkItem';
import { ClassTalkListInterface } from '@/recoil/store/class';
import { linkOpen } from '@/services/native';
import { adjustText } from '@/utils/table/tableMethod';
import publicMethod from '@/utils/publicMethod';
import FileIcon from '@/assets/icon/common/file.svg';                    // Dompurify 적용완료
import LinkIcon from '@/assets/icon/common/link.svg';                   // Dompurify 적용완료
import MessageIcon from '@/assets/icon/common/message.svg';             // Dompurify 적용완료
import ScreenShareIcon from '@/assets/icon/common/screenshare.svg';     // Dompurify 적용완료
import CloseButton from '@/assets/icon/popup/close_button.svg';         // Dompurify 적용완료
import DownloadIcon from '@/assets/icon/common/download.svg';           // Dompurify 적용완료
import CopyIcon from '@/assets/icon/common/copy.svg';                   // Dompurify 적용완료


interface ClassTalkItemProps {
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>,
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>,
  getDetailMonitorRequest: (id: string) => void,
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>,
  role: 'teacher' | 'student'
}

/**  수업톡 내 아이템 정의  **/
const ClassTalkItem = ({ returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk, role }: ClassTalkItemProps) => {

  const { classTalkList, fileContentBoxRef, fileContentBoxRequestRef, fileWidth, fileWidthRequest, confirmScreenShare, removeClassTalk, handleCopyClipBoard, clickDownload } = ClassTalkItemHook(returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk);


  return (
    <li className='lai-inner-box'>
      {[...classTalkList].reverse().map((item: ClassTalkListInterface, index: any) => (
        <article className={'lai-ca-ticket'} key={index}>

          {/* Talk Label */}
          <div className={`lai-ca-ticket-label ${item.talkTitle}`}></div>

          <div className='lai-ca-ticket-content'>

            {/* Top Title - Arrival or Request or Approval/Denial */}
            <div className='lai-ca-title'>

              {/* Top Title - Icon */}
              {item.talkTitle === 'file' && <img src={publicMethod.svgClear(FileIcon)} alt={'TicketIcon'} draggable={false} />}
              {item.talkTitle === 'link' && <img src={publicMethod.svgClear(LinkIcon)} alt={'TicketIcon'} draggable={false} />}
              {item.talkTitle === 'msg' && <img src={publicMethod.svgClear(MessageIcon)} alt={'TicketIcon'} draggable={false} />}
              {item.talkTitle === 'mirror' && <img src={publicMethod.svgClear(ScreenShareIcon)} alt={'TicketIcon'} draggable={false} />}

              {/* Top Title - Title Text */}
              <span className='lai-ca-title-main ticket'>
                {item.talkType === 'chat' &&
                  <>
                    {item.talkTitle === 'file' && <> File </>}
                    {item.talkTitle === 'link' && <> Link </>}
                    {item.talkTitle === 'msg' && <> Message </>}
                    Arrived
                  </>
                }

                {item.talkType === 'event' && item.eventInfo &&
                  <>
                    {item.talkTitle === 'file' && <> File Transfer </>}
                    {item.talkTitle === 'link' && <> Link Transfer </>}
                    {item.talkTitle === 'mirror' && <> Screen Share </>}

                    {
                      ['fileResponse', 'linkResponse', 'mirrorResponse'].includes(item.eventInfo.type) &&
                      (item.eventInfo.str === 'Y' ? <>Accepted</> : item.eventInfo.str === 'N' ? <>Denied</> : null)
                      || ['fileRequest', 'linkRequest', 'mirrorRequest'].includes(item.eventInfo.type) && <>Request</>
                    }
                  </>
                }
              </span>

              {/* Top Title - Send Time */}
              <span className='lai-ca-title-date ticket'>
                {item.sendTime}
              </span>

              {/* Top Title - Close Button */}
              <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} style={{ width: '24px', height: '24px' }} onClick={(e) => { e.stopPropagation(); removeClassTalk(item.talkId); }} />
            </div>

            {/* Description Text */}
            <span className='lai-ca-text'>

              {/* Sending (chat) Text1 - File, Link, Message Sent */}
              {item.talkType === 'chat' && item.chatInfo &&
                <>
                  {item.chatInfo.name} {role === 'teacher' ? 'Student' : 'Teacher'} has
                  {item.talkTitle === 'file' && <> sent a file </>}
                  {item.talkTitle === 'link' && <> sent a link </>}
                  {item.talkTitle === 'msg' && <> sent a message </>}
                  .
                </>
              }

              {/* Request (event) Text2 - Teacher */}
              {role === 'teacher'
                ?
                <>
                  {/* Message - Share Request, Accept, Deny */}
                  {item.talkType === 'event' && item.eventInfo && item.eventInfo.targetInfo && <>
                    {
                      'mirrorResponse' === item.eventInfo.type &&
                      (item.eventInfo.str === 'Y'
                        ?
                        <>{item.eventInfo.senderName} Student has accepted the screen share.</> : item.eventInfo.str === 'N'
                          ?
                          <>{item.eventInfo.senderName} Student has denied the screen share.</>
                          : null)
                      || 'mirrorRequest' === item.eventInfo.type &&
                      <>
                        {item.eventInfo.senderName} Student is trying to share the screen with {item.eventInfo.targetInfo.map(i => `${i.name}${i.division === 'student' ? ' Student' : i.division === 'teacher' ? ' Teacher' : ''}`).join(', ')}.
                      </>
                    }
                  </>}
                  {(item.talkType === 'event' && item.talkTitle === 'link' && item.eventInfo && item.eventInfo.targetInfo) && <> {item.eventInfo.senderName} Student is trying to send a link to {item.eventInfo.targetInfo.map(i => `${i.name}${i.division === 'student' ? ' Student' : i.division === 'teacher' ? ' Teacher' : ''}`).join(', ')}.</>}
                  {(item.talkType === 'event' && item.talkTitle === 'file' && item.eventInfo && item.eventInfo.targetInfo) && <>{item.eventInfo.senderName} Student is trying to send a file to {item.eventInfo.targetInfo.map(i => `${i.name}${i.division === 'student' ? ' Student' : i.division === 'teacher' ? ' Teacher' : ''}`).join(', ')}.</>}
                </>
                :
                <>
                  {/* Sub Text - Accept, Deny Notification */}
                  {(item.talkType === 'event' && item.eventInfo && ['fileResponse', 'linkResponse', 'mirrorResponse'].includes(item.eventInfo.type)) &&
                    <> My
                      {item.talkTitle === 'file' && <> file transfer </>}
                      {item.talkTitle === 'link' && <> link transfer </>}
                      {item.talkTitle === 'mirror' && <> screen share </>}
                      {
                        item.eventInfo.str === 'Y'
                          ?
                          <>has been accepted</>
                          :
                          <>has been denied</>
                      }
                    </>
                  }

                  {/* Sub Text - When a request is received */}
                  {(item.talkType === 'event' && item.eventInfo && item.eventInfo.type === 'mirrorRequest') && <> {item.eventInfo.senderName} Teacher is trying to share their screen with me.</>}
                </>
              }
            </span>

            {/* Teacher Use - Link Share Request, File Share Request */}
            {role === 'teacher' && <>
              {item.talkType === 'event' && item.eventInfo && ['fileRequest', 'linkRequest'].includes(item.eventInfo.type) &&
                <span className={'lai-ca-talk'}>
                  {
                    item.talkTitle === 'file' &&
                    <div className='file-content-box' ref={fileContentBoxRequestRef}>
                      <img src={publicMethod.getFileIcon(item.eventInfo?.fileList![0].fileRealName)} alt={'FileIcon'} draggable={false} />
                      <div className='file-content-name'>
                        {
                          item.eventInfo?.fileList!.length > 1
                            ?
                            <>
                              {adjustText(item.eventInfo?.fileList![0].fileRealName!, fileWidthRequest - 120)} and {item.eventInfo?.fileList!.length - 1} others
                            </>
                            :
                            adjustText(item.eventInfo?.fileList![0].fileRealName!, fileWidthRequest - 60)
                        }
                      </div>
                      <span>{publicMethod.formatFileSize(publicMethod.calculateTotalFileSize(item.eventInfo?.fileList!))}</span>
                    </div>
                  }
                  {
                    item.talkTitle === 'link' &&
                    <div className='link-content-box'>
                      <span onClick={() => linkOpen('out', item.eventInfo?.str!)}>{item.eventInfo?.str!}</span>
                      <img src={publicMethod.svgClear(CopyIcon)} alt={'CopyIcon'} draggable={false} onClick={() => handleCopyClipBoard(item.eventInfo?.str!)} />
                    </div>
                  }
                </span>
              }
            </>}

            {/* Main Content */}
            <span className={`lai-ca-talk ${item.talkType === 'event' && item.eventInfo && ['fileRequest', 'mirrorRequest', 'linkRequest'].includes(item.eventInfo.type) ? 'request' : ''}`}>

              {/* Main Content (chat) - When receiving files, links, messages */}
              {item.talkType === 'chat' && item.chatInfo &&
                <>
                  {/* When receiving a file */}
                  {item.talkTitle === 'file' && <div className='file-content-box' ref={fileContentBoxRef}>
                    <img src={publicMethod.getFileIcon(item.chatInfo?.fileList![0].fileRealName)} alt={'FileIcon'} draggable={false} />
                    <div className='file-content-name'>
                      {
                        item.chatInfo?.fileList!.length > 1
                          ?
                          <>
                            {adjustText(item.chatInfo?.fileList![0].fileRealName!, fileWidth - 240)} and {item.chatInfo?.fileList!.length - 1} others
                          </>
                          :
                          adjustText(item.chatInfo?.fileList![0].fileRealName!, fileWidth - 190)
                      }
                    </div>
                    <span>{publicMethod.formatFileSize(publicMethod.calculateTotalFileSize(item.chatInfo?.fileList!))}</span>
                    <img src={publicMethod.svgClear(DownloadIcon)} alt={'DownloadIcon'} draggable={false} onClick={(e) => { e.stopPropagation(); clickDownload(item.talkId, item.chatInfo!.fileList!); }} />
                  </div>}
                  {/* When receiving a link */}
                  {item.talkTitle === 'link' && <div className='link-content-box'>
                    <span onClick={() => linkOpen('out', item.chatInfo?.str!)}>{item.chatInfo?.str!}</span>
                    <img src={publicMethod.svgClear(CopyIcon)} alt={'CopyIcon'} draggable={false} onClick={() => handleCopyClipBoard(item.chatInfo?.str!)} />
                  </div>}
                  {/* When receiving a message */}
                  {item.talkTitle === 'msg' && <> {item.chatInfo.str} </>}
                </>
              }

              {/* Main Content (event) - Accept, Deny Notification */}
              {item.talkType === 'event' && item.eventInfo && item.eventInfo.targetInfo && !item.eventInfo.type.includes('Request') && <>
                Target: {item.eventInfo.targetInfo.map(i => `${i.name}${i.division === 'student' ? ' Student' : i.division === 'teacher' ? ' Teacher' : ''}`).join(', ')}
              </>}

              {/* Main Content (event) - When receiving file, link, screen share requests */}
              {item.talkType === 'event' && item.eventInfo && ['fileRequest', 'linkRequest', 'mirrorRequest'].includes(item.eventInfo.type) && <>
                <div className='request-button refuse' onClick={(e) => { e.stopPropagation(); confirmScreenShare('N', item); }}>Deny</div>
                <div className='request-button approve' onClick={(e) => { e.stopPropagation(); confirmScreenShare('Y', item); }}>Accept</div>
              </>}
            </span>

          </div>
        </article>
      ))}
    </li>
  );

};

export default ClassTalkItem;


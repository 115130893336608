import React from 'react';
import '@/components/popup/popup.css';
import popupEnvironmentSignageHook from '@/hooks/components/popup/signage/popupEnvironmentSignage';
import publicMethod from '@/utils/publicMethod';
import EnvironmentHigh from '@/assets/icon/popup/environment_high.svg';
import EnvironmentLow from '@/assets/icon/popup/environment_low.svg';




/* 사이니지 교실환경 알림 팝업 */
const PopupEnvironmentSignage = () => {

  const { popupEnvironment, popupClose, isVisible } = popupEnvironmentSignageHook();

  return (

    <>
      {popupEnvironment.TYPE === 'environmentSignage' &&
        <div className={`layout-popup signage ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box signage alert'}>

            <article className={'layout-popup-content'}>


              {/* 팝업 아이콘 */}
              <figure className='layout-popup-icon-box signage'>
                {
                  // 타이틀 문구가 어떻게 전달되는지 확인 필요 (높음, 낮음)
                  popupEnvironment.TITLE?.includes('High') ?
                    <img src={publicMethod.svgClear(EnvironmentHigh)} alt={'EnvironmentIcon'} draggable={false} />
                    :
                    <img src={publicMethod.svgClear(EnvironmentLow)} alt={'EnvironmentIcon'} draggable={false} />
                }
              </figure>

              {/* 팝업 타이틀 */}
              <div className='layout-popup-title signage'>{popupEnvironment.TITLE}</div>

              <span className='layout-popup-textarea signage'>{popupEnvironment.CONTENT}</span>


            </article>

            <button className={'layout-popup-button signage'} onClick={() => popupClose()}>
              {popupEnvironment.BUTTON}
            </button>
          </div>
        </div>
      }
    </>




  );
};

export default PopupEnvironmentSignage;

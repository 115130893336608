import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { popupTutorialStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';


/* 튜토리얼 팝업 HOOK */
export default() => {

    // 페이지 이동
    const navigate = useNavigate();
    // 팝업 튜토리얼 state
    const [popupTutorial, setPopupTutorial] = useRecoilState(popupTutorialStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 세션 기본 정보
    const { sessionBaseInfo } = getSession();


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupTutorial.TYPE === 'tutorial'){
            setIsVisible(true);
            document.body.style.overflow = 'hidden';
        } else {
            setIsVisible(false);
        }
    }, [popupTutorial.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopupTutorial((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };

    // 튜토리얼 이동하기
    const moveTutorial =  () => {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/setting/tutorial`);
        popupClose();
    };


    return { popupTutorial, isVisible, popupClose, navigate, moveTutorial };
};

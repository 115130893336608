import '@/components/popup/popup.css';
import CloseButton from '@/assets/icon/popup/close_button.svg';     // Dompurify 적용완료
import PhoneCertIcon from '@/assets/icon/popup/phoneCertIcon.svg';  // Dompurify 적용완료
import NoneCertIcon from '@/assets/icon/popup/none-cert-icon.svg';  // Dompurify 적용완료
import InputError from '@/assets/icon/auth/input-error.svg';        // Dompurify 적용완료
import InputDelete from '@/assets/icon/auth/input-delete.svg';      // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import popupPhoneCert from '@/hooks/components/popup/auth/popupPhoneCert';

/* 로그인 추가인증 팝업 */
const PopupPhoneCert = () => {

  const { popup, inputs, remainingTime, phoneCertError, popupClose, resendCertNumber, runCertify, handleInputs, isVisible, numberInputDelete, moveLink } = popupPhoneCert();

  return (
    <>
      {popup.TYPE === 'phoneCert' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box'}>
            <article className={'layout-popup-content'}>
              {/* Close button */}
              <figure className='layout-popup-close-button-box'>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} onClick={() => popupClose()} />
              </figure>

              {/* Popup icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(PhoneCertIcon)} alt={'PhoneCertIcon'} draggable={false} />
              </figure>

              {/* Popup title */}
              <div className='layout-popup-title'>Additional Authentication for Login</div>

              {/* Popup content */}
              <div className='phone-cert-content'>
                <span>
                  An authentication code has been sent to the registered mobile number <span style={{ marginLeft: '4px', marginRight: '4px', color: '#FF64DC' }}>{publicMethod.maskPhoneNumber(popup.CONTENT)}</span>.<br />
                </span>
              </div>

              {/* Popup authentication code input */}
              <div className={`phone-cert-input-box ${(phoneCertError || remainingTime === 0) && 'error'}`}>
                <input name='cert_number' type="text" placeholder='6-digit authentication code' value={inputs.cert_number} autoComplete={'off'} onChange={handleInputs} />
                {(inputs.cert_number !== '') && <img src={publicMethod.svgClear(InputDelete)} alt={'InputDelete'} draggable={false} onClick={numberInputDelete} />}
                <span>{publicMethod.secondToMMSS(remainingTime)}</span>
              </div>
              {
                phoneCertError &&
                <div className='layout-popup-input-message-box'>
                  <img src={publicMethod.svgClear(InputError)} alt={'InputError'} draggable={false} />
                  <span>Please check the authentication code and try again.</span>
                </div>
              }

              {
                remainingTime === 0 &&
                <div className='layout-popup-input-message-box'>
                  <img src={publicMethod.svgClear(InputError)} alt={'InputError'} draggable={false} />
                  <span>The authentication code entry time has expired. <br /> Please press [Resend] to receive a new authentication code.</span>
                </div>
              }
            </article>

            <div className='layout-popup-button-box'>
              <button className={`layout-popup-button double ${remainingTime < 240 ? 'white' : 'disable'}`} onClick={() => remainingTime < 240 ? resendCertNumber() : undefined}>
                Resend
              </button>
              <button className={`layout-popup-button double ${(inputs.cert_number.length === 6 && remainingTime > 0) ? '' : 'disable'}`} onClick={() => (inputs.cert_number.length === 6 && remainingTime > 0) ? runCertify(inputs) : undefined}>
                Authenticate
              </button>
            </div>

            {/* Link for when authentication code does not arrive */}
            <div className='phone-cert-none-work'>
              <img src={publicMethod.svgClear(NoneCertIcon)} alt={'NoneCertIcon'} draggable={false} />
              <span onClick={() => moveLink(`${process.env.REACT_APP_MICROSITE}/qna`)}> Did not receive the authentication code?</span>
            </div>
          </div>
        </div>
      }
    </>
  );

};

export default PopupPhoneCert;

import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();

interface VerifyInfo {
    username: string
    requestId: string
    finish: boolean
    apiRes?: any
}

export const verifyStore = atom<VerifyInfo | null>({
    key: 'verify',
    default: null
});

export const idSaveStore = atom<string>({
    key: 'idSave',
    default: '',
    effects_UNSTABLE: [persistAtom]
});

interface Step1 {
    oauthType : string | undefined | null; // SSO 회원가입 유형 ('google', 'whale')
    accessToken : string | undefined | null;  // SSO 엑세스 토큰
    memberType: string;  // 회원 유형 ('teacher', 'student')
    isChild: string; // 14세 미만 여부 ('under','over')
}

interface Step2 {
    agAll: boolean; // 전체 동의
    agService: boolean; // 서비스 이용 약관 동의
    agPrivacy: boolean; // 개인정보 수집 이용 동의
    agParent: boolean; // 법정 대리인 동의
}

interface Step3 {
    name: string; //  이름
    birth: string; // 생년월일
    sex: string; // 성별 ('male','female')
    nation: string; // 국적 ('local','foreigner')
}

interface Step4 {
    name: string,
    birth: string,
    sex: string,
    nation: string,
    certMethod: string; // 본인인증 수단 ('phone','ipin')
    phone : string; // 휴대전화번호
    id: string; // 아이디
    pw: string; // 비밀번호
    pwCheck: string; // 비밀번호 체크
    email: string; // 이메일
    neisId: string;  // NEIS 아이디
    neisNone: boolean;  // NEIS 아이디 없음
}

interface Step5 {
    schoolId: string; // 학교 ID
    schoolName: string; // 학교 이름
    schoolType: string
    teacherType: string; // 선생타입 ('educator', 'staff')
    teacherMainSub: string; // 담임 or 비담임 ('', 'main', 'sub')
    teacherSubject: boolean; // 교과선택 여부
    staffType: string; // 교직원 유형 ('in','out')
    grade: string; // 학년 (1,2,3,4)
    departmentId: string; // 학과 ID
    departmentName: string; // 학과명
    class: string;  // 반 (1,2,3,4)
    schoolNo: string; // 번호 (1,2,3,4)
}

interface GuardianInfo {
    name: string
    birth: string
    gender: string
    national: string
}

interface NiceInfo {
    ci: string
    name: string
    birthdate: string
    gender: string
    mobileno: string
    nationalInfo: string
    tokenId: string
    memberExisting: string
}

export interface RegisterDetailInfo {
    step: number; // 회원가입 단계 (1,2,3,4,5)
    step1: Step1;
    step2: Step2;
    step3: Step3;
    step4: Step4;
    step5: Step5;
    niceInfo: NiceInfo | null;
    guardianInfo: GuardianInfo | null;
}


export const departmentListStore = atom<{ id: string, name: string }[]>({
    key: 'departmentList',
    default: []
});

export const registerDetailStore = atom<RegisterDetailInfo>({
    key: 'registerDetail',
    default: {
        step: 1,
        step1: {
            oauthType: null,
            accessToken: null,
            memberType: '',
            isChild: 'over'
        },
        step2: {
            agAll: false,
            agService: false,
            agPrivacy: false,
            agParent: false
        },
        step3: {                    // 14세 미만정보를 저장한다.
            name: '',
            birth: '',
            sex: '',
            nation: 'local'
        },
        step4: {
            name: '',
            birth: '',
            sex: '',
            nation: '',
            phone: '',
            certMethod: '',
            id: '',
            pw: '',
            pwCheck: '',
            email: '',
            neisId: '',
            neisNone: false
        },
        step5: {
            schoolId: '',
            schoolName: '',
            schoolType: '',
            teacherType: 'educator',
            teacherMainSub: '',
            teacherSubject: false,
            staffType: 'in',
            grade: '',
            departmentId: '',
            departmentName: '',
            class: '',
            schoolNo: ''
        },
        niceInfo: null,
        guardianInfo: null
    },
    effects_UNSTABLE: [persistAtom]
});

/* 회원가입 시, 회원정보 입력 -> 본인인증으로 갔을 때를 위한 recoil 값*/
export const isRegisterCertStore = atom<boolean>({
    key: 'isRegisterCert',
    default: false
});


/* 학교 검색 후, 학교명 및 아이디 저장 */
interface ChangeSchoolInfo {
    schoolName: string
    schoolId: string
}

export const changeSchoolInfoStore = atom<ChangeSchoolInfo | null>({
    key: 'changeSchoolInfo',
    default: null
});

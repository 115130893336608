import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
import { FrontSessionBaseInfo } from '@/services/core/me/getProfile';
const { persistAtom } = recoilPersist();

export interface TokenInfoInterface {
    coreAccessToken?: string
    chatAccessToken?: string
    chatRefreshToken?: string
}

/* 중복기기 로그인으로 인한 강제 로그아웃 */
export const forceLogoutStore = atom<{ force: boolean, reason?: 'session' | 'otherDevice' | 'token' }>({
    key: 'foreLogout',
    default: { force: false }
});

export const userTokenInfoStore = atom<TokenInfoInterface>({
    key: 'userTokenInfo',
    default: {
        coreAccessToken: ''
    },
    effects_UNSTABLE: [persistAtom]
});

export const userBaseInfoStore = atom<FrontSessionBaseInfo | null>({
    key: 'userBaseInfo',
    default: null,
    effects_UNSTABLE: [persistAtom]
});

/* 마이페이지 이름변경시 nice redirect 대응 */
export const userTempInfoStore = atom<boolean>({
    key: 'userTempInfo',
    default: false,
    effects_UNSTABLE: [persistAtom]
});

/* redirect url data */
export const userRedirectDataStore = atom<any>({
    key: 'userRedirectData',
    default: null
});

/* 회원탈퇴 여부 인터페이스 (true일 경우 모든 버튼 비활성화 처리) */
export const withdrawInfoStore = atom<boolean>({
    key: 'withdrawInfo',
    default: false
});

/* 비밀번호 검증 callbackId */
export const passwordCheckCodeStore = atom<string | null>({
    key: 'passwordCheckCode',
    default: null
});

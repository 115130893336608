import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import { popupNestingStore, popupStore } from '@/recoil/store/popup';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { toastStore } from '@/recoil/store/common';
import { classCommandStore, studentTargetStore } from '@/recoil/store/class';
import { adjustText } from '@/utils/table/tableMethod';
import { BlendedStateInterface, blendedStateStore } from '@/recoil/store/blended';

/* 파일, 링크, 메시지전송 팝업 HOOK */
export default (
  sendPermissionRequestMessage: (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }) => Promise<boolean>,
  startBlendedStreamLearning?: (roomTitle: string) => Promise<boolean>,
  startBlendedRecord?: (recordName: string) => Promise<boolean>
) => {

  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  const resetPopup = useResetRecoilState(popupStore);
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 중첩 팝업 state
  const [popupNesting, setPopupNesting] = useRecoilState(popupNestingStore);
  // 학습 시, 명령 변수
  const setClassCommand = useSetRecoilState(classCommandStore);
  // 학생의 대상 선택 변수
  const setStudentTarget = useSetRecoilState(studentTargetStore);
  // 파일전송 관련 변수
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [totalFileSize, setTotalFileSize] = useState<number>(0);
  const [totalFileSizeCount, setTotalFileSizeCount] = useState<number>(0);
  const [displayText, setDisplayText] = useState<{ name: string }[]>([]);
  // 링크전송 관련 변수
  const [linkInput, setLinkInput] = useState({ link: '' });
  // 메시지전송 관련 변수
  const [messageInput, setMessageInput] = useState({ checkLabel: '', message: '' });
  const [checkboxItems, setCheckboxItems] = useState([
    { key: 'first_cb', label: 'Great job! Excellent!', checked: false },
    { key: 'second_cb', label: 'Full marks for today’s class attitude!', checked: false },
    { key: 'third_cb', label: 'Shh! Be quiet!', checked: false },
    { key: 'fourth_cb', label: 'Please focus on the class.', checked: false },
    { key: 'fifth_cb', label: 'You shouldn’t look at other screens during class.', checked: false },
    { key: 'textarea_cb', label: '', checked: false }
  ]);


  // 블렌디드 녹화 관련 변수
  const [recordInput, setRecordInput] = useState({ record: '' });
  // 블렌디드 수업 관련 변수
  const [onlineInput, setOnlineInput] = useState({ online: '' });
  // 인풋 포커스 여부
  const [isFocused, setIsFocused] = useState(false);
  // 블랜디드 상태
  const setBlendedState = useSetRecoilState(blendedStateStore);


  /** 팝업 노출 시키기**/
  useEffect(() => {
    if (popup.TYPE === 'file' || popup.TYPE === 'link' || popup.TYPE === 'msg' || popup.TYPE === 'blendedRecord' || popup.TYPE === 'blendedOnline') {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
    }



    if (popup.TYPE === '') {
      setIsVisible(false);
      setTimeout(() => {
        setRecordInput({ record: '' });
        setOnlineInput({ online: '' });
      }, 200);
    }


  }, [popup]);

  /** 파일 추가 시 변경**/
  useEffect(() => {

    /** ******* 전체 파일 사이즈 세팅 ******* **/

    // 이미 추가된 파일 사이즈
    let addedFileSize = 0;
    // 이미 추가된 파일 사이즈 계산하기
    for (const file of files) {
      addedFileSize += file.size;
    }

    // 추가된 파일 + 이미 추가된 파일의 총합 계산
    setTotalFileSizeCount(addedFileSize);
    // MB로 계싼
    const addedFileSizeMB = addedFileSize / (1024 * 1024);
    setTotalFileSize(addedFileSizeMB);

    /** ******* 파일명 세팅 (길 경우, 확장자가 보이도록 축약 처리) ******* **/
    const handleResize = () => {
      // 파일이 들어갈 수 있는 Width 250
      const fileNameWidth = 150;
      const newDisplayText = files.map((item: any) => {
        if (item.name) {
          return { ...item, name: adjustText(item.name, fileNameWidth) };
        }
        return item;
      });
      setDisplayText(newDisplayText);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [files]);


  // 팝업 끌 경우
  const popupClose = (goSelectPopup?: boolean) => {
    setIsVisible(false);
    if (goSelectPopup) { // 전송할 대상 팝업으로 이동해야할 경우
      setTimeout(() => {
        if (popup.TYPE === 'file') {
          setPopup((prevData) => ({ ...prevData, TYPE: 'studentTargetFile' }));
        } else if (popup.TYPE === 'link') {
          setPopup((prevData) => ({ ...prevData, TYPE: 'studentTargetLink' }));
        }
      }, 100);
    } else {
      setTimeout(() => {
        setPopup((prevData) => ({ ...prevData, TYPE: '' }));
      }, 100);

    }

    setTimeout(() => {
      // setPopup((prevData) => ({ ...prevData, TITLE: '', CONTENT: '', BUTTON: '', RESERVE: '' }));
      if (popup.TYPE === 'file') {
        handleFileReset();
      } else if (popup.TYPE === 'link') {
        setLinkInput({ link: '' });
      } else if (popup.TYPE === 'msg') {
        resetMessageData();
      } else if (popup.TYPE === 'blendedRecord') {
        setRecordInput({ record: '' });
      }
      else if (popup.TYPE === 'blendedOnline') {
        setOnlineInput({ online: '' });
      }

    }, 200);

    document.body.style.overflow = '';
  };

  // 팝업 끌 경우
  const popupBlendedClose = (nesting?: boolean) => {

    if (nesting) {
      if (popup.TYPE === 'blendedRecord') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'If you do not enter a title, \n the recording will be canceled.\n Do you want to cancel the recording?', BUTTON: 'Cancel Recording', RESERVE: 'close' }));
      }
      if (popup.TYPE === 'blendedOnline') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'If you do not enter a title, \n the room creation will be canceled. \n Do you want to cancel?', BUTTON: 'Cancel Creation', RESERVE: 'close' }));
      }
    } else {
      popupClose();
    }
  };

  /** 파일 관련 함수 **/
  // 파일 추가
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {

    const selectedFiles = event.target.files;

    // 허용된 MIME 타입 목록
    const allowedMimeTypes = [
      'image/jpeg', 'image/jpg', 'image/png', 'image/gif', // 이미지 파일
      'application/pdf', // PDF 파일
      'application/msword', // .doc 파일
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx 파일
      'application/vnd.ms-excel', // .xls 파일
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx 파일
      'application/vnd.ms-powerpoint', // .ppt 파일
      'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx 파일
      'application/haansoft-hwp', // .hwp 파일 (한글 파일)
      'text/plain' // .txt 파일
    ];
    // 허용되지 않은 파일 찾기
    const invalidFiles = Array.from(selectedFiles!).filter((file: File) => {
      // 파일의 MIME 타입 추출
      const mimeType = file.type.toLowerCase();
      // 허용된 MIME 타입 목록에 포함되지 않는지 확인
      return !allowedMimeTypes.includes(mimeType);
    });
    // 이미 추가된 파일 사이즈
    let addedFileSize = 0;
    // 선택된 파일 사이즈
    let selectedFileSize = 0;

    // 이미 추가된 파일 사이즈 계산하기
    for (const file of files) {
      addedFileSize += file.size;
    }

    // 선택된 파일 사이즈 계산하기
    if (selectedFiles) {
      for (const file of Array.from(selectedFiles)) {
        selectedFileSize += file.size;
      }
    }
    // 추가된 파일 + 이미 추가된 파일의 총합 계산
    const totalFileSizeInMB = (addedFileSize + selectedFileSize) / (1024 * 1024);


    if (invalidFiles.length > 0) {
      setPopupNesting({ TYPE: 'alert', TITLE: 'Notification', CONTENT: 'Unsupported format.', BUTTON: 'OK', RESERVE: '' });
    } else if ((selectedFiles!.length + files.length) > 5) {  // Check if the number of files is within 5
      setPopupNesting((prevData) => ({ ...prevData, TYPE: 'alert', CONTENT: 'You can only send up to 5 files.' }));
    } else if (totalFileSizeInMB > 100) {  // Check if the total file size is within 100MB
      setPopupNesting((prevData) => ({ ...prevData, TYPE: 'alert', CONTENT: 'The total file size can only be up to 100MB.' }));
    } else if (selectedFiles) {
      let newFiles: File[];
      newFiles = Array.from(selectedFiles).slice(0, 5 - files.length);
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }


  };
  // 파일 삭제
  const removeFile = (index: number) => {
    setFiles((prevFiles) => {
      const newFiles = [...prevFiles];
      newFiles.splice(index, 1);
      return newFiles;
    });
  };
  // 파일 초기화
  const handleFileReset = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFiles([]);
  };
  // 파일전송
  const sendFile = () => {
    if (popup.RESERVE === 'studentTarget') { // 학생의 파일전송
      // 학습 모드 설정 - 링크 공유
      setStudentTarget((prevData) => ({ ...prevData, TYPE: 'file', DATA: files }));
      // 공유 후, 공유 대상 팝업으로 이동
      popupClose(true);
    } else { // 선생의 파일전송
      // 수업 모드 설정 - 파일 공유
      setClassCommand((prevData) => ({ ...prevData, TYPE: 'file', DATA: files }));
      // 팝업 끄기
      popupClose();
    }
  };
  // 파일 선택 트리거
  const clickFileInput = () => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.click();
    }
  };

  /** 링크 관련 함수 **/
  // 링크 입력
  const handleLinkInputs = (event: any) => {
    const { name, value } = event.target;
    // 비밀번호 입력값 검증 - 한글, 영문, 숫자, 특수문자 입력 가능 (공백 입력 불가)
    const isValidInput = /^[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(value);
    if (value.length <= 2000 && isValidInput) { // 입력값이 20자 이하일 경우에만 상태 업데이트
      setLinkInput((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // 링크전송
  const sendLink = () => {
    if (popup.RESERVE === 'studentTarget') { // 학생의 링크전송
      // 학습 모드 설정 - 링크 공유
      setStudentTarget((prevData) => ({ ...prevData, TYPE: 'link', DATA: linkInput.link }));
      // 공유 후, 공유 대상 팝업으로 이동
      popupClose(true);
    } else { // 선생의 링크전송
      // 수업 모드 설정 - 링크 공유
      setClassCommand((prevData) => ({ ...prevData, TYPE: 'link', DATA: linkInput.link }));
      // 팝업 끄기
      popupClose();
    }
  };

  /** 메시지 관련 함수 **/
  // 메시지 입력
  const handleMessageInputs = (event: any) => {
    const { name, value } = event.target;
    // 입력값이 100자를 초과하는 경우 자르기
    const truncatedValue = value.slice(0, 100);
    setMessageInput((prevData) => ({ ...prevData, [name]: truncatedValue }));
  };
  // 메시지 고정 데이터 선택
  const handleCheckboxChange = (key: string, label: string) => {
    const selectedIndex = checkboxItems.findIndex(item => item.key === key);
    const updatedCheckboxItems = checkboxItems.map((item, index) => ({
      ...item,
      checked: index === selectedIndex
    }));
    setCheckboxItems(updatedCheckboxItems);

    if (key !== 'textarea_cb') {
      setMessageInput((prevData) => ({ ...prevData, checkLabel: label, message: '' }));
    } else {
      setMessageInput((prevData) => ({ ...prevData, checkLabel: label }));
    }


  };


  // 메시지전송
  const sendMessage = async () => {

    let getMessage: string = '';

    if (popup.RESERVE === 'studentTarget') {
      getMessage = messageInput.message;
    } else if (messageInput.message === '') {
      getMessage = messageInput.checkLabel;
    } else {
      getMessage = messageInput.message;
    }

    if (getMessage !== '') {

      if (popup.RESERVE === 'studentTarget') { // Sending message to student
        // alert(`Message to send: ${getMessage}`);
        // alert(`Target ID: ${popup.CONTENT}`);

        const sendPermissionRequestMessageIssue = await sendPermissionRequestMessage({ type: 'msg', targetInfo: [{ id: popup.CONTENT, division: 'student' }], msg: getMessage });
        setToast({ TYPE: 'alert', CONTENT: `Message to the teacher has been ${sendPermissionRequestMessageIssue ? 'successfully sent' : 'failed to send'}.`, TIME: 3 });

      } else if (popup.RESERVE === 'directMessage') {

        const sendPermissionRequestMessageIssue = await sendPermissionRequestMessage({ type: 'msg', targetInfo: [{ id: popup.CONTENT, division: 'student' }], msg: getMessage });
        setToast({ TYPE: 'alert', CONTENT: `Message to the student has been ${sendPermissionRequestMessageIssue ? 'successfully sent' : 'failed to send'}.`, TIME: 3 });

        // Reset popup
        resetPopup();

      } else { // Sending message from teacher
        // Set class mode - message sharing
        setClassCommand((prevData) => ({ ...prevData, TYPE: 'msg', DATA: getMessage }));
      }
      // Close popup
      popupClose();
    }

  };
  // 메시지 초기화
  const resetMessageData = () => {
    setCheckboxItems([
      { key: 'first_cb', label: 'Great job! Excellent!', checked: false },
      { key: 'second_cb', label: 'Full marks for today’s class attitude!', checked: false },
      { key: 'third_cb', label: 'Shh! Be quiet!', checked: false },
      { key: 'fourth_cb', label: 'Please focus on the class.', checked: false },
      { key: 'fifth_cb', label: 'You shouldn’t look at other screens during class.', checked: false },
      { key: 'textarea_cb', label: '', checked: false }
    ]);
    setMessageInput({ checkLabel: '', message: '' });
  };



  /** 녹화 관련 함수 **/


  // 녹화 입력
  const handleRecordInputs = (event: any) => {
    const { name, value } = event.target;

    // 영문, 한글, 숫자, 특수문자 입력 가능 (공백 입력 불가)

    const isValidInput = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/.test(value);

    if (value.length <= 30 && isValidInput) { // 입력값이 30자 이하일 경우에만 상태 업데이트
      setRecordInput((prevData) => ({ ...prevData, [name]: value }));
    }
  };


  // 녹화 전송
  const sendRecord = () => {

    // 카운트 다운 실행
    if (startBlendedRecord) {
      // 카운트 다운 시작, 수업 제목 삽입
      setBlendedState((prev: BlendedStateInterface) => ({ ...prev, recordLectureTitle: recordInput.record, recordMode: 'COUNTDOWN' }));
    }
    popupBlendedClose();
  };


  /** 화상수업 관련 함수 **/
  // 화상수업 입력
  const handleOnlineInputs = (event: any) => {
    const { name, value } = event.target;
    if (value.length <= 50) { // 입력값이 50자 이하일 경우에만 상태 업데이트
      setOnlineInput((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  // 화상수업 전송
  const sendOnline = async () => {
    if (containsOnlyConsonantsOrVowels(onlineInput.online)) {
      setPopupNesting((prevData) => ({
        ...prevData,
        TYPE: 'alert',
        CONTENT: 'The title of the online class can only contain Korean, English, numbers, spaces, and special characters (-).'
      }));
      popupBlendedClose();
    } else {
      if (startBlendedStreamLearning) {
        setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: true }));
        await startBlendedStreamLearning(onlineInput.online);
      }
      popupBlendedClose();
    }
  };


  // 한글 모음, 자음만 입력할 경우 유효성 체크
  function containsOnlyConsonantsOrVowels(data: string): boolean {
    const consonants = 'ㄱㄲㄴㄷㄸㄹㅁㅂㅃㅅㅆㅇㅈㅉㅊㅋㅌㅍㅎ';
    const vowels = 'ㅏㅐㅑㅒㅓㅔㅕㅖㅗㅘㅙㅚㅛㅜㅝㅞㅟㅠㅡㅢㅣ';

    for (let char of data) {
      if (!(consonants.includes(char) || vowels.includes(char))) {
        return false;
      }
    }
    return true;
  }


  return {
    popup, popupClose, isVisible, popupNesting, files, fileInputRef, totalFileSize, displayText, handleFileChange, removeFile, sendFile, clickFileInput,
    handleLinkInputs, sendLink, handleMessageInputs, handleCheckboxChange, sendMessage, messageInput, linkInput, checkboxItems,
    handleRecordInputs, sendRecord, recordInput, handleOnlineInputs, sendOnline, onlineInput, popupBlendedClose, isFocused, setIsFocused, totalFileSizeCount
  };
};

import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { monitorScreenStore } from '@/recoil/store/class';
import { toastStore } from '@/recoil/store/common';




// 모니터링 상세화면 훅
export default (
  getDetailMonitorRequest: (id: string) => void,
  detailMonitorImage: { id: string, imageUrl: string } | null,
  sendScreenLock?: (type: 'all' | 'one', lock: boolean, id?: string) => Promise<boolean>
) => {


  // 팝업 state
  const setPopup = useSetRecoilState(popupStore);
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 모니터링 state
  const [monitorScreen, setMonitorScreen] = useRecoilState(monitorScreenStore);
  // 모니터링 상세화면 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 전체보기 state
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false);



  /** 상세화면 노출 시키기**/
  useEffect(() => {
    if (monitorScreen.OPEN) {
      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  }, [monitorScreen]);


  useEffect(() => {
    if (monitorScreen.OPEN && monitorScreen.DATA.id) {
      getDetailMonitorRequest(monitorScreen.DATA.id);
    }
  }, [monitorScreen]);

  useEffect(() => {
    if (`${process.env.REACT_APP_ENV !== 'PRODUCTION'}`) {
      console.log('Detail Image', detailMonitorImage);
    }
  }, [detailMonitorImage]);


  // 상세화면 끄기
  const monitorClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setMonitorScreen((prevData) => ({ ...prevData, OPEN: false }));
    }, 100);
    setTimeout(() => {
      setMonitorScreen((prevData) => ({ ...prevData, ZOOM: false, DATA: {}, RESERVE: '' }));
      setIsFullScreen(false);
    }, 200);
  };



  // 잠금화면 클릭 시
  const clickLockIcon = async (getId: string, getState: boolean) => {

    if (sendScreenLock) {
      const sendScreenLockIssue = await sendScreenLock('one', !getState, getId);

      if (getState) {
        if (sendScreenLockIssue) {
          setToast({ TYPE: 'alert', CONTENT: 'The monitored screen lock has been released.', TIME: 3 });
        } else {
          setToast({ TYPE: 'alert', CONTENT: 'Failed to release the monitored screen lock.', TIME: 3 });
        }

      } else if (sendScreenLockIssue) {
        setToast({ TYPE: 'alert', CONTENT: 'The monitored screen has been locked.', TIME: 3 });
      } else {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to lock the monitored screen.', TIME: 3 });
      }
    }
  };


  const sendDirectMessage = () => {
    setPopup((prevData) => ({ ...prevData, TYPE: 'msg', CONTENT: monitorScreen.DATA.id, RESERVE: 'directMessage' }));
  };

  return { monitorScreen, setIsFullScreen, isFullScreen, isVisible, monitorClose, clickLockIcon, sendDirectMessage };
};

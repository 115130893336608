import '@/components/container/service/full/Container.css';
import React, { ReactNode } from 'react';
import PopupAlert from '@/components/popup/common/popupAlert';
import PopupPhoneCert from '@/components/popup/auth/popupPhoneCert';
import PopupSchoolSearch from '@/components/popup/auth/popupSchoolSearch';
import PopupDecision from '@/components/popup/common/popupDecision';
import PageNetwork from '@/components/page/service/common/error/PageNetwork';
import { useRecoilValue } from 'recoil';
import { NetworkStatusStore } from '@/recoil/store/common';
import PopupError from '@/components/popup/common/popupError';


interface AuthContainerProps {
    children: ReactNode;
}

// Auth 컨테이너
const AuthContainer = ({ children } : AuthContainerProps) => {

    // 네트워크 상태
    const networkStatus = useRecoilValue(NetworkStatusStore);

    return (
        <div className='container-auth'>

            {/* z index 600 */}
            {/* 서버에러 팝업 */}
            <PopupError/>

            {/* z index 50 */}
            {/* 알림 팝업 */}
            <PopupAlert/>
            {/* 결정 팝업 */}
            <PopupDecision/>
            {/* 로그인 추가인증 */}
            <PopupPhoneCert/>
            {/* 학교 검색 */}
            <PopupSchoolSearch/>
            {
                !networkStatus &&
                <div className='container-network-error auth'>
                    <PageNetwork/>
                </div>
            }
            {children}
        </div>
    );


};

export default AuthContainer;

import { useRoutes } from 'react-router-dom';

// SERVICE
import AuthRoutes from './service/authRoutes';
import TeacherRoutes from './service/teacherRoutes';
import StudentRoutes from './service/studentRoutes';
import TestRoutes from './service/testRoutes';

// BOARD
import BoardCommonRoutes from '@/routes/board/boardRoutes';
import SignageCommonRoutes from '@/routes/signage/signageRoutes';
import floatingRoutes from '@/routes/service/floatingRoutes';
import AdminRoutes from '@/routes/service/adminRoutes';
import ExternalRoutes from '@/routes/service/externalRoutes';



const ServiceRoutes = () => {
    return useRoutes([AuthRoutes, TeacherRoutes, StudentRoutes, floatingRoutes, AdminRoutes, TestRoutes, ExternalRoutes]);
};

const BoardRoutes = () => {
    return useRoutes([BoardCommonRoutes]);
};

const SignageRoutes = () => {
    return useRoutes([SignageCommonRoutes]);
};


export {
    ServiceRoutes,
    BoardRoutes,
    SignageRoutes
};

import '@/components/page/service/teacher/class/learning/monitorScreen.css';
import { UsersInfoInterface } from '@/hooks/services/checkIn';
import monitorScreenHook from '@/hooks/components/page/service/teacher/class/learning/monitorScreen';
import VideoReadyImg from '@/assets/image/learning/video_ready.png';
import MonitorCloseIcon from '@/assets/icon/learning/close-monitor.svg';            // Dompurify 적용완료
import MonitorCloseFullIcon from '@/assets/icon/learning/monitor-close-full.svg';   // Dompurify 적용완료
import MonitorFullIcon from '@/assets/icon/learning/monitor-fullscreen.svg';        // Dompurify 적용완료
import MonitorHalfIcon from '@/assets/icon/learning/monitor-halfscreen.svg';        // Dompurify 적용완료
import MonitorLockIcon from '@/assets/icon/learning/monitor-lock.svg';              // Dompurify 적용완료
import MonitorUnlockIcon from '@/assets/icon/learning/monitor-unlock.svg';          // Dompurify 적용완료
import MonitorMessageIcon from '@/assets/icon/learning/monitor-message.svg';        // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import { useEffect } from 'react';

interface MonitorScreenProps {
  usersInfo: UsersInfoInterface[]
  sendScreenLock?: (type: 'all' | 'one', lock: boolean, id?: string) => Promise<boolean>
  getDetailMonitorRequest: (id: string) => void
  detailMonitorImage: { id: string, imageUrl: string } | null
}

/* 모니터링 상세화면 */
const MonitorScreen = ({ usersInfo, sendScreenLock, getDetailMonitorRequest, detailMonitorImage }: MonitorScreenProps) => {

  const { monitorScreen, setIsFullScreen, isFullScreen, isVisible, monitorClose, clickLockIcon, sendDirectMessage } = monitorScreenHook(getDetailMonitorRequest, detailMonitorImage, sendScreenLock);

  useEffect(() => {
    console.log('usersInfo', JSON.stringify(detailMonitorImage));
    if (detailMonitorImage)
      getDetailMonitorRequest(detailMonitorImage.id);
  }, [usersInfo]);
  // useEffect(() => {
  //   console.log('detailMonitorImage', JSON.stringify(detailMonitorImage));
  //   getDetailMonitorRequest(detailMonitorImage!.id);
  // }, [detailMonitorImage]);


  return (
    <>
      {
        (monitorScreen.OPEN && usersInfo && detailMonitorImage) &&
        /* 모니터링 배경 - dimgray */
        <div className={`layout-monitor ${isVisible && 'visible'}`}>

          {/* 모니터링 박스 */}
          <div className={`layout-monitor-box ${isFullScreen && 'full'}`}>

            <div className={`layout-monitor-title-box ${isFullScreen && 'full'}`}>
              <img src={publicMethod.svgClear(MonitorCloseIcon)} alt={'MonitorCloseIcon'} draggable={false} onClick={() => monitorClose()} />
            </div>

            <div className={'layout-monitor-screen-box'}>
              <img src={detailMonitorImage.imageUrl} alt={'Monitor Box'} draggable={false} placeholder={VideoReadyImg} onError={(e) => { e.currentTarget.src = VideoReadyImg; }} />
            </div>

            <div className={'lm-user-data'}>
              {monitorScreen.DATA.division === 'student' && <>{monitorScreen.DATA.number} </>}{monitorScreen.DATA.name}
            </div>

            {
              isFullScreen &&
              <div className={`lm-close-button ${isFullScreen && 'full'}`}>
                <img src={publicMethod.svgClear(MonitorCloseFullIcon)} alt={'MonitorCloseIcon'} draggable={false} onClick={() => monitorClose()} />
              </div>
            }

            <div className={'lm-button-box'}>
              <div className={'lm-button-item'} onClick={sendDirectMessage}>
                <img src={publicMethod.svgClear(MonitorMessageIcon)} alt={'MonitorMessageIcon'} draggable={false} />
              </div>
              {/* {usersInfo.filter(item => item.id === monitorScreen.DATA.id)[0].division === 'student' &&
                                <div className={'lm-button-item'} onClick={()=>clickLockIcon(monitorScreen.DATA.id, usersInfo.filter(item => item.id === monitorScreen.DATA.id)[0].isLocked)}>
                                    <img src={usersInfo.filter(item => item.id === monitorScreen.DATA.id)[0].isLocked ? publicMethod.svgClear(MonitorLockIcon) : publicMethod.svgClear(MonitorUnlockIcon) } alt={'MonitorLockIcon'} draggable={false}/>
                                </div>
                            } */}
              <div className={'lm-button-item'}>
                <img src={isFullScreen ? publicMethod.svgClear(MonitorHalfIcon) : publicMethod.svgClear(MonitorFullIcon)} alt={'MonitorFullIcon'} draggable={false} onClick={() => setIsFullScreen(!isFullScreen)} />
              </div>
            </div>
          </div>
        </div>
      }
    </>




  );
};

export default MonitorScreen;

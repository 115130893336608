import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* #####################################
 * ############# 로그업데이트 #############
 * ##################################### */



/* function interface */
export interface FunctionReqData {
  schoolId: string                            // 학교식별값
  classId: string                             // 교실식별값
  messageType: string                         // 메시지 구분
  fileUrl?: string                             // file url
  fileSize?: number                            // file size
  fileOriginalName?: string                    // file original name
  fileExtension?: string                       // file 확장자명
  messageValue?: string                       // 메시지 내용 / 링크 주소

  senderId: string                            // 전송자 uuid
  senderRoomId: string                        // 전송자 채팅방 uuid
  senderRole: string                          // 전송자 role
  senderName: string                          // 전송자 이름
  senderNumber: string | null                 // 전송자 번호이며 값이 없을수도 있음
  senderGrade: string                         // 전송자의 전송당시 학년
  senderClass: string                         // 전송자의 전송당시 교실명
  senderSubject: string                       // 전송자의 전송당시 과목명

  receiverGroupId: string                     // 수신자들을 그룹화하기위해 Front 에서 그룹값 전송
  receiverId: string                          // 수신자 uuid
  receiverRoomId: string                      // 수신자 채팅방 uuid
  receiverRole: string                        // 수신자 role
  receiverName: string                        // 수신자 이름
  receiverNumber: string | null               // 수신자 번호이며 값이 없을수도 있음
  receiverGrade: string                       // 수신자의 전송당시 학년
  receiverClass: string                       // 수신자의 전송당시 교실명
  receiverSubject: string                     // 수신자의 전송당시 과목명

  storageMethod: 'Shared' | 'Recording' | 'Announcement' | 'Notification'
}
export interface FunctionReq {
  token: string
  list: FunctionReqData[]
}

export interface FunctionRes {
  message: string         // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
}


/* Api Interface */
interface ApiRes {
  status: number,
  code: string
  message: string
}

interface ApiReq {
  list: {
    schoolId: string                            // 학교식별값
    classId: string                             // 교실식별값
    messageType: string                         // 메시지 구분
    fileUrl?: string                             // file url
    fileSize?: number                            // file size
    fileOriginalName?: string                    // file original name
    fileExtension?: string                       // file 확장자명
    messageValue?: string                       // 메시지 내용 / 링크 주소

    senderId: string                            // 전송자 uuid
    senderRoomId: string                        // 전송자 채팅방 uuid
    senderRole: string                          // 전송자 role
    senderName: string                          // 전송자 이름
    senderNumber: string | null                 // 전송자 번호이며 값이 없을수도 있음
    senderGrade: string                         // 전송자의 전송당시 학년
    senderClass: string                         // 전송자의 전송당시 교실명
    senderSubject: string                       // 전송자의 전송당시 과목명

    receiverGroupId: string                     // 수신자들을 그룹화하기위해 Front 에서 그룹값 전송
    receiverId: string                          // 수신자 uuid
    receiverRoomId: string                      // 수신자 채팅방 uuid
    receiverRole: string                        // 수신자 role
    receiverName: string                        // 수신자 이름
    receiverNumber: string | null               // 수신자 번호이며 값이 없을수도 있음
    receiverGrade: string                       // 수신자의 전송당시 학년
    receiverClass: string                       // 수신자의 전송당시 교실명
    receiverSubject: string                     // 수신자의 전송당시 과목명

    storageMethod: 'Shared' | 'Recording' | 'Announcement' | 'Notification'
  }[]
}



export const updateMessages = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/schools/message-logs`;
  const method = 'POST';

  const requestBody: ApiReq = {
    list: request.list
  };

  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, requestBody, method, request.token, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Registration failed.';

  if (res.status === 200 || res.status === 201) {
    return {
      message: 'SUCCESS',
      errorCode,
      errorMessage
    };
  }
  return {
    message,
    errorCode,
    errorMessage
  };

};

import React, { useEffect, useState } from 'react';
import '@/components/popup/popup.css';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { resetStudentPassword } from '@/services/core/users/resetStudentPassword';
import { getSession } from '@/hooks/common';
import { popupErrorStore, popupStore } from '@/recoil/store/popup';
import { toastStore } from '@/recoil/store/common';
import { forceLogoutStore } from '@/recoil/store/user';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import publicMethod from '@/utils/publicMethod';
import { useNavigate } from 'react-router-dom';




/* 알림 팝업 */
const PopupResetPassword = () => {


  // 페이지 이동
  const navigate = useNavigate();
  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 토큰 정보, 세션 정보
  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  const setForceLogout = useSetRecoilState(forceLogoutStore);
  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
  // 팝업 에러 state
  const setPopupError = useSetRecoilState(popupErrorStore);


  /** 팝업 노출 시키기**/
  useEffect(() => {
    if (popup.TYPE === 'resetPassword') {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
    }
  }, [popup.TYPE]);

  // 팝업 끄기
  const popupClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setPopup((prevData) => ({ ...prevData, TYPE: '' }));
      document.body.style.overflow = '';
    }, 100);
  };

  // 비밀번호 초기화 -> SMS 발송
  const sendSMS = () => {
    resetTargetPassword('sms', JSON.parse(popup.CONTENT).uid);
  };

  // 비밀번호 초기화 -> 이메일 발송
  const sendEmail = () => {
    resetTargetPassword('email', JSON.parse(popup.CONTENT).uid);
  };


  /* 학생 비밀번호 초기화 */
  const resetTargetPassword = async (sendType: string, getUserId: string) => {
    const apiRes = await resetStudentPassword({ userId: getUserId, token: sessionTokenInfo.coreAccessToken! });
    if (apiRes.message === 'SUCCESS') {
      setLoginSessionCount(publicMethod.sessionUpdate);
      if (sendType === 'sms') {
        setToast({ TYPE: 'alert', CONTENT: 'The password has been reset. Please check your SMS.', TIME: 3 });
      } else if (sendType === 'email') {
        setToast({ TYPE: 'alert', CONTENT: 'The password has been reset. Please check your email.', TIME: 3 });
      }
      popupClose();
    } else if (apiRes.message === 'LOGOUT') {
      setForceLogout({ force: true, reason: 'otherDevice' });
      setToast({ TYPE: 'alert', CONTENT: 'Failed to reset the password.', TIME: 3 });
    } else if (apiRes.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
      setToast({ TYPE: 'alert', CONTENT: 'Failed to reset the password.', TIME: 3 });
    } else if (apiRes.message === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      // Server error popup
      setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
      // setToast({ TYPE: 'alert', CONTENT: 'Failed to reset the password.', TIME: 3 });
    }
  };


  return (
    <>
      {popup.TYPE === 'resetPassword' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box'}>
            <span className='reset-password-title'>Password Reset</span>

            <div className='layout-popup-box-info'>
              <div className='layout-popup-box-info-item'>
                <p> · </p>
                <span> When resetting the password, a temporary password will be sent to the student's email  </span>
              </div>
              <div className='layout-popup-box-info-item'>
                <p> · </p>
                <span> Please instruct the user to reset their password upon logging in with the temporary password. </span>
              </div>
            </div>

            {
              popup.CONTENT &&
              <>
                <div className='reset-password-target'>
                  <span> {JSON.parse(popup.CONTENT).studentNumber} {JSON.parse(popup.CONTENT).name} </span>
                </div>
                <div className='reset-password-button-box'>
                  {/* <div className='reset-password-button' onClick={sendSMS}> Send via SMS </div> */}
                  <div className='reset-password-button' onClick={sendEmail}> Send via Email </div>
                </div>
              </>
            }

            <button className={'layout-popup-button'} onClick={() => popupClose()}>
              Close
            </button>
          </div>
        </div>
      }
    </>
  );

};

export default PopupResetPassword;

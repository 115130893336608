import React from 'react';
import '@/components/popup/popup.css';
import { UsersInfoInterface } from '@/hooks/services/checkIn';
import ScrollBar from '@/components/common/scroll/ScrollBar';
import popupOtherHook from '@/hooks/components/popup/other/popupOther';
import ScreenShareIcon from '@/assets/icon/common/screenshare.svg';     // Dompurify 적용완료
import OnlineClassIcon from '@/assets/icon/common/online_class.svg';    // Dompurify 적용완료
import MessageIcon from '@/assets/icon/common/message.svg';             // Dompurify 적용완료
import LinkIcon from '@/assets/icon/common/link.svg';                   // Dompurify 적용완료
import CopyIcon from '@/assets/icon/common/copy.svg';                   // Dompurify 적용완료
import FileIcon from '@/assets/icon/common/file.svg';                    // Dompurify 적용완료
import FileDownload from '@/assets/icon/common/download.svg';           // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';


interface PopupOtherProps {
  usersInfo: UsersInfoInterface[]
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>
  getDetailMonitorRequest: (id: string) => void
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>
  joinBlendedStreamLearning?: (roomId: string) => Promise<'SUCCESS' | 'FAIL' | 'NONE'>
}

// 타인으로부터 온 팝업
// 화면공유, 파일전송, 파일 공유 요청, 링크전송, 링크 공유 요청, 메시지전송
const PopupOther = ({ usersInfo, joinBlendedStreamLearning, returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk }: PopupOtherProps) => {

  const { sessionBaseInfo, popupOther, isVisible, copyLink, moveLink, popupClose, displayText, fileList, copyToast, confirmScreenShare, confirmOnlineClass,
    targetDivision, downloadFiles, downloadAll } = popupOtherHook(returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, deleteClassTalk, joinBlendedStreamLearning);

  const getUserDivisionLabel = (targetId: string) => {
    const user = usersInfo?.find(u => u.id === targetId);
    if (user?.division === 'teacher') {
      return 'Teacher';
    } else if (user?.division === 'student') {
      return 'Student';
    }

    return ''; // 다른 조건이 없는 경우
  };


  return (
    /* 레이아웃 팝업 */
    <>
      {(popupOther.TYPE === 'mirror' || popupOther.TYPE === 'file' || popupOther.TYPE === 'link' || popupOther.TYPE === 'msg' || popupOther.TYPE === 'msgDetail' || popupOther.TYPE === 'onlineClass') &&
        <div className={`layout-popup request ${isVisible && 'visible'}`}>

          {/* Screen Share */}
          {popupOther.TYPE === 'mirror' &&
            <div className={'layout-popup-box decision'}>

              <div className={'cmd-lp-top'}>
                <div className="cmd-lp-cover">
                  {/* Screen share request - received by both student and teacher */}
                  {popupOther.TYPE === 'mirror' &&
                    <>
                      <img src={publicMethod.svgClear(ScreenShareIcon)} alt={'ScreenShareIcon'} draggable={false} />
                      <span>Screen Share Request</span>
                    </>
                  }
                </div>
              </div>

              {
                sessionBaseInfo?.baseInfo.role === 'student' &&
                <article className={'layout-popup-content'} style={{ marginTop: '6px', marginBottom: '2px' }}>
                  <span>{popupOther.DATA?.eventInfo?.senderName} {getUserDivisionLabel(popupOther.DATA?.eventInfo?.senderId!)} is trying to share their screen with me.</span>
                </article>
              }

              {
                sessionBaseInfo?.baseInfo.role === 'teacher' &&
                <article className={'layout-popup-content'} style={{ marginTop: '6px', marginBottom: '2px' }}>
                  <span>{popupOther.DATA?.eventInfo?.senderName} {getUserDivisionLabel(popupOther.DATA?.eventInfo?.senderId!)} is trying to share their screen with {popupOther.DATA?.eventInfo?.targetInfo![0].name}{targetDivision}.</span>
                </article>
              }

              <div className="layout-popup-button-box">
                <button className={'layout-popup-button double gray'} onClick={() => confirmScreenShare('N', popupOther.DATA!)}>
                  Reject
                </button>
                <button className={'layout-popup-button double'} onClick={() => confirmScreenShare('Y', popupOther.DATA!)}>
                  Accept
                </button>
              </div>

            </div>
          }


          {/* File */}
          {popupOther.TYPE === 'file' &&
            <div className={`layout-popup-box ${(sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') && 'board'}`}>
              <div className={'cmd-lp-top'}>
                {
                  (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') &&
                  <>
                    {fileList?.length === 1 && <div className="cmd-board-blank"></div>}
                    {fileList?.length === 2 && <div className="cmd-board-blank-thin"></div>}
                  </>
                }
                <div className="cmd-lp-cover">
                  <img src={publicMethod.svgClear(FileIcon)} alt={'FileIcon'} draggable={false} />

                  {
                    popupOther.REQUEST
                      ?
                      <>
                        <span>File Transfer Request</span>
                        {popupOther.DATA?.eventInfo?.senderName} {getUserDivisionLabel(popupOther.DATA?.eventInfo?.senderId!)} is trying to send a file to {popupOther.DATA?.eventInfo?.targetInfo![0].name}{targetDivision}.
                      </>
                      :
                      <>
                        <span>File Arrival</span>
                        A file sent by {popupOther.DATA?.chatInfo?.name} {getUserDivisionLabel(popupOther.DATA?.chatInfo?.id!)} has arrived.
                      </>
                  }
                </div>
              </div>

              <div className="cmd-lp-content file">
                <ScrollBar scrollbarHeight={'174px'}>
                  {fileList && fileList.length > 0 && fileList.map((file: any, index: number) => (
                    <div key={`${file.fileUrl}-${index}`} className='cmd-file-container'>
                      <img src={publicMethod.getFileIcon(file.fileRealName)} alt={'FileIcon'} draggable={false} />
                      <div className={`cmd-file-container-input ${popupOther.REQUEST ? 'request' : ''}`}>
                        <span>
                          {displayText[index] ? displayText[index].fileRealName : file.fileRealName}
                        </span>
                      </div>
                      <div className={`cmd-file-container-size ${fileList.length === 1 && 'long'}`}>
                        <span style={{ color: '#ADB0B8' }}>{publicMethod.formatFileSize(file.fileSize)}</span>
                      </div>
                      {
                        fileList.length > 1 &&
                        <>
                          {!popupOther.REQUEST && <img src={publicMethod.svgClear(FileDownload)} alt={'FileDownload'} draggable={false} onClick={() => downloadFiles([{ fileUrl: file.fileUrl, fileName: file.fileRealName }])} />}
                        </>
                      }
                    </div>
                  ))}
                </ScrollBar>
              </div>

              {/* Total size displayed when there are 2 or more files */}
              {fileList && fileList.length > 1 &&
                <div className='cmd-file-size'>
                  <span>Total Size</span>
                  <span>
                    <span style={{ color: '#3F0AB1', marginRight: '4px' }}>
                      {popupOther.REQUEST
                        ?
                        <> {publicMethod.formatFileSize(publicMethod.calculateTotalFileSize(popupOther.DATA?.eventInfo?.fileList!))}</>
                        :
                        <> {publicMethod.formatFileSize(publicMethod.calculateTotalFileSize(popupOther.DATA?.chatInfo?.fileList!))}</>
                      }
                    </span>
                  </span>
                </div>
              }

              {
                (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') &&
                <>
                  {fileList?.length === 1 && <div className="cmd-board-blank">  </div>}
                  {fileList?.length === 2 && <div className="cmd-board-blank-thin">  </div>}
                </>
              }

              {
                popupOther.REQUEST
                  ?
                  <div className="layout-popup-button-box">
                    <button className={'layout-popup-button double gray'} onClick={() => confirmScreenShare('N', popupOther.DATA!)}>
                      <span style={{ marginLeft: '8px' }}>Reject</span>
                    </button>
                    <button className={'layout-popup-button double'} onClick={() => confirmScreenShare('Y', popupOther.DATA!)}>
                      Accept
                    </button>
                  </div>
                  :
                  <div className="layout-popup-button-box">
                    <button className={'layout-popup-button double gray'} onClick={() => popupClose(popupOther.DATA?.talkId)}>
                      <span style={{ marginLeft: '8px' }}>Close</span>
                    </button>
                    <button className={'layout-popup-button double'} onClick={() => downloadAll(popupOther.DATA?.talkId!)}>
                      {fileList && fileList.length === 1 &&
                        <> Save</>
                      }
                      {fileList && fileList.length > 1 &&
                        <> Save All</>
                      }
                    </button>
                  </div>
              }
            </div>
          }


          {/* Link */}
          {popupOther.TYPE === 'link' &&
            <>
              <div className={`layout-popup-box ${(sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') && 'board'}`}>
                <div className={'cmd-lp-top'}>
                  {
                    (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') &&
                    <div className="cmd-board-blank"></div>
                  }

                  <div className="cmd-lp-cover">
                    <img src={publicMethod.svgClear(LinkIcon)} alt={'LinkIcon'} draggable={false} />
                    {
                      popupOther.REQUEST
                        ?
                        <>
                          <span>Link Transfer Request</span>
                          {popupOther.DATA?.eventInfo?.senderName} {getUserDivisionLabel(popupOther.DATA?.eventInfo?.senderId!)} is trying to send a link to {popupOther.DATA?.eventInfo?.targetInfo![0].name}{targetDivision}.
                        </>
                        :
                        <>
                          <span>Link Arrival</span>
                          A link sent by {popupOther.DATA?.chatInfo?.name} {getUserDivisionLabel(popupOther.DATA?.chatInfo?.id!)} has arrived.
                        </>
                    }
                  </div>
                </div>

                <div className="cmd-lp-content link">
                  <span className={'link-https other'} onClick={() => moveLink(popupOther.DATA?.talkId!)}>
                    {
                      popupOther.REQUEST
                        ?
                        <>
                          {popupOther.DATA?.eventInfo?.str}
                        </>
                        :
                        <>
                          {popupOther.DATA?.chatInfo?.str}
                        </>
                    }
                  </span>
                  <img src={publicMethod.svgClear(CopyIcon)} alt={'CopyIcon'} draggable={false} onClick={() => copyLink(popupOther.DATA?.talkId!)} />
                </div>

                {
                  (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') &&
                  <div className="cmd-board-blank">  </div>
                }

                <div className="layout-popup-button-box">
                  {
                    popupOther.REQUEST
                      ?
                      <>
                        <button className={'layout-popup-button double gray'} onClick={() => confirmScreenShare('N', popupOther.DATA!)}>
                          Reject
                        </button>
                        <button className={'layout-popup-button double'} onClick={() => confirmScreenShare('Y', popupOther.DATA!)}>
                          Accept
                        </button>
                      </>
                      :
                      <>
                        <button className={'layout-popup-button double gray'} onClick={() => popupClose(popupOther.DATA?.talkId)}>
                          Close
                        </button>
                        <button className={'layout-popup-button double'} onClick={() => moveLink(popupOther.DATA?.talkId!)}>
                          Go to Address
                        </button>
                      </>
                  }
                </div>
              </div>

              <div className={`layout-popup-toast ${copyToast && 'visible'}`}>
                The link has been copied.
              </div>
            </>
          }


          {/* Message */}
          {popupOther.TYPE === 'msg' &&
            <div className={`layout-popup-box ${(sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') && 'board'}`}>

              <div className='cmd-lp-top'>
                <div className='cmd-lp-cover'>
                  <img src={publicMethod.svgClear(MessageIcon)} alt={'MessageIcon'} draggable={false} />
                  <span>Message Arrival</span>
                </div>
              </div>

              <div className='cmd-lp-content message'>
                <div className={'cmd-message-item full student'}>
                  <div className='cmd-message-item-content other'>
                    <div className='cmd-message-item-sender'>
                      <span>
                        {popupOther.DATA?.chatInfo?.name} {getUserDivisionLabel(popupOther.DATA?.chatInfo?.id!)}
                      </span>
                      <span>
                        {publicMethod.formatDate('today', 'dot')} {popupOther.DATA?.sendTime}
                      </span>
                    </div>
                    <textarea name={'message'} value={popupOther.DATA?.chatInfo?.str} placeholder={'Enter message (up to 100 characters)'} readOnly={true} />
                  </div>
                </div>
              </div>
              <div className={'cmd-lp-button'} onClick={() => popupClose(popupOther.DATA?.talkId)}>
                Close
              </div>
            </div>
          }


          {/* 화상수업 초대 */}
          {popupOther.TYPE === 'onlineClass' &&
            <div className={'layout-popup-box decision'}>

              <div className={'cmd-lp-top'}>
                <div className='cmd-lp-cover'>
                  <img src={publicMethod.svgClear(OnlineClassIcon)} alt={'OnlineClassIcon'} draggable={false} />
                  <span>Online Class Invitation</span>
                </div>
              </div>

              <article className={'layout-popup-content'} style={{ marginTop: '6px', marginBottom: '2px' }}>
                <span>{popupOther.RESERVE?.teacherName} is inviting you to <br /> an online class</span>
              </article>

              <div className='layout-popup-button-box'>
                <button className={'layout-popup-button double gray'} onClick={() => confirmOnlineClass('N', popupOther.RESERVE!.roomId)}>
                  Reject
                </button>
                <button className={'layout-popup-button double'} onClick={() => confirmOnlineClass('Y', popupOther.RESERVE!.roomId)}>
                  Accept
                </button>
              </div>

            </div>
          }

        </div>
      }
    </>


  );
};

export default PopupOther;

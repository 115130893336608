import { atom } from 'recoil';

/* 현재 교실의 디바이스 목록 */
export interface DeviceListInterface {
    id: string                  // ex) 05f86416-66d2-4a95-8266-9c3772c11043
    type: string                // ex) 교수학습
    name: string                // ex) 미디어 박스
    linkSchoolDeviceId: string  // ex) GLD_MediaBox_CC827F456AC3
    modelName: string           // ex) MediaBox
    deviceId: string            // ex) GLD_MediaBox_CC827F456AC3
    status: string              // ex) normal(정상), inspection(점검), break(고장), stop_using(중지), disconnect(연결끊김)
    serialNum: string
    active: string              // ex) disabled, enabled
    registrationDate: string    // ex) 2024-05-09T00:29:44.000Z
    serviceId: string
    categoryId: string          // ex) MEDIA_BOX
    typeLabel: string           // ex) 교수학습
    nameLabel: string           // ex) 대형칠판
    issue_exists: number        // ex) 0: 점검요청없음, 1: 점검요청중
    classroom: {
        id: string
        groupId: string
    }
    groupId: string
    frontType?: string
}
export const deviceListStore = atom<{ classId: string, deviceList: DeviceListInterface[] }>({
    key: 'deviceList',
    default: { classId: '', deviceList: [] }
});
export const signageDeviceSelectedStore = atom<boolean>({
    key: 'signageDeviceSelected',
    default: false
});

/* 현재 수업에서 그룹모드가 켜졌는지, 꺼졌는지, 켜져있다면 그룹정보가 어떻게 되는지에 대한 정보 관리 */
export interface GroupInfoInterface {
    groupMode: boolean
    groupInfo: {
        groupId: string
        groupName: string
        subGroupList: {
            subGroupId: string;
            subGroupName: string;
            subMirror: boolean;
            webRtcRoomId: number | null;
            subGroupMember: {
                id: string;
                division: string;
                name: string;
                number: string | null;
                position: 'leader' | 'member';
            }[]
        }[]
    } | null
}
export const classGroupModeStore = atom<GroupInfoInterface>({
    key: 'classGroupMode',
    default: { groupMode: false, groupInfo: null }
});





export interface GroupMiddleInfoInterface {
    groupId: string
    groupName: string
    subGroupList: {
        subGroupId: string
        subGroupName: string
        subMirror: boolean
        subGroupMember: {
            division: string
            id: string
            name: string
            number: string | null
            position: 'leader' | 'member'
        }[]
    }[]
}
export const groupMiddleModeStore = atom<GroupMiddleInfoInterface | null>({
    key: 'groupMiddleMode',
    default: null
});



/* 내가 현재 참여중인 미러링 관리 */
export interface MyMirrorManagementInterface {
    webRtcRoomId: number
    position: string
    pubInfo: {
        videoNumber?: string
        division: string
        id: string
        name: string
        number?: string | null
    }[]
}
export const myMirrorManagementStore = atom<MyMirrorManagementInterface | null>({
    key: 'myMirror',
    default: null
});



/*
 * 체크인 상태 관리
 * - boolean ( true: 연결, false: 연결실패 )
 * - null ( 최초값 )
 * ## 만일 중요한값이 false 라면 체크인 실패로 판단. ( 로딩을 보여주는 지점에서 null 로 초기화 진행하여 다시 최초 상태값으로 만든다. )
 * */
export interface CheckInManagementInterface {
    checkInState: boolean | null,
    coreCheckIn: boolean,
    chatConnect: boolean | null,
    mqttConnect: boolean | null,
    monitorStart: boolean | null,
    mediaBoxConnect: boolean | null
}
export const checkInManagementStore = atom<CheckInManagementInterface>({
    key: 'checkInManagement',
    default: {
        checkInState: null,
        coreCheckIn: false,
        chatConnect: null,
        mqttConnect: null,
        monitorStart: null,
        mediaBoxConnect: null
    }
});

import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* ####################################
 * ############# 수업톡 조회 #############
 * #################################### */





/* function interface */
export interface FunctionReq {
  token: string;
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
  data?: {
    permissionData?: permissionData;
    nonPermissionData?: nonPermissionData;
  }
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
  data?: {
    permissionData?: permissionData;
    nonPermissionData?: nonPermissionData;
  }
}

interface permissionData {
  request: talkData[];         // 요청건
  permission: talkData[];      // 승인건
  rcvrList: talkData[];        // 수신건
}
interface nonPermissionData {
  rcvrList: talkData[];        // 수신건
}

interface talkData {
  id: string;             // talk id
  lectureId: string;      // lecture id
  talkType: string;       // talk type (FILE, LINK, MESSAGE, MIRROR_OPEN, MIRROR_JOIN)
  requestUserId: string;
  requestDivision: 'student' | 'teacher';
  permissionNeed: boolean;
  permissionUserId?: string;
  permissionDivision?: 'student' | 'teacher';
  permission: 'APPROVED' | 'REJECTED' | 'PENDING';
  requestDate: string;
  permissionDate?: string;
  message: string;        // 메세지 / 링크
  rcvrList: {
    id: string;
    userId: string;
    number?: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    name: string;
    deleteYn: boolean;
  }[];
  attachInfoList?: {
    id: string;
    filePath: string;
    fileName: string;
    fileType: string;
    fileSize: number;
  }[]
  mirrorInfoList?: {
    createRoom: boolean;
    createAt: string;
    groupMirror: boolean;
    subGroupId?: string;
    id: string;
    lectureId: string;
    lectureTalkId?: string;
    permissionUserId?: string;
    requestUserId?: string;
    roomNumber?: number;
    updatedAt: string;
    mirrorUsers: {
      id: string;
      userId: string;
      division: 'student' | 'teacher' | 'board' | 'onequick';
      number?: number;
      name: string;
      mirrorType: 'PUB' | 'SUB';
      state: 'WAIT' | 'JOIN' | 'REQ';
      sendJoinMessage: boolean;
      lectureTalkId?: string;
    }[]
  }[]
}



export const findMyTalkLIst = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/talk`;
  const method = 'GET';

  const res: ApiRes = await statusApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to retrieve class talk.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message,
    data: res.data
  };
};

import { ServiceRoutes, BoardRoutes, SignageRoutes } from '@/routes/index';
import './App.css';
import app from '@/hooks/app';


function App() {

  const targetRoute = app();

  return (
    <div className="App">
      {(targetRoute === 'Android' || targetRoute === 'WinPC' || targetRoute === 'Tablet' || targetRoute === 'Ipad') && <ServiceRoutes />}
      {(targetRoute === 'OPS' || targetRoute === 'Onequick') && <BoardRoutes />}
      {targetRoute === 'Signage' && <SignageRoutes />}
    </div>
  );
}

export default App;

import { useRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 알림 팝업 HOOK */
export default() => {


    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE.includes('alertSignage')){
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }
    }, [popup.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {

        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
        }, 100);
    };

    return {
        popup,
        popupClose,
        isVisible
    };
};

import { grmApi } from '@/services/network';
import { GooroomeeMappingCode, GooroomeeResponseCode } from '@/utils/code-enums';

/* #######################################
 * ############# 구루미 방 생성 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
    roomUrlId: string
    roomTitle: string
    availDate: string
    resvDate: string
}
export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
    roomId?: string
    passwd?: string
}


/* Api Interface */
interface ApiReq {
    roomUrlId: string
    roomTitle: string
    roomType: string
    enterAvailDate: string
    endResvDate: string
    definePasswd: boolean
    enterGuest: boolean
}
interface ApiRes {
    status: number
    code: string
    message: string
    data?: {
        roomId: string
        groupId: string
        userId: string
        roomUrlId: string
        roomTitle: string
        roomType: string
        currJoinCnt: number
        maxCnt: number
        lock: boolean
        definePasswd: boolean
        useNickname: boolean
        passwd: string
        roomMode: string
        regDate: string
        beginDate: string
        enterAvailDate: string
        endResvDate: string
        end: boolean
        useScreen: boolean
        enterGuest: boolean
    }
}



export const makeBlendedRoom = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_GOOROOMEE_SERVER}/openapi/v3/rooms`;
    const method = 'POST';

    const requestBody: ApiReq = {
        roomUrlId: request.roomUrlId,
        roomTitle: request.roomTitle,
        roomType: 'edu',
        enterAvailDate: request.availDate,
        endResvDate: request.resvDate,
        definePasswd: false,
        enterGuest: true
    };

    const res: ApiRes = await grmApi<ApiReq, ApiRes>(endPoint, requestBody, method);

    const message = GooroomeeMappingCode[res.code as GooroomeeResponseCode] || 'FAIL';

    if (res.code === GooroomeeResponseCode.SUCCESS && res.data) {
        return {
            message,
            roomId: res.data.roomId,
            passwd: res.data.passwd
        };
    }
    return { message };

};

import { streamApi } from '@/services/network';
import { StreamMappingCode, StreamResponseCode } from '@/utils/code-enums';

/* ############################################
 * ############# 스트림 Publish 시작 #############
 * ############################################ */

/* function interface */
export interface FunctionReq {
    mediaBoxIp: string;     // 미디어박스 아이피
    lectureId: string;      // 수업 아이디
    record: boolean;        // 녹화 여부
    conference: boolean;    // 구루미 생성여부
    roomId?: string;        // 구루미 룸 아이디
    duration: number;       // 잔여 수업 시간초
    pingTimeout: number;    // 핑 주기
}

export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
    target?: string
}



/* Api Interface */
interface ApiReq {
    lecture_id: string;
    video: boolean;
    audio: boolean;
    record: boolean;
    conference: boolean;
    room_id: string;
    username: string;
    duration: number;
    ping_timeout: number;
}
interface ApiRes {
    status: number,
    code: number,
    data: any
}




export const publishStream = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `http://${request.mediaBoxIp}:9090/v2/publish_stream`;
    const method = 'POST';

    const req: ApiReq = {
        lecture_id: request.lectureId,
        video: true,
        audio: true,
        record: request.record,
        conference: request.conference,
        room_id: request.roomId ? request.roomId : '',
        username: request.roomId ? 'CAMERA' : '',
        duration: request.duration,
        ping_timeout: request.pingTimeout
    };

    const res:ApiRes = await streamApi<ApiReq, ApiRes>(endPoint, req, method);

    const message = StreamMappingCode[res.code.toString() as StreamResponseCode] || 'FAIL';

    if (res.code.toString() === StreamResponseCode.SUCCESS) {
        return {
            message,
            target: res.data
        };
    }
    return { message };

};

import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 알림 팝업 HOOK */
export default(logout ?: ()=> void) => {

    // 페이지 이동
    const navigate = useNavigate();
    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE.includes('alert')){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popup.TYPE]);


    // 팝업 끄기
    const popupClose =  () => {

        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);

        const TYPE = popup.TYPE;
        const RESERVE = popup.RESERVE;

        setTimeout(() => {
            if (TYPE && RESERVE && TYPE === 'alertLogin') {
                if (RESERVE === 'teacher') {
                    navigate('/service/teacher/home', { replace: true });
                } else if (RESERVE === 'student') {
                    navigate('/service/student/home', { replace: true });
                }
            }

            if (TYPE && (TYPE === 'alertFindPw' || TYPE === 'alertRegister')){
                navigate('/auth/login', { replace: true });
            }

            if (RESERVE && RESERVE === 'autoLogout'){
                // 로그아웃 한뒤, 로그인으로 이동
                if (logout) {
                    logout();
                }
            }

        }, 200);
    };

    return {
        popup,
        popupClose,
        isVisible
    };
};

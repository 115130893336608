import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { checkInManagementStore } from '@/recoil/store/checkIn';
import { getSession } from '@/hooks/common';
import { blendedStateStore } from '@/recoil/store/blended';
import { mirrorAction } from '@/services/native';
import { ExternalPopupInfoStore, WinPcDownloadPathStore } from '@/recoil/store/common';

export default () => {

    const navigate = useNavigate();

    const { sessionBaseInfo } = getSession();

    const setCheckInManagement = useSetRecoilState(checkInManagementStore);
    const checkInManagement = useRecoilValue(checkInManagementStore);
    const blendedState = useRecoilValue(blendedStateStore);
    const setExternalPopupInfo = useSetRecoilState(ExternalPopupInfoStore);

    const setWinPcDownloadPath = useSetRecoilState(WinPcDownloadPathStore);

    useEffect(() => {
        // native callback 시작 --> 화면 최대화 기본
        const handleNativeCallback = (action: string, paramString: string) => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`LINK SCHOOL NATIVE CALLBACK START - ${action} : ${JSON.stringify(paramString)}`);
            }
            try {
                const param = JSON.parse(paramString);
                if (param.command === 'moveto') {
                    handleMoveTo(param);
                } else if (param.command === 'media_projection') {
                    setCheckInManagement(prev => ({ ...prev, monitorStart: param.state !== 'cancel' }));
                } else if (param.command === 'selectdirectory') {
                    setWinPcDownloadPath(param.directory);
                } else if (param.command === 'popupresult') {
                    const result = JSON.parse(param.result);
                    if (result.tokenVersionId && result.encData && result.integrityValue) {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL NATIVE CALLBACK - NICE Redirect');
                        }
                        setExternalPopupInfo(prev => ({ ...prev, callbackData: { passInfo: { tokenVersionId: result.tokenVersionId, encData: result.encData, integrityValue: result.integrityValue } } }));
                    } else {
                        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                            console.log('LINK SCHOOL NATIVE CALLBACK - 팝업 닫힘');
                        }
                        setExternalPopupInfo(prev => ({ ...prev, externalPopup: false }));
                    }
                }
            } catch (e: any) {
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    console.log(`LINK SCHOOL NATIVE CALLBACK FAIL - ${JSON.stringify(e.message)}`);
                }
            }
        };

        const handleMoveTo = (param: any) => {
            const isTeacher = sessionBaseInfo?.baseInfo.role === 'teacher';
            const isCheckedIn = checkInManagement.checkInState;
            const deviceExists = blendedState.deviceId;
            const rolePath = isTeacher ? '/service/teacher' : '/service/student';
            setTimeout(() => {
                mirrorAction('screen', { command: 'state', state: 'max' });
            }, 500);
            switch (param.target) {
                case 'home':
                    navigate(`${rolePath}/home`);
                    break;
                case 'environment':
                    if (isCheckedIn) navigate(`${rolePath}/environment`);
                    else navigate(`${rolePath}/home`);
                    break;
                case 'learning':
                    if (isCheckedIn) navigate(`${rolePath}/learning`);
                    else navigate(`${rolePath}/home`);
                    break;
                case 'blended':
                    if (isTeacher && isCheckedIn && deviceExists) navigate(`${rolePath}/blended`);
                    else navigate(`${rolePath}/home`);
                    break;
                case 'mybox':
                    navigate(`${rolePath}/mybox`);
                    break;
                case 'menuNotice':
                    navigate(`${rolePath}/noticeManage`);
                    break;
                default:
                    navigate(`${rolePath}/home`);
            }
        };

        window.MirrorCallbackAction(handleNativeCallback);

        if (checkInManagement.checkInState) {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('LINK SCHOOL FLOATING CHECKIN');
            }
            window.MirrorMainMessage('checkIn');
        } else {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('LINK SCHOOL FLOATING CHECKOUT');
            }
            window.MirrorMainMessage('offRedDot');
            window.MirrorMainMessage('checkOut');
        }

    }, [checkInManagement.checkInState, blendedState.deviceId]);

    /* 플로팅 제어 */
    useEffect(() => {

        /* 세션정보 플로팅쪽으로 전달 */
        const handleFloatingUICallback = (message: string) => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`LINK SCHOOL MAIN CALLBACK - ${message}`);
            }
            if (message === 'onFloating') {
                window.MirrorMainMessage(sessionBaseInfo?.baseInfo.status === 'approved' ? 'approved' : 'notApproved');
            }
        };

        window.MirrorMainCallbackMessage(handleFloatingUICallback);

        /* 플로팅 On */
        const floatingUrl = `${process.env.REACT_APP_BASEURL}/floating/${sessionBaseInfo?.baseInfo.role}`;
        window.MirrorFloatingShow(true, floatingUrl);


        return () => {
            window.MirrorFloatingShow(false, 'about:blank');
        };
    }, []);
};

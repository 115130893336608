import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';
const { persistAtom } = recoilPersist();



/* LNB onoff  */
export const menuOpenStore = atom<boolean>({
    key: 'menuOpen',
    default: true
});

/* 선생 LNB에서 학습 - 관리 (추후 스크롤 변경 후 삭제)  */
export const lnbStatusStore = atom<boolean>({
    key: 'lnbStatus',
    default: true
});


/* 상단 알림 NEW */
export const alarmDotStore = atom<boolean>({
    key: 'alarmDot',
    default: false
});

/* 상단 설정 NEW */
export const settingDotStore = atom<boolean>({
    key: 'settingDot',
    default: false
});


/* 최상단 로딩 설정 */
export const topLoadingStore = atom<boolean>({
    key: 'topLoading',
    default: false
});

/* 알림 토스트  */
export interface ToastInterface {
    TYPE: string;
    CONTENT ?: string;
    RESERVE ?: string;
    TIME : number;
}

export const toastStore = atom<ToastInterface>({
    key: 'toast',
    default: {
        TYPE: '',
        CONTENT: '',
        RESERVE: '',
        TIME: 300
    }
});



export const toastClassStore = atom<ToastInterface>({
    key: 'toastClass',
    default: {
        TYPE: '',
        CONTENT: '',
        RESERVE: '',
        TIME: 300
    }
});



/* 디바이스정보 */
interface DeviceInfo {
    model: 'WinPC' | 'OPS' | 'Onequick' | 'Signage' | 'Android' | 'Web'
    deviceId: string | null
    osInfo: string
    appVersion: string
}

export const deviceInfoStore = atom<DeviceInfo | null>({
    key: 'deviceInfo',
    default: null
});


/* 최초실행관리 (처음 링스쿨을 실행하는 유저 구분) */
interface FirstAccessInfo {
    access: boolean;
    login: boolean;
}

export const firstAccessStore = atom<FirstAccessInfo>({
    key: 'firstAccess',
    default: {
        access: false,
        login: false
    },
    effects_UNSTABLE: [persistAtom]
});


/* 첫번째 로그인 관리 (최초 로그인이 아닌, 유저 로그아웃 후 재로그인 한 경우) */
export const firstLoginStore = atom<boolean>({
    key: 'firstLogin',
    default: false
});

/* 디바이스 저장경로 */
export const WinPcDownloadPathStore = atom<string>({
    key: 'winPcDownloadPath',
    default: ''
});

/* 네트워크 상태 */
export const NetworkStatusStore = atom<boolean>({
    key: 'networkStatus',
    default: true
});

/* External popup data 처리 */
interface ExternalPopupInfo {
    externalPopup: boolean;
    callbackData?: {
        oAuthInfo?: {
            code: string;
        };
        passInfo?: {
            encData: string;
            integrityValue: string;
            tokenVersionId: string;
        };
    }
}
export const ExternalPopupInfoStore = atom<ExternalPopupInfo>({
    key: 'externalPopupInfo',
    default: {
        externalPopup: false
    }
});







import { streamApi } from '@/services/network';
import { StreamMappingCode, StreamResponseCode } from '@/utils/code-enums';

/* ############################################
 * ############# 스트림 Publish 종료 #############
 * ############################################ */

/* function interface */
export interface FunctionReq {
    mediaBoxIp: string
}

export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
    target?: string
}



/* Api Interface */
interface ApiRes {
    status: number;
    code: number;
    target: string;
}




export const unPublishStream = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `http://${request.mediaBoxIp}:9090/v2/unpublish_stream`;
    const method = 'GET';

    const res:ApiRes = await streamApi<{}, ApiRes>(endPoint, {}, method);

    const message = StreamMappingCode[res.code.toString() as StreamResponseCode] || 'FAIL';

    if (res.code.toString() === StreamResponseCode.SUCCESS) {
        return {
            message,
            target: res.target
        };
    }
    return { message };

};

import '@/components/container/service/full/Container.css';
import React, { ReactNode, useEffect } from 'react';
import {
  ClassGroupInfoInterface,
  ClassInfoInterface,
  MirrorOpenRequestInterface,
  UsersInfoBoxInterface,
  UsersInfoInterface
} from '@/hooks/services/checkIn';
import PopupAlert from '@/components/popup/common/popupAlert';
import PopupDecision from '@/components/popup/common/popupDecision';
import PopupPhoneCert from '@/components/popup/auth/popupPhoneCert';
import PopupSchoolSearch from '@/components/popup/auth/popupSchoolSearch';
import LocalNavigationBar from '@/components/container/service/navbar/LocalNavigationBar';
import TopNavigationBar from '@/components/container/service/navbar/TopNavigationBar';
import ClassModeBar from '@/components/container/service/class/ClassModeBar';
import CheckInToast from '@/components/common/toast/checkInToast';
import AlertToast from '@/components/common/toast/alertToast';
import PopupCommandCancel from '@/components/popup/command/popupCommandCancel';
import MonitorScreen from '@/components/page/service/teacher/class/learning/monitorScreen';
import MirrorContainer from '@/components/container/service/full/mirrorContainer';
import PopupNesting from '@/components/popup/nesting/popupNesting';
import PopupOther from '@/components/popup/other/popupOther';
import PopupCommand from '@/components/popup/command/popupCommand';
import ClassTalkBar from '@/components/container/service/class/ClassTalkBar';
import PopupTargetSelect from '@/components/popup/command/popupTargetSelect';
import PopupGroup from '@/components/popup/learning/popupGroup';
import PopupAutoLogout from '@/components/popup/common/popupAutoLogout';
import PopupSystem from '@/components/popup/common/popupSystem';
import GroupCustomBar from '@/components/container/service/etc/GroupCustomBar';
import PopupMyGroup from '@/components/popup/learning/popupMyGroup';
import PopupClassTarget from '@/components/popup/common/popupClassTarget';
import PopupResetPassword from '@/components/popup/auth/popupResetPassword';
import PopupTutorial from '@/components/popup/common/popupTutorial';
import PopupEnvironment from '@/components/popup/common/popupEnvironment';
import PopupMypage from '@/components/popup/common/popupMypage';
import BoardCapture from '@/components/page/service/teacher/class/myBox/boardCapture';
import PopupAgreement from '@/components/popup/common/popupAgreement';
import PopupNotice from '@/components/popup/common/popupNotice';
import VideoScreen from '@/components/page/service/common/myBox/videoScreen';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import { getSession } from '@/hooks/common';
import { menuOpenStore, NetworkStatusStore } from '@/recoil/store/common';
import MenuClose from '@/assets/icon/LNB/menu_close.svg';   // Dompurify 적용완료
import MenuOpen from '@/assets/icon/LNB/menu_open.svg';     // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import { videoScreenStore } from '@/recoil/store/myBox';
import PageNetwork from '@/components/page/service/common/error/PageNetwork';
import ClassBlendedBar from '@/components/container/service/class/ClassBlendedBar';
import PopupError from '@/components/popup/common/popupError';
import { checkInManagementStore } from '@/recoil/store/checkIn';
import PopupMessage from '@/components/popup/common/popupMessage';





interface ServiceContainerProps {
  children: ReactNode;
  logout: () => void;
  classInfo: ClassInfoInterface | null
  usersInfo: UsersInfoInterface[]
  usersInfoBox: UsersInfoBoxInterface[]
  setUsersInfoBox: React.Dispatch<React.SetStateAction<UsersInfoBoxInterface[]>>
  sendPermissionRequestMessage: (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }) => Promise<boolean>
  mirrorOpenRequest: (request: MirrorOpenRequestInterface) => Promise<boolean>
  mirrorStartCommand: (pubs: string[], subs: string[]) => boolean
  mirrorStopCommand: (mirrorRoomId: number) => boolean
  sendScreenLock?: (type: 'all' | 'one', lock: boolean, id?: string) => Promise<boolean>
  startBlendedRecord?: (recordName: string) => Promise<boolean>
  endBlendedRecord?: () => Promise<boolean>
  startBlendedStreamLearning?: (roomTitle: string) => Promise<boolean>
  joinBlendedStreamLearning: (roomId: string) => Promise<'SUCCESS' | 'FAIL' | 'NONE'>
  requestJoinBlendedStreamLearning?: (userList: string[], roomId: string) => void;
  returnPermissionMessage: (request: { permission: boolean, talkId: string }) => Promise<boolean>
  returnMirrorPermissionMessage: (request: { permission: boolean, talkId: string, senderId: string, targetId: string }) => Promise<boolean>
  getDetailMonitorRequest: (id: string) => void
  detailMonitorImage: { id: string, imageUrl: string } | null
  checkInExtendRequest: () => Promise<boolean>
  classCheckIn?: (classInfo: ClassInfoInterface) => Promise<boolean>
  deleteClassTalk: (talkIds: string[]) => Promise<boolean>
  classModeChange?: (groupMode: boolean, groupInfo?: ClassGroupInfoInterface) => Promise<boolean>
}


// 서비스 앱 페이지 컨테니어
const ServiceContainer = (
  {
    children, logout, usersInfo, usersInfoBox,
    setUsersInfoBox, sendPermissionRequestMessage, mirrorOpenRequest, mirrorStartCommand,
    mirrorStopCommand, sendScreenLock,
    startBlendedStreamLearning, startBlendedRecord, endBlendedRecord, joinBlendedStreamLearning, requestJoinBlendedStreamLearning,
    returnPermissionMessage, returnMirrorPermissionMessage, getDetailMonitorRequest, detailMonitorImage, checkInExtendRequest, classCheckIn, classInfo, deleteClassTalk, classModeChange
  }: ServiceContainerProps) => {


  // 페이지 이동
  const navigate = useNavigate();
  // 경로
  const location = useLocation();
  const locationPath = location.pathname;
  // 탭 메뉴 열기 닫기 여부
  const [isMenuOpen, setIsMenuOpen] = useRecoilState(menuOpenStore);
  // 로그인 정보, 접근 디바이스 (PC인지 태블릿인지)
  const { sessionBaseInfo, deviceInfo } = getSession();
  // 화면이 1500px 이하일 경우
  const isSmallScreen = useMediaQuery('(max-width: 1500px)');
  // mini 클래스 명 선정
  const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';
  // 비디오 스크린
  const videoScreen = useRecoilValue(videoScreenStore);
  let className: string;
  // 네트워크 상태
  const networkStatus = useRecoilValue(NetworkStatusStore);
  // 체크인 관리 ( 각 서버 정상접근중인지 여부 확인 )
  const checkInManagement = useRecoilValue(checkInManagementStore);

  if (locationPath.includes('/floating')) {
    className = 'container-floating';
  } else if (locationPath.includes('/service/teacher/') || locationPath.includes('/service/student/')) {
    className = 'container-class';
  } else {
    className = 'container-auth';
  }


  // 메뉴 탭 열고 닫기 함수
  const toggleSidebar = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  // 페이지 이동 시
  useEffect(() => {
    // 체크인이 되어있지 않았는데 교실환경, 교수학습, 블렌디드 수업 접근 시 -> home으로 이동
    if (!checkInManagement.checkInState) {
      if (locationPath.includes('/environment') || locationPath.includes('/learning') || locationPath.includes('/blended')) {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/home`, { replace: true });
      }
    }
  }, [locationPath]);



  return (

    <>
      {sessionBaseInfo &&
        <div className={className}>


          {/* z index 600 */}
          {/* 서버에러 팝업 */}
          <PopupError />

          {/* z index 500 */}
          {/* 자동로그아웃 팝업 */}
          <PopupAutoLogout logout={logout} />

          {/* z index 490 */}
          {/* 튜토리얼 팝업 */}
          <PopupTutorial />

          {/* z index 480 */}
          {/* 공지사항 팝업 */}
          <PopupNotice />


          {/* z index 400 */}
          {/* 시스템 팝업 */}
          <PopupSystem />

          {/* z index 350 */}
          {/* 교실환경 안내 팝업 */}
          <PopupEnvironment />

          {/* z index 100 */}
          {/* 타유저로부터 요청이 올경우 */}
          <PopupOther usersInfo={usersInfo} returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage} getDetailMonitorRequest={getDetailMonitorRequest} deleteClassTalk={deleteClassTalk} joinBlendedStreamLearning={joinBlendedStreamLearning} />


          {/* z index 80 */}
          {/* 미러링 화면 */}
          <MirrorContainer usersInfo={usersInfo} mirrorStopCommand={mirrorStopCommand} />

          {/* z index 60 */}
          {/* 알림 토스트 */}
          <AlertToast menuOpen={isMenuOpen} />

          {/* z index 51 */}
          {/* 팝업 위의 팝업 (나의 동작 제어로 인한 중첩 팝업) */}
          {/* 알림, 안내, 경고, 결정, 취소 팝업 */}
          <PopupNesting />


          {/* z index 50 */}
          {/* 알림 팝업 */}
          <PopupAlert logout={logout} />
          {/* 로그인 추가인증 */}
          <PopupPhoneCert />
          {/* 학교 검색 */}
          <PopupSchoolSearch />
          {/* 블렌디드 이용약관 팝업 */}
          <PopupAgreement />
          {/* 비밀번호 초기화 */}
          <PopupResetPassword />
          {/* 메시지 상세 팝업 */}
          <PopupMessage />
          {/* 결정 팝업 */}
          <PopupDecision sendScreenLock={sendScreenLock} />
          {/* 파일, 링크, 메시지전송 팝업 */}
          <PopupCommand sendPermissionRequestMessage={sendPermissionRequestMessage} startBlendedStreamLearning={startBlendedStreamLearning} startBlendedRecord={startBlendedRecord} endBlendedRecord={endBlendedRecord} />
          {/* 교수학습 명령 취소 팝업, 지난 모둠 불러오기 팝업 */}
          {sessionBaseInfo.baseInfo.role === 'teacher' &&
            <>
              <PopupCommandCancel />
              <PopupGroup classInfo={classInfo} />
              {/* 반 대상 선택 */}
              <PopupClassTarget />
            </>
          }
          {/* 학습모드 타겟 선택 (학생), 블렌디드 타겟 선택 (선생) */}
          <PopupTargetSelect usersInfo={usersInfo} sendPermissionRequestMessage={sendPermissionRequestMessage} mirrorOpenRequest={mirrorOpenRequest} requestJoinBlendedStreamLearning={requestJoinBlendedStreamLearning} usersInfoBox={usersInfoBox} setUsersInfoBox={setUsersInfoBox} />
          {/* 내 모둠원 확인 팝업 */}
          <PopupMyGroup />
          {/* 마이페이지 팝업 */}
          <PopupMypage />

          {/* z index 40 */}
          {sessionBaseInfo.baseInfo.role === 'teacher' &&
            <>
              {/* 수업모드 조명 설정 */}
              <ClassModeBar />
              {/* 모둠 커스텀 설정 */}
              <GroupCustomBar />
              {/* 수업톡 설정 */}
              <ClassTalkBar returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage} getDetailMonitorRequest={getDetailMonitorRequest} deleteClassTalk={deleteClassTalk} />
              {/* 블렌디드 공개대상 설정 */}
              <ClassBlendedBar />
            </>
          }


          {/* z index 40 */}
          {/* 체크인 토스트 */}
          {
            sessionBaseInfo.baseInfo.role === 'teacher' && checkInExtendRequest && classCheckIn &&
            <CheckInToast menuOpen={isMenuOpen} checkInExtendRequest={checkInExtendRequest} classCheckIn={classCheckIn} />
          }

          {
            videoScreen.OPEN &&
            <VideoScreen />
          }



          {sessionBaseInfo && sessionBaseInfo.baseInfo.role === 'teacher' &&
            <>
              {/* z index 30 */}
              {/* 모니터링 화면 */}
              <MonitorScreen usersInfo={usersInfo} sendScreenLock={sendScreenLock} getDetailMonitorRequest={getDetailMonitorRequest} detailMonitorImage={detailMonitorImage} />
              {/* 전자칠판 화면 상세 */}
              <BoardCapture />
            </>
          }


          {/* 페이지 */}


          {(className !== 'container-floating') ?
            <>
              {/* 좌측 네이비게이션 바 */}
              <LocalNavigationBar menuOpen={isMenuOpen} classInfo={classInfo} />


              {/* LNB 온오프 버튼 */}
              <div className={`left-toggle-box ${miniClass}  ${!isMenuOpen && 'closed'}`}>
                <div className='left-toggle' onClick={() => toggleSidebar()}>
                  <img src={`${isMenuOpen ? publicMethod.svgClear(MenuClose) : publicMethod.svgClear(MenuOpen)}`} alt={'MenuToggle'} draggable={false} />
                </div>
              </div>

              {/* 우측 90%를 차지하는 컨텐츠 영역 */}
              <div className={`container-content ${miniClass} ${isMenuOpen ? '' : 'tab-closed'}`}>

                {/* 네트워크 연결 에러 발생 시 노출 */}
                {
                  !networkStatus &&
                  <div className='container-network-error'>
                    <PageNetwork />
                  </div>
                }

                {/* 컨텐츠 영역 */}
                <div className='container-page'>

                  {/* 상단 네이비게이션 바 */}
                  <TopNavigationBar logout={logout} usersInfo={usersInfo} classInfo={classInfo} classModeChange={classModeChange} />


                  {/* 내부 컨텐츠 */}
                  {children}

                </div>

              </div>
            </>
            :
            <>
              {children}
            </>
          }
        </div>
      }
    </>

  );


};

export default ServiceContainer;

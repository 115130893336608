import { contentApi } from '@/services/network';
import { getCodeList } from '@/services/content/code/getCodeList';
import publicMethod from '@/utils/publicMethod';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* #####################################
 * ############# 음원목록조회 #############
 * ##################################### */


/* function interface */
export interface FunctionReq {
    token: string
    classificationCode: string
    releaseTargetId: string
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: MusicUseInterface[]
}

export interface MusicUseInterface {
    id: number
    name: string
    duration: string
    format: string
    fileName: string
    fileLength: string
    contentInfoId: number
    thumbnailId: number
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
    data: MusicInterface[]
}

export interface MusicInterface {
    album: string
    artist: string
    classificationCode: string
    contentInfoId: number
    createdBy: string
    createdById: string
    createdDate: string
    duration: number
    fileLength: string
    fileName: string
    format: string
    generalReleaseStatus: string
    genre: string
    id: number
    lastModifiedBy: string
    lastModifiedById: string
    lastModifiedDate: string
    name: string
    releaseClassification: string
    releaseDate: string
    releaseStatus: string
    releaseTarget: string
    releaseTargetId: string
    schoolReleaseStatus: string
    thumbnailId: string
    title: string
}



export const getMusicList = async(request: FunctionReq): Promise<FunctionRes> => {
    let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/sound/search-all`;
    const method = 'GET';

    endPoint += `?releaseTargetId=${ request.releaseTargetId }&classificationCode=${ request.classificationCode }`;

    const musicList = await contentApi<any, ApiRes>(endPoint, {}, method, request.token, null);

    if (musicList.code === ContentResponseCode.OK && musicList.data.length > 0) {

        musicList.data = musicList.data.map((music: MusicInterface) => {
            return {
                id: music.id,
                name: music.name,
                duration: publicMethod.formatDuration(Math.ceil(music.duration)),
                format: music.format,
                fileName: music.fileName,
                fileLength: music.fileLength,
                contentInfoId: music.contentInfoId,
                thumbnailId: music.thumbnailId
            };
        });


        return {
            code: musicList.code,
            status: 200,
            message: '음원 조회 성공',
            data: musicList.data
        };
    } else {
        return {
            code: musicList.code,
            status: 410,
            message: '음원 조회 실패',
            data: []
        };
    }
};

import React, { useEffect, useState } from 'react';
import '@/components/popup/popup.css';
import { useRecoilState } from 'recoil';
import { getSession } from '@/hooks/common';
import { classTargetStore } from '@/recoil/store/class';
import { popupStore } from '@/recoil/store/popup';
import Scrollbar from '@/components/common/scroll/ScrollBar';
import CloseIcon from '@/assets/icon/popup/close_button.svg';           // Dompurify 적용완료
import DeleteClassIcon from '@/assets/icon/popup/delete_class.svg';     // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



// 매핑 클래스 인터페이스
interface MappingClassInterface {
  classId: string                     // 교실 id
  userClassId?: string                // 수업 식별 id ( 디바이스는 없음 )
  className: string                   // 교실명
  grade: string                       // 학년
  department?: string                // 학과
  classificationType?: string         // homeroom(담임) | assistant(부담임) | subject(교과교사) | student(학생)
}


/* ———————— 반 선택 팝업 (공지대상)  ———————— */
const PopupClassTarget = () => {

  const { sessionBaseInfo } = getSession();
  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 학년 선택
  const [selectedGrade, setSelectedGrade] = useState<string>('');
  // 알림 대상 학급 리스트 (클라이언트, 팝업 내부에서만 사용)
  const [selectedClassList, setSelectedClassList] = useState<{ classId: string, className: string }[]>([]);
  // 알림 대상 학급 리스트 (recoil, 저장)
  const [classTarget, setClassTarget] = useRecoilState(classTargetStore);





  useEffect(() => {
    if (popup.TYPE.includes('classTarget')) {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
        // 팝업 열 때, classTarget의 데이터 팝업 내의 classList에 추가
        setSelectedClassList(classTarget);
      }, 100);
    }
  }, [popup.TYPE]);


  const popupClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setPopup((prevData) => ({ ...prevData, TYPE: '' }));
      // 선택한 학년, 학급 초기화
      setSelectedGrade('');
      setSelectedClassList([]);
      document.body.style.overflow = '';
    }, 100);
  };

  /* 학년 클릭 시 */
  const handleGradeClick = (grade: string) => {
    setSelectedGrade(grade);
  };

  /* 학급 클릭 시 */
  const handleClassClick = (item: MappingClassInterface) => () => {
    // 선택한 학급 정보를 selectedClassList에 추가
    setSelectedClassList((prevData) => {
      const isExist = prevData.find((data) => data.classId === item.classId);
      if (isExist) {
        return prevData.filter((data) => data.classId !== item.classId);
      } else {
        return [...prevData, { classId: item.classId, className: item.className }];
      }
    });

  };

  /* 선택한 학급 삭제 */
  const deleteClassTarget = (classId: string) => () => {
    setSelectedClassList((prevData) => {
      return prevData.filter((data) => data.classId !== classId);
    });
  };


  /* 학급 선택 완료 */
  const confirmClassTarget = () => {
    setClassTarget(selectedClassList);
    popupClose();
  };



  return (
    <div className={`layout-popup ${popup.TYPE === 'classTarget'} ${isVisible && 'visible'}`}>
      <div className={'layout-popup-box command'}>
        <div className={'cmd-lp-top'}>
          <div className='cmd-lp-close-box'>
            <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
          </div>
          <div className='cmd-lp-cover'>
            <span>Select {popup.TITLE}</span>
          </div>
        </div>

        <div className='ctl-box'>
          <div className='ctl-box-inner'>
            <Scrollbar scrollbarHeight={'100%'}>
              {sessionBaseInfo?.mappingClass?.map(item => item.grade)
                .filter((value, index, self) => self.indexOf(value) === index)
                .sort((a, b) => Number(a) - Number(b))
                .map(grade => (
                  <div key={grade} className={`ctl-item ${selectedGrade === grade && 'selected'}`} onClick={() => handleGradeClick(grade)}>
                    <span> {grade} Grade </span>
                  </div>
                ))}
            </Scrollbar>
          </div>
          <div className={`ctl-box-inner ${selectedGrade === '' && 'none'}`}>
            {selectedGrade === ''
              ?
              <p>Please select a grade first.</p>
              :
              <Scrollbar scrollbarHeight={'100%'}>
                {
                  sessionBaseInfo?.mappingClass?.filter(item => item.grade === selectedGrade).sort((a, b) => {
                    // Set empty string if department does not exist
                    const deptA = a.department ?? '';
                    const deptB = b.department ?? '';
                    // Compare departments in Korean order
                    if (deptA < deptB) return -1;
                    if (deptA > deptB) return 1;
                    // If departments are the same, compare className in ascending order
                    const classA = parseInt(a.className, 10);
                    const classB = parseInt(b.className, 10);
                    return classA - classB;
                  })
                    .map((item: any, index: number) => (
                      // Mark class selected if in selectedClassList
                      <div key={item.classId} className={`ctl-item ${selectedClassList.find(u => u.classId === item.classId) && 'selected'}`} onClick={handleClassClick(item)}>
                        <span>{(item.department && item.department !== 'General' && item.department !== '-') ? `${item.department} Department ${item.className} Class` : `${item.className} Class`}</span>
                      </div>
                    ))}
              </Scrollbar>

            }
          </div>
        </div>

        {
          selectedClassList.length > 0
            ?
            <div className='cts-total'>
              <Scrollbar scrollbarHeight={'100%'}>
                <div className='cts-box'>
                  {
                    selectedClassList.map((item, index) => (
                      <div key={item.classId}>
                        {sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId)?.grade} Grade
                        {(sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId)?.department && sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId)?.department !== 'General') && `${sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId)?.department} Department`}
                        {sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId)?.className} Class
                        <img src={publicMethod.svgClear(DeleteClassIcon)} alt={'DeleteClassIcon'} draggable={false} onClick={deleteClassTarget(item.classId)} />
                      </div>
                    ))
                  }
                </div>
              </Scrollbar>
            </div>
            :
            <div className='cts-box-none'>
              <span>Please select {popup.TITLE}.</span>
            </div>
        }

        <div className='layout-popup-button-box'>
          <button className={`layout-popup-button ${selectedClassList.length === 0 && 'disable'}`} onClick={selectedClassList.length > 0 ? confirmClassTarget : undefined}>
            {popup.BUTTON}
          </button>
        </div>

      </div>
    </div>
  );

};

export default PopupClassTarget;

import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ###################################################
 * ############# 2차인증/비밀번호찾기 코드 확인 #############
 * ################################################### */


/* function interface */
export interface FunctionReq {
  requestId: string
  code: string
}

export interface FunctionRes {
  message: string         // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
  callbackId?: string
}


/* Api Interface */
interface ApiRes {
  status: number
  data?: {
    callbackId: string
  }
  code: string
  message: string
}

interface ApiReq {
  requestId: string
  code: string
}



export const verifyCode = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/auth/verify-code`;
  const method = 'POST';

  const requestBody: ApiReq = {
    requestId: request.requestId,
    code: request.code
  };

  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, requestBody, method, null, null);

  let message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to verify the verification code.';
  let callbackId: string | null = null;

  if (res.code === CoreResponseCode.CODE_VERIFY_SUCCESS && res.data) {
    callbackId = res.data.callbackId;
  }

  const returnObj: FunctionRes = {
    message,
    errorCode,
    errorMessage
  };
  if (callbackId) {
    returnObj.callbackId = callbackId;
  }
  return returnObj;

};

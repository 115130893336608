import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { popupNoticeAgainListStore, popupNoticeStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 공지사항 팝업 HOOK */
export default() => {



    // 팝업 공지사항 state
    const popupNotice = useRecoilValue(popupNoticeStore);
    // 팝업 공지사항 초기화
    const resetPopupNotice = useResetRecoilState(popupNoticeStore);
    // 다시보지 않기 리스트
    const setPopupNoticeAgainList = useSetRecoilState(popupNoticeAgainListStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupNotice.TYPE.includes('notice')){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popupNotice.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            resetPopupNotice();
            document.body.style.overflow = '';
        }, 100);

        // 유틸리티 상단 쪽 레드닷 on/off - 개별 알림 읽기 -> 추후 데이터 줄 경우 전달
    };

    // 팝업 다시 보지 않기 클릭
    const popupAgainNone =  () => {
        setPopupNoticeAgainList(Number(popupNotice.ID));
        // 팝업 닫기 및 읽음 처리
        popupClose();
    };



    return { popupNotice, isVisible, popupClose, popupAgainNone };
};

import '@/components/page/service/common/myBox/videoScreen.css';
import 'video.js/dist/video-js.css';
import videoScreenHook from '@/hooks/components/page/service/common/myBox/videoScreen';
import CloseIcon from '@/assets/icon/video/close_icon.svg';             // Dompurify 적용완료
import ScreenMinIcon from '@/assets/icon/video/screen_min_icon.svg';    // Dompurify 적용완료
import ScreenMaxIcon from '@/assets/icon/video/screen_max_icon.svg';    // Dompurify 적용완료
import PlayIcon from '@/assets/icon/video/play-icon.svg';               // Dompurify 적용완료
import VolumnOnIcon from '@/assets/icon/video/volumn_on_icon.svg';      // Dompurify 적용완료
import VolumnOffIcon from '@/assets/icon/video/volumn_off_icon.svg';    // Dompurify 적용완료
import PauseIcon from '@/assets/icon/video/pause_icon.svg';             // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



/* 모니터링 상세화면 */
const VideoScreen = () => {

  const { isVisible, videoRef, playerRef, videoScreen, setIsHovered, setVideoScreen, videoClose } = videoScreenHook();

  return (
    <>
      {
        videoScreen.OPEN &&
        /* Monitoring background - dimgray */
        <div className={`layout-video ${isVisible && 'visible'}`}>
          {/* Monitoring box */}
          <div
            className={`layout-video-box ${videoScreen.ZOOM && 'full'}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onTouchStart={() => setIsHovered(true)}
            onTouchMove={() => setIsHovered(true)}
            onTouchEnd={() => { setTimeout(() => setIsHovered(false), 3000); }}
            onClick={() => {
              if (videoScreen.PLAY) {
                playerRef.current.pause();
              } else {
                playerRef.current.play();
              }
            }}
          >
            <div className="header-menu">
              <div className="inner">
                {videoScreen.ZOOM ? (
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      setVideoScreen({ ...videoScreen, ZOOM: false });
                    }}
                  >
                    <img src={publicMethod.svgClear(ScreenMaxIcon)} alt="ScreenMaxIcon" className="icon" />
                  </span>
                ) : (
                  <span
                    onClick={e => {
                      e.stopPropagation();
                      setVideoScreen({ ...videoScreen, ZOOM: true });
                    }}
                  >
                    <img src={publicMethod.svgClear(ScreenMinIcon)} alt="ScreenMinIcon" className="icon" />
                  </span>
                )}

                <span
                  onClick={() => {
                    videoClose();
                  }}
                >
                  <img src={publicMethod.svgClear(CloseIcon)} alt="CloseIcon" className="icon" />
                </span>
              </div>
            </div>

            <div className="video-box">
              <div data-vjs-player style={{ width: '100%', height: '100%', outline: 'none' }}>
                <div ref={videoRef} />
              </div>

              {/* <div className="overlay-head">
                <div className="title">
                  <p>
                    This video is a copyrighted work for educational purposes under Article 25, Section 2 of the Copyright Act. Therefore,
                    it is prohibited to disclose or post this video to the public or to others. <br />
                    Violating this may result in copyright infringement penalties according to relevant laws.
                  </p>
                </div>
              </div> */}

              <div className={`overlay-bottom ${videoScreen.HIDE && 'hide'}`}>
                <div className="content">
                  <p>
                    <span> {ellipsisText(`[${videoScreen.DATA.subjectName}]`, 8)} </span>
                    <span>{ellipsisText(videoScreen.DATA.title, 30)}</span>
                  </p>

                  <p>
                    <span> {videoScreen.DATA.teacherName} Teacher </span>
                    <span> {publicMethod.convertDate(videoScreen.DATA.recordDate)} </span>
                  </p>
                </div>

                <div className="status">
                  {!videoScreen.PLAY ? (
                    <div className="play" onClick={() => playerRef.current?.play()}>
                      <img src={publicMethod.svgClear(PlayIcon)} alt="PlayIcon" />
                    </div>
                  ) : (
                    <div className="pause" onClick={() => playerRef.current?.pause()}>
                      <img src={publicMethod.svgClear(PauseIcon)} alt="PauseIcon" />
                    </div>
                  )}

                  <div className="duration" onClick={e => e.stopPropagation()}>
                    <div className="duration-input" onClick={e => e.stopPropagation()}>
                      <div className="rail" style={{ zIndex: -1 }} />
                      <div className="track" style={{ width: `${(playerRef.current?.currentTime() / playerRef.current?.duration()) * 100}%` }} />
                      <input
                        type="range"
                        min="0"
                        max={videoScreen.DURATION}
                        step="1"
                        value={playerRef.current?.currentTime() ? playerRef.current?.currentTime() : 0}
                        onChange={e => playerRef.current.currentTime(e.target.value)}
                        className="slider duration-slider"
                      />
                    </div>
                    <span className="time">
                      {playerRef.current && formatTime(playerRef.current?.currentTime())} / {formatTime(playerRef.current?.duration())}
                    </span>
                  </div>

                  <div className="volume" onClick={e => e.stopPropagation()}>
                    {videoScreen.MUTE && <img src={publicMethod.svgClear(VolumnOffIcon)} alt="VolumnOffIcon" onClick={() => playerRef.current.volume(0.5)} />}
                    {!videoScreen.MUTE && <img src={publicMethod.svgClear(VolumnOnIcon)} alt="VolumnOnIcon" onClick={() => playerRef.current.volume(0)} />}

                    <div className="volume-input">
                      <div className="rail" />
                      <div className="track" style={{ width: `${videoScreen.VOLUME * 100}%` }} />
                      <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={videoScreen.VOLUME}
                        onChange={e => playerRef.current.volume(e.target.value)}
                        className="slider volume-slider"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </>
  );

};

export default VideoScreen;


// 초 단위를 MM:SS 형태로 변환
const formatTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time - minutes * 60);

  let formattedSeconds;
  if (seconds < 10) {
    formattedSeconds = `0${seconds}`;
  } else {
    formattedSeconds = seconds;
  }

  return `${minutes}:${formattedSeconds}`;
};

// 글자 초과시 말줄임 처리
const ellipsisText = (text: string, length: number) => {
  return text.length > length ? `${text.slice(0, length)}...` : text;
};

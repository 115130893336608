/*
 * 공통사용 사용자 정의 모듈 위치
 * */

const base64ToFile = (base64: string, filename: string, mimeType: string): File => {
  // 접두사 제거
  const base64Data = base64.replace(/^data:image\/[a-z]+;base64,/, '');

  // 공백 제거
  const cleanedBase64 = base64Data.replace(/\s/g, '');

  // base64 유효성 검사
  function isValidBase64(str: string): boolean {
    return /^([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/.test(str);
  }

  if (!isValidBase64(cleanedBase64)) {
    throw new Error("유효하지 않은 base64 문자열입니다.");
  }

  try {
    const byteCharacters = atob(cleanedBase64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: mimeType });
    return new File([blob], filename, { type: mimeType });
  } catch (e) {
    console.error("base64 디코딩 실패:", e);
    throw new Error("base64 디코딩 중 오류가 발생했습니다.");
  }
};

// 오늘 날짜 String으로 변환
const onlyTodayDateString = (rtnType: string): string => {
  const originalDate = new Date();

  const year = originalDate.getFullYear().toString().padStart(4, '0');
  const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
  const day = originalDate.getDate().toString().padStart(2, '0');

  if (rtnType === 'NONE') {
    return `${year}${month}${day}`;
  } else if (rtnType === 'LINE') {
    return `${year}-${month}-${day}`;
  } else if (rtnType === 'DOT') {
    return `${year}.${month}.${day}`;
  } else {
    return '';
  }
};

/* 요일을 입력받아 금주 날짜 반환 */
const getThisWeekDay = (day: string): string => {
  const today = new Date();
  const dayOfWeek = today.getDay(); // 0 (Sunday) to 6 (Saturday)
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust when today is Sunday
  const mondayDate = new Date(today.setDate(diff));

  // Map '월' ~ '일' to their corresponding days
  const dayMap: { [key: string]: number } = {
    'Mon': 1,
    'Tue': 2,
    'Wed': 3,
    'Thu': 4,
    'Fri': 5,
    'Sat': 6,
    'Sun': 7
  };


  const dayNumber = dayMap[day];
  // Calculate the date by adding the difference between the input day and Monday
  const targetDate = new Date(mondayDate);
  targetDate.setDate(mondayDate.getDate() + (dayNumber - 1));
  // Format the date as 'yyyymmdd'
  const year = targetDate.getFullYear().toString();
  const month = (targetDate.getMonth() + 1).toString().padStart(2, '0');
  const dayOfMonth = targetDate.getDate().toString().padStart(2, '0');
  return year + month + dayOfMonth;
};

/* 오늘 요일 반환 */
const getToday = (): string => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const date = new Date();
  return days[date.getDay()];
};


export {
  base64ToFile, onlyTodayDateString, getThisWeekDay, getToday
};

import { coreApi } from '@/services/network';
import { getThisWeekDay, getToday } from '@/utils/common';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ####################################
 * ############# 교실 시간표 #############
 * #################################### */





/* function interface */
export interface FunctionReq {
  classId: string             // sessionBaseInfo.base
  weekNumber: number
  currentSemester: number
  token: string
}
interface TimetableClassInfo {
  classSubjectId: string
  classroomId: string
  subject: {
    name: string
    code: string
  }
  weekNumber: string
  semester: string
  year: string,
  teacher?: {
    userId: string
    neisId: string
    name: string
  }
  startTime: string
  endTime: string
}
interface TimetableDayInfo {
  '1'?: TimetableClassInfo
  '2'?: TimetableClassInfo
  '3'?: TimetableClassInfo
  '4'?: TimetableClassInfo
  '5'?: TimetableClassInfo
  '6'?: TimetableClassInfo
  '7'?: TimetableClassInfo
  '8'?: TimetableClassInfo
  '9'?: TimetableClassInfo
  '10'?: TimetableClassInfo
  '11'?: TimetableClassInfo
  '12'?: TimetableClassInfo
}
interface TimetableWeekInfo {
  '월'?: TimetableDayInfo
  '화'?: TimetableDayInfo
  '수'?: TimetableDayInfo
  '목'?: TimetableDayInfo
  '금'?: TimetableDayInfo
  '토'?: TimetableDayInfo
  '일'?: TimetableDayInfo
}



interface CustomShowTimeTable {
  day: string
  dayNumber: number,
  classTime: {
    period: string, subject: string, classNumber: string, teacherName: string, startTime: string, endTime: string, useClass: boolean
  }[]
}
interface CustomScheduleTimeTable {
  today: string
  timeTable: {
    classId: string
    className: string
    startTime: string
    endTime: string
  }[]
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
  data: {
    showTimeTable: CustomShowTimeTable[]
    scheduleTimeTable: CustomScheduleTimeTable
  }
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  data?: {
    classroom: {
      id: string,
      name: string,
      grade: string,
      department: string,
      classNumber: string,
      specialClassroom: null | string,
      teacherInCharge?: {
        name: string,
        neisId: string
      },
      assistantInCharge?: {
        name: string,
        neisId: string
      },
      totalStudent: number
    }
    timetableData: TimetableWeekInfo
  }
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
}



export const classTimetable = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/timetable/classroom/${request.classId}`;
  const method = 'GET';

  let queryString = `?weekNumber=${request.weekNumber}&currentSemester=${request.currentSemester}`;

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint + queryString, {}, method, request.token, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve the timetable.';

  if (res.code === CoreResponseCode.GET_CLASSROOM_TIMETABLE_DETAILS_SUCCESS && res.data) {

    if (Object.keys(res.data.timetableData).length === 0) {
      return {
        message: 'NONE',
        errorCode,
        errorMessage,
        data: {
          showTimeTable: [],
          scheduleTimeTable: {
            today: getThisWeekDay(getToday()),
            timeTable: []
          }
        }
      };
    }

    const daysOfWeek = ['월', '화', '수', '목', '금', '토', '일'];

    let tempShowTimeTable: CustomShowTimeTable[] = [];
    const schedule: CustomScheduleTimeTable['timeTable'] = [];

    let maxPeriod = 7;

    /*
     * - 최대 교시는 12교시까지 있으며 최소 7교시로 표기한다.
     * - 스케줄을 전부 확인하여 최대교시를 구해오며 만약 최대교시가 7교시 이하라면 7교시까지 표기한다.
     * */
    for (const day of daysOfWeek) {
      const dayData = res.data.timetableData[day as keyof TimetableWeekInfo];
      if (dayData) {
        for (let i = 1; i <= 12; i++) {
          const classInfo = dayData[i.toString() as keyof TimetableDayInfo];
          if (classInfo) {
            maxPeriod = maxPeriod > i ? maxPeriod : i;
          }
        }
      }
    }

    for (const day of daysOfWeek) {
      const classes: CustomShowTimeTable['classTime'] = [];
      const dayData = res.data.timetableData[day as keyof TimetableWeekInfo];

      if (dayData) {
        for (let i = 1; i <= maxPeriod; i++) {

          const classInfo = dayData[i.toString() as keyof TimetableDayInfo];
          const splitStartTime = classInfo?.startTime.split(':');
          const splitEndTime = classInfo?.endTime.split(':');

          if (classInfo && splitStartTime && splitStartTime.length === 2 && splitEndTime && splitEndTime.length === 2) {
            // Process classInfo
            const classId = res.data.classroom.id;
            const className = classInfo.subject.name;

            const splitStartTimeFirst = splitStartTime[0].length < 2 ? `0${splitStartTime[0]}` : splitStartTime[0];
            const splitStartTimeSecond = splitStartTime[1].length < 2 ? `0${splitStartTime[1]}` : splitStartTime[1];

            const splitEndTimeFirst = splitEndTime[0].length < 2 ? `0${splitEndTime[0]}` : splitEndTime[0];
            const splitEndTimeSecond = splitEndTime[1].length < 2 ? `0${splitEndTime[1]}` : splitEndTime[1];


            const startTime = `${splitStartTimeFirst + splitStartTimeSecond}00`;
            const endTime = `${splitEndTimeFirst + splitEndTimeSecond}00`;

            let classNumber = `${res.data.classroom.grade} - ${res.data.classroom.classNumber} class`;

            if (res.data.classroom.specialClassroom) {
              classNumber = res.data.classroom.specialClassroom;
            }

            classes.push({
              period: `${i} period`,
              subject: className,
              classNumber: classNumber,
              teacherName: classInfo.teacher?.name ? classInfo.teacher.name : '',
              startTime: startTime,
              endTime: endTime,
              useClass: true
            });

            if (getToday() === day) {
              schedule.push({ classId, className, startTime, endTime });
            }

          } else {
            classes.push({
              period: `${i} period`,
              subject: '',
              classNumber: '',
              teacherName: '',
              startTime: '',
              endTime: '',
              useClass: false
            });
          }
        }
      }
      else {
        if (day !== '토' && day !== '일') {
          for (let i = 1; i <= maxPeriod; i++) {
            classes.push({
              period: `${i} period`,
              subject: '',
              classNumber: '',
              teacherName: '',
              startTime: '',
              endTime: '',
              useClass: false
            });
          }
        }
      }
      if (classes.length > 0) {
        tempShowTimeTable.push({ day, dayNumber: daysOfWeek.indexOf(day) + 1, classTime: classes });
      }
    }


    return {
      message,
      errorCode,
      errorMessage,
      data: {
        showTimeTable: tempShowTimeTable,
        scheduleTimeTable: {
          today: getThisWeekDay(getToday()),
          timeTable: schedule
        }
      }
    };
  }
  return {
    message,
    errorCode,
    errorMessage,
    data: {
      showTimeTable: [],
      scheduleTimeTable: {
        today: getThisWeekDay(getToday()),
        timeTable: []
      }
    }
  };
};

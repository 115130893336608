import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { popupErrorStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { userBaseInfoStore, userTokenInfoStore } from '@/recoil/store/user';

/* 에러 팝업 HOOK */
export default() => {


    // 페이지 이동
    const navigate = useNavigate();
    // 팝업 state
    const [popupError, setPopupError] = useRecoilState(popupErrorStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 기본정보 초기화
    const resetUserBaseInfo = useResetRecoilState(userBaseInfoStore);
    const resetUserTokenInfo = useResetRecoilState(userTokenInfoStore);

    /** 팝업 노출 시키기**/
    useEffect(() => {

        if(popupError.length > 0){
            setTimeout(() => {
                // 팝업 visible
                setIsVisible(true);
                // body scroll 막기
                document.body.style.overflow = 'hidden';
            }, 100);
        }

    }, [popupError.length]);


    // 팝업 끄기
    const popupClose = (index : number) => {

        // 마지막 에러 팝업을 닫을 경우
        if(index === 0){
            // 팝업 invisible
            setIsVisible(false);
            // body scroll 재활성화
            document.body.style.overflow = '';
        }

        // 현재 에러 리스트의 1번째 데이터 삭제
        setTimeout(() => {
            setPopupError(prevData => prevData.slice(1));
        }, 100);

        /* @@@@@@@@@@@@@@@@@@@@@@@@@@@ 팝업 확인 클릭 시 -> 다른 동작제어가 필요할 경우  @@@@@@@@@@@@@@@@@@@@@@@@@@@*/

        const RESERVE = popupError[0].RESERVE;

        setTimeout(() => {
            if (RESERVE && RESERVE === 'back'){
                navigate(-1);
            }else if(RESERVE && RESERVE === 'login'){
                resetUserBaseInfo();
                resetUserTokenInfo();
                navigate('/auth/login', { replace: true });
            }
        }, 200);

    };

    return {
        popupError,
        popupClose,
        isVisible
    };
};

import { useEffect, useState } from 'react';
import '@/components/container/service/class/ClassModeBar.css';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { classModeStore } from '@/recoil/store/class';
import CloseButton from '@/assets/icon/popup/close_button.svg'; // Dompurify 적용완료
import Iconsvg from '@/components/common/Iconsvg';
import { popupStore } from '@/recoil/store/popup';
import publicMethod from '@/utils/publicMethod';


/* Class Mode Lighting Settings */
const ClassModeBar = () => {

  // Popup state
  const [popup, setPopup] = useRecoilState(popupStore);
  const resetPopup = useResetRecoilState(popupStore);
  const [classMode, setClassMode] = useRecoilState(classModeStore);

  const [switchItems, setSwitchItems] = useState<{ id: string, title: string, lightness: number, color: number }[]>([]);

  /* Initial Access */
  useEffect(() => {
    if (classMode.openPopup) {
      setSwitchItems([
        { id: 'lecture', title: 'Lecture', lightness: classMode.lecture.lightness, color: classMode.lecture.color },
        { id: 'talk', title: 'Talk', lightness: classMode.talk.lightness, color: classMode.talk.color },
        { id: 'multi', title: 'Multimedia', lightness: classMode.multi.lightness, color: classMode.multi.color },
        { id: 'rest', title: 'Break', lightness: classMode.rest.lightness, color: classMode.rest.color }
      ]);
    }
  }, [classMode.openPopup]);

  /* Close Class Mode Bar on Popup Change */
  useEffect(() => {
    if (popup.RESERVE === 'decisionCloseClassModeBar') {
      setClassMode({
        ...classMode,
        openPopup: false
      });
      // Reset Popup
      resetPopup();
    }
  }, [popup.RESERVE]);

  /* Close Class Mode Lighting Settings */
  const navClose = () => {
    setPopup({ TYPE: 'decisionCloseClassModeBar', TITLE: 'Notification', CONTENT: 'Changes will not be saved. \n Do you want to close the popup?', BUTTON: 'Close', RESERVE: '' });
  };

  /* Save Class Mode Lighting Settings */
  const saveClassMode = () => {
    setClassMode({
      ...classMode,
      openPopup: false,
      lecture: { lightness: switchItems[0].lightness, color: switchItems[0].color },
      talk: { lightness: switchItems[1].lightness, color: switchItems[1].color },
      multi: { lightness: switchItems[2].lightness, color: switchItems[2].color },
      rest: { lightness: switchItems[3].lightness, color: switchItems[3].color }
    });
  };

  const changeLightCount = (itemId: string, operation: string, property: string) => {
    setSwitchItems(currentItems =>
      currentItems.map(item => {
        if (item.id === itemId) {
          // @ts-ignore
          let newValue = item[property];
          if (operation === 'plus') {
            newValue += 1;
          } else if (operation === 'minus') {
            newValue -= 1;
          }
          newValue = Math.max(1, Math.min(newValue, 11));

          return { ...item, [property]: newValue };
        }
        return item;
      })
    );
  };

  const resetLightCount = (itemId: string) => {
    setSwitchItems(currentItems =>
      currentItems.map(item => {
        if (item.id === itemId) {
          return { ...item, lightness: 5, color: 5 };
        }
        return item;
      })
    );
  };

  return (
    <div className={`layout-class-mode ${classMode.openPopup && 'visible'}`}>

      <section className={`class-mode-section ${classMode.openPopup && 'open'}`}>
        {classMode.openPopup &&
          <>
            <div className='class-mode-title'>
              <div className='class-mode-img'></div>
              <span>Class Mode Lighting Settings</span>
              <figure className='class-mode-img' style={{ cursor: 'pointer' }} onClick={navClose}>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} />
              </figure>
            </div>

            <div className='class-mode-switch'>
              {switchItems.map((item, index) => (
                <div key={index} className='cm-switch-item'>
                  <span className='cm-switch-title'>
                    {item.title}
                  </span>
                  <div className='cm-switch-ib'>
                    <div className='cm-switch-ib-item'>
                      <button className='cm-switch-ib-button' onClick={() => changeLightCount(item.id, 'plus', 'lightness')}>
                        <Iconsvg name={'arrowUp'} />
                      </button>
                      <div className='cm-switch-ib-detail'>
                        Brightness<span>{` ${item.lightness}`}</span>
                      </div>
                      <button className='cm-switch-ib-button' onClick={() => changeLightCount(item.id, 'minus', 'lightness')}>
                        <Iconsvg name={'arrowDown'} />
                      </button>
                    </div>
                    <div className='cm-switch-ib-item'>
                      <button className='cm-switch-ib-button red' onClick={() => changeLightCount(item.id, 'plus', 'color')}>
                        <Iconsvg name={'arrowUp'} />
                      </button>
                      <div className='cm-switch-ib-detail'>
                        Color<span>{` ${item.color}`}</span>
                      </div>
                      <button className='cm-switch-ib-button blue' onClick={() => changeLightCount(item.id, 'minus', 'color')}>
                        <Iconsvg name={'arrowDown'} />
                      </button>
                    </div>
                  </div>
                  <button className='cm-switch-ib-button' onClick={() => resetLightCount(item.id)}>
                    <span>Restore Default</span>
                  </button>
                </div>
              ))}

            </div>

            <button className='class-mode-save' onClick={saveClassMode}>
              <span>Save</span>
            </button>
          </>
        }
      </section>

    </div>
  );
};

export default ClassModeBar;

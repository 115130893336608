import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import '@/components/container/service/class/ClassBlenedBar.css';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';
import {
  classBlendedToggleStore,
  classTargetStore
} from '@/recoil/store/class';
import { getSession } from '@/hooks/common';
import { useMediaQuery } from '@mui/material';
import CloseButton from '@/assets/icon/popup/close_button.svg'; // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import ArrowFillDown from '@/assets/icon/mypage/arrow_fill_down.svg';
import Scrollbar from '@/components/common/scroll/ScrollBar';
import { popupStore } from '@/recoil/store/popup';
import { FunctionReq as mraFunctionReq, modifyRecord } from '@/services/content/document/video/modifyRecord';
import { FunctionReq as gdFunctionReq, getDetail } from '@/services/content/document/video/getDetail';
import { useNavigate } from 'react-router-dom';
import { forceLogoutStore } from '@/recoil/store/user';
import { toastStore } from '@/recoil/store/common';





/* 블렌디드 공개대상 설정 */
const ClassBlendedBar = () => {


  // 페이지 이동
  const navigate = useNavigate();
  // 토큰 정보, 세션 정보
  const { sessionTokenInfo, sessionBaseInfo } = getSession();
  // 접근 디바이스 (PC인지 태블릿인지)
  const { deviceInfo } = getSession();
  // 화면이 1500px 이하일 경우
  const isSmallScreen = useMediaQuery('(max-width: 1500px)');
  // mini 클래스 명 선정
  const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';
  // 블렌디드 수업 공개대상 지정 탭 노출 여부
  const [classBlendedToggle, setClassBlendedToggle] = useRecoilState(classBlendedToggleStore);
  // 블렌디드 수업 공개대상 지정 탭 노출 초기화
  const resetClassBlendedToggle = useResetRecoilState(classBlendedToggleStore);
  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);

  // 블렌디드 수업 공개대상 ON/OFF
  const [isVisible, setIsVisible] = useState(false);
  // 알림 대상 학급 리스트 open 여부
  const [isOpen, setIsOpen] = useState(false);
  // 알림 대상 학급 리스트 선택
  const [selectedItem, setSelectedItem] = useState({ releaseId: '', releaseName: '' });
  // 알림 셀렉트박스 타입
  const [releaseTypeList, setReleaseTypeList] = useState<{ releaseId: string, releaseName: string }[]>([]);
  // 알림 대상 학급 리스트 Ref
  const dropdownRef = useRef<HTMLDivElement>(null);
  const handleDropdownToggle = () => setIsOpen(!isOpen);
  const [inputs, setInputs] = useState<{ class_title: string }>({ class_title: '' });
  // 공개 대상 학급 리스트 (recoil, 저장용)
  const [classTarget, setClassTarget] = useRecoilState(classTargetStore);
  const resetClassTarget = useResetRecoilState(classTargetStore);
  // 공개 대상 (클라이언트)
  const [releaseTarget, setReleaseTarget] = useState<{ id?: number, classId: string, className: string }[]>([]);
  const setForceLogout = useSetRecoilState(forceLogoutStore);
  // 기존 저장된 대상 리스트 (추후 비교를 위해 저장)
  const [originalTarget, setOriginalTarget] = useState<{ id: number, classId: string, className: string }[]>([]);



  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);


  /* 최초 접근 시*/
  useEffect(() => {
    // 공개대상 리스트
    setReleaseTypeList([
      { releaseId: 'all', releaseName: 'Public' },
      { releaseId: 'part', releaseName: 'Partial' },
      { releaseId: 'none', releaseName: 'Private' }
    ]);

    // 외부 클릭 시 드롭다운 닫기
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };


  }, []);


  useEffect(() => {


    if (classBlendedToggle.OPEN) {

      // 영상 정보 상세 조회
      getDetailVideoInfo();
      // 공개 대상 한 교실 초기화
      resetClassTarget();
      // 강의 제목 설정
      setInputs({ class_title: classBlendedToggle.DATA.title });
      // 공개대상 설정
      if (classBlendedToggle.DATA.disclosureStatus === 'ALL') {
        setSelectedItem({ releaseId: 'all', releaseName: 'Public' });
      } else if (classBlendedToggle.DATA.disclosureStatus === 'CLASS') {
        setSelectedItem({ releaseId: 'part', releaseName: 'Partial' });
        // Save partially disclosed data, need to save in setClassTarget
      } else if (classBlendedToggle.DATA.disclosureStatus === 'NON') {
        setSelectedItem({ releaseId: 'none', releaseName: 'Private' });
      }


      setTimeout(() => {
        setIsVisible(true);
      }, 100);
    }
  }, [classBlendedToggle.OPEN]);



  /* 팝업 변화에 따른 선생님알림 삭제 */
  useEffect(() => {
    if (popup.RESERVE === 'decisionCancelRelease') {
      navClose();
    }
  }, [popup.RESERVE]);


  /* classTarget이 변할때마다 AlarmTarget 동기화 */
  useEffect(() => {
    // classTarget의 className에 학년 추가
    const filteredClassTarget = classTarget.map((item) => {
      const classInfo = sessionBaseInfo?.mappingClass?.find(u => u.classId === item.classId);
      // classInfo?.department가 '일반'일 경우 과목명을 제외한 학년과 반만 표시
      const filteredClassName = (classInfo?.department === 'General' || classInfo?.department === '-')
        ? `${classInfo?.grade} - ${classInfo?.className} class`
        : `${classInfo?.grade} - ${classInfo?.department} department ${classInfo?.className} class`;

      return { ...(item.id && { id: item.id }), classId: item.classId, className: filteredClassName };
    });
    setReleaseTarget(filteredClassTarget);
  }, [classTarget]);


  // 알림톡 OFF
  const navClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      resetClassBlendedToggle();
      setIsOpen(false);
    }, 310);
  };


  /* 선생님알림 종류 선택 시 */
  const handleItemSelect = (value: string, label: string) => {
    setSelectedItem({ releaseId: value, releaseName: label });
    setIsOpen(false);
  };



  const selectReleaseTarget = () => {
    setPopup({ TYPE: 'classTarget', TITLE: 'Release Target', CONTENT: '', BUTTON: 'Apply', RESERVE: '' });
  };

  /* 영상 정보 수정 하기*/
  const saveReleaseTarget = async () => {

    // /*
    // * 1. 전체공개, 일부공개, 비공개 확인
    // * 2. 전체공개일 경우
    // * 3. 일부공개일 경우 - 추가된 데이터와 삭제된 데이터 각각 분기 후, API 요청 (비교는 기존 classBlendedToggle의 데이터 내, targetClass와의 데이터로)
    // * 4. 비공개일 경우
    // *
    // */

    // 내문서함 공개 Status
    let disclosureStatus: 'ALL' | 'NON' | 'CLASS';
    if (selectedItem.releaseId === 'all') {
      disclosureStatus = 'ALL';
    } else if (selectedItem.releaseId === 'part') {
      disclosureStatus = 'CLASS';
    } else {
      disclosureStatus = 'NON';
    }

    // 내문서함 영상 제목
    let title = inputs.class_title;


    // console.log('releaseTarget - 공개할 대상');
    // console.log(releaseTarget);


    // 추가할 공개 대상
    let targetDtoList: mraFunctionReq['targetDtoList'];
    // 삭제할 공개 대상
    let deleteTargetIds: mraFunctionReq['deleteTargetIds'] = [];

    if (originalTarget.length === 0) {  // 기존 데이터가 없는 경우 -> 추가한 데이터 전체 저장, 삭제할 데이터는 없음
      targetDtoList = releaseTarget.map((item) => ({ classroomId: item.classId, name: item.className }));
    } else {  // 기존 데이터가 있는 경우 -> 새롭게 추가된 데이터만 저장, 삭제할 데이터 저장
      // changeData에는 있고 originalData에는 없는 데이터 (추가된 데이터)
      const addedList = releaseTarget.filter((changed) => !originalTarget.some((original) => original.classId === changed.classId));
      targetDtoList = addedList.map((item) => ({ classroomId: item.classId, name: item.className }));
      // originalData에는 있고 changeData에는 없는 데이터 (삭제된 데이터)
      const deletedList = originalTarget.filter((original) => !releaseTarget.some((changed) => changed.classId === original.classId));
      deleteTargetIds = deletedList.map((item) => item.id);
    }


    // console.log('추가할 데이터');
    // console.log(targetDtoList);
    // console.log('삭제할 데이터');
    // console.log(deleteTargetIds);


    const apiReq: mraFunctionReq = {
      token: sessionTokenInfo.coreAccessToken!,
      id: classBlendedToggle.DATA.id,
      title,
      disclosureStatus,
      deleteTargetIds,
      targetDtoList
    };



    const apiRes = await modifyRecord(apiReq);

    if (apiRes.code === 'OK') {
      setClassBlendedToggle((prevData) => ({ ...prevData, IS_CHANGE: true }));
      setToast({ TYPE: 'alert', CONTENT: 'Information has been updated.', TIME: 3 });
      navClose();
    } else if (apiRes.code === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
    } else if (apiRes.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      /* @@@@@@@@@@@@ Planning Definition Needed @@@@@@@@@@@@ */
      setToast({ TYPE: 'alert', CONTENT: 'Failed to update information.', TIME: 3 });
      navClose();
    }
  };

  const cancelReleaseTarget = () => {
    setPopup({ TYPE: 'decisionCancelRelease', TITLE: 'Notification', CONTENT: 'The modified information will not be saved. \n Do you want to close the popup?', BUTTON: 'Close', RESERVE: '' });
  };




  /* 입력값 설정 */
  const handleInputs = (event: ChangeEvent<HTMLInputElement>) => {
    let { name, value } = event.target;

    // 영문, 한글, 숫자, 특수문자 입력 가능
    const isValidTitle = /^[a-zA-Z0-9ㄱ-ㅎㅏ-ㅣ가-힣!@#$%^&*()_+\-={}';:"\\|,.<>/?\s]*$/.test(value);

    if (value.length <= 30 && isValidTitle) { // 입력값이 30자 이하일 경우에만 상태 업데이트
      setInputs((prevData: any) => ({ ...prevData, [name]: value }));
    }
  };


  /* 외부 클릭 시 드롭다운 닫기 */
  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as Node;
    if (dropdownRef.current && !dropdownRef.current.contains(target)) {
      setIsOpen(false);
    }
  };

  /* 영상 정보 상세 */
  const getDetailVideoInfo = async () => {

    let apiReq: gdFunctionReq;

    apiReq = {
      token: sessionTokenInfo.coreAccessToken!,
      id: classBlendedToggle.DATA.id
    };


    // 내문서함 영상 데이터 변경
    const apiRes = await getDetail(apiReq);


    if (apiRes.code === 'OK') {
      console.log('영상 정보 조회 성공');

      // 공개 대상이 이미 등록된 경우, 일부공개인 경우
      if (apiRes.data.targetList && apiRes.data.disclosureStatus === 'CLASS') {
        console.log('이미 등록된 공개대상 존재');
        const getTargetList = apiRes.data.targetList.map((item) => ({ id: item.id, classId: item.classroomId, className: item.name }));
        // 추후 비교를 위해 저장
        setOriginalTarget(getTargetList);
        // 공개 대상 recoil에 저장 (등록된 교실이 있을 경우 표기용)
        setClassTarget(getTargetList);
      } else {
        // originalData 초기화
        setOriginalTarget([]);
        // 공개 대상 recoil값 초기화
        resetClassTarget();
      }




    } else if (apiRes.code === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
    } else if (apiRes.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      console.log('영상 정보 조회 실패');
    }

  };



  return (
    <>
      {
        classBlendedToggle.OPEN &&
        <>
          <div className={`layout-class-blended ${isVisible && 'visible'}`}>
          </div>
          <section className={`class-blended-section ${miniClass} ${isVisible && 'open'}`}>

            <div>
              <div className='class-blended-close'>
                <figure className='class-blended-img' style={{ cursor: 'pointer' }} onClick={cancelReleaseTarget}>
                  <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} />
                </figure>
              </div>

              <div className='class-blended-title'>
                Edit Video Information
              </div>

              <div className='class-blended-info'>
                <span>Lecture Title</span>
                <div className='class-blended-input'>
                  <input name='class_title' type='text' placeholder='Please enter the lecture title.' value={inputs.class_title || ''} autoComplete={'off'} onChange={handleInputs} />
                </div>
              </div>

              <div className='class-blended-info'>
                <span>Visibility</span>

                <div className='class-blended-dropdown' ref={dropdownRef}>
                  <div className={`class-blended-dropdown-header ${isOpen ? 'active' : ''}`} onClick={handleDropdownToggle}>
                    {selectedItem.releaseName || 'Select Notification Type'}
                    <img src={publicMethod.svgClear(ArrowFillDown)} alt='ArrowFillDown' />
                  </div>
                  {isOpen && (
                    <div className='class-blended-dropdown-list'>
                      <Scrollbar scrollbarHeight='208px'>
                        {releaseTypeList.map((item, index) => (
                          <div key={index} className={`class-blended-dropdown-item ${selectedItem.releaseId === item.releaseId ? 'active' : ''}`} onClick={() => handleItemSelect(item.releaseId, item.releaseName)}>
                            {item.releaseName}
                          </div>
                        ))}
                      </Scrollbar>
                    </div>
                  )}
                </div>

              </div>

              {selectedItem.releaseId === 'part' &&
                <div className='class-blended-info'>
                  <span>Release Target</span>
                  <div className='class-blended-part-purple' onClick={selectReleaseTarget}>
                    Select / Edit Release Target
                  </div>
                </div>
              }

              <div className='class-blended-info target'>
                <span>Release Target</span>
              </div>

              <div className='class-blended-target'>
                {selectedItem.releaseId === 'all' && <> All Assigned Classrooms</>}
                {selectedItem.releaseId === 'part' &&
                  <>
                    {
                      releaseTarget.length === 0
                        ?
                        <>
                          Please select a release target.
                        </>
                        :
                        <>
                          {releaseTarget.map((item, index) => (
                            <div key={index} className='class-blended-target-item'>
                              <span>{item.className}</span>
                            </div>
                          ))}
                        </>
                    }
                  </>
                }
                {selectedItem.releaseId === 'none' && <> No Release Target</>}
              </div>
            </div>

            <div className='class-blended-button-box'>
              <div className='class-blended-button cancel' onClick={cancelReleaseTarget}>
                Cancel
              </div>
              <div className={`class-blended-button save ${(inputs.class_title === '' || (selectedItem.releaseId === 'part' && releaseTarget.length === 0)) && 'disable'}`} onClick={(inputs.class_title === '' || (selectedItem.releaseId === 'part' && releaseTarget.length === 0)) ? undefined : saveReleaseTarget}>
                Save
              </div>
            </div>

          </section>
        </>
      }
    </>
  );
};

export default ClassBlendedBar;

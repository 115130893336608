import { chatApi } from '@/services/network';
import { ChatMappingCode, ChatResponseCode } from '@/utils/code-enums';

/* ###########################################
 * ############# 모니터 이미지 업로드 #############
 * ########################################### */

/* function interface */
export interface FunctionReq {
    token: string
    roomId: string
    myId: string
    file: File
}
export interface FunctionRes {
    message: string
    fileRealName?: string
    fileType?: string
    fileUrl?: string
    fileSize?: number
}




/* Api Interface */

interface ApiRes {
    status: number
    code: string
    message: string
    file_name?: string
    file_type?: string
    file_download_url?: string
    file_original_name?: string
    file_size?: number
}




export const uploadMonitor = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/chat/monitor`;
    const method = 'POST';

    const formData = new FormData();
    formData.append('file', request.file);
    formData.append('room_id', request.roomId);
    formData.append('user_id', request.myId);
    formData.set('Authorization', `Bearer ${request.token}`);

    const res:ApiRes = await chatApi<any, ApiRes>(endPoint, formData, method, request.token, true);

    const message = ChatMappingCode[res.code as ChatResponseCode] || 'FAIL';

    if (res.status === 200) {
        if (res.code === ChatResponseCode.SUCCESS) {
            return {
                message,
                fileRealName: res.file_original_name,
                fileType: res.file_type,
                fileUrl: res.file_download_url,
                fileSize: res.file_size
            };
        } else if (res.code === ChatResponseCode.CODE_ERROR_COPY_FILE_TO_STORAGE) {
            return {
                message
            };
        }
    } else if (res.status === 401){
        return {
            message: 'REISSUE'
        };
    }

    return { message };

};

import { downloadApi } from '@/services/network';

/* #####################################
 * ############# 파일다운로드 #############
 * ##################################### */

/* function interface */
export interface FunctionReq {
  token: string
  fileUrl: string
}



export const chatServerFileDownloadS3 = async (request: FunctionReq): Promise<Blob | any> => {

  // const parts = request.fileUrl.split('/');
  // const fileNameWithExtension = parts[parts.length - 1];

  // const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/chat/download/${fileNameWithExtension}?${Date.now()}`;
  // const element = document.createElement('a');
  // element.setAttribute('href', endPoint);
  // element.setAttribute('download', fileNameWithExtension);
  // element.click();
  // document.body.removeChild(element);

  const parts = request.fileUrl.split('/');
  const fileNameWithExtension = parts[parts.length - 1];

  const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/chat/download_s3/${fileNameWithExtension}?${Date.now()}`;
  console.log('download endPoint', endPoint);
  const method = 'GET';

  const res = await downloadApi<null, Blob | any>(endPoint, null, method, request.token);
  console.log(res);
  if (typeof res === 'object' && !res.size) {
    return { code: 'TIMEOUT' };
  } else {
    return res;
  }
};



export const chatServerFileDownload = async (request: FunctionReq): Promise<Blob | any> => {

  const parts = request.fileUrl.split('/');
  const fileNameWithExtension = parts[parts.length - 1];

  const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/chat/download/${fileNameWithExtension}?${Date.now()}`;
  // const method = 'GET';
  // const response = await fetch(endPoint);
  // const blob = await response.blob();
  // const url = window.URL.createObjectURL(blob);

  console.log('download endPoint', endPoint);

  const element = document.createElement('a');
  element.setAttribute('href', endPoint);
  element.setAttribute('download', fileNameWithExtension);
  document.body.appendChild(element); // DOM에 추가
  element.click();
  document.body.removeChild(element); // DOM에서 제거
  window.URL.revokeObjectURL(endPoint); // 메모리 해제
  return { code: '' };

  // document.removeChild(element);

  // const parts = request.fileUrl.split('/');
  // const fileNameWithExtension = parts[parts.length - 1];

  // const endPoint = `${process.env.REACT_APP_CHAT_SERVER}/chat/download_s3/${fileNameWithExtension}?${Date.now()}`;
  // console.log('download endPoint', endPoint);
  // const method = 'GET';

  // const res = await downloadApi<null, Blob | any>(endPoint, null, method, request.token);
  // console.log(res);
  // if (typeof res === 'object' && !res.size) {
  //   return { code: 'TIMEOUT' };
  // } else {
  //   return res;
  // }
};

import { contentApi } from '@/services/network';
import { GroupListManagementInterface } from '@/recoil/store/teacherManagement';

/* #######################################
 * ############# 모둠 최초 저장 #############
 * ####################################### */


/* function interface */
export interface functionReq {
    token: string
    groupData: GroupListManagementInterface[]
}

export interface functionRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}




/* Api Interface */
interface apiRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}


export const saveGroups = async(request: functionReq): Promise<functionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/setting/groups`;
    const method = 'POST';

    const res = await contentApi<GroupListManagementInterface[], apiRes>(endPoint, request.groupData, method, request.token, null);

    if (res.code === 'OK') {
        return {
            code: 'OK',
            status: res.status,
            message: '그룹 업데이트 성공',
            data: res.data
        };
    }
    else {
        return {
            code: 'FAIL',
            status: res.status,
            message: '그룹 업데이트 실패'
        };
    }

};

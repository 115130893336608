import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* #####################################################
 * ############# 녹화정보 상세(내문서함 영상 상세) #############
 * ##################################################### */


/* function interface */
export interface FunctionReq {
    token: string;
    id: number;
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: VideoDetailInterface
}




/* Api Interface */
interface ApiReq {
    id: number;
}

export interface ApiRes {
    code: string;
    status: number;
    message: string;
    data: VideoDetailInterface;
}

export interface VideoDetailInterface {
    id: number; // 영상 ID
    title: string; // 영상제목
    note?: string;  // Description
    subjectName: string;    // 수업 이름
    schoolId: string;   // 학교 ID
    schoolName: string; // 학교 이름
    teacherId: string;  // 선생님 ID
    teacherName: string;    // 선생님명
    grade: string;  // 학년
    disclosureStatus: string;   // 공개 상태 enum(ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실))
    recordStatus: string;   // 레코딩 상태 enum(STARTED, COMPLETE, SAVE_FAILED, DELETED)
    downloadStatus: string; // 다운로드 여부 enum(ENABLED - 가능, DISABLED - 불가능)
    fileOriginName: string; // 파일 실제 이름
    thumbnailPath?: string;  // 썸네일 CDN Path
    filePath: string;   // CDN Path
    fileSize: number;   // 파일크기
    contentType: string;
    duration: number;   // 영상시간
    expirationDate: string; // YYYY-MM-DD
    recordDate: string;    // YYYY-MM-DD
    targetList?: {            // 공개할 교실 아이디 Object 목록
        id: number;              // 교실 아이디
        classroomId: string;     // 교실 아이디
        name: string;            // 교실 이름
    }[]
}



export const getDetail = async(request: FunctionReq): Promise<FunctionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/document/video/${request.id}`;
    const method = 'GET';

    const req: ApiReq = {
        id: request.id
    };

    const res = await contentApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

    const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

    return {
        code,
        status: res.status,
        message: res.message,
        data: res.data
    };

};

import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* #############################################
 * ############# 회원정보(채팅서버 관련) #############
 * ############################################# */

/* function interface */
export interface FunctionReq {
  accessToken: string
  id: string
}

export interface FunctionRes {
  message: string
  errorCode: string;
  errorMessage: string;
  data?: {
    grantType: string
    accessToken: string
    refreshToken: string
    refreshTokenExpirationTime: number
    userId: string
    userNickname: string
    userGroup: string
    userRole: string
  }
}



/* Api Interface */
interface ApiRes {
  status: number,
  data?: {
    grantType: string
    accessToken: string
    refreshToken: string
    refreshTokenExpirationTime: number
    userId: string
    userNickname: string
    userGroup: string
    userRole: string
  }
  code?: string
  message: string
}




export const getChatToken = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/users/me/chatroom/`;
  const method = 'POST';

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint + request.id, {}, method, request.accessToken, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Entry failed.';

  if (res.code === CoreResponseCode.CODE_SUCCESS && res.data) {
    return {
      message,
      errorCode,
      errorMessage,
      data: res.data
    };
  }

  return {
    message,
    errorCode,
    errorMessage
  };
};

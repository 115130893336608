// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import Loadable from '@/components/Loadable';
import StudentLayout from '@/layout/service/studentLayout';

/* 학생 버전 */

const StudentFloating = Loadable(lazy(() => import('@/pages/service/common/floating/teacherMenu')));

const StudentHome = Loadable(lazy(() => import('@/pages/service/student/home')));
const StudentLearning= Loadable(lazy(() => import('@/pages/service/student/learning')));
const StudentMyBox = Loadable(lazy(() => import('@/pages/service/student/myBox')));

const NoticeManage = Loadable(lazy(() => import('@/pages/service/common/notice/noticeList')));
const NoticeDetail = Loadable(lazy(() => import('@/pages/service/common/notice/noticeDetail')));
const NoticePost = Loadable(lazy(() => import('@/pages/service/common/notice/noticePost')));

const StudentMyPage = Loadable(lazy(() => import('@/pages/service/common/mypage/mypageMain')));
const StudentPasswordCheck = Loadable(lazy(() => import('@/pages/service/common/mypage/passwordCheck')));
const StudentEmailCert = Loadable(lazy(() => import('@/pages/service/common/mypage/emailCert')));

const StudentBasicInfo = Loadable(lazy(() => import('@/pages/service/common/mypage/basicInfoChange')));
const StudentEmploymentChange = Loadable(lazy(() => import('@/pages/service/common/mypage/employmentChange')));
const StudentFaq = Loadable(lazy(() => import('@/pages/service/common/mypage/faq')));
const StudentInquiry = Loadable(lazy(() => import('@/pages/service/common/mypage/inquiry')));
const StudentInquiryPost = Loadable(lazy(() => import('@/pages/service/common/mypage/inquiryPost')));
const StudentSetting = Loadable(lazy(() => import('@/pages/service/common/setting/settingMain')));
const StudentTutorial = Loadable(lazy(() => import('@/pages/service/common/setting/tutorial')));
const StudentAlert = Loadable(lazy(() => import('@/pages/service/common/alert/alert')));
const StudentWithdrawal = Loadable(lazy(() => import('@/pages/service/common/mypage/withdrawal')));
const StudentWithdrawalCompleted = Loadable(lazy(() => import('@/pages/service/common/mypage/withdrawalCompleted')));

const StudentError = Loadable(lazy(() => import('@/pages/service/common/error')));


const StudentRoutes = {
    path: '/service/student',
    element: <StudentLayout />,
    children: [
        /* url 바로 접근시 -홈으로 이동 */
        { path: '', element: <StudentHome/> },
        /* 학생 플로팅 */
        { path: 'floating', element: <StudentFloating/> },

        /* 학생 버전 */
        { path: 'home', element: <StudentHome/> },
        { path: 'learning', element: <StudentLearning/> },
        { path: 'myBox', element: <StudentMyBox/> },
        { path: 'noticeManage', element: <NoticeManage/> },
        { path: 'noticeDetail/:id', element: <NoticeDetail/> },
        { path: 'noticePost', element: <NoticePost/> },
        /* 학생 버전 - 마이페이지 */
        { path: 'mypage', element: <StudentMyPage/> },
        { path: 'mypage/passwordCheck', element: <StudentPasswordCheck/> },
        { path: 'mypage/emailCert', element: <StudentEmailCert/> },
        { path: 'mypage/basicInfoChange', element: <StudentBasicInfo/> }, // 기본정보 변경
        { path: 'mypage/employmentChange', element: <StudentEmploymentChange/> }, // 재직정보 변경
        { path: 'mypage/withdrawal', element: <StudentWithdrawal/> }, // 탈퇴
        { path: 'mypage/withdrawal/complete', element: <StudentWithdrawalCompleted/> }, // 탈퇴
        { path: 'mypage/faq', element: <StudentFaq/> }, // 자주하는 질문
        { path: 'mypage/inquiry', element: <StudentInquiry/> }, // 1:1 문의
        { path: 'mypage/inquiry/post', element: <StudentInquiryPost/> }, // 1:1 문의 작성
        { path: 'setting', element: <StudentSetting/> }, // 설정
        { path: 'setting/tutorial', element: <StudentTutorial/> }, // 튜토리얼
        { path: 'alert', element: <StudentAlert/> }, // 알림
        /* 500 에러*/
        { path: 'error', element: <StudentError/> }, // 알림
        /* 404 페이지나 페이지가 없는 경우 - 홈으로 리다이렉트 */
        { path: '*', element: <StudentHome /> }
    ]
};

export default StudentRoutes;

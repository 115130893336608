import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  checkInTimeInfoStore,
  loginSessionCountStore,
  thisSchedulerStore,
  timeTableStore
} from '@/recoil/store/schedule';
import { forceLogoutStore } from '@/recoil/store/user';
// eslint-disable-next-line import/no-webpack-loader-syntax
import SchedulerWorker from 'worker-loader!../../timerWorker';
import publicMethod from '@/utils/publicMethod';

/* 시간표 및 스케줄 관리 */
export default () => {

  const timeTable = useRecoilValue(timeTableStore);       // 당일 시간표
  const [checkInTimeInfo, setCheckInTimeInfo] = useRecoilState(checkInTimeInfoStore);   // 체크인 시간정보
  const setThisScheduler = useSetRecoilState(thisSchedulerStore);
  const setForceLogout = useSetRecoilState(forceLogoutStore);                                         // 강제로그아웃
  const [loginSessionCount, setLoginSessionCount] = useRecoilState(loginSessionCountStore);  // 로그인 세션 카운트

  // 최대 재시도 횟수와 초기 지연 시간 설정

  const startWorker = (
    command: string,
    key: string,
    time: number,
    onMessage: (e: MessageEvent) => void
  ): Worker => {

    const worker = new SchedulerWorker();

    const setupWorker = () => {
      worker.postMessage(JSON.stringify({ command, key, time }));
      worker.onmessage = onMessage;
    };

    setupWorker();

    return worker;
  };


  /*
   * 로그인 세션 유지곤리
   * 1시간 유지 : 로그인 될 때 count : 3600
   * 팝업 오픈 : count : 300
   * 자동 로그아웃 : count : 0
   * */
  useEffect(() => {
    let worker: Worker | null = null;
    let isErrorHandled = false; // 오류 처리 여부를 추적

    if (loginSessionCount && loginSessionCount.sec > 0) {
      if (loginSessionCount.sec < 1) {
        setForceLogout({ force: true, reason: 'session' });
      } else {
        worker = startWorker(
          'startInterval',
          'LogInSession',
          1000,
          () => {
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
              // console.log(`LINK SCHOOL LOGIN SESSION SCHEDULER: ${publicMethod.getTimeDifferenceInSeconds(loginSessionCount.sessionOutTime)}`);
            }
            if (loginSessionCount.sec === 1) {
              setForceLogout({ force: true, reason: 'session' });
            } else {
              setLoginSessionCount({
                ...loginSessionCount,
                sec: publicMethod.getTimeDifferenceInSeconds(loginSessionCount.sessionOutTime)
              });
            }
          }
        );
        worker.onerror = (err: ErrorEvent) => {
          if (!isErrorHandled) {
            isErrorHandled = true;
            if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
              // console.log(`LINK SCHOOL LOGIN SESSION SCHEDULER: ${publicMethod.getTimeDifferenceInSeconds(loginSessionCount.sessionOutTime)}`);
            }
            if (loginSessionCount.sec === 1) {
              setForceLogout({ force: true, reason: 'session' });
            } else {
              setLoginSessionCount({
                ...loginSessionCount,
                sec: publicMethod.getTimeDifferenceInSeconds(loginSessionCount.sessionOutTime)
              });
            }
          }
        };
      }
    } else if (loginSessionCount?.sec || !loginSessionCount) {
      setForceLogout({ force: true, reason: 'session' });
    }

    return () => {
      if (worker) {
        worker.postMessage(JSON.stringify({ command: 'stop' }));
        worker.terminate();
      }
    };

  }, [loginSessionCount]);



  /*
   * 체크인 세션 유지관리
   * 체크인이 되어있는 경우 잔여시간을 1초씩 깍는다.
   */
  useEffect(() => {
    let worker: Worker | null = null;

    if (checkInTimeInfo) {
      worker = startWorker(
        'startInterval',
        'CheckInSession',
        1000,
        () => {
          const sec = publicMethod.getRemainingSeconds(checkInTimeInfo.checkOutTime);
          setCheckInTimeInfo({ ...checkInTimeInfo, sec });
        }
      );
      worker.onerror = (err: ErrorEvent) => {
        const sec = publicMethod.getRemainingSeconds(checkInTimeInfo.checkOutTime);
        setCheckInTimeInfo({ ...checkInTimeInfo, sec });
      };

      return () => {
        if (worker) {
          worker.postMessage(JSON.stringify({ command: 'stop' }));
          worker.terminate();
        }
      };
    }
  }, [checkInTimeInfo]);



  /* 시간표 스케줄러 */
  useEffect(() => {
    let worker: Worker | null = null;

    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('Scheduler Start', timeTable);
    }

    if (timeTable) {

      worker = startWorker(
        'startInterval',
        'TimeTable',
        1000,
        async () => {
          let timeCheckRun = false;
          if (timeTable.today !== publicMethod.getThisDay()) {
            window.location.reload();
          } else {
            timeCheckRun = true;
          }

          if (timeCheckRun) {
            const nowTime = Number(publicMethod.getThisTime());
            let checkTimeTable = false;

            for (const schedule of timeTable.timeTable) {
              const startTime = Number(schedule.startTime);
              const endTime = Number(schedule.endTime);

              if (nowTime < startTime) {
                setThisScheduler({
                  classId: schedule.classId,
                  className: schedule.className,
                  startTime: schedule.startTime,
                  endTime: schedule.endTime,
                  inOut: 'IN',
                  sec: publicMethod.getSecondsDifferenceFromNow(schedule.startTime)
                });
                checkTimeTable = true;
                break;
              } else if (nowTime >= startTime && nowTime <= endTime) {
                setThisScheduler({
                  classId: schedule.classId,
                  className: schedule.className,
                  startTime: schedule.startTime,
                  endTime: schedule.endTime,
                  inOut: 'OUT',
                  sec: publicMethod.getSecondsDifferenceFromNow(schedule.endTime)
                });
                checkTimeTable = true;
                break;
              }
            }

            if (!checkTimeTable) {
              setThisScheduler(null);
            }
          }
        }
      );
      let ErrorRun = false;
      worker.onerror = (err: ErrorEvent) => {
        if (!ErrorRun) {
          ErrorRun = true;
          let timeCheckRun = false;
          if (timeTable.today !== publicMethod.getThisDay()) {
            window.location.reload();
          } else {
            timeCheckRun = true;
          }

          if (timeCheckRun) {
            const nowTime = Number(publicMethod.getThisTime());
            let checkTimeTable = false;

            for (const schedule of timeTable.timeTable) {
              const startTime = Number(schedule.startTime);
              const endTime = Number(schedule.endTime);

              if (nowTime < startTime) {
                setThisScheduler({
                  classId: schedule.classId,
                  className: schedule.className,
                  startTime: schedule.startTime,
                  endTime: schedule.endTime,
                  inOut: 'IN',
                  sec: publicMethod.getSecondsDifferenceFromNow(schedule.startTime)
                });
                checkTimeTable = true;
                break;
              } else if (nowTime >= startTime && nowTime <= endTime) {
                setThisScheduler({
                  classId: schedule.classId,
                  className: schedule.className,
                  startTime: schedule.startTime,
                  endTime: schedule.endTime,
                  inOut: 'OUT',
                  sec: publicMethod.getSecondsDifferenceFromNow(schedule.endTime)
                });
                checkTimeTable = true;
                break;
              }
            }

            if (!checkTimeTable) {
              setThisScheduler(null);
            }
          }
        }
      };

      return () => {
        if (worker) {
          worker.postMessage(JSON.stringify({ command: 'stop' }));
          worker.terminate();
        }
      };
    }
    else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('None time table');
    }

  }, [timeTable]);

};

import { useRecoilState } from 'recoil';
import { popupSystemStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 시스템 팝업 HOOK */
export default() => {


    // 팝업 시스템 state
    const [popupSystem, setPopupSystem] = useRecoilState(popupSystemStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupSystem.TYPE.includes('system')){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popupSystem.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopupSystem((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };

    // 시스템 점검 안내 확인 클릭 시 -> 앱 종료
    const confirmSystemCheck =  () => {
        alert('앱 종료');
        popupClose();
    };

    // 업데이터 클릭 시 -> 안드로이드 (구글 플레이스토어), 윈도우 (실행파일 업데이트)
    const confirmSystemUpdate =  () => {
        // alert('시스템 업데이트 (OS 따라 분기 처리)');
        popupClose();
    };

    return { popupSystem, isVisible, popupClose, confirmSystemCheck, confirmSystemUpdate };
};

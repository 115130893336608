import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* #######################################
 * ############# 링스쿨알림 조회 #############
 * ####################################### */

/* function interface */
export interface FunctionReq {
    token: string
    page: number
    limit: number
    searchType?: string
    search?: string
    startDate?: string   // YYYY-MM-DD
    endDate?: string     // YYYY-MM-DD
    topFixed?: boolean
    popup?: boolean
}
export interface FunctionRes {
    code: string
    status: number
    message: string
    data?: {
        id: number
        title: string
        content: string
        topFixed: boolean
        popup: boolean
        viewCount: number
        createdBy: string
        createdById: string
        createdDate: string
        lastModifiedBy: string
        lastModifiedById: string
        lastModifiedDate: string
        attachmentFiles: {
            id: number
            fileName: string
            size: number
        }[]
    }[]
    pagination?: {
        page: number
        size: number
        total: number
    }
}




/* Api Interface */
interface ApiReq {
    page: number
    limit: number
    allowDate: boolean
    searchType?: string
    search?: string
    startDate?: string   // YYYY-MM-DD
    endDate?: string     // YYYY-MM-DD
    topFixed?: boolean
    popup?: boolean
}
export interface ApiRes {
    status: number
    code: string
    message: string
    data?: {
        id: number
        title: string
        content: string
        topFixed: boolean
        popup: boolean
        viewCount: number
        createdBy: string
        createdById: string
        createdDate: string
        lastModifiedBy: string
        lastModifiedById: string
        lastModifiedDate: string
        attachmentFiles: {
            id: number
            fileName: string
            size: number
        }[]
    }[]
    pagination?: {
        page: number
        size: number
        total: number
    }
}




export const getNoticeList = async(request: FunctionReq): Promise<FunctionRes> => {
    let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/notice/linkschool/find`;
    const method = 'POST';

    const req: ApiReq = {
        page: request.page,
        limit: request.limit,
        allowDate: true,
        topFixed: request.topFixed
    };

    if (request.startDate && request.endDate) {
        req.allowDate = false;
        req.startDate = request.startDate;
        req.endDate = request.endDate;
    }

    if (request.search) {
        req.searchType = 'TITLE';
    }
    if (request.search) {
        req.search = request.search;
    }

    if (request.popup) {
        req.popup = request.popup;
    }

    const res = await contentApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

    const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

    if (res.code === ContentResponseCode.OK) {
        return {
            code,
            status: res.status,
            message: '링스쿨알림 조회 성공',
            data: res.data ? res.data : [],
            pagination: res.pagination ? res.pagination : { page: 0, size: 0, total: 0 }
        };
    } else if (res.code === ContentResponseCode.UNAUTHORIZED) {
        return {
            code,
            status: res.status,
            message: '토큰 만료'
        };
    }
    return {
        code,
        status: res.status,
        message: '링스쿨알림 조회 실패'
    };

};

import { contentApi } from '@/services/network';
import { getCodeList } from '@/services/content/code/getCodeList';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* ######################################
 * ############# 영상상태 변경 #############
 * ###################################### */


/* function interface */
export interface FunctionReq {
    token: string
    id: number
    recordStatus: 'DELETED'| 'STARTED' | 'COMPLETE' | 'SAVE_FAILED'
}

export interface FunctionRes {
    code: string
    status: number
    message: string
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
}



export const changeRecordStatus = async(request: FunctionReq): Promise<FunctionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/document/video/status/${request.id}?recordStatus=${request.recordStatus}`;
    const method = 'PUT';

    const res = await contentApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

    const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

    return {
        code,
        status: res.status,
        message: res.message
    };

};

import SimpleBar from 'simplebar-react';
import './ScrollBar.css';
import React from 'react';

// 스크롤표 Props 데이터
interface ScrollbarProps {
    children: React.ReactNode;
    scrollbarHeight?: string;
    nonePadding ?: boolean;
    scrollRef?: any;
    scrollBarOn?: boolean;
    getHeight?: string;
}


/**  커스텀 스크롤바  **/
const Scrollbar = ({ children, scrollRef, scrollbarHeight, nonePadding, scrollBarOn, getHeight }: ScrollbarProps) => {
    return (
        <SimpleBar
            className={`${nonePadding && 'none-padding'} ${scrollBarOn && 'scrollBarOn'}`}
            ref={scrollRef}
            style={{ width: '100%', height: getHeight ?? 'auto', maxHeight: scrollbarHeight }}>
            {children}
        </SimpleBar>


    );
};

export default Scrollbar;

import { grmApi } from '@/services/network';
import { GooroomeeMappingCode, GooroomeeResponseCode } from '@/utils/code-enums';

/* #######################################
 * ############# 구루미 방 제거 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
    roomId: string
}
export interface FunctionRes {
    message: string        // 성공(SUCCESS)/실패(FAIL)
}


/* Api Interface */
interface ApiReq {
    roomId: string
}
interface ApiRes {
    status: number
    code: string
    message: string
}



export const deleteBlendedRoom = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_GOOROOMEE_SERVER}/openapi/v3/rooms/${request.roomId}`;
    const method = 'DELETE';

    const requestBody: ApiReq = {
        roomId: request.roomId
    };

    const res: ApiRes = await grmApi<ApiReq, ApiRes>(endPoint, requestBody, method);

    const message = GooroomeeMappingCode[res.code as GooroomeeResponseCode] || 'FAIL';

    return { message };

};

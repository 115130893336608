import { coreApi } from '@/services/network';
import { FrontSessionBaseInfo } from '@/services/core/me/getProfile';
import { getDeviceProfile } from '@/services/core/me/getDeviceProfile';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##################################################################
 * ############# device id 를 통한 model, accessToken 발급 #############
 * ################################################################## */





/* function interface */
export interface FunctionReq {
  deviceId: string
  categoryId: 'WinPC' | 'OPS' | 'Onequick' | 'Signage'
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  accessToken?: string
  profile?: FrontSessionBaseInfo
}


/* Api Interface */
interface ApiReq {
  deviceId: string
  categoryId: string
}
interface ApiRes {
  status: number          // status
  data?: {
    id: string
    accessToken: string
    status: string
  }
  code: string
  message: string
}



export const deviceLogin = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/device/login`;
  const method = 'POST';

  const requestBody: ApiReq = { deviceId: request.deviceId, categoryId: request.categoryId };

  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, requestBody, method, null, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  let errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  let errorMessage = 'An error occurred while issuing the token.';

  if (res.code === CoreResponseCode.DEVICE_QUERY_SUCCESS && res.data) {

    const profile = await getDeviceProfile({ accessToken: res.data.accessToken, categoryId: request.categoryId });

    if (profile.message === 'SUCCESS' && profile.data) {
      return {
        message: 'SUCCESS',
        errorCode,
        errorMessage,
        accessToken: res.data.accessToken,
        profile: profile.data
      };
    } else {
      errorCode = profile.errorCode;
      errorMessage = profile.errorMessage;
    }
  }
  return {
    message,
    errorCode,
    errorMessage
  };
};

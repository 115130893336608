import React from 'react';
import '@/components/popup/popup.css';
import popupAlertSignageHook from '@/hooks/components/popup/signage/popupAlertSignage';




/* 사이니지 알림 팝업 */
const PopupAlertSignage = () => {

    const { popup, popupClose, isVisible } = popupAlertSignageHook();

    return (

        <>
            {popup.TYPE.includes('alertSignage') &&
                <div className={`layout-popup signage ${isVisible && 'visible'}`}>
                    <div className={'layout-popup-box signage alert'}>
                        <article className={'layout-popup-content'}>
                            <span style={{ fontSize: '32px', marginBottom: '10px' }}>{popup.CONTENT}</span>
                        </article>

                        <button className={'layout-popup-button signage'} onClick={() => popupClose()}>
                            {popup.BUTTON}
                        </button>
                    </div>
                </div>
            }
        </>
        



    );
};

export default PopupAlertSignage;

import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* #######################################
 * ############# 요청메세지 반환 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
  token: string;
  mirrorId: string;
  permission: 'REJECTED';
  self: boolean;
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
}


/* Api Interface */
interface ApiReq {
  permission: 'REJECTED';
  self: boolean;
}

interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const returnOpenPermission = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/mirror/open/${request.mirrorId}/permission`;
  const method = 'PUT';

  const req: ApiReq = {
    permission: request.permission,
    self: request.self
  };

  const res: ApiRes = await statusApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to return.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message
  };
};

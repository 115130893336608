import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';
import { getMusicList } from '@/services/content/sound/getMusicList';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  musicCategoryInfoStore,
  musicPlayerStore, categoryMusicListStore, PlayerModuleMusic, CategoryMusicListInterface, categoryChangeLoadingStore
} from '@/recoil/store/signage';
import { download } from '@/services/content/sound/download';
import { downloadThumbnail } from '@/services/content/sound/downloadThumbnail';
import { getCategoryList } from '@/services/content/sound/getCategoryList';
import publicMethod from '@/utils/publicMethod';

/* 사이니지 음원 관련 모듈 */
export default () => {

  const { sessionBaseInfo, sessionTokenInfo } = getSession();

  const setMusicCategoryInfo = useSetRecoilState(musicCategoryInfoStore);

  const [categoryMusicList, setCategoryMusicList] = useRecoilState(categoryMusicListStore);

  const [playerState, setPlayerState] = useRecoilState(musicPlayerStore);

  const setCategoryLoading = useSetRecoilState(categoryChangeLoadingStore);

  /*
   * 현재 관리중인 Category Thumbnail Blob url list
   *  - 카테고리 변경시 기존 Blob url 전부 제거 후 신규 Blob url 추가
   */
  const [categoryUrlList, setCategoryUrlList] = useState<string[]>([]);

  /*
   * 현재 관리중인 Player Thumbnail, audio Blob url list
   *  - player 내부 music list 가 변경된 경우 기존 Blob url 전부 제거 후 신규 Blob url 추가
   */
  const [playerUrlList, setPlayerUrlList] = useState<string[]>([]);



  /*
   * 음원 개별 다운로드 처리 ( 플레이어 모듈에서 audio url 이 없다면 player 다운로딩 상태를 true 로 변경 후 Function call )
   *  - 성공시
   *      - Player music list 내의 해당 음악의 audio blob url 추가
   *      - 관리중인 Player blob url list 에 추가
   *      - Player 다운로딩 상태를 false 로 변경
   *      - true 반환
   *  - 실패시
   *     - Player 다운로딩 상태를 false 로 변경
   *     - false 반환 후 목록에서 제거?
   */
  const playerMusicDownload = async (musicIndex: number): Promise<boolean> => {
    if (playerState.musicList.length > 0) {
      const music = playerState.musicList[musicIndex];
      if (!music.audioUrl) {
        const blob = await download({ token: sessionTokenInfo.coreAccessToken!, contentInfoId: music.contentInfoId });
        const url = URL.createObjectURL(blob.data);
        setPlayerState((prev) => ({
          ...prev,
          musicList: prev.musicList.map((m, index) => {
            if (index === musicIndex) {
              return { ...m, audioUrl: url };
            }
            return m;
          }),
          musicDownloading: false
        }));
        setPlayerUrlList([...playerUrlList, url]);
        return true;
      }
    }
    setPlayerState((prev) => ({
      ...prev,
      musicDownloading: false
    }));
    return false;
  };

  /*
   * Music player module 에 현재 카테고리 음원목록으로 설정 ( 플레이어 모듈에서 player 다운로딩 상태를 true 로 변경 후 Function call )
   *  - 현재 카테고리 음원목록을 그대로 넣고, 썸네일은 반복문을 통해 바로 blob url 형태로 집어넣는다.
   *  - Player module 에서 관리중인 기존의 모든 Blob url 을 제거한다.
   *  - Player module 에서 관리중인 Blob url list 에 신규 썸네일 목록의 blob url 을 추가한다.
   *  - 위 과정이 성공이라면 true 반환
   *  - 위 과정이 실패라면 false 반환
   */
  const setPlayerMusicList = async (tabIndex: number, trackIndex: number): Promise<boolean> => {
    console.log(categoryMusicList);
    if (sessionTokenInfo.coreAccessToken && categoryMusicList.length > 0) {

      const blobUrlList: string[] = [];
      const deleteBlobUrlList: string[] = [...playerUrlList];

      const musics: PlayerModuleMusic[] = [];

      for (const music of categoryMusicList) {
        const blob = await downloadThumbnail({
          token: sessionTokenInfo.coreAccessToken,
          thumbnailId: music.thumbnailId
        });
        const url = URL.createObjectURL(blob.data);
        blobUrlList.push(url);

        musics.push({
          id: music.id,
          name: music.name,
          duration: music.duration,
          format: music.format,
          fileName: music.fileName,
          fileLength: music.fileLength,
          contentInfoId: music.contentInfoId,
          thumbnailId: music.thumbnailId,
          thumbnailUrl: url
        });
      }

      setPlayerUrlList(blobUrlList);

      setPlayerState(prevState => ({
        ...prevState,
        musicDownloading: false,
        categoryIndex: tabIndex,
        currentTrackIndex: trackIndex,
        musicList: musics,
        elapsedTime: 0,
        isPlaying: true,
        totalTime: publicMethod.convertToSeconds(musics[trackIndex].duration)
      }));


      deleteBlobUrlList.forEach((url) => {
        URL.revokeObjectURL(url);
      });

      return true;

    }

    setPlayerState((prev) => ({
      ...prev,
      musicDownloading: false,
      musicList: []
    }));

    return false;
  };

  /*
   * 특정 카테고리의 음원 목록조회 ( 카테고리 내 음원목록 조회시 Function call )
   *  - 기존 Category Thumbnail Blob url list 제거
   *  - 신규로 추가된 Category Thumbnail Blob url 추가
   *  - 현재 카테고리 음원목록에 데이터 추가
   */
  const getSchoolMusicList = async (classificationCode: string): Promise<boolean> => {
    if (sessionTokenInfo.coreAccessToken && sessionBaseInfo?.schoolInfo?.schoolId) {

      const apiRes = await getMusicList({ token: sessionTokenInfo.coreAccessToken, releaseTargetId: sessionBaseInfo.schoolInfo.schoolId, classificationCode: classificationCode });

      const blobUrlList: string[] = [];
      const deleteBlobUrlList: string[] = [...categoryUrlList];

      let musics: CategoryMusicListInterface[] = [];

      for (const music of apiRes.data) {
        const blob = await downloadThumbnail({
          token: sessionTokenInfo.coreAccessToken,
          thumbnailId: music.thumbnailId
        });
        const url = URL.createObjectURL(blob.data);
        blobUrlList.push(url);
        musics.push({
          id: music.id,
          name: music.name,
          duration: music.duration,
          format: music.format,
          fileName: music.fileName,
          fileLength: music.fileLength,
          contentInfoId: music.contentInfoId,
          thumbnailId: music.thumbnailId,
          thumbnailUrl: url
        });
      }
      setCategoryMusicList(musics);
      setCategoryUrlList(blobUrlList);

      deleteBlobUrlList.forEach((url) => {
        URL.revokeObjectURL(url);
      });
      setCategoryLoading(false);
      return true;
    }
    setCategoryLoading(false);
    return false;
  };

  /*
   * 음원 카테고리 목록 조회
   */
  const getSchoolMusicCategory = async () => {
    const apiRes = await getCategoryList({ token: sessionTokenInfo.coreAccessToken! });

    if (apiRes.code === 'OK' && apiRes.data) {
      setMusicCategoryInfo(apiRes.data);
    } else {
      alert('Failed to retrieve audio category.');
    }
  };


  /* 토큰 존재시 음원 카테고리 목록 조회 */
  useEffect(() => {
    if (sessionTokenInfo.coreAccessToken) {
      getSchoolMusicCategory();
    }
  }, [sessionBaseInfo, sessionTokenInfo]);

  return { playerMusicDownload, setPlayerMusicList, getSchoolMusicList };


};

import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##################################
 * ############# 학교검색 #############
 * ################################## */


/* function interface */
export interface FunctionReq {
  schoolName?: string
}

export interface FunctionRes {
  count: number
  errorCode: string;
  errorMessage: string;
  message: string;
  limit: number
  page: number
  data: {
    id: string
    name: string
    code: string
    schoolType: string
    provinceCode: string
    provinceName: string
    streetAddress: string
    detailAddress: string
    organizationName: string
    schoolClassification: string
    departments: {
      id: string
      name: string
    }[]
  }[]
}




/* Api Interface */
interface ApiRes {
  count: number
  limit: number
  page: number
  data: SchoolListInterface[]
}

export interface SchoolListInterface {
  id: string
  name: string
  code: string
  schoolType: string
  provinceCode: string
  provinceName: string
  streetAddress: string
  detailAddress: string
  organizationName: string
  schoolClassification: string
  departments: {
    id: string
    name: string
  }[]
}



export const internalsSchool = async (request: FunctionReq): Promise<FunctionRes> => {
  let endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/schools/register`;
  const method = 'GET';

  if (request.schoolName) endPoint += `?schoolName=${encodeURIComponent(request.schoolName)}`;

  const res = await coreApi<{}, ApiRes>(endPoint, {}, method, null, null);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve school information.';

  return {
    message,
    count: res.count,
    limit: res.limit,
    page: res.page,
    data: res.data.map((school: SchoolListInterface) => ({
      id: school.id,
      name: school.name,
      code: school.code,
      schoolType: school.schoolType,
      provinceCode: school.provinceCode,
      provinceName: school.provinceName,
      streetAddress: school.streetAddress,
      detailAddress: school.detailAddress,
      organizationName: school.organizationName,
      schoolClassification: school.schoolClassification,
      departments: school.departments
    })),
    errorCode,
    errorMessage
  };
};

import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupErrorStore, popupStore } from '@/recoil/store/popup';
import { verifyStore } from '@/recoil/store/auth';
import { ChangeEvent, useEffect, useState } from 'react';
import { requestCode } from '@/services/core/auth/requestCode';
import { FunctionRes as vcFunctionRes, verifyCode } from '@/services/core/auth/verifyCode';
import { FunctionRes as vatFunctionRes, verifyAuthToken } from '@/services/core/auth/verifyAuthToken';
import { useNavigate } from 'react-router-dom';
import { linkOpen } from '@/services/native';

/* 로그인 추가인증 팝업 HOOK */
export default() => {

    const navigate = useNavigate();
    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 입력값 설정
    const [inputs, setInputs] = useState({ cert_number: '' });
    // 인증 번호 (5분 = 300초)
    const [remainingTime, setRemainingTime] = useState(300);
    // 인증실패 시 상태값
    const [phoneCertError, setPhoneCertError] = useState(false);
    // 2차인증 사용자정보 데이터 ( 로그인과 데이터 송수신 )
    const [verifyInfo, setVerifyInfo] = useRecoilState(verifyStore);
    // 팝업 에러 state
    const setPopupError = useSetRecoilState(popupErrorStore);



    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE === 'phoneCert'){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popup.TYPE]);

    // 핸드폰 인증 팝업 접근 시 or 인증시간 초기화 시
    useEffect(() => {
        if(popup.TYPE === 'phoneCert'){
            const timer = setInterval(() => {
                setRemainingTime(prevTime => {
                    if (prevTime <= 0) {
                        clearInterval(timer);
                        return 0;
                    } else {
                        return prevTime - 1;
                    }
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [popup.TYPE, remainingTime]);

    // 인증번호 재발송
    const resendCertNumber = async () => {
        const rcApiRes = await requestCode({ type: 'PHONE', checkType: 'make', username: verifyInfo!.username });
        if (rcApiRes.message === 'SUCCESS' && rcApiRes.data) {
            setVerifyInfo({ username: verifyInfo!.username, requestId: rcApiRes.data.requestId, finish: false });
            resetPopupData();
        }
        else if (rcApiRes.message === 'TIMEOUT') {
            navigate('/auth/error');
        }
        else if (rcApiRes.message === 'TOO_MANY') {
            /* @@@@@@@@@@@@ 기획 정의 필요 @@@@@@@@@@@@ */
            // alert('요청이 너무 많음');
        }
        else {
            // 서버 에러 팝업
            setPopupError(prevData => [...prevData, { CODE: rcApiRes.errorCode, MESSAGE: rcApiRes.errorMessage }]);
        }
    };

    // 인증하기 시도
    const runCertify = async (inputs: any) => {

        const vcApiRes: vcFunctionRes = await verifyCode({ code: inputs.cert_number.toString(), requestId: verifyInfo!.requestId });

        if (vcApiRes.message === 'SUCCESS' && vcApiRes.callbackId) {

            const vcaApiRes: vatFunctionRes = await verifyAuthToken({ callbackId: vcApiRes.callbackId });

            popupClose();

            setTimeout(async () => {
                setVerifyInfo({ username: verifyInfo!.username, requestId: verifyInfo!.requestId, finish: true, apiRes: vcaApiRes });
            }, 200);

        } else if (vcApiRes.message === 'TIMEOUT') {
            navigate('/auth/error');
        } else {
            // 서버 에러 팝업
            setPopupError(prevData => [...prevData, { CODE: vcApiRes.errorCode, MESSAGE: vcApiRes.errorMessage }]);
            setPhoneCertError(true);
        }

    };

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
        setTimeout(() => {
            // setPopup((prevData) => ({ ...prevData, TITLE: '', CONTENT: '', BUTTON: '', RESERVE: '' }));
            resetPopupData();
        }, 200);

    };

    // 팝업 데이터 초기화
    const resetPopupData =  () => {
        // 시간 초기화
        setRemainingTime(300);
        // 입력값 초기화
        setInputs((prevData: any) => ({ ...prevData, cert_number: '' }));
    };

    // 입력값 설정
    const handleInputs = (event : ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;

        // 숫자만 입력 가능 (공백 입력 불가)
        const isValidNumber = /^[0-9]*$/.test(value);

        if (value.length <= 6 && isValidNumber) { // 입력값이 6자 이하일 경우에만 상태 업데이트
            setInputs((prevData: any) => ({ ...prevData, [name]: value }));
        }
    };

    /* 인증번호 입력값 삭제 */
    const numberInputDelete = () => {
        setInputs((prevData) => ({ ...prevData, cert_number: '' }));
    };

    /* 해당 주소로 이동 */
    const moveLink =  (url: string) => {
        if(url){
            linkOpen('out', url);
        }
    };

    return {
        navigate, popup, inputs, remainingTime, phoneCertError,
        setPhoneCertError, popupClose, resendCertNumber, runCertify, handleInputs, resetPopupData, isVisible, numberInputDelete, moveLink
    };
};

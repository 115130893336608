import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* ##############################################
 * ############# 수업톡 파일정보 업데이트 #############
 * ############################################## */





/* function interface */
export interface FunctionReq {
  token: string;
  talkId: string;
  attachInfoList: {
    filePath: string;
    fileName: string;
    fileType: string;
    fileSize: number;
  }[]
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
}


/* Api Interface */
interface ApiReq {
  attachInfoList: {
    filePath: string;
    fileName: string;
    fileType: string;
    fileSize: number;
  }[]
}

interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const updateTalkFileInfo = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/talk/send/${request.talkId}`;
  const method = 'PUT';

  const req: ApiReq = {
    attachInfoList: request.attachInfoList
  };

  const res: ApiRes = await statusApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to send the file.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message
  };
};

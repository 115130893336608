import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { CompatClient } from '@stomp/stompjs';
import { base64ToFile } from '@/utils/common';
import { getSession } from '@/hooks/common';
import { checkInManagementStore, classGroupModeStore, deviceListStore, myMirrorManagementStore } from '@/recoil/store/checkIn';
import { groupManagementStore, mirrorManagementStore, MirrorInfoInterface } from '@/recoil/store/teacherManagement';
import { forceLogoutStore, userTokenInfoStore } from '@/recoil/store/user';
import { checkInTimeInfoStore, loginSessionCountStore } from '@/recoil/store/schedule';
import { classModeStore, classTalkListStore, classTalkPopupStore, ClassTalkListInterface } from '@/recoil/store/class';
import { BlendedStateInterface, blendedStateStore } from '@/recoil/store/blended';
import { chatServerFileDownload, chatServerFileDownloadS3 } from '@/services/chat/chatServerFileDownload';
import { FunctionReq as umFunctionReq, FunctionRes as umFunctionRes, uploadMonitor } from '@/services/chat/uploadMonitor';
import { FunctionReq as ufFunctionReq, FunctionRes as ufFunctionRes, uploadFile } from '@/services/chat/uploadFiles';
import { FunctionReq as rsFunctionReq, FunctionRes as rsFunctionRes, reissue } from '@/services/chat/reissue';
import { FunctionReq as luFunctionReq, FunctionReqData, updateMessages } from '@/services/core/schools/updateMessages';
import { changeStream } from '@/services/blended/stream/changeStream';
import { makeBlendedRoom } from '@/services/blended/gooroomee/makeBlendedRoom';
import { makeRoomOtp } from '@/services/blended/gooroomee/makeRoomOtp';
import { publishStream } from '@/services/blended/stream/publishStream';
import {
  mqttConnect,
  mqttDisconnect,
  MqttConnectRequest,
  MqttClassMessageInterface,
  MqttBypassMessageInterface,
  MqttPrivateReceiveMessage,
  MqttPermissionRequestMessage,
  MqttPermissionResponseMessage,
  MqttMirrorManageMessage,
  MqttMirrorOpenPermissionRequestMessage,
  MqttMirrorJoinPermissionRequestMessage
} from '@/services/classMqtt';
import {
  webSocketConnect,
  webSocketDisconnect,
  MonitorMessageInterface,
  SocketConnectInterface
} from '@/services/socket';
import {
  mirrorCommand,
  linkOpen,
  MediaBoxJoinRoomRequest,
  MediaBoxRegisterRequest,
  MediaBoxRoomDeleteAll,
  MediaBoxRoomOpen,
  MediaBoxStartMonitor,
  MediaBoxStartMirror,
  MediaBoxStopMirror
} from '@/services/native';
import { unPublishStream } from '@/services/blended/stream/unPublishStream';
import { popupErrorStore, popupOtherStore } from '@/recoil/store/popup';
import { deleteBlendedRoom } from '@/services/blended/gooroomee/deleteBlendedRoom';
import { deviceInfoStore, toastClassStore, toastStore } from '@/recoil/store/common';
// eslint-disable-next-line import/no-webpack-loader-syntax
import SchedulerWorker from 'worker-loader!../../timerWorker';
import { pingServer } from '@/services/blended/stream/pingServer';
import { saveRecordInfo } from '@/services/content/document/video/saveRecordInfo';
import { changeRecordStatus } from '@/services/content/document/video/changeRecordStatus';
import { LogicDelayLog, ncpLogUpdate } from '@/services/ncloud/saveAppLog';
import publicMethod from '@/utils/publicMethod';
import { hashFilter } from '@/services/content/external/fileSafer/hashFiler';
import { ChatroomTokenInterface, lectureSubjectClassification, MonitoringInterface } from '@/services/status/checkIn';
import { checkOut as statusCheckOut } from '@/services/status/checkOut';
import { classModeUpdate } from '@/services/status/classModeUpdate';
import { checkInExtend } from '@/services/status/checkInExtend';
import { userBypassMessage } from '@/services/status/userBypassMessage';
import { findMyTalkLIst } from '@/services/status/talk/fildMyTalkList';
import { deleteMyTalk } from '@/services/status/talk/deleteMyTalk';
import { sendPermission, FunctionReq as sendPermissionFunctionReq } from '@/services/status/talk/sendPermission';
import { updateTalkFileInfo } from '@/services/status/talk/updateTalkFileInfo';
import { deleteErrorTalk } from '@/services/status/talk/deleteErrorTalk';
import { returnPermission } from '@/services/status/talk/returnPermission';
import { userMirrorUpdate } from '@/services/status/userMirrorUpdate';
import { returnOpenPermission } from '@/services/status/mirror/returnOpenPermission';
import { returnJoinPermission } from '@/services/status/mirror/returnJoinPermission';
import { sendOpenPermission, FunctionReq as sendOpenPermissionReq } from '@/services/status/mirror/sendOpenPermission';
import { getLectureState } from '@/services/status/getLectureState';
import { findLectureMirrorList } from '@/services/status/mirror/fildLectureMirrorList';
import { userLockUpdate } from '@/services/status/userLockUpdate';
import { MqttClient } from 'mqtt';


export interface MirrorOpenRequestInterface {
  permissionNeed: boolean;
  subGroupId?: number;
  groupMirror: boolean;
  pubs: {
    id: string;
    division: 'teacher' | 'student' | 'onequick' | 'board';
    name: string;
    number?: number;
  }[];
  subs: {
    id: string;
    division: 'teacher' | 'student' | 'onequick' | 'board';
    name: string;
    number?: number;
  }[];
}


export interface ClassInfoInterface {
  lectureId: string;
  classId: string;                                            // 교실 id
  chatRoomIds: string[];                                      // 공용 채팅방 id
  myRoomId: string[];                                         // 개인 채팅방 id list
  lectureSubjectClassification: lectureSubjectClassification;   // 수업 분류 코드
  subject?: string;                                           // 과목명
  subjectId?: string;                                         // 과목 id
  classSubject?: string;                                      // 교실과목명
  classSubjectId?: string;                                    // 교실과목 id
  currentTimeSlotIndex: string;                               // 현재 시간표 인덱스
  classGrade: string;
  classNumber: string;
  mediaBoxIp?: string;                                        // 미디어박스 ip
  department?: string;                                        // 학과
  checkOutTime: string;                                       // 체크아웃 시간
  checkedInAt: string;                                        // 체크인 시간
  breakTime: boolean;                                         // 쉬는시간 여부
  usersInfo: CheckInUserInterface[];                          // 해당 교실의 시간표에 해당하는 선생님/학생/전자칠판/원퀵 리스트
  monitoring: MonitoringInterface;                            // 모니터링 정보
  mirrorInfo: MirrorInfoInterface[];                          // 미러링 정보
  groupMode: boolean;                                         // 그룹모드 여부
  groupInfo?: ClassGroupInfoInterface | null;                 // 그룹 정보
  chatroomToken: ChatroomTokenInterface;                      // Utility 토큰 정보
}
export interface CheckInUserInterface {
  division: 'student' | 'teacher' | 'board' | 'onequick';
  id: string;
  name: string;
  number?: string;
  checkIn: boolean;
  roomId: string[];
  mediaBoxConnect: boolean;
  mirrorPosition: 'NON' | 'PUB' | 'SUB';
  mirrorRoomNumber: number | null;
  locked: boolean;
}
export interface ClassGroupInfoInterface {
  groupId: string;
  groupName: string;
  subGroupList: {
    subGroupId: string;
    subGroupName: string;
    subMirror: boolean;
    webRtcRoomId: number | null;
    subGroupMember: {
      id: string;
      division: string;
      name: string;
      number: string | null;
      position: 'leader' | 'member';
    }[]
  }[]
}

export interface UsersInfoInterface {
  division: 'student' | 'teacher' | 'board' | 'onequick';
  id: string;
  name: string;
  number?: string;
  checkIn: boolean;
  roomId: string[];
  mediaBoxConnect: boolean;
  mirrorPosition: 'NON' | 'PUB' | 'SUB';
  mirrorRoomNumber: number | null;
  isLocked: boolean;

  imageUrl: string;
}
export interface UsersInfoBoxInterface {
  id: string;
  name: string;
  division: 'teacher' | 'student' | 'onequick' | 'board';
  number?: string;
  isChecked: boolean;
  isCheckAble: boolean;
}


export interface ClassFileMessage {
  filePath: string;
  fileName: string;
  fileType: string;
  fileSize: number;
}

export interface MessageReceiver {
  userId: string;
  division: 'student' | 'teacher' | 'board' | 'onequick';
  number?: number;
  name: string;
}

/*
 * 내가 요청한 건 임시저장 인터페이스
 */
interface TempEventDataInterface { // 미러링인 경우에는 미사용
  talkId: string;
  lectureId: string;
  eventType: 'FILE' | 'LINK' | 'MIRROR_OPEN' | 'MIRROR_JOIN';
  linkMessage?: string;
  fileList?: File[];
  receiverList?: {
    id: string;
    division: 'student' | 'teacher' | 'board' | 'onequick';
    number?: number;
    name: string;
  }[]
  tempMirrorOpenInformation?: {   // 미러링 Open 요청건에서만 사용
    mirrorId: string;
    permissionNeed: boolean;
    subGroupId?: number;
    groupMirror: boolean;
    pubs: {
      id: string;
      division: 'teacher' | 'student' | 'onequick' | 'board';
      name: string;
      number?: number;
    }[];
    subs: {
      id: string;
      division: 'teacher' | 'student' | 'onequick' | 'board';
      name: string;
      number?: number;
    }[];
  }
}

/*
 * 미러링 Open/Join 요청이 들어온 건
 *  - 정상 작동이 되었다면 삭제
 *  - 비정상 작동이라면 해당 talkId 쪽에 거절 메세지 전송
 */
interface TempMirrorDataInterface {
  talkId?: string;                // Permission 이 필요없다면 저장하지않는다.
  mirrorId?: string;              // Mirror open 에서 사용
  mirrorJoinInformation?: {       // Mirror join 에서 사용
    mirrorId: string;
    mirrorPosition: 'PUB' | 'SUB';
    mirrorRoomNumber: number;
  }
}


interface LogFunctionInterface {
  messageType: string,
  receiverGroupId: string,
  receiverId: string,
  receiverRoomId: string,
  fileURL?: string,
  fileSize?: number,
  fileOriginalName?: string,
  fileExtension?: string,
  messageInfo?: string
}




export default () => {

  const navigate = useNavigate();

  /*
   * 내 세션정보
   */
  const { sessionTokenInfo, sessionBaseInfo, deviceInfo } = getSession();

  /*
   * 채팅서버 Client
   */
  const [webSocket, setWebSocket] = useState<CompatClient | null>(null);

  /* Mqtt Client */
  const [mqttClient, setMqttClient] = useState<MqttClient | null>(null);

  /*
   * 내 토큰정보 변경
   */
  const setUserTokenInfo = useSetRecoilState(userTokenInfoStore);

  /*
   * 체크인된 클래스 정보 관리
   */
  const [classInfo, setClassInfo] = useState<ClassInfoInterface | null>(null);

  /*
   * 체크인된 클래스 참여자 목록 관리
   */
  const [usersInfo, setUsersInfo] = useState<UsersInfoInterface[]>([]);

  /*
   * 사용자 목록 ( 체크박스 제어용 )
   */
  const [usersInfoBox, setUsersInfoBox] = useState<UsersInfoBoxInterface[]>([]);

  /*
   * 미디어박스 Callback 메시지
   */
  const [mediaBoxMessage, setMediaBoxMessage] = useState<any[]>([]);

  /*
   * 특정 학생 모니터링 상세 이미지
   */
  const [detailMonitorImage, setDetailMonitorImage] = useState<{ id: string, imageUrl: string } | null>(null);

  /*
   * 현재시간 스케줄러 셋팅 함수
   */
  const [checkInTimeInfo, setCheckInTimeInfo] = useRecoilState(checkInTimeInfoStore);

  /*
   * 강제 로그아웃 셋팅
   */
  const setForceLogout = useSetRecoilState(forceLogoutStore);

  /*
   * 교실 디바이스 목록
   */
  const setDeviceList = useSetRecoilState(deviceListStore);

  /*
   * 블랜디드관련 상태
   */
  const [blendedState, setBlendedState] = useRecoilState(blendedStateStore);

  /*
   * 블랜디드 상태관리 초기화
   */
  const resetBlendedState = useResetRecoilState(blendedStateStore);

  /*
   * 팝업관련 전역상태
   */
  const setPopupOther = useSetRecoilState(popupOtherStore);

  /*
   * 토스트관련 전역상태
   */
  const setToast = useSetRecoilState(toastStore);

  /*
   * 토스트 체크인 state
   */
  const [toastClass, setToastClass] = useRecoilState(toastClassStore);

  /*
   * 로그인 세션 관리
   */
  const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);

  /*
   * 내부 체크인 연장팝업
   */
  const [checkOutPopup, setCheckOutPopup] = useState<boolean>(false);

  /*
   * 현재 디바이스 정보
   */
  const localDeviceInfo = useRecoilValue(deviceInfoStore);

  /*
   * 팝업 에러 state
   */
  const setPopupError = useSetRecoilState(popupErrorStore);

  /*
   * 각 서버 연결정보 초기화
   */
  const checkInManagementClear = useResetRecoilState(checkInManagementStore);

  /*
   * 화면잠금 상태
   */
  const [lockState, setLockState] = useState<boolean>(false);

  /*
   * 내가 참여중인 미러링 정보
   */
  const [myMirrorManagement, setMyMirrorManagement] = useRecoilState(myMirrorManagementStore);

  /*
   * 체크인시 각 서버 연결관리 정보
   */
  const [checkInManagement, setCheckInManagement] = useRecoilState(checkInManagementStore);

  /*
   * 수업모드 초기화
   */
  const classModeClear = useResetRecoilState(classModeStore);

  /*
   * 선생님용 교실 상태관리 정보
   */
  const [mirrorManagement, setMirrorManagement] = useRecoilState(mirrorManagementStore);

  /*
   * 그룹모드 On/Off
   */
  const [groupMode, setGroupMode] = useRecoilState(classGroupModeStore);

  /*
   * 본인모둠 확인처리를 위해 개인 모둠정보 가져오기
   */
  const groupManagement = useRecoilValue(groupManagementStore);

  /*
   * 정제된 채팅 채팅 + MQTT 이벤트 목록
   */
  const setClassTalkList = useSetRecoilState(classTalkListStore);

  /*
   * 정제된 개인 채팅 + MQTT 이벤트 UI 팝업데이터 셋팅 함수
   */
  const setClassTalkPopup = useSetRecoilState(classTalkPopupStore);

  /*
   * 정제된 개인 채팅 + MQTT 이벤트 리스트 초기화
   */
  const classTalkListClear = useResetRecoilState(classTalkListStore);

  /*
   * 정제된 개인 채팅 + MQTT 이벤트 팝업데이터 초기화
   */
  const classTalkLPopupClear = useResetRecoilState(classTalkPopupStore);

  /*
   * 모니터링 메세지
   */
  const [monitorMessages, setMonitorMessages] = useState<MonitorMessageInterface[]>([]);

  /*
   * MQTT 교실 메시지
   */
  const [classMessages, setClassMessages] = useState<MqttClassMessageInterface[]>([]);

  /*
   * MQTT Bypass 메세지
   */
  const [bypassMessages, setBypassMessages] = useState<MqttBypassMessageInterface[]>([]);

  /*
   * MQTT Receive 메세지
   */
  const [receiveMessages, setReceiveMessages] = useState<MqttPrivateReceiveMessage[]>([]);

  /*
   * MQTT File/Message/Link Request 메세지 ( 허가자입장에서 승인요청 메세지가 온 경우 )
   */
  const [permissionRequestMessages, setPermissionRequestMessages] = useState<MqttPermissionRequestMessage[]>([]);

  /*
   * MQTT Response 메세지 ( 요청자 입장에서 허가자 반환 메세지가 온 경우 )
   */
  const [permissionResponseMessages, setPermissionResponseMessages] = useState<MqttPermissionResponseMessage[]>([]);

  /*
   * MQTT Mirror Open Request 메세지 ( 허가자입장에서 승인요청 메세지가 온 경우 )
   */
  const [mirrorOpenRequestMessages, setMirrorOpenRequestMessages] = useState<MqttMirrorOpenPermissionRequestMessage[]>([]);

  /*
   * MQTT Mirror Join Request 메세지
   */
  const [mirrorJoinRequestMessages, setMirrorJoinRequestMessages] = useState<MqttMirrorJoinPermissionRequestMessage[]>([]);

  /*
   * MQTT Mirror 관리 메세지
   */
  const [mirrorManageMessage, setMirrorManageMessage] = useState<MqttMirrorManageMessage[]>([]);

  /*
   * 요청메세지 임시저장 데이터
   */
  const [tempEventData, setTempEventData] = useState<TempEventDataInterface[]>([]);

  /*
   * 요청이 들어온건 임시저장
   */
  const [tempMirrorEventData, setTempMirrorEventData] = useState<TempMirrorDataInterface[]>([]);


  /* Worker */
  let pingWorker: Worker | null = null;
  let recordWorker: Worker | null = null;
  let firstCheckIn = false;






  /*
   * Blended 용 핑전송 시작/종료
   */
  useEffect(() => {
    if (blendedState.record || blendedState.stream) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PING START', new Date());
      }
      if (pingWorker) {
        pingWorker.terminate();
        pingWorker = null;
      }
      pingWorker = startWorker(
        'startInterval',
        'BlendedPing',
        30000,
        () => pingServer({ mediaBoxIp: classInfo?.mediaBoxIp! }),
        () => console.error('PING Worker error occurred.')
      );

      if (blendedState.record) {
        recordWorker = startWorker(
          'startInterval',
          'record++',
          1000,
          () => {
            setBlendedState((prev: BlendedStateInterface) => ({
              ...prev,
              recordCount: prev.recordCount + 1
            }));
          },
          () => console.error('RECORD Worker error occurred.')
        );
      } else if (recordWorker) {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('LINK SCHOOL - BLENDED RECORD ++ STOP', new Date());
        }
        recordWorker.postMessage(JSON.stringify({ command: 'stop' }));
        recordWorker.terminate();
      }
    }
    else if (pingWorker) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PING STOP', new Date());
      }
      pingWorker.postMessage(JSON.stringify({ command: 'stop' }));
      pingWorker.terminate();
      pingWorker = null;
    }
  }, [blendedState.record, blendedState.stream]);

  /*
   * 녹화 카운트, 종료시간 제어
   */
  useEffect(() => {
    if (blendedState.record && (blendedState.recordMode === 'PLAY')) {
      /*
       *  - 녹화 종료시간을 기준으로 남은 녹화 잔여시간을 계산한다.
       *  - 1초마다 반복되는 worker 실행
       */
      if (recordWorker) {
        recordWorker.terminate();
        recordWorker = null;
      }
      recordWorker = startWorker(
        'startInterval',
        'recordTimer',
        1000,
        () => {
          setBlendedState((prev: BlendedStateInterface) => ({
            ...prev,
            recordCount: prev.recordCount + 1
          }));
        },
        () => classCheckOut()
      );
    }
    return () => {
      if (recordWorker) {
        recordWorker.terminate();
        recordWorker = null;
      }
    };

  }, [blendedState.record, blendedState.recordMode]);

  /*
   * 최초 블랜디드러닝 카메라가 존재할경우 상태 초기화를 진행한다.
   */
  useEffect(() => {
    let updateBlendedState = false;
    if (blendedState.deviceId && !updateBlendedState && classInfo?.mediaBoxIp) {
      updateBlendedState = true;
      unPublishStream({ mediaBoxIp: classInfo?.mediaBoxIp! });
    }
    return () => {
      updateBlendedState = false;
    };
  }, [blendedState.deviceId]);

  /*
   * 체크인 연장팝업 처리, 체크아웃 메세지 못받을 경우 체크아웃 처리
   */
  useEffect(() => {
    if (classInfo && checkInTimeInfo) {
      // console.log(`체크인 잔여시간 ${checkInTimeInfo.sec}`);
      if (checkInTimeInfo.sec > 0 && checkInTimeInfo.sec <= 300 && !checkOutPopup && toastClass.TYPE !== 'checkOut' && classInfo.lectureSubjectClassification !== 'FREE') {
        // if (checkInTimeInfo.sec > 0 && checkInTimeInfo.sec <= 300 && !checkOutPopup && toastClass.TYPE !== 'checkOut' && classInfo.lectureSubjectClassification !== 'FREE') {

        if (sessionBaseInfo?.baseInfo.role === 'teacher') {
          setCheckOutPopup(true);
          setToastClass({ TYPE: 'checkOut', TIME: checkInTimeInfo.sec });
        }

      }
      else if (checkInTimeInfo.sec < -10) {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`LINK SCHOOL CHECKIN - FAIL REASON CHECKIN TIMEOUT : ${JSON.stringify(checkInTimeInfo)}`);
        }
        classCheckOut(true);
        if (sessionBaseInfo?.baseInfo.role === 'teacher' || sessionBaseInfo?.baseInfo.role === 'student') {

          setTimeout(() => {
            setToast({ TYPE: 'alert', CONTENT: 'Class time has ended\nand you have been automatically checked out.', TIME: 3 });
            navigate(`/service/${sessionBaseInfo.baseInfo.role}/home`, { replace: true });
          }, 500);

        }
        else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('전자칠판/원퀵 체크아웃');
          window.location.reload();
        }
      }
    }
  }, [classInfo, checkInTimeInfo, checkOutPopup]);

  /*
   * 교실 Utility 메세지 수신처리 ( 모니터링 이미지 )
   */
  useEffect(() => {
    if (classInfo) {
      if (monitorMessages.length > 0) {
        const targetMessage: MonitorMessageInterface = monitorMessages[0];
        setMonitorMessages(prevMessages => prevMessages.slice(1));
        usersInfoSetImageUrl(targetMessage.userId, targetMessage.imageUrl);
      }
    }
  }, [classInfo, monitorMessages]);

  /*
   * 체크인시 각 서버 연결관리 정보가 변경될 때 동작하는 Hook
   *  - 백엔드 체크인이 성공할경우 동작한다. ( checkInManagement.coreCheckIn === true )
   *  - checkInState 가 null 일때는 아직 각 서버에 연결을 요청한 상태이다.
   *  - checkInState 가 true 일때는 모든 서버에 연결이 성공한 상태이다.
   *  - checkInState 가 false 일때는 특정 서버에 연결이 실패한 상태이다.
   *  - mediaBox 연결은 안되어도 체크인은 성공할 수 있다.
   *  - 선생님의 경우 디바이스 목록을 조회하기 위해 classId 를 지정한다.
   */
  useEffect(() => {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log(`LINK SCHOOL SERVER CONNECTION ${JSON.stringify(checkInManagement)}`);
    }
    if (!classInfo || !checkInManagement.coreCheckIn) return;
    const { checkInState, chatConnect, mqttConnect, monitorStart, mediaBoxConnect } = checkInManagement;
    if (checkInState === null && chatConnect !== null && mqttConnect !== null && monitorStart !== null) {
      const allConnected = chatConnect && mqttConnect && monitorStart;
      setCheckInManagement(prev => ({ ...prev, checkInState: allConnected, mediaBoxConnect }));
      if (allConnected) {
        if (sessionBaseInfo?.baseInfo.role === 'teacher') {
          setDeviceList({ classId: classInfo.classId, deviceList: [] });
        }
      }
    }
  }, [classInfo, checkInManagement]);

  /*
   * 미디어박스 메시지가 온 경우 동작하는 Hook
   *  - 미디어박스 체크인 메시지가 온 경우 ( mirrorcheckin )
   *      - 성공 메시지
   *          1. 미디어박스 회원등록 요청 커맨드 전송 ( register )
   *          2. 모니터링 이미지 요청 커맨드 전송 ( monitorstart )
   *      - 실패 메시지
   *          1. 각 서버 연결정보 중 미디어박스 연결상태 false 처리
   *          2. 모니터링 이미지 요청 커맨드 전송 ( monitorstart )
   *  - 미디어박스 회원정보 등록 메시지가 온 경우 ( register )
   *      - 성공 메시지
   *          1. 각 서버 연결정보 중 미디어박스 연결상태 true 처리
   *      - 실패 메시지
   *          1. 각 서버 연결정보 중 미디어박스 연결상태 false 처리
   *  - 모니터링 이미지 요청 메시지가 온 경우 ( monitorstart )
   *      1. 네이티브쪽으로 화면스크린 이미지 base64 문자열 요청
   *      2. base64 문자열을 파일로 변환하여 S3 업로드 요청함수 실행
   *  - 미러링 방생성완료 메시지가 온 경우 ( createroom )
   *      - 성공 메시지
   *          1. 미러링 임시저장 리스트에서 방생성을 요청했으며, 방번호를 할당받지 못한 미러링 정보를 찾는다.
   *          2. 선생님 관리데이터의 미러링 목록에서 모든 방에 참여중인 사용자 중 해당 미러링 정보의 사용자가 겹치는지 확인한다. ( 같은 사용자가 두개 이상의 방에 들어가는것을 제거하기 위함 )
   *              - 겹치는 사용자가 있을 때
   *                  1. 겹치는 사용자가 있다면 임시저장된 해당 미러링 정보를 제거한다.
   *                  2. 방생성을 다시 요청한다. ( mirrorRoomOpenCommand )
   *              - 겹치는 사용자가 없을 때
   *                  1. 해당 미러링 정보에 방번호를 할당받은 정보를 반영한다.
   *                  2. 미러링 임시저장 리스트를 변경된 리스트로 갱신한다.
   *      - 실패 메시지
   *          1. 방생성을 다시 요청한다. ( mirrorRoomOpenCommand )
   *  - 미러링 참여전송 완료 메시지가 온 경우 ( joinroom )
   *      - 참여전송된 대상이 Publish, Subscribe 에 따라서 선생님 관리데이터의 미러링 목록에서 해당 방번호를 찾아서 해당 사용자를 추가한다. ( mirrorUserAdd )
   *  - 미러링 방 구성원 전달 메시지가 온 경우 ( roommember )
   *      - 전달받은 방 구성원중 Publish 목록을 내 미러링 정보의 Publish 목록으로 대체한다.
   *  - 미러링 중지 요청/완료 메시지가 온 경우 ( mirrorstop )
   *      - 내가 선생님인 경우
   *          1. 선생님 관리데이터의 미러링 목록에서 해당 방번호를 찾아서 미러링 목록에서 찾은 미러링정보를 제거한다. ( mirrorUserDel )
   *          2. 내 미러링 정보를 초기화한다.
   *      - 내가 선생님이 아닌 경우
   *          1. MQTT 메시지를 통해 미러링 중지를 선생님에게 전달한다.
   *          2. 내 미러링 정보를 초기화한다.
   */
  useEffect(() => {
    if (classInfo) {
      try {

        if (mediaBoxMessage.length > 0) {

          const targetMessage = JSON.parse(mediaBoxMessage[0]);
          setMediaBoxMessage(prevMessages => prevMessages.slice(1));

          console.log('aschool targetMessage: ' + mediaBoxMessage[0]);

          /* 미디어박스 체크인 메시지가 온 경우 */
          if (targetMessage.command === 'mirrorcheckin' && targetMessage.type === 'response') {

            try {
              if (targetMessage.error === 'connected') {

                const mediaBoxCommand1: MediaBoxRegisterRequest = {
                  commandDivision: 'REGISTER',
                  classroom: classInfo.lectureId,
                  command: 'register',
                  type: 'request',
                  sender: sessionBaseInfo?.baseInfo.userId!,
                  role: sessionBaseInfo?.baseInfo.role!
                };
                mirrorCommand(mediaBoxCommand1);

              }
              else {

                /* MQTT CheckIn Fail 전송 */
                setCheckInManagement(prev => ({ ...prev, mediaBoxConnect: false }));
                if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                  console.log('LINK SCHOOL MEDIA BOX - FAIL CHECK IN');
                }
              }

              const mediaBoxCommand2: MediaBoxStartMonitor = {
                commandDivision: 'MONITOR_START',
                classroom: classInfo.lectureId,
                command: 'monitorstart',
                type: 'request',
                data: {
                  monitorSettings: {
                    intervalSec: classInfo.monitoring.monitoringCycle,
                    maxCount: Math.floor(classInfo.monitoring.monitoringEnds * 60 / classInfo.monitoring.monitoringCycle),
                    sizePercent: classInfo.monitoring.resolution
                  }
                },
                sender: sessionBaseInfo?.baseInfo.userId!
              };
              mirrorCommand(mediaBoxCommand2);

            } catch (e: any) {
              setCheckInManagement(prev => ({ ...prev, mediaBoxConnect: false }));
              if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`LINK SCHOOL MEDIA BOX FAIL - REGISTER ${e.message}`);
              }
            }

          }

          /* 미디어박스 회원정보 등록 메시지가 온 경우 */
          else if (targetMessage.command === 'register' && targetMessage.type === 'response') {

            try {
              if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('LINK SCHOOL MEDIA BOX CONNECT SUCCESS');
              }
              setCheckInManagement(prev => ({ ...prev, mediaBoxConnect: targetMessage.error === 'success' }));
            } catch (e: any) {
              setCheckInManagement(prev => ({ ...prev, mediaBoxConnect: false }));
              if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`LINK SCHOOL MEDIA BOX FAIL - MONITOR ${e.message}`);
              }
            }

          }

          /* 모니터링 이미지 요청 메시지가 온 경우 */
          else if (targetMessage.command === 'monitorstart' && targetMessage.type === 'request') {
            const base64Str = window.MirrorGetImageBase();
            if (base64Str) {
              uploadMonitorImage(base64ToFile(base64Str, 'capture.jpeg', 'image/jpeg'));
            }
          }

          /* 미러링 방생성완료 메시지가 온 경우 */
          else if (targetMessage.command === 'createroom' && targetMessage.type === 'response') {

            if (targetMessage.error === 'success' || targetMessage.error === 'lecture_status_api_create') {

              const mirrorId = `${targetMessage.data.pubs[0]}`;
              const targetTempMirror = tempMirrorEventData.find(data => data.mirrorId === mirrorId);

              console.log('aschool mirror create targetTempMirror1: ', JSON.stringify(targetTempMirror));
              // console.log('aschool mirror create targetTempMirror2: ', JSON.stringify(targetTempMirror?.talkId));

              if (targetTempMirror) {
                setTempMirrorEventData(prev => prev.filter(data => data.talkId !== targetTempMirror.talkId));
              }
            }
            else {
              // Lower case

              // SUCCESS,
              // NO_UUIDS,
              // NO_PUBSUB_UUIDS,
              // NO_ROOMNUM,
              // NO_MONITOR_SETTINGS,
              // NO_PRIVATE_KEY,
              // LECTURE_STATUS_API_CREATE,
              // LECTURE_STATUS_API_JOIN,
              // LECTURE_STATUS_API_OUT,
              // LECTURE_STATUS_API_CLOSE


              // 미러링 방 생성이 실패했을 때
              // - 승인이 필요한건에서 내가 승인자 입장일 경우 : 로직 실패로 인한 MIRROR_OPEN 거절처리
              // - 승인이 필요하지않은 건인 경우 : 삭제처리
              const mirrorId = `${targetMessage.data.pubs[0]}`;
              selfMirrorOpenReturn({ mirrorId: mirrorId });
              setToast({ TYPE: 'alert', CONTENT: 'Failed to create screen share.', TIME: 3 });

            }
          }
          else if (targetMessage.command === 'mirrorstart' && targetMessage.type === 'response') {
            console.log('aschool mirrorstart response targetMessage', JSON.stringify(targetMessage));
            const position = targetMessage.data.pubs.indexOf(sessionBaseInfo?.baseInfo.userId) !== -1 ? 'pub' : 'sub';
            const pubInfo = usersInfo
              .filter(user => targetMessage.data.pubs.includes(user.id))
              .map(user => ({
                division: user.division,
                id: user.id,
                name: user.name,
                number: user.number
              }));
            setMyMirrorManagement({ webRtcRoomId: targetMessage.data.mirror.roomnum, position: position, pubInfo: pubInfo });
          }
          else if (targetMessage.command === 'mirrorstart' && targetMessage.type === 'request') {
            console.log('aschool mirrorstart request targetMessage', JSON.stringify(targetMessage));
            if (sessionBaseInfo?.baseInfo.role === 'board' || sessionBaseInfo?.baseInfo.role === 'onequick') {
              const pubInfo = usersInfo
                .filter(user => targetMessage.data.pubs.includes(user.id))
                .map(user => ({
                  division: user.division,
                  id: user.id,
                  name: user.name,
                  number: user.number
                }));

              // console.log('aschool mirrorstart!! request1', JSON.stringify(targetMessage));
              // console.log('aschool mirrorstart!! request2', targetMessage.data.mirror.roomnum);

              setMyMirrorManagement({ webRtcRoomId: targetMessage.data.mirror.roomnum, position: 'sub', pubInfo: pubInfo });
            }


            // console.log('aschool mirrorstart!! request')
            // mirrorStartCommand(targetMessage.data.pubs, targetMessage.data.subs);
          }

          /* 미러링 참여전송 완료 메시지가 온 경우 */
          else if (targetMessage.command === 'joinroom' && targetMessage.type === 'response') {

            const checkPub = targetMessage.data.pubs.indexOf(sessionBaseInfo?.baseInfo.userId);
            const roomnum = targetMessage.data.mirror.roomnum;

            if (targetMessage.error === 'success') {
              const apiReq = {
                lectureId: classInfo.lectureId,
                userId: sessionBaseInfo?.baseInfo.userId!,
                mirrorPosition: checkPub !== -1 ? 'PUB' : 'SUB',
                mirrorRoomNumber: roomnum.toString(),
                token: sessionTokenInfo.coreAccessToken!
              };
              userMirrorUpdate(apiReq);
              setTempMirrorEventData(prev => prev.filter(data => roomnum !== data.mirrorJoinInformation?.mirrorRoomNumber));
            }
            else {

              const targetTempMirror = tempMirrorEventData.find(data => roomnum && data.mirrorJoinInformation?.mirrorRoomNumber === roomnum);

              if (targetTempMirror?.talkId) {
                returnMirrorPermissionMessage({ permission: false, talkId: targetTempMirror.talkId, senderId: '', targetId: '' });
                setToast({ TYPE: 'alert', CONTENT: 'Failed to join screen share.', TIME: 3 });
              }
            }
          }

          /* 미러링 방 구성원 전달 메시지가 온 경우 */
          else if (targetMessage.command === 'roommember') {
            const checkPub = targetMessage.data.pubs.indexOf(sessionBaseInfo?.baseInfo.userId);
            const checkSub = targetMessage.data.subs.indexOf(sessionBaseInfo?.baseInfo.userId);
            if (checkPub !== -1 || checkSub !== -1) {
              const position = targetMessage.data.pubs.indexOf(sessionBaseInfo?.baseInfo.userId) !== -1 ? 'pub' : 'sub';
              const pubInfo = usersInfo
                .filter(user => targetMessage.data.pubs.includes(user.id))
                .map(user => ({
                  division: user.division,
                  id: user.id,
                  name: user.name,
                  number: user.number
                }));
              setMyMirrorManagement({ webRtcRoomId: targetMessage.data.mirror.roomnum, position: position, pubInfo: pubInfo });
            }
          }

          /* 미러링 중지완료로 인한 반환 */
          else if (targetMessage.command === 'mirrorstop' && (targetMessage.type === 'request' || targetMessage.type === 'response')) {
            /* 나한테 참여중인 미러링 종료가 온 경우 */
            if (Number(myMirrorManagement?.webRtcRoomId) === Number(targetMessage.data.mirror.roomnum)) {
              let myIndex = usersInfo.findIndex(user => user.id === sessionBaseInfo?.baseInfo.userId);

              if (myIndex === -1) {
                myIndex = 0;
              }


              setTimeout(() => {
                setUsersInfo(prev => prev.map(user => {
                  if (user.id === sessionBaseInfo?.baseInfo.userId) {
                    return {
                      ...user,
                      mirrorPosition: 'NON',
                      mirrorRoomNumber: null
                    };
                  }
                  return user;
                }));
                setMyMirrorManagement(null);
                const apiReq = {
                  lectureId: classInfo.lectureId,
                  userId: sessionBaseInfo?.baseInfo.userId!,
                  mirrorPosition: 'NON',
                  mirrorRoomNumber: null,
                  token: sessionTokenInfo.coreAccessToken!
                };
                userMirrorUpdate(apiReq);
              }, myIndex * 50);


            }

          }

          /* OPS 판서 파일 내보내기 한 경우 */
          else if (targetMessage.command === 'drawupload' && deviceInfo?.model === 'OPS') {
            try {
              const fileInfoArray = targetMessage.data.monitorSettings.filePath;

              if (fileInfoArray && fileInfoArray.length > 0) {
                const fixedResponse = fileInfoArray.replace(/\\/g, '\\\\');
                const fileInfo = JSON.parse(fixedResponse)[0];
                const filePath = fileInfo.name; // 원본 파일명
                const fileName = filePath.split('\\').pop();
                const fileBase64 = window.MirrorGetFileBase(filePath);

                if (fileBase64) {
                  const base64Parts = fileBase64.split(',');
                  if (base64Parts.length > 0) {
                    const extension = fileName.split('.').pop();
                    const mimeType = extension === 'linkschool' ? 'application/octet-stream' : 'image/jpeg';
                    uploadOpsFile(base64ToFile(fileBase64, fileName, mimeType));
                  }
                  else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                    console.log('drawupload : base64 데이터를 올바르게 분리할 수 없습니다.');
                  }
                }
                else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                  console.log('drawupload : 파일을 읽어오지 못했습니다.');
                }
              }
              else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log('drawupload : 파일 정보가 없습니다.');
              }
            } catch (e: any) {
              console.log(e);
              if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
                console.log(`drawupload error : ${JSON.stringify(e.message)}`);
              }
            }
          }
        }

      } catch (e: any) {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`LINK SCHOOL MEDIA BOX MESSAGE FAIL - ${e.message}`);
        }
      }
    }
  }, [classInfo, mediaBoxMessage]);

  /*
   * 교실 전체알림 메시지가 온 경우 동작하는 Hook
   *  - 체크인/체크아웃 메시지가 온 경우
   *      - 사용자 정보 업데이트 ( 체크인 플래그, 모니터링 이미지 URL )
   *  - 체크인 연장 메시지가 온 경우
   *      - 체크아웃 시간 연장
   *  - 화면잠금 메세지가 온 경우
   *      - 내 화면잠금에 대한 처리 ( 기존 화면잠금 상태와 다를 경우 처리 )
   *  - 모둠모드 변경 메세지가 온 경우
   *      - 모둠모드 셋팅 처리
   *  - 체크아웃시간 도달 메세지가 온 경우
   *      - 강제 체크아웃 처리
   */
  useEffect(() => {

    if (classInfo && classMessages.length > 0) {
      let targetMessage = classMessages[0];
      setClassMessages(prevMessages => prevMessages.slice(1));
      /*
       * 개인 체크인 || 체크아웃 ( CHECK_IN || CHECK_OUT_USER )
       */
      if ((targetMessage.eventType === 'CHECK_IN' || targetMessage.eventType === 'CHECK_OUT_USER') && targetMessage.checkInLecture.usersInfo) {
        setUsersInfo(prev => prev.map((user: UsersInfoInterface) => {
          const targetUser = targetMessage.checkInLecture.usersInfo?.find(u => u.id === user.id);
          if (targetUser) {
            if (targetUser.checkIn !== user.checkIn) {
              if (!targetUser.checkIn) {
                if (user.imageUrl) URL.revokeObjectURL(user.imageUrl);
              }
              return {
                ...user,
                checkIn: targetUser.checkIn,
                imageUrl: !targetUser.checkIn ? '' : user.imageUrl
              };
            }
          }
          return user;
        }));
      }

      /*
       * 체크인 연장 ( CHECK_IN_EXTENSION )
       */
      else if (targetMessage.eventType === 'CHECK_IN_EXTENSION' && targetMessage.checkInLecture.checkOutTime) {
        setCheckInTimeInfo({ ...checkInTimeInfo!, checkOutTime: targetMessage.checkInLecture.checkOutTime, sec: publicMethod.getRemainingSeconds(targetMessage.checkInLecture.checkOutTime) });
      }

      /*
       * 화면잠금 ( LOCKED )
       */
      else if (targetMessage.eventType === 'LOCKED' && targetMessage.checkInLecture.usersInfo && sessionBaseInfo?.baseInfo.role === 'student') {
        const myInfo = targetMessage.checkInLecture.usersInfo.find(u => u.id === sessionBaseInfo?.baseInfo.userId);
        if (myInfo && lockState !== myInfo.locked) {
          setLockState(myInfo.locked);
          if (myInfo.locked) {
            window.MirrorSetLock(myInfo.locked, publicMethod.getRemainingSeconds(classInfo.checkOutTime));
          }
          else {
            window.MirrorSetLock(myInfo.locked, 0);
          }
        }
      }

      /*
       * 화면잠금 ( LOCKED )
       */
      else if (targetMessage.eventType === 'LOCKED' && targetMessage.checkInLecture.usersInfo && sessionBaseInfo?.baseInfo.role === 'teacher') {
        setUsersInfo(prev => prev.map((user: UsersInfoInterface) => {
          const targetUser = targetMessage.checkInLecture.usersInfo?.find(u => u.id === user.id);
          if (targetUser) {
            return {
              ...user,
              isLocked: targetUser.locked
            };
          }
          return user;
        }));
      }

      /*
       * 모둠모드 ( GROUP_MODE )
       */
      else if (targetMessage.eventType === 'GROUP_MODE') {
        /* 모드 변경시 내가 참여중인 미러링이 존재한다면 나감처리 */
        if (targetMessage.checkInLecture.groupMode !== groupMode.groupMode && myMirrorManagement?.webRtcRoomId) {
          mirrorStopCommand(myMirrorManagement.webRtcRoomId);
          setMyMirrorManagement(null);
        }

        setGroupMode({
          groupMode: targetMessage.checkInLecture.groupMode,
          groupInfo: targetMessage.checkInLecture.groupInfo ? targetMessage.checkInLecture.groupInfo : null
        });
      }

      /*
       * 체크아웃시간 도달 ( CHECK_OUT_LECTURE )
       */
      else if (targetMessage.eventType === 'CHECK_OUT_LECTURE') {
        classCheckOut(true);
        if (sessionBaseInfo?.baseInfo.role === 'teacher' || sessionBaseInfo?.baseInfo.role === 'student') {
          setTimeout(() => {
            setToast({ TYPE: 'alert', CONTENT: 'The class time has ended\nand you have been automatically checked out.', TIME: 3 });
            navigate(`/service/${sessionBaseInfo.baseInfo.role}/home`, { replace: true });
          }, 500);
        }
        else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('전자칠판/원퀵 체크아웃');
          window.location.reload();
        }
      }

      /* 미러링 */
      else if (targetMessage.eventType === 'USER_INFO_MIRROR') {
        setUsersInfo(prev => prev.map((user: UsersInfoInterface) => {
          const targetUser = targetMessage.checkInLecture.usersInfo?.find(u => u.id === user.id);
          if (targetUser) {
            if (targetUser.checkIn) {
              return {
                ...user,
                mirrorPosition: targetUser.mirrorPosition,
                mirrorRoomNumber: targetUser.mirrorRoomNumber
              };
            }
            else {
              return {
                ...user,
                checkIn: false,
                imageUrl: '',
                mirrorPosition: targetUser.mirrorPosition,
                mirrorRoomNumber: targetUser.mirrorRoomNumber
              };
            }

          }
          return user;
        }));
        setMirrorManagement((prev) => ({ ...prev, mirrorInfo: targetMessage.checkInLecture.mirrorInfo ? targetMessage.checkInLecture.mirrorInfo : [] }));
      }

    }

  }, [classInfo, classMessages]);

  /*
   * 미러링 관리메세지에 신규 메세지가 왔을 때 동작하는 Hook
   *  - mirrorManagement 중 Temp 부분 업데이트 처리
   */
  useEffect(() => {
    if (classInfo && sessionBaseInfo?.baseInfo.role === 'teacher') {
      // Pub들이 전부 거절인 경우, Sub들이 전부 거절인 경우 미러링 종료 처리
      setMirrorManagement((prev) => ({ ...prev, mirrorTempInfo: mirrorManageMessage }));

      for (const m of mirrorManageMessage) {
        let pubCount = 0;
        let subCount = 0;
        for (const u of m.mirrorUsers) {
          if (u.mirrorType === 'PUB') {
            pubCount++;
          }
          else if (u.mirrorType === 'SUB') {
            subCount++;
          }
        }
        if (pubCount < 1 || subCount < 1) {
          mirrorStopCommand(m.roomNumber);
        }
      }

    }
  }, [classInfo, mirrorManageMessage]);

  /*
   * 개인 메세지가 도착한 경우
   */
  useEffect(() => {
    if (classInfo && receiveMessages.length > 0) {
      const targetMessage = receiveMessages[0];
      setReceiveMessages(prevMessages => prevMessages.slice(1));

      const senderInfo = usersInfo.find(user => user.id === targetMessage.senderId);

      if (senderInfo) {
        const talkId = targetMessage.talkId;
        const senderId = senderInfo.id;
        const senderName = senderInfo.name;
        let talkTitle: 'file' | 'msg' | 'link' = 'msg';
        let fileList: {
          fileRealName: string;
          fileUrl: string;
          fileSize: number;
        }[] = [];
        let message: string = '';

        if (targetMessage.eventType === 'TALK_MESSAGE_SEND' && typeof targetMessage.message === 'string') {
          message = targetMessage.message;
          talkTitle = 'msg';
        }
        else if (targetMessage.eventType === 'TALK_LINK_SEND' && typeof targetMessage.message === 'string') {
          message = targetMessage.message;
          talkTitle = 'link';
        }
        else if (targetMessage.eventType === 'TALK_FILE_SEND' && typeof targetMessage.message !== 'string') {
          const files = targetMessage.message;
          for (const f of files) {
            fileList.push({
              fileRealName: f.fileName,
              fileUrl: f.filePath,
              fileSize: f.fileSize
            });
          }
          talkTitle = 'file';
        }

        const talkListData: ClassTalkListInterface = {
          talkId: talkId,
          talkType: 'chat',
          talkTitle: talkTitle,
          sendTime: publicMethod.getCurrentTime(),
          isRead: false,
          chatInfo: {
            messageId: targetMessage.talkId,
            id: senderId,
            name: senderName,
            type: talkTitle,
            str: message,
            fileList: fileList
          }
        };

        setClassTalkList(prev => [...prev, talkListData]);

        setClassTalkPopup(prev => [...prev, talkListData]);

      }

    }
  }, [classInfo, receiveMessages]);

  /*
   * 요청 메세지가 도착한 경우 ( File / Link / Message )
   */
  useEffect(() => {
    if (classInfo && permissionRequestMessages.length > 0) {
      const targetMessage = permissionRequestMessages[0];
      setPermissionRequestMessages(prevMessages => prevMessages.slice(1));

      const talkId = targetMessage.talkId;
      let talkTitle: 'file' | 'mirror' | 'link' | null = null;
      const requestUserId = targetMessage.message.requestId;      // 요청자 uuid
      const requestUserName = targetMessage.message.requestName;  // 요청자 이름
      const targetInfo = targetMessage.message.rcvrInfoList.map((receiver) => {
        return {
          id: receiver.userId,
          name: receiver.userName,
          division: receiver.division
        };
      });
      let eventType: 'fileRequest' | 'linkRequest' | null = null;
      let fileList: {
        fileRealName: string;
        fileUrl: string;
        fileSize: number;
      }[] = [];
      let linkMessage: string = '';


      if (targetMessage.eventType === 'TALK_LINK_PERMISSION') {
        linkMessage = targetMessage.message.requestMessage;   // 요청 링크
        eventType = 'linkRequest';
        talkTitle = 'link';
      }
      else if (targetMessage.eventType === 'TALK_FILE_PERMISSION') {
        try {
          const fileInfo: { fileName: string; fileSize: number; }[] = JSON.parse(targetMessage.message.requestMessage);   // 전송요청 파일 정보 목록
          talkTitle = 'file';
          eventType = 'fileRequest';
          for (const f of fileInfo) {
            fileList.push({
              fileRealName: f.fileName,
              fileUrl: '',
              fileSize: f.fileSize
            });
          }
        }
        catch (e: any) {
          console.log(e);
        }
      }

      if (talkTitle && eventType) {
        const talkListData: ClassTalkListInterface = {
          talkId: talkId,
          talkType: 'event',
          talkTitle: talkTitle,
          sendTime: publicMethod.getCurrentTime(),
          isRead: false,
          eventInfo: {
            senderId: requestUserId,
            senderName: requestUserName,
            targetInfo: targetInfo,
            eventId: talkId,
            type: eventType,
            str: linkMessage,
            fileList: fileList
          }
        };
        setClassTalkList(prev => [...prev, talkListData]);
        setClassTalkPopup(prev => [...prev, talkListData]);
      }

    }
  }, [classInfo, permissionRequestMessages]);

  /*
   * 요청 메세지가 도착한 경우 ( Mirror Open )
   */
  useEffect(() => {

    console.log('classInfo mirrorOpenRequestMessages: ' + JSON.stringify(classInfo), mirrorOpenRequestMessages.length);
    if (classInfo && mirrorOpenRequestMessages.length > 0) {

      const targetMessage = mirrorOpenRequestMessages[0];
      console.log('mirrorOpenRequestMessages: ' + JSON.stringify(targetMessage));
      setMirrorOpenRequestMessages(prevMessages => prevMessages.slice(1));


      const talkId = targetMessage.talkId;
      const talkTitle = 'mirror';
      const requestUserId = targetMessage.message.requestUserId;      // 요청자 uuid

      const requestUserInfo = usersInfo.find(user => user.id === requestUserId);
      const eventType = 'mirrorRequest';
      // const pubInfo: { id: string; name: string; division: string; }[] = [];
      // const subInfo: { id: string; name: string; division: string; }[] = [];
      // targetMessage.message.mirrorUsers.map((user) => {
      //   if (user.mirrorType === 'PUB') {
      //     pubInfo.push({
      //       id: user.id,
      //       name: user.name,
      //       division: user.division
      //     });
      //   }
      // });
      // targetMessage.message.mirrorUsers.map((user) => {
      //   if (user.mirrorType === 'SUB') {
      //     subInfo.push({
      //       id: user.id,
      //       name: user.name,
      //       division: user.division
      //     });
      //   }
      // });

      const pubInfo: { id: string; name: string; division: string; }[] = targetMessage.message.mirrorUsers.filter((user) => user.mirrorType === 'PUB').map((user) => {
        return {
          id: user.id,
          name: user.name,
          division: user.division
        }
      });

      const subInfo: { id: string; name: string; division: string; }[] = targetMessage.message.mirrorUsers.filter((user) => user.mirrorType === 'SUB').map((user) => {
        return {
          id: user.id,
          name: user.name,
          division: user.division
        }
      });





      if (requestUserInfo) {
        const talkListData: ClassTalkListInterface = {
          talkId: talkId,
          talkType: 'event',
          talkTitle: talkTitle,
          sendTime: publicMethod.getCurrentTime(),
          isRead: false,
          eventInfo: {
            senderId: requestUserId,
            senderName: requestUserInfo.name,
            eventId: talkId,
            type: eventType,
            str: '미러링 오픈요청',
            pubInfo: pubInfo[0],
            targetInfo: subInfo
          }
        };
        setClassTalkList(prev => [...prev, talkListData]);
        setClassTalkPopup(prev => [...prev, talkListData]);

        const tempMirrorEventData: TempMirrorDataInterface = {
          talkId: talkId,
          mirrorId: targetMessage.message.id
        };

        setTempMirrorEventData(prev => [...prev, tempMirrorEventData]);
      }

    }
  }, [classInfo, mirrorOpenRequestMessages]);

  /*
   * 요청 메세지가 도착한 경우 ( Mirror Join )
   */
  useEffect(() => {
    if (classInfo && mirrorJoinRequestMessages.length > 0) {



      const targetMessage = mirrorJoinRequestMessages[0];
      setMirrorJoinRequestMessages(prevMessages => prevMessages.slice(1));

      const myPosition = targetMessage.message.mirrorType;
      const myOpenRequest = tempEventData.find(data => data.tempMirrorOpenInformation?.mirrorId === targetMessage.message.mirrorId);

      console.log('eventmsg myOpenRequest: ' + JSON.stringify(myOpenRequest));

      if (sessionBaseInfo?.baseInfo.role === 'student' && !myOpenRequest) {
        if (myPosition === 'PUB') {

          const talkId = targetMessage.message.talkId;
          let talkTitle: 'mirror' = 'mirror';
          const requestUserId = targetMessage.senderId;      // 요청자 uuid

          const requestUserInfo = usersInfo.find(user => user.id === requestUserId);
          let eventType: 'mirrorRequest' = 'mirrorRequest';
          const targetList: { id: string; name: string; division: string; }[] = targetMessage.message.mirrorUsers.map(u => {
            return {
              id: u.userId,
              name: u.name,
              division: u.division
            };
          });

          if (requestUserInfo) {
            const talkListData: ClassTalkListInterface = {
              talkId: talkId,
              talkType: 'event',
              talkTitle: talkTitle,
              sendTime: publicMethod.getCurrentTime(),
              isRead: false,
              eventInfo: {
                senderId: requestUserId,
                senderName: requestUserInfo.name,
                eventId: talkId,
                type: eventType,
                str: '미러링 오픈요청',
                targetInfo: targetList
              }
            };
            setClassTalkList(prev => [...prev, talkListData]);
            setClassTalkPopup(prev => [...prev, talkListData]);

            const tempMirrorJoinInformation: TempMirrorDataInterface = {
              talkId: targetMessage.message.talkId,
              mirrorJoinInformation: {
                mirrorId: targetMessage.message.mirrorId,
                mirrorPosition: myPosition,
                mirrorRoomNumber: targetMessage.message.roomNumber
              }
            };
            setTempMirrorEventData(prev => [...prev, tempMirrorJoinInformation]);
          }

        }
        else {
          const tempMirrorJoinInformation: TempMirrorDataInterface = {
            talkId: targetMessage.talkId,
            mirrorJoinInformation: {
              mirrorId: targetMessage.message.mirrorId,
              mirrorPosition: myPosition,
              mirrorRoomNumber: targetMessage.message.roomNumber
            }
          };
          setTempMirrorEventData(prev => [...prev, tempMirrorJoinInformation]);

          const roomNumber = targetMessage.message.roomNumber!;
          mirrorJoinRoomCommand([], [sessionBaseInfo.baseInfo.userId], Number(roomNumber));
        }
      }
      else {
        const tempMirrorJoinInformation: TempMirrorDataInterface = {
          talkId: targetMessage.talkId,
          mirrorJoinInformation: {
            mirrorId: targetMessage.message.mirrorId,
            mirrorPosition: myPosition,
            mirrorRoomNumber: targetMessage.message.roomNumber
          }
        };
        setTempMirrorEventData(prev => [...prev, tempMirrorJoinInformation]);

        if (myPosition === 'SUB') {
          const roomNumber = targetMessage.message.roomNumber!;
          mirrorJoinRoomCommand([], [sessionBaseInfo!.baseInfo.userId], Number(roomNumber));
        }
        else {
          const roomNumber = targetMessage.message.roomNumber!;
          mirrorJoinRoomCommand([sessionBaseInfo!.baseInfo.userId], [], Number(roomNumber));
        }
      }

    }
  }, [classInfo, mirrorJoinRequestMessages]);

  /*
   * 요청에 대한 응답 메세지가 도착한 경우
   */
  useEffect(() => {
    if (classInfo && permissionResponseMessages.length > 0) {
      const targetMessage = permissionResponseMessages[0];
      setPermissionResponseMessages(prevMessages => prevMessages.slice(1));

      if (targetMessage.eventType === 'PERMISSION_RESPONSE' && (targetMessage.message.permission === 'APPROVED' || targetMessage.message.permission === 'REJECTED')) {
        permissionResponseLogic({ permission: targetMessage.message.permission, talkId: targetMessage.message.talkId, mirrorId: targetMessage.message.mirrorId, senderId: targetMessage.senderId });
      }
    }
  }, [classInfo, permissionResponseMessages]);

  /*
   * bypass 메세지가 도착한 경우 ( 다이렉트 전송 )
   */
  useEffect(() => {
    if (classInfo && bypassMessages.length > 0) {
      const targetMessage = bypassMessages[0];
      setBypassMessages(prevMessages => prevMessages.slice(1));

      if (targetMessage.type === 'STREAM_REQUEST') {
        setPopupOther((prevData) => ({ ...prevData, TYPE: 'onlineClass', RESERVE: { roomId: targetMessage.bypassData, teacherName: usersInfo.find(u => u.id === targetMessage.senderId)!.name } }));
      }
      else if (targetMessage.type === 'MONITOR_RESPONSE') {
        if (detailMonitorImage && (targetMessage.senderId === detailMonitorImage.id) && targetMessage.bypassData !== 'FAIL') {
          setUIDetailMonitorImage(targetMessage.bypassData);
        }
      }
      else if (targetMessage.type === 'MONITOR_REQUEST') {
        uploadDetailMonitorImage(targetMessage.senderId);
      }
    }
  }, [classInfo, bypassMessages]);

  /*
   * Mqtt 활성화 연결로 변경될 경우 교실상태, 학생상태, 미러링 상태 등을 동기화 처리한다.
   */
  useEffect(() => {
    if (checkInManagement.mqttConnect) {
      if (!firstCheckIn) {
        firstCheckIn = true;
      }
      else {
        findLectureInformation();
      }

      /* 기존 수업톡 조회 */
      findClassTalkList();

      /* 미러링 관리데이터 조회 */
      if (sessionBaseInfo?.baseInfo.role === 'teacher') {
        findMirrorManagementInformation();
      }
    }
  }, [checkInManagement.mqttConnect]);


  /*
   * 체크인 상태처리 함수
   *  - 상태 변경 ( 재연결 시 )
   *  - Mqtt 는 기존에 받지 못한 데이터가 있는지 서버와 동기화 처리를 진행해야한다.
   */
  const checkInStateChange = async (type: 'mqtt' | 'chat', state: boolean) => {
    if (type === 'mqtt') {
      setCheckInManagement(prev => ({ ...prev, mqttConnect: state }));
    }
    else if (type === 'chat') {
      setCheckInManagement(prev => ({ ...prev, chatConnect: state }));
    }
  };

  /*
   * 모니터링 이미지 URL 사용자정보에 셋팅하는 함수
   *  - 기존 이미지를 별도 저장
   *  - 유저에 신규 이미지 URL 을 셋팅
   *  - 기존 이미지를 런타임 연결해제
   */
  const usersInfoSetImageUrl = async (id: string, url: string) => {
    const imageUrl = await setMonitorImageUrl(url);
    if (imageUrl) {
      const userInfo = usersInfo.find(u => u.id === id);
      let beforeImage: string | null = null;
      if (userInfo?.imageUrl && userInfo?.imageUrl !== imageUrl) {
        beforeImage = userInfo.imageUrl;
      }
      const newImageUrl = `${imageUrl}`;
      console.log(userInfo?.name, newImageUrl);
      setUsersInfo(prev => prev.map(user => user.id === id ? { ...user, imageUrl: newImageUrl } : user));
      if (beforeImage) {
        URL.revokeObjectURL(beforeImage);
      }
    }
  };

  /*
   * 모니터링 파일 다운로드 함수
   *  - 모니터링 이미지 URL을 받아서 해당 URL을 통해 Blob 파일을 다운로드 받는 함수를 호출한다.
   *  - Blob 파일을 다운로드 받은 후 해당 Blob 파일을 URL로 변환하여 반환한다.
   */
  const setMonitorImageUrl = async (imageUrl: string): Promise<string | null> => {
    try {
      const blob = await getUrlToBlob(imageUrl);
      if (blob && blob.size > 1000) {
        return URL.createObjectURL(blob);
      }
      else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL MONITOR IMAGE DOWNLOAD FAIL - Blob 변환실패');
      }
    }
    catch (e: any) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`LINK SCHOOL MONITOR IMAGE DOWNLOAD FAIL - ${e.message}`);
      }
    }
    return null;
  };

  /*
   * URL 을 통해 Blob 파일을 다운로드 받는 함수
   *  1. 해당 URL 을 통해 Blob 파일을 다운로드 받는다.
   * */
  const getUrlToBlob = async (url: string): Promise<Blob | false> => {
    try {
      const apiReq = { token: sessionTokenInfo.chatAccessToken!, fileUrl: url };
      const blob = await chatServerFileDownloadS3(apiReq);
      if (!blob.code) {
        return blob;
      } else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`LINK SCHOOL MONITOR IMAGE DOWNLOAD FAIL : ${blob.code}`);
      }
    } catch (e: any) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`LINK SCHOOL MONITOR IMAGE DOWNLOAD FAIL - ${e.message}`);
      }
    }
    return false;
  };

  /*
   * 미디어박스 연결 함수
   *  1. 미디어박스 연결 셋팅을 처리한다.
   *  2. 연결 셋팅을 토대로 미디어박스 연결하며 Callback 메시지 처리를 셋팅한다.
   *  3. 연결에 실패할경우 각 서버 연결정보 중 미디어박스 연결상태 false 처리한다.
   */
  const mediaBoxConnect = (classInfo: ClassInfoInterface): boolean => {

    try {
      console.log('aschool mbIP: ' + classInfo.mediaBoxIp);
      const mirrorSettings = {
        mirrorServer: classInfo.mediaBoxIp ? `http://${classInfo.mediaBoxIp}` : null,
        mirrorClassroom: classInfo.lectureId,
        mirrorUuid: sessionBaseInfo?.baseInfo.userId,
        videoFPS: 15,
        grade: !isNaN(Number(classInfo.classGrade)) ? Number(classInfo.classGrade) : 0,
        lesson: !isNaN(Number(classInfo.classNumber)) ? Number(classInfo.classNumber) : 0,
        floatingURL: `${process.env.REACT_APP_BASEURL}/floating/${sessionBaseInfo?.baseInfo.role}`
      };

      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`aschool SCHOOL - MIRROR SETTING : ${JSON.stringify(mirrorSettings)}`);
      }

      window.MirrorCheckIn(mirrorSettings, (msg: any) => {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`
            aschool SCHOOL - MIRROR CALLBACK SUCCESS ==========================================
            ${msg}
            ==============================================================================`);
        }
        setMediaBoxMessage((prevMessages: any[]) => [...prevMessages, msg]);
      });

      return true;

    } catch (e: any) {
      setCheckInManagement(prev => ({ ...prev, mediaBoxConnect: false }));
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`aschool SCHOOL MEDIA BOX FAIL - CHECK IN ${e.message}`);
      }

      return false;
    }

  };

  /*
   * 미러링 방생성 요청 Command 함수
   *  - 학생, 모둠 요청자
   */
  const mirrorRoomOpenCommand = (mirrorPrimaryKey: string) => {
    if (classInfo?.mediaBoxIp) {
      const mediaBoxCommand: MediaBoxRoomOpen = {
        commandDivision: 'MIRROR_CREATE_ROOM',
        classroom: classInfo.lectureId,
        command: 'createroom',
        type: 'request',
        data: {
          pubs: [mirrorPrimaryKey]
        },
        sender: sessionBaseInfo?.baseInfo.userId!
      };
      return mirrorCommand(mediaBoxCommand);
    }
    return false;
  };

  /*
   * 미러링 특정방 참여전송 Command 함수
   */
  const mirrorJoinRoomCommand = (pubs: string[], subs: string[], roomNumber: number) => {
    if (classInfo?.mediaBoxIp) {
      const mediaBoxCommand: MediaBoxJoinRoomRequest = {
        commandDivision: 'MIRROR_JOIN_ROOM',
        classroom: classInfo.lectureId,
        command: 'joinroom',
        type: 'request',
        data: {
          pubs: pubs,
          subs: subs,
          roomnum: roomNumber
        },
        sender: sessionBaseInfo?.baseInfo.userId!
      };
      return mirrorCommand(mediaBoxCommand);
    }
    return false;
  };

  /*
   * 전체 미러링 종료 요청 Command 함수
   */
  const stopAllMirrorRoomCommand = (lectureId?: string) => {
    // console.log('stopAllMirrorRoomCommand', classInfo, sessionBaseInfo?.baseInfo?.role === 'teacher', lectureId);
    if (sessionBaseInfo?.baseInfo?.role === 'teacher') {
      const mediaBoxCommand: MediaBoxRoomDeleteAll = {
        commandDivision: 'MIRROR_STOP_ALL',
        classroom: lectureId ? lectureId : classInfo!.lectureId,
        command: 'deleteroomall',
        type: 'request',
        sender: sessionBaseInfo.baseInfo.userId
      };
      return mirrorCommand(mediaBoxCommand);
    }
    return false;
  };

  /*
   * 수업톡 조회 및 현재 Front 수업톡 갱신 Api
   *  - Mqtt 연결플래그가 true 일 경우 동작
   *  - PermissionData 와 nonPermissionData 를 종합해서 하나로 보여줘야한다.
   *      - 내가 수신자인 경우 : nonPermissionData 중 rcvrList 부분이며 requestDate 를 사용, permissionData 또한 rcvrList 부분이며 permissionDate 를 사용
   *      - 내가 요청자인 경우 : permissionData 중 request 부분이며 permissionDate 를 사용
   *      - 내가 허가자인 경우 : permissionData 중 permission 부분이며 requestDate 를 사용
   */
  const findClassTalkList = async (): Promise<boolean> => {
    const apiRes = await findMyTalkLIst({ token: sessionTokenInfo.coreAccessToken! });
    if (apiRes.code === 'OK' && apiRes.data) {

      const classTalkDataList: ClassTalkListInterface[] = [];
      const classTempEventDataList: TempEventDataInterface[] = [];
      const classTempMirrorDataList: TempMirrorDataInterface[] = [];

      const permissionData = apiRes.data.permissionData;
      const nonPermissionData = apiRes.data.nonPermissionData;

      /*
       * 내가 수신자일 때 수업톡
       */
      if (permissionData && permissionData.rcvrList) {

        for (const talk of permissionData.rcvrList) {

          const myInfo = talk.rcvrList.find(r => r.userId === sessionBaseInfo?.baseInfo.userId);

          if (myInfo && !myInfo.deleteYn) {
            const talkId = talk.id;

            let talkTitle: 'file' | 'link' | 'msg' | null = null;

            if (talk.talkType === 'FILE') {
              talkTitle = 'file';
            }
            else if (talk.talkType === 'LINK') {
              talkTitle = 'link';
            }
            else if (talk.talkType === 'MESSAGE') {
              talkTitle = 'msg';
            }

            const sendTime = publicMethod.getCurrentTime(talk.permissionDate);
            const targetInfo = usersInfo.find(u => u.id === talk.requestUserId);
            const message = talk.message;
            const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];
            if (talk.attachInfoList) {
              for (const f of talk.attachInfoList) {
                fileList.push({
                  fileRealName: f.fileName,
                  fileUrl: f.filePath,
                  fileSize: f.fileSize
                });
              }
            }

            if (talkTitle && sendTime && targetInfo && ((talk.talkType === 'FILE' && fileList.length > 0) || talk.talkType !== 'FILE')) {
              const talkListData: ClassTalkListInterface = {
                talkId: talkId,
                talkType: 'chat',
                talkTitle: talkTitle,
                sendTime: sendTime,
                isRead: true,
                chatInfo: {
                  messageId: talkId,
                  id: targetInfo.id,
                  name: targetInfo.name,
                  type: talkTitle,
                  str: message,
                  fileList: fileList
                }
              };
              classTalkDataList.push(talkListData);
            }

          }

        }

      }
      if (nonPermissionData && nonPermissionData.rcvrList) {
        for (const talk of nonPermissionData.rcvrList) {

          const myInfo = talk.rcvrList.find(r => r.userId === sessionBaseInfo?.baseInfo.userId);

          if (myInfo?.name && !myInfo.deleteYn && myInfo.deleteYn !== undefined) {
            const talkId = talk.id;

            let talkTitle: 'file' | 'link' | 'msg' | null = null;

            if (talk.talkType === 'FILE') {
              talkTitle = 'file';
            }
            else if (talk.talkType === 'LINK') {
              talkTitle = 'link';
            }
            else if (talk.talkType === 'MESSAGE') {
              talkTitle = 'msg';
            }

            const sendTime = publicMethod.getCurrentTime(talk.requestDate);
            const targetInfo = usersInfo.find(u => u.id === talk.requestUserId);
            const message = talk.message;
            const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];
            if (talk.attachInfoList) {
              for (const f of talk.attachInfoList) {
                fileList.push({
                  fileRealName: f.fileName,
                  fileUrl: f.filePath,
                  fileSize: f.fileSize
                });
              }
            }

            if (talkTitle && sendTime && targetInfo && ((talk.talkType === 'FILE' && fileList.length > 0) || talk.talkType !== 'FILE')) {
              const talkListData: ClassTalkListInterface = {
                talkId: talkId,
                talkType: 'chat',
                talkTitle: talkTitle,
                sendTime: sendTime,
                isRead: true,
                chatInfo: {
                  messageId: talkId,
                  id: targetInfo.id,
                  name: targetInfo.name,
                  type: talkTitle,
                  str: message,
                  fileList: fileList
                }
              };
              classTalkDataList.push(talkListData);
            }

          }

        }
      }

      // /*
      //  * 내가 요청자일 때 수업톡 ( 응답 완료된건 )
      //  */
      // if (permissionData && permissionData.request) {
      //     for(const talk of permissionData.request) {
      //
      //         const talkId = talk.id;
      //
      //         let talkTitle: 'file' | 'link' | 'msg' | 'mirror' | null = null;
      //         let eventType: 'fileResponse' | 'linkResponse' | 'mirrorResponse' | null = null;
      //
      //         if (talk.talkType === 'FILE') {
      //             talkTitle = 'file';
      //             eventType = 'fileResponse';
      //         }
      //         else if (talk.talkType === 'LINK') {
      //             talkTitle = 'link';
      //             eventType = 'linkResponse';
      //         }
      //         else if (talk.talkType === 'MIRROR_OPEN') {
      //
      //         }
      //         else if (talk.talkType === 'MIRROR_JOIN') {
      //
      //         }
      //
      //         const sendTime = publicMethod.getCurrentTime(talk.permissionDate!);
      //         const targetInfo = usersInfo.find(u => u.id === talk.requestUserId);
      //         const message = talk.message;
      //         const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];
      //         const receiverList: { id: string; name: string; division: string; }[] = talk.rcvrList.map(r => {
      //             return {
      //                 id: r.userId,
      //                 name: r.name,
      //                 division: r.division
      //             };
      //         });
      //
      //         if (talk.attachInfoList) {
      //             for(const f of talk.attachInfoList) {
      //                 fileList.push({
      //                     fileRealName: f.fileName,
      //                     fileUrl: f.filePath,
      //                     fileSize: f.fileSize
      //                 });
      //             }
      //         }
      //
      //         if (talk.talkType === 'MIRROR_OPEN' || talk.talkType === 'MIRROR_JOIN') {
      //             // if (talk.talkType === 'MIRROR_OPEN') {
      //             //     // 내가만든 미러링 오픈 수락/거절이 온 경우
      //             // }
      //             // else if (talk.talkType === 'MIRROR_JOIN') {
      //             //     // 내가만든 미러링 참여 수락/거절이 온 경우
      //             // }
      //         }
      //         if (talk.permission === 'APPROVED' || talk.permission === 'REJECTED') {
      //             if (talkTitle && sendTime && targetInfo && eventType) {
      //                 const talkListData: ClassTalkListInterface = {
      //                     talkId: talkId,
      //                     talkType: 'event',
      //                     talkTitle: talkTitle,
      //                     sendTime: sendTime,
      //                     isRead: true,
      //                     eventInfo: {
      //                         senderId: targetInfo.id,
      //                         senderName: targetInfo.name,
      //                         targetInfo: receiverList,
      //                         eventId: talkId,
      //                         type: eventType,
      //                         str: talk.permission === 'APPROVED' ? 'Y' : 'N',
      //                         fileList: fileList
      //                     }
      //                 };
      //                 classTalkDataList.push(talkListData);
      //             }
      //         }
      //
      //     }
      // }
      //
      // /*
      //  * 내가 응답자일 때 수업톡
      //  */
      // if (permissionData && permissionData.permission) {
      //     for(const talk of permissionData.permission) {
      //
      //         const talkId = talk.id;
      //         let message = talk.message;
      //
      //         let talkTitle: 'file' | 'link' | 'msg' | 'mirror' | null = null;
      //         let eventType: 'fileRequest' | 'linkRequest' | 'mirrorRequest' | null = null;
      //         const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];
      //
      //         if (talk.talkType === 'FILE') {
      //             talkTitle = 'file';
      //             eventType = 'fileRequest';
      //             try {
      //                 const fileMessageList: { fileName: string; fileSize: number; }[] = JSON.parse(message);
      //
      //                 for(const f of fileMessageList) {
      //                     fileList.push({
      //                         fileRealName: f.fileName,
      //                         fileUrl: '',
      //                         fileSize: f.fileSize
      //                     });
      //                 }
      //
      //
      //             } catch (e: any) {
      //                 console.log(e);
      //             }
      //         }
      //         else if (talk.talkType === 'LINK') {
      //             talkTitle = 'link';
      //             eventType = 'linkRequest';
      //         }
      //
      //         const sendTime = publicMethod.getCurrentTime(talk.permissionDate!);
      //         const targetInfo = usersInfo.find(u => u.id === talk.requestUserId);
      //
      //         const receiverList: { id: string; name: string; division: string; }[] = talk.rcvrList.map(r => {
      //             return {
      //                 id: r.userId,
      //                 name: r.name,
      //                 division: r.division
      //             };
      //         });
      //
      //         if (talk.talkType === 'MIRROR_OPEN' || talk.talkType === 'MIRROR_JOIN') {
      //             if (talk.talkType === 'MIRROR_OPEN') {
      //                 // 나에게 온 미러링 오픈요청
      //                 if (talk.mirrorInfoList && talk.mirrorInfoList.length > 0 && targetInfo?.id) {
      //                     const mirrorInfo = talk.mirrorInfoList ? talk.mirrorInfoList[0] : null;
      //                     if (mirrorInfo && !mirrorInfo.createRoom) {
      //                         const pubInfo = {
      //                             id: targetInfo.id,
      //                             name: targetInfo.name,
      //                             division: targetInfo.division
      //                         };
      //
      //                         const subInfo = mirrorInfo.mirrorUsers.filter(user => user.mirrorType === 'SUB').map((user) => {
      //                             return {
      //                                 id: user.id,
      //                                 name: user.name,
      //                                 division: user.division
      //                             };
      //                         });
      //
      //                         if (subInfo.length > 0) {
      //                             const talkListData: ClassTalkListInterface = {
      //                                 talkId: talkId,
      //                                 talkType: 'event',
      //                                 talkTitle: 'mirror',
      //                                 sendTime: publicMethod.getCurrentTime(talk.permissionDate!),
      //                                 isRead: true,
      //                                 eventInfo: {
      //                                     senderId: targetInfo.id,
      //                                     senderName: targetInfo.name,
      //                                     targetInfo: subInfo,
      //                                     eventId: talkId,
      //                                     type: 'mirrorRequest',
      //                                     str: '미러링 오픈요청',
      //                                     pubInfo: pubInfo
      //                                 }
      //                             };
      //                             classTalkDataList.push(talkListData);
      //
      //                             classTempMirrorDataList.push({
      //                                 talkId: talkId,
      //                                 mirrorId: mirrorInfo.id
      //                             });
      //                         }
      //
      //                     }
      //                 }
      //             }
      //             else if (talk.talkType === 'MIRROR_JOIN') {
      //                 // 나에게 온 미러링 참여요청
      //                 if (talk.mirrorInfoList && talk.mirrorInfoList.length > 0 && targetInfo?.id) {
      //                     const mirrorInfo = talk.mirrorInfoList ? talk.mirrorInfoList[0] : null;
      //                     if (mirrorInfo) {
      //                         const myMirrorInfo = mirrorInfo.mirrorUsers.find(user => user.id === sessionBaseInfo?.baseInfo.userId);
      //
      //                         if (myMirrorInfo && mirrorInfo.roomNumber && myMirrorInfo.mirrorType === 'PUB' && mirrorInfo.requestUserId !== sessionBaseInfo?.baseInfo.userId) {
      //                             const talkListData: ClassTalkListInterface = {
      //                                 talkId: talkId,
      //                                 talkType: 'event',
      //                                 talkTitle: 'mirror',
      //                                 sendTime: publicMethod.getCurrentTime(talk.permissionDate!),
      //                                 isRead: true,
      //                                 eventInfo: {
      //                                     senderId: targetInfo.id,
      //                                     senderName: targetInfo.name,
      //                                     eventId: talkId,
      //                                     type: 'mirrorRequest',
      //                                     str: '미러링 참여요청'
      //                                 }
      //                             };
      //                             classTalkDataList.push(talkListData);
      //
      //                             classTempMirrorDataList.push({
      //                                 talkId: talkId,
      //                                 mirrorJoinInformation: {
      //                                     mirrorId: mirrorInfo.id,
      //                                     mirrorPosition: myMirrorInfo.mirrorType,
      //                                     mirrorRoomNumber: mirrorInfo.roomNumber
      //                                 }
      //                             });
      //                         }
      //                     }
      //                 }
      //             }
      //         }
      //         else if (talkTitle && sendTime && targetInfo && eventType) {
      //             const talkListData: ClassTalkListInterface = {
      //                 talkId: talkId,
      //                 talkType: 'event',
      //                 talkTitle: talkTitle,
      //                 sendTime: sendTime,
      //                 isRead: true,
      //                 eventInfo: {
      //                     senderId: targetInfo.id,
      //                     senderName: targetInfo.name,
      //                     targetInfo: receiverList,
      //                     eventId: talkId,
      //                     type: eventType,
      //                     str: message,
      //                     fileList: fileList
      //                 }
      //             };
      //             classTalkDataList.push(talkListData);
      //         }
      //
      //     }
      // }


      // 기존 수업톡 초기화 및 신규 수업톡으로 대체
      // sendTime 기준으로 최신 순서로 정렬
      classTalkDataList.sort((a, b) => {
        const [aHours, aMinutes] = a.sendTime.split(':').map(Number);
        const [bHours, bMinutes] = b.sendTime.split(':').map(Number);

        // 시간을 비교, 먼저 시간 비교하고 시간이 같으면 분 비교
        if (aHours !== bHours) {
          return bHours - aHours; // 최신 시간이 앞으로 오도록 정렬
        }
        return bMinutes - aMinutes; // 같은 시간일 경우 분으로 비교
      });

      setClassTalkList(classTalkDataList);

      setTempMirrorEventData(prev => [...prev, ...classTempMirrorDataList]);
      setTempEventData(prev => [...prev, ...classTempEventDataList]);

      return true;
    }
    else if (apiRes.code !== 'OK') {
      setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
    }
    return false;
  };

  /*
   * 교실상태 갱신 Api
   *  - Mqtt 연결플래그가 true 일 경우 동작
   */
  const findLectureInformation = async (): Promise<boolean> => {
    const apiRes = await getLectureState({ lectureId: classInfo?.lectureId!, token: sessionTokenInfo.coreAccessToken! });
    if (apiRes.code === 'OK' && apiRes.data) {

      const lectureInfo = apiRes.data;

      setGroupMode({ groupMode: lectureInfo.groupMode, groupInfo: lectureInfo.groupInfo });

      if (sessionBaseInfo?.baseInfo.role === 'teacher') {

        setMirrorManagement(prev => ({ ...prev, mirrorInfo: lectureInfo.mirrorInfo }));

        // 유저정보 갱신
        setUsersInfo(lectureInfo.usersInfo.map(user => ({ ...user, isLocked: user.locked, imageUrl: usersInfo.find((u: any) => u.id === user.id)?.imageUrl ?? '' })));

      }
      else if (sessionBaseInfo?.baseInfo.role === 'student') {
        // 유저정보 가져와서 화면잠금부분 처리
        const myInfo = lectureInfo.usersInfo.find(user => user.id === sessionBaseInfo.baseInfo.userId);
        if (myInfo) {
          if (lockState !== myInfo.locked) {
            setLockState(myInfo.locked);
            window.MirrorSetLock(myInfo.locked, publicMethod.getRemainingSeconds(lectureInfo.checkOutTime));
          }
        }
      }

      if (classInfo !== null) {
        setClassInfo(({ ...classInfo, checkOutTime: lectureInfo.checkOutTime }));
      }

      return true;
    } else if (apiRes.code !== 'OK') {
      setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
    }
    return false;
  };

  /*
   * 관리 미러링 임시정보 갱신 Api
   *  - Mqtt 연결플래그가 true 일 경우 동작
   */
  const findMirrorManagementInformation = async (): Promise<boolean> => {
    const apiRes = await findLectureMirrorList({ token: sessionTokenInfo.coreAccessToken!, lectureId: classInfo?.lectureId! });
    if (apiRes.code === 'OK' && apiRes.data) {

      setMirrorManagement((prev) => ({ ...prev, mirrorTempInfo: apiRes.data }));
      for (const m of mirrorManageMessage) {
        if (m.mirrorUsers.length < 1) {
          // mirrorStopCommand(m.webRtcRoomId);
          mirrorStopCommand(m.roomNumber);
        }
      }

      return true;
    }
    return false;
  };

  /*
   * 실제 파일요청 로직
   *  - 파일요청
   *  - 파일업로드
   *  - 내문서함 업데이트
   */
  const fileRequestLogic = async (request: { type: 'file'; myId: string; myRole: 'teacher' | 'student'; permission: boolean; receiver: MessageReceiver[], file: File[] }): Promise<boolean> => {

    let logicSuccess: boolean = false;

    if (classInfo) {

      const fileMessage: { fileName: string; fileSize: number; }[] = [];

      request.file.map(file => {
        fileMessage.push({
          fileName: file.name,
          fileSize: file.size
        });
      });

      /* 요청이 필요한 경우 */
      if (request.permission) {

        const target = usersInfo.find(u => u.division === 'teacher' && u.checkIn);

        if (target) {

          const apiReq: sendPermissionFunctionReq = {
            token: sessionTokenInfo.coreAccessToken!,
            lectureId: classInfo.lectureId,
            talkType: 'FILE',
            requestUserId: request.myId,
            requestDivision: request.myRole,
            permissionNeed: true,
            permissionUserId: target.id,
            permissionDivision: 'teacher',
            message: JSON.stringify(fileMessage),
            rcvrList: request.receiver
          };

          const apiRes = await sendPermission(apiReq);
          if (apiRes.code === 'OK' && apiRes.data) {

            const talkId = apiRes.data;

            const tempEventData: TempEventDataInterface = {
              talkId: talkId,
              lectureId: classInfo.lectureId,
              eventType: 'FILE',
              fileList: request.file,
              receiverList: request.receiver.map(r => {
                return {
                  id: r.userId,
                  name: r.name,
                  division: r.division
                };
              })
            };
            setTempEventData(prev => [...prev, tempEventData]);
            logicSuccess = true;
          } else if (apiRes.code !== 'OK') {
            setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
          }

        }

      }
      /* 요청이 필요하지 않은 경우 */
      else {

        const apiReq: sendPermissionFunctionReq = {
          token: sessionTokenInfo.coreAccessToken!,
          lectureId: classInfo.lectureId,
          talkType: 'FILE',
          requestUserId: request.myId,
          requestDivision: request.myRole,
          permissionNeed: false,
          message: JSON.stringify(fileMessage),
          rcvrList: request.receiver
        };
        const apiRes = await sendPermission(apiReq);
        if (apiRes.code === 'OK' && apiRes.data) {

          const talkId = apiRes.data;

          const uploadedFiles: ClassFileMessage[] = await storageUploadFiles(request.file);

          if (uploadedFiles.length > 0) {
            const updateRes = await updateTalkFileInfo({ token: sessionTokenInfo.coreAccessToken!, talkId: talkId, attachInfoList: uploadedFiles });
            if (updateRes.code === 'OK') {

              const logList: LogFunctionInterface[] = [];

              for (const f of uploadedFiles) {

                const receiverGroupId = `${classInfo.lectureId}-${sessionBaseInfo?.baseInfo.userId}-${publicMethod.generateRandomString()}-${Date.now().toString()}`;

                for (const t of request.receiver) {

                  const target = usersInfo.find(u => u.id === t.userId);

                  if (target) {
                    const logPushData = {
                      messageType: 'file',
                      receiverGroupId: receiverGroupId,
                      receiverId: target.id,
                      receiverRoomId: target.roomId[0],
                      messageInfo: '파일공유'
                    };
                    logList.push({
                      ...logPushData,
                      fileURL: f.filePath,
                      fileSize: f.fileSize,
                      fileOriginalName: f.fileName,
                      fileExtension: f.fileType
                    });
                  }

                }
              }

              myBoxUpdate(logList);

              logicSuccess = true;
            } else {
              if (updateRes.code !== 'OK') {
                setPopupError(prevData => [...prevData, { CODE: updateRes.errorCode, MESSAGE: updateRes.errorMessage }]);
              }
              deleteErrorTalk({ token: sessionTokenInfo.coreAccessToken!, talkId: talkId });
            }
          } else {
            deleteErrorTalk({ token: sessionTokenInfo.coreAccessToken!, talkId: talkId });
          }

        } else if (apiRes.code !== 'OK') {
          setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
        }
      }


    }
    return logicSuccess;
  };

  /*
   * 실제 메세지/링크 요청 로직
   *  - 메세지/링크 요청
   *  - 내문서함 업데이트
   */
  const stringRequestLogic = async (request: { type: 'link' | 'msg'; myId: string; myRole: 'teacher' | 'student'; permission: boolean; receiver: MessageReceiver[], message: string }): Promise<boolean> => {

    let logicSuccess: boolean = false;

    if (classInfo) {

      /* 요청이 필요한 경우 */
      if (request.permission) {

        const target = usersInfo.find(u => u.division === 'teacher' && u.checkIn);

        if (target) {

          const apiReq: sendPermissionFunctionReq = {
            token: sessionTokenInfo.coreAccessToken!,
            lectureId: classInfo.lectureId,
            talkType: 'LINK',
            requestUserId: request.myId,
            requestDivision: request.myRole,
            permissionNeed: true,
            permissionUserId: target.id,
            permissionDivision: 'teacher',
            message: request.message,
            rcvrList: request.receiver
          };

          const apiRes = await sendPermission(apiReq);
          if (apiRes.code === 'OK' && apiRes.data) {
            const talkId = apiRes.data;

            const tempEventData: TempEventDataInterface = {
              talkId: talkId,
              lectureId: classInfo.lectureId,
              eventType: 'LINK',
              linkMessage: request.message,
              receiverList: request.receiver.map(r => {
                return {
                  id: r.userId,
                  name: r.name,
                  division: r.division
                };
              })
            };
            setTempEventData(prev => [...prev, tempEventData]);
            logicSuccess = true;
          } else if (apiRes.code !== 'OK') {
            setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
          }

        }

      }
      /* 요청이 필요하지 않은 경우 */
      else {

        const apiReq: sendPermissionFunctionReq = {
          token: sessionTokenInfo.coreAccessToken!,
          lectureId: classInfo.lectureId,
          talkType: request.type === 'link' ? 'LINK' : 'MESSAGE',
          requestUserId: request.myId,
          requestDivision: request.myRole,
          permissionNeed: false,
          message: request.message,
          rcvrList: request.receiver
        };
        const apiRes = await sendPermission(apiReq);
        if (apiRes.code === 'OK' && apiRes.data) {

          logicSuccess = true;

          const logList: LogFunctionInterface[] = [];

          const receiverGroupId = `${classInfo!.lectureId}-${sessionBaseInfo?.baseInfo.userId}-${publicMethod.generateRandomString()}-${Date.now().toString()}`;

          for (const t of request.receiver) {

            /* 학생이 전자칠판에 다이렉트 전송을 할 경우 선생님이 관리하는 전자칠판의 방에 먼저 전송 */
            const target = usersInfo.find(u => u.id === t.userId);

            if (target) {

              const logPushData = {
                messageType: request.type,
                receiverGroupId: receiverGroupId,
                receiverId: target.id,
                receiverRoomId: target.roomId[0],
                messageInfo: request.message
              };

              logList.push(logPushData);



            }

          }
          myBoxUpdate(logList);

        } else if (apiRes.code !== 'OK') {
          setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
        }
      }
    }
    return logicSuccess;
  };

  /*
   * 요청에 대한 반환이 온 경우 처리
   * - 파일 요청에대한 허가가 반환이 온 경우
   *  - 수락일 경우
   *      1. Utility 업로드
   *      2. 수업톡 업데이트
   *      3. 내문서함 저장
   *      4. 임시저장 제거
   *  - 거절일 경우
   *      1. 임시저장 제거
   *
   * - 링크 요청에대한 허가가 반환이 온 경우
   *  - 수락일 경우
   *      1. 내문서함 저장
   *      2. 임시저장 제거
   *  - 거절일 경우
   *      1. 임시저장 제거
   */
  const permissionResponseLogic = async (request: { permission: 'APPROVED' | 'REJECTED'; talkId: string; mirrorId?: string; senderId?: string; }) => {
    if (classInfo) {
      const targetTempEvent = tempEventData.find(t => t.talkId === request.talkId);

      if (targetTempEvent) {

        if (targetTempEvent.eventType !== 'MIRROR_OPEN') {

          console.log('eventmsg permissionResponseLogic: ' + JSON.stringify(request));

          setTempEventData(prev => prev.filter(t => t.talkId !== request.talkId));
        }

        if (targetTempEvent.eventType === 'FILE' && targetTempEvent.fileList) {

          if (request.permission === 'APPROVED') {
            const uploadedFiles: ClassFileMessage[] = await storageUploadFiles(targetTempEvent.fileList);

            if (uploadedFiles.length > 0) {
              const updateRes = await updateTalkFileInfo({ token: sessionTokenInfo.coreAccessToken!, talkId: request.talkId, attachInfoList: uploadedFiles });
              if (updateRes.code === 'OK') {

                /* 수업톡 처리 fileResponse */
                const talkId = targetTempEvent.talkId;
                const talkTitle = 'file';
                const senderInfo = usersInfo.find(u => u.division === 'teacher' && u.checkIn);
                const targetInfo = targetTempEvent.receiverList;
                const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];
                for (const f of uploadedFiles) {
                  fileList.push({
                    fileRealName: f.fileName,
                    fileUrl: f.filePath,
                    fileSize: f.fileSize
                  });
                }

                if (senderInfo) {
                  const talkListData: ClassTalkListInterface = {
                    talkId: talkId,
                    talkType: 'event',
                    talkTitle: talkTitle,
                    sendTime: publicMethod.getCurrentTime(),
                    isRead: false,
                    eventInfo: {
                      senderId: senderInfo.id,
                      senderName: senderInfo.name,
                      targetInfo: targetInfo,
                      eventId: talkId,
                      type: 'fileResponse',
                      str: 'Y',
                      fileList: fileList
                    }
                  };
                  setClassTalkList(prev => [...prev, talkListData]);
                }

                if (request.permission) {
                  const logList: LogFunctionInterface[] = [];

                  for (const f of uploadedFiles) {

                    const receiverGroupId = `${classInfo!.lectureId}-${sessionBaseInfo?.baseInfo.userId}-${publicMethod.generateRandomString()}-${Date.now().toString()}`;

                    for (const t of targetTempEvent.receiverList!) {
                      const target = usersInfo.find(u => u.id === t.id);
                      if (target) {
                        const logPushData = {
                          messageType: 'file',
                          receiverGroupId: receiverGroupId,
                          receiverId: target.id,
                          receiverRoomId: target.roomId[0],
                          messageInfo: '파일공유'
                        };
                        logList.push({
                          ...logPushData,
                          fileURL: f.filePath,
                          fileSize: f.fileSize,
                          fileOriginalName: f.fileName,
                          fileExtension: f.fileType
                        });
                      }

                    }
                  }

                  myBoxUpdate(logList);
                }


              }
              else {
                if (updateRes.code !== 'OK') {
                  setPopupError(prevData => [...prevData, { CODE: updateRes.errorCode, MESSAGE: updateRes.errorMessage }]);
                }
                deleteErrorTalk({ token: sessionTokenInfo.coreAccessToken!, talkId: request.talkId });
              }
            }
            else {
              deleteErrorTalk({ token: sessionTokenInfo.coreAccessToken!, talkId: request.talkId });
            }
          } else {
            /* 수업톡 처리 fileResponse */


            const talkId = targetTempEvent.talkId;
            const talkTitle = 'file';
            const senderInfo = usersInfo.find(u => u.division === 'teacher' && u.checkIn);
            const targetInfo = targetTempEvent.receiverList;
            const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];

            if (senderInfo) {
              const talkListData: ClassTalkListInterface = {
                talkId: talkId,
                talkType: 'event',
                talkTitle: talkTitle,
                sendTime: publicMethod.getCurrentTime(),
                isRead: false,
                eventInfo: {
                  senderId: senderInfo.id,
                  senderName: senderInfo.name,
                  targetInfo: targetInfo,
                  eventId: talkId,
                  type: 'fileResponse',
                  str: 'N',
                  fileList: fileList
                }
              };
              setClassTalkList(prev => [...prev, talkListData]);
            }
          }



        }
        else if (targetTempEvent.eventType === 'LINK' && targetTempEvent.linkMessage) {

          /* 수업톡 처리 fileResponse */
          const talkId = targetTempEvent.talkId;
          const talkTitle = 'link';
          const senderInfo = usersInfo.find(u => u.division === 'teacher');
          const targetInfo = targetTempEvent.receiverList;
          const fileList: { fileRealName: string; fileUrl: string; fileSize: number; }[] = [];

          if (senderInfo) {
            const talkListData: ClassTalkListInterface = {
              talkId: talkId,
              talkType: 'event',
              talkTitle: talkTitle,
              sendTime: publicMethod.getCurrentTime(),
              isRead: false,
              eventInfo: {
                senderId: senderInfo.id,
                senderName: senderInfo.name,
                targetInfo: targetInfo,
                eventId: talkId,
                type: 'linkResponse',
                str: request.permission === 'APPROVED' ? 'Y' : 'N',
                fileList: fileList
              }
            };

            setClassTalkList(prev => [...prev, talkListData]);
          }

          if (request.permission === 'APPROVED') {
            const logList: LogFunctionInterface[] = [];

            const receiverGroupId = `${classInfo!.lectureId}-${sessionBaseInfo?.baseInfo.userId}-${publicMethod.generateRandomString()}-${Date.now().toString()}`;

            for (const t of targetTempEvent.receiverList!) {

              const target = usersInfo.find(u => u.id === t.id);

              if (target) {

                const logPushData = {
                  messageType: 'link',
                  receiverGroupId: receiverGroupId,
                  receiverId: target.id,
                  receiverRoomId: target.roomId[0],
                  messageInfo: targetTempEvent.linkMessage
                };

                logList.push(logPushData);

              }

            }
            myBoxUpdate(logList);
          }

        }
        else if (targetTempEvent.eventType === 'MIRROR_OPEN') {
          /* 내가 미러링 오픈요청한것에 대한 반환이 온 경우, 요청을 했다는것은 학생이 했다는것임 */
          const talkId = targetTempEvent.talkId;
          const talkTitle = 'mirror';
          const senderInfo = usersInfo.find(u => u.division === 'teacher' && u.checkIn);
          const pubInfo = targetTempEvent.tempMirrorOpenInformation?.pubs;
          const targetInfo = targetTempEvent.tempMirrorOpenInformation?.subs;

          if (senderInfo) {
            const talkListData: ClassTalkListInterface = {
              talkId: talkId,
              talkType: 'event',
              talkTitle: talkTitle,
              sendTime: publicMethod.getCurrentTime(),
              isRead: false,
              eventInfo: {
                senderId: senderInfo.id,
                senderName: senderInfo.name,
                targetInfo: targetInfo,
                eventId: talkId,
                type: 'mirrorResponse',
                str: request.permission === 'APPROVED' ? 'Y' : 'N',
                pubInfo: pubInfo ? pubInfo[0] : { id: '', name: '', division: '' }
              }
            };
            setClassTalkList(prev => [...prev, talkListData]);
          }
        }
      }
      else if (request.mirrorId) {

        /*
 * 해당 메세지가 미러링 조인에 대한 거절일 경우
 *  - mirrorId 를 통해 OPEN 요청내역쪽에서 찾아서 해당유저를 제거
 *  - 만일 OPEN 요청내역에서 PUB 또는 SUB 전부 제거됬다면 해당 미러링 방 제거 요청
 */

        const targetMirrorOpen = tempEventData.find(t => t.tempMirrorOpenInformation?.mirrorId === request.mirrorId);

        console.log('eventmsg targetMirrorOpen: ' + JSON.stringify(targetMirrorOpen));

        if (targetMirrorOpen) {
          const subInfo = targetMirrorOpen.tempMirrorOpenInformation?.subs.map(u => {
            return {
              id: u.id,
              name: u.name,
              division: u.division
            };
          });
          const pubInfo: { id: string; name: string; division: 'teacher' | 'student' | 'onequick' | 'board' }[] | undefined = targetMirrorOpen.tempMirrorOpenInformation?.pubs.map(u => {
            return {
              id: u.id,
              name: u.name,
              division: u.division
            };
          });
          const senderInfo = usersInfo.find(u => u.id === request.senderId);

          if (subInfo !== undefined && pubInfo !== undefined && senderInfo) {
            const talkListData: ClassTalkListInterface = {
              talkId: request.talkId,
              talkType: 'event',
              talkTitle: 'mirror',
              sendTime: publicMethod.getCurrentTime(),
              isRead: false,
              eventInfo: {
                senderId: senderInfo.id,
                senderName: senderInfo.name,
                targetInfo: subInfo,
                eventId: 'mirrorResponse',
                type: 'mirrorResponse',
                str: 'N',
                pubInfo: pubInfo[0]
              }
            };
            setClassTalkList(prev => [...prev, talkListData]);
          }

        }
      } else {
        // 해당 임시저장정보가 없는 경우 에러로 판단하여 서버에 삭제요청 전송
        deleteErrorTalk({ token: sessionTokenInfo.coreAccessToken!, talkId: request.talkId });
      }

    }
  };

  /*
   * 파일전송 함수
   *  1. 파일목록을 받아서 하나씩 업로드 처리를 진행한다.
   *  2. 전체가 진행됬다면 파일정보를 Return 처리한다.
   */
  const storageUploadFiles = async (file: File[]): Promise<ClassFileMessage[]> => {

    const fileList: ClassFileMessage[] = [];

    const fileCheck = await hashFilter({ files: file });

    if (fileCheck.code === 'OK' && classInfo?.myRoomId) {
      for (let f of file) {

        const apiReq: ufFunctionReq = {
          token: sessionTokenInfo.chatAccessToken!,
          roomId: classInfo.myRoomId[0],
          myId: sessionBaseInfo?.baseInfo.userId!,
          file: f
        };

        let apiRes: ufFunctionRes = await uploadFile(apiReq);

        if (apiRes.message === 'REISSUE') {

          const reissueApiReq: rsFunctionReq = { accessToken: sessionTokenInfo.chatAccessToken!, refreshToken: sessionTokenInfo.chatRefreshToken! };
          const reissueApiRes: rsFunctionRes = await reissue(reissueApiReq);

          if (reissueApiRes.message === 'SUCCESS') {

            setUserTokenInfo({
              coreAccessToken: sessionTokenInfo.coreAccessToken,
              chatAccessToken: reissueApiRes.accessToken,
              chatRefreshToken: reissueApiRes.refreshToken
            });

            apiReq.token = reissueApiRes.accessToken!;
            apiRes = await uploadFile(apiReq);

          }
          else {
            classCheckOut();
            setPopupError(prevData => [...prevData, { CODE: 'U9999', MESSAGE: 'Connection to the server has been lost.' }]);
            return [];
          }

        }

        if (apiRes.message === 'SUCCESS') {
          fileList.push({
            filePath: apiRes.fileUrl!,
            fileType: apiRes.fileType!,
            fileName: apiRes.fileRealName!,
            fileSize: apiRes.fileSize!
          });
        }
      }
      return fileList;
    }
    else {
      return [];
    }
  };

  /*
   * 판서 업로드 함수
   */
  const uploadOpsFile = async (file: File) => {

    const target = usersInfo.find(m => m.division === 'teacher' && m.checkIn);

    let logList = [];

    if (target) {
      const receiverGroupId = `${classInfo!.lectureId}-${sessionBaseInfo?.baseInfo.userId}-receiverGroup-${Date.now().toString()}`;

      const apiReq: umFunctionReq = {
        token: sessionTokenInfo.chatAccessToken!,
        roomId: target.roomId[1],
        myId: sessionBaseInfo?.baseInfo.userId!,
        file: file
      };

      let apiRes: umFunctionRes = await uploadMonitor(apiReq);

      if (apiRes.message === 'REISSUE') {

        const reissueApiRes: rsFunctionRes = await reissue({ accessToken: sessionTokenInfo.chatAccessToken!, refreshToken: sessionTokenInfo.chatRefreshToken! });

        if (reissueApiRes.message === 'SUCCESS') {

          setUserTokenInfo({
            coreAccessToken: sessionTokenInfo.coreAccessToken,
            chatAccessToken: reissueApiRes.accessToken,
            chatRefreshToken: reissueApiRes.refreshToken
          });

          apiReq.token = reissueApiRes.accessToken!;
          apiRes = await uploadMonitor(apiReq);

        }
      }

      logList.push({
        messageType: 'file',
        receiverGroupId: receiverGroupId,
        receiverId: target.id,
        receiverRoomId: target.roomId[1],
        fileURL: apiRes.fileUrl!,
        fileSize: apiRes.fileSize!,
        fileOriginalName: apiRes.fileRealName!,
        fileExtension: apiRes.fileType!
      });

      myBoxUpdate(logList);
    }



  };

  /*
   * 모니터링 이미지 업로드 함수
   *  1. 모니터링 이미지를 수업 모니터링용 채팅방에 업로드 처리한다.
   */
  const uploadMonitorImage = async (file: File) => {

    const apiReq: umFunctionReq = {
      token: sessionTokenInfo.chatAccessToken!,
      roomId: classInfo?.chatRoomIds[0]!,
      myId: sessionBaseInfo?.baseInfo.userId!,
      file: file
    };

    let apiRes: umFunctionRes = await uploadMonitor(apiReq);

    if (apiRes.message === 'REISSUE') {

      const reissueApiRes: rsFunctionRes = await reissue({ accessToken: sessionTokenInfo.chatAccessToken!, refreshToken: sessionTokenInfo.chatRefreshToken! });

      if (reissueApiRes.message === 'SUCCESS') {

        setUserTokenInfo({
          coreAccessToken: sessionTokenInfo.coreAccessToken,
          chatAccessToken: reissueApiRes.accessToken,
          chatRefreshToken: reissueApiRes.refreshToken
        });

        apiReq.token = reissueApiRes.accessToken!;
        apiRes = await uploadMonitor(apiReq);

      }
      else {
        classCheckOut();
        setForceLogout({ force: true, reason: 'token' });
        return false;
      }

    }

    return apiRes.message === 'SUCCESS';

  };

  /*
   * 확대이미지 업로드 함수
   *  1. 선생님에게 상세 이미지요청이 온 경우 본인 채팅방에 업로드를 통해 이미지를 서버에 올린다.
   *  2. 선생님에게 상세 이미지를 Return 한다.
   */
  const uploadDetailMonitorImage = async (senderId: string) => {

    const mirrorGetImageBase100 = `${window.MirrorGetImageBase100()}`;

    const apiReq: umFunctionReq = {
      token: sessionTokenInfo.chatAccessToken!,
      roomId: classInfo?.chatRoomIds[0]!,
      myId: sessionBaseInfo?.baseInfo.userId!,
      file: base64ToFile(mirrorGetImageBase100, 'capture.jpeg', 'image/jpeg')
    };

    let apiRes = await uploadFile(apiReq);

    if (apiRes.message === 'SUCCESS' && apiRes.fileUrl) {
      const messageFormat: MqttBypassMessageInterface = {
        type: 'MONITOR_RESPONSE',
        senderId: sessionBaseInfo?.baseInfo.userId!,
        bypassData: apiRes.fileUrl
      };

      const messageJson: string = JSON.stringify(messageFormat);

      userBypassMessage({ token: sessionTokenInfo.coreAccessToken!, topic: `bypass-topic/lecture/${classInfo?.lectureId}/${senderId}`, message: messageJson });

    }
    else {
      const messageFormat: MqttBypassMessageInterface = {
        type: 'MONITOR_RESPONSE',
        senderId: sessionBaseInfo?.baseInfo.userId!,
        bypassData: 'FAIL'
      };

      const messageJson: string = JSON.stringify(messageFormat);

      userBypassMessage({ token: sessionTokenInfo.coreAccessToken!, topic: `bypass-topic/lecture/${classInfo?.lectureId}/${senderId}`, message: messageJson });

    }

  };

  /*
   * 업로드된 상세 이미지를 다운로드 하여 UI 에 셋팅하는 함수
   */
  const setUIDetailMonitorImage = async (url: string) => {
    const imageUrl = await setMonitorImageUrl(url);
    if (imageUrl) {
      setDetailMonitorImage((prev) => {
        if (prev !== null) {
          return { ...prev, imageUrl: imageUrl };
        } else {
          return null; // or handle the null case accordingly
        }
      });
    } else {
      setDetailMonitorImage(null);
    }
  };

  /*
   * 내문서함 저장 함수
   *  - 내문서함 저장 Api Request 폼에 맞게 데이터를 가공하여 Api 를 호출한다.
   */
  const myBoxUpdate = async (request: LogFunctionInterface[]) => {

    let myInfo = usersInfo.find(u => u.id === sessionBaseInfo?.baseInfo.userId);
    if (myInfo && sessionBaseInfo && classInfo) {

      const apiReq: luFunctionReq = {
        token: sessionTokenInfo.coreAccessToken!,
        list: []
      };
      for (const i of request) {

        const targetInfo = usersInfo.find(u => u.id === i.receiverId);

        if (targetInfo) {
          const newUpdate: FunctionReqData = {
            schoolId: sessionBaseInfo?.baseInfo?.schoolId,
            classId: classInfo.classId,
            messageType: i.messageType,
            senderId: myInfo.id,
            senderRoomId: myInfo.roomId[0],
            senderRole: myInfo.division,
            senderName: myInfo.name,
            senderNumber: myInfo.number ?? '',
            senderGrade: classInfo.classGrade,
            senderClass: classInfo.classNumber,
            senderSubject: classInfo.subject ?? 'Non-Class',
            receiverRoomId: i.receiverRoomId,
            receiverRole: targetInfo.division,
            receiverName: targetInfo.name,
            receiverNumber: targetInfo.number ?? '',
            receiverGrade: classInfo.classGrade,
            receiverClass: classInfo.classNumber,
            receiverSubject: classInfo.subject ?? 'Non-Class',
            receiverGroupId: i.receiverGroupId,
            receiverId: i.receiverId,
            storageMethod: 'Shared'
          };
          if (i.messageType === 'file') {
            newUpdate.fileUrl = i.fileURL;
            newUpdate.fileSize = i.fileSize;
            newUpdate.fileOriginalName = i.fileOriginalName;
            newUpdate.fileExtension = i.fileExtension;
          }
          else {
            newUpdate.messageValue = i.messageInfo;
          }
          apiReq.list.push(newUpdate);
        }


      }

      if (apiReq.list.length > 0) {
        const updateLogRes = await updateMessages(apiReq);


        if (updateLogRes.message === 'SUCCESS') {
          // 내문서함 업데이트 성공
          if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
            console.log('내문서함 업데이트 성공');
          }
        }
        else {
          // 서버 에러 팝업
          setPopupError(prevData => [...prevData, { CODE: updateLogRes.errorCode, MESSAGE: updateLogRes.errorMessage }]);
        }


        setLoginSessionCount(publicMethod.sessionUpdate);
      }

    }


  };

  /*
   * Worker 함수
   */
  const startWorker = (command: string, key: string, time: number, onMessage: (e: MessageEvent) => void, onError: () => void): Worker => {
    let worker = new SchedulerWorker();
    let retryCount = 0;
    let baseDelay = 1000;

    const setupWorker = () => {
      worker.postMessage(JSON.stringify({ command, key, time }));

      worker.onmessage = onMessage;

      worker.onerror = (err: ErrorEvent) => {
        console.error('Worker error: ', err);
        onError();

        const delay = baseDelay * Math.pow(2, retryCount);
        retryCount += 1;

        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`Retrying in ${delay / 1000} seconds...`);
        }
        setTimeout(() => {
          worker.terminate();
          worker = new SchedulerWorker();
          setupWorker();
        }, delay);
      };
    };

    setupWorker();

    return worker;
  };

  /*
   * Blended publish stream lecture id 생성 함수
   */
  const makeBlendedLectureId = async (recordTitle: string): Promise<string | null> => {

    if (!sessionBaseInfo?.schoolInfo) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : NONE SCHOOL INFO');
      }
      return null;
    }

    if (sessionBaseInfo?.baseInfo.role !== 'teacher') {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : NONE TEACHER ROLE');
      }
      return null;
    }

    if (blendedState.recordLectureId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : HAVE LECTURE ID');
      }
      return null;
    }

    if (!blendedState.deviceId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : NONE DEVICE ID');
      }
      return null;
    }

    if (!classInfo?.mediaBoxIp) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : NONE MediaBoxIp');
      }
      return null;
    }

    const res = await saveRecordInfo({
      token: sessionTokenInfo.coreAccessToken!,
      title: recordTitle,
      note: 'Teacher start record',
      subjectName: classInfo.subject || 'Non-Class',
      schoolId: sessionBaseInfo.schoolInfo.schoolId,
      teacherId: sessionBaseInfo.baseInfo.userId,
      grade: classInfo.classGrade
    });
    if (res.code === 'OK' && res.data) {
      console.log('res.data.id', res.data.id);
      return res.data.id.toString();
    }
    else {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED MAKE LECTURE ID FAIL : CONTENT API FAIL');
      }
      return null;
    }
  };

  /*
   * Blended record lectureId 삭제요청 함수
   */
  const deleteLectureId = (lectureId: string) => {
    changeRecordStatus({ token: sessionTokenInfo.coreAccessToken!, id: Number(lectureId), recordStatus: 'DELETED' });
  };

  /*
   * 구루미 방생성시 LectureId 를 대체하기 위한 문자열 생성함수
   */
  const generateRandomString = (): string => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const MIN_LENGTH = 10;
    const MAX_LENGTH_VARIATION = 5;

    const array = new Uint32Array(1);
    crypto.getRandomValues(array);

    const length = (array[0] % MAX_LENGTH_VARIATION) + MIN_LENGTH; // Secure random length between 10 and 14
    let randomString = '';

    for (let i = 0; i < length; i++) {
      const randomIndexArray = new Uint32Array(1);
      crypto.getRandomValues(randomIndexArray);
      const randomIndex = randomIndexArray[0] % characters.length; // Secure random index
      randomString += characters[randomIndex];
    }

    return randomString;
  };

  /*
   * publish stream
   */
  const setPublishStream = async (request: { lectureId: string; conference: boolean; record: boolean; roomId?: string; }): Promise<boolean> => {

    if (!classInfo?.mediaBoxIp) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PUBLISH FAIL : NONE MediaBoxIp');
      }
      return false;
    }

    if (!blendedState.deviceId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PUBLISH FAIL : NONE DEVICE ID');
      }
      return false;
    }

    if ((request.conference && !request.roomId) || (!request.conference && !request.record)) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PUBLISH FAIL : NONE GOOROOMEE ROOM ID');
      }
      return false;
    }

    const apiReq: any = {
      mediaBoxIp: classInfo.mediaBoxIp,
      lectureId: request.lectureId,
      record: request.record,
      conference: request.conference,
      duration: publicMethod.getRemainingSeconds(classInfo.checkOutTime),
      pingTimeout: 60000
    };
    if (request.roomId) {
      apiReq.roomId = request.roomId;
    }
    const apiRes = await publishStream(apiReq);

    if (apiRes.message === 'SUCCESS') {
      return true;
    } else {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED PUBLISH FAIL : MEDIABOX API FAIL');
      }
      return false;
    }
  };

  /*
   * unpublish stream
   */
  const setUnPublishStream = async (): Promise<boolean> => {

    if (!classInfo?.mediaBoxIp) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED UNPUBLISH FAIL : NONE MediaBoxIp');
      }
      return false;
    }

    if (!blendedState.deviceId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED UNPUBLISH FAIL : NONE DEVICE ID');
      }
      return false;
    }

    const apiRes = await unPublishStream({ mediaBoxIp: classInfo.mediaBoxIp });
    if (apiRes.message === 'SUCCESS') {
      return true;
    } else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('LINK SCHOOL - BLENDED UNPUBLISH FAIL : MEDIABOX API FAIL');
    }

    return false;
  };

  /*
   * change stream
   */
  const setChangeStream = async (request: { lectureId: string; conference: boolean; record: boolean; roomId?: string }): Promise<boolean> => {

    if (!classInfo?.mediaBoxIp) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED CHANGE PUBLISH FAIL : NONE MediaBoxIp');
      }
      return false;
    }

    if (!blendedState.deviceId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED CHANGE PUBLISH FAIL : NONE DEVICE ID');
      }
      return false;
    }

    if (!blendedState.recordLectureId) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL - BLENDED CHANGE PUBLISH FAIL : NONE LECTURE ID');
      }
      return false;
    }

    const apiReq: any = {
      mediaBoxIp: classInfo.mediaBoxIp,
      lectureId: request.lectureId,
      record: request.record,
      conference: request.conference
    };
    if (request.roomId) {
      apiReq.roomId = request.roomId;
    }
    const apiRes = await changeStream(apiReq);

    if (apiRes.message === 'SUCCESS') {
      return true;
    } else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('LINK SCHOOL - BLENDED CHANGE STREAM FAIL : MEDIABOX API FAIL');
    }

    return false;
  };

  /*
   * 로직 실패로 인한 미러링 Open 거절처리
   */
  const selfMirrorOpenReturn = async (request: { mirrorId: string; }) => {

    const target = tempMirrorEventData.find(t => t.mirrorId === request.mirrorId);

    if (target) {
      setTempMirrorEventData(tempMirrorEventData.filter(t => t.mirrorId !== request.mirrorId));

      const res = await returnOpenPermission({ permission: 'REJECTED', mirrorId: request.mirrorId, token: sessionTokenInfo.coreAccessToken!, self: true });
      if (res.code !== 'OK') {
        setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
      }
      return res.code === 'OK';
    }

  };






  /*
   * [UI 호출] - 체크인 함수
   *  - 연결해야할 각 서버에 연결을 처리하며, 연결 성공 실패 여부를 반환한다.
   *      1. 체크아웃 해야할 시간을 계산하여 잔여시간초를 셋팅한다.
   *      2. 채팅서버에 연결 요청 및 subscribe 메시지 처리 셋팅
   *      3. MQTT 이벤트서버에 연결 요청 및 subscribe 메시지 처리 셋팅
   *      4. 안드로이드 계열이 아니라면 서버 연결관리 데이터에 모니터링 연결성공 셋팅
   *      5. 미디어박스 연결 요청 및 subscribe 메시지 처리 셋팅
   *      6. classInfo, usersInfo 정보 셋팅 및 기존 클래스정보 동기화 처리
   */
  const classCheckIn = async (classInfo: ClassInfoInterface): Promise<boolean> => {

    /* 체크인 세션 저장 */
    const checkOutTime = classInfo.checkOutTime;
    const checkOutTimeSec = publicMethod.getRemainingSeconds(checkOutTime);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool LINK SCHOOL - Step0. 수업체크인');
      console.log(`LINK SCHOOL - Step0. 현재시간 : ${new Date()}`);
      console.log(`LINK SCHOOL - Step0. 체크아웃시간 : ${classInfo.checkOutTime}`);
      console.log(`LINK SCHOOL - Step0. 잔여시간초 : ${checkOutTimeSec}`);
    }
    setCheckInTimeInfo({
      classId: classInfo.classId,
      checkOutTime: checkOutTime,
      sec: checkOutTimeSec
    });

    /* 채팅서버 토큰저장 */
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool LINK SCHOOL - Step2. Utility Token 저장');
    }
    setUserTokenInfo({
      coreAccessToken: sessionTokenInfo.coreAccessToken,
      chatAccessToken: classInfo.chatroomToken.accessToken,
      chatRefreshToken: classInfo.chatroomToken.refreshToken
    });

    /* 웹소켓 연결 */
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool SCHOOL - Step3. 소켓연결 시작');
    }
    const webSocketFunctionReq: SocketConnectInterface = {
      token: classInfo.chatroomToken.accessToken,
      roomId: classInfo.chatRoomIds && classInfo.chatRoomIds.length > 0 ? classInfo.chatRoomIds[0] : '',
      setMonitorMessages: setMonitorMessages,
      setStateChangeFunction: checkInStateChange,
      userRole: sessionBaseInfo?.baseInfo.role!
    };
    const ws = await webSocketConnect(webSocketFunctionReq);
    if (ws) {
      setWebSocket(ws);
    }
    else {
      setCheckInTimeInfo(null);
      checkInStateChange('chat', false);
      setCheckInManagement((prev) => ({ ...prev, checkInState: false, chatConnect: false }));
      return false;
    }

    /* MQTT 이벤트서버 연결 */
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool SCHOOL - Step3. MQTT 서버 Connect & Subscribe');
    }
    const mqttConnectReq: MqttConnectRequest = {
      lectureId: classInfo.lectureId,
      clientId: sessionBaseInfo?.baseInfo.userId!,
      setReceiveMessages: setReceiveMessages,
      setMirrorOpenPermissionRequestMessages: setMirrorOpenRequestMessages,
      setMirrorJoinPermissionRequestMessages: setMirrorJoinRequestMessages,
      setPermissionRequestMessages: setPermissionRequestMessages,
      setPermissionResponseMessages: setPermissionResponseMessages,
      setBypassMessage: setBypassMessages,
      setClassMessages: setClassMessages,
      stateChangeFunction: checkInStateChange,
      mqttAccountInfo: sessionBaseInfo!.mqtt
    };
    if (sessionBaseInfo?.baseInfo.role === 'teacher') {
      mqttConnectReq.setMirrorManageMessage = setMirrorManageMessage;
    }
    const mqtt = mqttConnect(mqttConnectReq);


    /* 안드로이드 제외 ( 윈도우 계열 ) 은 모니터링 바로시작 */
    if (deviceInfo?.model !== 'Android' || `${process.env.REACT_APP_ENV}` === 'DEV' || `${process.env.REACT_APP_ENV}` === 'STAG') {
      setCheckInManagement(prev => ({ ...prev, monitorStart: true }));
    }

    /* 미디어박스 연결 */
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool SCHOOL - Step4. 내부망 체크인 or 모니터링시작');
    }
    const mb = mediaBoxConnect(classInfo);
    if (!mb) {
      webSocketDisconnect(ws);
      mqttDisconnect(mqtt);
      setMqttClient(null);
      setWebSocket(null);
      setCheckInTimeInfo(null);
      setCheckInManagement((prev) => ({ ...prev, checkInState: false }));
      return false;
    } else {
      setMqttClient(mqtt);
    }


    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('aschool SCHOOL - Step5. class/users 셋팅');
    }
    setClassInfo(classInfo);




    const usersInfoMap = classInfo.usersInfo.map(user => ({ id: user.id, name: user.name, division: user.division, isChecked: false, isCheckAble: false, ...(user.number && { number: user.number }) }));
    const usersInfo = classInfo.usersInfo.map(user => ({ ...user, isLocked: user.locked, imageUrl: '' }));
    if (classInfo.usersInfo) {
      setUsersInfoBox(usersInfoMap);
      setUsersInfo(usersInfo);
    }



    /* 선생님은 교실 내 상태를 본인 관리상태에 저장한다. */
    if (sessionBaseInfo?.baseInfo.role === 'teacher') {
      console.log('teacher checkin', classInfo);
      stopAllMirrorRoomCommand(classInfo.lectureId);
      /* 만약 현재 모둠모드가 본인이 가지고있지 않은 모둠모드일 경우 */
      if (classInfo.groupMode && classInfo.groupInfo) {
        console.log('내 모둠 확인', groupManagement, classInfo);
        const groups = groupManagement.find(gm => gm.classId === classInfo.classId);
        if (groups) {
          const findGroup = groups.groupList.find(gl => Number(gl.id) === Number(classInfo.groupInfo?.groupId));
          if (!findGroup) {
            // 1. 미러링 전부제거
            // 2. 기존 모둠모드 제거
            stopAllMirrorRoomCommand(classInfo.lectureId);
            classModeChange(false);
            classInfo.groupMode = false;
            classInfo.groupInfo = null;
          } else {
            setGroupMode({
              groupMode: classInfo.groupMode,
              groupInfo: classInfo.groupInfo
            });
          }
        }
        else {
          // 1. 미러링 전부제거
          // 2. 기존 모둠모드 제거
          stopAllMirrorRoomCommand(classInfo.lectureId);
          classModeChange(false);
          classInfo.groupMode = false;
          classInfo.groupInfo = null;
        }
      }


      /* 미러링 상태 동기화 */
      setMirrorManagement({ lectureId: classInfo.lectureId, mirrorInfo: classInfo.mirrorInfo ?? [], mirrorTempInfo: [] });
    }
    else if (sessionBaseInfo?.baseInfo.role === 'student') {
      /* 본인이 화면잠금 상태라면 화면잠금 처리를 진행한다. */
      const myInfo = classInfo.usersInfo.find(u => u.id === sessionBaseInfo.baseInfo.userId);
      if (myInfo?.locked) {
        setLockState(true);
        window.MirrorSetLock(myInfo.locked, publicMethod.getRemainingSeconds(classInfo.checkOutTime));
      }
      if (classInfo.groupMode && classInfo.groupInfo) {
        setGroupMode({
          groupMode: classInfo.groupMode,
          groupInfo: classInfo.groupInfo
        });
      }
    }

    const apiReq = {
      lectureId: classInfo.lectureId,
      userId: sessionBaseInfo?.baseInfo.userId!,
      mirrorPosition: 'NON',
      mirrorRoomNumber: null,
      token: sessionTokenInfo.coreAccessToken!
    };
    await userMirrorUpdate(apiReq);

    return true;

  };

  /*
   * [UI 호출] - 체크아웃 함수
   *  1. 선생님인 경우 내가 관리중인 미러링방 종료 Command 를 호출한다.
   *  2. 선생님 이외의 경우 본인이 pub 중인 미러링방 종료 Command 를 호출한다.
   *  3. 모니터링 종료 Command 를 호출한다.
   *  4. 미디어박스 회원등록 해제 Command 를 호출한다.
   *  5. 채팅서버 연결 웹소켓 Disconnect 처리 함수를 호출한다.
   *  6. 영상녹화, 스트리밍 중이라면 종료처리한다.
   *  7. 동기화함수 Interval 을 종료처리 한다.
   *  8. 체크인 내에서 관리중인 모든 데이터를 초기화처리 한다.
   */
  const classCheckOut = async (forceCheckOut?: boolean): Promise<boolean> => {

    if (classInfo) {

      const delayLogBody: LogicDelayLog = {
        type: 'check',
        device: localDeviceInfo?.osInfo ? localDeviceInfo.osInfo : 'UNKNOWN',
        code: 'CK_0004',
        description: 'Check-out',
        startAt: new Date().toISOString(),
        endAt: '',
        millisecond: '',
        message: '체크아웃 성공'
      };

      if (mqttClient) {
        mqttDisconnect(mqttClient);
      }

      const res = await statusCheckOut({ lectureId: classInfo.lectureId, token: sessionTokenInfo.coreAccessToken! });
      if (res.code !== 'OK' && !forceCheckOut) {
        setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
      }

      /* webrtc 종료 */
      if (classInfo.mediaBoxIp) {
        if (sessionBaseInfo?.baseInfo.role === 'teacher' && mirrorManagement.mirrorInfo && mirrorManagement.mirrorInfo.length > 0) {
          // 내가 관리중인 webrtc 가 있는지 확인하여 모든 방 파괴
          for (const m of mirrorManagement.mirrorInfo) {
            if (!m.groupMirror) {
              mirrorStopCommand(m.webRtcRoomId);
            }
          }
        }
        if (sessionBaseInfo?.baseInfo.role !== 'teacher' && myMirrorManagement && myMirrorManagement.position === 'pub') {
          // 본인이 pub 중인 방 파괴
          mirrorStopCommand(myMirrorManagement.webRtcRoomId);
        }
      }

      /* 모니터링 종료 */
      if (checkInManagement.monitorStart && classInfo.lectureId) {
        const mediaBoxCommand1: MediaBoxStartMonitor = {
          commandDivision: 'MONITOR_STOP',
          classroom: classInfo.lectureId,
          command: 'monitorstop',
          sender: sessionBaseInfo?.baseInfo.userId!,
          type: 'request'
        };
        mirrorCommand(mediaBoxCommand1);
      }

      /* 미디어박스 회원등록 해제 */
      if (classInfo.mediaBoxIp) {
        const mediaBoxCommand2: MediaBoxRegisterRequest = {
          commandDivision: 'UNREGISTER',
          classroom: classInfo.lectureId,
          command: 'unregister',
          role: sessionBaseInfo?.baseInfo.role!,
          sender: sessionBaseInfo?.baseInfo.userId!,
          type: 'request'
        };
        mirrorCommand(mediaBoxCommand2);
      }

      /* 웹소켓 Disable */
      if (checkInManagement.chatConnect && webSocket) {
        webSocketDisconnect(webSocket);
      }

      if (sessionBaseInfo?.baseInfo.role === 'teacher') {

        /* 미디어박스가 있는 경우 미디어박스 블랜디드 러닝서버 종료 */
        if (classInfo?.mediaBoxIp && (blendedState.record || blendedState.stream)) {
          try {
            setBlendedState((prev) => ({
              ...prev,
              audio: true,
              video: true,
              record: false,
              recordMode: 'NONE', // 만일 기존에 녹화가 되어있다면 PAUSE
              stream: false,
              roomId: ''
            }));
            unPublishStream({ mediaBoxIp: classInfo.mediaBoxIp });
            destroyBlendedStreamLearning();
          } catch (e) {
            console.error(e);
          }
        }
      }

      delayLogBody.endAt = new Date().toISOString();
      delayLogBody.millisecond = `${new Date(delayLogBody.endAt).getTime() - new Date(delayLogBody.startAt).getTime()}`;
      ncpLogUpdate({ logLevel: 'DEBUG', body: delayLogBody });

      if (pingWorker) {
        pingWorker.terminate();
        pingWorker = null;
      }

      if (recordWorker) {
        recordWorker.terminate();
        recordWorker = null;
      }

    }

    firstCheckIn = false;

    setWebSocket(null);
    setClassInfo(null);
    setUsersInfo([]);
    setUsersInfoBox([]);
    setMediaBoxMessage([]);
    setDetailMonitorImage(null);
    setCheckInTimeInfo(null);
    resetBlendedState();
    setPopupOther((prevData) => ({ ...prevData, TYPE: '' }));
    setToast({
      TYPE: '',
      CONTENT: '',
      RESERVE: '',
      TIME: 300
    });
    // setToastClass()  // 어떤 처리가 필요한지?
    setCheckOutPopup(false);
    checkInManagementClear();
    setLockState(false);
    window.MirrorSetLock(false, 0);
    setMyMirrorManagement(null);        // 참여 미러링 제거
    checkInManagementClear();                       // 전역 체크인 상태관리 초기화
    classTalkListClear();                           // 정제된 개인 채팅 + MQTT 이벤트 초기화
    classTalkLPopupClear();
    setMirrorManagement({
      lectureId: 'default',
      mirrorInfo: [],
      mirrorTempInfo: []
    });  // 선생님 관리 미러링 상태정보 초기화
    classModeClear(); // 그룹모드 초기화
    setMonitorMessages([]);
    setClassMessages([]);
    setBypassMessages([]);
    setReceiveMessages([]);
    setPermissionRequestMessages([]);
    setPermissionResponseMessages([]);
    setMirrorOpenRequestMessages([]);
    setMirrorManageMessage([]);
    setTempEventData([]);
    setDeviceList({ classId: '', deviceList: [] });
    setGroupMode({ groupMode: false, groupInfo: null }); // 그룹모드 초기화
    setMqttClient(null);

    return true;

  };

  /*
   * [UI 호출] - 수업모드 변경 함수
   */
  const classModeChange = async (groupMode: boolean, groupInfo?: ClassGroupInfoInterface): Promise<boolean> => {
    if (classInfo?.lectureId && sessionBaseInfo?.baseInfo.role === 'teacher') {
      const apiRes = await classModeUpdate({
        token: sessionTokenInfo.coreAccessToken!,
        lectureId: classInfo.lectureId,
        groupMode: groupMode,
        groupInfo: groupInfo
      });
      if (apiRes.code === 'OK') {

        setGroupMode({ groupMode: groupMode, groupInfo: groupInfo ? groupInfo : null });
        return true;
      }
      else if (apiRes.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
      }
      else if (apiRes.message === 'TOKEN_EXPIRED') {
        setForceLogout({ force: true, reason: 'token' });
      }
      else {
        setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
      }
    }
    return false;
  };

  /*
   * [UI 호출] - 체크인 연장 함수
   * 1. 5분 연장이 가능한지 여부 확인
   * 2. Api 를 통해 체크아웃 시간 연장
   * */
  const checkInExtendRequest = async (): Promise<boolean> => {
    if (classInfo && sessionBaseInfo?.baseInfo.role === 'teacher') {

      // 2. Api Call
      const apiRes = await checkInExtend({ lectureId: classInfo.lectureId, token: sessionTokenInfo.coreAccessToken! });

      if (apiRes.code === 'OK') {
        setLoginSessionCount(publicMethod.sessionUpdate);
        setToast({ TYPE: 'alert', CONTENT: 'The class time has been extended by 5 minutes.', TIME: 3 });
        return true;
      }
      else if (apiRes.code === 'TOKEN_EXPIRED') {
        setForceLogout({ force: true, reason: 'token' });
      }
      else if (apiRes.code === 'TIMEOUT') {
        navigate(`/service/${sessionBaseInfo.baseInfo.role}/error`);
      }
      else {
        // 서버 에러 팝업
        setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
      }
    }

    return false;
  };

  /*
   * [UI 호출] - 화면잠금/해제 함수
   *  1. 대상 화면잠금/해제 여부를 반영하여 수업 참여자목록을 새로 만든다.
   *  2. 새로만든 수업 참여자목록으로 기존 수업참여자 목록을 대체한다.
   */
  const sendScreenLock = async (type: 'all' | 'one', lock: boolean, id?: string): Promise<boolean> => {
    if (classInfo && sessionBaseInfo?.baseInfo.role === 'teacher') {

      const req: any = {
        lectureId: classInfo.lectureId,
        locked: lock,
        token: sessionTokenInfo.coreAccessToken
      };

      if (type === 'one' && id) {
        req.userId = id;
      }
      const res = await userLockUpdate(req);

      if (res.code !== 'OK') {
        setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
      }

      return res.code === 'OK';
    }
    return false;
  };

  /*
   * [UI 호출] - 미러링 시작 요청 Command 함수
   *  - pub / sub userID 전송한다
   */
  const mirrorStartCommand = (pubs: string[], subs: string[]): boolean => {

    if (classInfo?.mediaBoxIp) {
      const mediaBoxCommand: MediaBoxStartMirror = {
        commandDivision: 'MIRROR_START',
        classroom: classInfo.lectureId,
        command: 'mirrorstart',
        type: 'request',
        data: {
          pubs: pubs,
          subs: subs,
        },
        sender: sessionBaseInfo?.baseInfo.userId!
      };
      mirrorCommand(mediaBoxCommand);

      // 토스트 띄워주기
      setToast({ TYPE: 'alert', CONTENT: 'Screen sharing has been start.', TIME: 3 });

      return true;
    }
    return false;
  };

  /*
   * [UI 호출] - 미러링 종료 요청 Command 함수
   *  - 해당 방번호 미러링 종료 Command 를 전송한다.
   */
  const mirrorStopCommand = (mirrorRoomId: number): boolean => {

    if (classInfo?.mediaBoxIp && (sessionBaseInfo?.baseInfo.role === 'teacher' || (myMirrorManagement && myMirrorManagement.position === 'pub' && myMirrorManagement.webRtcRoomId === mirrorRoomId))) {
      const mediaBoxCommand: MediaBoxStopMirror = {
        commandDivision: 'MIRROR_STOP',
        classroom: classInfo.lectureId,
        command: 'mirrorstop',
        type: 'request',
        data: {
          mirror: {
            roomnum: mirrorRoomId
          }
        },
        sender: sessionBaseInfo?.baseInfo.userId!
      };
      mirrorCommand(mediaBoxCommand);

      // 토스트 띄워주기
      setToast({ TYPE: 'alert', CONTENT: 'Screen sharing has been stopped.', TIME: 3 });

      return true;
    }
    return false;
  };

  /*
* [UI 호출] - 수업톡 삭제 Api
*  - 프론트에서 수업톡 삭제 버튼 클릭시 동작
*/
  const deleteClassTalk = async (talkIds: string[]): Promise<boolean> => {
    const apiRes = await deleteMyTalk({ token: sessionTokenInfo.coreAccessToken!, talkIds: talkIds });
    if (apiRes.code !== 'OK') {
      setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);
    }
    return apiRes.code === 'OK';
  };

  /*
   * [UI 호출] - 파일/링크/메세지 요청 Api 호출
   *  - 요청이 필요한건과 필요하지 않은건을 분리하여 처리한다.
   */
  const sendPermissionRequestMessage = async (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }): Promise<boolean> => {
    if (classInfo) {

      const receiverList: { userId: string; division: 'student' | 'teacher' | 'board' | 'onequick'; number?: number; name: string; }[] = [];
      request.targetInfo.map(t => {
        const target = usersInfo.find(u => u.id === t.id);
        if (target) {
          const pushData: any = {
            userId: target.id,
            division: target.division,
            name: target.name
          };
          if (target.number) {
            pushData.number = target.number;
          }
          receiverList.push(pushData);
        }
      });

      if (request.type === 'file' || request.type === 'link') {

        if (sessionBaseInfo?.baseInfo?.role === 'student') {

          let isTargetGroupOneQuick = false;

          if (groupMode?.groupInfo && groupMode?.groupInfo?.subGroupList.length > 0) {
            for (const subGroup of groupMode.groupInfo.subGroupList) {
              for (const member of subGroup.subGroupMember) {
                if (member.id === request.targetInfo[0].id && member.division === 'onequick' && subGroup.subGroupId !== 'unRegisterGroup_LinkSchool') {
                  const me = subGroup.subGroupMember.find(m => m.id === sessionBaseInfo?.baseInfo.userId);
                  if (me) {
                    isTargetGroupOneQuick = true;
                    break;
                  }
                }
              }
            }
          }

          if (request.targetInfo.length === 1 && (request.targetInfo[0].division === 'teacher' || isTargetGroupOneQuick)) {
            // 선생님에게 전송하거나, 모둠모드 시 본인모둠 원퀵에게 전송시
            if (request.type === 'file' && request.file) {
              return await fileRequestLogic({ type: 'file', myId: sessionBaseInfo.baseInfo.userId!, myRole: sessionBaseInfo.baseInfo.role, permission: false, receiver: receiverList, file: request.file });
            }
            else if (request.type === 'link' && request.link) {
              return await stringRequestLogic({ type: 'link', myId: sessionBaseInfo!.baseInfo.userId!, myRole: sessionBaseInfo!.baseInfo.role, permission: false, receiver: receiverList, message: request.link });
            }
          }
          else if (request.type === 'file' && request.file) {
            return await fileRequestLogic({ type: 'file', myId: sessionBaseInfo.baseInfo.userId!, myRole: sessionBaseInfo.baseInfo.role, permission: true, receiver: receiverList, file: request.file });
          }
          else if (request.type === 'link' && request.link) {
            return await stringRequestLogic({ type: 'link', myId: sessionBaseInfo.baseInfo.userId!, myRole: sessionBaseInfo.baseInfo.role, permission: true, receiver: receiverList, message: request.link });
          }

        }
        else if (sessionBaseInfo?.baseInfo?.role === 'teacher' && request.type === 'file' && request.file) {
          return await fileRequestLogic({ type: 'file', myId: sessionBaseInfo.baseInfo.userId!, myRole: sessionBaseInfo.baseInfo.role, permission: false, receiver: receiverList, file: request.file });
        }
        else if (sessionBaseInfo?.baseInfo?.role === 'teacher' && request.type === 'link' && request.link) {
          return await stringRequestLogic({ type: 'link', myId: sessionBaseInfo!.baseInfo.userId!, myRole: sessionBaseInfo!.baseInfo.role, permission: false, receiver: receiverList, message: request.link });
        }
      }
      else if (request.type === 'msg' && request.msg && (sessionBaseInfo?.baseInfo?.role === 'teacher' || sessionBaseInfo?.baseInfo?.role === 'student')) {
        return await stringRequestLogic({ type: 'msg', myId: sessionBaseInfo!.baseInfo.userId!, myRole: sessionBaseInfo!.baseInfo.role, permission: false, receiver: receiverList, message: request.msg });
      }
    }
    return false;    // 요청 실패
  };

  /*
   * [UI 호출] - 승인 요청자의 허가자 승인/거절여부 전달 Api 호출
   *  - 파일/링크/미러링오픈/미러링조인 요청에 대한 반환처리
   */
  const returnPermissionMessage = async (request: { permission: boolean, talkId: string }): Promise<boolean> => {
    if (classInfo) {

      const res = await returnPermission({ token: sessionTokenInfo.coreAccessToken!, talkId: request.talkId, permission: request.permission, lectureId: classInfo.lectureId });

      if (res.code === 'OK') {
        return true;
      } else {
        setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
      }

    }
    return true;
  };

  /*
   * [UI 호출] - 모니터링 화면확대 요청 함수
   *  1. 요청을 전송할 대상을 찾는다.
   *  2. 대상이 존재하면 해당 대상의 이미지를 임시로 미러링 확대화면 상태값에 저장한다.
   *  3. 대상에게 모니터링 확대화면 요청을 전송한다.
   *  4. 대상이 없다면 모니터링 확대화면을 초기화시킨다.
   */
  const getDetailMonitorRequest = (id: string) => {

    if (classInfo && sessionBaseInfo?.baseInfo?.role === 'teacher') {
      const target = usersInfo.find(u => u.id === id);

      if (target?.imageUrl) {
        setDetailMonitorImage({ id: id, imageUrl: target.imageUrl });

        const messageFormat: MqttBypassMessageInterface = {
          senderId: sessionBaseInfo?.baseInfo.userId!,
          type: 'MONITOR_REQUEST',
          bypassData: '고해상도 모니터링 이미지 요청'
        };

        const messageJson: string = JSON.stringify(messageFormat);

        userBypassMessage({ token: sessionTokenInfo.coreAccessToken!, topic: `bypass-topic/lecture/${classInfo?.lectureId}/${target.id}`, message: messageJson });
      }
      else {
        setDetailMonitorImage(null);
      }
    }
  };

  /*
   * [UI 호출] - 구루미 방 생성 요청 함수 ( 선생님만 사용 )
   *  1. mediaBox publish_stream
   *  2. 구루미 makeBlendedRoom
   *  3. core start_blended_learning
   *  4. joinBlendedStreamLearning
   */
  const startBlendedStreamLearning = async (roomTitle: string): Promise<boolean> => {

    let logicSuccess: boolean = true;

    if (!classInfo?.mediaBoxIp && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM CREATE FAIL : NONE MediaBoxIp');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to create video class.', TIME: 3 });
      logicSuccess = false;
    }

    if (!blendedState.deviceId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM CREATE FAIL : NONE DEVICE ID');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to create video class.', TIME: 3 });
      logicSuccess = false;
    }

    if (sessionBaseInfo?.baseInfo.role !== 'teacher' && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM CREATE FAIL : NONE TEACHER ROLE');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to create video class.', TIME: 3 });
      logicSuccess = false;
    }

    if (blendedState.stream && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM CREATE FAIL : ALREADY STREAMING');
      setToast({ TYPE: 'alert', CONTENT: 'A video class is already in progress.', TIME: 3 });
      logicSuccess = false;
    }

    let roomId: string;
    let roomUrlId: string;

    if (logicSuccess) {
      roomUrlId = `${classInfo!.lectureId}${publicMethod.getCurrentDateTimeSecond()}`.replace(/-/gi, '');

      const makeRoomRes = await makeBlendedRoom({ roomUrlId: roomUrlId, roomTitle: roomTitle, availDate: publicMethod.streamStartTime(), resvDate: publicMethod.streamEndTime(checkInTimeInfo?.sec!) });

      if (makeRoomRes.message === 'FAIL' || !makeRoomRes.roomId) {
        console.log('LINK SCHOOL - BLENDED ROOM CREATE FAIL : GOOROOMEE API FAIL');
        setToast({ TYPE: 'alert', CONTENT: 'Failed to create video class.', TIME: 3 });
        logicSuccess = false;
      } else {
        roomId = makeRoomRes.roomId;

        let streamRes;

        if (!blendedState.record) {
          streamRes = await setPublishStream({ lectureId: generateRandomString(), conference: true, record: false, roomId: roomId });
        }
        else {
          streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: true, record: true, roomId: roomId });
        }

        if (!streamRes) {
          logicSuccess = false;
        }
      }
    }

    if (logicSuccess) {
      setBlendedState((prev: BlendedStateInterface) => ({
        ...prev,
        mediaBoxRequest: false,
        stream: true,
        roomId: roomId,
        publicUrl: `${process.env.REACT_APP_GOOROOMEE_SERVICE}/${roomUrlId}`
      }));
      setToast({ TYPE: 'alert', CONTENT: 'Video class has been successfully created.', TIME: 3 });
    } else {
      setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false }));
      setToast({ TYPE: 'alert', CONTENT: 'Failed to create video class.', TIME: 3 });
    }

    return logicSuccess;
  };

  /*
   * [UI 호출] - 구루미 방 참여 함수 ( 선생님, 학생 전부 사용 )
   *  1. makeRoomOtp
   *  2. link Open
   */
  const joinBlendedStreamLearning = async (roomId: string): Promise<'SUCCESS' | 'FAIL' | 'NONE'> => {

    if (!classInfo?.mediaBoxIp) {
      console.log('LINK SCHOOL - BLENDED ROOM JOIN FAIL : NONE MediaBoxIp');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to join video class.', TIME: 3 });
      return 'FAIL';
    }

    const makeRoomOtpReq = {
      roomId: roomId,
      username: sessionBaseInfo?.baseInfo.name!,
      roleId: 'participant',
      apiUserId: sessionBaseInfo?.baseInfo.username!
    };
    const makeRoomOtpRes = await makeRoomOtp(makeRoomOtpReq);
    if (makeRoomOtpRes.message === 'SUCCESS') {
      linkOpen('out', `${process.env.REACT_APP_GOOROOMEE_SERVICE}/otp-v3/room/otp/${makeRoomOtpRes.otp}`);
      return 'SUCCESS';
    }
    else {
      console.log('LINK SCHOOL - BLENDED ROOM JOIN FAIL : GROOMEE API FAIL');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to join video class.', TIME: 3 });
      return 'NONE';
    }
  };

  /*
   * [UI 호출] - 특정 학생들에게 구루미 방 참여요청 전송 함수 ( 선생님만 사용 )
   *  1. mqtt 를 통해 대상에게 구루미 roomId 전송
   */
  const requestJoinBlendedStreamLearning = async (userList: string[], roomId: string) => {
    if (classInfo) {

      const messageFormat: MqttBypassMessageInterface = {
        type: 'STREAM_REQUEST',
        senderId: sessionBaseInfo?.baseInfo.userId!,
        bypassData: roomId
      };

      const messageJson: string = JSON.stringify(messageFormat);

      for (const u of userList) {
        userBypassMessage({ token: sessionTokenInfo.coreAccessToken!, topic: `bypass-topic/lecture/${classInfo.lectureId}/${u}`, message: messageJson });
      }
    }
  };

  /*
   * [UI 호출] - 구루미 방 제거 함수 ( 선생님만 사용 )
   *  1. 현재 녹화중이 아니라면
   *      1. core stop_blended_learning
   *      2. mediaBox unpublish_stream
   *      3. 구루미 deleteBlendedRoom
   *  2. 현재 녹화중이라면
   *      1. core update_blended_learning
   *      2. mediaBox
   */
  const destroyBlendedStreamLearning = async (): Promise<boolean> => {
    let logicSuccess: boolean = true;

    if (!classInfo?.mediaBoxIp && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM DESTROY FAIL : NONE MediaBoxIp');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to end video class.', TIME: 3 });
      logicSuccess = false;
    }
    if (!blendedState.deviceId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM DESTROY FAIL : NONE DEVICE ID');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to end video class.', TIME: 3 });
      logicSuccess = false;
    }
    if (!blendedState.roomId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM DESTROY FAIL : NONE ROOM ID');
      setToast({ TYPE: 'alert', CONTENT: 'There is no ongoing video class.', TIME: 3 });
      logicSuccess = false;
    }
    if (!blendedState.stream && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM DESTROY FAIL : NONE STREAMING');
      setToast({ TYPE: 'alert', CONTENT: 'There is no ongoing video class.', TIME: 3 });
      logicSuccess = false;
    }
    if (sessionBaseInfo?.baseInfo.role !== 'teacher' && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED ROOM DESTROY FAIL : NONE TEACHER ROLE');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to end video class.', TIME: 3 });
      logicSuccess = false;
    }

    if (logicSuccess) {
      let streamRes;
      if (blendedState.record) {
        streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: false, record: true });
      }
      else {
        streamRes = await setUnPublishStream();
      }

      if (streamRes) {
        deleteBlendedRoom({ roomId: blendedState.roomId! });
        setToast({ TYPE: 'alert', CONTENT: 'The video class has been ended.', TIME: 3 });
        console.log('LINK SCHOOL - BLENDED ROOM DESTROY SUCCESS');
      }
      else {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to end the video class.', TIME: 3 });
        logicSuccess = false;
      }
    }
    if (logicSuccess) {
      setBlendedState((prev: BlendedStateInterface) => ({
        ...prev,
        mediaBoxRequest: false,
        stream: false,
        roomId: null,
        publicUrl: null
      }));
    } else {
      setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false }));
    }
    return logicSuccess;
  };

  /*
   * [UI 호출] - 녹화 실행 함수
   *  - 이미 스트리밍 중이라면 : changePublishStream 호출
   *  - 스트리밍중이 아니라면 : publishStream 호출
   */
  const startBlendedRecord = async (recordTitle: string): Promise<boolean> => {

    let logicSuccess: boolean = true;

    if (!classInfo?.mediaBoxIp && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD START FAIL : NONE MediaBoxIp');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to start recording.', TIME: 3 });
      logicSuccess = false;
    }

    if (!blendedState.deviceId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD START FAIL : NONE DEVICE ID');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to start recording.', TIME: 3 });
      logicSuccess = false;
    }

    if (sessionBaseInfo?.baseInfo.role !== 'teacher' && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD START FAIL : NONE TEACHER ROLE');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to start recording.', TIME: 3 });
      logicSuccess = false;
    }

    if (blendedState.record && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD START FAIL : ALREADY RECORDING');
      setToast({ TYPE: 'alert', CONTENT: 'Already recording.', TIME: 3 });
      logicSuccess = false;
    }

    let newLectureId: string | null = null;

    if (logicSuccess) {
      newLectureId = await makeBlendedLectureId(recordTitle);

      if (!newLectureId) {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to start recording.', TIME: 3 });
        logicSuccess = false;
      }
    }

    if (logicSuccess && newLectureId) {
      let streamRes;

      if (blendedState.stream) {
        streamRes = await setChangeStream({ lectureId: newLectureId, conference: true, record: true });
      }
      else {
        streamRes = await setPublishStream({ lectureId: newLectureId, conference: false, record: true });
      }

      if (!streamRes) {
        deleteLectureId(newLectureId);
        setToast({ TYPE: 'alert', CONTENT: 'Failed to start recording.', TIME: 3 });
        logicSuccess = false;
      }
    }


    if (logicSuccess) {

      /*
       * 녹화 종료시간을 측정한다.
       *  - classInfo 의 subject 를 토대로 수업/비수업 여부를 판단한다.
       *  - 수업중이라면
       *      - checkOut 시간이 1시간 이하라면 녹화 종료시간은 체크아웃 시간이다.
       *      - checkOut 시간이 1시간 초과라면 녹화 종료시간은 1시간이다.
       *  - 비수업이라면
       *      - checkOut 시간이 3시간 이하라면 녹화 종료시간은 체크아웃 시간이다.
       *      - checkOut 시간이 3시간 초과라면 녹화 종료시간은 3시간이다.
       */
      let recordEndTime: string | null = null;
      let forceRecordEnd: boolean = false;
      const now: Date = new Date();

      const remainingSecond = publicMethod.getRemainingSeconds(classInfo!.checkOutTime);   // 잔여시간초
      if (classInfo!.subject === 'Non-Class') {
        if (remainingSecond <= 3600) {
          recordEndTime = classInfo!.checkOutTime;
        } else {
          forceRecordEnd = true;
          recordEndTime = new Date(now.getTime() + 60 * 60 * 1000).toISOString();
        }
      }
      else if (remainingSecond <= 10800) {
        recordEndTime = classInfo!.checkOutTime;
      } else {
        forceRecordEnd = true;
        recordEndTime = new Date(now.getTime() + 3 * 60 * 60 * 1000).toISOString();
      }

      setBlendedState((prev: BlendedStateInterface) => ({
        ...prev,
        mediaBoxRequest: false,
        recordLectureId: newLectureId,
        recordLectureTitle: recordTitle,
        record: true,
        recordMode: 'PLAY',
        recordStartDate: now.toISOString(),
        recordEndDate: recordEndTime,
        forceRecordEnd: forceRecordEnd
      }));

      setToast({ TYPE: 'alert', CONTENT: 'Recording has started.', TIME: 3 });
    }
    else {
      setBlendedState((prev: BlendedStateInterface) => ({
        ...prev,
        mediaBoxRequest: false,
        recordLectureTitle: null,
        recordMode: 'NONE'
      }));
    }
    return logicSuccess;
  };

  /*
   * [UI 호출] - 녹화종료 함수
   */
  const endBlendedRecord = async (): Promise<boolean> => {

    let logicSuccess: boolean = true;

    if (!classInfo?.mediaBoxIp && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE MediaBoxIp');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to stop recording.', TIME: 3 });
      logicSuccess = false;
    }

    if (!blendedState.deviceId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE DEVICE ID');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to stop recording.', TIME: 3 });
      logicSuccess = false;
    }

    if (sessionBaseInfo?.baseInfo.role !== 'teacher' && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE TEACHER ROLE');
      setToast({ TYPE: 'alert', CONTENT: 'Failed to stop recording.', TIME: 3 });
      logicSuccess = false;
    }

    let streamRes;

    if (logicSuccess) {
      if (blendedState.stream) {
        streamRes = await setChangeStream({ lectureId: generateRandomString(), conference: true, record: false });
      }
      else {
        streamRes = await setUnPublishStream();
      }

      if (!streamRes) {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to stop recording.', TIME: 3 });
        logicSuccess = false;
      }
    }

    if (logicSuccess) {
      setBlendedState((prev: BlendedStateInterface) => ({
        ...prev,
        mediaBoxRequest: false,
        recordLectureId: null,
        recordLectureTitle: null,
        record: false,
        recordCount: 0,
        recordEndCount: 0,
        recordEndDate: null,
        forceRecordEnd: false,
        recordMode: 'NONE'
      }));
      setToast({ TYPE: 'alert', CONTENT: 'The recording has been stopped.', TIME: 3 });
    }
    else {
      setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false }));
    }
    return logicSuccess;
  };

  /*
   * [UI 호출] - 녹화 일시중지, 다시시작 함수
   */
  const changeBlendedRecord = async (): Promise<boolean> => {

    let logicSuccess: boolean = true;

    if (!classInfo?.mediaBoxIp && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE MediaBoxIp');
      if (blendedState.recordMode === 'PLAY') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to pause the recording.', TIME: 3 });
      } else if (blendedState.recordMode === 'PAUSE') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to resume the recording.', TIME: 3 });
      }
      logicSuccess = false;
    }

    if (!blendedState.deviceId && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE DEVICE ID');
      if (blendedState.recordMode === 'PLAY') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to pause the recording.', TIME: 3 });
      } else if (blendedState.recordMode === 'PAUSE') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to resume the recording.', TIME: 3 });
      }
      logicSuccess = false;
    }

    if (sessionBaseInfo?.baseInfo.role !== 'teacher' && logicSuccess) {
      console.log('LINK SCHOOL - BLENDED RECORD END FAIL : NONE TEACHER ROLE');
      if (blendedState.recordMode === 'PLAY') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to pause the recording.', TIME: 3 });
      } else if (blendedState.recordMode === 'PAUSE') {
        setToast({ TYPE: 'alert', CONTENT: 'Failed to resume the recording.', TIME: 3 });
      }
      logicSuccess = false;
    }

    if (logicSuccess) {
      let streamRes;

      if (blendedState.stream) {
        if (blendedState.recordMode === 'PLAY') {
          streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: true, record: false });
        }
        else {
          streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: true, record: true });
        }
      }
      else if (blendedState.recordMode === 'PLAY') {
        streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: false, record: false });
      }
      else {
        streamRes = await setChangeStream({ lectureId: blendedState.recordLectureId!, conference: false, record: true });
      }

      if (!streamRes) {
        logicSuccess = false;
      }
    }

    if (logicSuccess) {
      if (blendedState.recordMode === 'PLAY') {
        setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false, recordMode: 'PAUSE' }));
        setToast({ TYPE: 'alert', CONTENT: 'Recording has been paused.', TIME: 3 });
      }
      else if (blendedState.recordMode === 'PAUSE') {
        setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false, recordMode: 'PLAY' }));
        setToast({ TYPE: 'alert', CONTENT: 'Recording has resumed.', TIME: 3 });
      }
    }
    else {
      setBlendedState((prev: BlendedStateInterface) => ({ ...prev, mediaBoxRequest: false }));
    }

    return logicSuccess;
  };

  /*
   * [UI 호출] - 미러링 오픈요청 ( 최초 미러링을 만들 때 요청 )
   */
  const mirrorOpenRequest = async (request: MirrorOpenRequestInterface): Promise<boolean> => {
    //임시
    // request.permissionNeed = false;

    const req: sendOpenPermissionReq = {
      token: sessionTokenInfo.coreAccessToken!,
      lectureId: classInfo?.lectureId!,
      permission: request.permissionNeed,
      groupMirror: request.groupMirror,
      pubUsers: request.pubs.map(r => {
        return {
          userId: r.id,
          name: r.name,
          number: r.number || 0,
          division: r.division
        };
      }),
      subUsers: request.subs.map(r => {
        return {
          userId: r.id,
          name: r.name,
          number: r.number || 0,
          division: r.division
        };
      })
    };
    if (request.permissionNeed) {
      const teacherUserId = usersInfo.find(u => u.division === 'teacher' && u.checkIn)?.id;
      if (teacherUserId) {
        req.permissionUserId = teacherUserId!;
      }
      else {
        setToast({ TYPE: 'alert', CONTENT: 'There is no teacher currently checked in.', TIME: 3 });
        return false;
      }
    }
    if (request.groupMirror) {
      if (request.subGroupId) {
        req.subGroupId = request.subGroupId.toString();
      }
      else {
        setToast({ TYPE: 'alert', CONTENT: 'There is no group.', TIME: 3 });
        return false;
      }
    }

    const res = await sendOpenPermission(req);

    console.log('mirror open!!  ', JSON.stringify(res));

    if (request.permissionNeed && res.code === 'OK' && res.data?.lectureTalkId) {

      /* 요청건이 전송 된 경우 */

      const appendRequest: TempEventDataInterface = {
        talkId: res.data.lectureTalkId,
        lectureId: classInfo?.lectureId!,
        eventType: 'MIRROR_OPEN',
        tempMirrorOpenInformation: {
          ...request,
          mirrorId: res.data.mirrorId
        }
      };
      setTempEventData(prev => ([...prev, appendRequest]));

      return res.code === 'OK';
    }
    else if (!request.permissionNeed && res.code === 'OK' && res.data) {

      /* 요청이 필요 없는 경우 - talkId 또한 존재하지 않는다. */

      setTempMirrorEventData(prev => ([...prev, { mirrorId: res.data?.mirrorId }]));

      const appendRequest: TempEventDataInterface = {
        talkId: res.data.lectureTalkId ?? '',
        lectureId: classInfo?.lectureId!,
        eventType: 'MIRROR_OPEN',
        tempMirrorOpenInformation: {
          ...request,
          mirrorId: res.data.mirrorId
        }
      };
      setTempEventData(prev => ([...prev, appendRequest]));

      mirrorRoomOpenCommand(res.data.mirrorId);

      // const position = req.pubUsers.map(r => { return r.userId; }).indexOf(sessionBaseInfo!.baseInfo.userId) !== -1 ? 'pub' : 'sub';
      // const pubInfo = usersInfo
      //   .filter(user => req.pubUsers.map(r => { return r.userId; }).includes(user.id))
      //   .map(user => ({
      //     division: user.division,
      //     id: user.id,
      //     name: user.name,
      //     number: user.number
      //   }));

      // setMyMirrorManagement({ webRtcRoomId: res.data.roomnum, position: position, pubInfo: pubInfo });

      // mirrorStartCommand(req.pubUsers.map(r => {
      //   return r.userId;
      // }), req.subUsers.map(r => {
      //   return r.userId;
      // }));
      return true;
    }
    else if (res.code !== 'OK') {
      setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
    }

    return false;

  };

  /*
   * [UI 호출] - 미러링 오픈/참여 요청 승인/거절 처리 ( UI 에서는 오픈/참여 두개를 구분하지않고 이 함수만을 사용 )
   */
  const returnMirrorPermissionMessage = async (request: { permission: boolean, talkId: string, senderId: string, targetId: string; }): Promise<boolean> => {
    const target = tempMirrorEventData.find(t => t.talkId === request.talkId);

    if (target) {

      if (request.permission) {
        // 승인할 경우
        if (target.mirrorJoinInformation) {
          // Join 을 승인한 케이스
          const pubList = target.mirrorJoinInformation.mirrorPosition === 'PUB' ? [sessionBaseInfo?.baseInfo.userId!] : [];
          const subList = target.mirrorJoinInformation.mirrorPosition === 'SUB' ? [sessionBaseInfo?.baseInfo.userId!] : [];
          const roomNumber = target.mirrorJoinInformation.mirrorRoomNumber;
          console.log('aschool join');

          mirrorJoinRoomCommand(pubList, subList, Number(roomNumber));
          return true;
        }
        else if (target.mirrorId) {
          // Open 을 승인한 케이스
          console.log('aschool permission OK: ' + JSON.stringify(target));

          const mirrorId = target.mirrorId;
          mirrorRoomOpenCommand(mirrorId);

          // 이름이 바뀌면 바꿔줘야 동작 주의 0312 
          console.log('request target: ', request.targetId);
          if (request.targetId !== 'Board' && request.targetId !== 'GroupBoard') {
            mirrorStartCommand([request.senderId], [sessionBaseInfo?.baseInfo.userId!]); // 요청 승인

          }
          else {
            mirrorStartCommand([request.senderId], [usersInfo.find(u => u.name == request.targetId)!.id]);
          }

          return true;
        }

      }
      else {
        setTempMirrorEventData(tempMirrorEventData.filter(t => t.talkId !== request.talkId));
        // 거절할 경우
        if (target.mirrorJoinInformation) {
          // Join 을 거절한 케이스
          const res = await returnJoinPermission({ lectureTalkId: request.talkId, token: sessionTokenInfo.coreAccessToken! });
          if (res.code !== 'OK') {
            setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
          }
          return res.code === 'OK';
        }
        else if (target.mirrorId) {
          // Open 을 거절한 케이스
          const res = await returnOpenPermission({ permission: 'REJECTED', mirrorId: target.mirrorId, token: sessionTokenInfo.coreAccessToken!, self: false });
          if (res.code !== 'OK') {
            setPopupError(prevData => [...prevData, { CODE: res.errorCode, MESSAGE: res.errorMessage }]);
          }
          return res.code === 'OK';
        }

      }

    }

    return true;

  };



  return {
    usersInfo, setUsersInfo, classInfo, detailMonitorImage, usersInfoBox, setUsersInfoBox,

    classCheckIn, classCheckOut, checkInExtendRequest, sendScreenLock, mirrorStartCommand, mirrorStopCommand, stopAllMirrorRoomCommand,
    deleteClassTalk, sendPermissionRequestMessage, returnPermissionMessage,
    getDetailMonitorRequest, startBlendedStreamLearning, joinBlendedStreamLearning,
    requestJoinBlendedStreamLearning, destroyBlendedStreamLearning,
    startBlendedRecord, endBlendedRecord, changeBlendedRecord,
    mirrorOpenRequest, returnMirrorPermissionMessage, classModeChange
  };

};

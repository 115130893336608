import SockJS from 'sockjs-client';
import { CompatClient, Stomp } from '@stomp/stompjs';
import React from 'react';

export interface SocketConnectInterface {
    token: string
    roomId: string;
    setMonitorMessages: React.Dispatch<React.SetStateAction<MonitorMessageInterface[]>>;
    setStateChangeFunction: (type: 'mqtt' | 'chat', state: boolean) => void;
    userRole: string;
}

export interface MonitorMessageInterface {
    userId: string
    imageUrl: string
}

let isReconnecting = false;
let reconnectAttempt = 0;

export const webSocketConnect = async (functionReq: SocketConnectInterface): Promise<CompatClient | null> => {
    const stompHeaders = {
        Authorization: `Bearer ${functionReq.token}`
    };

    const socketUrl = `${process.env.REACT_APP_CHAT_SERVER}/seers`;
    const webSocketFactory = () => new SockJS(socketUrl);
    const ws = Stomp.over(webSocketFactory);

    ws.debug = ((log: any) => {
        // if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        //     console.log(log);
        // }
    });

    const connectWs = (): Promise<boolean> => {
        return new Promise<boolean>((resolve, reject) => {
            ws.connect(
                stompHeaders,
                () => {
                    isReconnecting = false;
                    reconnectAttempt = 0;
                    console.log('LINK SCHOOL - WebSocket Connected');
                    resolve(true);
                },
                (error: any) => {
                    console.error('LINK SCHOOL - WebSocket Connection Error:', error);
                    reject(false);
                }
            );
        });
    };

    try {
        const connected = await connectWs();
        if (!connected) return null;

        if (functionReq.userRole === 'teacher') {
            const classRoomSubscription = ws.subscribe(`/sub/chat/room/${functionReq.roomId}`, (message) => {
                const rcvMessage = JSON.parse(message.body);
                try {
                    if (rcvMessage.type === 'MSG_MONITOR') {
                        functionReq.setMonitorMessages((prevMessages: MonitorMessageInterface[]) => [...prevMessages, {
                            userId: rcvMessage.user_id,
                            imageUrl: rcvMessage.file_download_path
                        }]);
                    }
                } catch (e: any) {
                    console.log('LINK SCHOOL - Class Message', `${JSON.stringify(e)}`);
                }
            });
            if (!classRoomSubscription) {
                console.error('Failed to subscribe to class room');
                return null;
            }
        }

        functionReq.setStateChangeFunction('chat', true);

        ws.onDisconnect = () => {
            console.error('LINK SCHOOL - WebSocket Disconnected');
            if (!isReconnecting) {
                isReconnecting = true;
                reconnectAttempt++;
                const delay = Math.min(1000 * Math.pow(2, reconnectAttempt), 60000); // 최대 30초 대기
                setTimeout(() => {
                    webSocketConnect(functionReq); // 재연결 시도
                }, delay);
            }
        };

        return ws; // Return WebSocket client if all subscriptions are successful

    }
    catch (error) {
        functionReq.setStateChangeFunction('chat', false);
        console.error('Subscription Error:', error);
        return null; // Return null if any subscription fails or if there's any error
    }
};

export const webSocketDisconnect = (ws: CompatClient) => {
    isReconnecting = false;
    ws.disconnect(() => {
        console.log('LINK SCHOOL - WebSocket Disconnected');
    });
};

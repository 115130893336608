import React from 'react';
import './toast.css';
import alertToastHook from '@/hooks/components/common/toast/alertToast';


interface AlertToastProps {
    menuOpen: boolean;
}

/* 알림 토스트 */
const AlertToast = ({ menuOpen }: AlertToastProps) => {

    const { isVisible, miniClass, toast } = alertToastHook();

    return (
        <>
            {toast.TYPE.includes('alert') &&
                <div className={`layout-toast ${menuOpen ? '' : 'tab-closed'} ${miniClass} ${isVisible && 'visible'}`}>
                    <article className={'layout-toast-box alert'}>
                        {toast.CONTENT}
                    </article>
                </div>
            }
        </>

    );
};

export default AlertToast;

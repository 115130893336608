import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* ####################################
 * ############# 수업톡 조회 #############
 * #################################### */





/* function interface */
export interface FunctionReq {
  token: string;
  talkIds: string[];
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
}


/* Api Interface */
// interface ApiReq {
//     talkIds: string[];
// }
interface ApiRes {
  status: number,                 // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}


export const deleteMyTalk = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/talk`;
  const method = 'DELETE';

  const res: ApiRes = await statusApi<string[], ApiRes>(endPoint, request.talkIds, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to delete.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message
  };
};

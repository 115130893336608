import { statusApi } from '@/services/network';
import {
  ContentMappingCode, ContentResponseCode, StatusMappingLogCode, StatusResponseErrorCode
} from '@/utils/code-enums';

/* #######################################
 * ############# 요청메세지 반환 #############
 * ####################################### */





/* function interface */
export interface FunctionReq {
  token: string;
  lectureTalkId: string;
}
export interface FunctionRes {
  message: string;
  errorCode: string;
  errorMessage: string;
  code: string;
}


/* Api Interface */
interface ApiRes {
  status: number,         // status
  code: string                    // core 반환 구분값
  message: string                 // core 반환 구분값 desc
  label: string
}



export const returnJoinPermission = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_STATUS_SERVER}/${process.env.REACT_APP_STATUS_VERSION}/mirror/join/reject/${request.lectureTalkId}`;
  const method = 'PUT';

  const res: ApiRes = await statusApi<{}, ApiRes>(endPoint, {}, method, request.token, null);

  const message = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';
  const errorCode = StatusMappingLogCode[res.label as StatusResponseErrorCode] || 'S9999';
  const errorMessage = 'Failed to request rejection of mirroring participation.';

  return {
    errorCode,
    errorMessage,
    code: res.code === 'OK' ? 'OK' : res.label,
    message
  };
};

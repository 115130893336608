import { useRecoilState } from 'recoil';
import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';
import { toastStore } from '@/recoil/store/common';
import { useMediaQuery } from '@mui/material';


/* 알림 토스트 HOOK */
export default() => {

    // 토스트 state
    const [toast, setToast] = useRecoilState(toastStore);
    // 토스트 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 접근 디바이스 (PC인지 태블릿인지)
    const { deviceInfo } = getSession();
    // 화면이 가로 1500px 이하일 경우
    const isSmallScreen = useMediaQuery('(max-width: 1500px)');
    // mini 클래스 명 선정
    const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';

    // 토스트 노출
    useEffect(() => {
        if (toast.TYPE.includes('alert')) {
            setIsVisible(true);
            setTimeout(() => {
                setIsVisible(false);
                setTimeout(() => {
                    setToast((prevData) => ({ ...prevData, TYPE: '', CONTENT: '', RESERVE: '' }));
                }, 100);
            }, 3000);
        }
    }, [toast.TYPE]);



    return { isVisible, miniClass, toast };
};

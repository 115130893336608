import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { toastStore } from '@/recoil/store/common';


/* 결정 팝업 HOOK */
export default (sendScreenLock?: (type: 'all' | 'one', lock: boolean, id?: string) => Promise<boolean>) => {


  // 페이지 이동
  const navigate = useNavigate();
  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);


  /** 팝업 노출 시키기**/
  useEffect(() => {
    if (popup.TYPE.includes('decision')) {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
    }
  }, [popup.TYPE]);

  // 팝업 끄기
  const popupClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setPopup((prevData) => ({ ...prevData, TYPE: '' }));
      document.body.style.overflow = '';
    }, 100);
  };

  // 팝업 확인
  const popupConfirm = async () => {

    const TYPE = popup.TYPE;
    const commonPopupClose = () => {
      popupClose();
      setPopup((prevData) => ({ ...prevData, RESERVE: TYPE }));
    };

    if (!TYPE) {
      popupClose();
      return;
    }

    switch (TYPE) {
      case 'decisionUnlock': { // 화면잠금 해제
        if (sendScreenLock) {
          const sendScreenLockIssue = await sendScreenLock('all', false);
          setToast({
            TYPE: 'alert',
            CONTENT: sendScreenLockIssue
              ? 'The screen lock for all students has been released.'
              : 'Failed to release the screen lock for all students.',

            TIME: 3
          });
          popupClose();
        }
        break;
      }
      case 'decisionRegister': {
        popupClose();
        setTimeout(() => {
          navigate('/auth/login', { replace: true });
        }, 200);
        break;
      }
      case 'decisionGroupSettingDelete': {
        popupClose();
        setPopup((prevData) => ({ ...prevData, RESERVE: `GroupSettingDelete-${popup.RESERVE}` }));
        break;
      }
      case 'decisionGroupItemDelete': {
        popupClose();
        setPopup((prevData) => ({ ...prevData, RESERVE: `GroupItemDelete-${popup.RESERVE}` }));
        break;
      }
      case 'decisionStudentStatus': {
        popupClose();
        setPopup((prevData) => ({ ...prevData, RESERVE: `StudentStatus===${popup.RESERVE}` }));
        break;
      }
      case 'decisionCancelInquiry':
      case 'decisionCancelNotice': {
        popupClose();
        navigate(-1);
        break;
      }
      case 'decisionDeleteTeacherAlarm':
      case 'decisionCancelDeviceRequest':
      case 'decisionGroupMakeNew':
      case 'decisionDeleteMyBox':
      case 'decisionCloseClassModeBar':
      case 'decisionWithdrawal':
      case 'decisionGroupModeOff':
      case 'decisionMakeAgreement':
      case 'decisionEndBlended':
      case 'decisionCancelRelease': {
        commonPopupClose();
        break;
      }

      default:
        popupClose();
        break;
    }
  };


  return {
    navigate,
    popup,
    popupClose,
    popupConfirm,
    isVisible
  };
};

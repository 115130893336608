import '@/components/container/service/full/Container.css';
import { ReactNode } from 'react';


interface FloatingContainerProps{
    children: ReactNode;
}


/* ———————— 플로팅 컨테이너  ———————— */
const FloatingContainer = ({ children }: FloatingContainerProps) => {

    return (
        <div className={'container-floating'}>
            {children}
        </div>
    );

};

export default FloatingContainer;

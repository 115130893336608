// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import AuthLayout from '@/layout/service/authLayout';
import Loadable from '@/components/Loadable';

/* 로그인 */
const Login = Loadable(lazy(() => import('@/pages/service/auth/login')));
/* 아이디 찾기 */
const FindId = Loadable(lazy(() => import('@/pages/service/auth/findId')));
/* 비밀번호 재설정 */
const FindPw = Loadable(lazy(() => import('@/pages/service/auth/findPw')));
/* 비밀번호 변경 */
const ChangePw = Loadable(lazy(() => import('@/pages/service/auth/changPw')));
/* 회원가입 1단계*/
const RegisterUserType = Loadable(lazy(() => import('@/pages/service/auth/registerUserType')));
/* 회원가입 2단계*/
const RegisterAgreement = Loadable(lazy(() => import('@/pages/service/auth/registerAgreement')));
/* 회원가입 3단계*/
const RegisterNorm = Loadable(lazy(() => import('@/pages/service/auth/registerNorm')));
/* 회원가입 4단계*/
const RegisterCert = Loadable(lazy(() => import('@/pages/service/auth/registerCert')));
/* 회원가입 5단계*/
const RegisterSchoolInfo = Loadable(lazy(() => import('@/pages/service/auth/registerSchoolInfo')));


const AuthError = Loadable(lazy(() => import('@/pages/service/common/error')));



const AuthRoutes = {
    path: '/',
    element: <AuthLayout />,
    children: [
        /* url 바로 접근시  */
        { path: '/', element: <Login/> },
        { path: 'auth/login', element: <Login/> },
        { path: 'auth/findId', element: <FindId/> },
        { path: 'auth/findPw', element: <FindPw/> },
        { path: 'auth/changePw', element: <ChangePw/> },
        { path: 'auth/registerUserType', element: <RegisterUserType/> },
        { path: 'auth/registerAgreement', element: <RegisterAgreement/> },
        { path: 'auth/registerNorm', element: <RegisterNorm/> },
        { path: 'auth/registerCert', element: <RegisterCert/> },
        { path: 'auth/registerSchoolInfo', element: <RegisterSchoolInfo/> },
        { path: 'auth/error', element: <AuthError/> },
        /* 404 오류 페이지 접근 시*/
        { path: '*', element: <Login /> }
    ]
};

export default AuthRoutes;

import React from 'react';
import { FrontSessionBaseInfo } from '@/services/core/me/getProfile';
import '@/pages/signage/common.css';
import TimeTableIcon from '@/assets/icon/signage/BNB/timetable.svg'; // Dompurify 적용완료
import NoticeIcon from '@/assets/icon/signage/BNB/notice.svg'; // Dompurify 적용완료
import MenuClassIcon from '@/assets/icon/signage/BNB/menu_class.svg'; // Dompurify 적용완료
import DeviceIcon from '@/assets/icon/signage/BNB/device.svg'; // Dompurify 적용완료
import MealIcon from '@/assets/icon/signage/BNB/meal.svg';  // Dompurify 적용완료
import MusicIcon from '@/assets/icon/signage/BNB/music.svg'; // Dompurify 적용완료
import AppIcon from '@/assets/icon/signage/BNB/app.svg'; // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import { useNavigate } from 'react-router-dom';
import { DeviceListInterface } from '@/recoil/store/checkIn';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { NetworkStatusStore } from '@/recoil/store/common';
import { popupStore } from '@/recoil/store/popup';

/* ———————— 사이니지 LNB 컴포넌트 인터페이스  ———————— */
interface SignageLNBProps {
  deviceList: { classId: string, deviceList: DeviceListInterface[] }
  sessionBaseInfo: FrontSessionBaseInfo | null;
  locationPage: string;
  navigate: ReturnType<typeof useNavigate>;
}

/* ———————— 사이니지 LNB  ———————— */
const SignageLNB = ({ deviceList, sessionBaseInfo, locationPage, navigate }: SignageLNBProps) => {

  // 팝업 state
  const setPopup = useSetRecoilState(popupStore);

  // 네트워크 상태
  const networkStatus = useRecoilValue(NetworkStatusStore);

  // 탭 이동
  const moveTab = (tab: string) => {
    if (networkStatus) {
      navigate(tab);
    } else {
      setPopup({
        TYPE: 'alertSignage',
        CONTENT: 'Please check the network connection and \n try again.',
        BUTTON: 'OK'
      });

    }
  };


  return (
    <div className='container-app-layout'>
      <div className={`container-app-item-box ${(locationPage === 'home' || locationPage === '') && 'selected'}`} onClick={() => moveTab('/home')}>
        <img src={publicMethod.svgClear(TimeTableIcon)} alt={'TimeTableIcon'} draggable={false} />
        <span>Timetable</span>
      </div>
      <div className={`container-app-item-box ${locationPage.includes('notice') && 'selected'}`} onClick={() => moveTab('/notice')}>
        <img src={publicMethod.svgClear(NoticeIcon)} alt={'NoticeIcon'} draggable={false} />
        <span>Notices</span>
      </div>
      {
        deviceList.deviceList.find(device => device.frontType === 'SENSOR') &&
        <div className={`container-app-item-box ${locationPage === 'environment' && 'selected'}`} onClick={() => moveTab('/environment')}>
          <img src={publicMethod.svgClear(MenuClassIcon)} alt={'MenuClassIcon'} draggable={false} />
          <span>Classroom Environment</span>
        </div>
      }
      {
        deviceList.deviceList.find(device => device.frontType === 'CONTROL') &&
        <div className={`container-app-item-box ${locationPage === 'device' && 'selected'}`} onClick={() => moveTab('/device')}>
          {/* <div className={`container-app-item-box ${locationPage === 'device' && 'selected'} disabled`} > */}
          <img src={publicMethod.svgClear(DeviceIcon)} alt={'DeviceIcon'} draggable={false} />
          <span style={{ color: '#EBEBE4 !important' }}>Device Control</span>
        </div>
      }

      {/* {
        sessionBaseInfo?.schoolInfo?.schoolClassification === 'School' &&
        <div className={`container-app-item-box ${locationPage === 'meal' && 'selected'}`} onClick={() => moveTab('/meal')}>
          <img src={publicMethod.svgClear(MealIcon)} alt={'MealIcon'} draggable={false} />
          <span>Meal Information</span>
        </div>
      } */}

      {/* <div className={`container-app-item-box ${locationPage === 'music' && 'selected'}`} onClick={() => moveTab('/music')}>
        <img src={publicMethod.svgClear(MusicIcon)} alt={'MusicIcon'} draggable={false} />
        <span>Music Playback</span>
      </div> */}
      <div className={`container-app-item-box ${locationPage === 'appGroup' && 'selected'}`} onClick={() => moveTab('/appGroup')}>
        <img src={publicMethod.svgClear(AppIcon)} alt={'AppIcon'} draggable={false} />
        <span>App Drawer</span>
      </div>
    </div>
  );


};

export default SignageLNB;

import React, { useEffect, useState } from 'react';
import '@/components/popup/popup.css';
import { useRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import CloseIcon from '@/assets/icon/popup/close_button.svg';               // Dompurify 적용완료
import GroupActivityIcon from '@/assets/icon/common/groupactivity.svg';     // Dompurify 적용완료
import GroupLeader from '@/assets/icon/manage/group_leader.svg';            // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';

/* 학생의 모둠원 확인 팝업 */
const PopupMyGroup = () => {

  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);

  // 팝업 노출 시, 체크박스 초기화
  useEffect(() => {
    if (popup.TYPE === 'myGroup') {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
    }
  }, [popup.TYPE]);

  // 팝업 끌 경우
  const popupClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setPopup((prevData) => ({ ...prevData, TYPE: '', CONTENT: '' }));
      document.body.style.overflow = '';
    }, 100);
  };

  return (
    <>
      {popup.TYPE === 'myGroup' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box command'}>
            <div className={'cmd-lp-top'}>
              <div className='cmd-lp-close-box'>
                <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
              </div>
              <div className='cmd-lp-cover'>
                <img src={publicMethod.svgClear(GroupActivityIcon)} alt={'GroupActivityIcon'} draggable={false} />
                <span>Our Group Members</span> Please check my group members.
              </div>
            </div>

            <div className='cmd-lp-target-box'>
              <div className='cmd-lp-mb-inner'>
                {
                  popup.CONTENT && popup.CONTENT.length! > 0 &&
                  <>
                    {
                      popup.CONTENT.map((item: { division: string; id: string; name: string; number: string | null; position: 'leader' | 'member'; }, index: string) => (
                        <div key={item.id} className={`cmd-lp-mb-item leader ${item.position === 'leader' && 'leader'}`}>
                          <span>{item.number} {item.name}</span>
                          {
                            item.position === 'leader' && <img src={publicMethod.svgClear(GroupLeader)} alt={'GroupLeader'} draggable={false} />
                          }
                        </div>
                      ))
                    }
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      }
    </>


  );
};

export default PopupMyGroup;

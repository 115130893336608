// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import Loadable from '@/components/Loadable';
import SignageLayout from '@/layout/signage/signageLayout';

/* 사이니지 화면 */
const Home = Loadable(lazy(() => import('@/pages/signage/snHome')));
const Notice = Loadable(lazy(() => import('@/pages/signage/snNotice')));
const NoticeDetail = Loadable(lazy(() => import('@/pages/signage/snNoticeDetail')));
const Environment = Loadable(lazy(() => import('@/pages/signage/snEnvironment')));
const Device = Loadable(lazy(() => import('@/pages/signage/snDevice')));
const Meal = Loadable(lazy(() => import('@/pages/signage/snMeal')));
const Music = Loadable(lazy(() => import('@/pages/signage/snMusic')));
const AppGroup = Loadable(lazy(() => import('@/pages/signage/snAppGroup')));

/* 500 에러 화면 */
const ServerError = Loadable(lazy(() => import('@/pages/signage/snServerError')));

const SignageRoutes = {
    path: '/',
    element: <SignageLayout />,
    children: [
        /* url 바로 접근시  */
        { path: '/', element: <Home/> },
        { path: 'home', element: <Home/> },
        { path: 'notice', element: <Notice/> },
        { path: 'noticeDetail/:id', element: <NoticeDetail/> },
        { path: 'environment', element: <Environment/> },
        { path: 'device', element: <Device/> },
        { path: 'meal', element: <Meal/> },
        { path: 'music', element: <Music/> },
        { path: 'appGroup', element: <AppGroup/> },
        { path: 'serverError', element: <ServerError/> },
        /* 404 오류 페이지 접근 시*/
        { path: '*', element: <Home /> }
    ]
};

export default SignageRoutes;

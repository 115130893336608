// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import Loadable from '@/components/Loadable';
import AdminLayout from '@/layout/service/adminLayout';

/* 관리자 교실환경 페이지 */
const AdminEnvironment= Loadable(lazy(() => import('@/pages/service/admin/environment')));

const AdminError = Loadable(lazy(() => import('@/pages/service/common/error')));
const AdminInvalid = Loadable(lazy(() => import('@/pages/service/common/invalid')));



const AdminRoutes = {
    path: '/admin',
    element: <AdminLayout />,
    children: [
        { path: 'environment', element: <AdminEnvironment/> },
        { path: 'error', element: <AdminError/> },
        { path: 'invalid', element: <AdminInvalid/> }
    ]
};

export default AdminRoutes;

import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import '@/components/container/service/navbar/ToplNavigationBar.css';
import { ClassGroupInfoInterface, ClassInfoInterface, UsersInfoInterface } from '@/hooks/services/checkIn';
import { SubGroupInterface } from '@/pages/service/student/learning';
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';
import { getSession } from '@/hooks/common';
import {
  checkInManagementStore,
  groupMiddleModeStore,
  classGroupModeStore
} from '@/recoil/store/checkIn';
import { alarmDotStore, NetworkStatusStore, settingDotStore, toastStore } from '@/recoil/store/common';
import { learningModeStore } from '@/recoil/store/class';
import { withdrawInfoStore } from '@/recoil/store/user';
import { popupStore } from '@/recoil/store/popup';
import MarkIcon from '@/assets/icon/LNB/top_menu_mark_gray.svg';    // Dompurify 적용완료
import MarkIconRed from '@/assets/icon/LNB/top_menu_mark_red.svg';  // Dompurify 적용완료
import MenuBell from '@/assets/icon/LNB/menu_bell.svg';             // Dompurify 적용완료
import MenuMy from '@/assets/icon/LNB/menu_my.svg';                 // Dompurify 적용완료
import MenuSetting from '@/assets/icon/LNB/menu_setting.svg';       // Dompurify 적용완료
import MenuLogout from '@/assets/icon/LNB/menu_logout.svg';         // Dompurify 적용완료
import ActiveMy from '@/assets/icon/LNB/active_my.svg';             // Dompurify 적용완료
import ActiveBell from '@/assets/icon/LNB/active_bell.svg';         // Dompurify 적용완료
import ActiveSetting from '@/assets/icon/LNB/active_setting.svg';   // Dompurify 적용완료
import ModumIcon from '@/assets/icon/TNB/modum.svg';                // Dompurify 적용완료
import ModumClose from '@/assets/icon/TNB/modum-close.svg';         // Dompurify 적용완료
import DisableMy from '@/assets/icon/LNB/disable_my.svg';           // Dompurify 적용완료
import DisableBell from '@/assets/icon/LNB/disable_bell.svg';       // Dompurify 적용완료
import DisableSetting from '@/assets/icon/LNB/disable_setting.svg'; // Dompurify 적용완료
import DisableLogout from '@/assets/icon/LNB/disable_logout.svg';   // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';






interface TNBProps {
  logout: () => void;
  usersInfo: UsersInfoInterface[];
  classInfo: ClassInfoInterface | null;
  classModeChange?: (groupMode: boolean, groupInfo?: ClassGroupInfoInterface) => Promise<boolean>
}


/**  상단, 좌측 메뉴  **/
const TopNavigationBar = ({ logout, usersInfo, classInfo, classModeChange }: TNBProps) => {


  // 페이지 이동
  const navigate = useNavigate();
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 알람 점 설정
  const settingDot = useRecoilValue(settingDotStore);
  // 알람 점 설정
  const alarmDot = useRecoilValue(alarmDotStore);

  // 계정정보
  const { sessionBaseInfo } = getSession();
  // 경로 파악
  const location = useLocation();

  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  const resetPopup = useResetRecoilState(popupStore);
  // 모둠모드 상태
  const classGroupMode = useRecoilValue(classGroupModeStore);
  const resetClassGroupMode = useResetRecoilState(classGroupModeStore);
  // 모둠 중간 상태 (전체모드와 모둠수업 시작의 중간 상태로 클라이언트 데이터)
  const groupMiddleInfo = useRecoilValue(groupMiddleModeStore);
  // 교수학습 상태
  const [learningMode, setLearningMode] = useRecoilState(learningModeStore);
  const resetLearningMode = useResetRecoilState(learningModeStore);

  // 회원 탈퇴 상태
  const withdrawStatus = useRecoilValue(withdrawInfoStore);
  // 체크인 관리 ( 각 서버 정상접근중인지 여부 확인 )
  const checkInManagement = useRecoilValue(checkInManagementStore);

  const [isMirroring, setIsMirroring] = useState(false);  // 미러링 중인지 여부

  const [checkInClassName, setCheckInClassName] = useState<string>('');
  // 나의 모둠
  const [mySubGroup, setMySubGroup] = useState<SubGroupInterface | null>(null);
  // 네트워크 상태
  const networkStatus = useRecoilValue(NetworkStatusStore);

  const [isComingBack, setIsComingBack] = useState(false);  // 모둠모드 였다가 다시 되돌아갈 경우

  // 최초 접근 시
  useEffect(() => {
    // 그룹 모드 초기화
    resetLearningMode();
    // 그룹 모드 초기화
    resetClassGroupMode();
  }, []);


  // url 변경 시
  useEffect(() => {
    // alert(location.pathname);
  }, [location]);




  useEffect(() => {
    const handleClassModeChange = async () => {
      if (popup.RESERVE === 'decisionGroupModeOff') {
        if (classModeChange) {
          const classModeChangeIssue = await classModeChange(false);
          if (classModeChangeIssue) {
            setToast({ TYPE: 'alert', CONTENT: 'Group class has been ended.', TIME: 3 });
          } else {
            setToast({ TYPE: 'alert', CONTENT: 'Cannot end the group class.', TIME: 3 });
          }
        }
        resetPopup();
      }
    };
    handleClassModeChange();

  }, [popup.RESERVE]);


  useEffect(() => {
    if (checkInManagement.checkInState) {
      const getClassGrade = sessionBaseInfo?.mappingClass?.find(item => item.classId === classInfo?.classId)?.grade;
      const getClassClassName = sessionBaseInfo?.mappingClass?.find(item => item.classId === classInfo?.classId)?.className;
      setCheckInClassName(`${getClassGrade} - ${getClassClassName} Class`);
    } else {
      setCheckInClassName('');
    }
  }, [checkInManagement.checkInState]);



  // userInfo 변경에 따른 미러링 여부 판단
  useEffect(() => {
    const mirroringUser = usersInfo.filter(item => item.mirrorPosition !== 'NON');
    if (mirroringUser.length > 0) {
      setIsMirroring(true);
    } else {
      setIsMirroring(false);
    }
  }, [usersInfo]);

  // 모둠모드일 경우, 자신이 속한 모둠을 찾아서 상태에 저장
  useEffect(() => {
    console.log('LearningMode 제어', classGroupMode, isComingBack);
    if (classGroupMode.groupMode) {
      const foundSubGroup = classGroupMode.groupInfo?.subGroupList.find(subGroup =>
        subGroup.subGroupMember.some(member => member.id === sessionBaseInfo?.baseInfo.userId)
      );
      if (foundSubGroup) {
        setMySubGroup(foundSubGroup);
      }

      setLearningMode((prevData) => ({ ...prevData, TYPE: 3 }));
    } else if (isComingBack) {
      setLearningMode((prev) => ({ ...prev, TYPE: 2 }));
      setIsComingBack(false);
    } else {
      // 최초 접근 시
      setLearningMode((prev) => ({ ...prev, TYPE: 0 }));
    }
  }, [classGroupMode]);


  // 모둠모드 시작, 종료
  const groupModeOnOff = async () => {
    if (isMirroring) {
      setToast({ TYPE: 'alert', CONTENT: 'Please stop the ongoing screen sharing.', TIME: 3 });
    } else if (learningMode.TYPE === 2 && !classGroupMode.groupMode) {

      console.log('모둠모드: groupMiddleInfo', JSON.stringify(groupMiddleInfo));

      if (groupMiddleInfo) {
        const subGroupTemp = groupMiddleInfo.subGroupList.map(subGroup => ({
          ...subGroup,
          webRtcRoomId: 0
        }));
        const groupMiddleInfoTemp = {
          ...groupMiddleInfo,
          subGroupList: subGroupTemp
        };

        if (classModeChange) {

          const classModeChangeIssue = await classModeChange(true, groupMiddleInfoTemp);

          setIsComingBack(true);

          if (classModeChangeIssue) {
            setToast({ TYPE: 'alert', CONTENT: 'Group class has started.', TIME: 3 });
          } else {
            setToast({ TYPE: 'alert', CONTENT: 'Unable to start group class.', TIME: 3 });
          }
        }
      }

    } else if (learningMode.TYPE === 3 && classGroupMode.groupMode) {
      setPopup((prevData) => ({ ...prevData, TYPE: 'decisionGroupModeOff', CONTENT: 'Do you want to end the group class?', BUTTON: 'End' }));
    }
  };

  // Network error popup
  const networkErrorPopup = () => {
    setPopup({ TYPE: 'alert', TITLE: 'Notification', CONTENT: 'Please check your network connection and try again.', BUTTON: 'OK', RESERVE: '' });
  };



  /**  변수 설정  **/
  return (
    <div className={`top-navigation-bar ${classGroupMode.groupMode && 'group'}`}>

      {/* For teachers */}
      {
        sessionBaseInfo?.baseInfo.role === 'teacher' &&
        <>
          {
            // Full mode (0), Full mode + Before group setting (1)
            (learningMode.TYPE === 0 || learningMode.TYPE === 1)
              ?
              <>
                {
                  checkInManagement.checkInState
                    ?
                    <div className='top-navigation-message red'>
                      <img src={publicMethod.svgClear(MarkIconRed)} alt={'MarkIconRed'} draggable={false} />
                      <span>You have entered {checkInClassName}.</span>
                    </div>
                    :
                    <div className='top-navigation-message'>
                      <img src={publicMethod.svgClear(MarkIcon)} alt={'MarkIcon'} draggable={false} />
                      <span>You have not entered the classroom yet.</span>
                    </div>
                }
              </>
              :
              <div className='top-navigation-group'>
                <div className='top-navigation-group-title'>
                  {
                    learningMode.TYPE === 2 && <span>Please start the group class.</span>
                  }
                  {
                    learningMode.TYPE === 3 &&
                    <>
                      <img src={publicMethod.svgClear(ModumIcon)} alt={'ModumIcon'} draggable={false} />
                      <span>Group class is ongoing.</span>
                    </>
                  }
                </div>

                {
                  learningMode.TYPE === 2 &&
                  <button className={`top-navigation-group-button ${(isMirroring || location.pathname === '/service/teacher/groupManage/create') && 'gray'}`} onClick={(!isMirroring && location.pathname !== '/service/teacher/groupManage/create') ? groupModeOnOff : undefined}>
                    <span>Start Group Class</span>
                  </button>
                }

                {
                  learningMode.TYPE === 3 &&
                  <button className={`top-navigation-group-button ${isMirroring && 'gray'}`} onClick={!isMirroring ? groupModeOnOff : undefined}>
                    <img src={publicMethod.svgClear(ModumClose)} alt={'ModumClose'} draggable={false} />
                    <span>End Group Class</span>
                  </button>
                }
              </div>
          }
        </>
      }

      {/* For students */}
      {
        sessionBaseInfo?.baseInfo.role === 'student' &&
        <>
          {
            classGroupMode.groupMode
              ?
              <div className='top-navigation-group'>
                <div className='top-navigation-group-title'>
                  <img src={publicMethod.svgClear(ModumIcon)} alt={'ModumIcon'} draggable={false} />
                  <span>Group class is ongoing.</span>
                </div>
                {
                  mySubGroup && mySubGroup.subGroupMember.some(member => member.id === sessionBaseInfo?.baseInfo.userId) &&
                  (() => {
                    const member = mySubGroup.subGroupMember.find(member => member.id === sessionBaseInfo?.baseInfo.userId);
                    const isLeader = member!.position === 'leader';
                    return (
                      <button className={'top-navigation-group-button'}>
                        <span>You are the {isLeader ? 'leader' : 'member'} of {mySubGroup.subGroupName} group.</span>
                      </button>
                    );
                  })()
                }
              </div>
              :
              <>
                {
                  checkInManagement.checkInState
                    ?
                    <div className='top-navigation-message red'>
                      <img src={publicMethod.svgClear(MarkIconRed)} alt={'MarkIconRed'} draggable={false} />
                      <span>You have entered {checkInClassName}.</span>
                    </div>
                    :
                    <div className='top-navigation-message'>
                      <img src={publicMethod.svgClear(MarkIcon)} alt={'MarkIcon'} draggable={false} />
                      <span>You have not entered the classroom yet.</span>
                    </div>
                }
              </>
          }
        </>
      }

      {
        withdrawStatus
          ?
          <div className='top-navigation-button'>
            <div className='tnb-item disable'>
              <img src={publicMethod.svgClear(DisableMy)} alt={'MenuMy'} draggable={false} />
            </div>
            <div className='tnb-item disable'>
              <img src={publicMethod.svgClear(DisableBell)} alt={'DisableBell'} draggable={false} />
            </div>
            <div className='tnb-item disable'>
              <img src={publicMethod.svgClear(DisableSetting)} alt={'DisableSetting'} draggable={false} />
            </div>
            <div className='tnb-item disable'>
              <img src={publicMethod.svgClear(DisableLogout)} alt={'DisableLogout'} draggable={false} />
            </div>
          </div>
          :
          <div className='top-navigation-button'>
            <div className={`tnb-item ${location.pathname.includes('/mypage') && 'purple'}`} onClick={networkStatus ? () => navigate(`/service/${sessionBaseInfo?.baseInfo.role}/mypage`) : networkErrorPopup}>
              {location.pathname.includes('/mypage') ? <img src={publicMethod.svgClear(ActiveMy)} alt={'ActiveMy'} draggable={false} /> : <img src={publicMethod.svgClear(MenuMy)} alt={'MenuMy'} draggable={false} />}
            </div>
            <div className={`tnb-item ${location.pathname.includes('/alert') && 'purple'}`} onClick={networkStatus ? () => navigate(`/service/${sessionBaseInfo?.baseInfo.role}/alert`) : networkErrorPopup}>
              {
                location.pathname.includes('/alert') ? <img src={publicMethod.svgClear(ActiveBell)} alt={'ActiveBell'} draggable={false} /> : <img src={publicMethod.svgClear(MenuBell)} alt={'MenuBell'} draggable={false} />
              }
              {
                alarmDot && <div className='red-alarm'> </div>
              }
            </div>
            <div className={`tnb-item ${location.pathname.includes('/setting') && 'purple'}`} onClick={networkStatus ? () => navigate(`/service/${sessionBaseInfo?.baseInfo.role}/setting`) : networkErrorPopup}>
              {
                location.pathname.includes('/setting') ? <img src={publicMethod.svgClear(ActiveSetting)} alt={'ActiveSetting'} draggable={false} /> : <img src={publicMethod.svgClear(MenuSetting)} alt={'MenuSetting'} draggable={false} />
              }
              {
                settingDot && <div className='red-alarm'> </div>
              }
            </div>
            <div className='tnb-item'>
              <img src={publicMethod.svgClear(MenuLogout)} alt={'MenuLogout'} draggable={false} onClick={networkStatus ? () => logout() : networkErrorPopup} />
            </div>
          </div>
      }
    </div>
  );

};

export default TopNavigationBar;

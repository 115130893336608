import '@/components/popup/popup.css';
import ScrollBar from '@/components/common/scroll/ScrollBar';
import popupGroupHook from '@/hooks/components/popup/learning/popupGroup';
import { ClassInfoInterface } from '@/hooks/services/checkIn';
import CloseIcon from '@/assets/icon/popup/close_button.svg';           // Dompurify 적용완료
import GroupIcon from '@/assets/icon/learning/group-activity.svg';      // Dompurify 적용완료
import CheckedIcon from '@/assets/icon/popup/popup-checked.svg';        // Dompurify 적용완료
import UncheckedIcon from '@/assets/icon/popup/popup-unchecked.svg';    // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';




interface PopupGroupProps {
  classInfo: ClassInfoInterface | null
}

/* 지난 모둠설정 불러오기 */
const PopupGroup = ({ classInfo }: PopupGroupProps) => {


  const { popup, popupClose, isVisible, handleCheckboxChange, selectGroup, groupSelect, checkboxItems } = popupGroupHook(classInfo);


  return (
    <>
      {popup.TYPE === 'group' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <main className={'layout-popup-box'}>

            <div className='cmd-lp-top'>
              <div className='cmd-lp-close-box'>
                <img src={publicMethod.svgClear(CloseIcon)} alt={'CloseIcon'} draggable={false} onClick={() => popupClose()} />
              </div>

              <div className='cmd-lp-cover'>
                <img src={publicMethod.svgClear(GroupIcon)} alt={'GroupIcon'} draggable={false} />
                <span>Load Previous Group Settings</span> Please select the group settings to load.
              </div>
            </div>

            <div className='cmd-lp-content group'>
              <ScrollBar scrollbarHeight="100%">
                {checkboxItems.map(item => (
                  <div key={item.groupId} className={`cmd-message-item ${item.checked ? 'selected' : ''}`} onClick={() => handleCheckboxChange(item.groupId, item.groupName)}>
                    <img src={item.checked ? publicMethod.svgClear(CheckedIcon) : publicMethod.svgClear(UncheckedIcon)} alt={item.checked ? 'checkbox' : 'uncheckbox'} draggable={false} />
                    <span>{item.groupName}</span>
                  </div>
                ))}
              </ScrollBar>
            </div>
            <div className={`cmd-lp-button ${(groupSelect.groupId === '' || groupSelect.groupName === '') && 'gray'}`} onClick={(groupSelect.groupId !== '' && groupSelect.groupName !== '') ? () => selectGroup() : undefined}>
              Load
            </div>
          </main>

        </div>
      }
    </>
  );

};

export default PopupGroup;


import React from 'react';
import '@/components/popup/popup.css';
import popupCommandCancelHook from '@/hooks/components/popup/command/popupCommandCancel';


/* 화면공유, 파일/링크/메시지전송 등 진행 중 취소 시 */
const PopupCommandCancel = () => {


  const { popup, isVisible, popupClose, popupConfirm } = popupCommandCancelHook();

  return (
    <>
      {popup.TYPE === 'commandCancel' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box decision'}>


            <article className={'layout-popup-content'}>
              <span>{popup.CONTENT}</span>
            </article>


            <div className='layout-popup-button-box'>
              <button className={'layout-popup-button double gray'} onClick={() => popupClose()}>
                Close
              </button>
              <button className={'layout-popup-button double'} onClick={() => popupConfirm()}>
                {popup.BUTTON}
              </button>
            </div>

          </div>
        </div>
      }
    </>






  );
};

export default PopupCommandCancel;

import React from 'react';
import '@/components/popup/popup.css';
import popupDecisionHook from '@/hooks/components/popup/common/popupDecision';


interface PopupDecisionProps {
  sendScreenLock?: (type: 'all' | 'one', lock: boolean, id?: string) => Promise<boolean>
}

/* 결정 팝업 */
const PopupDecision = ({ sendScreenLock }: PopupDecisionProps) => {

  const { popup, popupClose, popupConfirm, isVisible } = popupDecisionHook(sendScreenLock);

  return (
    <>
      {popup.TYPE.includes('decision') &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          {/* <div className={`layout-popup-box decision ${popup.TYPE === 'decisionWithdrawal' && 'long'}`}> */}
          <div className={'layout-popup-box decision'}>
            <article className={'layout-popup-content'}>
              <span>{popup.CONTENT}</span>
            </article>

            <div className='layout-popup-button-box'>
              <button className={'layout-popup-button double gray'} onClick={() => popupClose()}>
                {(popup.TYPE === 'decisionCancelInquiry'
                  || popup.TYPE === 'decisionDeleteTeacherAlarm'
                  || popup.TYPE === 'decisionCancelNotice'
                  || popup.TYPE === 'decisionCancelDeviceRequest'
                  || popup.TYPE === 'decisionMakeAgreement'
                ) ? 'Close' : 'Cancel'}
              </button>

              <button className={'layout-popup-button double'} onClick={() => popupConfirm()}>
                {popup.BUTTON}
              </button>
            </div>

          </div>
        </div>
      }
    </>




  );
};

export default PopupDecision;

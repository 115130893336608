import React from 'react';
import '@/components/popup/popup.css';
import popupTutorialHook from '@/hooks/components/popup/common/popupTutorial';
import CloseButton from '@/assets/icon/popup/close_button.svg';     // Dompurify 적용완료
import TutorialIcon from '@/assets/icon/popup/tutorial_icon.svg';   // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';




/* 튜토리얼 안내 팝업 */
const PopupTutorial = () => {

  const { popupTutorial, isVisible, popupClose, moveTutorial } = popupTutorialHook();


  return (
    <>
      {popupTutorial.TYPE === 'tutorial' &&
        <div className={`layout-popup tutorial ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>

              {/* Popup Close Button */}
              <figure className='layout-popup-close-button-box'>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} onClick={() => popupClose()} />
              </figure>

              {/* Popup Icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(TutorialIcon)} alt={'TutorialIcon'} draggable={false} />
              </figure>

              {/* Popup Title */}
              <div className='layout-popup-title'>Tutorial Guide</div>

              {/* Popup Detailed Content */}
              <div className='layout-popup-common-content tutorial'>
                <span>Is this your first time with Advanced School?</span>
                <span>Check out the tutorial</span>
              </div>

            </article>

            <div className='layout-popup-button-box'>
              <button className={'layout-popup-button double white'} onClick={() => popupClose()}>
                Close
              </button>
              <button className={'layout-popup-button double'} onClick={moveTutorial}>
                View Tutorial
              </button>
            </div>

          </div>

        </div>
      }
    </>
  );

};

export default PopupTutorial
  ;

/* AIoT Server Response Code */
export enum AIoTResponseCode {
  SUCCESS = 'success',
  FAIL = 'failure',
  TIMEOUT = 'TIMEOUT'
}

/* AIoT Server Response code mapping */
export const AIoTMappingCode: { [key in AIoTResponseCode]: string } = {
  [AIoTResponseCode.SUCCESS]: 'SUCCESS',	// Api 호출 성공
  [AIoTResponseCode.FAIL]: 'FAIL',		// 성공 이외 모든 처리
  [AIoTResponseCode.TIMEOUT]: 'TIMEOUT'
};







/* Gooroomee Server Response Code */
export enum GooroomeeResponseCode {
  SUCCESS = 'RT_SUCCESS',
  FAIL = 'RT_FAIL',
  TIMEOUT = 'TIMEOUT'
}

/* Gooroomee Server Response code mapping */
export const GooroomeeMappingCode: { [key in GooroomeeResponseCode]: string } = {
  [GooroomeeResponseCode.SUCCESS]: 'SUCCESS',	// Api 호출 성공
  [GooroomeeResponseCode.FAIL]: 'FAIL',		// 성공 이외 모든 처리
  [GooroomeeResponseCode.TIMEOUT]: 'TIMEOUT'
};








/* Stream Server Response Code */
export enum StreamResponseCode {
  SUCCESS = '0',
  FAIL = '1',
  TIMEOUT = 'TIMEOUT'
}

/* Stream Server Response code mapping */
export const StreamMappingCode: { [key in StreamResponseCode]: string } = {
  [StreamResponseCode.SUCCESS]: 'SUCCESS',	// Api 호출 성공
  [StreamResponseCode.FAIL]: 'FAIL',			// 성공 이외 모든 처리
  [StreamResponseCode.TIMEOUT]: 'TIMEOUT'
};







/* Chat Server Response Code */
export enum ChatResponseCode {
  SUCCESS = 'CODE_SUCCESS',
  CODE_ERROR_COPY_FILE_TO_STORAGE = 'CODE_ERROR_COPY_FILE_TO_STORAGE',
  FAIL = 'CODE_FAIL',
  TIMEOUT = 'TIMEOUT'
}

/* Chat Server Response code mapping */
export const ChatMappingCode: { [key in ChatResponseCode]: string } = {
  [ChatResponseCode.SUCCESS]: 'SUCCESS',									// Api 호출 성공
  [ChatResponseCode.CODE_ERROR_COPY_FILE_TO_STORAGE]: 'STORAGE_ERROR',	// 파일 저장 에러
  [ChatResponseCode.FAIL]: 'FAIL',										// 성공, 파일저장 에러 이외 모든 처리
  [ChatResponseCode.TIMEOUT]: 'TIMEOUT'									// 타임아웃
};







/* Content Server Response Code */
export enum ContentResponseCode {
  OK = 'OK',
  UNAUTHORIZED = '401',
  SERVER_ERROR = '500',
  FAIL = 'FAIL',
  TIMEOUT = 'TIMEOUT'
}

/* Content Server Response code mapping */
export const ContentMappingCode: { [key in ContentResponseCode]: string } = {
  [ContentResponseCode.OK]: 'OK',							// Api 호출 성공
  [ContentResponseCode.UNAUTHORIZED]: 'TOKEN_EXPIRED',	// 토큰 만료
  [ContentResponseCode.SERVER_ERROR]: 'FAIL',
  [ContentResponseCode.FAIL]: 'FAIL',						// 성공, 토큰만료 이외 모든 처리
  [ContentResponseCode.TIMEOUT]: 'TIMEOUT'				// 타임아웃
};





/* Core Server code mapping log code */
export enum CoreResponseErrorCode {
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  GM_INITIAL_LOGIN = 'GM_INITIAL_LOGIN',
  CLASS_NOT_FOUND = 'CLASS_NOT_FOUND',
  FORCE_LOGOUT = 'FORCE_LOGOUT',
  USER_BLOCKED = 'USER_BLOCKED',
  PRIVILEGES_PERMISSION = 'PRIVILEGES_PERMISSION',
  PASSWORD_CHANGE_REQUIRE = 'PASSWORD_CHANGE_REQUIRE',
  INSUFFICIENT_PERMISSIONS = 'INSUFFICIENT_PERMISSIONS',
  UNAUTHORIZED = 'UNAUTHORIZED',
  MISSING_ARGUMENTS = 'MISSING_ARGUMENTS',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  SCHOOL_NOT_FOUND = 'SCHOOL_NOT_FOUND',
  SEMESTER_NOT_FOUND = 'SEMESTER_NOT_FOUND',
  SEMESTER_SETTING_NOT_FOUND = 'SEMESTER_SETTING_NOT_FOUND',
  INVALID_SCHOOL_CONTRACT_STATUS = 'INVALID_SCHOOL_CONTRACT_STATUS',
  RESET_PASSWORD_MUST_NOT_MATCH = 'RESET_PASSWORD_MUST_NOT_MATCH',
  GOOGLE_USER_FOUND = 'GOOGLE_USER_FOUND',
  WHALESPACE_USER_FOUND = 'WHALESPACE_USER_FOUND',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  BLOCK_FIND_BY_EMAIL = 'BLOCK_FIND_BY_EMAIL',
  BLOCK_FIND_BY_PHONE = 'BLOCK_FIND_BY_PHONE',
  ALREADY_LOGIN = 'ALREADY_LOGIN',
  TOO_MANY_LOGIN_ATTEMPT = 'TOO_MANY_LOGIN_ATTEMPT',
  NEED_VERIFICATION = 'NEED_VERIFICATION',
  INVALID_IPIN_REDIRECT_URL = 'INVALID_IPIN_REDIRECT_URL',
  INVALID_OAUTH_TOKEN = 'INVALID_OAUTH_TOKEN',
  USERNAME_EXISTED = 'USERNAME_EXISTED',
  REGISTER_SCHOOL_NOT_FOUND = 'REGISTER_SCHOOL_NOT_FOUND',
  MISSING_GUARDIAN_INFO = 'MISSING_GUARDIAN_INFO',
  INVALID_USER_ROLE = 'INVALID_USER_ROLE',
  DUPLICATE_NEIS_ID = 'DUPLICATE_NEIS_ID',
  INVALID_GUARDIAN_BIRTH = 'INVALID_GUARDIAN_BIRTH',
  INVALID_GUARDIAN_GENDER = 'INVALID_GUARDIAN_GENDER',
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  TOO_MANY_REQUEST_CODE_ATTEMPT = 'TOO_MANY_REQUEST_CODE_ATTEMPT',
  INVALID_CODE = 'INVALID_CODE',
  EXPIRED_CODE = 'EXPIRED_CODE',
  EMAIL_VERIFY_REQUEST_EXCEEDED = 'EMAIL_VERIFY_REQUEST_EXCEEDED',
  PHONE_VERIFY_REQUEST_EXCEEDED = 'PHONE_VERIFY_REQUEST_EXCEEDED',
  REQUEST_CODE_NOTE_FOUND = 'REQUEST_CODE_NOTE_FOUND',
  DEVICE_CLASSROOM_NOTFOUND = 'DEVICE_CLASSROOM_NOTFOUND',
  DEVICE_CLASSROOM_DEPEND_ON_GROUP_NOTFOUND = 'DEVICE_CLASSROOM_DEPEND_ON_GROUP_NOTFOUND',
  DEVICE_NOT_FOUND = 'DEVICE_NOT_FOUND',
  DEVICE_SCHOOL_NOT_FOUND = 'DEVICE_SCHOOL_NOT_FOUND',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  DEVICE_SITUATION_INVALID = 'DEVICE_SITUATION_INVALID',
  DEVICE_AIOT_FAILURE = 'DEVICE_AIOT_FAILURE',
  WRONG_PAYLOAD_SPECIAL_CLASSROOM = 'WRONG_PAYLOAD_SPECIAL_CLASSROOM',
  WRONG_PAYLOAD_GENERAL_CLASSROOM = 'WRONG_PAYLOAD_GENERAL_CLASSROOM',
  ASSISTANT_NOT_FOUND = 'ASSISTANT_NOT_FOUND',
  TEACHER_NOT_FOUND = 'TEACHER_NOT_FOUND',
  CLASSROOM_NOT_FOUND = 'CLASSROOM_NOT_FOUND',
  USER_CLASSROOM_NOT_FOUND = 'USER_CLASSROOM_NOT_FOUND',
  MAX_LENGTH_CLASSROOM_NAME = 'MAX_LENGTH_CLASSROOM_NAME',
  SENDER_NOT_FOUND = 'SENDER_NOT_FOUND',
  RECEIVER_NOT_FOUND = 'RECEIVER_NOT_FOUND',
  MESSAGE_NOT_FOUND = 'MESSAGE_NOT_FOUND',
  CLASSES_HAVE_NOT_EXISTED = 'CLASSES_HAVE_NOT_EXISTED',
  DUPLICATE_CLASS_NUMBER = 'DUPLICATE_CLASS_NUMBER',
  STUDENT_MISSING_CLASS_OR_GRADE = 'STUDENT_MISSING_CLASS_OR_GRADE',
  UN_EXISTED_STUDENTS = 'UN_EXISTED_STUDENTS',
  NOT_DURING_PROMOTION_TIME = 'NOT_DURING_PROMOTION_TIME',
  PROMOTION_STATUS_NOT_VALID = 'PROMOTION_STATUS_NOT_VALID',
  GET_SCHOOL_CALENDAR_TIME_SETTING_FAILED = 'GET_SCHOOL_CALENDAR_TIME_SETTING_FAILED',
  GET_SCHOOL_TIME_SETTING_DETAIL_FAILED = 'GET_SCHOOL_TIME_SETTING_DETAIL_FAILED',
  GET_SCHOOL_SEMESTER_TIME_SETTING_FAILED = 'GET_SCHOOL_SEMESTER_TIME_SETTING_FAILED',
  GET_SCHOOL_TIMETABLE_UPLOAD_SETTINGS_FAILED = 'GET_SCHOOL_TIMETABLE_UPLOAD_SETTINGS_FAILED',
  INVALID_SEMESTER_TIME = 'INVALID_SEMESTER_TIME',
  REQUIRED_START_AND_END_DATE = 'REQUIRED_START_AND_END_DATE',
  MISSING_START_OR_END_DATE_BETWEEN_GAP_TIME = 'MISSING_START_OR_END_DATE_BETWEEN_GAP_TIME',
  OUT_OF_AVAILABLE_RANGE = 'OUT_OF_AVAILABLE_RANGE',
  GET_SCHOOL_FAILED = 'GET_SCHOOL_FAILED',
  SETTING_NOT_FOUND = 'SETTING_NOT_FOUND',
  CHANGE_STUDENT_SLOT_TIMETABLE_FAILED = 'CHANGE_STUDENT_SLOT_TIMETABLE_FAILED',
  CHANGE_STUDENT_SLOT_TIMETABLE_FAILED_STUDENT_NOT_FOUND = 'CHANGE_STUDENT_SLOT_TIMETABLE_FAILED_STUDENT_NOT_FOUND',
  CREATE_SLOT_TIMETABLE_FAILED = 'CREATE_SLOT_TIMETABLE_FAILED',
  CREATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT = 'CREATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT',
  CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM = 'CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM',
  CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER = 'CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER',
  DELETE_CLASS_TIMETABLE_RESPONSE_FAILED = 'DELETE_CLASS_TIMETABLE_RESPONSE_FAILED',
  DELETE_CLASSROOM_TIMETABLE_RESPONSE_FAILED = 'DELETE_CLASSROOM_TIMETABLE_RESPONSE_FAILED',
  DELETE_SLOT_TIMETABLE_FAILED = 'DELETE_SLOT_TIMETABLE_FAILED',
  DELETE_TEACHER_TIMETABLE_RESPONSE_FAILED = 'DELETE_TEACHER_TIMETABLE_RESPONSE_FAILED',
  GET_CLASS_TIMETABLE_DETAILS_FAILED = 'GET_CLASS_TIMETABLE_DETAILS_FAILED',
  GET_LIST_CLASS_TIMETABLE_FAILED = 'GET_LIST_CLASS_TIMETABLE_FAILED',
  GET_LIST_CLASSROOM_TIMETABLE_FAILED = 'GET_LIST_CLASSROOM_TIMETABLE_FAILED',
  GET_LIST_STUDENTS_IN_CLASS_FAILED = 'GET_LIST_STUDENTS_IN_CLASS_FAILED',
  GET_LIST_TEACHER_TIMETABLE_FAILED = 'GET_LIST_TEACHER_TIMETABLE_FAILED',
  GET_TEACHER_TIMETABLE_DETAILS_FAILED = 'GET_TEACHER_TIMETABLE_DETAILS_FAILED',
  GET_CLASSROOM_TIMETABLE_DETAILS_FAILED = 'GET_CLASSROOM_TIMETABLE_DETAILS_FAILED',
  RESET_TIMETABLE_RESPONSE_FAILED = 'RESET_TIMETABLE_RESPONSE_FAILED',
  UPDATE_SLOT_TIMETABLE_FAILED = 'UPDATE_SLOT_TIMETABLE_FAILED',
  UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER = 'UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER',
  UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM = 'UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM',
  UPDATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT = 'UPDATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT',
  UPDATE_SLOT_TIMETABLE_FAILED_SUBJECT_NOT_FOUNDED = 'UPDATE_SLOT_TIMETABLE_FAILED_SUBJECT_NOT_FOUNDED',
  CHECK_OUT_FAILED = 'CHECK_OUT_FAILED',
  CLASS_SLOT_NOT_FOUND = 'CLASS_SLOT_NOT_FOUND',
  CHECK_IN_SLOT_NOT_FOUND = 'CHECK_IN_SLOT_NOT_FOUND',
  CLASS_SESSION_NOT_FOUND = 'CLASS_SESSION_NOT_FOUND',
  EXTEND_CHECK_IN_NOT_ALLOWED = 'EXTEND_CHECK_IN_NOT_ALLOWED',
  SCHOOL_TIME_SETTING_NOT_FOUND = 'SCHOOL_TIME_SETTING_NOT_FOUND',
  ALREADY_CHECK_IN_TEACHER = 'ALREADY_CHECK_IN_TEACHER',
  CHECK_IN_NOT_ALLOWED = 'CHECK_IN_NOT_ALLOWED',
  CREATE_REASON_UNMASK_FAILED = 'CREATE_REASON_UNMASK_FAILED',
  GET_USER_LIST_MASKING_FAILED = 'GET_USER_LIST_MASKING_FAILED',
  GET_USER_DETAIL_FAILED = 'GET_USER_DETAIL_FAILED',
  CANNOT_SEND_NEW_PASSWORD = 'CANNOT_SEND_NEW_PASSWORD',
  INVALID_USER_STATUS = 'INVALID_USER_STATUS',
  INVALID_USER_GRADE_CLASS = 'INVALID_USER_GRADE_CLASS',
  SUBJECT_NOT_FOUND = 'SUBJECT_NOT_FOUND',
  SCHOOL_ADMIN_EXCEEDED = 'SCHOOL_ADMIN_EXCEEDED',
}

/* Core Server Response Code */
export enum CoreResponseCode {
  GET_USER_POLICY_SUCCESS = 'GET_USER_POLICY_SUCCESS',
  UPDATE_USER_POLICY_SUCCESS = 'UPDATE_USER_POLICY_SUCCESS',
  MISSING_POLICY_AGREE = 'MISSING_POLICY_AGREE',
  USER_NOT_FOUND = 'USER_NOT_FOUND',
  FORCE_LOGOUT = 'FORCE_LOGOUT',
  USER_BLOCKED = 'USER_BLOCKED',
  PRIVILEGES_PERMISSION = 'PRIVILEGES_PERMISSION',
  PASSWORD_CHANGE_REQUIRE = 'PASSWORD_CHANGE_REQUIRE',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  RESET_PASSWORD_MUST_NOT_MATCH = 'RESET_PASSWORD_MUST_NOT_MATCH',
  GOOGLE_USER_FOUND = 'GOOGLE_USER_FOUND',
  WHALESPACE_USER_FOUND = 'WHALESPACE_USER_FOUND',
  ACCOUNT_LOCKED = 'ACCOUNT_LOCKED',
  BLOCK_FIND_BY_EMAIL = 'BLOCK_FIND_BY_EMAIL',
  BLOCK_FIND_BY_PHONE = 'BLOCK_FIND_BY_PHONE',
  ALREADY_LOGIN = 'ALREADY_LOGIN',
  TOO_MANY_LOGIN_ATTEMPT = 'TOO_MANY_LOGIN_ATTEMPT',
  NEED_VERIFICATION = 'NEED_VERIFICATION',
  TOO_MANY_REQUEST_CODE_ATTEMPT = 'TOO_MANY_REQUEST_CODE_ATTEMPT',
  CLASS_SESSION_NOT_FOUND = 'CLASS_SESSION_NOT_FOUND',
  EXTEND_CHECK_IN_NOT_ALLOWED = 'EXTEND_CHECK_IN_NOT_ALLOWED',
  ALREADY_CHECK_IN_TEACHER = 'ALREADY_CHECK_IN_TEACHER',
  CHECK_IN_NOT_ALLOWED = 'CHECK_IN_NOT_ALLOWED',
  FAIL = 'FAIL',
  PASSWORD_CHANGED = 'PASSWORD_CHANGED',
  PASSWORD_CORRECT = 'PASSWORD_CORRECT',
  USER_FOUND = 'USER_FOUND',
  ID_NOT_FOUND = 'ID_NOT_FOUND',
  CODE_SEND_SUCCESS = 'CODE_SEND_SUCCESS',
  STUDENT_TEACHER_LOGIN_SUCCESS = 'STUDENT_TEACHER_LOGIN_SUCCESS',
  RESET_PASSWORD_LOGIN = 'RESET_PASSWORD_LOGIN',
  GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS',
  IPIN_REQUEST_SUCCESS = 'IPIN_REQUEST_SUCCESS',
  MOBILE_VALIDATE_SUCCESS = 'MOBILE_VALIDATE_SUCCESS',
  IPIN_VALIDATE_SUCCESS = 'IPIN_VALIDATE_SUCCESS',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_PHONE = 'INVALID_PHONE',
  RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS',
  DELAY_PASSWORD_CHANGE_SUCCESS = 'DELAY_PASSWORD_CHANGE_SUCCESS',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  CODE_VERIFY_SUCCESS = 'CODE_VERIFY_SUCCESS',
  CREDENTIAL_VALIDATED = 'CREDENTIAL_VALIDATED',
  DEVICE_QUERY_SUCCESS = 'DEVICE_QUERY_SUCCESS',
  REPORT_MAINTENANCE_SUCCESS = 'REPORT_MAINTENANCE_SUCCESS',
  CHECK_IN_SUCCESS = 'CHECK_IN_SUCCESS',
  ALREADY_CHECKED_IN = 'ALREADY_CHECKED_IN',
  EXTEND_CHECK_IN_SUCCESS = 'EXTEND_CHECK_IN_SUCCESS',
  CHECK_OUT_SUCCESS = 'CHECK_OUT_SUCCESS',
  UPDATE_TEACHER_CLASS_SETTING_SUCCESS = 'UPDATE_TEACHER_CLASS_SETTING_SUCCESS',
  CODE_SUCCESS = 'CODE_SUCCESS',
  EXTEND_SESSION_SUCCESS = 'EXTEND_SESSION_SUCCESS',
  UNIQUE_CONSTRAINT_VIOLATION_EXCEPTION = 'UNIQUE_CONSTRAINT_VIOLATION_EXCEPTION',
  UPDATE_SUCCESS = 'UPDATE_SUCCESS',
  USER_WITHDRAWAL_SUCCESS = 'USER_WITHDRAWAL_SUCCESS',
  QUERY_SUCCESS = 'QUERY_SUCCESS',
  GET_TEACHER_CLASS_SETTING_SUCCESS = 'GET_TEACHER_CLASS_SETTING_SUCCESS',
  DELETE_MESSAGE_LOGS_SUCCESS = 'DELETE_MESSAGE_LOGS_SUCCESS',
  GET_SUBJECT_MEMEBER_SUCCESS = 'GET_SUBJECT_MEMEBER_SUCCESS',
  GET_CLASSROOM_DEVICE_SUCCESS = 'GET_CLASSROOM_DEVICE_SUCCESS',
  GET_NEIS_SCHOOL_MEALS_SUCCESS = 'GET_NEIS_SCHOOL_MEALS_SUCCESS',
  GET_SCHOOL_WEATHER_CODE_SUCCESS = 'GET_SCHOOL_WEATHER_CODE_SUCCESS',
  GET_CLASSROOM_MEMBER_SUCCESS = 'GET_CLASSROOM_MEMBER_SUCCESS',
  GET_CLASSROOM_TIMETABLE_DETAILS_SUCCESS = 'GET_CLASSROOM_TIMETABLE_DETAILS_SUCCESS',
  GET_CLASS_TIMETABLE_DETAILS_SUCCESS = 'GET_CLASS_TIMETABLE_DETAILS_SUCCESS',
  GET_TEACHER_TIMETABLE_DETAILS_SUCCESS = 'GET_TEACHER_TIMETABLE_DETAILS_SUCCESS',
  NEW_PASSWORD_SEND = 'NEW_PASSWORD_SEND',
  INVALID_REQUEST = 'INVALID_REQUEST',
  TIMEOUT = 'TIMEOUT'
}

/* Core Server Response code mapping : front code */
export const CoreMappingCode: { [key in CoreResponseCode]: string } = {
  [CoreResponseCode.GET_USER_POLICY_SUCCESS]: 'SUCCESS',
  [CoreResponseCode.UPDATE_USER_POLICY_SUCCESS]: 'SUCCESS',
  [CoreResponseCode.MISSING_POLICY_AGREE]: 'MISSING_POLICY_AGREE',
  [CoreResponseCode.TIMEOUT]: 'TIMEOUT',
  [CoreResponseCode.FORCE_LOGOUT]: 'LOGOUT',			// 중복 로그인 ( 로그인 화면으로 이동 )
  [CoreResponseCode.UNAUTHORIZED]: 'TOKEN_EXPIRED',	// 토큰 만료 ( 로그인 화면으로 이동 )
  [CoreResponseCode.FAIL]: 'FAIL',					// 정의한 코드 이외 모든 코드 통일
  [CoreResponseCode.PASSWORD_CHANGED]: 'SUCCESS',					// 비밀번호 변경 성공
  [CoreResponseCode.RESET_PASSWORD_MUST_NOT_MATCH]: 'SAME',		// 기존 비밀번호와 중복
  [CoreResponseCode.PASSWORD_CORRECT]: 'SUCCESS',				// 비밀번호 일치
  [CoreResponseCode.TOO_MANY_LOGIN_ATTEMPT]: 'LOCK',			// 계정 잠김
  [CoreResponseCode.USER_FOUND]: 'SUCCESS',				// 아이디 찾기 성공
  [CoreResponseCode.ID_NOT_FOUND]: 'FAIL',				// 아이디 찾기 실패
  [CoreResponseCode.USER_NOT_FOUND]: 'NOT_FOUND',			// 계정 없음
  [CoreResponseCode.GOOGLE_USER_FOUND]: 'GOOGLE',			// Google 계정
  [CoreResponseCode.WHALESPACE_USER_FOUND]: 'WHALE',		// Whale 계정
  [CoreResponseCode.CODE_SEND_SUCCESS]: 'SUCCESS',				// 인증코드 발송 성공
  [CoreResponseCode.TOO_MANY_REQUEST_CODE_ATTEMPT]: 'TOO_MANY',	// 인증코드 요청 횟수 초과
  [CoreResponseCode.ACCOUNT_LOCKED]: 'BLOCK_ALL',					// 계정 잠김
  [CoreResponseCode.BLOCK_FIND_BY_EMAIL]: 'BLOCK_EMAIL',			// 이메일 찾기 차단
  [CoreResponseCode.BLOCK_FIND_BY_PHONE]: 'BLOCK_PHONE',			// 전화번호 찾기 차단
  // [CoreResponseCode.USER_NOT_FOUND]: 'NOT_FOUND',					// 계정 없음
  [CoreResponseCode.STUDENT_TEACHER_LOGIN_SUCCESS]: 'SUCCESS',		// 로그인 성공
  [CoreResponseCode.USER_BLOCKED]: 'BLOCKED',						// 계정 잠김
  [CoreResponseCode.PRIVILEGES_PERMISSION]: 'PERMISSION',			// 권한 없음
  [CoreResponseCode.ALREADY_LOGIN]: 'ALREADY',						// 중복 로그인
  [CoreResponseCode.NEED_VERIFICATION]: 'VERIFY',					// 인증 필요
  [CoreResponseCode.PASSWORD_CHANGE_REQUIRE]: 'PASSWORD',			// 비밀번호 변경 필요
  [CoreResponseCode.RESET_PASSWORD_LOGIN]: 'RESET_PASSWORD',			// 비밀번호 재설정 필요
  [CoreResponseCode.GET_PROFILE_SUCCESS]: 'SUCCESS',					// 프로필 조회 성공
  [CoreResponseCode.IPIN_REQUEST_SUCCESS]: 'SUCCESS',					// Nice 토큰발급 성공
  [CoreResponseCode.MOBILE_VALIDATE_SUCCESS]: 'SUCCESS',				// 모바일 인증 성공
  [CoreResponseCode.IPIN_VALIDATE_SUCCESS]: 'SUCCESS',				// IPIN 인증 성공
  [CoreResponseCode.INVALID_EMAIL]: 'INVALID',						// 이메일 유효성 체크
  [CoreResponseCode.INVALID_PHONE]: 'INVALID',						// 전화번호 유효성 체크
  [CoreResponseCode.RESET_PASSWORD_SUCCESS]: 'SUCCESS',				// 비밀번호 재설정 성공
  [CoreResponseCode.DELAY_PASSWORD_CHANGE_SUCCESS]: 'SUCCESS',			// 비밀번호 변경 연기 성공
  [CoreResponseCode.INVALID_CREDENTIAL]: 'FAIL',						// 인증 실패
  [CoreResponseCode.LOGIN_SUCCESS]: 'SUCCESS',						// 로그인 성공
  [CoreResponseCode.CODE_VERIFY_SUCCESS]: 'SUCCESS',					// 코드 검증 성공
  [CoreResponseCode.CREDENTIAL_VALIDATED]: 'SUCCESS', 				// 중복검사 성공
  [CoreResponseCode.DEVICE_QUERY_SUCCESS]: 'SUCCESS',					// 디바이스 로그인 성공
  [CoreResponseCode.REPORT_MAINTENANCE_SUCCESS]: 'SUCCESS',				// 점검요청 성공
  [CoreResponseCode.CHECK_IN_SUCCESS]: 'SUCCESS',						// 체크인 성공
  [CoreResponseCode.ALREADY_CHECKED_IN]: 'SUCCESS',					// 이미 체크인
  [CoreResponseCode.CHECK_IN_NOT_ALLOWED]: 'NOT_ALLOWED',				// 체크인 불가
  [CoreResponseCode.ALREADY_CHECK_IN_TEACHER]: 'OTHER_TEACHER',			// 다른 선생님 체크인
  [CoreResponseCode.EXTEND_CHECK_IN_SUCCESS]: 'SUCCESS',				// 체크인 연장 성공
  [CoreResponseCode.CHECK_OUT_SUCCESS]: 'SUCCESS',						// 체크아웃 성공
  [CoreResponseCode.UPDATE_TEACHER_CLASS_SETTING_SUCCESS]: 'SUCCESS',	// 모둠/AIoT/수업상태 셋팅 성공
  [CoreResponseCode.CODE_SUCCESS]: 'SUCCESS',							// 코드 검증 성공
  [CoreResponseCode.EXTEND_SESSION_SUCCESS]: 'SUCCESS',					// 세션 연장 성공
  [CoreResponseCode.UPDATE_SUCCESS]: 'SUCCESS',							// 업데이트 성공
  [CoreResponseCode.UNIQUE_CONSTRAINT_VIOLATION_EXCEPTION]: 'UNIQUE_CONSTRAINT_VIOLATION_EXCEPTION',	// 중복제약조건
  [CoreResponseCode.USER_WITHDRAWAL_SUCCESS]: 'SUCCESS',				// 회원탈퇴 성공
  [CoreResponseCode.QUERY_SUCCESS]: 'SUCCESS',							// 조회 성공
  [CoreResponseCode.GET_TEACHER_CLASS_SETTING_SUCCESS]: 'SUCCESS',		// 조회 성공
  [CoreResponseCode.DELETE_MESSAGE_LOGS_SUCCESS]: 'SUCCESS',			// 메시지 삭제 성공
  [CoreResponseCode.GET_SUBJECT_MEMEBER_SUCCESS]: 'SUCCESS',				// 조회 성공
  [CoreResponseCode.GET_CLASSROOM_DEVICE_SUCCESS]: 'SUCCESS',			// 조회 성공
  [CoreResponseCode.GET_NEIS_SCHOOL_MEALS_SUCCESS]: 'SUCCESS',			// 조회 성공
  [CoreResponseCode.GET_SCHOOL_WEATHER_CODE_SUCCESS]: 'SUCCESS',			// 조회 성공
  [CoreResponseCode.GET_CLASSROOM_MEMBER_SUCCESS]: 'SUCCESS',				// 조회 성공
  [CoreResponseCode.GET_CLASSROOM_TIMETABLE_DETAILS_SUCCESS]: 'SUCCESS',	// 조회 성공
  [CoreResponseCode.GET_CLASS_TIMETABLE_DETAILS_SUCCESS]: 'SUCCESS',		// 조회 성공
  [CoreResponseCode.GET_TEACHER_TIMETABLE_DETAILS_SUCCESS]: 'SUCCESS',		// 조회 성공
  [CoreResponseCode.NEW_PASSWORD_SEND]: 'SUCCESS',							// 비밀번호 초기화 성공
  [CoreResponseCode.INVALID_REQUEST]: 'FAIL',
  [CoreResponseCode.EXTEND_CHECK_IN_NOT_ALLOWED]: 'NOT_ALLOWED',
  [CoreResponseCode.CLASS_SESSION_NOT_FOUND]: 'FAIL'
};

/* Core Server Response code mapping : log code */
export const CoreMappingLogCode: { [key in CoreResponseErrorCode]: string } = {
  [CoreResponseErrorCode.USER_NOT_FOUND]: 'C0005',
  [CoreResponseErrorCode.GM_INITIAL_LOGIN]: 'C0006',
  [CoreResponseErrorCode.CLASS_NOT_FOUND]: 'C0007',
  [CoreResponseErrorCode.FORCE_LOGOUT]: 'C0008',
  [CoreResponseErrorCode.USER_BLOCKED]: 'C0009',
  [CoreResponseErrorCode.PRIVILEGES_PERMISSION]: 'C0010',
  [CoreResponseErrorCode.PASSWORD_CHANGE_REQUIRE]: 'C0011',
  [CoreResponseErrorCode.INSUFFICIENT_PERMISSIONS]: 'C0012',
  [CoreResponseErrorCode.UNAUTHORIZED]: 'C0013',
  [CoreResponseErrorCode.MISSING_ARGUMENTS]: 'C0014',
  [CoreResponseErrorCode.INVALID_CREDENTIAL]: 'C0015',
  [CoreResponseErrorCode.SCHOOL_NOT_FOUND]: 'C0016',
  [CoreResponseErrorCode.SEMESTER_NOT_FOUND]: 'C0017',
  [CoreResponseErrorCode.SEMESTER_SETTING_NOT_FOUND]: 'C0018',
  [CoreResponseErrorCode.INVALID_SCHOOL_CONTRACT_STATUS]: 'C0019',
  [CoreResponseErrorCode.RESET_PASSWORD_MUST_NOT_MATCH]: 'C0020',
  [CoreResponseErrorCode.GOOGLE_USER_FOUND]: 'C0021',
  [CoreResponseErrorCode.WHALESPACE_USER_FOUND]: 'C0022',
  [CoreResponseErrorCode.ACCOUNT_LOCKED]: 'C0023',
  [CoreResponseErrorCode.BLOCK_FIND_BY_EMAIL]: 'C0024',
  [CoreResponseErrorCode.BLOCK_FIND_BY_PHONE]: 'C0025',
  [CoreResponseErrorCode.ALREADY_LOGIN]: 'C0026',
  [CoreResponseErrorCode.TOO_MANY_LOGIN_ATTEMPT]: 'C0028',
  [CoreResponseErrorCode.NEED_VERIFICATION]: 'C0029',
  [CoreResponseErrorCode.INVALID_IPIN_REDIRECT_URL]: 'C0030',
  [CoreResponseErrorCode.INVALID_OAUTH_TOKEN]: 'C0031',
  [CoreResponseErrorCode.USERNAME_EXISTED]: 'C0032',
  [CoreResponseErrorCode.REGISTER_SCHOOL_NOT_FOUND]: 'C0033',
  [CoreResponseErrorCode.MISSING_GUARDIAN_INFO]: 'C0034',
  [CoreResponseErrorCode.INVALID_USER_ROLE]: 'C0035',
  [CoreResponseErrorCode.DUPLICATE_NEIS_ID]: 'C0036',
  [CoreResponseErrorCode.INVALID_GUARDIAN_BIRTH]: 'C0037',
  [CoreResponseErrorCode.INVALID_GUARDIAN_GENDER]: 'C0038',
  [CoreResponseErrorCode.INVALID_ARGUMENT]: 'C0039',
  [CoreResponseErrorCode.TOO_MANY_REQUEST_CODE_ATTEMPT]: 'C0040',
  [CoreResponseErrorCode.INVALID_CODE]: 'C0041',
  [CoreResponseErrorCode.EXPIRED_CODE]: 'C0042',
  [CoreResponseErrorCode.EMAIL_VERIFY_REQUEST_EXCEEDED]: 'C0043',
  [CoreResponseErrorCode.PHONE_VERIFY_REQUEST_EXCEEDED]: 'C0044',
  [CoreResponseErrorCode.REQUEST_CODE_NOTE_FOUND]: 'C0045',
  [CoreResponseErrorCode.DEVICE_CLASSROOM_NOTFOUND]: 'C0046',
  [CoreResponseErrorCode.DEVICE_CLASSROOM_DEPEND_ON_GROUP_NOTFOUND]: 'C0047',
  [CoreResponseErrorCode.DEVICE_NOT_FOUND]: 'C0048',
  [CoreResponseErrorCode.DEVICE_SCHOOL_NOT_FOUND]: 'C0049',
  [CoreResponseErrorCode.PERMISSION_DENIED]: 'C0050',
  [CoreResponseErrorCode.DEVICE_SITUATION_INVALID]: 'C0051',
  [CoreResponseErrorCode.DEVICE_AIOT_FAILURE]: 'C0052',
  [CoreResponseErrorCode.WRONG_PAYLOAD_SPECIAL_CLASSROOM]: 'C0053',
  [CoreResponseErrorCode.WRONG_PAYLOAD_GENERAL_CLASSROOM]: 'C0054',
  [CoreResponseErrorCode.ASSISTANT_NOT_FOUND]: 'C0055',
  [CoreResponseErrorCode.TEACHER_NOT_FOUND]: 'C0056',
  [CoreResponseErrorCode.CLASSROOM_NOT_FOUND]: 'C0057',
  [CoreResponseErrorCode.USER_CLASSROOM_NOT_FOUND]: 'C0058',
  [CoreResponseErrorCode.MAX_LENGTH_CLASSROOM_NAME]: 'C0059',
  [CoreResponseErrorCode.SENDER_NOT_FOUND]: 'C0062',
  [CoreResponseErrorCode.RECEIVER_NOT_FOUND]: 'C0063',
  [CoreResponseErrorCode.MESSAGE_NOT_FOUND]: 'C0064',
  [CoreResponseErrorCode.CLASSES_HAVE_NOT_EXISTED]: 'C0065',
  [CoreResponseErrorCode.DUPLICATE_CLASS_NUMBER]: 'C0066',
  [CoreResponseErrorCode.STUDENT_MISSING_CLASS_OR_GRADE]: 'C0067',
  [CoreResponseErrorCode.UN_EXISTED_STUDENTS]: 'C0068',
  [CoreResponseErrorCode.NOT_DURING_PROMOTION_TIME]: 'C0069',
  [CoreResponseErrorCode.PROMOTION_STATUS_NOT_VALID]: 'C0070',
  [CoreResponseErrorCode.GET_SCHOOL_CALENDAR_TIME_SETTING_FAILED]: 'C0071',
  [CoreResponseErrorCode.GET_SCHOOL_TIME_SETTING_DETAIL_FAILED]: 'C0072',
  [CoreResponseErrorCode.GET_SCHOOL_SEMESTER_TIME_SETTING_FAILED]: 'C0073',
  [CoreResponseErrorCode.GET_SCHOOL_TIMETABLE_UPLOAD_SETTINGS_FAILED]: 'C0074',
  [CoreResponseErrorCode.INVALID_SEMESTER_TIME]: 'C0075',
  [CoreResponseErrorCode.REQUIRED_START_AND_END_DATE]: 'C0076',
  [CoreResponseErrorCode.MISSING_START_OR_END_DATE_BETWEEN_GAP_TIME]: 'C0077',
  [CoreResponseErrorCode.OUT_OF_AVAILABLE_RANGE]: 'C0078',
  [CoreResponseErrorCode.GET_SCHOOL_FAILED]: 'C0079',
  [CoreResponseErrorCode.SETTING_NOT_FOUND]: 'C0080',
  [CoreResponseErrorCode.CHANGE_STUDENT_SLOT_TIMETABLE_FAILED]: 'C0081',
  [CoreResponseErrorCode.CHANGE_STUDENT_SLOT_TIMETABLE_FAILED_STUDENT_NOT_FOUND]: 'C0082',
  [CoreResponseErrorCode.CREATE_SLOT_TIMETABLE_FAILED]: 'C0083',
  [CoreResponseErrorCode.CREATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT]: 'C0084',
  [CoreResponseErrorCode.CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM]: 'C0085',
  [CoreResponseErrorCode.CREATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER]: 'C0086',
  [CoreResponseErrorCode.DELETE_CLASS_TIMETABLE_RESPONSE_FAILED]: 'C0087',
  [CoreResponseErrorCode.DELETE_CLASSROOM_TIMETABLE_RESPONSE_FAILED]: 'C0088',
  [CoreResponseErrorCode.DELETE_SLOT_TIMETABLE_FAILED]: 'C0089',
  [CoreResponseErrorCode.DELETE_TEACHER_TIMETABLE_RESPONSE_FAILED]: 'C0090',
  [CoreResponseErrorCode.GET_CLASS_TIMETABLE_DETAILS_FAILED]: 'C0091',
  [CoreResponseErrorCode.GET_LIST_CLASS_TIMETABLE_FAILED]: 'C0093',
  [CoreResponseErrorCode.GET_LIST_CLASSROOM_TIMETABLE_FAILED]: 'C0094',
  [CoreResponseErrorCode.GET_LIST_STUDENTS_IN_CLASS_FAILED]: 'C0095',
  [CoreResponseErrorCode.GET_LIST_TEACHER_TIMETABLE_FAILED]: 'C0096',
  [CoreResponseErrorCode.GET_TEACHER_TIMETABLE_DETAILS_FAILED]: 'C0097',
  [CoreResponseErrorCode.GET_CLASSROOM_TIMETABLE_DETAILS_FAILED]: 'C0098',
  [CoreResponseErrorCode.RESET_TIMETABLE_RESPONSE_FAILED]: 'C0099',
  [CoreResponseErrorCode.UPDATE_SLOT_TIMETABLE_FAILED]: 'C0100',
  [CoreResponseErrorCode.UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_USER]: 'C0101',
  [CoreResponseErrorCode.UPDATE_SLOT_TIMETABLE_FAILED_DUPLICATE_CLASSROOM]: 'C0102',
  [CoreResponseErrorCode.UPDATE_SLOT_TIMETABLE_FAILED_INVALID_INPUT]: 'C0103',
  [CoreResponseErrorCode.UPDATE_SLOT_TIMETABLE_FAILED_SUBJECT_NOT_FOUNDED]: 'C0104',
  [CoreResponseErrorCode.CHECK_OUT_FAILED]: 'C0105',
  [CoreResponseErrorCode.CLASS_SLOT_NOT_FOUND]: 'C0107',
  [CoreResponseErrorCode.CHECK_IN_SLOT_NOT_FOUND]: 'C0108',
  [CoreResponseErrorCode.CLASS_SESSION_NOT_FOUND]: 'C0109',
  [CoreResponseErrorCode.EXTEND_CHECK_IN_NOT_ALLOWED]: 'C0110',
  [CoreResponseErrorCode.SCHOOL_TIME_SETTING_NOT_FOUND]: 'C0111',
  [CoreResponseErrorCode.ALREADY_CHECK_IN_TEACHER]: 'C0112',
  [CoreResponseErrorCode.CHECK_IN_NOT_ALLOWED]: 'C0113',
  [CoreResponseErrorCode.CREATE_REASON_UNMASK_FAILED]: 'C0114',
  [CoreResponseErrorCode.GET_USER_LIST_MASKING_FAILED]: 'C0115',
  [CoreResponseErrorCode.GET_USER_DETAIL_FAILED]: 'C0116',
  [CoreResponseErrorCode.CANNOT_SEND_NEW_PASSWORD]: 'C0117',
  [CoreResponseErrorCode.INVALID_USER_STATUS]: 'C0118',
  [CoreResponseErrorCode.INVALID_USER_GRADE_CLASS]: 'C0119',
  [CoreResponseErrorCode.SUBJECT_NOT_FOUND]: 'C0121',
  [CoreResponseErrorCode.SCHOOL_ADMIN_EXCEEDED]: 'C0122'

};








/* Status Server code mapping log code */
export enum StatusResponseErrorCode {
  TIMEOUT = 'TIMEOUT',
  SCHOOL_DAILY_SCHEDULE_NOT_EXISTS = 'SCHOOL_DAILY_SCHEDULE_NOT_EXISTS',
  TIME_TABLE_NOT_EXISTS = 'TIME_TABLE_NOT_EXISTS',
  CLASSROOM_NOT_EXISTS = 'CLASSROOM_NOT_EXISTS',
  USER_NOT_EXISTS = 'USER_NOT_EXISTS',
  REDIS_LECHER_STATE_NOT_EXISTS = 'REDIS_LECHER_STATE_NOT_EXISTS',
  LECTURE_NOT_EXISTS = 'LECTURE_NOT_EXISTS',
  INVALID_TOKEN = 'INVALID_TOKEN',
  WRONG_TOKEN = 'WRONG_TOKEN',
  NOT_EXISTS_TOKEN = 'NOT_EXISTS_TOKEN',
  INVALID_AUTHORITY = 'INVALID_AUTHORITY',
  NOT_EXISTS_AUTHORITY = 'NOT_EXISTS_AUTHORITY',
  CHECK_IN_DATA_NOT_EXISTS = 'CHECK_IN_DATA_NOT_EXISTS',
  CHECK_IN_LECTURE_NOT_EXISTS = 'CHECK_IN_LECTURE_NOT_EXISTS',
  CHECK_IN_NOT_ALLOWED_CLASSROOM = 'CHECK_IN_NOT_ALLOWED_CLASSROOM',
  CHECK_IN_STUDENT_NOT_ALLOWED_BREAK_TIME = 'CHECK_IN_STUDENT_NOT_ALLOWED_BREAK_TIME',
  CHECK_IN_DEVICE_NOT_ALLOWED_BREAK_TIME = 'CHECK_IN_DEVICE_NOT_ALLOWED_BREAK_TIME',
  CHECK_IN_EXTENSION_NOT_AUTHORITY = 'CHECK_IN_EXTENSION_NOT_AUTHORITY',
  CHECK_IN_EXTENSION_NOT_ALLOWED = 'CHECK_IN_EXTENSION_NOT_ALLOWED',
  CHECK_IN_ALREADY_ANOTHER_TEACHER = 'CHECK_IN_ALREADY_ANOTHER_TEACHER',
  CHECK_IN_NOT_ALLOWED_CLASS_NOT_MATCH = 'CHECK_IN_NOT_ALLOWED_CLASS_NOT_MATCH',
  TALK_PERMISSION_USER_NOT_EXISTS = 'TALK_PERMISSION_USER_NOT_EXISTS',
  TALK_DATA_NOT_EXISTS = 'TALK_DATA_NOT_EXISTS',
  MIRROR_ALREADY_JOIN_USER_EXISTS = 'MIRROR_ALREADY_JOIN_USER_EXISTS',
  MIRROR_INFO_DATA_NOT_EXISTS = 'MIRROR_INFO_DATA_NOT_EXISTS',
  MIRROR_PERMISSION_AUTHORITY_NOT_EXISTS = 'MIRROR_PERMISSION_AUTHORITY_NOT_EXISTS',
  MIRROR_REQUEST_USER_NOT_EXISTS = 'MIRROR_REQUEST_USER_NOT_EXISTS',
  MIRROR_PERMISSION_USER_NOT_EXISTS = 'MIRROR_PERMISSION_USER_NOT_EXISTS',
  MIRROR_USER_NOT_EXISTS = 'MIRROR_USER_NOT_EXISTS',
  REDIS_LECHER_MIRROR_STATE_NOT_EXISTS = 'REDIS_LECHER_MIRROR_STATE_NOT_EXISTS',
  MEDIA_BOX_AUTHENTICATION_FAIL = 'MEDIA_BOX_AUTHENTICATION_FAIL',
  CHECK_IN_NOT_ALLOWED_CLASS_TEACHER_NOT_EXISTS = 'CHECK_IN_NOT_ALLOWED_CLASS_TEACHER_NOT_EXISTS',
  CHECK_IN_NOT_ALLOWED_CLASSROOM_NOT_EXISTS = 'CHECK_IN_NOT_ALLOWED_CLASSROOM_NOT_EXISTS',
  CLASSROOM_CLASS_NOT_MATCHED_EXISTS = 'CLASSROOM_CLASS_NOT_MATCHED_EXISTS',
  TALK_REQUEST_USER_NOT_MATCH = 'TALK_REQUEST_USER_NOT_MATCH',
  TALK_PERMISSION_DATA_WRONG = 'TALK_PERMISSION_DATA_WRONG',
  TALK_DELETE_NOT_AUTHORITY = 'TALK_DELETE_NOT_AUTHORITY',
  CRYPTO_ERROR = 'CRYPTO_ERROR',
  DECRYPT_ERROR = 'DECRYPT_ERROR',
  INVALID_KEY = 'INVALID_KEY',
  INVALID_ALGORITHM = 'INVALID_ALGORITHM',
  INVALID_PADDING = 'INVALID_PADDING',
  INVALID_BLOCK_SIZE = 'INVALID_BLOCK_SIZE',
  INVALID_CIPHER = 'INVALID_CIPHER',
  SETTING_NOT_EXISTS = 'SETTING_NOT_EXISTS',
  REDIS_LECHER_GROUP_STATE_NOT_EXISTS = 'REDIS_LECHER_GROUP_STATE_NOT_EXISTS',
  MIRROR_SUB_GROUP_ID_NOT_EXISTS = 'MIRROR_SUB_GROUP_ID_NOT_EXISTS',
  NORMAL_MIRROR_ALREADY_STATE = 'NORMAL_MIRROR_ALREADY_STATE',
  REST_CALL_ERROR = 'REST_CALL_ERROR'
}

/* Core Server Response code mapping : log code */
export const StatusMappingLogCode: { [key in StatusResponseErrorCode]: string } = {
  [StatusResponseErrorCode.SCHOOL_DAILY_SCHEDULE_NOT_EXISTS]: 'S0001',
  [StatusResponseErrorCode.TIME_TABLE_NOT_EXISTS]: 'S0002',
  [StatusResponseErrorCode.CLASSROOM_NOT_EXISTS]: 'S0003',
  [StatusResponseErrorCode.USER_NOT_EXISTS]: 'S0004',
  [StatusResponseErrorCode.REDIS_LECHER_STATE_NOT_EXISTS]: 'S0005',
  [StatusResponseErrorCode.LECTURE_NOT_EXISTS]: 'S0006',
  [StatusResponseErrorCode.INVALID_TOKEN]: 'S0007',
  [StatusResponseErrorCode.WRONG_TOKEN]: 'S0008',
  [StatusResponseErrorCode.NOT_EXISTS_TOKEN]: 'S0009',
  [StatusResponseErrorCode.INVALID_AUTHORITY]: 'S0010',
  [StatusResponseErrorCode.NOT_EXISTS_AUTHORITY]: 'S0011',
  [StatusResponseErrorCode.CHECK_IN_DATA_NOT_EXISTS]: 'S0012',
  [StatusResponseErrorCode.CHECK_IN_LECTURE_NOT_EXISTS]: 'S0013',
  [StatusResponseErrorCode.CHECK_IN_NOT_ALLOWED_CLASSROOM]: 'S0014',
  [StatusResponseErrorCode.CHECK_IN_STUDENT_NOT_ALLOWED_BREAK_TIME]: 'S0015',
  [StatusResponseErrorCode.CHECK_IN_DEVICE_NOT_ALLOWED_BREAK_TIME]: 'S0016',
  [StatusResponseErrorCode.CHECK_IN_EXTENSION_NOT_AUTHORITY]: 'S0017',
  [StatusResponseErrorCode.CHECK_IN_EXTENSION_NOT_ALLOWED]: 'S0018',
  [StatusResponseErrorCode.CHECK_IN_ALREADY_ANOTHER_TEACHER]: 'S0019',
  [StatusResponseErrorCode.CHECK_IN_NOT_ALLOWED_CLASS_NOT_MATCH]: 'S0020',
  [StatusResponseErrorCode.TALK_PERMISSION_USER_NOT_EXISTS]: 'S0021',
  [StatusResponseErrorCode.TALK_DATA_NOT_EXISTS]: 'S0022',
  [StatusResponseErrorCode.MIRROR_ALREADY_JOIN_USER_EXISTS]: 'S0023',
  [StatusResponseErrorCode.MIRROR_INFO_DATA_NOT_EXISTS]: 'S0024',
  [StatusResponseErrorCode.MIRROR_PERMISSION_AUTHORITY_NOT_EXISTS]: 'S0025',
  [StatusResponseErrorCode.MIRROR_REQUEST_USER_NOT_EXISTS]: 'S0026',
  [StatusResponseErrorCode.MIRROR_PERMISSION_USER_NOT_EXISTS]: 'S0027',
  [StatusResponseErrorCode.MIRROR_USER_NOT_EXISTS]: 'S0028',
  [StatusResponseErrorCode.REDIS_LECHER_MIRROR_STATE_NOT_EXISTS]: 'S0029',
  [StatusResponseErrorCode.MEDIA_BOX_AUTHENTICATION_FAIL]: 'S0030',
  [StatusResponseErrorCode.CHECK_IN_NOT_ALLOWED_CLASS_TEACHER_NOT_EXISTS]: 'S0031',
  [StatusResponseErrorCode.CHECK_IN_NOT_ALLOWED_CLASSROOM_NOT_EXISTS]: 'S0032',
  [StatusResponseErrorCode.CLASSROOM_CLASS_NOT_MATCHED_EXISTS]: 'S0033',
  [StatusResponseErrorCode.TALK_REQUEST_USER_NOT_MATCH]: 'S0034',
  [StatusResponseErrorCode.TALK_PERMISSION_DATA_WRONG]: 'S0035',
  [StatusResponseErrorCode.TALK_DELETE_NOT_AUTHORITY]: 'S0036',
  [StatusResponseErrorCode.CRYPTO_ERROR]: 'S0037',
  [StatusResponseErrorCode.DECRYPT_ERROR]: 'S0038',
  [StatusResponseErrorCode.INVALID_KEY]: 'S0039',
  [StatusResponseErrorCode.INVALID_ALGORITHM]: 'S0040',
  [StatusResponseErrorCode.INVALID_PADDING]: 'S0041',
  [StatusResponseErrorCode.INVALID_BLOCK_SIZE]: 'S0042',
  [StatusResponseErrorCode.INVALID_CIPHER]: 'S0043',
  [StatusResponseErrorCode.SETTING_NOT_EXISTS]: 'S0044',
  [StatusResponseErrorCode.REDIS_LECHER_GROUP_STATE_NOT_EXISTS]: 'S0045',
  [StatusResponseErrorCode.MIRROR_SUB_GROUP_ID_NOT_EXISTS]: 'S0046',
  [StatusResponseErrorCode.NORMAL_MIRROR_ALREADY_STATE]: 'S0047',
  [StatusResponseErrorCode.TIMEOUT]: 'S0048',
  [StatusResponseErrorCode.REST_CALL_ERROR]: 'S0049'
};

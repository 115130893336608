import { contentApi } from '@/services/network';
import {
    GroupListManagementInterface,
    GroupManagementInterface,
    SubGroupInterface
} from '@/recoil/store/teacherManagement';

/* ###################################
 * ############# 모둠 삭제 #############
 * ################################### */


/* function interface */
export interface functionReq {
    token: string;
    parentId: number;
    removeGroupId: number;
}

export interface functionRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}




/* Api Interface */
interface apiReq {
    parentId: number;
    removeGroupIds: number[];
}

interface apiRes {
    code: string
    status: number
    message: string
    data?: GroupListManagementInterface[]
}


export const removeGroup = async(request: functionReq): Promise<functionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/setting/remove-group`;
    const method = 'POST';

    const req: apiReq = {
        parentId: request.parentId,
        removeGroupIds: [request.removeGroupId]
    };

    console.log('그룹 삭제 Api Request', req);

    const res = await contentApi<apiReq, apiRes>(endPoint, req, method, request.token, null);

    console.log('그룹 삭제 Api Response', res);

    if (res.code === 'OK') {
        return {
            code: 'OK',
            status: res.status,
            message: '그룹 삭제 성공',
            data: res.data
        };
    }
    else {
        return {
            code: 'FAIL',
            status: res.status,
            message: '그룹 삭제 실패'
        };
    }

};

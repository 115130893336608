import { contentApi } from '@/services/network';

/* #####################################
 * ############# 분류코드조회 #############
 * ##################################### */


/* function interface */
export interface FunctionReq {
    token: string
    type: string
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: CommonCodeInterface[]
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
    data: CommonCodeInterface[]
}

export interface CommonCodeInterface {
    code1: string
    code2: string
    createdBy: string
    createdById: string
    createdDate: string
    id: string
    lastModifiedBy: string
    lastModifiedById: string
    lastModifiedDate: string
    status: string
    type: string
    typeText: string
    value1: string
    value2: string
}



export const getCodeList = async(request: FunctionReq): Promise<FunctionRes> => {
    let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/code/find-type-all?type=${request.type}`;
    const method = 'GET';

    return await contentApi<any, ApiRes>(endPoint, {}, method, request.token, null);
};

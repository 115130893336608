import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { popupNestingStore, popupStore } from '@/recoil/store/popup';
import React, { useEffect, useState } from 'react';
import { toastStore } from '@/recoil/store/common';
import { studentTargetStore } from '@/recoil/store/class';
import { getSession } from '@/hooks/common';
import { MirrorOpenRequestInterface, UsersInfoBoxInterface, UsersInfoInterface } from '@/hooks/services/checkIn';
import { blendedStateStore } from '@/recoil/store/blended';
import { linkOpen } from '@/services/native';
import { classGroupModeStore } from '@/recoil/store/checkIn';

/* 학생의 전송 대상 팝업 HOOK */
export default (
  usersInfo: UsersInfoInterface[],
  usersInfoBox: UsersInfoBoxInterface[],
  setUsersInfoBox: React.Dispatch<React.SetStateAction<UsersInfoBoxInterface[]>>,
  sendPermissionRequestMessage: (request: { type: 'file' | 'link' | 'msg', targetInfo: { id: string, division: string }[], file?: File[], link?: string, msg?: string }) => Promise<boolean>,
  mirrorOpenRequest: (request: MirrorOpenRequestInterface) => Promise<boolean>,
  requestJoinBlendedStreamLearning?: (userList: string[], roomId: string) => void
) => {

  const { sessionBaseInfo } = getSession();
  // 팝업 state
  const [popup, setPopup] = useRecoilState(popupStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 링크 복사 완료 토스트
  const [copyToast, setCopyToast] = useState<boolean>(false);
  // 중첩 팝업 state
  // 팝업 state
  const setPopupNesting = useSetRecoilState(popupNestingStore);
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 학습 시, 명령 변수
  const [studentTarget, setStudentTarget] = useRecoilState(studentTargetStore);
  // userinfo 데이터 -> 클라이언트 데이터로 복제
  const [targetMemberData, setTargetMemberData] = useState<UsersInfoInterface[]>(usersInfo);
  // 블렌디드 수업 상태
  const blendedState = useRecoilValue(blendedStateStore);
  // 모둠모드 상태
  const classGroupMode = useRecoilValue(classGroupModeStore);


  useEffect(() => {
    setTargetMemberData(usersInfo);
  }, [usersInfo, usersInfoBox]);



  // 팝업 노출 시, 체크박스 초기화
  useEffect(() => {
    if (popup.TYPE.includes('studentTarget') || popup.TYPE.includes('blendedTarget')) {
      setTimeout(() => {
        setIsVisible(true);
        document.body.style.overflow = 'hidden';
      }, 100);
      resetTargetCheckbox();
    }
  }, [popup.TYPE]);





  useEffect(() => {
    if (popup.TYPE !== 'blendedTarget') { // 블렌디드 타겟이 아닌 학생의 수업모드 일 경우
      // 선택된 인원을 [] 배열로 정의
      const selectedTarget = usersInfoBox.filter(item => item.isChecked).map(item => ({ id: item.id, division: item.division, name: item.name, ...(item.number && { number: Number(item.number) }) }));
      // alert(JSON.stringify(selectedTarget));
      setStudentTarget(prevState => ({ ...prevState, TARGET: selectedTarget }));
    }
  }, [usersInfoBox]);


  // 팝업 끌 경우
  const popupClose = (nesting?: boolean) => {
    if (nesting) {
      if (studentTarget.TYPE === 'screen') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'Do you want to cancel screen sharing?', BUTTON: 'Cancel Sharing', RESERVE: 'close' }));
      }
      if (studentTarget.TYPE === 'file') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'Do you want to cancel file transfer?', BUTTON: 'Cancel Transfer', RESERVE: 'close' }));
      }
      if (studentTarget.TYPE === 'link') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'Do you want to cancel link transfer?', BUTTON: 'Cancel Transfer', RESERVE: 'close' }));
      }
      if (popup.TYPE === 'blendedTarget') {
        setPopupNesting((prevData) => ({ ...prevData, TYPE: 'decision', CONTENT: 'Do you want to cancel the online class invitation?', BUTTON: 'Cancel Invitation', RESERVE: 'close' }));
      }
    } else {
      setIsVisible(false);
      setTimeout(() => {
        setPopup((prevData) => ({ ...prevData, TYPE: '' }));
      }, 100);
    }
    document.body.style.overflow = '';
  };

  // 대상 선택 완료
  const targetSelectComplete = async () => {

    const isStudentTarget = popup.TYPE.includes('studentTarget') && studentTarget.TARGET.length > 0;
    const isBlendedTarget = popup.TYPE.includes('blendedTarget') && targetMemberData.filter(item => usersInfoBox.find(u => u.id === item.id)?.isChecked).length > 0;

    if (isStudentTarget) {
      await handleStudentTarget();
    } else if (isBlendedTarget) {
      await handleBlendedTarget();
    }

  };

  const handleStudentTarget = async () => {
    const studentPubInfo = createStudentPubInfo();

    if (studentTarget.TYPE === 'screen') {
      await handleScreenTarget(studentPubInfo);
    } else if (studentTarget.TYPE === 'file' && typeof studentTarget.DATA !== 'string' && studentTarget.DATA) {
      await handleFileTarget();
    } else if (studentTarget.TYPE === 'link' && typeof studentTarget.DATA === 'string') {
      await handleLinkTarget();
    }

    popupClose(); // 팝업 끄기
  };

  const createStudentPubInfo = () => {
    return [{
      id: sessionBaseInfo?.baseInfo.userId!,
      division: 'student',
      name: sessionBaseInfo?.baseInfo.name!,
      number: Number(sessionBaseInfo?.schoolInfo?.number) || 0
    }];
  };

  const handleScreenTarget = async (studentPubInfo: any) => {
    if (classGroupMode.groupMode && classGroupMode.groupInfo && studentTarget.TARGET[0].division === 'onequick') {
      await handleGroupScreenShare(studentPubInfo);
    } else {
      await handleIndividualScreenShare(studentPubInfo);
    }
  };
  const handleGroupScreenShare = async (studentPubInfo: any) => {
    const subGroupId = findSubGroupId(studentTarget.TARGET[0].id);
    if (subGroupId) {
      const request: MirrorOpenRequestInterface = {
        permissionNeed: false,
        subGroupId: Number(subGroupId),
        groupMirror: true,
        pubs: studentPubInfo,
        subs: studentTarget.TARGET
      };
      const success = await mirrorOpenRequest(request);
      showToast(`Screen sharing to the small blackboard was ${success ? 'successful' : 'failed'}.`);
    }
  };

  const handleIndividualScreenShare = async (studentPubInfo: any) => {
    const request: MirrorOpenRequestInterface = {
      permissionNeed: true,
      groupMirror: false,
      pubs: studentPubInfo,
      subs: studentTarget.TARGET
    };
    const success = await mirrorOpenRequest(request);
    showToast(`Requested screen sharing with the teacher was ${success ? 'successful' : 'failed'}.`);
  };

  const handleFileTarget = async () => {
    if (typeof studentTarget.DATA !== 'string' && studentTarget.DATA) {
      const success = await sendPermissionRequestMessage({ type: 'file', targetInfo: studentTarget.TARGET, file: studentTarget.DATA });
      const isTeacher = studentTarget.TARGET[0].division === 'teacher';
      if (isTeacher) {
        setToast({ TYPE: 'alert', CONTENT: `File transfer was ${success ? 'successful' : 'failed'}.`, TIME: 3 });
      } else if (success) {
        setToast({ TYPE: 'alert', CONTENT: 'Requested file transfer to the teacher.', TIME: 3 });
      } else {
        setToast({ TYPE: 'alert', CONTENT: 'File transfer request failed.', TIME: 3 });
      }
    }
  };

  const handleLinkTarget = async () => {
    if (typeof studentTarget.DATA === 'string') {
      const success = await sendPermissionRequestMessage({ type: 'link', targetInfo: studentTarget.TARGET, link: studentTarget.DATA });
      const isTeacher = studentTarget.TARGET[0].division === 'teacher';
      if (isTeacher) {
        setToast({ TYPE: 'alert', CONTENT: `Link transfer was ${success ? 'successful' : 'failed'}.`, TIME: 3 });
      } else if (success) {
        setToast({ TYPE: 'alert', CONTENT: 'Requested link transfer to the teacher.', TIME: 3 });
      } else {
        setToast({ TYPE: 'alert', CONTENT: 'Link transfer request failed.', TIME: 3 });
      }
    }
  };

  const showToast = (message: string) => {
    setToast({ TYPE: 'alert', CONTENT: message, TIME: 3 });
  };

  const handleBlendedTarget = async () => {
    const selectedBlendedTarget = getSelectedBlendedTarget();
    if (requestJoinBlendedStreamLearning && blendedState.roomId) {
      requestJoinBlendedStreamLearning(selectedBlendedTarget, blendedState.roomId);
      setToast({ TYPE: 'alert', CONTENT: 'Online class invitation link has been sent.', TIME: 3 });
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'Please create the online class first.', TIME: 3 });
    }
    popupClose();
  };

  const getSelectedBlendedTarget = (): string[] => {
    return targetMemberData
      .filter(item => usersInfoBox.find(u => u.id === item.id)?.isChecked)
      .map(item => item.id); // Extracting only id to return as a string[] array
  };

  const findSubGroupId = (targetId: string): string => {
    if (!classGroupMode.groupInfo) {
      return '';
    }
    for (const group of classGroupMode.groupInfo.subGroupList) {
      for (const member of group.subGroupMember) {
        if (member.id === targetId) {
          return group.subGroupId;
        }
      }
    }
    return '';
  };














  const clickTarget = (targetData: UsersInfoInterface) => {
    const targetId = targetData.id;
    const updatedUsersInfoBox = usersInfoBox.map(item =>
      item.id === targetId ? { ...item, isChecked: !item.isChecked } : { ...item, isChecked: false }
    );
    setUsersInfoBox(updatedUsersInfoBox);
  };

  // 블렌디드 타겟 선택
  const clickBlendedTarget = (targetData: UsersInfoInterface) => {
    const targetId = targetData.id;
    const updatedUsersInfoBox = usersInfoBox.map(item =>
      item.id === targetId ? { ...item, isChecked: !item.isChecked } : item
    );
    setUsersInfoBox(updatedUsersInfoBox);
  };



  // 타겟 체크박스 초기화
  const resetTargetCheckbox = () => {
    const updatedUsersInfoBox = usersInfoBox.map(item => ({ ...item, isCheckAble: false, isChecked: false }));
    setUsersInfoBox(updatedUsersInfoBox);
  };


  // 링크 복사하기
  const copyLink = async (url: string) => {

    if (url) {
      try {
        // 클립보드 사용 가능 -> PC
        if (navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(url);
        }
        // 클립보드 사용 불가능 -> TABLET, MOBILE
        else {
          const input = document.createElement('textarea');
          input.value = url;
          document.body.appendChild(input);
          input.select();
          document.execCommand('copy');
          document.body.removeChild(input);
        }

        // 링크 복사 완료 토스트
        setCopyToast(true);
        setTimeout(() => {
          setCopyToast(false);
        }, 3000);

      } catch (err) {
        console.error('Failed to copy:', err);
      }
    }


  };


  // 해당 주소로 이동
  const moveLink = (url: string) => {
    if (url) {
      linkOpen('out', url);
    }
  };


  return { popup, isVisible, popupClose, targetMemberData, studentTarget, clickTarget, clickBlendedTarget, targetSelectComplete, copyToast, copyLink, moveLink };
};

import { useRecoilState } from 'recoil';

import { useEffect, useState } from 'react';
import { popupEnvironmentStore } from '@/recoil/store/popup';
import publicMethod from '@/utils/publicMethod';

/* 교실환경 안내 팝업 HOOK */
export default() => {


    // 팝업 시스템 state
    const [popupEnvironment, setPopupEnvironment] = useRecoilState(popupEnvironmentStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 교실환경 알림 안내
    const [alarmTime, setAlarmTime] = useState('');

    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupEnvironment.TYPE === 'environment'){
            setAlarmTime(publicMethod.convertDateMore(new Date().toISOString()));
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popupEnvironment.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopupEnvironment((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };



    return { popupEnvironment, isVisible, popupClose, alarmTime };
};

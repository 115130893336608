import { coreApi } from '@/services/network';
import { CoreMappingLogCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##################################
 * ############# 로그아웃 #############
 * ################################## */


/* function interface */
export interface FunctionReq {
  accessToken: string
}
export interface FunctionRes {
  message: string         // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
}


/* Api Interface */
interface ApiRes {
  status: number
  code: string
  message: string
}


export const logout = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/auth/logout`;
  const method = 'POST';

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint, {}, method, request.accessToken, null);

  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Logout failed.';

  if (res.status !== 500 && res.status !== 600) {
    return {
      message: 'SUCCESS',
      errorCode,
      errorMessage
    };
  } else {
    return {
      message: 'FAIL',
      errorCode,
      errorMessage
    };
  }

};

import { coreApi } from '@/services/network';
import { getProfile, FrontSessionBaseInfo } from '@/services/core/me/getProfile';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* #######################################
 * ############# 2차인증 로그인 #############
 * ####################################### */


/*
 * USER_FOUND : 사용자를 찾았습니다.
 * USER_NOT_FOUND : 입력하신 정보와 일치하는 회원이 없습니다.
 * ALREADY_LOGIN : 동일한 ID로 다른 기기가 로그인되어 있습니다. 현재 기기를 사용해 로그인하시겠습니까?
 * INVALID_CREDENTIAL : 일치하는 회원정보가 없습니다. 아이디 또는 비밀번호를 정확히 입력해 주세요.
 * TOO_MANY_LOGIN_ATTEMPT : 비밀번호가 5회 이상 잘못 입력되어 잠금처리 되었습니다. 비밀번호 찾기 페이지에서 비밀번호를 재설정해 주세요.
 * LOGIN_SUCCESS : 사용자 로그인 성공했습니다.
 * PRIVILEGES_PERMISSION : 접근이 제한되었습니다. 이 기능은 승인된 사용자만 이용할 수 있습니다.
 */

/* function interface */
export interface FunctionReq {
  callbackId: string
}

export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)
  errorCode: string;
  errorMessage: string;
  accessToken?: string,
  data?: FrontSessionBaseInfo | null
}



/* Api Interface */
interface ApiReq {
  callbackId: string
}

interface ApiRes {
  status: number,
  data?: {
    accessToken?: string
    needVerification?: string
  }
  code: string
  message: string
}




export const verifyAuthToken = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/auth/verify-token`;
  const method = 'POST';

  const requestBody: ApiReq = {
    callbackId: request.callbackId
  };

  const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, requestBody, method, null, null);

  let message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  let errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  let errorMessage = 'Validation failed.';

  let data: FrontSessionBaseInfo | null | undefined;

  if ((res.code === CoreResponseCode.LOGIN_SUCCESS || res.code === CoreResponseCode.USER_FOUND || res.code === CoreResponseCode.ALREADY_LOGIN) && res.data) {
    if (res.data.needVerification) {
      message = 'VERIFY';
    } else if (res.data.accessToken) {

      /* 회원 role 가져오기 */
      const funcRes = await getProfile({ accessToken: res.data.accessToken });

      if (funcRes.code === CoreResponseCode.PASSWORD_CHANGE_REQUIRE) {
        message = 'PASSWORD';
        data = funcRes.data;
      }
      else if (funcRes.message === 'SUCCESS') {
        data = funcRes.data;
      }
      else if (funcRes.message === 'TIMEOUT') {
        message = 'TIMEOUT';
      }
      else {
        errorCode = funcRes.errorCode;
        errorMessage = funcRes.errorMessage;
        message = 'FAIL';
      }

    }
  }
  return {
    message: message,
    errorCode,
    errorMessage,
    accessToken: res.data?.accessToken,
    data: data
  };

};

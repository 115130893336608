import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode, CoreMappingLogCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ########################################
 * ############# 로고파일 다운로드 #############
 * ######################################## */


/* function interface */
export interface FunctionReq {
    token: string
    schoolId: string
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data?: {
        id: string
        schoolId: string
        logoType: 'wlogo' | 'hlogo'
        originalName: string
        createdAt: string
        base64Image: string
    }[]
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
    data?: {
        id: string
        schoolId: string
        logoType: 'wlogo' | 'hlogo'
        originalName: string
        createdAt: string
        base64Image: string
    }[]
}


export const downloadLogoFile = async(request: FunctionReq): Promise<FunctionRes> => {
    let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/core/logo/${request.schoolId}`;
    const method = 'GET';

    const res = await contentApi<{}, ApiRes>(endPoint, {}, method, request.token);

    let code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

    if (res.data) {
        return {
            code,
            status: 200,
            message: code,
            data: res.data
        };
    } else {
        return {
            code,
            status: 200,
            message: code
        };
    }
};

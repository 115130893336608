import { useEffect, useState } from 'react';
import '@/components/container/service/etc/GroupCustomBar.css';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { classGroupCustomStore, GroupCustomModeStore, registeredMemberStore } from '@/recoil/store/class';
import ScrollBar from '@/components/common/scroll/ScrollBar';
import CloseButton from '@/assets/icon/popup/close_button.svg';             // Dompurify 적용완료
import MemberDeleteButton from '@/assets/icon/popup/member-delete.svg';     // Dompurify 적용완료
import RadioCheckedIcon from '@/assets/icon/popup/popup-checked.svg';       // Dompurify 적용완료
import RadioUncheckedIcon from '@/assets/icon/popup/popup-unchecked.svg';   // Dompurify 적용완료
import CheckedIcon from '@/assets/icon/common/checked-box.svg';             // Dompurify 적용완료
import UncheckedIcon from '@/assets/icon/common/unchecked-box.svg';         // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



/* 그룹 생성 설정바 */
const GroupCustomBar = () => {


  // 모둠 커스텀
  const [groupCustomMode, setGroupCustomMode] = useRecoilState(GroupCustomModeStore);
  const resetGroupCustomMode = useResetRecoilState(GroupCustomModeStore);
  // 모둠장 선택 여부
  const [noneLeader, setNoneLeader] = useState<boolean>(false);
  // 칠판 선택 여부
  const [noneOnequick, setNoneOnequick] = useState<boolean>(false);
  // 모둠 정보 (클라이언트)
  const [classGroupCustom, setClassGroupCustom] = useRecoilState(classGroupCustomStore);
  // 모둠 정보 - 기등록된 자 리스트 (클라이언트)
  const [registeredMember, setRegisteredMember] = useRecoilState(registeredMemberStore);





  useEffect(() => {

    // 모둠 커스텀 모드 초기화
    if (groupCustomMode.OPEN) {
      const getGroupMember = classGroupCustom.subGroupList.find(group => String(group.id) === groupCustomMode.subGroupId);
      if (getGroupMember) {
        const getSelectedMember = getGroupMember.subGroupMember.map(subGroup => {
          return subGroup.memberId;
        }).flat();
        setRegisteredMember({ ...registeredMember, selectedGroupMember: getSelectedMember });
      }
    } else {
      setRegisteredMember({ ...registeredMember, selectedGroupMember: [] });
    }

  }, [groupCustomMode]);





  // 모둠 커스텀 모드 닫기
  const navClose = () => {
    resetGroupCustomMode();
    setNoneLeader(false);
    setNoneOnequick(false);
  };

  // 모둠 커스텀 모드 다음 단계
  const moveNextCustomStep = () => {
    setGroupCustomMode({
      ...groupCustomMode,
      STEP: groupCustomMode.STEP + 1
    });
  };

  // 모둠 커스텀 모드 이전 단계
  const moveBeforeCustomStep = () => {
    if (groupCustomMode.STEP !== 1) {
      setGroupCustomMode({
        ...groupCustomMode,
        STEP: groupCustomMode.STEP - 1
      });
    }
  };



  // 모둠원 선택
  const clickGroupMember = (id: string) => {
    // 체크된 상태인지 확인
    const isChecked = groupCustomMode.subGroupMember.some(u => u.memberId === id);
    //  체크된 상태라면 제거
    if (isChecked) {
      setGroupCustomMode({
        ...groupCustomMode,
        subGroupMember: groupCustomMode.subGroupMember.filter(u => u.memberId !== id)
      });
    }
    else { // 체크되지 않은 상태라면 추가
      const member = groupCustomMode.classRealMember.find(u => u.id === id);
      if (member) {
        setGroupCustomMode({
          ...groupCustomMode,
          subGroupMember: [...groupCustomMode.subGroupMember, { division: member.division, memberId: member.id, number: member.number, name: member.name, position: 'member', deviceActive: member.status === 'normal' }]
        });
      }
    }
  };



  // 모둠장 선택
  const selectGroupLeader = (id: string) => {

    if (id === 'none') {
      setNoneLeader(true);
      setGroupCustomMode({
        ...groupCustomMode,
        subGroupMember: groupCustomMode.subGroupMember.map(u => ({ ...u, position: 'member' }))
      });
    } else {
      const getLeaderId = groupCustomMode.subGroupMember.find(u => u.memberId === id);
      if (getLeaderId) {
        setGroupCustomMode({
          ...groupCustomMode,
          subGroupMember: groupCustomMode.subGroupMember.map(u =>
            u.memberId === id
              ? { ...u, position: 'leader' }
              : { ...u, position: 'member' }
          )
        });
      }
      setNoneLeader(false);
    }



  };

  // 모둠 칠판 선택
  const selectGroupOnequick = (id: string) => {
    if (id === 'none') {
      setNoneOnequick(true);
      setGroupCustomMode({
        ...groupCustomMode,
        subGroupMember: groupCustomMode.subGroupMember.filter(member => member.division !== 'onequick')
      });
    } else {
      // 모든 칠판 데이터 제거
      const updatedSubGroupMembers = groupCustomMode.subGroupMember.filter(member => member.division !== 'onequick');

      // 선택한 칠판 데이터 추가
      const onequick = groupCustomMode.classRealMember.find(u => u.id === id);

      if (onequick) {
        updatedSubGroupMembers.push({ division: onequick.division, memberId: onequick.id, number: onequick.number, name: onequick.name, position: 'member', deviceActive: onequick.status === 'normal' });
        setGroupCustomMode({ ...groupCustomMode, subGroupMember: updatedSubGroupMembers });
      }
      setNoneOnequick(false);
    }
  };






  // 모둠 커스텀 저장
  const saveGroupCustom = () => {

    // 모둠 커스텀 데이터 업데이트
    let targetSubGroupIndex = classGroupCustom.subGroupList.findIndex(group => String(group.id) === groupCustomMode.subGroupId);
    setClassGroupCustom({
      ...classGroupCustom, subGroupList: classGroupCustom.subGroupList.map((group, index) => {
        if (index === targetSubGroupIndex) {
          return { ...group, subGroupMember: groupCustomMode.subGroupMember };
        }
        return group;
      })
    });

    // 모둠 커스텀 모드 닫기
    navClose();
  };



  return (
    <div className={`layout-group-custom ${groupCustomMode.OPEN && 'visible'}`}>

      <section className={`group-custom-section ${groupCustomMode.OPEN && 'open'}`}>
        {groupCustomMode.OPEN &&
          <>

            {/* Group Custom Top Display */}
            <div className='group-custom-top'>
              {/* Group Name Display */}
              <div className='group-custom-title'>
                <div className='group-custom-img'></div>
                <span>{groupCustomMode.subGroupName} Group</span>
                <figure className='group-custom-img' style={{ cursor: 'pointer' }} onClick={() => navClose()}>
                  <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} />
                </figure>
              </div>

              {/* Group Custom Progress Steps Display */}
              <div className='group-custom-arrow'>
                <>
                  {groupCustomMode.STEP === 1 &&
                    <>
                      <div className='group-custom-arrow-item main'>
                        <span>Select Group Members</span>
                      </div>
                      <div className='group-custom-arrow-item sub'>
                        <div className='group-cai-triangle main'></div>
                        <span>Select Group Leader</span>
                      </div>
                      <div className='group-custom-arrow-item gray'>
                        <div className='group-cai-triangle sub'></div>
                        <span>Select Board</span>
                      </div>
                    </>
                  }

                  {groupCustomMode.STEP === 2 &&
                    <>
                      <div className='group-custom-arrow-item gray'>
                        <span style={{ color: '#3E0BB7' }}>Select Group Members</span>
                      </div>
                      <div className='group-custom-arrow-item main'>
                        <div className='group-cai-triangle gray'></div>
                        <span>Select Group Leader</span>
                      </div>
                      <div className='group-custom-arrow-item sub'>
                        <div className='group-cai-triangle main'></div>
                        <span>Select Board</span>
                      </div>
                    </>
                  }

                  {groupCustomMode.STEP === 3 &&
                    <>
                      <div className='group-custom-arrow-item gray'>
                        <span style={{ color: '#3E0BB7' }}>Select Group Members</span>
                      </div>
                      <div className='group-custom-arrow-item sub'>
                        <div className='group-cai-triangle gray'></div>
                        <span style={{ color: '#3E0BB7' }}>Select Group Leader</span>
                      </div>
                      <div className='group-custom-arrow-item main'>
                        <div className='group-cai-triangle sub'></div>
                        <span>Select Board</span>
                      </div>
                    </>
                  }
                </>
              </div>

            </div>

            {/* Group Custom Members Display */}
            <div className='group-custom-member'>
              <ScrollBar scrollbarHeight="100%" scrollBarOn={true}>
                {groupCustomMode.STEP === 1 &&
                  <>
                    {
                      groupCustomMode.subGroupMember.length > 0 &&
                      <div className='group-selected-member'>
                        {
                          [...groupCustomMode.subGroupMember].filter(item => item.division === 'student').sort((a, b) => parseInt(a.number!) - parseInt(b.number!)).map((item, index) => (
                            <div key={index} className='gsm-item'>
                              <span>{item.number}</span>
                              <span>{item.name}</span>
                              <img src={publicMethod.svgClear(MemberDeleteButton)} alt={'MemberDeleteButton'} draggable={false} onClick={() => clickGroupMember(item.memberId)} />
                            </div>
                          ))
                        }
                      </div>
                    }
                    <div className='group-member-list'>
                      <div className='gml-item-box'>
                        {
                          [...groupCustomMode.classRealMember]
                            .filter(item => item.division === 'student' && (!registeredMember.totalMember.includes(item.id) || registeredMember.selectedGroupMember.includes(item.id)))
                            .sort((a, b) => parseInt(a.number!) - parseInt(b.number!))
                            .map((item, index) => (
                              <div key={index} className='gml-item' onClick={() => clickGroupMember(item.id)}>
                                <img src={groupCustomMode.subGroupMember.some(u => u.memberId === item.id) ? publicMethod.svgClear(CheckedIcon) : publicMethod.svgClear(UncheckedIcon)} alt={'checkedIcon'} draggable={false} />
                                <span>{item.number} {item.name}</span>
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  </>
                }

                {groupCustomMode.STEP === 2 &&
                  <div className='group-member-list'>
                    <div className='gml-item-box'>
                      {
                        [...groupCustomMode.subGroupMember].filter(item => item.division === 'student').sort((a, b) => parseInt(a.number!) - parseInt(b.number!)).map((item, index) => (
                          <div key={index} className='gml-item' onClick={() => selectGroupLeader(item.memberId)}>
                            <img src={item.position === 'leader' ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={'RadioUncheckedIcon'} draggable={false} />
                            <span>{item.number} {item.name}</span>
                          </div>
                        ))
                      }
                      <div className='gml-item' onClick={() => selectGroupLeader('none')}>
                        <img src={noneLeader ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={'RadioUncheckedIcon'} draggable={false} />
                        <span>None</span>
                      </div>
                    </div>
                  </div>
                }

                {groupCustomMode.STEP === 3 &&
                  <div className='group-member-list'>
                    <div className='gml-item-box'>
                      {
                        [...groupCustomMode.classRealMember]
                          .filter(item => item.division === 'onequick' && (!registeredMember.totalMember.includes(item.id) || registeredMember.selectedGroupMember.includes(item.id)))
                          .map((item, index) => (
                            <div key={index} className='gml-item' onClick={() => selectGroupOnequick(item.id)}>
                              <img src={groupCustomMode.subGroupMember.some(u => u.memberId === item.id) ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={'RadioUncheckedIcon'} draggable={false} />
                              <span>{item.name}</span>
                            </div>
                          ))
                      }
                      <div className='gml-item' onClick={() => selectGroupOnequick('none')}>
                        <img src={noneOnequick ? publicMethod.svgClear(RadioCheckedIcon) : publicMethod.svgClear(RadioUncheckedIcon)} alt={'RadioUncheckedIcon'} draggable={false} />
                        <span>None</span>
                      </div>
                    </div>
                  </div>
                }
              </ScrollBar>

            </div>

            {/* Group Custom Buttons Display */}
            <div className='group-custom-button-box'>
              {groupCustomMode.STEP === 1 &&
                <>
                  <button className='group-custom-button disable' onClick={moveBeforeCustomStep}>
                    Previous
                  </button>
                  <button className={`group-custom-button ${groupCustomMode.subGroupMember.length > 0 ? 'main' : 'disable'}`} onClick={groupCustomMode.subGroupMember.length > 0 ? moveNextCustomStep : undefined}>
                    Next
                  </button>
                </>
              }

              {groupCustomMode.STEP === 2 &&
                <>
                  <button className='group-custom-button white' onClick={moveBeforeCustomStep}>
                    Previous
                  </button>
                  <button className={`group-custom-button ${(groupCustomMode.subGroupMember.find(u => u.position === 'leader') || noneLeader) ? 'main' : 'disable'}`} onClick={(groupCustomMode.subGroupMember.find(u => u.position === 'leader') || noneLeader) ? moveNextCustomStep : undefined}>
                    Next
                  </button>
                </>
              }

              {groupCustomMode.STEP === 3 &&
                <>
                  <button className='group-custom-button white' onClick={moveBeforeCustomStep}>
                    Previous
                  </button>
                  <button className={`group-custom-button ${(groupCustomMode.subGroupMember.find(u => u.division === 'onequick') || noneOnequick) ? 'main' : 'disable'}`} onClick={(groupCustomMode.subGroupMember.find(u => u.division === 'onequick') || noneOnequick) ? saveGroupCustom : undefined}>
                    Save
                  </button>
                </>
              }

            </div>

          </>
        }
      </section>

    </div>
  );
};

export default GroupCustomBar;

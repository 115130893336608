import '@/components/popup/popup.css';
import popupSchoolSearch from '@/hooks/components/popup/auth/popupSchoolSearch';
import React from 'react';
import Scrollbar from '@/components/common/scroll/ScrollBar';
import { SchoolListInterface } from '@/services/core/schools/internalsSchool';
import CloseButton from '@/assets/icon/popup/close_button.svg';             // Dompurify 적용완료
import SchoolSearchIcon from '@/assets/icon/popup/schoolSearchIcon.svg';    // Dompurify 적용완료
import DataEmptyIcon from '@/assets/icon/popup/dataEmptyIcon.svg';          // Dompurify 적용완료
import SearchIcon from '@/assets/icon/popup/searchIcon.svg';                // Dompurify 적용완료
import InputError from '@/assets/icon/auth/input-error.svg';                // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';


/* 학교검색 팝업 */
const PopupSchoolSearch = () => {

  const { popup, popupClose, inputs, schoolInfo, schoolList, searchTextError, searchHistory, runSearch, handleInputs, handleKeyPress, clickSchoolItem, selectSchoolInfo, isVisible } = popupSchoolSearch();

  return (
    <>
      {popup.TYPE === 'schoolSearch' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box school'}>
            <article className={'layout-popup-content'}>
              {/* Close button */}
              <figure className='layout-popup-close-button-box'>
                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} onClick={() => popupClose()} />
              </figure>

              {/* Popup icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(SchoolSearchIcon)} alt={'SchoolSearchIcon'} draggable={false} />
              </figure>

              {/* Popup title */}
              <div className='layout-popup-title' style={{ marginBottom: '20px' }}>{popup.TITLE}</div>

              {/* Popup search input */}
              <div className={`school-search-input-box ${searchTextError && 'error'}`}>
                <input name='searchName' type="text" placeholder='Search for a school name.' value={inputs.searchName} autoComplete={'off'} onChange={handleInputs} onKeyDown={handleKeyPress} />
                <img src={publicMethod.svgClear(SearchIcon)} alt={'SearchIcon'} draggable={false} onClick={() => runSearch()} />
              </div>

              {searchTextError &&
                <div className='layout-popup-input-message-box'>
                  <img src={publicMethod.svgClear(InputError)} alt={'InputError'} draggable={false} />
                  <span>Please enter a search term.</span>
                </div>
              }

              {/* School list */}
              <ul className='school-search-data-box'>
                {schoolList &&
                  <>
                    {
                      schoolList.length === 0
                        ?
                        <>
                          {
                            searchHistory
                              ?
                              <article className='school-search-empty-box'>
                                <img src={publicMethod.svgClear(DataEmptyIcon)} alt={'DataEmptyIcon'} draggable={false} />
                                <span>No search results found</span>
                                Please enter the school name accurately.
                              </article>
                              :
                              <> </>
                          }
                        </>
                        :
                        <Scrollbar scrollbarHeight='248px'>
                          {schoolList.map((item: SchoolListInterface, index: number) => (
                            <li className={`school-search-data-item ${schoolInfo.school_id === item.id && 'selected'}`} key={index} onClick={() => clickSchoolItem(item)}>
                              <div className={`school-search-data-info ${schoolInfo.school_id === item.id && 'selected'}`}>
                                <span>
                                  {item.name}
                                </span>
                              </div>
                              <span className='school-search-data-item-address'>
                                {item.streetAddress}
                                <span>{item.provinceName}</span>
                              </span>
                            </li>
                          ))}
                        </Scrollbar>
                    }
                  </>
                }
              </ul>

              <button className={`layout-popup-button ${schoolInfo.school_id === '' && 'disable'}`} onClick={() => schoolInfo.school_id !== '' ? selectSchoolInfo() : undefined}>
                {popup.BUTTON}
              </button>
            </article>

            <div className='layout-popup-button-box'>
              {/* Additional buttons can be added here if needed */}
            </div>
          </div>
        </div>
      }
    </>
  );

};

export default PopupSchoolSearch;

import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';

/* ##########################################
 * ############# 내문서함 영상 수정 #############
 * ########################################## */


/* function interface */
export interface FunctionReq {
  token: string;
  id: number;
  title?: string;              // 수정할 영상 제목
  disclosureStatus?: 'ALL' | 'NON' | 'USER' | 'CLASS';   // 공개 상태 enum(ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실))
  deleteTargetIds?: number[];  // 삭제할 공개 교실 PK 목록
  targetDtoList?: {            // 공개할 교실 아이디 Object 목록
    id?: number;             // 교실명 변경시 사용
    classroomId: string;     // 교실 아이디
    name: string;            // 교실 이름
  }[]
}

export interface FunctionRes {
  code: string
  status: number
  message: string
}




/* Api Interface */
interface ApiReq {
  title?: string;              // 수정할 영상 제목
  disclosureStatus?: 'ALL' | 'NON' | 'USER' | 'CLASS';   // 공개 상태 enum(ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실))
  deleteTargetIds: number[];  // 삭제할 공개 교실 PK 목록
  targetDtoList?: {            // 공개할 교실 아이디 Object 목록
    id?: number;             // 교실명 변경시 사용
    classroomId: string;     // 교실 아이디
    name: string;            // 교실 이름
  }[]
}

export interface ApiRes {
  code: string;
  status: number;
  message: string;
}



export const modifyRecord = async (request: FunctionReq): Promise<FunctionRes> => {

  const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/document/video/target/${request.id}`;
  const method = 'PUT';

  const req: ApiReq = {
    deleteTargetIds: []
  };
  if (request.deleteTargetIds) req.deleteTargetIds = request.deleteTargetIds;
  if (request.targetDtoList) req.targetDtoList = request.targetDtoList;
  if (request.title) req.title = request.title;
  if (request.disclosureStatus) req.disclosureStatus = request.disclosureStatus;


  const res = await contentApi<ApiReq, ApiRes>(endPoint, req, method, request.token, null);

  const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

  return {
    code,
    status: res.status,
    message: res.message
  };

};

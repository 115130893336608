import axios, { AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';

/* make network(axios) instance */
const netInstance: AxiosInstance = axios.create({
  baseURL: '/',
  timeout: 120000
});

/* Api Server Module, Chatting Server Module */
const requestOption = (method: string, url: string, headerConfig: any, body: any): AxiosRequestConfig => {
  return {
    method: method.toUpperCase(),
    url,
    headers: {
      'Content-Type': 'application/json',
      ...headerConfig
    },
    data: body
  };
};

/* Gooroomee Api Server Module */
export const grmApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string): Promise<any> => {

  let headerConfig: any = {
    'Content-Type': 'application/json',
    'GRM-OPEN-API-KEY': `${process.env.REACT_APP_GOOROOMEE_KEY}`
  };

  let requestOptions = requestOption(method, url, headerConfig, body);
  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('-> GOOROOMEE SUCCESS: ', (response.data.code), (response.data.result));
    }

    response.data.status = response.status;
    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('-> GOOROOMEE ERROR: ', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


export const streamApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string): Promise<any> => {

  let headerConfig: any = {
    'Content-Type': 'application/json'
  };

  let requestOptions = requestOption(method, url, headerConfig, body);
  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool STREAM SUCCESS: ', (response.data.code), (response.data.result));
    }

    response.data.status = response.status;
    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool STREAM ERROR', JSON.stringify(e));
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


/* Core Api Server Module */
export const coreApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token?: any, header?: any): Promise<any> => {

  let headerConfig: any = {
    'Content-Type': 'application/json'
  };

  if (header) {
    headerConfig = header;
  }

  if (token) {
    headerConfig.Authorization = `Bearer ${token}`;
  }

  let requestOptions = requestOption(method, url, headerConfig, body);
  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool CORE SUCCESS: ', (response.data.code), (response.data.result));
    }

    if (response.data && response.data !== '') {
      response.data.status = response.status;
    }
    return response.data;
  } catch (e: any) {

    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool CORE ERROR', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


/* Content Api Server Module */
export const contentApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token?: any, header?: any): Promise<any> => {

  let headerConfig: any = {
    'Content-Type': 'application/json'
  };

  if (header) {
    headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    };
  }

  if (token) {
    headerConfig.Authorization = `Bearer ${token}`;
  }

  let requestOptions = requestOption(method, url, headerConfig, body);


  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool CONTENT SUCCESS: ', (response.data.code), (response.data.result));
    }

    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool CONTENT ERROR: ', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};

/* Status Api Server Module */
export const statusApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token?: any, header?: any): Promise<any> => {

  let headerConfig: any = {
    'Accept': '*/*',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache'
  };

  if (header) {
    headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json',
      'Cache-Control': 'no-cache',
      ...header
    };
  }

  if (token) {
    headerConfig.Authorization = `Bearer ${token}`;
  }

  let requestOptions = requestOption(method, url, headerConfig, body);


  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool STATUS SUCCESS: ', (response.data.code), (response.data.result));
    }

    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool STATUS ERROR', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'FAIL', label: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


/* NCP */
export const ncpApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string): Promise<void> => {

  let headerConfig: any = {
    'Content-Type': 'application/json'
  };

  let requestOptions = requestOption(method, url, headerConfig, body);


  let response: any;

  try {
    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('-> NCP SUCCESS\r', response);
    }
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('-> NCP ERROR\r', e);
    }
  }
};

/* IoT Api Server Module */
export const iotApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token: string): Promise<any> => {

  let headerConfig: any = {
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${token}`
  };

  let requestOptions = requestOption(method, url, headerConfig, body);
  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool IOT SUCCESS: ', (response.data.code), (response.data.result));
    }
    response.data.status = response.status;
    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool IOT ERROR', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


/* Chatting Server Module */
export const chatApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token?: string, fileUpload?: any): Promise<any> => {

  let headerConfig: any = {};

  if (fileUpload) {
    headerConfig = {
      'Content-Type': 'multipart/form-data',
      'Accept': 'application/json'
    };
  }
  if (token) {
    headerConfig.Authorization = `Bearer ${token}`;
  }

  let requestOptions = requestOption(method, url, headerConfig, body);
  let response: any;

  try {

    response = await netInstance.request<ResInterface>(requestOptions);

    if (response.status >= 200 && response.status <= 204) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('->aschool CHAT SUCCESS: ', (response.data.code), (response.data.result));
      }
      response.data.status = response.status ? response.status : 200;
      return response.data;
    }
    else if (response.status === 401) {
      return { status: 401, moduleMessage: 'REISSUE' };
    }
    else {
      return { status: 500, moduleMessage: 'NONE STATUS' };
    }
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool CHAT ERROR', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    }
    if (e.response && e.response.data) {
      return e.response.data;
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }
};


/* Chatting Server Module */
export const downloadApi = async <ReqInterface, ResInterface>(url: string, body: ReqInterface, method: string, token: string): Promise<Blob | any> => {

  let headerConfig: any = {
    Authorization: `Bearer ${token}`
  };

  let requestOptions = requestOption(method, url, headerConfig, body);

  requestOptions.responseType = 'blob';

  try {
    const response = await netInstance.request<Blob>(requestOptions);
    // if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
    //     console.log('-> DOWNLOAD SUCCESS\r', response);
    // }
    return response.data;
  } catch (e: any) {
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('->aschool DOWNLOAD ERROR', e.message);
    }
    if (e.code === 'ECONNABORTED' && e.message.includes('timeout')) {
      return { code: 'TIMEOUT', status: 600, message: 'TIMEOUT' };
    } else {
      return { code: 'SERVER_ERROR', status: 600, message: 'SERVER_ERROR' };
    }
  }

};




/* response interceptor config */
const netResponse = (res: AxiosResponse): AxiosResponse => {

  switch (res.status) {
    case 200:
      // console.log('200 success');
      break;
    default:
      // console.log('DEFAULT');
      break;
  }
  return res;
};

/* interceptor & error catch setting */
// netInstance.interceptors.response.use(netResponse);

import { Outlet } from 'react-router-dom';
import checkIn from '@/hooks/services/checkIn';
import ServiceContainer from '@/components/container/service/full/ServiceContainer';
import nativeCallback from '@/hooks/services/nativeCallback';
import scheduler from '@/hooks/services/scheduler';
import groupManagement from '@/hooks/services/groupManagement';
import deviceManagement from '@/hooks/services/deviceManagement';
import commonLayoutHook from '@/hooks/layout/commonLayout';
import globalAlim from '@/hooks/services/globalAlim';
import firstAccess from '@/hooks/services/firstAccess';

/**  교사용 레이아웃  **/
const TeacherLayout = () => {

  // 체크인 HOOK
  const { usersInfo,
    classInfo,
    detailMonitorImage,
    setUsersInfo,
    classCheckIn,
    classCheckOut,
    mirrorStartCommand,
    mirrorStopCommand,
    sendPermissionRequestMessage,
    mirrorOpenRequest,
    returnPermissionMessage,
    returnMirrorPermissionMessage,
    getDetailMonitorRequest,
    checkInExtendRequest,
    stopAllMirrorRoomCommand,
    sendScreenLock,
    startBlendedRecord,
    endBlendedRecord,
    changeBlendedRecord,
    startBlendedStreamLearning,
    joinBlendedStreamLearning,
    requestJoinBlendedStreamLearning,
    destroyBlendedStreamLearning,
    usersInfoBox,
    setUsersInfoBox,
    deleteClassTalk,
    classModeChange
  } = checkIn();

  // 레이아웃 훅
  const { logout } = commonLayoutHook(classCheckOut);
  // 콜백 함수
  nativeCallback();
  // 시간표 스케줄러
  scheduler();

  // 모둠관리 HOOK
  const { getClassRealMember, getGroupInfo, modifyGroup, deleteGroup, makeNewGroup } = groupManagement();

  deviceManagement();         // 교실환경 모드설정 관리

  /* 전역알림 */
  globalAlim();
  /* 첫 접근 */
  firstAccess();

  return (
    <div className='AppRoot'>
      <ServiceContainer logout={logout} classInfo={classInfo} usersInfo={usersInfo} usersInfoBox={usersInfoBox} setUsersInfoBox={setUsersInfoBox} sendPermissionRequestMessage={sendPermissionRequestMessage} mirrorOpenRequest={mirrorOpenRequest} mirrorStartCommand={mirrorStartCommand} mirrorStopCommand={mirrorStopCommand} sendScreenLock={sendScreenLock}
        startBlendedRecord={startBlendedRecord} endBlendedRecord={endBlendedRecord} startBlendedStreamLearning={startBlendedStreamLearning} joinBlendedStreamLearning={joinBlendedStreamLearning} requestJoinBlendedStreamLearning={requestJoinBlendedStreamLearning}
        returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage} classModeChange={classModeChange}
        getDetailMonitorRequest={getDetailMonitorRequest} detailMonitorImage={detailMonitorImage} classCheckIn={classCheckIn} checkInExtendRequest={checkInExtendRequest} deleteClassTalk={deleteClassTalk}
      >
        <Outlet context={{
          classCheckIn, classCheckOut, usersInfo, classInfo, setUsersInfo, detailMonitorImage, mirrorStartCommand, mirrorStopCommand, sendPermissionRequestMessage, mirrorOpenRequest,
          getDetailMonitorRequest, checkInExtendRequest, stopAllMirrorRoomCommand, sendScreenLock, logout,
          startBlendedRecord, endBlendedRecord, changeBlendedRecord, startBlendedStreamLearning, joinBlendedStreamLearning,
          requestJoinBlendedStreamLearning, destroyBlendedStreamLearning, usersInfoBox, setUsersInfoBox, getClassRealMember, getGroupInfo, modifyGroup, deleteGroup, makeNewGroup, deleteClassTalk
        }} />
      </ServiceContainer>
    </div>
  );




};

export default TeacherLayout;

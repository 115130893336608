import { contentApi } from '@/services/network';
import { ContentMappingCode, ContentResponseCode } from '@/utils/code-enums';
import CryptoJS from 'crypto-js';

/* ##########################################
 * ############# File Safer 체크 #############
 * ########################################## */


/* function interface */
export interface FunctionReq {
  files: File[]
}

export interface FunctionRes {
  code: string
  message: string
}

/* Api Interface */
interface ApiRes {
  status: number
  code: string
  message: string
  data: {
    returnCode: string
    returnMessage: string
    totalRows: number
  }
}

interface HashResult {
  fileName: string
  algorithm: string
  hash: string
}

export const hashFilter = async (request: FunctionReq): Promise<FunctionRes> => {
  return {
    code: 'OK',
    message: '파일체크 성공'
  };
  // let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/external/file-safer/hash-filter`;
  // const method = 'GET';

  // const hashResults: HashResult[] = [];
  // try {
  //     const sha1Hash = await getHash(request.files[0], 'SHA-1');
  //     console.log('sha1Hash', sha1Hash);
  //     hashResults.push(sha1Hash);
  // } catch (error) {
  //     console.error('Error calculating hash:', error);
  //     return {
  //         code: 'FAIL',
  //         message: '해시생성 실패'
  //     };
  // }

  // if (hashResults.length > 0) {

  //     endPoint += `?hashCode=${hashResults[0].hash}&hashType=sha1`;

  //     const res:ApiRes = await contentApi<{}, ApiRes>(endPoint, {}, method, null);

  //     const code = ContentMappingCode[res.code as ContentResponseCode] || 'FAIL';

  //     if (res.code === ContentResponseCode.OK && res.data.returnMessage === 'success') {
  //         return {
  //             code,
  //             message: '파일체크 성공'
  //         };
  //     }
  //     return {
  //         code,
  //         message: '파일체크 실패 또는 비인가 파일 포함'
  //     };
  // } else {
  //     return {
  //         code: 'FAIL',
  //         message: '해시확인 실패'
  //     };
  // }
};

const makeSignature = (method: string, url: string, timestamp: number, secretKey: string): string => {
  const space = ' ';
  const newLine = '\n';
  const message = [method, space, url, newLine, timestamp.toString(), newLine, process.env.REACT_APP_ACCESS_KEY].join('');
  return CryptoJS.HmacSHA256(message, secretKey).toString(CryptoJS.enc.Base64);
};

const getHash = (file: File, algorithm: 'SHA-1' | 'MD5'): Promise<HashResult> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const arrayBuffer = e.target?.result;
      if (arrayBuffer) {
        const wordArray = CryptoJS.lib.WordArray.create(arrayBuffer as any);
        let hash;

        if (algorithm === 'SHA-1') {
          hash = CryptoJS.SHA1(wordArray);
        } else if (algorithm === 'MD5') {
          hash = CryptoJS.MD5(wordArray);
        } else {
          return reject(new Error('Unsupported algorithm'));
        }

        resolve({ fileName: file.name, algorithm, hash: hash.toString(CryptoJS.enc.Hex) });
      } else {
        reject(new Error('Error reading file'));
      }
    };
    reader.onerror = (e) => {
      reject(e);
    };
    reader.readAsArrayBuffer(file);
  });
};

// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import Loadable from '@/components/Loadable';
import TeacherLayout from '@/layout/service/teacherLayout';

/* 교사 버전 */

const TeacherFloating = Loadable(lazy(() => import('@/pages/service/common/floating/teacherMenu')));

const TeacherHome = Loadable(lazy(() => import('@/pages/service/teacher/class/home')));
const TeacherEnvironment = Loadable(lazy(() => import('@/pages/service/teacher/class/environment')));
const TeacherLearning = Loadable(lazy(() => import('@/pages/service/teacher/class/learning')));
const TeacherBlended = Loadable(lazy(() => import('@/pages/service/teacher/class/blended')));
const TeacherMyBox = Loadable(lazy(() => import('@/pages/service/teacher/class/myBox')));


const StudentManage = Loadable(lazy(() => import('@/pages/service/teacher/manage/student')));
const GroupManage = Loadable(lazy(() => import('@/pages/service/teacher/manage/group')));
const GroupDetail = Loadable(lazy(() => import('@/pages/service/teacher/manage/groupDetail')));
const GroupCreateManage = Loadable(lazy(() => import('@/pages/service/teacher/manage/groupCreate')));
const DeviceManage = Loadable(lazy(() => import('@/pages/service/teacher/manage/device')));
const DeviceDetail = Loadable(lazy(() => import('@/pages/service/teacher/manage/deviceDetail')));


const NoticeManage = Loadable(lazy(() => import('@/pages/service/common/notice/noticeList')));
const NoticeDetail = Loadable(lazy(() => import('@/pages/service/common/notice/noticeDetail')));
const NoticePost = Loadable(lazy(() => import('@/pages/service/common/notice/noticePost')));


const TeacherMyPage = Loadable(lazy(() => import('@/pages/service/common/mypage/mypageMain')));
const TeacherPasswordCheck = Loadable(lazy(() => import('@/pages/service/common/mypage/passwordCheck')));
const TeacherEmailCert = Loadable(lazy(() => import('@/pages/service/common/mypage/emailCert')));
const TeacherBasicInfo = Loadable(lazy(() => import('@/pages/service/common/mypage/basicInfoChange')));

const TeacherEmployment = Loadable(lazy(() => import('@/pages/service/common/mypage/employmentChange')));
const TeacherFaq = Loadable(lazy(() => import('@/pages/service/common/mypage/faq')));
const TeacherInquiry = Loadable(lazy(() => import('@/pages/service/common/mypage/inquiry')));
const TeacherInquiryPost = Loadable(lazy(() => import('@/pages/service/common/mypage/inquiryPost')));
const TeacherSetting = Loadable(lazy(() => import('@/pages/service/common/setting/settingMain')));
const TeacherTutorial = Loadable(lazy(() => import('@/pages/service/common/setting/tutorial')));
const TeacherAlert = Loadable(lazy(() => import('@/pages/service/common/alert/alert')));
const TeacherWithdrawal = Loadable(lazy(() => import('@/pages/service/common/mypage/withdrawal')));
const TeacherWithdrawalCompleted = Loadable(lazy(() => import('@/pages/service/common/mypage/withdrawalCompleted')));

const TeacherError = Loadable(lazy(() => import('@/pages/service/common/error')));


const TeacherRoutes = {
    path: '/service/teacher',
    element: <TeacherLayout />,
    children: [
        /* url 바로 접근시 - 홈으로 이동 */
        { path: '', element: <TeacherHome/> }, // Make the path empty to make it relative
        /* 교사 플로팅 */
        { path: 'floating', element: <TeacherFloating/> },
        /* 교사 버전 - 학습 */
        { path: 'home', element: <TeacherHome/> },
        { path: 'environment', element: <TeacherEnvironment/> },
        { path: 'learning', element: <TeacherLearning/> },
        { path: 'blended', element: <TeacherBlended/> },
        { path: 'myBox', element: <TeacherMyBox/> },
        /* 교사 버전 - 관리 */
        { path: 'studentManage', element: <StudentManage/> },
        { path: 'groupManage', element: <GroupManage/> },
        { path: 'groupDetail/:id', element: <GroupDetail/> },
        { path: 'groupManage/create', element: <GroupCreateManage/> },
        { path: 'deviceManage', element: <DeviceManage/> },
        { path: 'deviceDetail/:id', element: <DeviceDetail/> },
        { path: 'noticeManage', element: <NoticeManage/> },
        { path: 'noticeDetail/:id', element: <NoticeDetail/> },
        { path: 'noticePost', element: <NoticePost/> },
        /* 교사 버전 - 마이페이지 */
        { path: 'mypage', element: <TeacherMyPage/> },
        { path: 'mypage/passwordCheck', element: <TeacherPasswordCheck/> },
        { path: 'mypage/emailCert', element: <TeacherEmailCert/> },
        { path: 'mypage/basicInfoChange', element: <TeacherBasicInfo/> }, // 기본정보 변경
        { path: 'mypage/employmentChange', element: <TeacherEmployment/> }, // 재직 변경
        { path: 'mypage/withdrawal', element: <TeacherWithdrawal/> }, // 회원탈퇴
        { path: 'mypage/withdrawal/complete', element: <TeacherWithdrawalCompleted/> }, // 회원탈퇴 완료
        { path: 'mypage/faq', element: <TeacherFaq/> }, // 자주하는 질문
        { path: 'mypage/inquiry', element: <TeacherInquiry/> }, // 1:1 문의
        { path: 'mypage/inquiry/post', element: <TeacherInquiryPost/> }, // 1:1 문의 작성
        { path: 'setting', element: <TeacherSetting/> }, // 설정
        { path: 'setting/tutorial', element: <TeacherTutorial/> }, // 튜토리얼
        { path: 'alert', element: <TeacherAlert/> }, // 알림
        /* 500 에러*/
        { path: 'error', element: <TeacherError/> }, // 알림
        /* 404 페이지나 페이지가 없는 경우 - 홈으로 리다이렉트 */
        { path: '*', element: <TeacherHome /> }
    ]
};

export default TeacherRoutes;

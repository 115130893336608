import { coreApi } from '@/services/network';
import { DeviceListInterface } from '@/recoil/store/checkIn';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ###############################################
 * ############# 교실 내 디바이스 목록조회 #############
 * ############################################### */





/* function interface */
export interface FunctionReq {
  token: string
  classId: string
}
export interface FunctionRes {
  message: string        // 성공(SUCCESS)/실패(FAIL)/중복로그인(LOGOUT)
  errorCode: string;
  errorMessage: string;
  data?: DeviceListInterface[]
}


/* Api Interface */
interface ApiRes {
  status: number          // status
  message: string
  code: string
  data?: DeviceListInterface[]
}



export const getClassRoomDevice = async (request: FunctionReq): Promise<FunctionRes> => {
  const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/schools/classrooms/${request.classId}/device`;
  const method = 'GET';

  const res: ApiRes = await coreApi<{}, ApiRes>(endPoint, {}, method, request.token);

  const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';
  const errorCode = CoreMappingLogCode[res.code as CoreResponseErrorCode] || 'C9999';
  const errorMessage = 'Failed to retrieve device information.';

  if (res.code === CoreResponseCode.GET_CLASSROOM_DEVICE_SUCCESS && res.data) {
    return {
      message,
      errorCode,
      errorMessage,
      data: res.data
    };
  }
  return {
    message,
    errorCode,
    errorMessage
  };
};

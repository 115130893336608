import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupAgreementStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';
import { getList } from '@/services/content/terms/getList';
import { FunctionRes as setBlendedPolicyRes, setBlendedPolicy } from '@/services/core/users/setBlendedPolicy';
import { toastStore } from '@/recoil/store/common';
import { forceLogoutStore } from '@/recoil/store/user';


/* 튜토리얼 팝업 HOOK */
export default () => {

  // 페이지 이동
  const navigate = useNavigate();
  // 팝업 블렌디드 약관 동의 state
  const [popupAgreement, setPopupAgreement] = useRecoilState(popupAgreementStore);
  // 팝업 노출 여부
  const [isVisible, setIsVisible] = useState(false);
  // 약관동의 내용
  const [blendedAgreementText, setBlendedAgreementText] = useState('');
  // 세션 기본 정보
  const { sessionBaseInfo, sessionTokenInfo } = getSession();
  // 토스트 설정
  const setToast = useSetRecoilState(toastStore);
  // 강제 로그아웃
  const setForceLogout = useSetRecoilState(forceLogoutStore);


  useEffect(() => {
    getTermText();
  }, []);

  /** 팝업 노출 시키기**/
  useEffect(() => {
    if (popupAgreement.TYPE === 'agreement') {
      setIsVisible(true);
      document.body.style.overflow = 'hidden';
    } else {
      setIsVisible(false);
    }
  }, [popupAgreement.TYPE]);

  // 팝업 끄기
  const popupClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      setPopupAgreement((prevData) => ({ ...prevData, TYPE: '' }));
      document.body.style.overflow = '';
    }, 100);
  };



  // 약관동의 내용
  const getTermText = async (): Promise<void> => {
    const termsApiRes = await getList({ classificationCode: 'PRIVACY_POLICY_BLENDED' });

    if (termsApiRes.code === 'OK' && termsApiRes?.data.content) {
      setBlendedAgreementText(termsApiRes.data.content);
    } else if (termsApiRes.code === 'TIMEOUT') {
      navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
    } else {
      // Api 조회 실패로 팝업 노출 불가
    }

  };


  /* 블렌디드 약관동의 확인 */
  const agreeBlended = async () => {

    const setBlendedPolicyApi: setBlendedPolicyRes = await setBlendedPolicy({ token: sessionTokenInfo.coreAccessToken! });

    if (setBlendedPolicyApi.message === 'SUCCESS') {
      setPopupAgreement((prevData) => ({ ...prevData, isAgree: true }));
      popupClose();
    } else if (setBlendedPolicyApi.message === 'TOKEN_EXPIRED') {
      setForceLogout({ force: true, reason: 'token' });
    } else if (setBlendedPolicyApi.message === 'TIMEOUT') {
      setToast({ TYPE: 'alert', CONTENT: 'Failed to agree to the blended terms.', TIME: 3 });
    } else {
      setToast({ TYPE: 'alert', CONTENT: 'Failed to agree to the blended terms.', TIME: 3 });
    }

  };


  return { popupAgreement, isVisible, popupClose, navigate, agreeBlended, blendedAgreementText };
};

// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import ExternalLayout from '@/layout/service/externalLayout';
import Loadable from '@/components/Loadable';
/* 외부인증 */
const ExternalPass = Loadable(lazy(() => import('@/pages/service/external/externalPass')));

const ExternalRoutes = {
    path: '/external',
    element: <ExternalLayout />,
    children: [
        /* url 바로 접근시  */
        { path: 'pass', element: <ExternalPass/> }
    ]
};

export default ExternalRoutes;

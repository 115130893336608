import { useRecoilState } from 'recoil';
import { popupErrorStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 알림 팝업 HOOK */
export default() => {



    // 팝업 state
    const [popupError, setPopupError] = useRecoilState(popupErrorStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {

        if(popupError.length > 0){
            setTimeout(() => {
                // 팝업 visible
                setIsVisible(true);
            }, 100);
        }
    }, [popupError.length]);


    // 팝업 끄기
    const popupClose = (index : number) => {
        // 마지막 에러 팝업을 닫을 경우
        if(index === 0){
            // 팝업 invisible
            setIsVisible(false);
            // body scroll 재활성화
            document.body.style.overflow = '';
        }
        // 현재 에러 리스트의 1번째 데이터 삭제
        setTimeout(() => {
            setPopupError(prevData => prevData.slice(1));
        }, 100);
    };

    return {
        popupError,
        popupClose,
        isVisible
    };
};

import { contentApi } from '@/services/network';
import { getCodeList } from '@/services/content/code/getCodeList';

/* ######################################
 * ############# 녹화정보 저장 #############
 * ###################################### */


/* function interface */
export interface functionReq {
    token: string;
    title: string;  // 영상 제목
    note?: string;  // 영상 Description
    subjectName: string;    // 수업 이름
    schoolId: string;   // 학교 ID
    teacherId: string;  // 선생님 ID
    grade: string;  // 학년
}

export interface functionRes {
    code: string
    status: number
    message: string
    data?: {
        id: number; // 영상 PK
        title: string;  // 영상 제목
        note?: string;  // Description
        subjectName: string;    // 수업 이름
        schoolId: string;   // 학교 ID
        schoolName: string; // 학교 이름
        teacherId: string;  // 선생님 ID
        teacherName: string;    // 선생님 명
        grade: string;  // 학년
        disclosureStatus: 'ALL' | 'NON' | 'USER' |'CLASS';  // ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실)
        recordStatus: 'STARTED' | 'COMPLETE' | 'SAVE_FAILED' | 'DELETED';   // 레코딩 상태
        downloadStatus: 'ENABLE' | 'DISABLED';   // ENABLED - 가능, DISABLED - 불가능
        fileOriginName?: string;    // 파일 실제 이름
        filePath?: string;  // CDN PATH
        fileSize?: number;  // 파일 크기
        contentType?: string;
        duration: number;   // 영상시간
        expirationDate: string; // yyyy-MM-dd
        recordDate: string;    // yyyy-MM-dd
        targetList?: {
            id: number;
            name?: string;
            classroomId: string;    // 교실 ID
            classroomName: string;  // 교실명
        }[]
    }
}




/* Api Interface */
interface apiReq {
    title: string;  // 영상 제목
    note?: string;  // 영상 Description
    subjectName: string;    // 수업 이름
    schoolId: string;   // 학교 ID
    teacherId: string;  // 선생님 ID
    grade: string;  // 학년
    disclosureStatus: 'ALL' | 'NON' | 'USER' |'CLASS';  // ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실)
    recordStatus: 'STARTED' | 'COMPLETE' | 'SAVE_FAILED' | 'DELETED';   // 레코딩 상태
    downloadStatus?: 'ENABLED' | 'DISABLED';   // ENABLED - 가능, DISABLED - 불가능
    targetList?: {
        name?: string;          // 이름
        classroomId: string;    // 교실 ID
    }[]
}
interface apiRes {
    code: string
    status: number
    message: string
    data?: {
        id: number; // 영상 PK
        title: string;  // 영상 제목
        note?: string;  // Description
        subjectName: string;    // 수업 이름
        schoolId: string;   // 학교 ID
        schoolName: string; // 학교 이름
        teacherId: string;  // 선생님 ID
        teacherName: string;    // 선생님 명
        grade: string;  // 학년
        disclosureStatus: 'ALL' | 'NON' | 'USER' |'CLASS';  // ALL(전체공개), NON(비공개), USER(사용자), CLASS(교실)
        recordStatus: 'STARTED' | 'COMPLETE' | 'SAVE_FAILED' | 'DELETED';   // 레코딩 상태
        downloadStatus: 'ENABLED' | 'DISABLED';   // ENABLED - 가능, DISABLED - 불가능
        fileOriginName?: string;    // 파일 실제 이름
        filePath?: string;  // CDN PATH
        fileSize?: number;  // 파일 크기
        contentType?: string;
        duration: number;   // 영상시간
        expirationDate: string; // yyyy-MM-dd
        recordDate: string;    // yyyy-MM-dd
        targetList?: {
            id: number;
            name?: string;
            classroomId: string;    // 교실 ID
            classroomName: string;  // 교실명
        }[]
    }
}



export const saveRecordInfo = async(request: functionReq): Promise<functionRes> => {

    const endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/document/video`;
    const method = 'POST';

    const req: apiReq = {
        title: request.title,
        note: request.note,
        subjectName: request.subjectName,
        schoolId: request.schoolId,
        teacherId: request.teacherId,
        grade: request.grade,
        disclosureStatus: 'NON',
        recordStatus: 'STARTED',
        downloadStatus: 'DISABLED'
    };

    const res = await contentApi<apiReq, apiRes>(endPoint, req, method, request.token, null);

    if (res.code === 'OK') {
        return {
            code: 'OK',
            status: res.status,
            message: '녹화정보 저장 성공',
            data: res.data
        };
    }
    else {
        return {
            code: 'FAIL',
            status: res.status,
            message: '녹화정보 저장 실패'
        };
    }

};

import { downloadApi } from '@/services/network';

/* #####################################
 * ############# 음원다운로드 #############
 * ##################################### */


/* function interface */
export interface FunctionReq {
    token: string
    thumbnailId: number
}

export interface FunctionRes {
    code: string
    status: number
    message: string
    data: Blob
}




/* Api Interface */
interface ApiRes {
    code: string
    status: number
    message: string
    data: any
}



export const downloadThumbnail = async(request: FunctionReq): Promise<FunctionRes> => {
    let endPoint = `${process.env.REACT_APP_CONTENT_SERVER}/${process.env.REACT_APP_CONTENT_VERSION}/sound/thumbnail/${request.thumbnailId}`;
    const method = 'GET';

    const blob = await downloadApi<any, ApiRes>(endPoint, {}, method, request.token);

    return {
        code: 'OK',
        status: 200,
        message: 'SUCCESS',
        data: blob
    };
};

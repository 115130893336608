import React from 'react';
import '@/components/popup/popup.css';
import popupErrorHook from '@/hooks/components/popup/common/popupError';




/* 알림 팝업 */
const PopupError = () => {

  const { popupError, popupClose, isVisible } = popupErrorHook();

  return (

    <>
      {popupError.length > 0 &&
        <div className={`layout-popup error ${isVisible && 'visible'}`}>
          <div className={'layout-popup-box alert'}>

            <article className={'layout-popup-content'}>
              <span>{popupError[0].MESSAGE} [{popupError[0].CODE}]</span>
            </article>

            <button className={'layout-popup-button'} onClick={() => popupClose(popupError.length - 1)}>
              OK
            </button>
          </div>
        </div>
      }
    </>




  );
};

export default PopupError;

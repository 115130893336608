import { coreApi } from '@/services/network';
import { CoreMappingCode, CoreMappingLogCode, CoreResponseCode, CoreResponseErrorCode } from '@/utils/code-enums';

/* ##########################################
 * ############# 학생 권한정보 변경 #############
 * ########################################## */





/* function interface */
export interface FunctionReq {
    token: string
}
export interface FunctionRes {
    message: string;
}


/* Api Interface */
interface ApiReq {
    isAgree: true
}
export interface ApiRes {
    code: string;
    message: string         // core 반환 구분값 desc
}



export const setBlendedPolicy = async(request: FunctionReq): Promise<FunctionRes> => {
    const endPoint = `${process.env.REACT_APP_CORE_SERVER}/${process.env.REACT_APP_CORE_VERSION}/users/policy/blended`;
    const method = 'PUT';



    const res: ApiRes = await coreApi<ApiReq, ApiRes>(endPoint, { isAgree: true }, method, request.token, null);

    const message = CoreMappingCode[res.code as CoreResponseCode] || 'FAIL';

    return {
        message
    };
};

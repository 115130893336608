import { atom } from 'recoil';
import { ClassTalkListInterface } from '@/recoil/store/class';
import { recoilPersist } from 'recoil-persist';

const { persistAtom } = recoilPersist();
/* @@@@@@@@@ 나의 동작 제어로 노출되는 팝업 @@@@@@@@@ */
export interface PopupInterface {
  TYPE: string;
  TITLE ?: string;
  CONTENT ?: any;
  BUTTON ?: string;
  RESERVE ?: any;
}

export const popupStore = atom<PopupInterface>({
    key: 'popup',
    default: {
        TYPE: '',
        TITLE: '',
        CONTENT: '',
        BUTTON: '',
        RESERVE: ''
    }
});


/* @@@@@@@@@ 나의 동작 제어로 노출되는 중첩 팝업 @@@@@@@@@ */
/* @@@@@@@@@ 화면공유를 취소하시겠습니까? 파일은 5개 이내에서만 전송가능해요 등등 @@@@@@@@@ */
export interface PopupNestingInterface {
    TYPE: string;
    TITLE ?: string;
    CONTENT ?: any;
    BUTTON ?: any;
    RESERVE ?: string;
}

export const popupNestingStore = atom<PopupNestingInterface>({
    key: 'popupNesting',
    default: {
        TYPE: '',
        TITLE: '',
        CONTENT: '',
        BUTTON: '',
        RESERVE: ''
    }
});



/* @@@@@@@@@ 타 유저의 동작제어로 팝업 @@@@@@@@@ */
/* @@@@@@@@@ 화면공유 요청, 파일전송 요청 등등 @@@@@@@@@ */
export interface PopupOthersInterface {
    TYPE: string; // mirror / link / msg / file / onlineClass
    REQUEST: boolean;
    DATA : ClassTalkListInterface |  null
    MESSAGE ?: { messageType: string, uid: string, subject: string, class: string, senderName: string, sendTime: string, studentNumber: string, target: string, message: string, date: Date, expirationDate: Date } | null
    RESERVE ?: { roomId: string, teacherName: string } | null
    MSGTYPE ?: 'receive' | 'send' | null
}

export const popupOtherStore = atom<PopupOthersInterface>({
    key: 'popupOthers',
    default: {
        TYPE: '',
        REQUEST: false,
        DATA: null,
        MESSAGE: null,
        RESERVE: null,
        MSGTYPE: null
    }
});



/* @@@@@@@@@ 자동로그아웃 팝업 @@@@@@@@@ */
export interface PopupAutoInterface {
    TYPE: string;
}

export const popupAutoStore = atom<PopupAutoInterface>({
    key: 'popupAutoLogout',
    default: {
        TYPE: ''
    }
});


/* @@@@@@@@@ 튜토리얼 팝업 @@@@@@@@@ */

export interface PopupTutorialInterface {
    TYPE: string;
}

export const popupTutorialStore = atom<PopupTutorialInterface>({
    key: 'popupTutorial',
    default: {
        TYPE: ''
    }
});

/* @@@@@@@@@ 블렌디드 약관 동의 팝업 @@@@@@@@@ */

export interface PopupAgreementInterface {
    TYPE: string;
    isAgree: boolean;
}

export const popupAgreementStore = atom<PopupAgreementInterface>({
    key: 'popupAgreement',
    default: {
        TYPE: '',
        isAgree: false
    }
});



/* @@@@@@@@@ 시스템 팝업 @@@@@@@@@ */
export interface PopupSystemInterface {
    TYPE: string;
    TITLE ?: string;
    CONTENT ?: any;
    BUTTON ?: any;
    RESERVE ?: string;
}

export const popupSystemStore = atom<PopupSystemInterface>({
    key: 'popupSystem',
    default: {
        TYPE: '',
        TITLE: '',
        CONTENT: '',
        BUTTON: '',
        RESERVE: ''
    }
});


/* @@@@@@@@@ 공지사항 팝업 @@@@@@@@@ */
export interface PopupNoticeInterface {
    TYPE: string;
    ID : string;
    TITLE : string;
    CONTENT : any;
}

export const popupNoticeStore = atom<PopupNoticeInterface>({
    key: 'popupNotice',
    default: {
        TYPE: '',
        ID: '',
        TITLE: '',
        CONTENT: ''
    }
});

/* @@@@@@@@@ 공지사항 팝업, 다시보지 않기한 공지사항 ID @@@@@@@@@ */
export const popupNoticeAgainListStore = atom<number>({
    key: 'popupNoticeAgainList',
    default: 0,
    effects_UNSTABLE: [persistAtom]
});


/* @@@@@@@@@ 교실환경 팝업 @@@@@@@@@ */
export interface PopupEnvironmentInterface {
    TYPE: string;
    TITLE ?: string;
    CONTENT ?: any;
    BUTTON ?: any;
    RESERVE ?: string;
}

export const popupEnvironmentStore = atom<PopupEnvironmentInterface>({
    key: 'popupEnvironment',
    default: {
        TYPE: '',
        TITLE: '',
        CONTENT: '',
        BUTTON: '',
        RESERVE: ''
    }
});



/* @@@@@@@@@ 서버 에러로 인한 팝업 @@@@@@@@@ */
export interface PopupErrorInterface {
    CODE: string;
    MESSAGE: string;
    RESERVE ?: any;
}

export const popupErrorStore = atom<PopupErrorInterface[]>({
    key: 'popupError',
    default: []
});




















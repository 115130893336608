// 리액트 내장 함수
import { lazy } from 'react';
// 컴포넌트
import Loadable from '@/components/Loadable';
import FloatingLayout from '@/layout/service/floatingLayout';

/* 플로팅 페이지 */
const FloatingTeacher= Loadable(lazy(() => import('@/pages/service/common/floating/teacherMenu')));
const FloatingStudent = Loadable(lazy(() => import('@/pages/service/common/floating/studentMenu')));


const FloatingRoutes = {
    path: '/floating',
    element: <FloatingLayout />,
    children: [
        { path: 'teacher', element: <FloatingTeacher/> },
        { path: 'student', element: <FloatingStudent/> }
    ]
};

export default FloatingRoutes;

import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import PageContainer from '@/components/container/signage/PageContainer';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { deviceListStore, signageDeviceSelectedStore } from '@/recoil/store/checkIn';
import { getSession } from '@/hooks/common';
import deviceManagement from '@/hooks/services/deviceManagement';
import musicPlayerManagement from '@/hooks/services/musicPlayerManagement';
import { musicPlayerStore } from '@/recoil/store/signage';
import publicMethod from '@/utils/publicMethod';
import LG_GIF from '@/assets/gif/lghv.gif';
import signageAlim from '@/hooks/services/signageAlim';



/* ———————— 사이니지 레이아웃  ———————— */
const SignageLayout = () => {


  /* —————————————————————————————————————————— */
  /* ———————————————— 변수 설정  ———————————————— */
  /* —————————————————————————————————————————— */

  /* 교실 디바이스 목록 */
  const setDeviceList = useSetRecoilState(deviceListStore);
  const signageDeviceSelected = useRecoilValue(signageDeviceSelectedStore);
  // 세션 정보
  const { sessionBaseInfo } = getSession();
  // 로딩 상태
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // 오디오 리퍼런스
  const audioRef = useRef<HTMLAudioElement>(null);
  // 오디오 탭 인덱스
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0);
  // 전역 뮤직 플레이어 상태
  const [playerState, setPlayerState] = useRecoilState(musicPlayerStore);
  // 교실환경 모드설정 관리
  deviceManagement();
  // 음원 플레이어 관리
  const { playerMusicDownload, setPlayerMusicList, getSchoolMusicList } = musicPlayerManagement();

  signageAlim();


  /* —————————————————————————————————————————— */
  /* ——————————————— useEffect  ——————————————— */
  /* —————————————————————————————————————————— */

  /* 최초 접근 시 */
  useEffect(() => {
    // 교실 디바이스 목록 조회
    if (sessionBaseInfo?.schoolInfo?.classId) {
      setDeviceList({ classId: sessionBaseInfo.schoolInfo.classId, deviceList: [] });
    }

    // 음악 전곡 다운로드

  }, []);



  /* 사이니지 디바이스 목록 조회가 완료될때까지 로딩 */
  useEffect(() => {
    if (signageDeviceSelected) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [signageDeviceSelected]);





  /*  음원 플레이어 관리 */
  useEffect(() => {
    const fetchAudio = async () => {
      if (playerState.musicList.length > 0 && !playerState.musicDownloading) {
        const currentMusic = playerState.musicList[playerState.currentTrackIndex];
        if (currentMusic.audioUrl) {
          if (audioRef.current) {
            audioRef.current.src = currentMusic.audioUrl!;
            if (playerState.isPlaying) {
              try {
                console.log(audioRef.current);
                audioRef.current.currentTime = playerState.elapsedTime;
                await audioRef.current.play();
                if (playerState.replay) { // replay 부분 변수값 초기화를 통해 다시 재생될 수 있도록 세팅
                  setPlayerState(prevState => ({ ...prevState, replay: false }));
                }
              } catch (error) {
                setPlayerState(prevState => ({ ...prevState, isPlaying: false }));
                console.error('Audio play failed:', error);
              }
            } else {
              // alert('정지');
              audioRef.current.currentTime = playerState.elapsedTime;
              audioRef.current.pause();
            }
          }
        } else {
          setPlayerState(prevState => ({ ...prevState, musicDownloading: true }));
          playerMusicDownload(playerState.currentTrackIndex);
        }
      }
    };
    fetchAudio();
  }, [playerState.currentTrackIndex, playerState.isPlaying, playerState.musicList, playerState.replay, playerState.musicDownloading]);






  /* —————————————————————————————————————————— */
  /* ————————————— User Action 함수 ———————————— */
  /* —————————————————————————————————————————— */


  /* 음원 플레이어 컨트롤 함수 */
  const handlePlayPause = async () => {

    setPlayerState(prevState => ({
      ...prevState,
      isPlaying: !prevState.isPlaying
    }));

    if (audioRef?.current) {
      if (playerState.isPlaying) {
        audioRef.current.pause();
      } else {
        try {
          await audioRef.current.play();
        } catch (error) {
          console.error('Audio play failed:', error);
        }
      }
    }
  };

  /* 음원 플레이어 슬라이더 변경 함수 */
  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);

    setPlayerState(prevState => ({ ...prevState, elapsedTime: value }));

    if (audioRef?.current) {
      audioRef.current.currentTime = value;
    }
  };

  /* 음원 플레이어 리스트 클릭 함수 */
  const clickMusicItem = async (getIndex: number) => {

    // 선택한 곡이 다를 때에만 아래 함수 작동 (같은  곡 선택시, 작동 X)
    if (selectedTabIndex !== playerState.categoryIndex) {
      // 카테고리 변경
      if (audioRef?.current) {
        await setPlayerMusicList(selectedTabIndex, getIndex);
      }
    } else if (getIndex !== playerState.currentTrackIndex) {
      // 동일 카테고리 && 트랙 인덱스 변경
      if (audioRef?.current) {
        setPlayerState(prevState => ({
          ...prevState,
          currentTrackIndex: getIndex,
          isPlaying: true,
          elapsedTime: 0,
          totalTime: publicMethod.convertToSeconds(prevState.musicList[getIndex].duration)
        }));
      }
    }
  };

  /* —————————————————————————————————————————— */
  /* ———————————————— 기타 함수  ———————————————— */
  /* —————————————————————————————————————————— */

  /* 오디오가 끝났을 때의 함수 */
  const handleEnd = () => {

    // 1곡 반복 (2), 자동재생(1), 자동재생 안함(0)에 따라 분기 처리


    if (playerState.playOrder === 0) { //  자동재생 안함일 경우

      if (playerState.currentTrackIndex !== playerState.musicList.length - 1) { // 마지막 곡이 아닐 경우
        setPlayerState(prevState => ({
          ...prevState,
          currentTrackIndex: (prevState.currentTrackIndex + 1) % prevState.musicList.length,
          elapsedTime: 0,
          totalTime: publicMethod.convertToSeconds(prevState.musicList[(prevState.currentTrackIndex + 1) % prevState.musicList.length].duration)
        }));
      } else { // 마지막 곡일 경우

        setPlayerState(prevState => ({
          ...prevState,
          currentTrackIndex: 0,
          isPlaying: false,
          elapsedTime: 0,
          totalTime: publicMethod.convertToSeconds(prevState.musicList[0].duration)
        }));
      }
    }
    else if (playerState.playOrder === 1) { //  자동재생일 경우

      if (playerState.musicList.length === 1) { // 곡이 플레이리스테 하나인 경우, 해당 곡 다시 재생
        setPlayerState(prevState => ({
          ...prevState,
          currentTrackIndex: prevState.currentTrackIndex,
          elapsedTime: 0,
          replay: true
        }));
      }
      else {
        setPlayerState(prevState => ({
          ...prevState,
          currentTrackIndex: (prevState.currentTrackIndex + 1) % prevState.musicList.length,
          elapsedTime: 0,
          totalTime: publicMethod.convertToSeconds(prevState.musicList[(prevState.currentTrackIndex + 1) % prevState.musicList.length].duration)
        }));
      }

    }
    else { //  1곡 반복일 경우
      setPlayerState(prevState => ({
        ...prevState,
        currentTrackIndex: prevState.currentTrackIndex,
        elapsedTime: 0,
        replay: true
      }));
    }
  };


  /* 오디오가 진행중일 때의 함수 */
  const handleTimeUpdate = () => {
    if (audioRef.current) {

      if (playerState.musicList[playerState.currentTrackIndex].audioUrl) {
        // console.log(audioRef.current!.currentTime);
        setPlayerState(prevState => ({
          ...prevState,
          // elapsedTime: Math.floor(audioRef.current!.currentTime)
          elapsedTime: audioRef.current!.currentTime
        }));
      }
    }
  };






  return (
    <div className='AppRoot'>
      {/* 전역 뮤직 플레이어 컴포넌트 */}
      <div>
        <audio ref={audioRef} onEnded={handleEnd} onTimeUpdate={handleTimeUpdate} style={{ display: 'none' }}></audio>
      </div>
      {/* 컨텐츠 메인 */}
      {
        isLoading ?
          <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={LG_GIF} alt="LGHV GIF" draggable={false} />
          </div>
          :
          <PageContainer>
            <Outlet context={{ audioRef, handlePlayPause, handleSliderChange, clickMusicItem, selectedTabIndex, setSelectedTabIndex, setPlayerMusicList, getSchoolMusicList }} />
          </PageContainer>
      }
    </div>
  );

};

export default SignageLayout;

import { Outlet } from 'react-router-dom';
import BoardContainer from '@/components/container/service/full/BoardContainer';
import boardLayout from '@/hooks/layout/boardLayout';


/**  전자칠판/원퀵 레이아웃 ( 실제 화면은 미러링 화면만 존재 )  **/
const BoardLayout = () => {

    const { usersInfo, returnPermissionMessage, getDetailMonitorRequest, deleteClassTalk } = boardLayout();

    return (
        <div className='AppRoot'>


            {/* z index 100 */}
            {/* 타유저로부터 요청이 올경우 */}
            <BoardContainer usersInfo={usersInfo} returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnPermissionMessage} getDetailMonitorRequest={getDetailMonitorRequest} deleteClassTalk={deleteClassTalk}>
                <Outlet/>
            </BoardContainer>

        </div>
    );

};

export default BoardLayout;

import React, { useEffect, useState } from 'react';
import '@/components/container/service/full/mirrorContainer.css';
import { myMirrorManagementStore } from '@/recoil/store/checkIn';
import { UsersInfoInterface } from '@/hooks/services/checkIn';
import { useRecoilValue } from 'recoil';
import { getSession } from '@/hooks/common';
import { mirrorAction } from '@/services/native';
import VideoReadyImg from '@/assets/image/learning/video_ready.png';
import GetShare from '@/assets/icon/learning/getShare.svg';  // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import { classTalkPopupStore } from '@/recoil/store/class';


/* 실제 UI USER LIST 인터페이스 */
interface MirrorTempTagInterface {
  userId: string,
  userName: string,
  userNumber: string | null | undefined
  userRole: string,
  canBig: boolean,
  isBig: boolean
  videoNumber: string
}

/* Props */
interface MirrorContainerProps {
  usersInfo: UsersInfoInterface[]
  mirrorStopCommand?: (mirrorRoomId: number) => boolean
}

/* Mirror UI Component */
const MirrorContainer = ({ usersInfo, mirrorStopCommand }: MirrorContainerProps) => {

  /* 사용자 세션 정보 */
  const { deviceInfo, sessionBaseInfo } = getSession();

  /* 내 미러링 정보 */
  const myMirrorManagement = useRecoilValue(myMirrorManagementStore);

  /* Component Visible / UnVisible */
  const [isVisible, setIsVisible] = useState(false);

  /* UI 표기용으로 정제된 미러링 목록 */
  const [tempTag, setTempTag] = useState<MirrorTempTagInterface[]>([]);

  const popupList = useRecoilValue(classTalkPopupStore);



  /*
   * UI 표기용 미러링 리스트 정제 Hook
   * 1. 미러링 정보가 있을 경우 내부 로직 실행
   * 2. mirror.js 에서 Publish uuid 를 받아서 videoNumber 를 반환하는 Function 정의
   *      - Janus 모듈이 Pub 이 생성된다면 해당 Pub 의 송출 video Dom 을 찾기위해 호출되는 함수이다.
   * 3. UI에 화면분할을 명확히 하기 위해 실제 미러링 목록에 가상으로 채울 UI 를 더해서 화면에 그릴 TempTag 를 재구성한다.
   *      - 3명일 경우 화면분할 4
   *      - 4명일 경우 화면분할 4
   *      - 5명 이상일 경우 화면분할 9
   */
  useEffect(() => {
    /* 1. 미러링 정보가 있을 경우 내부 로직 실행 */
    if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log(`MY MIRROR MANAGEMENT : ${JSON.stringify(myMirrorManagement)}`);
    }
    if (myMirrorManagement) {
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log(`TEMP TAG : ${JSON.stringify(tempTag)}`);
      }

      /* 2. mirror.js 에서 Publish uuid 를 받아서 videoNumber 를 반환하는 Function 정의 */
      window.getUserVideoIndex = (uuid: string) => {
        let videoNumber = -999;
        tempTag.forEach((m, index) => {
          if (uuid === m.userId) {
            videoNumber = Number(m.videoNumber);
          }
        });
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`LINK SCHOOL MIRROR USER INDEX - ${uuid} ------> ${videoNumber}`);
        }
        return videoNumber;
      };

    }



  }, [tempTag]);

  /*
   * Component Visible/UnVisible 처리 및 미러링 정보를 UI 표기용 미러링 리스트에 추가 Hook
   * 1. 내 미러링 정보가 있을 경우
   *  1_1. 내 미러링 리스트만큼 반복하며 tempTag 에 추가한다.
   *      - 추가할 때 추가 대상의 uuid 를 통해 기존에 tempTag 에 추가되어있던 정보가 있는지 확인한다.
   *      - 해당 미러링 정보가 tempTag 에 있는 경우
   *          - 기존에 있던 정보를 추가한다.
   *      - 해당 미러링 정보가 tempTag 에 없는 경우
   *          - 새로운 videoNumber 를 할당하여 추가한다.
   *  1_2. 나의 미러링이 subscribe 인 경우
   *      - deviceModel 이 OPS, Onequick 인 경우 경우 화면 최대화를 진행한다.
   *      - 100ms 후에 화면을 visible 처리한다.
   * 2. 내 미러링 정보가 없을 경우
   *  2_1. tempTag 를 초기화한다.
   *  2_2. deviceModel 이 OPS, Onequick 인 경우 화면 최소화를 진행한다.
   * */
  useEffect(() => {

    /* 1. 내 미러링 정보가 있을 경우 */
    if (myMirrorManagement) {

      let tempTagTemp = [];

      /* 1_1. 내 미러링 리스트만큼 반복하며 tempTag 에 추가한다. */
      for (const pubInfo of myMirrorManagement.pubInfo) {

        /* 추가할 때 추가 대상의 uuid 를 통해 기존에 tempTag 에 추가되어있던 정보가 있는지 확인한다. */
        const targetUser = tempTag.find(i => i.userId === pubInfo.id);

        /* 해당 미러링 정보가 tempTag 에 없는 경우, 새로운 videoNumber 를 할당하여 추가한다. */
        if (!targetUser) {
          let randomNo = 1;
          while (true) {
            randomNo = publicMethod.getRandomInt(1, 1001);
            const checkNumber = myMirrorManagement.pubInfo.find(i => i.videoNumber === randomNo.toString());
            const tempTagTempCheck = tempTagTemp.find(i => i.videoNumber === randomNo.toString());
            if (!checkNumber && !tempTagTempCheck) {
              break;
            }
          }
          tempTagTemp.push({
            videoNumber: randomNo.toString(),
            userId: pubInfo.id,
            userName: pubInfo.name,
            userNumber: pubInfo.number,
            userRole: pubInfo.division,
            canBig: true,
            isBig: false
          });
        }
      }

      /* 나간 유저 제외처리 */
      let tempTagWithoutOutUser = tempTag.filter(i => myMirrorManagement.pubInfo.find(j => j.id === i.userId));
      console.log('myMirrorManagement.pubInfo', myMirrorManagement.pubInfo.length);
      console.log('tempTag', tempTag.length);
      console.log('tempTagWithoutOutUser', tempTagWithoutOutUser.length);
      setTempTag([...tempTagWithoutOutUser, ...tempTagTemp]);


      /* 1_2. 나의 미러링이 subscribe 인 경우  */
      if (myMirrorManagement.position === 'sub') {

        /* deviceModel 이 OPS, Onequick 인 경우 경우 화면 최대화를 진행한다.  */
        mirrorAction('screen', { command: 'state', state: 'max' });

        /* 100ms 후에 화면을 visible 처리한다. */
        setTimeout(() => {
          setIsVisible(true);
        }, 100);

      }
    }

    /* 내 미러링 정보가 없을 경우 */
    else {

      /* 2_1. tempTag 를 초기화한다. */
      setTempTag([]);

      /* 2_2. deviceModel 이 OPS, Onequick 인 경우 화면 최소화를 진행한다. */
      if (deviceInfo && (deviceInfo.model === 'OPS' || deviceInfo.model === 'Onequick')) {

        mirrorAction('screen', { command: 'state', state: 'min' });

        if (popupList.length > 0) {
          setTimeout(() => {
            mirrorAction('popup', { command: 'popup', width: '400', height: '500' });
          }, 500);
        }
      }
    }

  }, [myMirrorManagement, myMirrorManagement?.pubInfo]);


  /*
   * video 최대화, 원상복귀를 처리한다.
   * */
  const videoScaleChange = (v: MirrorTempTagInterface) => {
    if (sessionBaseInfo?.baseInfo.role === 'board') {
      if (v.canBig) {
        v.isBig = !v.isBig;
      }
    }
  };


  /*
   * 미러링 공유중지 요청 함수
   * */
  const roomDestroy = (roomNumber: number) => {
    if (mirrorStopCommand) {
      mirrorStopCommand(roomNumber);
      if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
        console.log('LINK SCHOOL MIRROR UI DESTROY SUCCESS');
      }
    } else if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
      console.log('LINK SCHOOL MIRROR UI DESTROY FAIL');
    }
  };

  // 중첩 삼항 연산자를 함수로 추출
  const getScreenContainerClass = (length: number) => {
    console.log('mirror length=====', length);
    if (length === 1) return '';
    if (length === 2) return 'double';
    if (length === 3 || length === 4) return 'quadra';
    return 'nine';
  };

  // console.log('stop screen1', sessionBaseInfo?.baseInfo, sessionBaseInfo?.baseInfo.role, myMirrorManagement, myMirrorManagement?.position);

  return (
    <div className={`layout-mirror ${isVisible && myMirrorManagement && myMirrorManagement.position === 'sub' && 'visible'}`}>
      <div className='layout-mirror-box'>
        {
          tempTag.length > 0 && myMirrorManagement && myMirrorManagement.position === 'sub' &&
          <>
            {tempTag.map((v, i) => (
              <div key={v.videoNumber} className={`layout-mirror-screen-container ${getScreenContainerClass(tempTag.length)}`}>
                <div className={`layout-mirror-screen-box ${v.isBig ? 'mirror-screen-full' : ''}`} onClick={() => videoScaleChange(v)} >
                  <video id={`mirror_video${v.videoNumber}`} width="100%" height="100%" autoPlay playsInline muted poster={VideoReadyImg}></video>
                  {
                    v.userId &&
                    <div className='layout-mirror-user-data'>
                      <div className='layout-mirror-text'>
                        <img src={publicMethod.svgClear(GetShare)} alt={'GetShare'} draggable={false} />

                        {/* If pub is teacher */}
                        {
                          v.userRole === 'teacher' &&
                          <> {v.userName} Teacher</>
                        }

                        {/* If pub is student */}
                        {
                          v.userRole === 'student' &&
                          <> {v.userNumber} {v.userName}</>
                        }

                        {/* If pub is board */}
                        {
                          (v.userRole !== 'teacher' && v.userRole !== 'student') &&
                          <> {v.userName} </>
                        }
                      </div>
                    </div>
                  }

                </div>
              </div>
            ))}
          </>
        }
        {/* ---------------------------------------------- */}
        {/* Handle full mode and group mode separately */}
        {/* ---------------------------------------------- */}
        {/* { */}
        {/*     sessionBaseInfo && sessionBaseInfo?.baseInfo && sessionBaseInfo?.baseInfo.role === 'teacher' &&  */}
        {/*     <div className='layout-mirror-stop' onClick={() => roomDestroy(lectureStateManagement.mirrorInfo[0].webRtcRoomId)}> */}
        {/*         Stop Screen Sharing */}
        {/*     </div> */}
        {/* } */}

        {/* If teacher and I am a SUB in screen sharing - Same for group mode and full mode */}
        {
          // sessionBaseInfo?.baseInfo && sessionBaseInfo?.baseInfo.role === 'teacher' && myMirrorManagement && myMirrorManagement.position === 'sub' &&
          sessionBaseInfo?.baseInfo && sessionBaseInfo.baseInfo.role === 'teacher' && myMirrorManagement && myMirrorManagement.position === 'sub' &&
          <div className='layout-mirror-stop' onClick={() => roomDestroy(myMirrorManagement?.webRtcRoomId)}>
            Stop Screen Sharing
          </div>
        }
      </div>
    </div>
  );
};

export default MirrorContainer;

import { useEffect } from 'react';
import { getSession } from '@/hooks/common';
import { classSetting } from '@/services/core/me/classSetting';
import { deviceManagementStore } from '@/recoil/store/teacherManagement';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { classModeStore } from '@/recoil/store/class';
import { getClassRoomDevice } from '@/services/core/schools/getClassRoomDevice';
import { DeviceListInterface, deviceListStore, signageDeviceSelectedStore } from '@/recoil/store/checkIn';
import { forceLogoutStore } from '@/recoil/store/user';
import { blendedStateStore } from '@/recoil/store/blended';
import publicMethod from '@/utils/publicMethod';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import { useNavigate } from 'react-router-dom';
import { popupErrorStore } from '@/recoil/store/popup';

/* 디바이스 셋팅정보 관리 & Core 업데이트 */
export default () => {

    // 페이지 이동
    const navigate = useNavigate();
    const { sessionBaseInfo, sessionTokenInfo } = getSession();

    const [deviceManagement, setDeviceManagement] = useRecoilState(deviceManagementStore);
    const [deviceList, setDeviceList] = useRecoilState(deviceListStore);
    const setSignageDeviceSelected = useSetRecoilState(signageDeviceSelectedStore);
    const setForceLogout = useSetRecoilState(forceLogoutStore);
    const [deviceClassMode, setDeviceClassMode] = useRecoilState(classModeStore);
    const setBlendedState = useSetRecoilState(blendedStateStore);
    const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트
    // 팝업 에러 state
    const setPopupError = useSetRecoilState(popupErrorStore);


    const deviceUpdate = async() => {
        const apiRes = await classSetting({
            token: sessionTokenInfo.coreAccessToken!,
            type: 'aiot',
            settingKey: sessionBaseInfo?.baseInfo.userId!,
            data: JSON.stringify(deviceManagement)
        });
        if (apiRes.message === 'SUCCESS') {
            setLoginSessionCount(publicMethod.sessionUpdate);
        }
        else if (apiRes.message === 'LOGOUT') {
            setForceLogout({ force: true, reason: 'otherDevice' });
        }
        else if (apiRes.message === 'TOKEN_EXPIRED') {
            setForceLogout({ force: true, reason: 'token' });
        }
        else if (apiRes.message === 'TIMEOUT') {
            navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
        }else{
            // 서버 에러 팝업
            setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);

        }
    };


    const getClassDeviceList = async () => {
        if (sessionTokenInfo.coreAccessToken && deviceList.classId !== '') {
            const deviceListRes = await getClassRoomDevice({ token: sessionTokenInfo.coreAccessToken, classId: deviceList.classId });

            if (deviceListRes.message === 'SUCCESS' && deviceListRes.data) {

                setLoginSessionCount(publicMethod.sessionUpdate);

                const camera = deviceListRes.data.find(device => device.categoryId === 'CAMERA');
                if (camera) {
                    setBlendedState((prev) => ({ ...prev, deviceId: camera.deviceId }));
                }

                const deviceList_: DeviceListInterface[] = deviceListRes.data.filter(device => device.status === 'normal' && device.active === 'enabled');
                const newDeviceList = deviceList_.map(device => {
                    if (device.categoryId === 'AIR_QUALITY_SENSOR') {
                        return { ...device, frontType: 'SENSOR' };
                    }
                    else if (device.categoryId === 'LIGHT_SENSOR') {
                        return { ...device, frontType: 'SENSOR' };
                    }
                    else if (device.categoryId === 'BLIND') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'LIGHT') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'AIR_CONDITIONER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'AIR_PURIFIER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'REFRIGERATOR') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'DEHUMIDIFIER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'WASHER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'DRYER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'ROBOT_CLEANER') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    else if (device.categoryId === 'SYNCPRO') {
                        return { ...device, frontType: 'CONTROL' };
                    }
                    return { ...device, frontType: 'NORMAL' };
                });
                setDeviceList((prev) => ({ ...prev, deviceList: newDeviceList }));
                const targetDeviceClassMode = deviceManagement.find(device => device.classId === deviceList.classId);
                if (targetDeviceClassMode) {
                    setDeviceClassMode((prev) => ({
                        ...prev,
                        classId: deviceList.classId,
                        lecture: targetDeviceClassMode.modeInfo.lecture,
                        talk: targetDeviceClassMode.modeInfo.talk,
                        multi: targetDeviceClassMode.modeInfo.multi,
                        rest: targetDeviceClassMode.modeInfo.rest
                    }));
                } else {
                    setDeviceClassMode((prev) => ({
                        ...prev,
                        classId: deviceList.classId
                    }));
                }
            }
            else if (deviceListRes.message === 'LOGOUT') {
                setForceLogout({ force: true, reason: 'otherDevice' });
                return;
            }
            else if (deviceListRes.message === 'TOKEN_EXPIRED') {
                setForceLogout({ force: true, reason: 'token' });
                return;
            }
            else if (deviceListRes.message === 'TIMEOUT') {
                navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
            }else{
                // 서버 에러 팝업
                setPopupError(prevData => [...prevData, { CODE: deviceListRes.errorCode, MESSAGE: deviceListRes.errorMessage }]);
            }
            setSignageDeviceSelected(true);
        }
    };


    useEffect(() => {
        getClassDeviceList();
    }, [deviceList.classId]);


    useEffect(() => {

        if (deviceClassMode.classId !== '') {
            const targetDevice = deviceManagement.find(device => device.classId === deviceClassMode.classId);

            if (targetDevice) {
                setDeviceManagement(prev => prev.map(device => {
                    if (device.classId === deviceClassMode.classId) {
                        return {
                            classId: deviceClassMode.classId,
                            modeInfo: {
                                lecture: deviceClassMode.lecture,
                                talk: deviceClassMode.talk,
                                multi: deviceClassMode.multi,
                                rest: deviceClassMode.rest
                            }
                        };
                    }
                    return device;
                }));
            } else {
                setDeviceManagement(
                    [...deviceManagement,
                        {
                            classId: deviceClassMode.classId,
                            modeInfo: {
                                lecture: deviceClassMode.lecture,
                                talk: deviceClassMode.talk,
                                multi: deviceClassMode.multi,
                                rest: deviceClassMode.rest
                            }
                        }]);
            }
        }

    }, [deviceClassMode]);


    useEffect(() => {

        if (sessionTokenInfo.coreAccessToken && sessionBaseInfo?.baseInfo.role === 'teacher' && deviceManagement.length > 0) {
            deviceUpdate();
        }

    }, [deviceManagement]);


};

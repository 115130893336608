import { atom } from 'recoil';
import { MqttMirrorManageMessage } from '@/services/classMqtt';

/* 모둠관리 - 서버용 */
export interface SubGroupMemberInterface {
    id?: number
    memberId: string
    division: string
    name: string
    number: string | null
    position: 'leader' | 'member'
    deviceActive: boolean
}

export interface SubGroupInterface {
    id?: number              // 서브그룹 아이디(subGroupId -> id)
    subGroupName: string
    subMirror: boolean;
    subGroupMember: SubGroupMemberInterface[]
}

export interface GroupManagementInterface {
    id?: number          // 그룹 아이디(groupId -> id)
    groupName: string
    subGroupList: SubGroupInterface[]
}

export interface GroupListManagementInterface {
    id?: number
    teacherId: string
    classId: string
    groupList: GroupManagementInterface[]
}
export const groupManagementStore = atom<GroupListManagementInterface[]>({
    key: 'groupManagement',
    default: []
});


/* 교실 별 AIoT 모드 관리 */
interface ClassSettings {
    lightness: number;
    color: number;
}
export interface LightModeInfo {
    lecture: ClassSettings;
    talk: ClassSettings;
    multi: ClassSettings;
    rest: ClassSettings;
}
export interface DeviceManagementInterface {
    classId: string
    modeInfo: LightModeInfo
}
export const deviceManagementStore = atom<DeviceManagementInterface[]>({
    key: 'deviceManagement',
    default: []
});



/* 수업 미러링 상태관리 */
export interface MirrorManagementInterface {
    lectureId: string
    mirrorInfo: MirrorInfoInterface[]
    mirrorTempInfo: MqttMirrorManageMessage[]
}



export interface MirrorInfoInterface {
    groupMirror: boolean;
    webRtcRoomId: number;
    pubInfo: {
        division: 'student' | 'teacher' | 'board' | 'onequick';
        id: string;
        name: string;
        number: string | null;
    }[];
    subInfo: {
        division: 'student' | 'teacher' | 'board' | 'onequick';
        id: string;
        name: string;
        number: string | null;
    }[];
}

export const mirrorManagementStore = atom<MirrorManagementInterface>({
    key: 'mirrorManagement',
    default: {
        lectureId: 'default',
        mirrorInfo: [],
        mirrorTempInfo: []
    }
});

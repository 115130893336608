import { useRecoilValue, useResetRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { getSession } from '@/hooks/common';


// 메시지 상세 팝업 훅
export default() => {



    // 세션값 가져오기
    const { sessionBaseInfo } = getSession();
    // 팝업 state
    const popup = useRecoilValue(popupStore);
    const resetPopup = useResetRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);





    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE === 'msgDetail'){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }


    }, [popup]);



    // 팝업 끌 경우
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            resetPopup();
            document.body.style.overflow = '';
        }, 100);
    };







    return { sessionBaseInfo, popup, isVisible, popupClose };
};

import React, { useState } from 'react';
import { ClassInfoInterface } from '@/hooks/services/checkIn';
import { Swiper, SwiperSlide } from 'swiper/react';
import { checkInManagementStore } from '@/recoil/store/checkIn';
import { learningModeStore, redDotStateStore } from '@/recoil/store/class';
import { withdrawInfoStore } from '@/recoil/store/user';
import { Swiper as SwiperType } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import '@/components/container/service/navbar/LocalNavigationBar.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useMediaQuery } from '@mui/material';
import { getSession } from '@/hooks/common';
import Iconsvg from '@/components/common/Iconsvg';
import LnbLogo from '@/assets/icon/logo/a-school-vertical-logo.svg';
import MenuHome from '@/assets/icon/LNB/menu_home.svg';         // Dompurify 적용완료
import MenuClass from '@/assets/icon/LNB/menu_class.svg';       // Dompurify 적용완료
import MenuTeach from '@/assets/icon/LNB/menu_teach.svg';       // Dompurify 적용완료
import MenuBlended from '@/assets/icon/LNB/menu_blended.svg';   // Dompurify 적용완료
import MenuFile from '@/assets/icon/LNB/menu_file.svg';          // Dompurify 적용완료
import MenuNotice from '@/assets/icon/LNB/menu_notice.svg';     // Dompurify 적용완료
import ManageArrow from '@/assets/icon/LNB/menu_arrow.svg';     // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import { popupStore } from '@/recoil/store/popup';
import { NetworkStatusStore } from '@/recoil/store/common';



interface LocalNavigationBarProps {
  menuOpen: boolean;
  classInfo: ClassInfoInterface | null;
}

/**  좌측 메뉴  **/
const LocalNavigationBar: React.FC<LocalNavigationBarProps> = ({ menuOpen, classInfo }) => {


  /**  변수 설정  **/

  // 페이지 이동
  const navigate = useNavigate();
  // 경로 파악
  const location = useLocation();
  const locationParts = location.pathname.split('/');
  const locationResult = locationParts[3];
  // 접근 디바이스 (PC인지 태블릿인지)
  const { sessionBaseInfo, deviceInfo } = getSession();
  // 화면이 가로 1500px 이하일 경우
  const isSmallScreen = useMediaQuery('(max-width: 1500px)');
  // 화면이 세로 768px 이하인 경우
  const isShortHeight = useMediaQuery('(max-height: 768px)');
  // mini 클래스 명 선정
  const miniClass = (deviceInfo?.model === 'Android' || isSmallScreen) && 'mini';
  // 체크인 관리 ( 각 서버 정상접근중인지 여부 확인 )
  const checkInManagement = useRecoilValue(checkInManagementStore);
  // 회원 탈퇴 상태
  const withdrawStatus = useRecoilValue(withdrawInfoStore);
  // 수업도구 상태
  const learningMode = useRecoilValue(learningModeStore);
  // 교수학습 레드닷 상태
  const redDotState = useRecoilValue(redDotStateStore);
  // LNB 슬라이드
  const [swiper, setSwiper] = useState<SwiperType | null>(null);
  // 팝업 state
  const setPopup = useSetRecoilState(popupStore);
  // 네트워크 상태
  const networkStatus = useRecoilValue(NetworkStatusStore);

  /**  API 호출 관련 함수  **/


  /**  버튼 클릭 관련 함수  **/


  // 페이지 이동 함수
  const movePage = (pageName: string) => {
    if (networkStatus) {
      navigate(pageName);
    } else {
      setPopup({ TYPE: 'alert', TITLE: 'Notification', CONTENT: 'Please check your network connection and try again.', BUTTON: 'OK', RESERVE: '' });
    }

  };

  // 슬라이드 업다운
  const handleNext = () => {
    swiper?.slideNext();
  };

  // 모둠관리 이동 (체크인 여부에 따라 분기)
  const moveGroupManage = () => {
    if (checkInManagement.checkInState) {   // 체크인 중일 경우
      navigate(`/service/teacher/groupDetail/${classInfo?.classId}`);
    } else {   // 체크인 중이 아닐 경우
      navigate('/service/teacher/groupManage');
    }
  };


  return (
    <div className={`container-left-menu ${miniClass} ${menuOpen ? '' : 'tab-closed'}`}>

      {/* 상단 로고 */}
      <div className={`container-left-logo ${miniClass}`}>
        <img src={LnbLogo} alt={'LnbLogo'} draggable={false} />
        {/* {
          sessionBaseInfo?.schoolInfo?.logoList &&
          <img
            src={sessionBaseInfo?.schoolInfo?.logoList?.find(l => l.logoType === (miniClass ? 'hlogo' : 'wlogo'))?.base64Image}
            alt={'LnbLogo'} draggable={false} />
        }

        {
          (!sessionBaseInfo?.schoolInfo?.logoList) &&
          <img src={LnbLogo} alt={'LnbLogo'} draggable={false} />
        } */}
      </div>


      {/* 중단 컨텐츠 */}
      <div className={`container-left-content ${miniClass}`}>
        {
          sessionBaseInfo?.baseInfo.status === 'approved' && sessionBaseInfo?.schoolInfo?.classification !== 'faculty'
            ?
            // 접근 권한이 있으며, 교직원이 아닌 경우
            <>
              {sessionBaseInfo?.baseInfo.role === 'teacher'
                ?
                <>
                  {
                    // For tablet devices
                    miniClass
                      ?
                      <div className={`container-left-slider ${menuOpen ? '' : 'tab-closed'}`}>
                        <Swiper direction={'vertical'} loop={true} slidesPerView={1}
                          className="lnb-swiper" onSwiper={(e: SwiperType) => {
                            setSwiper(e);
                          }}>
                          <SwiperSlide>
                            <ul className={'container-left-cmb-section mini'}>
                              <li className={`container-left-cmb-item mini ${locationResult.includes('home') && 'selected'}`}
                                onClick={!withdrawStatus ? () => movePage('/service/teacher/home') : undefined}>
                                {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                                <img src={publicMethod.svgClear(MenuHome)}
                                  alt={'MenuHome'} draggable={false} />
                                <span> Home </span>
                              </li>
                              <li className={`container-left-cmb-item mini ${locationResult.includes('environment') && 'selected'}`}
                                onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/environment') : undefined}>
                                {(withdrawStatus || !checkInManagement.checkInState) &&
                                  <div className='container-none-checkin-transparent'></div>}
                                <img src={publicMethod.svgClear(MenuClass)}
                                  alt={'MenuClass'} draggable={false} />
                                <span> Environment </span>
                              </li>
                              <li className={`container-left-cmb-item mini ${locationResult.includes('learning') && 'selected'}`}
                                onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/learning') : undefined}>
                                {(withdrawStatus || !checkInManagement.checkInState) &&
                                  <div className='container-none-checkin-transparent'></div>}
                                {redDotState.isRedDot &&
                                  <div className='red-alarm'></div>}
                                <img src={publicMethod.svgClear(MenuTeach)}
                                  alt={'MenuTeach'} draggable={false} />
                                <span> Learning </span>
                              </li>
                              <li className={`container-left-cmb-item mini ${locationResult.includes('blended') && 'selected'}`}
                                onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/blended') : undefined}>
                                {(withdrawStatus || !checkInManagement.checkInState) &&
                                  <div className='container-none-checkin-transparent'></div>}
                                <img src={publicMethod.svgClear(MenuBlended)}
                                  alt={'MenuBlended'} draggable={false} />
                                <span> Blended Classes </span>
                              </li>
                              <li className={`container-left-cmb-item mini ${locationResult.includes('myBox') && 'selected'}`}
                                onClick={!withdrawStatus ? () => movePage('/service/teacher/myBox') : undefined}>
                                {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                                <img src={publicMethod.svgClear(MenuFile)}
                                  alt={'MenuFile'} draggable={false} />
                                <span> Documents </span>
                              </li>
                            </ul>
                          </SwiperSlide>
                          <SwiperSlide>
                            <ul className={'container-left-cmb-section mini'}>
                              {/* If homeroom or assistant */}
                              {
                                sessionBaseInfo.schoolInfo?.classificationType?.some(type => ['homeroom', 'assistant'].includes(type)) &&
                                <li className={`container-left-cmb-item mini ${locationResult.includes('studentManage') && 'selected'}`}
                                  onClick={!withdrawStatus ? () => movePage('/service/teacher/studentManage') : undefined}>
                                  {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                                  <div className={'container-left-cmb-img'}>
                                    <Iconsvg name={'menuStudent'} />
                                  </div>
                                  <span> Student<br></br>Management </span>
                                </li>
                              }
                              <li className={`container-left-cmb-item mini ${locationResult.includes('group') && 'selected'}`}
                                onClick={(!withdrawStatus && learningMode.TYPE !== 3) ? moveGroupManage : undefined}>
                                {(withdrawStatus || learningMode.TYPE === 3) && <div className='container-none-checkin-transparent'></div>}
                                <div className={`container-left-cmb-img ${learningMode.TYPE === 3 && 'transparent'}`}>
                                  <Iconsvg name={'menuGroup'} />
                                </div>
                                <span> Group<br></br>Management </span>
                              </li>
                              {/* If there is a class assigned */}
                              {
                                sessionBaseInfo.schoolInfo?.classId &&
                                <li className={`container-left-cmb-item mini ${locationResult.includes('device') && 'selected'}`}
                                  onClick={!withdrawStatus ? () => movePage('/service/teacher/deviceManage') : undefined}>
                                  {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                                  <div className={'container-left-cmb-img'}>
                                    <Iconsvg name={'menuDevice'} />
                                  </div>
                                  <span> Device<br></br>Management </span>
                                </li>
                              }
                              <li className={`container-left-cmb-item mini ${locationResult.includes('notice') && 'selected'}`}
                                onClick={!withdrawStatus ? () => movePage('/service/teacher/noticeManage') : undefined}>
                                {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                                <div className={'container-left-cmb-img'}>
                                  <Iconsvg name={'menuNotice'} />
                                </div>
                                <span> Notice </span>
                              </li>
                            </ul>
                          </SwiperSlide>
                        </Swiper>
                      </div>
                      :
                      <div className={`container-left-cmb ${menuOpen ? '' : 'tab-closed'} ${isShortHeight && 'short'}`}>
                        {/* Class Management */}
                        <ul className={'container-left-cmb-section'}>
                          <li className={`container-left-cmb-item ${locationResult.includes('home') && 'selected'}`}
                            onClick={!withdrawStatus ? () => movePage('/service/teacher/home') : undefined}>
                            {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                            <img src={publicMethod.svgClear(MenuHome)} alt={'MenuHome'} draggable={false} />
                            <span> Home </span>
                          </li>
                          <li className={`container-left-cmb-item ${locationResult.includes('environment') && 'selected'}`}
                            onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/environment') : undefined}>
                            {(withdrawStatus || !checkInManagement.checkInState) && <div className='container-none-checkin-transparent'></div>}
                            <img src={publicMethod.svgClear(MenuClass)} alt={'MenuClass'} draggable={false} />
                            <span> Classroom<br></br>Environment </span>
                          </li>
                          <li className={`container-left-cmb-item ${locationResult.includes('learning') && 'selected'}`}
                            onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/learning') : undefined}>
                            {(withdrawStatus || !checkInManagement.checkInState) && <div className='container-none-checkin-transparent'></div>}
                            {redDotState.isRedDot && <div className='red-alarm'></div>}
                            <img src={publicMethod.svgClear(MenuTeach)} alt={'MenuTeach'} draggable={false} />
                            <span> Teaching<br></br>and Learning </span>
                          </li>
                          <li className={`container-left-cmb-item ${locationResult.includes('blended') && 'selected'}`}
                            onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/teacher/blended') : undefined}>
                            {(withdrawStatus || !checkInManagement.checkInState) && <div className='container-none-checkin-transparent'></div>}
                            <img src={publicMethod.svgClear(MenuBlended)} alt={'MenuBlended'} draggable={false} />
                            <span> Blended<br></br>Classes </span>
                          </li>
                          <li className={`container-left-cmb-item ${locationResult.includes('myBox') && 'selected'}`}
                            onClick={!withdrawStatus ? () => movePage('/service/teacher/myBox') : undefined}>
                            {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                            <img src={publicMethod.svgClear(MenuFile)} alt={'MenuFile'} draggable={false} />
                            <span> My<br></br>Documents </span>
                          </li>
                        </ul>

                        <hr className={'container-left-line'} />

                        {/* Other Management */}
                        <ul className={'container-left-cmb-section'}>
                          {/* If homeroom or assistant */}
                          {
                            sessionBaseInfo.schoolInfo?.classificationType?.some(type => ['homeroom', 'assistant'].includes(type)) &&
                            <li className={`container-left-cmb-item ${locationResult.includes('studentManage') && 'selected'}`}
                              onClick={!withdrawStatus ? () => movePage('/service/teacher/studentManage') : undefined}>
                              {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                              <div className={'container-left-cmb-img'}>
                                <Iconsvg name={'menuStudent'} />
                              </div>
                              <span> Student<br></br>Management </span>
                            </li>
                          }

                          <li className={`container-left-cmb-item ${locationResult.includes('group') && 'selected'}`}
                            onClick={(!withdrawStatus && learningMode.TYPE !== 3) ? moveGroupManage : undefined}>
                            {(withdrawStatus || learningMode.TYPE === 3) && <div className='container-none-checkin-transparent'></div>}
                            <div className={`container-left-cmb-img ${learningMode.TYPE === 3 && 'transparent'}`}>
                              <Iconsvg name={'menuGroup'} />
                            </div>
                            <span> Group<br></br>Management </span>
                          </li>

                          {/* If there is a class assigned */}
                          {
                            sessionBaseInfo.schoolInfo?.classId &&
                            <li className={`container-left-cmb-item ${locationResult.includes('device') && 'selected'}`}
                              onClick={!withdrawStatus ? () => movePage('/service/teacher/deviceManage') : undefined}>
                              {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                              <div className={'container-left-cmb-img'}>
                                <Iconsvg name={'menuDevice'} />
                              </div>
                              <span> Device<br></br>Management </span>
                            </li>
                          }

                          <li className={`container-left-cmb-item ${locationResult.includes('notice') && 'selected'}`}
                            onClick={!withdrawStatus ? () => movePage('/service/teacher/noticeManage') : undefined}>
                            {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                            <div className={'container-left-cmb-img'}>
                              <Iconsvg name={'menuNotice'} />
                            </div>
                            <span> Notice </span>
                          </li>
                        </ul>
                      </div>
                  }


                </>
                :
                // 학생으로 접근할 경우
                <div
                  className={`container-left-cmb student ${menuOpen ? '' : 'tab-closed'} ${miniClass}`}>
                  {/* Class Management */}
                  <ul className={`container-left-cmb-section ${miniClass}`}>
                    <li className={`container-left-cmb-item ${miniClass} ${locationResult.includes('home') && 'selected'}`}
                      onClick={!withdrawStatus ? () => movePage('/service/student/home') : undefined}>
                      {withdrawStatus &&
                        <div className='container-none-checkin-transparent'></div>}
                      <img src={publicMethod.svgClear(MenuHome)} alt={'MenuHome'}
                        draggable={false} />
                      <span> Home </span>
                    </li>
                    <li className={`container-left-cmb-item ${miniClass} ${locationResult.includes('learning') && 'selected'}`}
                      onClick={(!withdrawStatus && checkInManagement.checkInState) ? () => movePage('/service/student/learning') : undefined}>
                      {(withdrawStatus || !checkInManagement.checkInState) &&
                        <div className='container-none-checkin-transparent'></div>}
                      {redDotState.isRedDot && <div className='red-alarm'></div>}
                      <img src={publicMethod.svgClear(MenuTeach)} alt={'MenuTeach'}
                        draggable={false} />
                      <span> Learning </span>
                    </li>
                    <li className={`container-left-cmb-item ${miniClass} ${locationResult.includes('myBox') && 'selected'}`}
                      onClick={!withdrawStatus ? () => movePage('/service/student/myBox') : undefined}>
                      {withdrawStatus &&
                        <div className='container-none-checkin-transparent'></div>}
                      <img src={publicMethod.svgClear(MenuFile)} alt={'MenuFile'}
                        draggable={false} />
                      <span> Documents </span>
                    </li>
                    <li className={`container-left-cmb-item ${miniClass} ${locationResult.includes('notice') && 'selected'}`}
                      onClick={!withdrawStatus ? () => movePage('/service/student/noticeManage') : undefined}>
                      {withdrawStatus &&
                        <div className='container-none-checkin-transparent'></div>}
                      <img src={publicMethod.svgClear(MenuNotice)} alt={'MenuNotice'}
                        draggable={false} />
                      <span> Notice </span>
                    </li>
                  </ul>
                </div>

              }
            </>
            :
            // 접근 권한이 없는 경우
            <div className={`container-left-cmb no-access ${menuOpen ? '' : 'tab-closed'}`}>
              {/* SnHome */}
              <ul className={`container-left-cmb-section ${miniClass}`}>
                <li className={`container-left-cmb-item ${miniClass} ${locationResult.includes('home') && 'selected'}`}
                  onClick={!withdrawStatus ? () => movePage(`/service/${sessionBaseInfo?.baseInfo.role}/home`) : undefined}>
                  {withdrawStatus && <div className='container-none-checkin-transparent'></div>}
                  <img src={publicMethod.svgClear(MenuHome)} alt={'MenuHome'} draggable={false} />
                  <span> Home </span>
                </li>
              </ul>
            </div>
        }
      </div>


      {/* 하단 공백 or 화살표 */}

      <div className={`top-bottom-arrow-box ${(!isSmallScreen && deviceInfo?.model !== 'Android') && 'big'} ${isShortHeight && 'short'}`} onClick={handleNext}>
        {/* 소형 화면 && 선생 && 접근권한이 있는 경우에만 화살표 노출 */}
        {((isSmallScreen || deviceInfo?.model === 'Android') && sessionBaseInfo?.baseInfo.role === 'teacher' && sessionBaseInfo?.baseInfo.status === 'approved') &&
          <img src={publicMethod.svgClear(ManageArrow)} alt={'ManageArrow'} draggable={false} />
        }
      </div>
    </div>
  );

};

export default LocalNavigationBar;

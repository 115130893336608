import { Outlet } from 'react-router-dom';
import checkIn from '@/hooks/services/checkIn';
import ServiceContainer from '@/components/container/service/full/ServiceContainer';
import nativeCallback from '@/hooks/services/nativeCallback';
import scheduler from '@/hooks/services/scheduler';
import commonLayoutHook from '@/hooks/layout/commonLayout';
import globalAlim from '@/hooks/services/globalAlim';
import firstAccess from '@/hooks/services/firstAccess';

/**  학생용 레이아웃  **/
const StudentLayout = () => {


  // 체크인 HOOK
  const {
    usersInfo,
    classInfo,
    detailMonitorImage,
    setUsersInfo,
    classCheckIn,
    classCheckOut,
    mirrorStartCommand,
    mirrorStopCommand,
    sendPermissionRequestMessage,
    mirrorOpenRequest,
    returnPermissionMessage,
    returnMirrorPermissionMessage,
    getDetailMonitorRequest,
    checkInExtendRequest,
    stopAllMirrorRoomCommand,
    joinBlendedStreamLearning,
    usersInfoBox,
    setUsersInfoBox,
    deleteClassTalk
  } = checkIn();
  // 콜백 함수
  nativeCallback();
  // 시간표 스케줄러
  scheduler();
  // 레이아웃 훅
  const { logout } = commonLayoutHook(classCheckOut);
  /* 전역알림 */
  globalAlim();
  /* 첫 접근 */
  firstAccess();



  return (
    <div className='AppRoot'>
      <ServiceContainer logout={logout} classInfo={classInfo} usersInfo={usersInfo} usersInfoBox={usersInfoBox} setUsersInfoBox={setUsersInfoBox} sendPermissionRequestMessage={sendPermissionRequestMessage} mirrorOpenRequest={mirrorOpenRequest} mirrorStartCommand={mirrorStartCommand} mirrorStopCommand={mirrorStopCommand} joinBlendedStreamLearning={joinBlendedStreamLearning} checkInExtendRequest={checkInExtendRequest}
        returnPermissionMessage={returnPermissionMessage} returnMirrorPermissionMessage={returnMirrorPermissionMessage}
        getDetailMonitorRequest={getDetailMonitorRequest} detailMonitorImage={detailMonitorImage} deleteClassTalk={deleteClassTalk}
      >
        <Outlet context={{ classCheckIn, classCheckOut, usersInfo, classInfo, setUsersInfo, detailMonitorImage, mirrorStartCommand, mirrorStopCommand, sendPermissionRequestMessage, mirrorOpenRequest, getDetailMonitorRequest, checkInExtendRequest, stopAllMirrorRoomCommand, logout, joinBlendedStreamLearning, usersInfoBox, setUsersInfoBox, deleteClassTalk }} />
      </ServiceContainer>
    </div>
  );

};

export default StudentLayout;

import { useNavigate } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { popupAutoStore, popupErrorStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { loginSessionCountStore } from '@/recoil/store/schedule';
import { sessionExtend } from '@/services/core/me/sessionExtend';
import { getSession } from '@/hooks/common';
import { forceLogoutStore } from '@/recoil/store/user';
import publicMethod from '@/utils/publicMethod';

/* 자동로그아웃 팝업 HOOK */
export default(logout: ()=> void) => {

    // 페이지 이동
    const navigate = useNavigate();
    // 팝업 자동 로그아웃 state
    const [popupAuto, setPopupAuto] = useRecoilState(popupAutoStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);

    const setLoginSessionCount = useSetRecoilState(loginSessionCountStore);  // 로그인 세션 카운트

    const setForceLogout = useSetRecoilState(forceLogoutStore);   // 강제로그아웃

    const { sessionBaseInfo, sessionTokenInfo } = getSession();
    // 팝업 에러 state
    const setPopupError = useSetRecoilState(popupErrorStore);

    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupAuto.TYPE === 'autoLogout'){
            setIsVisible(true);
            document.body.style.overflow = 'hidden';
        } else {
            setIsVisible(false);
        }
    }, [popupAuto.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopupAuto((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };

    const userLogout = () => {
        logout();
    };

    const keepLogin = async () => {

        const apiRes = await sessionExtend({ token: sessionTokenInfo.coreAccessToken! });

        if (apiRes.message === 'LOGOUT') {
            setForceLogout({ force: true, reason: 'otherDevice' });
        }
        else if (apiRes.message === 'TOKEN_EXPIRED') {
            setForceLogout({ force: true, reason: 'token' });
        }
        else if (apiRes.message === 'SUCCESS') {
            setLoginSessionCount(publicMethod.sessionUpdate);
            setIsVisible(false);
            setTimeout(() => {
                setPopupAuto((prevData) => ({ ...prevData, TYPE: '' }));
            }, 100);
        }
        else if (apiRes.message === 'TIMEOUT') {
            navigate(`/service/${sessionBaseInfo?.baseInfo.role}/error`);
        }else{
            // 서버 에러 팝업
            setPopupError(prevData => [...prevData, { CODE: apiRes.errorCode, MESSAGE: apiRes.errorMessage }]);

        }

    };

    return { popupAuto, isVisible, popupClose, userLogout, keepLogin };
};

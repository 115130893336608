import { useRecoilState, useResetRecoilState } from 'recoil';
import { popupStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';
import { classCommandStore } from '@/recoil/store/class';


/* 파일, 링크, 메시지전송 취소 팝업 HOOK */
export default() => {

    // 팝업 state
    const [popup, setPopup] = useRecoilState(popupStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);
    // 교수학습 시, 명령 초기화
    const resetClassCommand = useResetRecoilState(classCommandStore);

    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popup.TYPE === 'commandCancel'){
            setTimeout(() => {
                setIsVisible(true);
                document.body.style.overflow = 'hidden';
            }, 100);
        }
    }, [popup.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {
        setIsVisible(false);
        setTimeout(() => {
            setPopup((prevData) => ({ ...prevData, TYPE: '' }));
            document.body.style.overflow = '';
        }, 100);
    };

    // 팝업 커맨드 취소 (화면공유, 파일/링크/메시지전송 등 진행 중 취소)
    const popupConfirm =  () => {
        // 명령 초기화
        resetClassCommand();
        // 팝업 끄기
        popupClose();
    };


    return { popup, isVisible, popupClose, popupConfirm };
};

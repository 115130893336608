import React from 'react';
import '@/components/popup/popup.css';
import MessageIcon from '@/assets/icon/common/message.svg';             // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';
import popupMessageHook from '@/hooks/components/popup/common/popupMessage';



// 메시지 확인 팝업
const PopupMessage = () => {

  const { sessionBaseInfo, popup, isVisible, popupClose } = popupMessageHook();

  const getRoleLabel = () => {
    let roleLabel = '';

    if (sessionBaseInfo?.baseInfo.role === 'teacher') {
      if (popup.RESERVE === 'send') {
        roleLabel = ' Teacher';
      } else if (popup.RESERVE === 'receive') {
        roleLabel = ' Student';
      } else {
        roleLabel = '1';
      }
    } else if (popup.RESERVE === 'send') {
      roleLabel = ' Student';
    } else if (popup.RESERVE === 'receive') {
      roleLabel = ' Teacher';
    } else {
      roleLabel = '2';
    }

    return roleLabel;
  };


  return (
    /* Layout Popup */
    <>
      {popup.TYPE === 'msgDetail' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>

          {/* Message */}
          {popup.TYPE === 'msgDetail' &&
            <div className={'layout-popup-box'}>

              <div className='cmd-lp-top'>
                <div className='cmd-lp-cover'>
                  <img src={publicMethod.svgClear(MessageIcon)} alt={'MessageIcon'} draggable={false} />
                  <span>Message Details</span>
                </div>
              </div>

              <div className='cmd-lp-content message'>
                <div className={'cmd-message-item full student'}>
                  <div className='cmd-message-item-content other'>
                    <div className='cmd-message-item-sender'>
                      <span>
                        {popup.CONTENT?.senderName}
                        {getRoleLabel()}
                      </span>
                      <span>
                        {/* {popupOther.MESSAGE?.sendTime} */}
                        {publicMethod.convertDate(popup.CONTENT?.sendTime)}
                      </span>
                    </div>
                    <textarea name={'message'} value={popup.CONTENT?.message} placeholder={'Enter message (up to 100 characters)'} readOnly={true} />
                  </div>
                </div>
              </div>
              {/* <div className={'cmd-lp-button'} onClick={() => popupOther.DATA?.talkId ? popupClose(popupOther.DATA?.talkId) : console.log('')}> */}
              <div className={'cmd-lp-button'} onClick={popupClose}>
                Confirm
              </div>
            </div>
          }
        </div>
      }
    </>
  );

};

export default PopupMessage;

import { useRecoilState } from 'recoil';
import { popupEnvironmentStore } from '@/recoil/store/popup';
import { useEffect, useState } from 'react';

/* 사이니지 교실환경 알림 팝업 HOOK */
export default() => {


    // 팝업 교실환경 state
    const [popupEnvironment, setPopupEnvironment] = useRecoilState(popupEnvironmentStore);
    // 팝업 노출 여부
    const [isVisible, setIsVisible] = useState(false);


    /** 팝업 노출 시키기**/
    useEffect(() => {
        if(popupEnvironment.TYPE === 'environmentSignage'){
            setTimeout(() => {
                setIsVisible(true);
            }, 100);
        }
    }, [popupEnvironment.TYPE]);

    // 팝업 끄기
    const popupClose =  () => {

        setIsVisible(false);
        setTimeout(() => {
            setPopupEnvironment((prevData) => ({ ...prevData, TYPE: '' }));
        }, 100);
    };

    return {
        popupEnvironment,
        popupClose,
        isVisible
    };
};

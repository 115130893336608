import {
  TABLE_HEADER_FILE,
  TABLE_HEADER_LINK,
  TABLE_HEADER_MESSAGE,
  TABLE_HEADER_VIDEO,
  TABLE_HEADER_BOARD,
  TABLE_HEADER_FILE_STUDENT,
  TABLE_HEADER_LINK_STUDENT,
  TABLE_HEADER_MESSAGE_STUDENT,
  TABLE_HEADER_VIDEO_STUDENT,
  TABLE_HEADER_ALERT,
  TABLE_HEADER_NOTICE_A,
  TABLE_HEADER_NOTICE_B,
  TABLE_HEADER_STUDENT_MANAGE,
  TABLE_HEADER_DEVICE,
  TABLE_HEADER_ALERT_STUDENT
} from './headerData';

/**
 * 
 * @param menu 탭 메뉴
 * @param type 학생인지 선생님인지
 * @param subMenu 세부 메뉴
 * @dscription 메뉴에 따른 Header 데이터를 가져오는 함수
 * @returns 
*/
export const getHeaderData = (menu: string, type: string, subMenu?: string) => {
  if (!menu) return;

  switch (menu) {
    case 'file':
      return type === 'teacher' ? TABLE_HEADER_FILE : TABLE_HEADER_FILE_STUDENT;
    case 'link':
      return type === 'teacher' ? TABLE_HEADER_LINK : TABLE_HEADER_LINK_STUDENT;
    case 'message':
      return type === 'teacher' ? TABLE_HEADER_MESSAGE : TABLE_HEADER_MESSAGE_STUDENT;
    case 'video':
      return type === 'teacher' ? TABLE_HEADER_VIDEO : TABLE_HEADER_VIDEO_STUDENT;
    case 'board':
      return TABLE_HEADER_BOARD;
    case 'alert':
      return type === 'teacher' ? TABLE_HEADER_ALERT : TABLE_HEADER_ALERT_STUDENT;
    case 'notice':
      return type === 'teacher' ? (subMenu === 'mySchool' ? TABLE_HEADER_NOTICE_A : TABLE_HEADER_NOTICE_B) : (subMenu === 'mySchool' ? TABLE_HEADER_NOTICE_A : TABLE_HEADER_NOTICE_B);
    case 'student':
      return TABLE_HEADER_STUDENT_MANAGE;
    case 'device':
      return TABLE_HEADER_DEVICE;
    default:
      return;
  }
};

// D-Day 계산 함수
export const getDday = (expirationDate: Date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // 오늘 날짜를 기준으로만 비교하기 위해 시간 부분을 0으로 설정
  expirationDate.setHours(0, 0, 0, 0); // 대상 날짜도 시간 부분을 0으로 설정

  const diff = expirationDate.getTime() - today.getTime();
  const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));

  if (diffDays === 0) return 'D-day';
  if (diffDays > 0) return `D-${diffDays}`;
  return 'Expired';
};

// D-day가 D-3, D-2, D-1, D-day 일때 expiration-near을 반환하는 함수
export const getExpirationNear = (dDay: string) => {
  if (dDay === 'D-day' || dDay === 'D-1' || dDay === 'D-2' || dDay === 'D-3' || dDay === 'Expired') {
    return 'expiration-near';
  } else {
    return '';
  }
};

// width에 따라 텍스트 조절 함수
export const adjustText = (text: string, width: number, signage?: boolean) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  if (!context) return text;

  if (signage) {
    context.font = '32px Pretendard Regular';
  } else {
    context.font = '16px Pretendard Regular';
  }

  const measureTextWidth = (text: string) => context.measureText(text).width;

  const extensionIndex = text.lastIndexOf('.');
  const extension = extensionIndex !== -1 ? text.substring(extensionIndex) : '';
  const baseText = extensionIndex !== -1 ? text.slice(0, extensionIndex) : text;

  if (measureTextWidth(text) <= width) return text;

  const ellipsis = '..';
  const ellipsisWidth = measureTextWidth(ellipsis);
  const maxWidth = width - ellipsisWidth - measureTextWidth(extension);

  let trimmedText = baseText;
  while (measureTextWidth(trimmedText) > maxWidth && trimmedText.length > 0) {
    trimmedText = trimmedText.slice(0, -1);
  }

  return `${trimmedText}${ellipsis}${extension}`;
};

// Function to format activation status for SnDevice Table
export const activeFormat = (type: string) => {
  // If type is active, return "Available", else return "Stopped"
  return type === 'active' ? 'Available' : 'Stopped';
};

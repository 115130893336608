import React from 'react';
import '@/components/popup/popup.css';
import popupMypageHook from '@/hooks/components/popup/common/popupMypage';
import Scrollbar from '@/components/common/scroll/ScrollBar';
import CloseButton from '@/assets/icon/popup/close_button.svg'; // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';



/* 마이페이지 안내 팝업 */
const PopupMypage = () => {

    const { popup, isVisible, popupClose } = popupMypageHook();


    return (
        <>
            {popup.TYPE.includes('mypage') &&
                <div className={`layout-popup ${isVisible && 'visible'}`}>

                    <div className={'layout-popup-box'}>

                        <article className={'layout-popup-content'}>

                            {/* 팝업 닫기 버튼 */}
                            <figure className='layout-popup-close-button-box'>
                                <img src={publicMethod.svgClear(CloseButton)} alt={'CloseButton'} draggable={false} onClick={()=>popupClose()}/>
                            </figure>

                            {/* 팝업 타이틀 */}
                            <div className='layout-popup-title' style={{ marginBottom: '20px' }}>
                                {popup.TITLE}
                            </div>

                            {/* 팝업 세부 컨텐츠 */}
                            <Scrollbar scrollbarHeight={'300px'}>
                                <div className='layout-popup-common-content mypage'>
                                    {/* <p className='layout-popup-mypage-title'> {popup.TITLE}</p> */}
                                    <span className='layout-popup-mypage-sub' dangerouslySetInnerHTML={{ __html: popup.CONTENT }}></span>
                                </div>
                            </Scrollbar>

                        </article>
                    </div>

                </div>
            }
        </>




    );
};

export default PopupMypage
;

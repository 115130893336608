import React from 'react';
import '@/components/popup/popup.css';
import popupAgreementHook from '@/hooks/components/popup/common/popupAgreement';
import AgreementIcon from '@/assets/icon/popup/agreement_icon.svg'; // Dompurify 적용완료
import publicMethod from '@/utils/publicMethod';




/* 블렌디드 개인정보 수집 및 이용 동의 안내 팝업 */
const PopupAgreement = () => {

  const { popupAgreement, isVisible, popupClose, agreeBlended, blendedAgreementText } = popupAgreementHook();


  return (
    <>
      {popupAgreement.TYPE === 'agreement' &&
        <div className={`layout-popup ${isVisible && 'visible'}`}>

          <div className={'layout-popup-box'}>

            <article className={'layout-popup-content'}>
              {/* Popup Icon */}
              <figure className='layout-popup-icon-box'>
                <img src={publicMethod.svgClear(AgreementIcon)} alt={'AgreementIcon'} draggable={false} />
              </figure>
              {/* Popup Title */}
              <div className='layout-popup-title'>Consent to Collection and Use of Personal Information</div>
              {/* Popup Detailed Content */}
              <div className="layout-popup-common-content agreement">
                <div style={{ overflow: 'auto' }} dangerouslySetInnerHTML={{ __html: blendedAgreementText }} />
              </div>
            </article>

            <div className='layout-popup-button-box'>
              <button className={'layout-popup-button double white'} onClick={popupClose}>
                Do Not Agree
              </button>
              <button className={'layout-popup-button double'} onClick={agreeBlended}>
                Agree
              </button>
            </div>
          </div>

        </div>
      }
    </>
  );

};

export default PopupAgreement
  ;

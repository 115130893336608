import { getSession } from '@/hooks/common';
import { mqttGlobalAlimConnect, mqttGlobalAlimDisconnect, MqttGlobalAlimResponse } from '@/services/globalMqtt';
import { useEffect, useState } from 'react';
import { MqttClient } from 'mqtt';
import { useSetRecoilState } from 'recoil';
import { alarmDotStore } from '@/recoil/store/common';
import { popupEnvironmentStore } from '@/recoil/store/popup';
import { v4 as uuidv4 } from 'uuid';
import { forceLogoutStore } from '@/recoil/store/user';



export default () => {

  // 세션, 토큰 정보
  const { sessionBaseInfo } = getSession();
  const [globalAlimClient, setGlobalAlimClient] = useState<MqttClient | null>(null);
  const [globalAlimMessages, setGlobalAlimMessages] = useState<MqttGlobalAlimResponse[]>([]);
  const [duplicateSession, setDuplicateSession] = useState<boolean>(false);
  const [duplicateSessionCheck, setDuplicateSessionCheck] = useState<boolean>(false);
  // 알람 점 설정
  const setAlarmDot = useSetRecoilState(alarmDotStore);
  // 교실환경 팝업 state
  const setPopupEnvironment = useSetRecoilState(popupEnvironmentStore);
  // 강제 로그아웃
  const setForceLogout = useSetRecoilState(forceLogoutStore);



  /* Global mqtt connect */
  useEffect(() => {
    if (sessionBaseInfo?.baseInfo.role === 'teacher' || sessionBaseInfo?.baseInfo.role === 'student') {
      if (!duplicateSessionCheck) {
        const uniqueId = uuidv4();
        const globalAlimMessageRequest = {
          userId: sessionBaseInfo.baseInfo.userId,
          uniqueId: uniqueId,
          setGlobalAlimMessages: setGlobalAlimMessages,
          setDuplicateSession: setDuplicateSession,
          mqttAccountInfo: sessionBaseInfo.mqtt
        };
        const mqttClient = mqttGlobalAlimConnect(globalAlimMessageRequest);
        setGlobalAlimClient(mqttClient);
        setDuplicateSessionCheck(true);
        /* 중복 로그인 제거 */
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log(`SEND GLOBAL MQTT SESSION CHECK : ${uniqueId}`);
        }
        mqttClient.publish(`session/${sessionBaseInfo.baseInfo.userId}`, JSON.stringify({ uniqueId: uniqueId }));
      }
    }
    else if (globalAlimClient) {
      mqttGlobalAlimDisconnect(globalAlimClient);
    }
  }, [sessionBaseInfo, duplicateSessionCheck]);

  useEffect(() => {

    if (globalAlimMessages.length > 0) {

      const targetMessage = globalAlimMessages[0];
      const message = targetMessage.message;

      // if (targetMessage.notificationType === 'LINK_SCHOOL_NOTICE') {
      //     getLinkSchoolNotice(targetMessage.detailId);
      //     setGlobalAlimMessages(prev => prev.slice(1));
      // }
      if (targetMessage.notificationType === 'TEACHER_NOTICE_CLASS' || targetMessage.notificationType === 'TEACHER_NOTICE_CLASS_SUBJECT') {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('선생님 반 or 교과 알림', message);
        }
        if (sessionBaseInfo?.baseInfo.role === 'student') {
          setGlobalAlimMessages(prev => prev.slice(1));
          setAlarmDot(true);
        }
      }
      else if (targetMessage.notificationType === 'SA_NOTICE' || targetMessage.notificationType === 'GRANT_PERMISSION') {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('학교 관리자 알림 or 권한 승인 알림', message);
        }
        if (sessionBaseInfo?.baseInfo.role === 'teacher') {
          setGlobalAlimMessages(prev => prev.slice(1));
          setAlarmDot(true);
        }
      }
      else if (targetMessage.notificationType === 'CLASSROOM_ENVIRONMENT') {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('교실환경 알림', message);
        }

        // 교실환경 알림 메시지 분리
        // message split to ^ ->  {학년}$^${반}$^${title}$^${message}
        const splitMessage = message.split('$^$');
        const environment_class = `${splitMessage[0]} - ${splitMessage[1]} Class`;
        const environment_title = splitMessage[2];
        const environment_content = splitMessage[3];


        setGlobalAlimMessages(prev => prev.slice(1));
        setAlarmDot(true);
        // 교실환경 팝업 띄우기
        setPopupEnvironment({ TYPE: 'environment', TITLE: environment_title, CONTENT: environment_content, BUTTON: 'OK', RESERVE: environment_class });
      }
      else if (targetMessage.notificationType === 'OTO_INQUIRY_ANSWER') {
        if (`${process.env.REACT_APP_ENV}` !== 'PRODUCTION') {
          console.log('1:1 문의 답변 알림', message);
        }
        setGlobalAlimMessages(prev => prev.slice(1));
        setAlarmDot(true);
      }
    }
  }, [globalAlimMessages]);

  useEffect(() => {
    if (duplicateSession) {
      setForceLogout({ force: true, reason: 'otherDevice' });
    }
  }, [duplicateSession]);






  return { globalAlimMessages, setGlobalAlimMessages };
};
